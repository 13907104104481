import axios from "axios";
import moment from "moment";

import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getDaysBetweenTwoDates } from "helpers/date";
import { debounce } from "helpers/input";
import { capitalize } from "helpers/string";
import { absencesStatutOptions, absencesTypesOptions } from "helpers/timesheet";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import ButtonFilters from "components/filters/ButtonFilters";
import { applyFiltersUsers } from "components/filters/FilterByUsers";
import { applyFiltersAbsencesStatuts } from "components/filters/FiltersSidePanel";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import ModalAbsence from "../modalAbsence/ModalAbsence";

import { Button, Dropdown, Flex, Input, Select, Table, Tag } from "antd";

const TabAbsencesValidation = () => {
    const { t } = useTranslation();
    const { setToolsData, toolsData } = useContext(DriveToolsContext);
    const { startDriveSyncMessage, successDriveSyncMessage } = useContext(PopupContext);

    const { addModalData } = useContext(ModalContext);

    const renderTextDaysDuration = (duration) => `${duration} ${duration > 1 ? t("jours") : t("jour")}`;

    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        axios.get(apiURL.getAbsencesValidations).then(({ data }) => {
            setDataList(data);

            setToolsData((e) => ({
                ...e,
                filtersStore: {
                    statuts: absencesStatutOptions,
                    users: data
                        .reduce((acc, curr) => {
                            if (!acc.find((e) => e._id === curr.author._id)) acc.push(curr.author);
                            return acc;
                        }, [])
                        .sort((a, b) => {
                            // Compare by firstname first
                            if (a.firstname < b.firstname) return -1;
                            if (a.firstname > b.firstname) return 1;
                            // If firstnames are equal, compare by lastname
                            if (a.lastname < b.lastname) return -1;
                            if (a.lastname > b.lastname) return 1;
                            return 0;
                        }),
                },
            }));
        });
    }, []);

    const filteredDataList = useMemo(() => {
        let result = [...dataList];
        result = applyFiltersUsers(result, toolsData.filters.users);
        result = applyFiltersAbsencesStatuts(result, toolsData.filters.statuts);
        return result.map((e, i) => ({
            ...e,
            index: i + 1,
        }));
    }, [dataList, toolsData.filters]);

    const deleteInline = (dataLine) => {
        addModalData({
            key: "deleteFile",
            title: t("voulezVousVraimentSupprimerCeFichier?"),
            okText: t("confirmer"),
            handleConfirm: () => {
                startDriveSyncMessage();
                axios.delete(apiURL.removeAbsence + dataLine._id).then(() => {
                    setDataList((e) => e.filter((e) => e._id !== dataLine._id));
                    successDriveSyncMessage();
                });
            },
        });
    };

    const editInline = (itemId, newItem) => {
        startDriveSyncMessage();
        axios.patch(apiURL.updateAbsence + itemId, newItem).then(({ data }) => {
            setDataList((e) => {
                if (e.find((k) => k._id === itemId)) {
                    return e.map((k) => (k._id === itemId ? { ...k, ...newItem } : k));
                }
                return [...e, data];
            });
            successDriveSyncMessage();
        });
    };

    return (
        <Table
            size="small"
            title={() => <ButtonFilters />}
            dataSource={filteredDataList}
            rowKey={(record) => record._id}
            pagination={false}
            tableLayout="auto"
            scroll={{
                x: 1500,
                y: "60vh",
            }}
            columns={[
                {
                    title: "",
                    dataIndex: "index",
                    key: "index",
                    width: 50,
                },
                {
                    title: capitalize(t("utilisateurs")),
                    dataIndex: "author",
                    key: "author",
                    render: (author) => author.firstname + " " + author.lastname,
                    sorter: (a, b) =>
                        a.author.firstname.localeCompare(b.author.firstname) ||
                        a.author.lastname.localeCompare(b.author.lastname),
                },
                {
                    title: t("dateDeDebut"),
                    key: "startDate",
                    dataIndex: "startDate",
                    render: (e) => moment(e).format("DD/MM/YYYY"),
                    sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
                },
                {
                    title: t("dateDeFin"),
                    key: "endDate",
                    dataIndex: "endDate",
                    render: (e) => moment(e).format("DD/MM/YYYY"),
                    sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
                },
                {
                    key: "duration",
                    title: t("duree"),
                    render: (_, e) =>
                        renderTextDaysDuration(
                            getDaysBetweenTwoDates(moment(e.endDate), moment(e.startDate).subtract(1, "day")),
                        ),
                },
                {
                    key: "type",
                    dataIndex: "type",
                    title: t("type"),
                    width: 200,
                    render: (value, record) => (
                        <Select
                            variant="borderless"
                            defaultValue={value}
                            options={absencesTypesOptions}
                            labelRender={(e) => t(e.label)}
                            optionRender={(e) => t(e.label)}
                            style={{
                                width: 200,
                            }}
                            onChange={(e) =>
                                editInline(record._id, {
                                    statut: record.statut,
                                    type: e,
                                })
                            }
                        />
                    ),
                    sorter: (a, b) => a.type.localeCompare(b.type),
                },
                {
                    key: "statut",
                    dataIndex: "statut",
                    title: t("statut"),
                    render: (value, record) => (
                        <Select
                            variant="borderless"
                            defaultValue={value}
                            options={absencesStatutOptions.map((e) => ({
                                value: e.value,
                                label: <Tag color={e.color}>{t(e.label)}</Tag>,
                            }))}
                            style={{
                                width: 150,
                            }}
                            onChange={(e) => editInline(record._id, { statut: e })}
                        />
                    ),
                    sorter: (a, b) => a.statut.localeCompare(b.statut),
                },
                {
                    key: "comment",
                    dataIndex: "comment",
                    title: t("commentaire"),
                    render: (value, record) => (
                        <Input.TextArea
                            rows={1}
                            style={{
                                fontSize: 12,
                            }}
                            onChange={debounce(
                                (e) =>
                                    editInline(record._id, {
                                        statut: record.statut,
                                        comment: e.target.value,
                                    }),
                                800,
                            )}
                            variant="borderless"
                            defaultValue={value}
                        />
                    ),
                },
                {
                    key: "action",
                    render: (_, e) => (
                        <Dropdown
                            placement="left"
                            trigger={["click"]}
                            menu={{
                                items: [
                                    {
                                        key: "delete",
                                        label: t("supprimer"),
                                        danger: true,
                                        icon: <MaterialIcons name="delete" size="large" />,
                                        onClick: () => deleteInline(e),
                                    },
                                    {
                                        key: "edit",
                                        label: t("modifier"),
                                        icon: <MaterialIcons name="edit" size="large" />,
                                        onClick: () => {
                                            const absenceStatut = absencesStatutOptions.find(
                                                (k) => k.value === e.statut,
                                            );
                                            addModalData({
                                                key: "editAbsence",
                                                title: (
                                                    <Flex gap="large" align="center">
                                                        <Text size="large" strong>
                                                            {t("modifierAbsence")}
                                                        </Text>
                                                        <Tag color={absenceStatut.color}>{t(absenceStatut.label)}</Tag>
                                                    </Flex>
                                                ),
                                                content: <ModalAbsence />,
                                                initialValues: e,
                                                handleConfirm: (values) => editInline(e._id, values),
                                            });
                                        },
                                    },
                                ],
                            }}>
                            <Button
                                shape="circle"
                                type="text"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}>
                                <MaterialIcons name="more_vert" />
                            </Button>
                        </Dropdown>
                    ),
                },
            ]}
        />
    );
};

export default TabAbsencesValidation;
