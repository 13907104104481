import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import InputCellTime, { getTotalWorkedHours } from "./InputCellTime";

import { Flex } from "antd";

const TableRowData = ({
    index,
    dataLine,
    daysInRange,
    isLineExpanded,
    setExpandedLines,
    setExpandedGroups,
    onEditInline,
    onAddInline,
    addMultiple,
    setAddMultiple,
    onDeleteInline,
    absences,
    getAbsences,
}) => {
    const isGroup = !!dataLine.isGroup;
    const isDetail = !!dataLine.isDetail;
    const { scheduleConfiguration } = useProfileStore();
    const { t } = useTranslation();

    const expandLine = useCallback(() => {
        if (isGroup)
            setExpandedGroups((expandedLines) =>
                expandedLines.some((k) => k === dataLine.uniqueId)
                    ? expandedLines.filter((k) => k !== dataLine.uniqueId)
                    : [...expandedLines, dataLine.uniqueId],
            );
        else
            setExpandedLines((expandedLines) =>
                expandedLines.some((k) => k === dataLine.uniqueId)
                    ? expandedLines.filter((k) => k !== dataLine.uniqueId)
                    : [...expandedLines, dataLine.uniqueId],
            );
    }, [setExpandedLines, isGroup]);

    const heightRow = " h-[40px]";

    return (
        <tr
            className={
                "group " +
                heightRow +
                //// INCASE Timesheet : Couleur des lignes "Groupes" et "Détails"
                (isGroup ? " bg-slate-100" : "")
                // +
                // (isDetail ? " bg-slate-100" : "")
            }>
            {/* COLONNE INDEX */}
            <td className="border px-3 text-2xs text-center">{index + 1}</td>

            {/* COLONNE DONNESS  */}
            {["userRender", "projectRender", "taskRender"].map((key, i) => {
                const isRecovery = dataLine?.mergedLines?.some((e) => e.entry?.isRecovery);
                const showExpand =
                    (!isRecovery && !isGroup && !isDetail && key === "taskRender") || (isGroup && dataLine[key]);
                const isClickable = showExpand;

                return (
                    <td
                        key={i + key}
                        className={
                            "text-2xs border px-3 max-w-[200px]" +
                            (isClickable ? " cursor-pointer" : "") +
                            (showExpand ? " font-bold" : "")
                        }
                        onClick={() => (isClickable ? expandLine() : null)}>
                        <div className="flex items-center">
                            {isRecovery && key === "taskRender" && (
                                <Flex gap="small" align="center">
                                    <MaterialIcons name="pending_actions" size="xs" type="secondary" />
                                    <Text size="small" bold type="secondary">
                                        {t("recuperationDheures")}
                                    </Text>
                                </Flex>
                            )}
                            {showExpand && (
                                <i
                                    className={
                                        "text-base material-icons notranslate duration-200" +
                                        (isLineExpanded ? " rotate-90" : "")
                                    }>
                                    navigate_next
                                </i>
                            )}
                            <span className="truncate">{dataLine[key]}</span>
                            {isGroup && dataLine[key] && (
                                <span className="font-bold ml-auto pl-4">{dataLine.mergedLines?.length || 0}</span>
                            )}
                        </div>
                    </td>
                );
            })}

            {/* COLONNE D'HEURES  */}
            {/* <td className="border px-3 text-2xs min-w-[65px]">{dataLine.HS}</td> */}
            {/* <td className="border px-3 text-2xs min-w-[65px]">{dataLine.HP}</td> */}

            {/* COLONNE TEMPS  */}
            <td className="border text-2xs w-full max-w-[0px]">
                <div className="overflow-x-scroll no-scrollbar h-full timeline-row">
                    <div className={"flex w-max" + heightRow}>
                        {daysInRange.map((day, i) => (
                            <InputCellTime
                                getAbsences={getAbsences}
                                isVacationUser={absences?.find((e) => {
                                    return (
                                        moment(day.date)
                                            .set({
                                                hour: 12,
                                            })
                                            .isAfter(moment(e.startDate).startOf("day")) &&
                                        moment(day.date)
                                            .set({
                                                hour: 12,
                                            })
                                            .isBefore(moment(e.endDate).endOf("day")) &&
                                        e.author === dataLine.userId
                                    );
                                })}
                                onAddInline={onAddInline}
                                key={i}
                                day={day}
                                dataLine={dataLine}
                                onEditInline={onEditInline}
                                addMultiple={addMultiple}
                                setAddMultiple={setAddMultiple}
                                onDeleteInline={onDeleteInline}
                            />
                        ))}
                    </div>
                </div>
            </td>

            {/* COLONNE TOTAL  */}
            <td className="text-2xs border px-3 text-right font-bold">
                {dataLine.total ||
                    (!isDetail &&
                        !isGroup &&
                        getTotalWorkedHours(
                            scheduleConfiguration,
                            dataLine.mergedLines.map((e) => e.entry),
                        ))}
            </td>
        </tr>
    );
};

export default TableRowData;
