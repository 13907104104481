import { useMediaPredicate } from "react-media-hook";

import Constants from "utils/constants";

import SideMenu from "components/nav/SideMenu";
import UserMenu from "components/nav/UserMenu";
import Notifications from "components/notification/Notifications";

import SearchBar from "./SearchBar";

import { Flex, theme } from "antd";

const DriveNav = ({
    profile,
    currentProject,
    refreshAction,
    userRoles,
    customerConfig,
    toolView,
}) => {
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const landscapeMobileMedia = useMediaPredicate(
        "(max-height: " + 300 + "px)",
    );

    const { token } = theme.useToken();

    return (
        <Flex
            component="nav"
            align="center"
            gap={12}
            style={{
                padding: lgMedia
                    ? `${token.paddingLG}px ${token.paddingLG}px ${0}px ${
                          token.paddingLG
                      }px`
                    : token.paddingXS,
            }}
        >
            {(!mdMedia || landscapeMobileMedia) && (
                <SideMenu
                    showDriveMenu={true}
                    profile={profile}
                    currentProject={currentProject}
                    refreshAction={refreshAction}
                    userRoles={userRoles}
                    customerConfig={customerConfig}
                    toolView={toolView}
                />
            )}

            <SearchBar />

            <div className="ml-auto relative flex">
                <Notifications profile={profile} />
                <UserMenu />
            </div>
        </Flex>
    );
};

export default DriveNav;
