import { useEffect, useState } from "react";

import AutomationForm from "./AutomationForm";
import AutomationsList from "./AutomationsList";

const TabAutomation = ({ view = "visa" }) => {
    const [isFormActive, setFormActive] = useState(false);
    const [editedAutomation, setEditedAutomation] = useState(null);

    useEffect(() => {
        if (editedAutomation) setFormActive(true);
    }, [editedAutomation]);

    useEffect(() => {
        if (!isFormActive) setEditedAutomation(null);
    }, [isFormActive]);

    return (
        <>
            {isFormActive ? (
                <AutomationForm
                    setFormActive={setFormActive}
                    editedAutomation={editedAutomation}
                    type={view}
                />
            ) : (
                <AutomationsList
                    setFormActive={setFormActive}
                    setEditedAutomation={setEditedAutomation}
                    type={view}
                />
            )}
        </>
    );
};

export default TabAutomation;
