import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import { usePlansStore } from "components/plans/PlansView";

import { theme } from "antd";

export default function MarkerNewItem({ form, scale }) {
    const { positionClick } = usePlansStore();
    const { token } = theme.useToken();
    const { t } = useTranslation();

    let markerScale = 4 - scale + 0.4;
    // Custom scale based on zoom level
    if (scale < 1) markerScale = 4 - scale + 1;
    else if (scale < 2) markerScale = 4 - scale - 0.4;
    else if (scale < 3) markerScale = 4 - scale;
    else if (scale < 5) markerScale = markerScale = 4 - scale + 0.4;

    return (
        <div
            style={{
                transform: "scale(" + markerScale + ")",
                position: "absolute",
                zIndex: 2,
                top: `${positionClick.y}%`,
                left: `${positionClick.x}%`,
                translate: "150% -200%",
            }}
            className={"duration-200"}
        >
            <MaterialIcons
                name={"sms"}
                type={"warning"}
                style={{
                    backgroundColor: token.colorPrimary + 33,
                }}
                className={"rounded "}
            />
        </div>
    );
}
