import { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import Constants from "utils/constants";

const DropDownButton = ({
    text,
    icon,
    items,
    isDisabled = false,
    isReversed = false,
    isTransparent = false,
    orientation = "bottom",
    customButton,
}) => {
    const smMedia = useMediaPredicate("(min-width: " + Constants.smSize + ")");

    const refDropdown = useRef(null);
    const [isShowContent, showContent] = useState(false);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (refDropdown.current) {
            if (!refDropdown.current.contains(e.target))
                setTimeout(() => showContent(false));
            else showContent(true);
        }
    };

    return (
        <div ref={refDropdown} className="relative text-center">
            {customButton ? (
                customButton
            ) : (
                <button
                    className={
                        "focus:outline-none font-bold focus:ring-blue-300 rounded-lg text-sm py-2 px-4 text-center inline-flex items-center border-2 border-primary dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" +
                        (isDisabled
                            ? " bg-blue-900 text-slate-200 hover:cursor-default"
                            : isReversed
                              ? " bg-white text-blue-700 hover:bg-secondary hover:border-secondary hover:text-white"
                              : isTransparent
                                ? " bg-transparent border-transparent text-primary hover:bg-slate-200"
                                : " bg-primary text-white hover:bg-secondary hover:border-secondary")
                    }
                    type="button"
                >
                    {smMedia || !icon ? (
                        text
                    ) : (
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "1.1rem" }}
                        >
                            {icon}
                        </i>
                    )}
                    <i
                        className="material-icons notranslate"
                        style={{ fontSize: "1.3rem" }}
                    >
                        {orientation === "top" ? "expand_less" : "expand_more"}
                    </i>
                </button>
            )}

            {isShowContent && (
                <div
                    className={
                        "absolute z-10 bg-white rounded-lg shadow min-w-full" +
                        (orientation === "top"
                            ? " bottom-[100%]"
                            : orientation === "left"
                              ? " right-0"
                              : "")
                    }
                >
                    <ul
                        className="py-2 text-xs text-gray-700"
                        aria-labelledby="dropdownDefaultButton"
                    >
                        {items.map((item, i) => (
                            <li key={i}>
                                <button
                                    type="button"
                                    className="block w-full text-left px-2 py-3 sm:py-2 hover:bg-gray-100 whitespace-nowrap"
                                    onClick={(e) => {
                                        item.action(item.param && item.param);
                                        showContent(false);
                                    }}
                                >
                                    <div className="flex flex-row">
                                        {item.icon && (
                                            <div className="flex w-[24px] mr-2">
                                                {item.icon}
                                            </div>
                                        )}
                                        <div className="my-auto">
                                            {item.text}
                                        </div>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropDownButton;
