import i18n from "i18next";
import moment from "moment";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { DriveToolsContext } from "components/drive/Drive";
import IconButton from "components/input/IconButton";

const ActionPagination = () => {
    const { t } = useTranslation();

    const { toolsData, setToolsData } = useContext(DriveToolsContext);

    const pagination = toolsData.pagination;

    const setPagination = (newValue) => {
        setToolsData((e) => ({
            ...e,
            pagination: newValue,
        }));
    };

    useEffect(() => {
        if (!pagination) {
            setPagination(moment(new Date()).format("YYYY-MM-DD"));
        }
    }, [pagination]);

    const paginatePrev = () => {
        switch (toolsData?.filters?.dateRange) {
            case "byDay":
                setPagination(
                    moment(pagination).subtract(1, "day").format("YYYY-MM-DD"),
                );
                break;
            case "byWeek":
                setPagination(
                    moment(pagination).subtract(1, "week").format("YYYY-MM-DD"),
                );
                break;
            case "byMonth":
                setPagination(
                    moment(pagination)
                        .subtract(1, "month")
                        .format("YYYY-MM-DD"),
                );
                break;
            default:
                return <div>/</div>;
        }
    };
    const paginateNext = () => {
        switch (toolsData?.filters?.dateRange) {
            case "byDay":
                setPagination(
                    moment(pagination).add(1, "day").format("YYYY-MM-DD"),
                );
                break;
            case "byWeek":
                setPagination(
                    moment(pagination).add(1, "week").format("YYYY-MM-DD"),
                );
                break;
            case "byMonth":
                setPagination(
                    moment(pagination).add(1, "month").format("YYYY-MM-DD"),
                );
                break;
            default:
                return <div>/</div>;
        }
    };

    const isLastPage = pagination === moment().format("YYYY-MM-DD");

    return (
        <div className="flex items-center">
            <div className="rotate-180">
                <IconButton
                    isNeutral
                    isReversed
                    isBgTransparent
                    removePadding
                    icon="navigate_next"
                    onClick={paginatePrev}
                />
            </div>
            <div>
                <IconButton
                    isDisabled={isLastPage}
                    isNeutral
                    isReversed
                    isBgTransparent
                    removePadding
                    icon="navigate_next"
                    onClick={paginateNext}
                />
            </div>

            <span className="capitalize ml-2 w-max font-bold">
                <RenderTextPagination />
            </span>
        </div>
    );
};

export const RenderTextPagination = () => {
    const { toolsData } = useContext(DriveToolsContext);

    const time = moment(toolsData?.pagination).locale(i18n.locale);

    switch (toolsData?.filters?.dateRange) {
        case "byDay":
            return new Date(toolsData?.pagination).toLocaleString("fr-FR", {
                weekday: "long",
                day: "numeric",
                month: "long",
            });
        case "byWeek":
            return (
                time.weekday(0).format("DD MMMM") +
                " - " +
                time.weekday(6).format("DD MMMM")
            );
        case "byMonth":
            return time.format("MMMM") + " " + time.format("YYYY");

        default:
            return "/";
    }
};

export default ActionPagination;
