import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { XhrHttpHandler } from "@aws-sdk/xhr-http-handler";

import Constants from "utils/constants";

const handler = new XhrHttpHandler({
    // requestTimeout
});

// Fonction pour upload un fichier vers S3
export const uploadToS3 = async (bucket, fileName, file, readStream) => {
    const s3Client = new S3Client({
        region: Constants.awsRegion,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
        requestHandler: handler,
        // HACK
        // Explication :
        // https://github.com/cloudflare/cloudflare-docs/pull/19236
        // https://github.com/aws/aws-sdk-js-v3/issues/6810
        responseChecksumValidation: "WHEN_REQUIRED",
        requestChecksumCalculation: "WHEN_REQUIRED",
    });

    try {
        // Init Stream Progress Object
        const parallelUploads3 = new Upload({
            client: s3Client,
            params: {
                Bucket: bucket,
                Key: fileName,
                Body: file,
                // Metadata: {
                //     // custom metadatas !
                //     thumbnail: thumbnail.toString("base64"),
                // },
            },
            leavePartsOnError: false,
            queueSize: 8,
            // Chunks 5MB Minimum
            // DOC : https://docs.aws.amazon.com/AmazonS3/latest/userguide/qfacts.html
            // DOC : https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/Package/-aws-sdk-lib-storage/
            // FIX XHR Request : https://www.npmjs.com/package/@aws-sdk/xhr-http-handler
            partSize: 1024 * 1024 * 5,
        });

        // Stream Progress
        if (readStream) {
            let startTime = new Date().getTime();
            let endTime = new Date().getTime();
            let downloadedBytes = 0;
            let secondsRemaining = 0;
            let percentage = 1;
            let speedNums = [];
            let progressTotal = 0;
            let progressLoaded = 0;

            const abortStream = async () => {
                await parallelUploads3.abort();
                clearInterval(intervalStream);
                return true;
            };

            const intervalStream = setInterval(() => {
                const numberPayload = 10;
                // Calcul de la vitesse de téléchargement
                const speed = progressLoaded - downloadedBytes;
                if (speed < 50) return;
                speedNums.push(progressLoaded - downloadedBytes);
                const speedBytesBySeconds =
                    (speedNums.length < numberPayload
                        ? speedNums
                        : speedNums.slice(-numberPayload)
                    ).reduce((a, b) => a + b, 0) / speedNums.length;

                downloadedBytes = progressLoaded;
                const bytesRemaining = progressTotal - progressLoaded;
                if (bytesRemaining === 0) return;
                secondsRemaining = bytesRemaining / speedBytesBySeconds;

                readStream({
                    status: "processing",
                    percentage,
                    secondsRemaining,
                    abortStream,
                });
            }, 1000);

            parallelUploads3.on("httpUploadProgress", (progress) => {
                // Hack : Skip loaded chunk ( not downloaded bytes )
                endTime = new Date().getTime();
                if (endTime - startTime < 30) {
                    startTime = endTime;
                    return;
                }
                startTime = endTime;

                percentage = Math.ceil((progress.loaded / progress.total) * 95);
                progressTotal = progress.total;
                progressLoaded = progress.loaded;
            });

            const result = await parallelUploads3.done();
            clearInterval(intervalStream);
            return result;
        }
        return await parallelUploads3.done();
    } catch (err) {
        throw err;
    }
};
