import { useSortable } from "@dnd-kit/sortable";
import axios from "axios";
import moment from "moment";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { getProjectparentsList } from "helpers/project";
import { capitalize } from "helpers/string";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import {
    Button as AntdButton,
    Select as AntdSelect,
    Card,
    Col,
    Flex,
    Input,
    Row,
    Select,
    theme,
} from "antd";

const ActionNomenclatureLine = ({
    index = 0,
    action,
    updateAction,
    deleteAction,
    orderAction,
    actionConstraints,
    canMove,
    folderId,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const selectedConstaint = actionConstraints.find(
        (e) => e.val === action.constraint,
    );

    const { listeners } = useSortable({ id: String(index), data: {} });

    if (selectedConstaint)
        return (
            <Flex gap={token.paddingLG}>
                <Text
                    bold={index === 0}
                    style={{
                        padding: mdMedia
                            ? token.paddingLG
                            : `${token.paddingLG}px 0`,
                        width: mdMedia ? 150 : "auto",
                    }}
                >
                    {index === 0 ? t("alors") : capitalize(t("et"))}
                    {/* + " " + t("ajoute")} */}
                </Text>

                <Card
                    style={{
                        flex: 1,
                        maxWidth: "100%",
                        backgroundColor: token.colorPrimaryBg + "44",
                    }}
                >
                    <Row gutter={[token.padding, token.paddingLG]}>
                        <Col xl={6} lg={12} span={24}>
                            <AntdSelect
                                name="constraint"
                                style={{ width: "100%" }}
                                options={actionConstraints.map((e) => ({
                                    value: e.val,
                                    label: e.label,
                                }))}
                                value={action.constraint}
                                onChange={(e) => {
                                    if (
                                        actionConstraints.find(
                                            (k) => k.val === e,
                                        ).code === "FILENAME_LIST" &&
                                        !Array.isArray(action.nomenclature)
                                    )
                                        updateAction(index, "nomenclature", []);

                                    if (
                                        (actionConstraints.find(
                                            (k) => k.val === e,
                                        ).code === "FILENAME_DATE" ||
                                            actionConstraints.find(
                                                (k) => k.val === e,
                                            ).code ===
                                                "FILENAME_DATE_SELECT") &&
                                        Array.isArray(action.nomenclature)
                                    )
                                        updateAction(
                                            index,
                                            "nomenclature",
                                            "YYYYMMDD",
                                        );
                                    if (
                                        actionConstraints.find(
                                            (k) => k.val === e,
                                        ).code === "FILENAME_CUSTOM"
                                    )
                                        updateAction(index, "nomenclature", "");

                                    updateAction(index, "constraint", e);
                                }}
                            />
                        </Col>
                        <Col xl={6} lg={12} span={24}>
                            <DescNomenclature
                                index={index}
                                selectedConstaint={selectedConstaint}
                                action={action}
                                updateAction={updateAction}
                            />
                        </Col>
                        <Col xl={6} lg={12} span={24}>
                            <DataNomenclature
                                index={index}
                                action={action}
                                selectedConstaint={selectedConstaint}
                                updateAction={updateAction}
                                folderId={folderId}
                            />
                        </Col>
                        <Col xl={6} lg={12} span={24}>
                            {canMove && (
                                <div className="ml-auto flex gap-2 w-[60px]">
                                    <AntdButton
                                        shape="circle"
                                        onClick={(e) => deleteAction(index)}
                                        type="text"
                                    >
                                        <MaterialIcons name="delete" />
                                    </AntdButton>

                                    <div>
                                        <AntdButton
                                            {...listeners}
                                            style={{
                                                cursor: "grab",
                                            }}
                                            shape="circle"
                                            type="text"
                                        >
                                            <MaterialIcons name="drag_indicator" />
                                        </AntdButton>
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Card>
            </Flex>
        );
};

const DescNomenclature = ({
    index,
    action,
    selectedConstaint,
    updateAction,
}) => {
    const { t } = useTranslation();
    if (
        selectedConstaint.code === "FILENAME_CUSTOM" ||
        selectedConstaint.code === "FILENAME_SET" ||
        selectedConstaint.code === "FILENAME_LIST"
    ) {
        return (
            <Input
                name="description"
                placeholder={t("entrezLaDescription")}
                required
                value={action.description}
                onChange={(e) => {
                    updateAction(index, "description", e.target.value);
                }}
            />
        );
    }
    if (selectedConstaint.code === "FILENAME_INDEX_NUMBER") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("format") + " : 0000 / 0001 / 0011 / 9999"}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_INDEX_ALPHA") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("format") + " : 0 / A / Z / AA / AB / ZZ / AAA / AAB"}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_GED") {
        return (
            <span className="text-xs text-slate-400 my-auto ml-auto">
                {t("commencerLincrementationA")}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_FILE") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("nomDuFichierEstConserve")}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_FOLDER") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("titreDeVotreDossier")}
            </span>
        );
    }
    if (
        selectedConstaint.code === "FILENAME_DATE_SELECT" ||
        selectedConstaint.code === "FILENAME_DATE"
    ) {
        return (
            <Select
                defaultActiveFirstOption
                style={{ width: "100%" }}
                placeholder={t("format")}
                // defaultValue="YYYYMMDD"
                value={action.nomenclature || "YYYYMMDD"}
                onChange={(e) => {
                    updateAction(index, "nomenclature", e);
                }}
                options={[
                    {
                        value: "YYYYMMDD",
                        label: `YYYYMMDD ( ex : ${moment().format("YYYYMMDD")} )`,
                    },
                    {
                        value: "YYMMDD",
                        label: `YYMMDD ( ex : ${moment().format("YYMMDD")} )`,
                    },
                ]}
            />
        );
    }

    return <span className="text-xs text-slate-400 my-auto"></span>;
};

const DataNomenclature = ({
    index,
    action,
    selectedConstaint,
    updateAction,
    folderId,
}) => {
    const { t } = useTranslation();

    const [currentGED, setCurrentGED] = useState(0);

    useEffect(() => {
        if (selectedConstaint.code === "FILENAME_GED" && folderId) {
            const getGEDDatas = async () => {
                const parents = await getProjectparentsList(folderId);

                if (parents.length > 0) {
                    const rootProject = parents.find(
                        (e) => e.parentProject === undefined,
                    );
                    setCurrentGED(rootProject.indexGED);
                } else {
                    const resFolder = await axios.get(
                        apiURL.getProjectById + folderId,
                    );

                    if (resFolder?.data) setCurrentGED(resFolder.data.indexGED);
                }
            };

            getGEDDatas();
        }
    }, [folderId, selectedConstaint.code]);

    if (selectedConstaint.code === "FILENAME_CUSTOM") {
        return (
            <Input
                name="nomenclature"
                value={action.nomenclature}
                placeholder={t("texte")}
                onChange={(e) => {
                    updateAction(index, "nomenclature", e.target.value);
                }}
            />
        );
    }
    if (selectedConstaint.code === "FILENAME_SET") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("ceChampSeraRempliLibrementDuDepotDuFichier")}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_GED") {
        return (
            <Input
                name="nomenclature"
                type="number"
                value={currentGED.toString().padStart(6, "0")}
                // onChange={(e) => {
                //     updateAction(index, "nomenclature", e.target.value);
                // }}
                disabled
            />
        );
    }
    if (selectedConstaint.code === "FILENAME_LIST") {
        return (
            <Select
                mode="tags"
                style={{
                    width: "100%",
                }}
                onChange={(e) => {
                    const sanitized = e.map((option) =>
                        option.replace(/_/g, " "),
                    );
                    updateAction(index, "nomenclature", sanitized);
                }}
                value={action.nomenclature}
                tokenSeparators={[","]}
                placeholder={t("veuillezDefinirDesOptions")}
                options={[]}
                showSearch={false}
                suffixIcon={null}
                optionRender={(e) => {
                    if (
                        action.nomenclature &&
                        action.nomenclature.includes(e.value)
                    )
                        return e.value;
                    return t("ajouter") + " " + e.value;
                }}
                notFoundContent={
                    <Flex>
                        <Text size="sm">
                            {t(
                                "renseignezVotreOptionEtAppuyezSurLaToucheEntree",
                            )}
                        </Text>
                    </Flex>
                }
            />
        );
    }
    if (selectedConstaint.code === "FILENAME_DATE_SELECT") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("permetAlutilisateurDeChoisirUneDateViaUnCalendrier")}
            </span>
        );
    }
    if (selectedConstaint.code === "FILENAME_DATE") {
        return (
            <span className="text-xs text-slate-400 my-auto">
                {t("genereAutomatiquementLaDateEnFonctionDuJourDeDepose")}
            </span>
        );
    }
    return <div></div>;
};

export default ActionNomenclatureLine;
