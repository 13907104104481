import axios from "axios";
import DOMPurify from "dompurify";
import i18n from "i18next";

import apiURL from "utils/apiURL";

export async function getTextByCode(code) {
    try {
        const text = await axios.get(apiURL.getTextByCode + code);

        if (text.data) return text.data;
        else return null;
    } catch (err) {
        return null;
    }
}

export async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand("copy", true, text);
    }
}

export async function getMetaTitleByCode(code) {
    try {
        const text = await axios.get(apiURL.getTextByCode + code);

        if (text.data) return text.data["title" + i18n.locale];
        else return "";
    } catch (err) {
        return "";
    }
}

// Remplace les mentions (@[email@test.com]) par du HTML dans un texte
export function formatMentions(text) {
    // Define a regular expression to match mentions: @[email@test.com]
    const mentionRegex = /\@\[([^\]]+)\]/g;

    // Define a regular expression to match URLs not already inside an <a> tag
    const urlRegex = /(?<!href=['"])(https?:\/\/[^\s<]+)/g;

    // Replace mentions
    let replacedText = text.replace(
        mentionRegex,
        '<span class="font-bold">@$1</span>',
    );

    // Replace URLs not already in <a> tags
    replacedText = replacedText.replace(
        urlRegex,
        '<a href="$1" target="_blank" class="text-primary" rel="noopener noreferrer">$1</a>',
    );

    // Sanitize the resulting HTML
    const sanitizedHtml = DOMPurify.sanitize(replacedText, {
        ALLOWED_TAGS: ["span", "a", "b", "br"], // Add more tags if necessary
        ALLOWED_ATTR: ["class", "href", "target", "rel"], // Add more attributes if necessary
    });

    return sanitizedHtml;
}
