import axios from "axios";

import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import { DriveToolsContext } from "components/drive/Drive";
import ButtonDelete from "components/tableInline/ButtonDelete";
import CustomOptionsList from "components/tableInline/CustomOptionsList";
import CustomTimeCondition from "components/tableInline/CustomTimeCondition";
import TableInline from "components/tableInline/Table";

const CustomFieldsTab = () => {
    const { t } = useTranslation();
    const { setToolsData } = useContext(DriveToolsContext);

    const getDataList = useCallback(async () => {
        const { data } = await axios.get(apiURL.getTimesheetFields);
        if (data)
            setToolsData((e) => ({
                ...e,
                fields: data,
            }));
        return data;
    }, []);

    const addInline = useCallback(async (field) => {
        const { data } = await axios.post(apiURL.createTimesheetField, {
            title: field.title || "Nouveau champ",
            type: "TEXT",
            options: [],
            timeCondition: null,
        });
        setToolsData((e) => ({
            ...e,
            fields: [...e.fields, data],
        }));
        return data;
    }, []);

    const editInline = useCallback(async (itemId, newItem) => {
        const { data } = await axios.patch(
            apiURL.updateTimesheetField + itemId,
            {
                title: newItem.title,
                type: newItem.type,
                options: newItem.options || [],
                timeCondition:
                    newItem.timeCondition === true ||
                    newItem.timeCondition === null
                        ? null
                        : newItem.timeCondition,
                defaultValue: newItem.defaultValue || null,
            },
        );
        setToolsData((e) => ({
            ...e,
            fields: e.fields.map((k) =>
                k._id === itemId ? { ...k, ...newItem } : k,
            ),
        }));
        return data;
    }, []);

    const changeOrderFields = useCallback(async (fields, newList) => {
        const data = await axios.post(apiURL.orderTimesheetField, {
            fields,
        });
        setToolsData((e) => ({
            ...e,
            fields: newList,
        }));
        return data;
    }, []);

    const getTypes = useCallback(() => {
        return [
            { val: "TEXT", label: t("texte"), iconMaterialKey: "text_fields" },
            {
                val: "NUMBER",
                label: t("numero"),
                iconMaterialKey: "123",
            },
            {
                val: "UNISELECT",
                label: t("selectionSimple"),
                iconMaterialKey: "radio_button_checked",
                customOptionsComponent: CustomOptionsList,
            },
            {
                val: "MULTISELECT",
                label: t("selectionMultiple"),
                iconMaterialKey: "checklist",
                customOptionsComponent: CustomOptionsList,
            },
            {
                val: "LIST",
                label: t("listeDeroulante"),
                iconMaterialKey: "expand_more",
                customOptionsComponent: CustomOptionsList,
            },
            {
                val: "CHECKBOX",
                label: t("caseACocher"),
                iconMaterialKey: "check_box_outline_blank",
            },
        ];
    }, []);

    const getConditions = useCallback(() => {
        return [
            {
                val: null,
                label: t("sansConditions"),
                iconMaterialKey: "timer_off",
            },
            {
                val: true,
                label: t("conditionsMultiples"),
                iconMaterialKey: "timer",
                customOptionsComponent: CustomTimeCondition,
            },
        ];
    }, []);

    const getTypeDefautlValue = useCallback((dataLine) => {
        switch (dataLine?.type) {
            case "TEXT":
                return false;
            case "NUMBER":
                return false;
            case "UNISELECT":
            case "LIST":
                return dataLine.options.map((k) => ({
                    val: k,
                    label: k,
                }));
            case "MULTISELECT":
                return dataLine.options.map((k) => ({
                    val: k,
                    label: k,
                }));
            case "CHECKBOX":
                return [
                    {
                        val: false,
                        label: "Non-cochée",
                    },
                    {
                        val: true,
                        label: "Cochée",
                    },
                ];
            default:
                return false;
        }
    }, []);

    return (
        <TableInline
            getFunction={getDataList}
            addFunction={addInline}
            editFunction={editInline}
            orderingFunction={changeOrderFields}
            ButtonOptions={ButtonOptions}
            fields={[
                {
                    key: "title",
                    label: t("titre"),
                    width: "25%",
                },
                {
                    key: "type",
                    label: t("type"),
                    getOptions: getTypes,
                    width: "25%",
                },
                {
                    key: "timeCondition",
                    label: t("conditionDeTemps"),
                    getOptions: getConditions,
                    width: "25%",
                },
                {
                    key: "defaultValue",
                    label: t("valeurParDefault"),
                    getOptions: getTypeDefautlValue,
                    multiple: (value) => value === "MULTISELECT",
                    width: "25%",
                },
            ]}
        />
    );
};

const ButtonOptions = ({ dataLine, setDataList, setLoader }) => {
    const { setToolsData } = useContext(DriveToolsContext);

    const deleteInline = useCallback(async () => {
        setLoader(true);
        await axios.delete(apiURL.removeTimesheetField + dataLine._id);
        setToolsData((e) => ({
            ...e,
            fields: e.fields
                .filter((k) => k._id !== dataLine._id)
                .map((k, i) => ({ ...k, order: i + 1 })),
        }));
        setDataList((e) =>
            e
                .filter((k) => k._id !== dataLine._id)
                .map((k, i) => ({ ...k, order: i + 1 })),
        );
        setLoader(false);
    }, [dataLine]);

    return <ButtonDelete deleteFunction={deleteInline} />;
};

export default CustomFieldsTab;
