import axios from "axios";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import apiURL from "utils/apiURL";

export const useTimesheetStore = create(
    persist(
        (set, get) => ({
            // DATA
            loading: true,

            // Preferences
            groupBy: [],
            pagination: new Date(),

            // Admin data
            listEntries: [],
            listAbsences: [],

            // User data
            entry: null,
            adminProjects: [],
            adminTasks: [],
            adminMembers: [],

            // userProjects: [],
            // userTasks: [],
            // userFields: [],
            // userTeam: [],
            // dateRange: "month", // month, week, day
            // dateStart: new Date(),

            // ACTIONS
            // setStoreDateRange: async (value) => {
            //     set({ dateRange: value });
            // },
            // setStoreDateStart: async (value) => {
            //     set({ dateStart: value });
            // },

            // setMyAbsences: async () => {
            //     const { data } = await axios.get(apiURL.getAbsences);
            //     set({ myAbsences: data });
            // },

            setStoreGroups: async (groupBy) => {
                set({ groupBy });
            },
            setPagination: async (pagination) => {
                set({ pagination });
            },

            getUserProjects: async () => {
                // const { data } = await axios.get(apiURL.getTimesheetProjects);
                const { data } = await axios.get(apiURL.getAllowedProjectTasks);
                set({ userProjects: data });
                return data;
            },
            getAdminProjects: async () => {
                const { data } = await axios.get(apiURL.getTimesheetProjects);
                set({ adminProjects: data });
                return data;
            },
            getAdminTasks: async () => {
                const { data } = await axios.get(apiURL.getTimesheetTasks);
                set({ adminTasks: data });
                return data;
            },
            getAdminMembers: async () => {
                const { data } = await axios.get(apiURL.getTeamTimesheetProject);
                set({ adminMembers: data });
                return data;
            },

            setEntry: async (entry) => {
                set({ entry });
            },
            patchEntry: async (entry) => {
                set({ ...get().entry, ...entry });
            },
        }),
        {
            name: "store-timesheet",
            storage: createJSONStorage(() => localStorage),
        },
    ),
);
