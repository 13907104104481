import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useProfileStore } from "providers/ProviderProfile";

import { memo, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { fieldsTypes } from "helpers/forms";
import { generateRandomToken } from "helpers/token";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { BooleanTemplate } from "../fields/Boolean";
import { DateTemplate } from "../fields/Date";
import { NumberTemplate } from "../fields/Number";
import { ParagraphTemplate } from "../fields/Paragraph";
import { PictureTemplate } from "../fields/Picture";
import { ReferenceTemplate } from "../fields/Reference";
import { SelectCheckboxTemplate } from "../fields/SelectCheckbox";
import { SelectListTemplate } from "../fields/SelectList";
import { SelectRadioTemplate } from "../fields/SelectRadio";
import { SeparatorTemplate } from "../fields/Separator";
import { SignatureTemplate } from "../fields/Signature";
import { StateTemplate } from "../fields/State";
import { SubtitleTemplate } from "../fields/Subtitle";
import { TextLongTemplate } from "../fields/TextLong";
import { TextShortTemplate } from "../fields/TextShort";
import { TimeTemplate } from "../fields/Time";
import { TitleTemplate } from "../fields/Title";
import { WeatherTemplate } from "../fields/Weather";
import { ZoneTemplate } from "../fields/Zone";
import { getColorGroup } from "./RenderFieldItem";

import { Button, Card, Col, Collapse, Flex, Form, Row, Select, Switch, theme } from "antd";

export default function RenderFormItem({ index, add, remove }) {
    const { token } = theme.useToken();
    const form = Form.useFormInstance();
    const values = form.getFieldValue("fields");
    const value = values[index];
    const { type, title, description, required, requiredUser, _id } = value;
    const { customerConfig } = useProfileStore();
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: _id });
    const [open, setOpen] = useState(Boolean(required || requiredUser));
    const { addModalData } = useContext(ModalContext);
    const { wrapperSyncElement } = useContext(PopupContext);
    const { t } = useTranslation();

    const fieldType = fieldsTypes.find((field) => field.code === type);

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.4 : 1,
    };

    const color = useMemo(() => {
        return getColorGroup(fieldType.group, token);
    }, [fieldType, token]);

    const canBeRequired =
        type !== "TITLE" && type !== "SUBTITLE" && type !== "PARAGRAPH" && type !== "SEPARATOR" && type !== "BOOLEAN";

    if (!fieldType) return <>Champ inconnu</>;
    return (
        <Card data-id={_id} size="small" ref={setNodeRef} style={style} {...attributes} className="group">
            <Row gutter={[token.padding, token.padding]}>
                <Col span={24}>
                    <Collapse
                        ghost
                        activeKey={open ? "1" : false}
                        bordered={false}
                        items={[
                            {
                                styles: {
                                    header: {
                                        padding: 0,
                                    },
                                    body: {
                                        padding: 0,
                                    },
                                },
                                key: "1",
                                showArrow: false,
                                label: (
                                    <Flex gap={12} align="center" wrap>
                                        <MaterialIcons size="lg" type={color} name={fieldType.icon} />
                                        <Text
                                            style={{
                                                marginRight: "auto",
                                            }}
                                            bold
                                            color={color}>
                                            {fieldType.name}
                                        </Text>
                                    </Flex>
                                ),
                                extra: (
                                    <Flex gap={12} align="center" wrap>
                                        {(fieldType.group === "input" || fieldType.group === "custom") && (
                                            <Button
                                                className="opacity-0 group-hover:opacity-100"
                                                type="text"
                                                onClick={() => {
                                                    setOpen(!open);
                                                }}>
                                                <MaterialIcons size="sm" name="settings" />
                                            </Button>
                                        )}

                                        <Button
                                            className="opacity-0 group-hover:opacity-100"
                                            type="text"
                                            onClick={async () => {
                                                const values = form.getFieldValue("fields");
                                                const value = values[index];
                                                await add(
                                                    {
                                                        ...value,
                                                        _id: generateRandomToken(10),
                                                    },
                                                    index + 1,
                                                );
                                            }}>
                                            <MaterialIcons size="sm" name="file_copy" />
                                        </Button>

                                        <Button
                                            className="opacity-0 group-hover:opacity-100"
                                            type="text"
                                            onClick={() => {
                                                remove(index);
                                            }}>
                                            <MaterialIcons size="sm" name="delete" type="error" />
                                        </Button>

                                        <Button
                                            className="opacity-0 group-hover:opacity-100"
                                            type="text"
                                            onMouseDown={() => {
                                                setOpen(false);
                                            }}
                                            {...listeners}>
                                            <MaterialIcons size="sm" name="drag_indicator" />
                                        </Button>
                                    </Flex>
                                ),
                                children: (
                                    <Row gutter={[token.padding, token.padding]}>
                                        {(fieldType.group === "input" || fieldType.group === "custom") && (
                                            <Col span={24} xl={12}>
                                                <Form.Item
                                                    name={[index, "requiredUser"]}
                                                    label="Assigner un utilisateur"
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                    layout="horizontal">
                                                    <Select
                                                        style={{
                                                            width: "100%",
                                                            maxWidth: "100%",
                                                        }}
                                                        allowClear
                                                        popupMatchSelectWidth={false}
                                                        options={[
                                                            ...customerConfig.forms.workerUsers,
                                                            ...customerConfig.forms.adminUsers,
                                                        ].map((user) => ({
                                                            label: (
                                                                <Flex gap="small" align="center">
                                                                    <Text>
                                                                        {user.firstname} {user.lastname}
                                                                    </Text>
                                                                    <Text type="secondary" size="sm">
                                                                        {user.email}
                                                                    </Text>
                                                                </Flex>
                                                            ),
                                                            value: user._id,
                                                        }))}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        )}

                                        {canBeRequired && (
                                            <Col span={24} xl={8}>
                                                <Form.Item
                                                    name={[index, "required"]}
                                                    label="Champ obligatoire"
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                    layout="horizontal">
                                                    <Switch size="small" />
                                                </Form.Item>
                                            </Col>
                                        )}
                                    </Row>
                                ),
                            },
                        ]}
                    />
                </Col>

                <Col span={24}>
                    <RenderField type={type} index={index} />
                </Col>
            </Row>
        </Card>
    );
}

const RenderField = memo(({ type, index }) => {
    switch (type) {
        case "TITLE":
            return <TitleTemplate index={index} />;
        case "SUBTITLE":
            return <SubtitleTemplate index={index} />;
        case "PARAGRAPH":
            return <ParagraphTemplate index={index} />;
        case "SEPARATOR":
            return <SeparatorTemplate index={index} />;
        case "TEXT_SHORT":
            return <TextShortTemplate index={index} />;
        case "TEXT_LONG":
            return <TextLongTemplate index={index} />;
        case "NUMBER":
            return <NumberTemplate index={index} />;
        case "DATE":
            return <DateTemplate index={index} />;
        case "TIME":
            return <TimeTemplate index={index} />;
        case "BOOLEAN":
            return <BooleanTemplate index={index} />;
        case "SELECT_CHECKBOX":
            return <SelectCheckboxTemplate index={index} />;
        case "SELECT_RADIO":
            return <SelectRadioTemplate index={index} />;
        case "SELECT_LIST":
            return <SelectListTemplate index={index} />;
        case "ZONE":
            return <ZoneTemplate index={index} />;
        case "PICTURE":
            return <PictureTemplate index={index} />;
        case "SIGNATURE":
            return <SignatureTemplate index={index} />;
        case "STATE":
            return <StateTemplate index={index} />;
        case "WEATHER":
            return <WeatherTemplate index={index} />;
        case "REFERENCE":
            return <ReferenceTemplate index={index} />;
        default:
            return <>Champ inconnu</>;
    }
});
