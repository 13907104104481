import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useSwipeable } from "react-swipeable";

import ConnectionAPIClient, {
    checkBIMCollabRole,
    isBIMCollabAvailable,
} from "helpers/bcf";
import Constants from "utils/constants";

import Button from "components/input/Button";
import InformationModal from "components/modal/InformationModal";
import Spinner from "components/utils/Spinner";

import CommentItem from "./CommentItem";
import CommentSearchBar from "./CommentSearchBar";
import { CommentsContext } from "./CommentsContent";
import CommentsFilters from "./CommentsFilters";

const CommentsList = ({
    profile,
    files,
    filesIds,
    toggleBcfTiles,
    isSearchVisible,
    searchedTerm = "",
    setSearchedTerm,
    commentsFilters,
    setCommentsFilters,
    onClickAdd,
    setListMode,
    showCommentForm,
    areMarkersVisible,
}) => {
    const { t } = useTranslation();

    const {
        comments,
        setComments,
        priorities,
        members,
        isTilesView,
        activeItem,
        setActiveItem,
        isListMode,
        isFromViewer,
        customerConfig,
        currentBIMCollabProject,
        bimCollabCommentsList,
        isLightViewer,
        currentBIMCollabUser,
        fetchComments,
        isCommentsLimitReached,
    } = useContext(CommentsContext);

    const apiClient = new ConnectionAPIClient(
        customerConfig ? customerConfig.BIMCollab.url : "",
    );

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const commentsLimit = 5;
    const [isSearching, setIsSearching] = useState(false);

    const [informationModal, setInformationModal] = useState(null);

    useEffect(() => {
        if (customerConfig === null) return;

        if (searchedTerm.length > 1) {
            if (isFromViewer && isBIMCollabAvailable(customerConfig)) {
                // BIM Collab
                const timeoutId = setTimeout(async () => {
                    setIsSearching(true);
                    const newComments = await apiClient.getFormattedTopics(
                        currentBIMCollabProject,
                        bimCollabCommentsList,
                        0,
                        searchedTerm,
                        commentsFilters,
                    );

                    setComments(newComments);
                    setIsSearching(false);
                }, 500);
                return () => clearTimeout(timeoutId);
            } else {
                // DB
                const timeoutId = setTimeout(async () => {
                    setIsSearching(true);

                    await fetchComments(0);

                    setIsSearching(false);
                }, 500);
                return () => clearTimeout(timeoutId);
            }
        } else if (
            searchedTerm.length === 0 &&
            isFromViewer &&
            !isBIMCollabAvailable(customerConfig)
        ) {
            const resetComments = async () => {
                setIsSearching(true);

                await fetchComments(0);

                setIsSearching(false);
            };

            resetComments();
        }
    }, [searchedTerm]);

    useEffect(() => {
        if (!isFromViewer || !commentsFilters) return;

        const applyTopicsFilters = async () => {
            setIsSearching(true);
            const newComments = await apiClient.getFormattedTopics(
                currentBIMCollabProject,
                bimCollabCommentsList,
                0,
                searchedTerm.length > 1 ? searchedTerm : "",
                commentsFilters,
            );

            setComments(
                newComments.sort((a, b) =>
                    a.BCFNumber < b.BCFNumber ? 1 : -1,
                ),
            );
            setIsSearching(false);
        };

        if (isBIMCollabAvailable(customerConfig)) applyTopicsFilters();
    }, [commentsFilters]);

    const handleSwipedLeft = () => {
        if (!mdMedia && activeItem < comments.length - 1) {
            setActiveItem(activeItem + 1);
        }
    };

    const handleSwipedRight = () => {
        if (!mdMedia && activeItem > 0) {
            setActiveItem(activeItem - 1);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleSwipedLeft,
        onSwipedRight: handleSwipedRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        trackTouch: true,
    });

    return (
        <Fragment>
            {isSearchVisible && isFromViewer && !isLightViewer && (
                <div className="flex flex-row">
                    {isTilesView && (
                        <button
                            type="button"
                            className="p-1 mx-2 mt-[10px]"
                            onClick={(e) => toggleBcfTiles()}
                            title={t("retour")}
                        >
                            <i
                                className="material-icons notranslate my-auto"
                                style={{ fontSize: "2.2rem" }}
                            >
                                aspect_ratio
                            </i>
                        </button>
                    )}

                    <div className="w-full flex flex-row md:max-w-[450px]">
                        <CommentSearchBar
                            term={searchedTerm}
                            setTerm={setSearchedTerm}
                            isTilesView={isTilesView}
                        />

                        <div className="my-auto mr-3">
                            <button
                                type="button"
                                className={
                                    "flex flex-row" +
                                    (isTilesView
                                        ? " ml-3 text-base"
                                        : " text-sm")
                                }
                                onClick={(e) =>
                                    setInformationModal({
                                        title: t("filtres"),
                                        content: (
                                            <CommentsFilters
                                                commentsFilters={
                                                    commentsFilters
                                                }
                                                setCommentsFilters={
                                                    setCommentsFilters
                                                }
                                                priorities={priorities}
                                                members={members.filter(
                                                    (member) => member.user,
                                                )}
                                                onClose={() =>
                                                    setInformationModal()
                                                }
                                            />
                                        ),
                                        closeReset: setInformationModal,
                                        customWidth: "max-w-[750px]",
                                    })
                                }
                            >
                                <i
                                    className="material-icons notranslate my-auto"
                                    style={{
                                        fontSize: isTilesView
                                            ? "1.5rem"
                                            : "1rem",
                                    }}
                                >
                                    filter_alt
                                </i>
                                <div className="my-auto">{t("filtres")}</div>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {!isTilesView &&
                comments.length > 0 &&
                !showCommentForm &&
                isFromViewer &&
                !isLightViewer && (
                    <button
                        type="button"
                        onClick={(e) => setListMode(!isListMode)}
                        className="p-1 m-2 flex flex-row text-sm"
                        title={t("modeDaffichage")}
                    >
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "1.3rem" }}
                        >
                            {isListMode ? "grid_view" : "view_list"}
                        </i>
                        <div className="ml-1">
                            {t(
                                isListMode
                                    ? "afficherEnCarte"
                                    : "afficherEnListe",
                            )}
                        </div>
                    </button>
                )}

            {isFromViewer &&
                !isLightViewer &&
                !isTilesView &&
                checkBIMCollabRole(
                    profile,
                    isFromViewer,
                    customerConfig,
                    "Editor",
                    currentBIMCollabUser,
                ) && (
                    <div className="inline-block m-3">
                        <Button
                            text={t("ajouterUnCommentaire")}
                            onClick={onClickAdd}
                            removePadding={true}
                        />
                    </div>
                )}

            {isSearching ? (
                <div className="relative z-0 mt-10">
                    <Spinner isSmall={true} />
                </div>
            ) : (
                <Fragment>
                    <div
                        className={
                            "pb-2 mt-8" +
                            (isFromViewer && !isTilesView
                                ? " mx-2 md:mx-4 pb-2"
                                : " text-left")
                        }
                    >
                        {(() => {
                            return comments.map((comment, i) => {
                                const isLastComment = i === comments.length - 1;

                                return (
                                    (mdMedia ||
                                        searchedTerm.length > 0 ||
                                        isListMode ||
                                        (!mdMedia && activeItem === i)) && (
                                        <div
                                            key={comment._id}
                                            {...handlers}
                                            className={
                                                "md:max-w-full" +
                                                (isTilesView
                                                    ? " mx-4 mb-8 inline-block align-top md:w-[300px]"
                                                    : " mx-auto lg:w-full mt-4") +
                                                (!isTilesView &&
                                                !isLastComment &&
                                                !comment.BCFContent
                                                    ? " border-b-2 pb-5"
                                                    : "")
                                            }
                                        >
                                            <CommentItem
                                                profile={profile}
                                                files={files}
                                                filesIds={filesIds}
                                                comment={comment}
                                                commentIndex={
                                                    !isFromViewer &&
                                                    comments.length - i
                                                }
                                                isFromMobile={!mdMedia}
                                            />
                                        </div>
                                    )
                                );
                            });
                        })()}
                    </div>
                    {fetchComments &&
                        !isFromViewer &&
                        !isCommentsLimitReached &&
                        comments.length >= commentsLimit && (
                            <button
                                type="button"
                                className="text-primary font-bold mt-2 text-sm"
                                onClick={(e) => fetchComments()}
                            >
                                {t("afficherPlus")}
                            </button>
                        )}
                </Fragment>
            )}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    isNoPadding={informationModal.isNoPadding}
                    customWidth={informationModal.customWidth}
                />
            )}
        </Fragment>
    );
};

export default CommentsList;
