import axios from "axios";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import { DriveToolsContext } from "components/drive/Drive";
import ButtonFilters from "components/filters/ButtonFilters";
import Spinner from "components/utils/Spinner";

import AutomationItem from "./AutomationItem";

import { Button, Flex } from "antd";

const AutomationsList = ({
    setFormActive,
    setEditedAutomation,
    type = "visa",
}) => {
    const { t } = useTranslation();

    const { setToolsData, toolsData } = useContext(DriveToolsContext);

    const [loader, setLoader] = useState(false);
    const [smallLoader, setSmallLoader] = useState(false);
    const [smallLoaderMore, setSmallLoaderMore] = useState(false);

    const [automations, setAutomations] = useState(null);

    const [automationsStep, setAutomationsStep] = useState(0);
    const [isAutomationsLimitReached, setAutomationsLimitReached] =
        useState(false);

    const limitNbAutomations = 30;

    useEffect(() => {
        getAutomations();
    }, [toolsData.filters.folders]);

    const getAutomations = async () => {
        setSmallLoader(true);

        try {
            const automationsRes = await axios.post(apiURL.getUserAutomations, {
                start: automationsStep,
                folders: toolsData.filters.folders.map((folder) => folder._id),
            });

            if (automationsRes && automationsRes.data) {
                setAutomations(
                    automationsRes.data.filter((e) =>
                        type === "visa"
                            ? !!e.actions[0]["visa"]
                            : !e.actions[0]["visa"],
                    ),
                );
            } else {
                setAutomations([]);
            }
        } catch (e) {
            setAutomations([]);
        }

        setSmallLoader(false);
    };

    const deleteAutomation = async (id) => {
        setLoader(true);

        try {
            const resDeleted = await axios.delete(apiURL.deleteAutomation + id);

            if (resDeleted && resDeleted.data) {
                toastAlert("success", t("lAutomatisationABienEteSupprimee"));
                setAutomations(
                    automations.filter((automation) => automation._id !== id),
                );
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    const handleScroll = async (e) => {
        if (automations.length < limitNbAutomations) return;

        if (
            e.target.scrollHeight - e.target.scrollTop ===
                e.target.clientHeight &&
            !isAutomationsLimitReached
        ) {
            setSmallLoaderMore(true);

            const automationsRes = await axios.post(apiURL.getUserAutomations, {
                start: automationsStep + 1,
                folders: toolsData.filters.folders.map((folder) => folder._id),
            });

            if (automationsRes && automationsRes.data) {
                if (automationsRes.data.length === 0)
                    setAutomationsLimitReached(true);
                else {
                    setAutomations((oldAutomations) => [
                        ...oldAutomations,
                        ...automationsRes.data,
                    ]);

                    setAutomationsStep(automationsStep + 1);
                }
            }

            setSmallLoaderMore(false);
        }
    };

    return (
        <>
            <Flex align="center">
                <ButtonFilters />
                <Button
                    type="primary"
                    style={{ marginLeft: "auto" }}
                    children={t("nouveauWorkflow")}
                    icon={<MaterialIcons name="add" />}
                    onClick={(e) => {
                        setFormActive(true);
                        setToolsData((e) => ({
                            ...e,
                            filtersOpen: false,
                        }));
                    }}
                />
                <div className="inline-block justify-end"></div>
            </Flex>
            <div className="">
                {smallLoader || !automations ? (
                    <div className="mt-10">
                        <Spinner isSmall={true} />
                    </div>
                ) : automations.length > 0 ? (
                    <Fragment>
                        <div className="relative">
                            <ul
                                className="mt-2 max-h-[calc(100vh-350px)] overflow-y-auto"
                                onScroll={(e) => handleScroll(e)}
                            >
                                {automations.map((automation) => (
                                    <AutomationItem
                                        key={automation._id}
                                        automation={automation}
                                        deleteAutomation={deleteAutomation}
                                        getAutomations={getAutomations}
                                        setEditedAutomation={
                                            setEditedAutomation
                                        }
                                    />
                                ))}
                            </ul>
                            {smallLoaderMore && (
                                <div className="absolute flex w-full bottom-0">
                                    <Spinner isSmall={true} />
                                </div>
                            )}
                        </div>
                    </Fragment>
                ) : (
                    <div className="text-center mt-10 mx-4  text-lg font-bold">
                        {t("aucuneAutomatisationDisponible")}
                    </div>
                )}
            </div>

            {loader && <Spinner />}
        </>
    );
};

export default AutomationsList;
