import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";
import Constants from "utils/constants";

import { DriveToolsContext } from "components/drive/Drive";
import Spinner from "components/utils/Spinner";

const TabConfiguration = () => {
    const { t } = useTranslation();

    const { scheduleConfiguration } = useProfileStore();

    return (
        <div className="border p-6 flex flex-col gap-8 pb-12">
            <div className="text-xs text-red-500">
                Seuls les utilisateurs avec l'adressse e-mail suivante peuvent
                voir cet onglet :
                <ul className="list-disc pl-8">
                    {[...Constants.demoBimonoMails].map((e) => (
                        <li key={e}>{e}</li>
                    ))}
                </ul>
            </div>

            {scheduleConfiguration ? (
                <div className="grid md:grid-cols-3 gap-20 w-full">
                    <div className="flex flex-col gap-8">
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">Samedi</h3>
                            <p>
                                {`Heure majorée à ${
                                    scheduleConfiguration.saturday?.modifier *
                                    100
                                }%`}
                            </p>
                            <p>
                                {`Heures travaillées le samedi iront dans les heures à payées ( HP )`}
                            </p>
                        </div>
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">Dimanche</h3>
                            <p>
                                {`Heure majorée à ${
                                    scheduleConfiguration.sunday?.modifier * 100
                                }%`}
                            </p>
                            <p>
                                {`Heures travaillées le dimanche iront dans les heures à payées ( HP )`}
                            </p>
                        </div>
                        <div>
                            <h3 className="text-lg font-bold">Saisons</h3>
                            {scheduleConfiguration.seasons?.map((e) => {
                                return (
                                    <div key={e._id} className="text-sm mb-4">
                                        <p className="text-slate-400">
                                            {`${t("nom")} : ${e.name}`}{" "}
                                        </p>
                                        <p className="text-slate-400">{`${t(
                                            "dateDeDebut",
                                        )} : ${moment(e.startDate).format(
                                            "DD/MM/YYYY",
                                        )}`}</p>
                                        <p className="text-slate-400">{`${t(
                                            "dateDeFin",
                                        )} : ${moment(e.endDate).format(
                                            "DD/MM/YYYY",
                                        )}`}</p>
                                        <p className="text-slate-400">{`${capitalize(
                                            t("temps"),
                                        )} max/jour : ${e.maxTime} h`}</p>
                                        <p>{`Dépassement du temps max/jour : Les heures travaillées en + iront dans les heures supplémentaires ( HS )`}</p>
                                        <p className="text-slate-400">{`Période de nuit : de ${e.nightTimeStart} à ${e.nightTimeEnd}`}</p>
                                        <p>{`Horaires de nuits : Les heures travaillées en horaires de nuit seront majorées à ${
                                            e.nightTimeModifier * 100
                                        }% et iront dans les heures supplémentaires ( HS )`}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">
                                Jours compensés
                            </h3>
                            <p>{`Heure non majorée`}</p>
                            <p>
                                {`Heures travaillées un jour compensé iront dans les heures supplémentaires ( HS )`}
                            </p>
                            {scheduleConfiguration.compensated?.map((e) => {
                                return (
                                    <p className="text-slate-400" key={e._id}>
                                        {`- ${moment(e.date).format(
                                            "DD/MM/YYYY",
                                        )} : ${e.name}`}
                                    </p>
                                );
                            })}
                        </div>
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">Jours fériés</h3>
                            <p>
                                {`Heure majorée à ${
                                    scheduleConfiguration.holidays?.[0]
                                        ?.modifier * 100
                                }%`}
                            </p>
                            <p>
                                {`Heures travaillées pendant un jour férié iront dans les heures à payées ( HP )`}
                            </p>
                            {scheduleConfiguration.holidays?.map((e) => {
                                return (
                                    <p className="text-slate-400" key={e._id}>
                                        {`- ${moment(e.date).format(
                                            "DD/MM/YYYY",
                                        )} : ${e.name}`}
                                    </p>
                                );
                            })}
                        </div>
                    </div>
                    <div className="flex flex-col gap-8">
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">
                                Vacances entreprises
                            </h3>
                            <p>{`Heure non majorée`}</p>
                            <p>
                                {`Heures travaillées pendant les vacances d'entreprises iront dans les heures supplémentaires ( HS )`}
                            </p>
                            {scheduleConfiguration.vacations?.map((e) => {
                                return (
                                    <p className="text-slate-400" key={e._id}>
                                        {`- Du ${moment(e.startDate).format(
                                            "DD/MM/YYYY",
                                        )} au ${moment(e.endDate).format(
                                            "DD/MM/YYYY",
                                        )} : ${e.name}`}
                                    </p>
                                );
                            })}
                        </div>
                        <div className="text-sm">
                            <h3 className="text-lg font-bold">
                                Vacances ouvrier
                            </h3>
                            <p>{`Heure non majorée`}</p>
                            <p>
                                {`Heures travaillées pendant les vacances d'entreprises iront dans les heures supplémentaires ( HS )`}
                            </p>
                            <p className="text-slate-400">
                                {`- Selon les demandes d'absences de l'ouvrier`}
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
                <Spinner isSmall />
            )}
        </div>
    );
};

export default TabConfiguration;
