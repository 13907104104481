import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { fromDateToStringDateHours } from "helpers/date";

import UserCircle from "components/user/UserCircle";

const ActivityItem = ({ item, activity, userRoles }) => {
    const { t } = useTranslation();

    const isInvisibleForUser = () => {
        if (
            item.files &&
            activity.type === "VIEWED_FILE" &&
            !userRoles.MODERATOR
        )
            return true;

        if (!item.files && activity.type === "VIEWED" && !userRoles.MODERATOR)
            return true;

        return false;
    };

    return (
        !isInvisibleForUser() && (
            <div className="border-b-2 py-2">
                <div className="flex flex-row">
                    <UserCircle
                        user={activity.user}
                        guestName={activity.guestName}
                    />
                    <div className="text-xs ml-2">
                        {item.files ? (
                            <Fragment>
                                <b>
                                    {activity.user
                                        ? activity.user.firstname +
                                          " " +
                                          activity.user.lastname
                                        : `${activity.guestName} ( ${t(
                                              "invite",
                                          )} )`}
                                </b>{" "}
                                {activity.type === "CREATE" ? (
                                    <Fragment>
                                        {t("aCreeLeDossier") + " "}
                                        <b>{activity.projectTitle}</b>
                                    </Fragment>
                                ) : activity.type === "UPDATE" ? (
                                    <Fragment>
                                        {t("aModifieLeDossier") + " "}
                                        <b>{activity.projectTitle}</b>
                                    </Fragment>
                                ) : activity.type === "DELETE" ? (
                                    <Fragment>
                                        {t("aSupprimeLeDossier") + " "}
                                        <b>{activity.projectTitle}</b>
                                    </Fragment>
                                ) : activity.type === "CREATE_FILE" ? (
                                    <Fragment>
                                        {t("aAjouteLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "UPDATE_FILE" ? (
                                    <Fragment>
                                        {t("aModifieLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "DELETE_FILE" ? (
                                    <Fragment>
                                        {t("aSupprimeLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "REPLACE_FILE" ? (
                                    <Fragment>
                                        {t("aRemplaceLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "COMMENT_FILE" ? (
                                    <Fragment>
                                        {t("aCommenteLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "CREATE_FILE_VERSION" ? (
                                    <Fragment>
                                        {t(
                                            "aAjouteUneNouvelleVersionAuFichier",
                                        ) + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "DELETE_FILE_VERSION" ? (
                                    <Fragment>
                                        {t("aSupprimeLaVersion") + " "}
                                        <b>{activity.fileTitle}</b>
                                        {!!activity.guestName && (
                                            <Fragment>
                                                {" " +
                                                    t("du") +
                                                    " " +
                                                    t("fichier") +
                                                    " "}
                                                <b>{activity.guestName}</b>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                ) : activity.type === "ADD_VISA_TO_FILE" ? (
                                    <Fragment>
                                        {t("aAjouteLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 border-2 border-primary text-primary">
                                            {activity.visa.title}
                                        </span>
                                        {" " + t("auFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "VIEWED_FILE" ? (
                                    <Fragment>
                                        {t("aConsulteLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <b>
                                    {activity.user
                                        ? activity.user.firstname +
                                          " " +
                                          activity.user.lastname
                                        : `${activity.guestName} ( ${t(
                                              "invite",
                                          )} )`}
                                </b>{" "}
                                {activity.type === "CREATE" ? (
                                    <Fragment>
                                        a créé le fichier{" "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "UPDATE" ? (
                                    <Fragment>
                                        a modifié le fichier{" "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "REPLACE" ? (
                                    <Fragment>
                                        a remplacé le fichier{" "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "COMMENT" ? (
                                    <Fragment>
                                        {t("aCommenteLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "CREATE_VERSION" ? (
                                    <Fragment>
                                        {t(
                                            "aAjouteUneNouvelleVersionAuFichier",
                                        ) + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "APPROVED_VISA" ? (
                                    <Fragment>
                                        {t("aApprouveLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 bg-emerald-400 border-2 border-emerald-400 text-white">
                                            {activity.visa.title}
                                        </span>
                                    </Fragment>
                                ) : activity.type === "REFUSED_VISA" ? (
                                    <Fragment>
                                        {t("aRefuseLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 bg-red-500 border-2 border-red-500 text-white">
                                            {activity.visa.title}
                                        </span>
                                    </Fragment>
                                ) : activity.type === "COMMENTED_VISA" ? (
                                    <Fragment>
                                        {t("aCommenteLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 bg-yellow-400 border-2 border-yellow-400 text-white">
                                            {activity.visa.title}
                                        </span>
                                    </Fragment>
                                ) : activity.type === "NOT_CONCERNED_VISA" ? (
                                    <Fragment>
                                        {t("nEstPasConcerneParLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 bg-fuchsia-500 border-2 border-fuchsia-500 text-white">
                                            {activity.visa.title}
                                        </span>
                                    </Fragment>
                                ) : activity.type === "ADD_VISA" ? (
                                    <Fragment>
                                        {t("aAjouteLeVisa") + " "}
                                        <span className="inline-block rounded-md text-2xs font-bold px-1 border-2 border-primary text-primary">
                                            {activity.visa.title}
                                        </span>
                                        {" " + t("auFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : activity.type === "VIEWED" ? (
                                    <Fragment>
                                        {t("aConsulteLeFichier") + " "}
                                        <b>{activity.fileTitle}</b>
                                    </Fragment>
                                ) : (
                                    ""
                                )}
                            </Fragment>
                        )}
                    </div>
                </div>

                {activity.visa?.comment && (
                    <p className="py-2 mt-2 text-xs">{activity.visa.comment}</p>
                )}

                <div className="text-2xs text-slate-400 mt-2">
                    {fromDateToStringDateHours(activity.date)}
                </div>
            </div>
        )
    );
};

export default ActivityItem;
