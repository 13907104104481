import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { fromMsToHoursMinutes, getDurationBetweenTwoDates } from "helpers/time";
import { absencesTypesOptions, getActualSeason, isLunchConcerned, isMorningConcerned } from "helpers/timesheet";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import Button from "components/input/Button";
import IconButton from "components/input/IconButton";
import ConfirmationModal from "components/modal/ConfirmationModal";
import { useTimesheetStore } from "components/timesheet/TimesheetStore";
import ModalAbsence from "components/timesheet/modalAbsence/ModalAbsence";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import ModalEntry from "../../modalEntry/ModalEntry";
import { applyConfiguration, getRealEndDate } from "./manageTable";

import { Badge, Dropdown, Flex, theme } from "antd";

const InputCellTime = ({
    dataLine,
    day,
    onEditInline,
    onAddInline,
    addMultiple,
    setAddMultiple,
    onDeleteInline,
    isVacationUser,
    getAbsences,
}) => {
    const { toolsData } = useContext(DriveToolsContext);
    const { groupBy } = useTimesheetStore();
    const { scheduleConfiguration } = useProfileStore();
    const { addModalData, removeModal } = useContext(ModalContext);
    const { startElementSyncMessage, successElementSyncMessage } = useContext(PopupContext);
    const actualSeason = getActualSeason(scheduleConfiguration);
    const { token } = theme.useToken();
    const dateRange = toolsData.filters.dateRange;
    const { t } = useTranslation();
    const [isOpen, setisOpen] = useState(false);

    const isGroup = !!dataLine.isGroup;
    const isDetail = !!dataLine.isDetail;
    const isSpecific = !!dataLine.isSpecific;

    const allItems = isGroup ? dataLine.mergedLines.map((e) => e.mergedLines).flat() : dataLine.mergedLines;

    const allItemsTime = allItems
        ?.filter((e) => moment(day.date).format("YYYY-MM-DD") === moment(e?.entry?.startDate).format("YYYY-MM-DD"))
        ?.map((e) => e.entry);

    const timeWorked = getResultCell({
        dataLine,
        day,
        configuration: scheduleConfiguration,
    });

    const canEdit = !isGroup && !isDetail && !!allItemsTime.length;
    const canAdd = !isGroup && !isDetail && allItemsTime.length === 0;
    const isWillAdd = addMultiple?.find((e) => e.uniqueId === dataLine.uniqueId && e.date === day.date);

    const hasComment =
        !!allItemsTime.find((e) =>
            e.data.find((k) => (k.title === "Commentaire" || k.title === "Commentaires") && !!k.value),
        ) &&
        !isDetail &&
        !isGroup;
    const isRecovery = dataLine.mergedLines.some((e) => e.entry?.isRecovery);

    return (
        <Dropdown
            trigger={canEdit || canAdd ? ["contextMenu"] : []}
            menu={{
                items: [
                    {
                        key: "entry",
                        label: (
                            <Flex gap={6} align="center">
                                <MaterialIcons name="add" />
                                <Text>
                                    {t("ajouter") + " " + (isRecovery ? t("recuperationDheures") : t("saisieDeTemps"))}
                                </Text>
                            </Flex>
                        ),
                        onClick: () => {
                            addModalData({
                                key: "modalEntry",
                                title: t("nouvelleSaisieDeTemps"),
                                isForm: false,
                                content: (
                                    <ModalEntry
                                        canSelectDate
                                        canSelectProject
                                        isFromAdmin
                                        onClose={async (entryData) => {
                                            removeModal("modalEntry");
                                            await onAddInline({
                                                ...entryData,
                                                author: dataLine.userId,
                                                timesheetTask: entryData?.timesheetTask?._id || dataLine.taskId,
                                                project: entryData?.project?._id || dataLine.projectId,
                                                timesheetTaskTitle:
                                                    entryData?.timesheetTask?.title || dataLine.taskRender,
                                            });
                                        }}
                                        onDelete={async (e) => {
                                            removeModal("modalEntry");
                                            await onDeleteInline(e);
                                        }}
                                        preData={{
                                            project: dataLine.projectId,
                                            timesheetTask: dataLine.taskId,
                                            isRecovery: isRecovery,
                                            forUsers: [dataLine.userId],
                                            startDate: moment(day.date)
                                                .set({
                                                    hours: parseInt(actualSeason.workDayStartHour.split(":")[0]),
                                                    minute: parseInt(actualSeason.workDayStartHour.split(":")[1]),
                                                    second: 0,
                                                })
                                                .toISOString(),
                                            endDate: moment(day.date)
                                                .set({
                                                    hours: parseInt(actualSeason.workDayEndHour.split(":")[0]),
                                                    minute: parseInt(actualSeason.workDayEndHour.split(":")[1]),
                                                    second: 0,
                                                })
                                                .toISOString(),
                                        }}
                                    />
                                ),
                            });
                        },
                    },
                    {
                        key: "absence",
                        label: (
                            <Flex gap={6} align="center">
                                <MaterialIcons name="add" />
                                <Text>{t("ajouter") + " " + t("absences")}</Text>
                            </Flex>
                        ),
                        onClick: () => {
                            addModalData({
                                key: "modalAbsence",
                                title: t("nouvelleDemande"),
                                initialValues: {
                                    startDate: moment(day.date).format("YYYY-MM-DD"),
                                    endDate: moment(day.date).add(3, "day").format("YYYY-MM-DD"),
                                },
                                content: <ModalAbsence />,
                                handleConfirm: async (values) => {
                                    startElementSyncMessage();
                                    await axios.post(apiURL.createAbsence, {
                                        user: dataLine.userId,
                                        ...values,
                                        statut: "ACCEPTED",
                                    });
                                    getAbsences();
                                    successElementSyncMessage();
                                },
                            });
                        },
                    },
                ],
            }}>
            <div
                key={dataLine.uniqueId + day.date}
                className={
                    "cellTime select-none group/time text-center text-2xs border-r flex items-center justify-center capitalize " +
                    (isOpen ? " bg-blue-300" : "") +
                    (canEdit || canAdd ? " cursor-pointer hover:bg-slate-200" : "") +
                    //  (!isDetail ? " font-bold" : "") +
                    (dateRange === "byDay" ? " w-[200px]" : "") +
                    (dateRange === "byWeek" ? " w-[74px]" : "") +
                    (dateRange === "byMonth" ? " w-[40px]" : "") +
                    (isWillAdd ? " border-2 border-blue-500" : "") +
                    (day.isToday
                        ? " bg-blue-100"
                        : //// INCASE Timesheet : Couleur des lignes "Groupes" et "Détails"
                          // isGroup
                          // ? " bg-slate-300"
                          // : isDetail
                          // ? " bg-slate-100"
                          // :
                          day.isWeekend
                          ? " bg-blue-50"
                          : !!day.isHoliday?.length
                            ? " bg-green-50"
                            : !!day.isCompendated?.length
                              ? " bg-yellow-50"
                              : !!day.isVacation
                                ? " bg-red-50"
                                : !!isVacationUser
                                  ? " bg-red-50"
                                  : "") +
                    (!!isVacationUser && !timeWorked ? " text-gray-300" : "")
                }
                onMouseDown={(e) => {
                    if (canAdd && e.button === 0) {
                        setAddMultiple([{ ...dataLine, ...day }]);
                    }
                }}
                onMouseUp={(e) => {
                    if (addMultiple && addMultiple.length > 0 && e.button === 0) {
                        const isRecovery = dataLine.mergedLines.some((e) => e.entry?.isRecovery);
                        const isOneEntry = addMultiple.length === 1;
                        const allSameProject =
                            addMultiple.every((e) => e.projectId === addMultiple[0].projectId) || isOneEntry;
                        const allSameDay =
                            addMultiple.every(
                                (e) =>
                                    moment(e.date).format("YYYY-MM-DD") ===
                                    moment(addMultiple[0].date).format("YYYY-MM-DD"),
                            ) || isOneEntry;

                        addModalData({
                            key: "modalEntry",
                            title: t("nouvelleSaisieDeTemps"),
                            isForm: false,
                            content: (
                                <ModalEntry
                                    isFromAdmin
                                    isMultiple={addMultiple.length > 1}
                                    canSelectDate={allSameDay}
                                    canSelectProject={allSameProject}
                                    onDelete={async (e) => {
                                        removeModal("modalEntry");
                                        await onDeleteInline(e);
                                    }}
                                    preData={{
                                        project: dataLine.projectId,
                                        timesheetTask: dataLine.taskId,
                                        isRecovery: isRecovery,
                                        startDate: moment(day.date)
                                            .set({
                                                hours: parseInt(actualSeason.workDayStartHour.split(":")[0]),
                                                minute: parseInt(actualSeason.workDayStartHour.split(":")[1]),
                                                second: 0,
                                            })
                                            .toISOString(),
                                        endDate: moment(day.date)
                                            .set({
                                                hours: parseInt(actualSeason.workDayEndHour.split(":")[0]),
                                                minute: parseInt(actualSeason.workDayEndHour.split(":")[1]),
                                                second: 0,
                                            })
                                            .toISOString(),
                                    }}
                                    onClose={async (entryData) => {
                                        await Promise.all(
                                            addMultiple.map((e) => {
                                                const isRecovery =
                                                    isOneEntry || e.mergedLines.some((e) => e.entry?.isRecovery);

                                                return onAddInline({
                                                    timesheetTask:
                                                        isRecovery && entryData.isRecovery
                                                            ? null
                                                            : isOneEntry
                                                              ? entryData.timesheetTask._id
                                                              : e.taskId,
                                                    project:
                                                        isRecovery && entryData.isRecovery
                                                            ? null
                                                            : isOneEntry
                                                              ? entryData.project._id
                                                              : e.projectId,
                                                    timesheetTaskTitle:
                                                        isRecovery && entryData.isRecovery
                                                            ? null
                                                            : isOneEntry
                                                              ? entryData.timesheetTask.title
                                                              : e.taskRender,
                                                    author: e.userId,
                                                    data: entryData.data,
                                                    breakLunch: entryData.breakLunch,
                                                    isRecovery: isRecovery && entryData.isRecovery,
                                                    startDate: moment(allSameDay ? entryData.startDate : e.date)
                                                        .set({
                                                            hour: moment(entryData.startDate).hour(),
                                                            minute: moment(entryData.startDate).minute(),
                                                        })
                                                        .format("YYYY-MM-DDTHH:mm:ss.SSS"),
                                                    endDate: moment(allSameDay ? entryData.endDate : e.date)
                                                        .set({
                                                            hour: moment(entryData.endDate).hour(),
                                                            minute: moment(entryData.endDate).minute(),
                                                        })
                                                        .format("YYYY-MM-DDTHH:mm:ss.SSS"),
                                                });
                                            }),
                                        );
                                        removeModal("modalEntry");
                                        setAddMultiple();
                                    }}
                                />
                            ),
                        });
                    }
                }}
                onMouseEnter={(e) => {
                    if (!!addMultiple && !isWillAdd && (canAdd || canEdit) && e.button === 0) {
                        setAddMultiple((e) => (e ? [...e, { ...dataLine, ...day }] : [{ ...dataLine, ...day }]));
                    }
                }}
                onClick={(e) => {
                    if (canEdit) {
                        setTimeout(() => {
                            if (e.target && e.target.closest(".cellTime"))
                                setisOpen({
                                    x: e.clientX,
                                    y: e.clientY,
                                });
                        }, 100);
                    }
                }}>
                {(canAdd || isWillAdd) && (
                    <MaterialIcons
                        name="add"
                        className={
                            "material-icons notranslate align-middle group-hover/time:block" +
                            (isWillAdd ? "" : "  hidden")
                        }
                    />
                )}

                {isGroup && groupBy?.length > 1 ? (
                    ""
                ) : isWillAdd ? (
                    ""
                ) : hasComment ? (
                    <Badge size="small" dot className="text-2xs" offset={[0, -6]}>
                        {timeWorked}
                    </Badge>
                ) : timeWorked ? (
                    timeWorked
                ) : isVacationUser ? (
                    absencesTypesOptions.find((e) => e.value === isVacationUser.type).letters.fr
                ) : (
                    ""
                )}

                {isOpen && (
                    <RenderEditTime
                        isOpen={isOpen}
                        dataLine={dataLine}
                        day={day}
                        setisOpen={setisOpen}
                        allItemsTime={allItemsTime}
                        onEditInline={onEditInline}
                        configuration={scheduleConfiguration}
                        onDeleteInline={onDeleteInline}
                        onAddInline={onAddInline}
                    />
                )}
            </div>
        </Dropdown>
    );
};

const RenderEditTime = ({
    isOpen,
    setisOpen,
    dataLine,
    day,
    allItemsTime = [],
    onEditInline = async () => {},
    configuration,
    onDeleteInline,
    onAddInline,
}) => {
    // For click outside
    const { t } = useTranslation();
    const cardRef = useRef(null);

    const { scheduleConfiguration } = useProfileStore();
    const { addModalData, removeModal } = useContext(ModalContext);
    const actualSeason = getActualSeason(scheduleConfiguration);

    const [positionStyle, setPositionStyle] = useState(null);

    const onClickOutside = useCallback(
        (e) => {
            if (cardRef.current && !cardRef.current.contains(e.target)) {
                setisOpen(false);
            }
        },
        [setisOpen, cardRef],
    );

    const [confirmationOpen, setConfirmationOpen] = useState(false);

    useEffect(() => {
        document.addEventListener("click", onClickOutside);
        return () => {
            document.removeEventListener("click", onClickOutside);
        };
    }, []);

    useEffect(() => {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const isTopHalf = isOpen.y < viewportHeight / 2;
        const isLeftHalf = isOpen.x < viewportWidth / 2;

        const cardTop = isTopHalf ? isOpen.y + 20 : viewportHeight - (isOpen.y - 20);
        const cardLeft = isLeftHalf ? isOpen.x + 20 : viewportWidth - (isOpen.x - 20);

        let style = {
            left: isLeftHalf ? cardLeft : "auto",
            right: !isLeftHalf ? cardLeft : "auto",
            top: isTopHalf ? cardTop : "auto",
            bottom: !isTopHalf ? cardTop : "auto",
        };

        setPositionStyle(style);
    }, [isOpen]);

    const isRecovery = dataLine.mergedLines.some((e) => e.entry?.isRecovery);

    return (
        <div
            ref={cardRef}
            className={
                "recap fixed card p-4 rounded flex flex-col gap-4 z-10 bg-white text-xs max-h-[500px] overflow-auto" +
                (positionStyle ? "" : " hidden")
            }
            style={positionStyle}>
            {allItemsTime
                .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
                .map((e, i) => {
                    const hoursFromConfig = applyConfiguration(
                        [
                            {
                                mergedLines: allItemsTime.map((e) => ({
                                    ...e,
                                    entry: e,
                                })),
                            },
                        ],
                        configuration,
                        e._id,
                    );

                    const targetedEntryConfig = hoursFromConfig[0]?.mergedLines?.find((u) => u._id === e._id)?.entry;

                    const modifiersFields = e?.data?.reduce((acc, field) => {
                        const timeConditions = field.timesheetField?.timeCondition;
                        timeConditions?.forEach((timeCondition) => {
                            if (timeCondition && timeCondition.valueToMatch === field.value) {
                                if (timeCondition.modifier === "SUBSTRACT") {
                                    acc.push({
                                        title: field.timesheetField.title,
                                        modifier: fromMsToHoursMinutes(timeCondition.time * -60 * 1000),
                                    });
                                } else if (timeCondition.modifier === "ADD") {
                                    acc.push({
                                        title: field.timesheetField.title,
                                        modifier: fromMsToHoursMinutes(timeCondition.time * 60 * 1000),
                                    });
                                }
                            }
                        });
                        return acc;
                    }, []);

                    const comment = e?.data.find((e) => e.title === "Commentaire" || e.title === "Commentaires")?.value;

                    return (
                        <div key={i} className="flex justify-between items-center border-b-2 pb-4">
                            {targetedEntryConfig?.record && (
                                <div className="flex flex-col items-start ">
                                    <ul className="flex flex-col items-start w-full font-normal">
                                        <li className="font-bold text-base">
                                            {`${t("horaires")} : ${moment(e.startDate).format("HH:mm")} - ${getRealEndDate(
                                                e.startDate,
                                                e.endDate,
                                            ).format(
                                                "HH:mm",
                                            )} ( ${fromMsToHoursMinutes(getDurationBetweenTwoDates(e.startDate, getRealEndDate(e.startDate, e.endDate)))} )`}
                                        </li>

                                        {!!targetedEntryConfig.breakLunch && (
                                            <li>{`* ${t("travailLeMidi")} : +${fromMsToHoursMinutes(targetedEntryConfig.breakLunch * 60 * 1000)}`}</li>
                                        )}
                                        {modifiersFields.map((field, i) => (
                                            <li
                                                key={
                                                    i
                                                }>{`* ${t("champsPersonnalises")} : ${field.title} = ${t("total")} ${field.modifier.includes("-") ? "" : "+"}${field.modifier}`}</li>
                                        ))}
                                        <li className="font-bold">{`${t("total")} travaillé: ${hoursFromConfig[0].total}`}</li>
                                        {targetedEntryConfig?.record?.map((record, i) => (
                                            <li key={i}>{record}</li>
                                        ))}
                                    </ul>
                                    <div className="flex items-center gap-6">
                                        <span
                                            className={
                                                hoursFromConfig[0].HP !== "0h" ? "font-bold text-primary" : ""
                                            }>{`Heures normales : ${hoursFromConfig[0].HP}`}</span>
                                        <span
                                            className={
                                                hoursFromConfig[0].HS !== "0h" ? "font-bold text-primary" : ""
                                            }>{`${t("heuresSupplementaires")} : ${hoursFromConfig[0].HS}`}</span>
                                    </div>{" "}
                                    <div className="flex items-center justify-between w-full">
                                        <span className={hoursFromConfig[0].HSstats.HS_25 && "font-bold text-primary"}>
                                            {`${t("HS 25%")} : ${fromMsToHoursMinutes(hoursFromConfig[0].HSstats.HS_25)}`}
                                        </span>
                                        <span className={hoursFromConfig[0].HSstats.HS_50 && "font-bold text-primary"}>
                                            {`${t("HS 50%")} : ${fromMsToHoursMinutes(hoursFromConfig[0].HSstats.HS_50)}`}
                                        </span>
                                        <span className={hoursFromConfig[0].HSstats.HS_125 && "font-bold text-primary"}>
                                            {`${t("HS 125%")} : ${fromMsToHoursMinutes(hoursFromConfig[0].HSstats.HS_125)}`}
                                        </span>
                                        <span className={hoursFromConfig[0].HSstats.HS_150 && "font-bold text-primary"}>
                                            {`${t("HS 150%")} : ${fromMsToHoursMinutes(hoursFromConfig[0].HSstats.HS_150)}`}
                                        </span>
                                    </div>
                                    {comment && (
                                        <Text size="small">
                                            <Badge dot offset={[-10, 5]} />
                                            <b>{`${t("Commentaire")} : `}</b>
                                            {comment}
                                        </Text>
                                    )}
                                </div>
                            )}
                            <div className="ml-4 flex gap-2">
                                <IconButton
                                    icon="edit"
                                    isNeutral
                                    isSmall
                                    onClick={() => {
                                        addModalData({
                                            key: "modalEntry",
                                            title: t("nouvelleSaisieDeTemps"),
                                            isForm: false,
                                            content: (
                                                <ModalEntry
                                                    canSelectDate
                                                    canSelectProject
                                                    isFromAdmin
                                                    onClose={async (entryData) => {
                                                        await onEditInline(entryData);
                                                        removeModal("modalEntry");
                                                    }}
                                                    onDelete={async (e) => {
                                                        removeModal("modalEntry");
                                                        await onDeleteInline(e);
                                                    }}
                                                    preData={e}
                                                />
                                            ),
                                        });
                                    }}
                                />
                                <IconButton
                                    icon="delete"
                                    isNeutral
                                    isSmall
                                    onClick={() => {
                                        setConfirmationOpen(e._id);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}

            <Button
                text={t("nouvelleSaisieDeTemps")}
                onClick={() => {
                    addModalData({
                        key: "modalEntry",
                        title: t("nouvelleSaisieDeTemps"),
                        isForm: false,
                        content: (
                            <ModalEntry
                                canSelectDate
                                canSelectProject
                                isFromAdmin
                                onClose={async (entryData) => {
                                    await onAddInline({
                                        ...entryData,
                                        author: dataLine.userId,
                                        timesheetTask: dataLine.taskId,
                                        project: dataLine.projectId,
                                        timesheetTaskTitle: dataLine.taskRender,
                                    });
                                    removeModal("modalEntry");
                                }}
                                onDelete={async (e) => {
                                    removeModal("modalEntry");
                                    await onDeleteInline(e);
                                }}
                                preData={{
                                    startDate: moment(day.date)
                                        .set({
                                            hours: parseInt(actualSeason.workDayStartHour.split(":")[0]),
                                            minute: parseInt(actualSeason.workDayStartHour.split(":")[1]),
                                            second: 0,
                                        })
                                        .toISOString(),
                                    endDate: moment(day.date)
                                        .set({
                                            hours: parseInt(actualSeason.workDayEndHour.split(":")[0]),
                                            minute: parseInt(actualSeason.workDayEndHour.split(":")[1]),
                                            second: 0,
                                        })
                                        .toISOString(),
                                    project: dataLine.projectId,
                                    timesheetTask: dataLine.taskId,
                                    isRecovery: isRecovery,
                                }}
                            />
                        ),
                    });
                }}
            />

            {confirmationOpen && (
                <ConfirmationModal
                    title={t("confimerLaSuppressionDefinitive?")}
                    closeReset={setConfirmationOpen}
                    callBackFunc={async () => {
                        await onDeleteInline(confirmationOpen);
                    }}
                />
            )}
        </div>
    );
};

export const getFieldModifier = (configuration, entry) => {
    let modifier = 0;

    // From data fields
    entry?.data?.forEach((field) => {
        const timeConditions = field.timesheetField?.timeCondition;
        timeConditions?.forEach((timeCondition) => {
            if (timeCondition && timeCondition.valueToMatch === field.value) {
                if (timeCondition.modifier === "SUBSTRACT") {
                    modifier -= timeCondition.time * 60;
                } else if (timeCondition.modifier === "ADD") {
                    modifier += timeCondition.time * 60;
                }
            }
        });
    });

    // On retire les heures de pauses travaillées (si la config précise que les heures de pause sont toujours travaillées on ne les comptes pas)
    if (configuration && !configuration.isMorningBreakHidden && isMorningConcerned(entry.startDate, entry.endDate)) {
        modifier -= (Constants.timesheetMaxMorningBreakDuration - entry.breakMorning) * 60;
    }

    if (configuration && !configuration.isLunchBreakHidden && isLunchConcerned(entry.startDate, entry.endDate)) {
        modifier -= (Constants.timesheetMaxLunchBreakDuration - entry.breakLunch) * 60;
    }

    return modifier * 1000;
};

export const getTotalWorkedHours = (configuration, items = [], withModifier = true) => {
    let result = 0;
    items.map((e) => {
        result +=
            getDurationBetweenTwoDates(e.startDate, getRealEndDate(e.startDate, e.endDate)) +
            (withModifier ? getFieldModifier(configuration, e) : 0);
    });

    if (items.length === 0 || result === 0) return "";
    return fromMsToHoursMinutes(result);
};

export const getTotalNumericValue = (items = []) => {
    let result = 0;
    items.map((entry) => {
        entry?.data?.forEach((field) => {
            if (typeof field.value === "number") {
                const timeConditions = field.timesheetField?.timeCondition;
                timeConditions?.forEach((timeCondition) => {
                    if (timeCondition && timeCondition.valueToMatch === Number(field.value)) {
                        if (timeCondition.modifier === "SUBSTRACT") {
                            result += Number(field.value);
                        } else if (timeCondition.modifier === "ADD") {
                            result += Number(field.value);
                        }
                    }
                });
            }
        });
    });
    if (items.length === 0 || result === 0) return "";
    return result;
};

const getSpecificValue = (items, line) => {
    if (items?.length === 0) return "";
    else if (line.uniqueId.includes("pause-matin")) return items.map((e) => e.breakMorning).reduce((a, b) => a + b, 0);
    else if (line.uniqueId.includes("pause-midi")) return items.map((e) => e.breakLunch).reduce((a, b) => a + b, 0);
    else return "";
};

export const getResultCell = ({ dataLine, day, configuration }) => {
    const isGroup = !!dataLine.isGroup;
    const isDetail = !!dataLine.isDetail;
    const isSpecific = !!dataLine.isSpecific;

    const allItems = isGroup ? dataLine.mergedLines.map((e) => e.mergedLines).flat() : dataLine.mergedLines;

    const allItemsTime = allItems
        ?.filter((e) => moment(day.date).format("YYYY-MM-DD") === moment(e?.entry?.startDate).format("YYYY-MM-DD"))
        ?.map((e) => e.entry);

    const timeWorked = isSpecific
        ? getSpecificValue(allItemsTime, dataLine) || ""
        : isDetail
          ? getTotalNumericValue(
                allItemsTime.map((e) => ({
                    data: e.data.filter((e) => e.timesheetField?.title === dataLine.taskRender),
                    startDate: moment().startOf("day").toISOString(),
                    endDate: moment().startOf("day").toISOString(),
                })),
            )
          : getTotalWorkedHours(configuration, allItemsTime);

    return timeWorked;
};

export default InputCellTime;
