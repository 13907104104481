import axios from "axios";

import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import ButtonDelete from "components/tableInline/ButtonDelete";
import TableInline from "components/tableInline/Table";

const TasksTab = () => {
    const { t } = useTranslation();

    const getDataList = useCallback(async () => {
        const { data } = await axios.get(apiURL.getTimesheetTasks);
        return data;
    }, []);

    const editInline = useCallback(async (itemId, newItem) => {
        const { data } = await axios.patch(
            apiURL.updateTimesheetTask + itemId,
            newItem,
        );
        return data;
    }, []);

    const addInline = useCallback(async (newItem) => {
        const { data } = await axios.post(apiURL.createTimesheetTask, {
            title: "Nouvelle Tâche",
            ...newItem,
        });
        return data;
    }, []);

    return (
        <TableInline
            getFunction={getDataList}
            addFunction={addInline}
            editFunction={editInline}
            ButtonOptions={ButtonOptions}
            fields={[
                {
                    key: "title",
                    label: t("taches"),
                },
                {
                    key: "isDefault",
                    label: t("tache") + " " + t("parDefaut"),
                    isBoolean: true,
                    width: "200px",
                },
            ]}
        />
    );
};

const ButtonOptions = ({ dataLine, setDataList, setLoader }) => {
    const deleteInline = useCallback(async () => {
        setLoader(true);
        await axios.delete(apiURL.removeTimesheetTask + dataLine._id);
        setDataList((e) => e.filter((e) => e._id !== dataLine._id));
        setLoader(false);
    }, [dataLine, setDataList, setLoader]);

    return <ButtonDelete deleteFunction={deleteInline} />;
};

export default TasksTab;
