import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { DriveToolsContext } from "components/drive/Drive";
import Checkbox from "components/input/Checkbox";

const FilterByProject = () => {
    const { t } = useTranslation();

    const { setToolsData, toolsData } = useContext(DriveToolsContext);

    const listProjects = toolsData?.filtersStore.projects;

    if (listProjects.length === 0)
        return <div>{t("aucuneDonneeAFiltrer")}</div>;

    return (
        <ul>
            {listProjects.map((project, index) => (
                <li
                    key={index}
                    className="cursor-pointer"
                    onClick={() =>
                        setToolsData((k) => ({
                            ...k,
                            filters: {
                                ...k.filters,
                                projects: toolsData.filters.projects?.find(
                                    (e) => e._id === project._id,
                                )
                                    ? toolsData.filters.projects?.filter(
                                          (e) => e._id !== project._id,
                                      )
                                    : [...toolsData.filters.projects, project],
                            },
                        }))
                    }
                >
                    <Checkbox
                        removeMarging
                        text={
                            <div className="flex items-center py-4">
                                <div className="flex-1 mr-2 w-0">
                                    {project?.customer?.name && (
                                        <div className="flex justify-between gap-4">
                                            <span className="text-xs truncate">
                                                {project?.customer?.name}
                                            </span>
                                            <span className="text-xs flex-1 text-right">
                                                {t("client")}
                                            </span>
                                        </div>
                                    )}
                                    <div className="flex justify-between gap-4">
                                        <span className="font-bold truncate">
                                            {project?.title}
                                        </span>
                                        <span className="flex-1 text-right">
                                            {t("dossier")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }
                        value={
                            !!toolsData.filters.projects?.find(
                                (e) => e._id === project._id,
                            )
                        }
                        onChange={(e) => {}}
                    />
                </li>
            ))}
        </ul>
    );
};

export default FilterByProject;
