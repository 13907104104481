import { useTranslation } from "react-i18next";

import constants from "utils/constants";

import { Form, Input } from "antd";

const ModalCreateProject = () => {
    const { t } = useTranslation();

    return (
        <Form.Item
            hasFeedback
            name={"title"}
            validateTrigger="onChange"
            rules={[
                {
                    max: constants.maxLengthProjectTitle,
                    required: true,
                },
            ]}
        >
            <Input
                placeholder={t("titreDeVotreDossier")}
                size="large"
                required
            />
        </Form.Item>
    );
};

export default ModalCreateProject;
