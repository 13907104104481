import axios from "axios";
import moment from "moment";

import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fromDateToDayMonthYearString } from "helpers/date";
import apiURL from "utils/apiURL";

import Button from "components/input/Button";
import IllustratedModalContent from "components/modal/IllustratedModalContent";
import InformationModal from "components/modal/InformationModal";

const SameDay = ({ dateFilter, setLoader, setFilteredEntriesEntries }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [oldEntries, setOldEntries] = useState();

    const getOldEntries = useCallback(async () => {
        setLoader(true);
        const { data } = await axios.post(apiURL.getPrevDayEntries, {
            date: moment(dateFilter).endOf("day").subtract(1, "days").toISOString(),
        });
        setLoader(false);
        return data.filter((e) => !!e.timesheetTask);
    }, [setLoader, dateFilter]);

    useEffect(() => {
        getOldEntries().then(setOldEntries);
    }, [dateFilter]);

    const createCopyEntries = useCallback(async () => {
        setLoader(true);
        const data = await Promise.all(
            oldEntries.map((e) =>
                axios.post(apiURL.createTimesheetEntry, {
                    data: e.data,
                    timesheetTask: e.timesheetTask?._id,
                    timesheetTaskTitle: e.timesheetTask?.title || e.timesheetTaskTitle,
                    project: e.project._id,
                    startDate: dateFilter + "T" + e.startDate.split("T")[1],
                    endDate: dateFilter + "T" + e.endDate.split("T")[1],
                }),
            ),
        );

        setFilteredEntriesEntries((k) => [...k, ...data.map((e) => e.data)]);
        setIsModalOpen(false);
        setLoader(false);
        return data;
    }, [oldEntries, setLoader, dateFilter]);

    if (oldEntries?.length)
        return (
            <div className="mt-4">
                {oldEntries?.length ? (
                    <Button
                        text={<span className="text-2xs">{t("votreJourneeEstLaMemeQueLaPrecedente")}</span>}
                        isBgTransparent={true}
                        isReversed={true}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    />
                ) : null}
                {/* TODO */}
                {isModalOpen && (
                    <InformationModal
                        title={<span className="text-sm">{t("votreJourneeEstLaMemeQueLaPrecedente")}</span>}
                        isNoPadding={true}
                        customWidth={"max-w-[750px] lg:max-w-[1050px]"}
                        content={
                            <IllustratedModalContent
                                text={` <div>
                               <div>
                                 ${t("votreJourneeEstLaMemeQueLaPrecedente")}
                               </div>
                               <div>( ${fromDateToDayMonthYearString(oldEntries[0].startDate, ["yearNumber"])} )</div>
                           </div>`}
                                title="Hey !"
                                content={
                                    <div className="text-black">
                                        <p className="text-sm">{t("nePerdezPasPlusDeTempsASaisirVotreTravail")}.</p>

                                        <div className="text-lg font-bold mt-4 mb-2">{t("confirmer")} :</div>
                                        <div className="flex gap-4">
                                            <Button text={t("oui")} onClick={createCopyEntries} />
                                            <Button
                                                isNeutral={true}
                                                isBgTransparent={true}
                                                text={t("non")}
                                                onClick={() => setIsModalOpen(false)}
                                            />
                                        </div>
                                    </div>
                                }
                                closeReset={() => {
                                    setIsModalOpen(false);
                                }}
                            />
                        }
                        closeReset={() => {
                            setIsModalOpen(false);
                        }}
                    />
                )}
            </div>
        );
};

export default SameDay;
