import axios from "axios";

import apiURL from "utils/apiURL";

import { GET_PROFILE, PROFILE_ERROR } from "./types";

// Recup du profil courant

export const getCurrentProfile = () => async (dispatch) => {
    try {
        const res = await axios.get(apiURL.getUserProfile);

        dispatch({
            type: GET_PROFILE,
            payload: res.data,
        });

        return res;
    } catch (error) {
        dispatch({
            type: PROFILE_ERROR,
            payload: {
                msg: error.response.statusText,
                status: error.response.status,
            },
        });

        return null;
    }
};

// Create user profile

export const createProfile = (formData) => async (dispatch) => {
    try {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        const res = await axios.post(apiURL.createProfile, formData, config);

        dispatch({
            type: GET_PROFILE,
            payload: res.data,
        });

        return res;
    } catch (err) {
        const errors = err.response.data.errors;

        if (errors) {
            return errors;
        }

        dispatch({
            type: PROFILE_ERROR,
            payload: {
                msg: err.response.statusText,
                status: err.response.status,
            },
        });

        return null;
    }
};
