import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useLocation, useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { downloadFile } from "helpers/file";
import { debounce } from "helpers/input";
import { isFavoriteFolder } from "helpers/project";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import ModalCallTender from "components/call-tender/ModalCallTender";
import ModalGuestsTender from "components/call-tender/ModalGuestsTender";
import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import ColorPicker from "components/input/ColorPicker";
import TextMatchConfirm from "components/utils/TextMatchConfirm";
import VisaForm from "components/visa/VisaForm";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";
import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import ModalCreateProject from "./ModalCreateProject";
import ModalDuplicate from "./ModalDuplicate";
import ModalRename from "./ModalRename";
import ModalURLRedirection from "./ModalURLRedirection";
import ProjectMembers from "./ProjectMembers";
import ProjectSelection from "./ProjectSelection";

import { Button, Dropdown, Flex, Spin, Upload, theme } from "antd";

const OptionsProject = ({
    project,
    urlProjectId,
    profile,
    refreshAction,
    showInfosInPanel,
    userRoles = {},
    children,
    placement = "topLeft",
    trigger = "click",
    isProjectGlobal = false,
    isFromBackground = false,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");
    const { pathname } = useLocation();

    const { onUpload, currentProject, customerConfig } =
        useContext(DriveToolsContext);
    const { addModalData, removeModal } = useContext(ModalContext);
    const { startDriveSyncMessage, runMessage, runNotification } =
        useContext(PopupContext);
    const { updateFileProgressIndicator } = useContext(
        BackgroundActionsContext,
    );

    const pickerRef = useRef(null);
    const { permissionsTools } = useProfileStore();
    const [loader, setLoader] = useState(false);

    const [deleteProjectConfirm, showDeleteProjectConfirm] = useState(false);

    const [members, setMembers] = useState([]);

    const { token } = theme.useToken();

    useEffect(() => {
        if (urlProjectId === project?._id.toString()) {
            getMembers();
        }
    }, [urlProjectId]);

    useEffect(() => {
        if (members.length > 0) {
            if (
                !members.some(
                    (member) =>
                        member.user && member.user._id === profile.user._id,
                )
            )
                navigate("/drive");
        }
    }, [members]);

    const getMembers = async () => {
        const membersRes = await axios.get(
            apiURL.getProjectMembers + project?._id,
        );

        if (membersRes && membersRes.data) {
            setMembers(membersRes.data);
            return membersRes.data;
        } else setMembers([]);

        return [];
    };

    // Déplacement du projet
    const onConfirmMoving = async (projectId) => {
        setLoader(true);
        startDriveSyncMessage();
        try {
            const resMoving = await axios.get(
                apiURL.moveProject +
                    project?._id +
                    "/" +
                    (projectId ? projectId : 0),
            );

            if (resMoving && resMoving.data) {
                toastAlert("success", t("leDossierABienEteDeplace"));
                refreshAction();
                removeModal("moveProject");
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    // Ajout / Suppression d'un dossier aux favoris
    const toggleFavorite = async () => {
        setLoader(true);
        startDriveSyncMessage();
        try {
            const resFavorite = await axios.put(apiURL.addFavorite, {
                id: project?._id,
                type: "folder",
            });

            if (resFavorite && resFavorite.data) {
                if (resFavorite.data) refreshAction();
                else toastAlert("error", t("uneErreurEstSurvenue"));
            } else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
            setLoader(false);
        }
    };

    const downloadFolderById = async () => {
        runMessage({
            content: t("telechargementEnCours"),
            duration: 0,
            type: "loading",
        });
        try {
            const resFiles = await fetch(apiURL.downloadFolders, {
                method: "POST",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ folders: [project?._id] }),
            });

            if (!resFiles.ok) {
                toastAlert("error", t("uneErreurEstSurvenue"));
                return;
            }

            const reader = resFiles.body.getReader();
            const chunks = [];
            let receivedLength = 0;

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                chunks.push(value);
                receivedLength += value.length;
            }

            const fullArray = new Uint8Array(receivedLength);
            let position = 0;
            for (let chunk of chunks) {
                fullArray.set(chunk, position);
                position += chunk.length;
            }

            const blob = new Blob([fullArray], { type: "application/zip" });
            downloadFile(project.title + ".zip", blob, "application/zip");
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
        runMessage({
            reset: true,
            content: t("telechargementEnCours"),
            type: "success",
            duration: 1,
        });
    };

    const toggleNotifications = async () => {
        setLoader(true);
        startDriveSyncMessage();
        try {
            const resEnabled = await axios.get(
                apiURL.toggleFolderNotificationsEnabled + project?._id,
            );

            if (resEnabled?.data !== null) refreshAction();
            else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
            setLoader(false);
        }
    };

    const canMove = profile.projects
        .filter((e) => !e.parentProject)
        .find((e) => e._id === project?._id)
        ? profile.projects?.filter((e) => !e.parentProject)?.length > 1
            ? true
            : false
        : true;

    const duplicationOptions = [
        ...(project.tender ? ["files"] : []),
        "users",
        ...(!project.parentProject && customerConfig?.visas.isActive
            ? ["visas"]
            : []),
        ...(customerConfig?.versioning.isActive ? ["automations"] : []),
        ...(customerConfig?.timesheet.isActive ? ["timesheet"] : []),
    ];

    const addItems = [
        {
            label: (
                <Flex align="center" gap={8}>
                    <Text>{t("creer")}</Text>
                </Flex>
            ),
            key: "create",
            children: setKeyMenu([
                {
                    label: (
                        <Flex align="center" gap={8}>
                            <MaterialIcons size="sm" name="add" />
                            <Text>{t("nouveauDossier")}</Text>
                        </Flex>
                    ),
                    onClick: () => {
                        addModalData({
                            key: "createFolder",
                            title: t("nouveauDossier"),
                            okText: t("confirmer"),
                            method: "BLOCK",
                            content: (
                                <ModalCreateProject
                                    profile={profile}
                                    refreshAction={refreshAction}
                                />
                            ),
                            handleConfirm: createProject,
                        });
                    },
                },
                project &&
                    !!customerConfig?.callForTender?.isActive &&
                    !currentProject?.tender && {
                        label: (
                            <Flex align="center" gap={8}>
                                <MaterialIcons size="sm" name="add" />
                                <Text>{t("boiteDeDepot")}</Text>
                            </Flex>
                        ),
                        onClick: () => {
                            addModalData({
                                key: "call-tender",
                                title: t("creerUneBoiteDeDepot"),
                                content: <ModalCallTender />,
                                okText: t("creer"),
                                handleConfirm: async (values) => {
                                    startDriveSyncMessage();
                                    const newProject =
                                        await createProject(values);
                                    await axios.post(apiURL.createCallTender, {
                                        project: newProject?._id,
                                        projectTitle: newProject.title,
                                        allowLateSubmission:
                                            !!values.allowLateSubmission,
                                        description: values.description || "",
                                        requiredFiles:
                                            values.requiredFiles || [],
                                        termDate: values.termDateEnabled
                                            ? moment(values.termDate).set({
                                                  hour: moment(
                                                      values.termTime,
                                                  ).hour(),
                                                  minute: moment(
                                                      values.termTime,
                                                  ).minute(),
                                                  second: moment(
                                                      values.termTime,
                                                  ).second(),
                                              })
                                            : null,
                                        password: values.passwordEnabled
                                            ? values.password
                                            : null,
                                    });
                                    refreshAction();
                                },
                            });
                        },
                    },
                (constants.demoBimonoMails.includes(profile.user.email) ||
                    profile.user.email === "ff@scale-arch.ch") && {
                    label: (
                        <Flex align="center" gap={8}>
                            <MaterialIcons size="sm" name="add" />
                            <Text>{t("redirectionURL")}</Text>
                        </Flex>
                    ),
                    onClick: () => {
                        addModalData({
                            key: "createRedirection",
                            title: t("redirectionURL"),
                            okText: t("confirmer"),
                            method: "BLOCK",
                            content: (
                                <ModalURLRedirection
                                    project={project}
                                    refreshAction={refreshAction}
                                    updateFileProgressIndicator={
                                        updateFileProgressIndicator
                                    }
                                />
                            ),
                        });
                    },
                },
                ,
            ]),
        },
        {
            label: (
                <Flex align="center" gap={8}>
                    <Text>{t("importer")}</Text>
                </Flex>
            ),
            key: "import",
            children: [
                {
                    label: (
                        <Upload
                            accept={constants.allowedExtensionsList}
                            multiple
                            fileList={[]}
                            showUploadList={false}
                            onChange={debounce(({ fileList }) => {
                                onUpload(
                                    fileList.map((file) => file.originFileObj),
                                    false,
                                );
                                return false;
                            }, 100)}
                            beforeUpload={() => {
                                return false;
                            }}
                        >
                            <Flex align="center" gap={8}>
                                <MaterialIcons size="sm" name="draft" />
                                <Text>{t("importerUnFichier")}</Text>
                            </Flex>
                        </Upload>
                    ),
                },
                {
                    label: (
                        <Upload
                            directory
                            showUploadList={false}
                            fileList={[]}
                            onChange={debounce(({ fileList }) => {
                                onUpload(
                                    fileList.map((file) => file.originFileObj),
                                    true,
                                );
                            }, 100)}
                            beforeUpload={() => false}
                        >
                            <Flex align="center" gap={8}>
                                <MaterialIcons size="sm" name="folder" />
                                <Text>{t("importerUnDossier")}</Text>
                            </Flex>
                        </Upload>
                    ),
                },
            ],
        },
    ];

    const getMembersOption = (isFromShare = false) => {
        return (
            profile && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="group"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>
                            {t(
                                userRoles.MODERATOR
                                    ? "gererLacces"
                                    : "membresDuDossier",
                            )}
                        </Text>
                    </Flex>
                ),
                key: "members" + (isFromShare ? "-share" : ""),
                onClick: async () => {
                    setLoader(true);
                    const resMembers = await getMembers();
                    setLoader(false);

                    addModalData({
                        key: "projectMembers",
                        title:
                            t("inviterARejoindre") + ' "' + project.title + '"',
                        content: (
                            <ProjectMembers
                                project={project}
                                members={resMembers}
                                user={profile.user}
                                refreshAction={refreshAction}
                                userRoles={userRoles}
                            />
                        ),
                        isForm: false,
                        width: 1200,
                    });
                },
                isProject: true,
            }
        );
    };

    const shareItems = [getMembersOption(true)];

    const organizeItems = [
        profile &&
            userRoles.MODERATOR &&
            canMove && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="drive_file_move"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("deplacerVers")}</Text>
                    </Flex>
                ),
                key: "move-to",
                onClick: () => {
                    addModalData({
                        key: "moveProject",
                        title: t("deplacerVers"),
                        content: (
                            <ProjectSelection
                                profile={profile}
                                currentProject={project}
                                onConfirm={onConfirmMoving}
                                isMoving={true}
                            />
                        ),
                        isForm: false,
                    });
                },
                isProject: true,
            },
        {
            label: (
                <Flex align="center" gap={8}>
                    <MaterialIcons
                        name={
                            profile.excludedNotificationsFolders.find(
                                (excluded) => excluded.folder === project?._id,
                            )?.isExcluded
                                ? "notifications_off"
                                : "notifications_active"
                        }
                        size="xs"
                        style={{
                            marginRight: token.marginXS,
                        }}
                    />
                    <Text>
                        {profile.excludedNotificationsFolders.find(
                            (excluded) => excluded.folder === project?._id,
                        )?.isExcluded
                            ? t("notificationsDesactivees")
                            : t("notificationsActivees")}
                    </Text>
                </Flex>
            ),
            key: "notifications",
            onClick: () => {
                toggleNotifications();
            },
            isProject: true,
        },
        profile &&
            userRoles.MODERATOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="palette"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("couleurDuDossier")}</Text>
                    </Flex>
                ),
                key: "color",
                onClick: () => {
                    pickerRef.current.showPicker();
                    pickerRef.current.addEventListener("change", (event) => {
                        updateColor(event.target.value);
                    });
                },
                isProject: true,
            },
        project?.color &&
            profile &&
            userRoles.MODERATOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <div
                            className="w-[15px] h-[15px] rounded-sm my-auto"
                            style={{
                                backgroundColor: project.color,
                                marginRight: token.marginXS,
                            }}
                        ></div>
                        <Text>{t("retirerLaCouleur")}</Text>
                    </Flex>
                ),
                key: "remove-color",
                onClick: () => {
                    updateColor(null);
                },
                isProject: true,
            },
        getMembersOption(),
        profile &&
            userRoles.MODERATOR &&
            canMove && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="folder_copy"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("dupliquerLaStructure")}</Text>
                    </Flex>
                ),
                key: "duplicate-folder",
                onClick: () =>
                    addModalData({
                        key: "duplicate",
                        title: t("dupliquerLaStructure"),
                        okText: t("confirmer"),
                        content: (
                            <ModalDuplicate
                                profile={profile}
                                project={project}
                                refreshAction={refreshAction}
                                customerConfig={customerConfig}
                            />
                        ),
                        initialValues: {
                            title: "",
                            duplicationOptions,
                        },
                        handleConfirm: async (values) => {
                            try {
                                startDriveSyncMessage();
                                const optionsObject = {
                                    files: values.duplicationOptions.includes(
                                        "files",
                                    ),
                                    users: values.duplicationOptions.includes(
                                        "users",
                                    ),
                                    automations:
                                        values.duplicationOptions.includes(
                                            "automations",
                                        ),
                                    visas: values.duplicationOptions.includes(
                                        "visas",
                                    ),
                                    timesheet:
                                        values.duplicationOptions.includes(
                                            "timesheet",
                                        ),
                                };

                                await axios.post(
                                    apiURL.duplicateProject + project._id,
                                    {
                                        title: values.title,
                                        options: optionsObject,
                                        destinationFolder:
                                            values.destinationFolder?._id,
                                    },
                                );
                                refreshAction();
                                return true;
                            } catch (err) {
                                toastAlert("error", t("uneErreurEstSurvenue"));
                                return false;
                            }
                        },
                    }),
                isProject: true,
            },
        permissionsTools?.visas?.canView &&
            permissionsTools.automations?.canView &&
            userRoles.MODERATOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="approval"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("visasDuProjet")}</Text>
                    </Flex>
                ),
                key: "visas",
                onClick: () => {
                    addModalData({
                        key: "projectVisas",
                        title: t("visasDuProjet"),
                        content: (
                            <VisaForm projectId={project._id} onlyForm={true} />
                        ),
                        isForm: false,
                    });
                },
                isProject: true,
            },
        {
            label: (
                <Flex align="center" gap={8}>
                    <MaterialIcons
                        name="favorite"
                        size="xs"
                        style={{
                            marginRight: token.marginXS,
                        }}
                    />
                    <Text>
                        {!isFavoriteFolder(project, profile)
                            ? t("ajouterAuxFavoris")
                            : t("retirerDesFavoris")}
                    </Text>
                </Flex>
            ),
            key: "favorite",
            onClick: () => {
                toggleFavorite();
            },
            isProject: true,
        },
    ];

    const tenderItems = !project
        ? []
        : [
              !!customerConfig?.callForTender?.isActive &&
                  profile &&
                  !!project.tender &&
                  userRoles.MODERATOR && {
                      type: "divider",
                  },
              !!customerConfig?.callForTender?.isActive &&
                  profile &&
                  !!project.tender &&
                  userRoles.MODERATOR && {
                      label: (
                          <Flex
                              align="center"
                              gap={8}
                              style={{
                                  width: "100%",
                              }}
                          >
                              <MaterialIcons size="sm" name="package_2" />
                              <Text>{t("parametres")}</Text>
                          </Flex>
                      ),
                      onClick: () =>
                          addModalData({
                              key: "call-tender",
                              title: t("modifier") + " " + t("boiteDeDepot"),
                              content: (
                                  <ModalCallTender edit={project.tender} />
                              ),
                              okText: t("modifier"),
                              initialValues: {
                                  ...project.tender,
                                  passwordEnabled: !!project.tender?.password,
                                  termDateEnabled: !!project.tender?.termDate,
                                  termDate: project.tender?.termDate
                                      ? moment(project.tender?.termDate)
                                      : null,
                                  termTime: project.tender?.termDate
                                      ? moment(project.tender?.termDate)
                                      : null,
                              },
                              handleConfirm: async (values) => {
                                  startDriveSyncMessage();
                                  await axios.put(
                                      apiURL.updateCallTender +
                                          project.tender?._id,
                                      {
                                          allowLateSubmission:
                                              !!values.allowLateSubmission,
                                          description: values.description || "",
                                          requiredFiles:
                                              values.requiredFiles || [],
                                          termDate: values.termDateEnabled
                                              ? moment(values.termDate).set({
                                                    hour: moment(
                                                        values.termTime,
                                                    ).hour(),
                                                    minute: moment(
                                                        values.termTime,
                                                    ).minute(),
                                                    second: moment(
                                                        values.termTime,
                                                    ).second(),
                                                })
                                              : null,
                                          password: values.passwordEnabled
                                              ? values.password
                                              : null,
                                      },
                                  );
                                  refreshAction();
                              },
                          }),
                  },
              !!customerConfig?.callForTender?.isActive &&
                  profile &&
                  !!project.tender &&
                  userRoles.MODERATOR && {
                      label: (
                          <Flex
                              align="center"
                              gap={8}
                              style={{
                                  width: "100%",
                              }}
                          >
                              <MaterialIcons
                                  size="sm"
                                  name="deployed_code_account"
                              />
                              <Text>{t("gererLesInvites")}</Text>
                          </Flex>
                      ),
                      onClick: () =>
                          addModalData({
                              key: "manage-invites",
                              title:
                                  t("gererLesInvites") + ": " + project.title,
                              content: (
                                  <ModalGuestsTender
                                      project={project}
                                      refreshAction={refreshAction}
                                  />
                              ),
                              isForm: false,
                              width: 1650,
                          }),
                  },
              !!customerConfig?.callForTender?.isActive &&
                  profile &&
                  !!project.tender &&
                  userRoles.MODERATOR && {
                      label: (
                          <Flex
                              align="center"
                              gap={8}
                              style={{
                                  width: "100%",
                              }}
                          >
                              <MaterialIcons
                                  size="sm"
                                  name="deployed_code_alert"
                              />
                              <Text>{t("desactiverLaBoiteDeDepot")}</Text>
                          </Flex>
                      ),
                      onClick: async () => {
                          addModalData({
                              key: "confirm",
                              title: t("desactiverLaBoiteDeDepot"),
                              content: t(
                                  "etesVousSurDeVouloirDesactiverLaBoiteDeDepot",
                              ),
                              okText: t("confirmer"),
                              handleConfirm: async () => {
                                  startDriveSyncMessage();
                                  await axios.post(apiURL.cancelCallTender, {
                                      id: project.tender,
                                  });
                                  refreshAction();
                              },
                          });
                      },
                  },
          ];

    const menuItems = !project
        ? []
        : [
              {
                  label: (
                      <Flex align="center" gap={8}>
                          <MaterialIcons
                              name="download"
                              size="xs"
                              style={{
                                  marginRight: token.marginXS,
                              }}
                          />
                          <Text>{t("telecharger")}</Text>
                      </Flex>
                  ),
                  key: "download",
                  onClick: () => downloadFolderById(project?._id),
                  isProject: true,
              },
              profile &&
                  userRoles.MODERATOR && {
                      label: (
                          <Flex align="center" gap={8}>
                              <MaterialIcons
                                  name="edit"
                                  size="xs"
                                  style={{
                                      marginRight: token.marginXS,
                                  }}
                              />
                              <Text>{t("renommer")}</Text>
                          </Flex>
                      ),
                      key: "remplacer",
                      onClick: () => {
                          addModalData({
                              key: "rename",
                              title: `${t("renommer")} ${t("leDossier")}`,
                              okText: t("confirmer"),
                              content: (
                                  <ModalRename
                                      project={project}
                                      refreshAction={refreshAction}
                                  />
                              ),
                          });
                      },
                      isProject: true,
                  },
              {
                  type: "divider",
              },
              {
                  label: (
                      <Flex align="center" gap={8}>
                          <Text>{t("partager")}</Text>
                      </Flex>
                  ),
                  key: "share",
                  children: setKeyMenu(shareItems, "share"),
              },
              {
                  label: (
                      <Flex align="center" gap={8}>
                          <Text>{t("organiser")}</Text>
                      </Flex>
                  ),
                  key: "organize",
                  children: setKeyMenu(organizeItems, "organize"),
              },
              {
                  type: "divider",
              },
              {
                  label: (
                      <Flex align="center" gap={8}>
                          <MaterialIcons
                              name="info"
                              size="xs"
                              style={{
                                  marginRight: token.marginXS,
                              }}
                          />
                          <Text>{t("informations")}</Text>
                      </Flex>
                  ),
                  key: "informations",
                  onClick: () => {
                      showInfosInPanel &&
                          showInfosInPanel(project?._id, true, true);
                  },
                  isProject: true,
              },
              profile &&
                  !userRoles.OWNER &&
                  (isProjectGlobal ||
                      isFromBackground ||
                      pathname === "/drive") && {
                      label: (
                          <Flex align="center" gap={8}>
                              <MaterialIcons
                                  name="disabled_by_default"
                                  size="xs"
                                  style={{
                                      marginRight: token.marginXS,
                                  }}
                              />
                              <Text>{t("quitterLeDossier")}</Text>
                          </Flex>
                      ),
                      key: "leave",
                      onClick: () => {
                          addModalData({
                              key: "leave",
                              title: t("voulezVousVraimentQuitterCeDossier?"),
                              content: (
                                  <b>{t("vousNaurezPlusAccesASonContenu")}</b>
                              ),
                              handleConfirm: () => handleLeaveFolder(),
                          });
                      },
                      isProject: true,
                  },
              profile &&
                  (userRoles.MODERATOR ||
                      project.users.find(
                          (e) =>
                              e.user === profile.user._id &&
                              (e.role.code === "OWNER" ||
                                  e.role.code === "MODERATOR"),
                      )) && {
                      label: (
                          <Flex align="center" gap={8}>
                              <MaterialIcons
                                  name="delete"
                                  size="xs"
                                  style={{
                                      marginRight: token.marginXS,
                                  }}
                              />
                              <Text>{t("supprimer")}</Text>
                          </Flex>
                      ),
                      key: "delete",
                      onClick: () => {
                          showDeleteProjectConfirm(false);
                          addModalData({
                              key: "delete",
                              title: t("voulezVousVraimentSupprimerCeDossier?"),
                              content: (
                                  <div>
                                      <b>{t("cetteActionEstIrreversible")}</b>
                                      <TextMatchConfirm
                                          text={
                                              <span>
                                                  {t("saisissez")}{" "}
                                                  <b>{project.title}</b>{" "}
                                                  {t(
                                                      "pourConfirmerSaSuppression",
                                                  )}
                                              </span>
                                          }
                                          termToMatch={project.title}
                                          onConfirm={(e) =>
                                              showDeleteProjectConfirm(true)
                                          }
                                          onCancel={() => removeModal("delete")}
                                      />
                                  </div>
                              ),
                              isForm: false,
                          });
                      },
                      isProject: true,
                  },
          ];

    useEffect(() => {
        if (deleteProjectConfirm) {
            removeModal("delete");
            addModalData({
                key: "confirmDelete",
                title: t("confirmation?"),
                content: (
                    <b>
                        {t(
                            "laSuppressionDuDossierEntraineraEgalementCelleDesSousDossiersOuFichiersAssociesACeDernier",
                        )}
                        .
                    </b>
                ),
                handleConfirm: () => handleDeleteFolder(),
                okText: t("supprimer"),
                cancelText: t("annuler"),
            });
        }
    }, [deleteProjectConfirm]);

    const handleDeleteFolder = async () => {
        startDriveSyncMessage();
        try {
            const resDeleted = await axios.delete(
                apiURL.deleteProject + project?._id,
            );

            if (resDeleted && resDeleted.data) {
                if (
                    project?._id === currentProject?._id &&
                    project.parentProject
                ) {
                    navigate("/drive/project/" + project.parentProject);
                } else {
                    refreshAction();
                }

                // nav
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const handleLeaveFolder = async () => {
        setLoader(true);
        startDriveSyncMessage();
        try {
            const resLeaved = await axios.delete(
                apiURL.removeProjectMember +
                    project?._id +
                    "/" +
                    profile.user._id,
            );

            if (resLeaved && resLeaved.data) {
                refreshAction();
                navigate("/drive");
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    const updateColor = async (newColor) => {
        if (newColor !== "") {
            setLoader(true);
            startDriveSyncMessage();
            const resUpdated = await axios.post(apiURL.updateProject, {
                id: project?._id,
                color: newColor,
            });

            if (resUpdated && resUpdated.data) {
                refreshAction();
            } else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        }
    };
    const [open, setopen] = useState();

    const createProject = async (formData) => {
        try {
            const projectFields = {
                projectTitle: formData.title,
                email: profile.user.email,
                language: profile.user.language.code,
                parentProject: project?._id,
                userId: profile.user._id,
                sendNotification: true,
            };

            const resProject = await axios.post(
                apiURL.createProject,
                projectFields,
            );

            if (resProject && resProject.data) {
                refreshAction();
                // setTimeout(() => {
                //     navigate("/drive/project/" + resProject.data._id);
                // }, 200);
                return resProject.data;
            } else {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLaCreationDuDossier"),
                );
            }
        } catch (error) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    return (
        <Fragment>
            <div className="absolute">
                <ColorPicker
                    name="color"
                    defaultColor={project?.color ? project.color : ""}
                    pickerRef={pickerRef}
                />
            </div>
            {loader && !isFromBackground ? (
                <Spin />
            ) : (
                <Dropdown
                    open={open}
                    onOpenChange={(e) => setopen(e)}
                    // destroyPopupOnHide // Les <Upload /> ne fonctionne pas avec cette propriété car ils sont destroy au click outside
                    dropdownRender={(menu) => {
                        return (
                            <Fragment>
                                <div
                                    className="mask"
                                    onClick={() => {
                                        setopen(false);
                                    }}
                                    onContextMenu={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setopen(false);
                                    }}
                                />
                                {{
                                    ...menu,
                                    props: {
                                        ...menu.props,
                                        onContextMenu: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        },
                                        onClick: (e) => {
                                            e.domEvent.stopPropagation();
                                            e.domEvent.preventDefault();
                                        },
                                    },
                                }}
                            </Fragment>
                        );
                    }}
                    overlayClassName={!mdMedia && "options-drawer"}
                    autoAdjustOverflow={mdMedia}
                    placement={mdMedia ? placement : "topRight"}
                    trigger={trigger}
                    menu={{
                        triggerSubMenuAction: mdMedia ? "hover" : "click",
                        subMenuCloseDelay: 0.3,
                        rootClassName: !mdMedia && "options-drawer",
                        items: isFromBackground
                            ? !project || isProjectGlobal
                                ? setKeyMenu(menuItems, "add") // If no project, only show "Ajouter"
                                : userRoles.EDITOR
                                  ? !!project.isResponseTender
                                      ? setKeyMenu(tenderItems, "tender")
                                      : setKeyMenu(addItems, "add")?.concat(
                                            tenderItems,
                                            "tender",
                                        )
                                  : setKeyMenu(menuItems, "add") // Default project options when not "isFromBackground"
                            : setKeyMenu(menuItems, "add"), // Default project options when not "isFromBackground"
                    }}
                >
                    {children || (
                        <Button
                            shape="circle"
                            type="text"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <MaterialIcons name="more_vert" />
                        </Button>
                    )}
                </Dropdown>
            )}
        </Fragment>
    );
};

const setKeyMenu = (array, keyName) => {
    if (keyName === "project") {
        return array
            .filter((e) => !!e?.isProject)
            .map((e, i) => ({
                key: keyName + "-" + i,
                ...e,
            }));
    }
    return array
        .filter((e) => !!e)
        .map((e, i) => ({
            key: keyName + "-" + i,
            ...e,
        }));
};

export default OptionsProject;
