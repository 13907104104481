import axios from "axios";

import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import TableInline from "components/tableInline/Table";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { TimesheetContext } from "../TimesheetViewContext";
import ModalProject from "../modalProject/ModalProject";

import { Button } from "antd";

const ProjectsTab = () => {
    const { t } = useTranslation();
    const { userProjects } = useContext(TimesheetContext);

    const getDataList = useCallback(async () => {
        return userProjects;
    }, [userProjects]);

    const editInline = useCallback(async (itemId, newItem) => {
        const { data } = await axios.patch(apiURL.updateTimesheetProject + itemId, {
            isTimesheet: typeof newItem.isTimesheet === "boolean" ? newItem.isTimesheet : false,
            referent: newItem.referent || undefined,
        });
        return data;
    }, []);

    return (
        <TableInline
            getFunction={getDataList}
            editFunction={editInline}
            ButtonOptions={ButtonOptions}
            fields={[
                {
                    key: "title",
                    label: t("dossier"),
                    width: "20vw",
                    isDisabled: true,
                    isSearchable: true,
                },
                {
                    key: "isTimesheet",
                    label: t("feuilleDeTemps"),
                    width: "140px",
                    isBoolean: true,
                },
                {
                    key: "customer.name",
                    label: t("client"),
                    width: "15vw",
                    isDisabled: true,
                },
                {
                    key: "referent",
                    label: t("referent"),
                },
            ]}
        />
    );
};

const ButtonOptions = ({ dataLine, setDataList }) => {
    const { addModalData } = useContext(ModalContext);
    const { startElementSyncMessage, successElementSyncMessage } = useContext(PopupContext);

    return (
        <Button
            type="text"
            onClick={() =>
                addModalData({
                    key: "modalProject",
                    title: dataLine.title,
                    content: <ModalProject />,
                    initialValues: {
                        ...dataLine,
                        customer: dataLine.customer?._id,
                        allowedTimesheetTasks: dataLine.allowedTimesheetTasks.map((e) => e._id),
                    },
                    handleConfirm: async (values) => {
                        startElementSyncMessage();
                        const { data } = await axios.patch(apiURL.updateTimesheetProject + dataLine._id, values);
                        if (Array.isArray(data)) {
                            setDataList((e) =>
                                e.map((k) =>
                                    data.find((d) => d._id === k._id)
                                        ? { ...k, ...data.find((d) => d._id === k._id) }
                                        : k,
                                ),
                            );
                        } else {
                            setDataList((e) => e.map((k) => (k._id !== dataLine._id ? k : newItem)));
                        }
                        successElementSyncMessage();
                    },
                })
            }>
            <MaterialIcons name="more_vert" />
        </Button>
    );
};

export default ProjectsTab;
