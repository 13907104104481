import axios from "axios";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";

import ProjectBreadcrumb from "./ProjectBreadcrumb";
import ProjectSelection from "./ProjectSelection";

import { Alert, Checkbox, Flex, Form, Input, theme } from "antd";

const ModalDuplicate = ({
    profile,
    project,
    refreshAction,
    customerConfig,
}) => {
    const { t } = useTranslation();

    const { token } = theme.useToken();

    const form = Form.useFormInstance();
    const titleRef = useRef(null);

    const { addModalData, removeModal } = useContext(ModalContext);

    const [parentProject, setParentProject] = useState(null);

    useEffect(() => {
        if (titleRef.current) {
            setTimeout(() => {
                titleRef.current.focus();
            }, 100);
        }
    }, [titleRef, form]);

    useEffect(() => {
        if (project?.parentProject && project?.tender && form) {
            const getParent = async () => {
                try {
                    const resParent = await axios.get(
                        apiURL.getProjectById + project.parentProject,
                    );

                    if (resParent?.data) {
                        form.setFieldValue("destinationFolder", resParent.data);
                        setParentProject(resParent.data);
                    }
                } catch (error) {
                    toastAlert("error", t("uneErreurEstSurvenue"));
                }
            };

            getParent();
        }
    }, [project, form]); // Ensure it runs when project changes

    const [selectedOptions, setSelectedOptions] = useState(
        form.getFieldValue("duplicationOptions") || [],
    );

    const onSelectProject = async (id) => {
        try {
            if (id) {
                const resProject = await axios.get(apiURL.getProjectById + id);

                if (resProject?.data)
                    form.setFieldValue("destinationFolder", resProject.data);
            } else {
                form.setFieldValue("destinationFolder", null);
                setParentProject(null);
            }
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        removeModal("selectDestinationFolder");
    };

    const handleCheckboxChange = (checkedValues) => {
        // Si on ne reprend pas les users, on ne peut pas reprendre timesheet
        if (!checkedValues.includes("users")) {
            checkedValues = checkedValues.filter(
                (value) => value !== "timesheet",
            );
        }

        setSelectedOptions(checkedValues);
        form.setFieldsValue({ duplicationOptions: checkedValues });
    };

    return (
        <Fragment>
            <Alert
                description={
                    <div>
                        {t(
                            "laDuplicationSeraRealiseeUniquementSurLarborescenceCompleteDesDossiersAPartirDeCeluiSelectionne",
                        ) + "."}
                        {!project.tender && (
                            <Fragment>
                                <br />
                                {t(
                                    "lesFichiersOuAutresElementsSpecifiquesAuProjetEnParticulierNeSerontPasRepris",
                                ) + "."}
                            </Fragment>
                        )}
                    </div>
                }
                type="info"
                showIcon
                style={{ marginBottom: token.marginMD }}
            />
            <Form.Item
                label={
                    <Text bold={true}>
                        {t("nomDuProjetRacineIssuDeLaDuplication")}
                    </Text>
                }
                hasFeedback
                name="title"
                validateTrigger="onChange"
                rules={[
                    {
                        max: constants.maxLengthProjectTitle,
                        required: true,
                        transform: (value) => value.trim(),
                    },
                ]}
                style={{ marginTop: token.marginMD }}
            >
                <Input
                    ref={titleRef}
                    placeholder={project?.title}
                    size="large"
                    required
                />
            </Form.Item>

            <div
                style={{
                    marginTop: token.marginLG,
                    marginBottom: token.marginLG,
                }}
            >
                <Form.Item
                    label={
                        <Text bold={true}>
                            {t("elementsReprisLorsDeLaDuplication")}
                        </Text>
                    }
                    name="duplicationOptions"
                    style={{ margin: 0 }}
                >
                    <Checkbox.Group
                        onChange={handleCheckboxChange}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 10,
                            marginTop: token.marginSM,
                        }}
                    >
                        {project.tender && (
                            <Checkbox value="files">
                                {t("fichiersDeLappelDoffre")}
                            </Checkbox>
                        )}
                        <Checkbox value="users">
                            {t("membresDuDossier")}
                        </Checkbox>
                        {!project.parentProject &&
                            customerConfig?.visas.isActive && (
                                <Checkbox value="visas">{t("visas")}</Checkbox>
                            )}
                        {customerConfig?.versioning.isActive && (
                            <Checkbox value="automations">
                                {t("automatisations")}
                            </Checkbox>
                        )}
                        {customerConfig?.timesheet.isActive &&
                            selectedOptions.includes("users") && (
                                <Checkbox value="timesheet">
                                    {t("configurationDeSaisieDeTemps")}
                                </Checkbox>
                            )}
                    </Checkbox.Group>
                </Form.Item>
            </div>

            <Form.Item
                label={<Text bold={true}>{t("emplacementDeDestination")}</Text>}
                style={{ marginTop: token.marginSM }}
                name="destinationFolder"
            >
                <Flex>
                    <ProjectBreadcrumb
                        readOnly={true}
                        project={
                            form.getFieldValue("destinationFolder") ||
                            parentProject
                        }
                        profile={profile}
                    />
                    <button
                        type="button"
                        onClick={(e) =>
                            addModalData({
                                key: "selectDestinationFolder",
                                title: t("emplacementDeDestination"),
                                content: (
                                    <ProjectSelection
                                        profile={profile}
                                        currentProject={project}
                                        refreshAction={refreshAction}
                                        onConfirm={onSelectProject}
                                        isDuplication={true}
                                    />
                                ),
                                isForm: false,
                            })
                        }
                        className="text-primary"
                        style={{ marginLeft: token.marginSM }}
                    >
                        {t("modifier")}
                    </button>
                </Flex>
            </Form.Item>
        </Fragment>
    );
};

export default ModalDuplicate;
