import { useAppStore } from "providers/ProviderApp";

import { Fragment, useContext, useEffect, useState } from "react";

import { getUserProjects } from "helpers/user";

import FilesList from "components/project/FilesList";
import SkeletonFileItem from "components/skeleton/SkeletonFileItem";

import { DriveToolsContext } from "./Drive";
import DriveEmptyLayout from "./DriveEmptyLayout";

import { Col, Row } from "antd";

const DriveDashboard = ({ profile, refreshAction, currentItem }) => {
    const { userRoles, onItemClick } = useContext(DriveToolsContext);

    const { viewMode } = useAppStore();
    const isListMode = viewMode === "list";
    const [projects, setProjects] = useState([]);

    const [isSkeletonVisible, showSkelton] = useState(true);

    useEffect(() => {
        const getProjects = async () => {
            showSkelton(true);
            const resProjects = await getUserProjects(false, true);
            if (resProjects) {
                setProjects(resProjects);
            }
            showSkelton(false);
        };

        getProjects();
    }, [profile]);

    return (
        <Fragment>
            {isSkeletonVisible && profile.projects.length > 0 && (
                <Row
                    gutter={[24, 24]}
                    style={{
                        padding: "24px 0",
                    }}
                >
                    {Array.from({ length: 8 }).map((_, i) => (
                        <Col
                            xxl={isListMode ? 24 : 6}
                            xl={isListMode ? 24 : 8}
                            lg={isListMode ? 24 : 12}
                            md={isListMode ? 24 : 12}
                            xs={24}
                            key={i}
                        >
                            <SkeletonFileItem />
                        </Col>
                    ))}
                </Row>
            )}

            {!isSkeletonVisible && projects.length > 0 && (
                <FilesList
                    profile={profile}
                    files={projects}
                    refreshAction={refreshAction}
                    isListMode={isListMode}
                    onItemClick={onItemClick}
                    currentItem={currentItem}
                    isDnd={false}
                    userRoles={userRoles}
                />
            )}

            {!isSkeletonVisible && projects.length === 0 && (
                <DriveEmptyLayout />
            )}
        </Fragment>
    );
};

export default DriveDashboard;
