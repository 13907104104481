import { Emoji } from "emoji-picker-react";

const ReactionsList = ({ reactions }) => {
    const groupedReactions = reactions.reduce((acc, reaction) => {
        if (acc[reaction.code]) {
            acc[reaction.code].count += 1;
        } else {
            acc[reaction.code] = { code: reaction.code, count: 1 };
        }
        return acc;
    }, {});

    const groupedReactionsArray = Object.values(groupedReactions);

    return (
        groupedReactionsArray.length > 0 && (
            <ul className="flex flex-row gap-2 hover:cursor-default">
                {groupedReactionsArray.map((reaction) => (
                    <li
                        key={reaction.code}
                        className="relative flex items-center"
                    >
                        <Emoji unified={reaction.code} size={18} />
                        {reaction.count > 1 && (
                            <span className="absolute top-5 text-xs">
                                +{reaction.count}
                            </span>
                        )}
                    </li>
                ))}
            </ul>
        )
    );
};

export default ReactionsList;
