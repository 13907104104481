import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fieldValidation } from "helpers/fieldValidation";
import { scrollToElementById } from "helpers/scroller";

const SeparatedTextField = ({
    text,
    maxLength,
    addedList,
    setAddedList,
    alreadyExistsList = [],
    isEmail = false,
    isDisabled = false,
}) => {
    const { t } = useTranslation();

    const [value, setValue] = useState("");

    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        scrollToElementById("separated-input", true);
        document.getElementById("separated-input").focus();

        return () => {
            setAddedList([]);
        };
    }, []);

    const handleKeyDown = (e) => {
        if (
            // Ajout de l'élément avec les touches "," / "ENTER" / "TAB"
            (e.keyCode === 188 || e.keyCode === 13 || e.keyCode === 9) &&
            !addedList.some((item) => item === e.target.value)
        ) {
            handleAddItem(e);
        }
    };

    const handleChange = (e) => {
        if (e.keyCode === 188) {
            e.preventDefault();
        } else setValue(e.target.value.replace(/,/g, ""));
    };

    const handleAddItem = async (e) => {
        if (e.target.value === "") return;

        const resValidation = fieldValidation(e.target.value, "email");
        if (resValidation !== "" && isEmail) {
            setErrorMsg(resValidation);
        } else {
            if (alreadyExistsList.length > 0) {
                // Si on reçoit une liste d'éléments déjà présents, on vérifie que l'élément ajouté n'est pas déjà présent
                if (alreadyExistsList.includes(e.target.value)) {
                    setErrorMsg(t("cetUtilisateurFaitDejaPartieDuDossier"));
                    return;
                }
            }
            setErrorMsg("");
            addItem(e.target.value);
            setValue("");
        }

        setTimeout(() => {
            scrollToElementById("separated-input", true);
            document.getElementById("separated-input").focus();
        });
    };

    const addItem = (item) => {
        setAddedList([...addedList, item]);
    };

    const removeItem = (itemIndex) => {
        setAddedList([
            ...addedList.slice(0, itemIndex),
            ...addedList.slice(itemIndex + 1),
        ]);
    };

    return (
        <Fragment>
            {text && (
                <label className="block text-gray-700 text-sm font-bold mb-2 text-left">
                    {text}
                </label>
            )}

            <div>
                <div
                    className={
                        "flex flex-row overflow-auto max-w-full border rounded-lg shadow" +
                        (isDisabled ? " bg-slate-200" : "")
                    }
                >
                    {addedList.map((item, i) => (
                        <div
                            key={i}
                            className="flex flex-row bg-teal-600 rounded-full text-white text-sm px-2 h-[20px] my-auto mx-1 whitespace-nowrap hover:cursor-pointer"
                            onClick={(e) => removeItem(i)}
                        >
                            {item}
                            <i
                                className="material-icons notranslate ml-1 my-auto"
                                style={{ fontSize: "1rem" }}
                            >
                                close
                            </i>
                        </div>
                    ))}

                    <input
                        id="separated-input"
                        className={
                            "min-w-[200px] w-full py-2 text-gray-700 focus:outline-none focus:shadow-outline ml-2 text-sm"
                        }
                        value={value}
                        maxLength={maxLength}
                        type="text"
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={(e) => handleChange(e)}
                        onBlur={(e) => handleAddItem(e)}
                        disabled={isDisabled}
                    />
                </div>
            </div>

            {errorMsg && (
                <div className="error-msg font-bold text-red-500 text-xs italic mt-2">
                    {errorMsg}
                </div>
            )}
        </Fragment>
    );
};

export default SeparatedTextField;
