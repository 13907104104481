import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext, useEffect, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { getAppImage } from "helpers/image";
import { getUserProjects, isTimesheetWorker } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import DocumentReader from "components/documents/DocumentReader";
import IntercomChat from "components/integrations/IntercomChat";
import ModalCreateProject from "components/project/ModalCreateProject";
import ProjectArbo from "components/project/ProjectArbo";

import { ModalContext } from "layout/LayoutModal";

import { DriveToolsContext } from "./Drive";
import NewActions from "./NewActions";

import { Divider, Flex, Menu, theme } from "antd";

const DriveSideMenu = ({
    currentProject,
    onlyContent = false,
    closeSideMenu,
    refreshAction,
    userRoles,
    toolView = "",
}) => {
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");
    const { permissionsTools, customerConfig } = useProfileStore();
    const { userProfile: profile } = useContext(DriveToolsContext);
    const { addModalData } = useContext(ModalContext);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [projects, setProjects] = useState();
    const [openKeys, setOpenKeys] = useState(["folders"]);
    const [renderDocumentFileId, setRenderDocumentFileId] = useState(null);

    useEffect(() => {
        const getProjects = async () => {
            const resProjects = await getUserProjects(false, true);
            setProjects(resProjects);
        };

        getProjects();
    }, [profile]);

    const closeReader = () => {
        setRenderDocumentFileId(null);
    };

    const toggleSubMenu = (key) => {
        setOpenKeys((prevKeys) =>
            prevKeys.includes(key)
                ? prevKeys.filter((k) => k !== key)
                : [...prevKeys, key],
        );
    };

    const customExpandIcon = ({ isOpen, key }) => (
        <MaterialIcons
            name="keyboard_arrow_down"
            style={{
                transform: "rotate(" + (isOpen ? 180 : 0) + "deg)",
                padding: token.paddingXS,
            }}
            onClick={(e) => {
                e.stopPropagation();
                toggleSubMenu(key);
            }}
        />
    );

    const createProject = () => {
        addModalData({
            key: "createFolder",
            title: t("nouveauDossier"),
            okText: t("confirmer"),
            method: "BLOCK",
            content: (
                <ModalCreateProject
                    profile={profile}
                    refreshAction={refreshAction}
                />
            ),
            handleConfirm: async (formData) => {
                try {
                    const projectFields = {
                        projectTitle: formData.title,
                        email: profile.user.email,
                        language: profile.user.language.code,
                        parentProject: null,
                        userId: profile.user._id,
                        sendNotification: true,
                    };

                    const resProject = await axios.post(
                        apiURL.createProject,
                        projectFields,
                    );

                    if (resProject && resProject.data) {
                        refreshAction();
                        setTimeout(() => {
                            return navigate(
                                "/drive/project/" + resProject.data._id,
                            );
                        }, 200);
                    } else {
                        toastAlert(
                            "error",
                            t("uneErreurEstSurvenueLorsDeLaCreationDuDossier"),
                        );
                    }
                } catch (error) {
                    toastAlert("error", t("uneErreurEstSurvenue"));
                }
            },
        });
    };

    const { token } = theme.useToken();

    return (
        <div
            style={{
                backgroundColor: token["purple-10"],
                height: "100vh",
                overflowY: "auto",
                paddingTop: token.paddingSM,
                userSelect: "none",
            }}
            className={isDesktop ? " scrollbar-hover" : ""}
        >
            {!onlyContent && (
                <Link
                    to={
                        isTimesheetWorker(permissionsTools)
                            ? "/portal"
                            : "/drive"
                    }
                >
                    <img
                        src={
                            customerConfig?.logo
                                ? getAppImage(
                                      "customers/" + customerConfig.logo,
                                  )
                                : "/img/logo-big.png"
                        }
                        className="m-auto w-[200px]"
                        alt={
                            customerConfig
                                ? customerConfig.name
                                : t("applicationTitle")
                        }
                    />
                </Link>
            )}

            {mdMedia && (
                <div style={{ width: "75%", margin: "auto" }}>
                    <Divider
                        style={{
                            borderColor: "#fff",
                        }}
                    />
                </div>
            )}

            {(!currentProject || (currentProject && userRoles.EDITOR)) &&
                toolView === "" && (
                    <div className="relative hidden md:block mx-4">
                        <NewActions
                            currentProject={currentProject}
                            closeSideMenu={closeSideMenu}
                            userRoles={userRoles}
                        />
                    </div>
                )}

            <Menu
                theme="dark"
                mode="inline"
                id="side-menu"
                openKeys={openKeys}
                onClick={closeSideMenu}
                style={{
                    marginTop: 20,
                }}
                selectedKeys={pathname === "/drive" ? ["folders"] : [pathname]}
                inlineIndent={12}
                items={[
                    // {
                    //     key: "workspace",
                    //     label: (
                    //         <Select
                    //             style={{
                    //                 width: "100%",
                    //             }}
                    //             options={[
                    //                 {
                    //                     label: "Chantiers 2024",
                    //                     value: "2024",
                    //                 },
                    //                 {
                    //                     label: "Chantiers 2025",
                    //                     value: "2025",
                    //                 },
                    //             ]}
                    //         />
                    //     ),
                    // },
                    // {
                    //     key: "/timesheet/absences-validation",
                    //     label: (
                    //         <MenuItem
                    //             path="/timesheet/absences-validation"
                    //             label={t("equipe")}
                    //             icon="groups"
                    //         />
                    //     ),
                    // },
                    // {
                    //     type: "divider",
                    //     style: {
                    //         borderColor: "white",
                    //         margin: token.marginSM,
                    //     },
                    // },
                    (permissionsTools.timesheetAdmin.canView ||
                        permissionsTools.timesheetUser.canView ||
                        permissionsTools.automations.canView ||
                        permissionsTools.visas.canView ||
                        permissionsTools.photosUser.canView ||
                        permissionsTools.photosAdmin.canView ||
                        permissionsTools.formsAdmin.canView ||
                        permissionsTools.formsUser.canView ||
                        customerConfig?.isLightViewer) && {
                        key: "tools",
                        label: <MenuItem label={t("outils")} icon="settings" />,
                        onTitleClick: (e) => e.domEvent.preventDefault(),
                        expandIcon: (props) =>
                            customExpandIcon({ ...props, key: "tools" }),
                        children: [
                            permissionsTools.timesheetAdmin.canView && {
                                key: "/timesheet/entries",
                                label: (
                                    <MenuItem
                                        path="/timesheet/entries"
                                        label={t("feuilleDeTemps")}
                                        icon="timer"
                                    />
                                ),
                            },
                            permissionsTools.timesheetAdmin.canView && {
                                key: "/timesheet/absences-validation",
                                label: (
                                    <MenuItem
                                        path="/timesheet/absences-validation"
                                        label={t("absences")}
                                        icon="sunny"
                                    />
                                ),
                            },
                            permissionsTools.timesheetAdmin.canView && {
                                key: "/timesheet/summary-counter",
                                label: (
                                    <MenuItem
                                        path="/timesheet/summary-counter"
                                        label={t("compteurDheures")}
                                        icon="timelapse"
                                    />
                                ),
                            },
                            permissionsTools.timesheetAdmin.canView && {
                                type: "divider",
                                style: {
                                    borderColor: "white",
                                    margin: token.marginSM,
                                },
                            },
                            permissionsTools.timesheetUser.canView && {
                                key: "/timesheet/summary",
                                label: (
                                    <MenuItem
                                        path="/timesheet/summary"
                                        label={t("saisieDeTemps")}
                                        icon="timer"
                                    />
                                ),
                            },
                            permissionsTools.timesheetUser.canView && {
                                key: "/timesheet/absences",
                                label: (
                                    <MenuItem
                                        path="/timesheet/absences"
                                        label={t("mesAbsences")}
                                        icon="sunny"
                                    />
                                ),
                            },
                            // permissionsTools.timesheetUser.canView && {
                            //     key: "/timesheet/hour-counter",
                            //     label: (
                            //         <MenuItem
                            //             path="/timesheet/hour-counter"
                            //             label={t("compteurDheures")}
                            //             icon="timelapse"
                            //         />
                            //     ),
                            // },
                            permissionsTools.timesheetUser.canView && {
                                type: "divider",
                                style: {
                                    borderColor: "white",
                                    margin: token.marginSM,
                                },
                            },
                            (permissionsTools.formsUser.canView ||
                                permissionsTools.formsAdmin.canView) && {
                                key: "/forms",
                                label: (
                                    <MenuItem
                                        path="/forms"
                                        label={t("formulaires")}
                                        icon="edit_note"
                                    />
                                ),
                            },
                            (permissionsTools.photosUser.canView ||
                                permissionsTools.photosAdmin.canView) && {
                                key: "/photos",
                                label: (
                                    <MenuItem
                                        path="/photos"
                                        label={t("photos")}
                                        icon="photo_camera"
                                    />
                                ),
                            },
                            permissionsTools.automations.canView && {
                                key: "/automations/visa",
                                label: (
                                    <MenuItem
                                        path={
                                            permissionsTools.automations
                                                .canViewVisas
                                                ? "/automations/visa"
                                                : permissionsTools.versioning
                                                        .canView
                                                  ? "/automations/nomenclature"
                                                  : ""
                                        }
                                        label={t("automatisations")}
                                        icon="electric_bolt"
                                    />
                                ),
                            },
                            permissionsTools.visas.canView && {
                                key: "/visas",
                                label: (
                                    <MenuItem
                                        path="/visas"
                                        label={t("visas")}
                                        icon="approval"
                                    />
                                ),
                            },
                            (permissionsTools.plansUser.canView ||
                                permissionsTools.plansAdmin.canView) && {
                                key: "/plans",
                                label: (
                                    <MenuItem
                                        path="/plans"
                                        label={t("plans")}
                                        icon="map"
                                    />
                                ),
                            },
                            customerConfig?.isLightViewer && {
                                key: "/statistics",
                                label: (
                                    <MenuItem
                                        path="/statistics"
                                        label={t("statistiques")}
                                        icon="equalizer"
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        key: "folders",
                        className:
                            "arbo " +
                            (pathname === "/drive"
                                ? "ant-menu-item-selected-drive"
                                : ""),
                        label: (
                            <MenuItem
                                label={t("mesProjets")}
                                icon="folder_open"
                            />
                        ),
                        onTitleClick: (e) => {
                            e.domEvent.preventDefault();
                            navigate("/drive");
                        },
                        expandIcon: (props) =>
                            customExpandIcon({ ...props, key: "folders" }),
                        children: [
                            {
                                key: "/drive",
                                label: projects ? (
                                    projects.length > 0 ? (
                                        <div
                                            style={{
                                                marginTop: mdMedia
                                                    ? token.marginXXS
                                                    : 0,
                                                marginBlock: token.marginXS,
                                            }}
                                        >
                                            <ProjectArbo
                                                profile={profile}
                                                projects={projects}
                                                closeMenu={closeSideMenu}
                                                currentProject={currentProject}
                                                refreshAction={refreshAction}
                                                isWhiteText={true}
                                                userRoles={userRoles}
                                            />
                                        </div>
                                    ) : mdMedia ? (
                                        <MenuItem
                                            onClick={createProject}
                                            label={t(
                                                "creezVotrePremierDossier",
                                            )}
                                            icon="add"
                                        />
                                    ) : (
                                        <MenuItem
                                            onClick={createProject}
                                            label={t(
                                                "creezVotrePremierDossier",
                                            )}
                                            icon="add"
                                        />
                                    )
                                ) : (
                                    <MaterialIcons
                                        style={{
                                            width: "min-content",
                                            display: "block",
                                            margin: "0 auto",
                                        }}
                                        spin
                                        color="white"
                                        size="sm"
                                        name="progress_activity"
                                    />
                                ),
                            },
                        ],
                    },
                    {
                        key: "/flow",
                        label: (
                            <MenuItem
                                path="/flow"
                                label={t("flux")}
                                icon="rss_feed"
                            />
                        ),
                    },
                    {
                        key: "/recents",
                        label: (
                            <MenuItem
                                path="/recents"
                                label={t("recents")}
                                icon="schedule"
                            />
                        ),
                    },
                    {
                        key: "/favorites",
                        label: (
                            <MenuItem
                                path="/favorites"
                                label={t("favoris")}
                                icon="favorite"
                            />
                        ),
                    },
                ]}
            />

            {!mdMedia && <IntercomChat isFromMenu={true} />}

            {renderDocumentFileId && (
                <DocumentReader
                    fileId={renderDocumentFileId}
                    closeReader={closeReader}
                    profile={profile}
                    refreshAction={refreshAction}
                />
            )}
        </div>
    );
};

const MenuItem = ({ onClick = () => {}, path, label, icon }) => {
    if (!path)
        return (
            <Flex onClick={onClick} align="center" gap="small">
                <MaterialIcons name={icon} size="xs" />
                <span>{label}</span>
            </Flex>
        );
    return (
        <Link to={path}>
            <Flex align="center" gap="small">
                <MaterialIcons name={icon} size="xs" />
                <span>{label}</span>
            </Flex>
        </Link>
    );
};

export default DriveSideMenu;
