import axios from "axios";
import moment from "moment";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getDaysBetweenTwoDates } from "helpers/date";
import { capitalize } from "helpers/string";
import { absencesStatutOptions, absencesTypesOptions } from "helpers/timesheet";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { useTimesheetStore } from "../TimesheetStore";
import ModalAbsence from "../modalAbsence/ModalAbsence";

import { Button, Card, Col, DatePicker, Empty, Flex, List, Row, Spin, Tag } from "antd";

const TabAbsences = () => {
    const { t } = useTranslation();

    const { addModalData } = useContext(ModalContext);
    const { pagination, setPagination } = useTimesheetStore();
    const { startElementSyncMessage, successElementSyncMessage } = useContext(PopupContext);
    const [userAbsences, setUserAbsences] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        axios
            .post(apiURL.getAbsences, { pagination })
            .then(({ data }) => setUserAbsences(data))
            .finally(() => setLoading(false));
    }, [pagination]);

    const openAskModal = () => {
        addModalData({
            key: "modalAbsence",
            title: t("nouvelleDemande"),
            content: <ModalAbsence />,
            handleConfirm: async (values) => {
                startElementSyncMessage();
                const { data } = await axios.post(apiURL.createAbsence, values);
                if (moment(values.startDate).get("month") === moment(pagination).get("month"))
                    setUserAbsences([...userAbsences, data]);
                else setPagination(moment(values.startDate).toISOString());
                successElementSyncMessage();
            },
        });
    };

    // const [availablesDays, setAvailablesDays] = useState();
    // useEffect(() => {
    // axios
    //     .post(apiURL.getAbsencesAvailables)
    //     .then(({ data }) => setAvailablesDays(data.reduce((acc, { startDate, endDate }) => acc + getDaysBetweenTwoDates(endDate, startDate), 0)));
    // }, [pagination]);
    // const renderTextDaysDuration = (duration) => `${Number(duration || 0)} ${duration > 1 ? t("jours") : t("jour")}`;

    return (
        <Row
            gutter={[16, 16]}
            style={{
                marginBottom: "20vh",
            }}>
            <Col lg={6} span={24}>
                <Flex vertical gap="large">
                    <Flex align="center">
                        <Button
                            onClick={() => setPagination(moment(pagination).subtract(1, "month").toISOString())}
                            type="text"
                            icon={<MaterialIcons name={"chevron_left"} />}
                        />
                        <DatePicker
                            picker="month"
                            inputReadOnly
                            variant="borderless"
                            suffixIcon={false}
                            allowClear={false}
                            locale="fr"
                            styles={{
                                textAlign: "center",
                            }}
                            style={{
                                fontWeight: "bold",
                            }}
                            value={moment(pagination)}
                            format={(time) => capitalize(time.format("MMMM YYYY"))}
                            onChange={(date) => {
                                setPagination(moment(date).toISOString());
                            }}
                        />
                        <Button
                            onClick={() => setPagination(moment(pagination).add(1, "month").toISOString())}
                            type="text"
                            icon={<MaterialIcons name={"chevron_right"} />}
                        />
                    </Flex>
                    <Button type="primary" size="large" onClick={openAskModal}>
                        {t("nouvelleDemande")}
                    </Button>
                    {/* <Card title={capitalize(t("soldes"))}>
                        <Text>{renderTextDaysDuration(availablesDays)}</Text>
                    </Card> */}
                </Flex>
            </Col>
            <Col lg={18} span={24}>
                <Spin spinning={loading}>
                    <Row gutter={[16, 16]}>
                        {userAbsences?.length ? (
                            userAbsences.map((item) => (
                                <Col span={24} xs={24} sm={12} md={12} lg={12} xl={8} key={item._id}>
                                    <AbsenceCard item={item} setUserAbsences={setUserAbsences} />
                                </Col>
                            ))
                        ) : (
                            <Empty
                                style={{
                                    width: "100%",
                                }}
                            />
                        )}
                    </Row>
                </Spin>
            </Col>
        </Row>
    );
};

const AbsenceCard = ({ item, setUserAbsences }) => {
    const { t } = useTranslation();
    const { addModalData } = useContext(ModalContext);
    const absenceType = absencesTypesOptions.find((e) => e.value === item.type);
    const absenceStatut = absencesStatutOptions.find((e) => e.value === item.statut);
    const renderTextDaysDuration = (duration) => `${Number(duration || 0)} ${duration > 1 ? t("jours") : t("jour")}`;
    const { startElementSyncMessage, successElementSyncMessage } = useContext(PopupContext);
    const isValidated = item.statut !== "PENDING";
    const { pagination, setPagination } = useTimesheetStore();

    return (
        <List.Item>
            <Card
                size="small"
                title={t(absenceType.label)}
                extra={
                    <Flex gap="small" align="center">
                        <Tag color={absenceStatut.color}>{t(absenceStatut.label)}</Tag>
                        {!isValidated && (
                            <Button
                                onClick={() => {
                                    addModalData({
                                        key: "modalAbsence",
                                        title: t("confimerLaSuppressionDefinitive?"),
                                        handleConfirm: async () => {
                                            startElementSyncMessage();
                                            await axios.delete(apiURL.removeAbsence + item._id);
                                            setUserAbsences((prev) => prev.filter((e) => e._id !== item._id));
                                            successElementSyncMessage();
                                        },
                                    });
                                }}
                                size="small"
                                type="text"
                                shape="circle"
                                icon={<MaterialIcons name="delete" />}
                            />
                        )}
                    </Flex>
                }
                hoverable
                onClick={(e) => {
                    if (e.target.closest(".ant-btn")) {
                        return;
                    }

                    addModalData({
                        key: "modalAbsence",
                        title: (
                            <Flex gap="large" align="center">
                                <Text size="large" strong>
                                    {isValidated ? t(absenceType.label) : t("modifierAbsence")}
                                </Text>
                                <Tag color={absenceStatut.color}>{t(absenceStatut.label)}</Tag>
                            </Flex>
                        ),
                        formDisabled: item.statut !== "PENDING",
                        footer: item.statut === "PENDING",
                        initialValues: item,
                        content: <ModalAbsence />,
                        handleConfirm: async (values) => {
                            startElementSyncMessage();
                            const { data } = await axios.patch(apiURL.updateAbsence + item._id, values);
                            if (moment(values.startDate).get("month") === moment(pagination).get("month"))
                                setUserAbsences((prev) => prev.map((e) => (e._id === item._id ? data : e)));
                            else setPagination(moment(values.startDate).toISOString());
                            successElementSyncMessage();
                        },
                    });
                }}>
                <Flex vertical gap="small">
                    <Text>
                        <b>{t("dateDeDebut")} : </b> {moment(item.startDate).format("DD/MM/YYYY")}
                    </Text>
                    <Text>
                        <b>{t("dateDeFin")} : </b> {moment(item.endDate).format("DD/MM/YYYY")}
                    </Text>
                    <Text>
                        <b>{t("duree")} : </b>
                        {renderTextDaysDuration(
                            getDaysBetweenTwoDates(moment(item.endDate), moment(item.startDate).subtract(1, "day")),
                        )}
                    </Text>
                    <Text
                        paragraph
                        size="small"
                        ellipsis={{
                            rows: 1,
                            expandable: true,
                            symbol: "Voir plus",
                        }}
                        style={{
                            whiteSpace: "pre-wrap",
                            margin: 0,
                        }}>
                        {item.comment}
                    </Text>
                </Flex>
            </Card>
        </List.Item>
    );
};

export default TabAbsences;
