import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { DriveToolsContext } from "components/drive/Drive";
import Spinner from "components/utils/Spinner";

import TableHeader from "./TableHeader";
import TableDataRow from "./TableRowData";
import TableNewRow from "./TableRowNew";
import { applyFilters, applySearch } from "./manageTable";

export const TableInlineContext = createContext(null);

const TableInline = ({
    addFunction = false,
    getFunction = async () => {},
    editFunction = async () => {},
    orderingFunction = false,
    ButtonOptions = null,
    fields = [],
    hasFilters = false,
}) => {
    const { toolsData } = useContext(DriveToolsContext);

    const [dataList, setDataList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [inlineLoader, setInlineLoader] = useState(true);

    const [clientFilterSearch, setClientFilterSearch] = useState({});

    const refTableBody = useRef(null);

    useEffect(() => {
        setInlineLoader(true);
        getFunction()
            .then(setDataList)
            .finally(() => setInlineLoader(false));
    }, []);

    const onEditInline = useCallback(
        async (dataLine, fieldKey, newValue) => {
            if (newValue === "") {
                return {
                    status: 400,
                    data: null,
                    message: "Cellule vide",
                };
            }

            setLoader(true);

            if (fieldKey.includes(".")) {
                const keys = fieldKey.split(".");
                // [keys[0]] === project.object
                // [keys[1]] === project.object.children
                const newItem = {
                    ...dataLine,
                    [keys[0]]: { ...dataLine[keys[0]], [keys[1]]: newValue },
                };
                await editFunction(dataLine._id, newItem);
                setDataList((e) => e.map((k) => (k._id !== dataLine._id ? k : newItem)));
                setLoader(false);

                return {
                    status: 200,
                    data: newItem,
                };
            } else {
                let newItem = {
                    ...dataLine,
                    [fieldKey]: newValue,
                };
                if (fieldKey === "type") {
                    newItem.timeCondition = null;
                }
                if (fieldKey === "type" || fieldKey === "options") {
                    newItem.defaultValue = null;
                }

                const response = await editFunction(dataLine._id, newItem);
                if (Array.isArray(response)) {
                    setDataList((e) =>
                        e.map((k) =>
                            response.find((r) => r._id === k._id)
                                ? {
                                      ...k,
                                      ...response.find((r) => r._id === k._id),
                                  }
                                : k,
                        ),
                    );
                } else {
                    setDataList((e) => e.map((k) => (k._id !== dataLine._id ? k : newItem)));
                }
                setLoader(false);

                return {
                    status: 200,
                    data: newItem,
                };
            }
        },
        [setDataList, setLoader, editFunction],
    );

    const onAddInline = useCallback(
        async (newdataLine) => {
            setLoader(true);
            const responseNewdataLine = await addFunction(newdataLine);
            setDataList((e) => [...e, responseNewdataLine]);
            setLoader(false);
        },
        [setDataList, setLoader],
    );

    const onOrderingInline = useCallback(
        async (source, target) => {
            if (source._id === target._id) {
                return;
            }

            setLoader(true);

            const newList = dataList
                .map((item) =>
                    item.order === source.order
                        ? { ...item, order: target.order }
                        : target.order > source.order
                          ? item.order > source.order && item.order <= target.order
                              ? { ...item, order: item.order - 1 }
                              : item
                          : item.order >= target.order && item.order < source.order
                            ? { ...item, order: item.order + 1 }
                            : item,
                )
                .sort((a, b) => (a.order > b.order ? 1 : -1));

            const response = await orderingFunction(
                newList.filter((e) => e.order !== dataList.find((k) => k._id === e._id).order),
                newList,
            );
            if (response) setDataList(newList);
            setLoader(false);
        },
        [dataList],
    );

    const formatedDataList = useMemo(() => {
        let result = [...dataList];

        if (Object.keys(clientFilterSearch).length) {
            result = applySearch(result, clientFilterSearch);
        }
        if (toolsData.filters) {
            result = applyFilters(result, toolsData.filters);
        }
        return result;
    }, [dataList, clientFilterSearch, toolsData.filters]);

    return (
        <TableInlineContext.Provider
            value={{
                dataList,
                setDataList,
                //
                fields,
                refTableBody,
                //
                onAddInline,
                onEditInline,
                onOrderingInline,
                setLoader,
            }}
        >
            {loader && <Spinner />}

            <table className="w-full border border-separate border-spacing-0">
                <TableHeader setClientFilterSearch={setClientFilterSearch} hasFilters={hasFilters} />

                <tbody ref={refTableBody}>
                    {inlineLoader && (
                        <tr>
                            <td></td>
                            <td className="py-4" colSpan="8">
                                <Spinner isSmall />
                            </td>
                        </tr>
                    )}

                    {!inlineLoader &&
                        !!formatedDataList?.length &&
                        formatedDataList.map((dataLine, index) => (
                            <TableDataRow
                                key={dataLine._id}
                                dataLine={dataLine}
                                indexRow={index}
                                ButtonOptions={ButtonOptions}
                                orderingFunction={orderingFunction}
                            />
                        ))}

                    {!inlineLoader && addFunction && <TableNewRow />}
                </tbody>
            </table>
        </TableInlineContext.Provider>
    );
};

export default TableInline;
