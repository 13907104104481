export function initDropzone(dropZoneElem, dropZoneInputElem, onDrop) {
    [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
    ].forEach(function (event) {
        dropZoneElem.addEventListener(event, function (e) {
            e.preventDefault();
            e.stopPropagation();
        });
    });

    dropZoneElem.addEventListener(
        "dragover",
        function (e) {
            this.classList.add("dropzone-dragging");
        },
        false,
    );

    dropZoneElem.addEventListener(
        "dragleave",
        function (e) {
            this.classList.remove("dropzone-dragging");
        },
        false,
    );

    dropZoneElem.addEventListener(
        "drop",
        function (e) {
            this.classList.remove("dropzone-dragging");
            var files = e.dataTransfer.files;
            var dataTransfer = new DataTransfer();

            Array.prototype.forEach.call(files, (file) => {
                dataTransfer.items.add(file);
            });

            var filesToBeAdded = dataTransfer.files;
            dropZoneInputElem.files = filesToBeAdded;

            onDrop();
        },
        false,
    );
}

export function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}

export function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const parseDataTransfert = (e) => {
    const { items } = e.dataTransfer;
    const files = [];
    const filesByRoutes = {};
    const traverseFileTree = (item, path = "") => {
        if (item.isFile) {
            item.file((file) => {
                file.path = path + file.name; // Custom path for folder structure
                files.push(file);

                // Regroup files by route
                if (!filesByRoutes[path]) {
                    filesByRoutes[path] = [];
                }
                filesByRoutes[path].push(file);
            });
        } else if (item.isDirectory) {
            const dirReader = item.createReader();
            dirReader.readEntries((entries) => {
                if (entries.length === 0) {
                    // If the directory is empty, treat it as a file and add isEmptyFolder prop
                    const emptyFolder = {
                        name: item.name,
                        path: path + item.name + "/",
                        isEmptyFolder: true, // Mark as an empty folder
                        size: 0,
                    };
                    files.push(emptyFolder); //
                    // Add to filesByRoutes

                    // filesByRoutes[path].push(emptyFolder);
                } else {
                    entries.forEach((entry) =>
                        traverseFileTree(entry, path + item.name + "/"),
                    );
                }
            });
        }
    };
    for (let i = 0; i < items.length; i++) {
        const entry = items[i].webkitGetAsEntry();
        if (entry) {
            traverseFileTree(entry);
        }
    }
    return files;
};

// Function for allow old browser / From https://stackoverflow.com/a/74528564
export async function copyToClipBoard(text) {
    return await new Promise((resolve, reject) => {
        if (
            typeof navigator !== "undefined" &&
            typeof navigator.clipboard !== "undefined" &&
            navigator.permissions !== "undefined"
        ) {
            const type = "text/plain";
            const blob = new Blob([text], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            navigator.permissions
                .query({ name: "clipboard-write" })
                .then((permission) => {
                    if (
                        permission.state === "granted" ||
                        permission.state === "prompt"
                    ) {
                        navigator.clipboard
                            .write(data)
                            .then(resolve, reject)
                            .catch(reject);
                    } else {
                        reject(new Error("Permission not granted!"));
                    }
                });
        } else if (
            document.queryCommandSupported &&
            document.queryCommandSupported("copy")
        ) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed";
            textarea.style.width = "2em";
            textarea.style.height = "2em";
            textarea.style.padding = 0;
            textarea.style.border = "none";
            textarea.style.outline = "none";
            textarea.style.boxShadow = "none";
            textarea.style.background = "transparent";
            document.body.appendChild(textarea);
            textarea.focus();
            textarea.select();
            try {
                document.execCommand("copy");
                document.body.removeChild(textarea);
                resolve();
            } catch (e) {
                document.body.removeChild(textarea);
                reject(e);
            }
        } else {
            reject(
                new Error(
                    "None of copying methods are supported by this browser!",
                ),
            );
        }
    });
}

export function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
    ) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}
