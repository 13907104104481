import axios from "axios";
import i18n from "i18next";
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getCurrentProfile } from "actions/profile";
import apiURL from "utils/apiURL";

import TriggerLine from "./TriggerLine";

const TriggerSection = ({
    profile: { profile },
    getCurrentProfile,
    formData,
    setFormData,
    type,
}) => {
    const [itemTypes, setItemTypes] = useState([]);
    const [itemActions, setItemActions] = useState([]);

    useEffect(() => {
        if (!profile) getCurrentProfile();
    }, []);

    useEffect(() => {
        axios.get(apiURL.getAllAutomationTriggerTypes).then(({ data }) => {
            const typesList = [];
            data.sort((a, b) => (a.position > b.position ? 1 : -1)).map(
                (type) => {
                    return typesList.push({
                        val: type._id,
                        label: type["title" + i18n.locale],
                    });
                },
            );
            setItemTypes(typesList);
        });
    }, []);

    useEffect(() => {
        if (formData.trigger.itemType) {
            axios
                .get(apiURL.getAllAutomationTriggerActions)
                .then(({ data }) => {
                    const actionsList = [];
                    data.sort((a, b) => (a.position > b.position ? 1 : -1)).map(
                        (action) => {
                            return actionsList.push({
                                val: action._id,
                                label: action["title" + i18n.locale],
                            });
                        },
                    );
                    setItemActions(actionsList);
                });
        }
    }, [formData.trigger.itemType]);

    return (
        <TriggerLine
            profile={profile}
            formData={formData}
            setFormData={setFormData}
            itemTypes={itemTypes}
            itemActions={itemActions}
            type={type}
        />
    );
};

TriggerSection.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(TriggerSection);
