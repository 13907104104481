import axios from "axios";
import i18n from "i18next";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { checkBIMCollabRole, isBIMCollabAvailable } from "helpers/bcf";
import { getCommentUserName } from "helpers/comment";
import {
    fromDateToStringDate,
    fromDateToStringDateHours,
    isDateAfter,
} from "helpers/date";
import { fieldValidation } from "helpers/fieldValidation";
import { formatMentions } from "helpers/text";
import {
    addBCFMarker,
    applyBCF,
    formatBCFNumber,
    generateBCFContent,
    getBCFImage,
    saveSceneSettings,
    takeScreenshot,
} from "helpers/viewer";
import apiURL from "utils/apiURL";

import BCFForm from "components/bcf/BCFForm";
import SubCommentForm from "components/comment/SubCommentForm";
import SubCommentList from "components/comment/SubCommentList";
import { DriveToolsContext } from "components/drive/Drive";
import Button from "components/input/Button";
import ConfirmationModal from "components/modal/ConfirmationModal";
import InformationModal from "components/modal/InformationModal";
import EmojisReactions from "components/reactions/EmojisReactions";
import ReactionsList from "components/reactions/ReactionsList";
import ShareProjectFile from "components/share/ShareProjectFile";
import UserCircle from "components/user/UserCircle";
import Badge from "components/utils/Badge";
import OptionMenu from "components/utils/OptionMenu";
import Popover from "components/utils/Popover";
import Spinner from "components/utils/Spinner";
import VisaBadge from "components/visa/VisaBadge";

import { ModalContext } from "layout/LayoutModal";

import CommentTextarea from "./CommentTextarea";
import { CommentsContext } from "./CommentsContent";

const CommentItem = ({
    profile,
    files,
    filesIds,
    comment,
    commentIndex,
    isFromMobile = false,
    set2DPlanVisible,
}) => {
    const { t } = useTranslation();

    const {
        comments,
        setComments,
        members,
        isTilesView,
        isListMode,
        setListMode,
        isFromViewer,
        customerConfig,
        userRoles,
        bimCollabMembers,
        activeBCF,
        deleteMarkerFromList,
        world,
        addMarkerToList,
        setActiveBCF,
        compiledFile,
        currentBIMCollabUser,
        respondToComment,
        areMarkersVisible,
        handleMarkerDeleted,
        isLightViewer,
    } = useContext(CommentsContext);

    const { addModalData } = useContext(ModalContext);

    const [confirmationModal, setConfirmationModal] = useState(null);
    const [informationModal, setInformationModal] = useState(null);
    const [loader, setLoader] = useState(false);

    const [isEdit, setEdit] = useState(false);
    const [isAnswersVisible, setAnswersVisible] = useState(false);
    const [isSmall, setIsSmall] = useState(isListMode);

    const [editedSubComment, setEditedSubComment] = useState(null);

    const [mentionsList, setMentionsList] = useState([]);
    const [isAssignedDisplay, setAssignedDisplay] = useState(false);

    const [reactions, setReactions] = useState(comment.reactions);
    const [showReactions, setShowReactions] = useState(false);

    const initialFormData = {
        id: comment._id,
        title: comment.title,
        priority: comment.priority,
        assignedTo: comment.assignedTo,
        content: comment.content,
        BCFContent: comment.BCFContent,
        fileId: files && files[0]._id,
        userEditor: profile && profile.user._id,
    };
    const [formData, setFormData] = useState(initialFormData);

    const initialFieldsError = {
        title: "",
        priority: "",
        content: "",
    };
    const [fieldsError, setFieldsError] = useState(initialFieldsError);

    useEffect(() => {
        setIsSmall(isListMode);
    }, [isListMode]);

    useEffect(() => {
        if (!isListMode) return;

        if (isSmall && activeBCF === comment._id) setIsSmall(false);
        else if (!isSmall && activeBCF && activeBCF !== comment._id)
            setIsSmall(true);
    }, [activeBCF]);

    useEffect(() => {
        if (isEdit) isFromMobile && setListMode(false);
    }, [isEdit]);

    useEffect(() => {
        if (comment.status === "CLOSED") setAnswersVisible(false);
    }, [comment.status]);

    const onChange = (e, name) => {
        setFormData({
            ...formData,
            [name ? name : e.target.name]: e.target.value,
        });
    };

    const onChangeMentions = async (e) => {
        if (e.slice(1).length < 2) {
            setMentionsList([]);
            return;
        }

        const terms = e.slice(1).toLowerCase();

        let resMembersByTerm = [];

        resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (
            resMembersByTerm.filter((item) => item.email !== profile.user.email)
                .length > 0
        ) {
            let updatedList = [];

            resMembersByTerm
                .filter((item) => item.email !== profile.user.email)
                .forEach((user) => {
                    updatedList.push({
                        id: user.email,
                        display:
                            user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.email +
                            ")",
                    });
                });

            setMentionsList(updatedList);
        } else setMentionsList([]);
    };

    const deleteComment = async () => {
        try {
            let isDeleted = false;

            const deletedRes = await axios.delete(
                apiURL.removeFileComment + formData.id,
            );

            if (deletedRes && deletedRes.data) isDeleted = true;

            if (isDeleted) {
                toastAlert("success", t("commentaireSupprime"));
                const updatedComments = comments.filter(
                    (item) => item._id !== formData.id,
                );

                setComments([...updatedComments]);

                // Suppression des marqueurs de la visionneuse 3D ou des pins PDF
                if (isFromViewer) deleteMarkerFromList(formData.id);
                else if (handleMarkerDeleted)
                    handleMarkerDeleted(areMarkersVisible, updatedComments);
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const onSave = async () => {
        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            try {
                if (isFromViewer && comment.BCFContent) {
                    formData.BCFContent = await generateBCFContent(world);

                    // Génération des settings actifs dans la scène
                    const resSettings = saveSceneSettings(world);

                    formData.settings = resSettings;
                }

                let commentDatas = null;

                const commentRes = await axios.post(
                    apiURL.updateComment,
                    formData,
                );

                if (commentRes && commentRes.data)
                    commentDatas = commentRes.data;

                if (commentDatas) {
                    toastAlert("success", t("commentaireModifie"));

                    const commentIndex = comments.findIndex(
                        (item) => item._id === comment._id,
                    );

                    if (commentIndex > -1) {
                        // On remplace l'élement dans le tableau des commentaires
                        const updatedComments = [...comments];
                        updatedComments[commentIndex] = commentDatas;

                        setComments([...updatedComments]);

                        // Update du form avec les nouvelles valeurs
                        setFormData((formData) => ({
                            ...formData,
                            title: commentDatas.title,
                            priority: commentDatas.priority,
                            assignedTo: commentDatas.assignedTo,
                            content: commentDatas.content,
                            BCFContent: commentDatas.BCFContent,
                        }));

                        if (
                            isFromViewer &&
                            !isBIMCollabAvailable(customerConfig)
                        ) {
                            // On supprime l'ancien marqueur car les donnés ne sont plus les mêmes
                            deleteMarkerFromList(comment._id);

                            addBCFMarker(
                                world,
                                commentDatas,
                                addMarkerToList,
                                setActiveBCF,
                                set2DPlanVisible,
                            );
                        }
                    }
                } else {
                    toastAlert(
                        "error",
                        t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                    );
                }
            } catch (err) {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                );
            }

            setFieldsError(initialFieldsError);
            setEdit(false);
        }

        setLoader(false);
    };

    const onCancel = () => {
        setFormData(initialFormData);
        setFieldsError(initialFieldsError);
        setEdit(false);
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        if (isFromViewer && comment.BCFContent) {
            validation = fieldValidation(formData.title, "required");
            updatedFieldsError.title = validation;
            if (validation !== "") nbErrors++;

            validation = fieldValidation(formData.priority, "required");
            updatedFieldsError.priority = validation;
            if (validation !== "") nbErrors++;
        }

        validation = fieldValidation(formData.content, "required");
        updatedFieldsError.content = validation;
        if (validation !== "") nbErrors++;

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const getAssignedUserName = () => {
        if (isFromViewer && isBIMCollabAvailable(customerConfig)) {
            const assignedMember = bimCollabMembers.find(
                (member) => member.email === comment.assignedTo,
            );

            return assignedMember ? assignedMember.name : "";
        } else {
            return `${comment.assignedTo.firstname} ${comment.assignedTo.lastname}`;
        }
    };

    const getAssignedUserDisplayName = () => {
        if (isFromViewer && isBIMCollabAvailable(customerConfig)) {
            const assignedMember = members.find(
                (member) => member.user.email === formData.assignedTo,
            );
            if (assignedMember) {
                return `${assignedMember.user.firstname} ${assignedMember.user.lastname} (${formData.assignedTo})`;
            }
        }
        if (formData.assignedTo?.email) {
            return `${formData.assignedTo.firstname} ${formData.assignedTo.lastname} (${formData.assignedTo.email})`;
        }
        return "";
    };

    const handleShowForm = async () => {
        const image = await takeScreenshot();

        setInformationModal({
            title: comment.title,
            content: (
                <BCFForm
                    profile={profile && profile}
                    files={files}
                    comment={comment}
                    compiledId={compiledFile && compiledFile._id}
                    set2DPlanVisible={set2DPlanVisible}
                    image={image}
                    onClose={() => setInformationModal()}
                />
            ),
            closeReset: setInformationModal,
            customWidth: "max-w-[750px] lg:max-w-[1050px]",
        });
    };

    const handleReaction = async (code) => {
        const resReaction = await axios.put(
            apiURL.handleCommentReaction + comment._id,
            {
                code,
            },
        );

        if (resReaction?.data) setReactions(resReaction.data);
    };

    const canEdit =
        profile &&
        ((comment.author && comment.author._id === profile.user._id) ||
            (isBIMCollabAvailable(customerConfig) && isFromViewer)) &&
        userRoles.EDITOR &&
        !isTilesView;

    const canDelete =
        profile &&
        ((comment.author && comment.author._id === profile.user._id) ||
            profile.projects.some((project) =>
                files.some((file) => project._id === file.project?._id),
            )) &&
        userRoles.MODERATOR &&
        (!isFromViewer ||
            (isFromViewer && !isBIMCollabAvailable(customerConfig)));

    const options = [
        canEdit &&
            isFromViewer &&
            !isLightViewer &&
            checkBIMCollabRole(
                profile,
                isFromViewer,
                customerConfig,
                "Editor",
                currentBIMCollabUser,
            ) && {
                content: (
                    <button
                        className="p-2 w-full text-left"
                        onClick={(e) =>
                            !isFromViewer || !comment.BCFContent
                                ? setEdit(!isEdit)
                                : handleShowForm()
                        }
                    >
                        {t("modifier")}
                    </button>
                ),
            },
        {
            content: (
                <button
                    className="p-2 w-full text-left"
                    onClick={(e) =>
                        addModalData({
                            key: "shareFile",
                            title: t("partager"),
                            width: 1000,
                            isForm: false,
                            content: (
                                <ShareProjectFile
                                    files={[files[0]]}
                                    filesIds={filesIds}
                                    bcfId={comment._id}
                                />
                            ),
                        })
                    }
                >
                    {t("partager")}
                </button>
            ),
        },
        isFromViewer &&
            !isBIMCollabAvailable(customerConfig) &&
            profile && {
                content: (
                    <button
                        className="p-2 w-full text-left"
                        onClick={(e) =>
                            addModalData({
                                key: "shareFile",
                                title: t("genererUnQRCode"),
                                isForm: false,
                                content: (
                                    <ShareProjectFile
                                        files={[files[0]]}
                                        filesIds={filesIds}
                                        bcfId={comment._id}
                                        isQRCode={true}
                                    />
                                ),
                            })
                        }
                    >
                        {t("genererUnQRCode")}
                    </button>
                ),
            },
        canDelete &&
            isFromViewer &&
            !isLightViewer && {
                content: (
                    <button
                        className="p-2 w-full text-left"
                        onClick={(e) => {
                            setConfirmationModal({
                                title: t(
                                    "voulezVousVraimentSupprimerCeCommentaire?",
                                ),
                                callBackFunc: deleteComment,
                                closeReset: setConfirmationModal,
                            });
                        }}
                    >
                        {t("supprimer")}
                    </button>
                ),
            },
    ];

    const matchedVisa =
        comment.visa &&
        files[0].visas.find((item) => item.visa._id === comment.visa);

    const optionMenuContent = (
        <div className="absolute z-[1] top-[18px] right-2">
            <OptionMenu
                options={options}
                forceFullWidth={isFromMobile}
                addBgColor={true}
            />
        </div>
    );

    return (
        comment && (
            <DriveToolsContext.Provider value={{}}>
                <div
                    className={
                        "relative transition ease-in-out text-left w-full h-full" +
                        (!isListMode && isFromViewer && !isLightViewer
                            ? " card rounded-2xl shadow-xl"
                            : isListMode && isFromViewer && !isLightViewer
                              ? " bg-indigo-50"
                              : " p-1.5 rounded-lg")
                    }
                    id={"comment-" + comment._id}
                    onMouseEnter={(e) => {
                        // Lien avec le marqueur d'un PDF
                        if (areMarkersVisible) {
                            const markerElement = document.getElementById(
                                "marker-" + comment._id,
                            );

                            if (markerElement) {
                                markerElement.classList.add("scale-150");
                            }
                        }

                        // Lien avec le marqueur visionneuse 3D
                        if (isFromMobile || !isFromViewer) return;

                        let elem = document.getElementById(
                            "marker-" + comment._id,
                        );

                        if (!elem) return;

                        elem.classList.add(
                            "bg-secondary",
                            "w-[90px]",
                            "h-[90px]",
                        );

                        elem.querySelector(".marker-content").classList.add(
                            "w-[80px]",
                            "h-[80px]",
                        );
                    }}
                    onMouseLeave={(e) => {
                        // Lien avec le marqueur d'un PDF
                        if (areMarkersVisible) {
                            const markerElement = document.getElementById(
                                "marker-" + comment._id,
                            );

                            if (markerElement) {
                                markerElement.classList.remove("scale-150");
                            }
                        }

                        // Lien avec le marqueur visionneuse 3D
                        if (isFromMobile || !isFromViewer) return;

                        let elem = document.getElementById(
                            "marker-" + comment._id,
                        );

                        if (!elem) return;

                        elem.classList.remove(
                            "bg-secondary",
                            "w-[90px]",
                            "h-[90px]",
                        );

                        elem.querySelector(".marker-content").classList.remove(
                            "w-[80px]",
                            "h-[80px]",
                        );
                    }}
                >
                    <div
                        className={
                            "absolute left-0 w-[6px]" +
                            (!isListMode
                                ? " top-[3px] h-[calc(100%-6px)] rounded-tl-2xl rounded-bl-2xl"
                                : " h-full") +
                            (!isLightViewer && comment.BCFContent
                                ? comment.status === "RESOLVED"
                                    ? " bg-cyan-400"
                                    : comment.status === "CLOSED"
                                      ? " bg-red-500"
                                      : " bg-slate-600"
                                : "")
                        }
                    ></div>
                    {comment.BCFContent && !isLightViewer && (
                        <div className="relative pb-0 p-4">
                            {(isFromViewer || comment.assignedTo) && (
                                <div className="flex flex-row w-[calc(100%-30px)]">
                                    <div
                                        className={
                                            "font-bold text-xs my-auto w-full whitespace-nowrap" +
                                            (comment.status === "CLOSED"
                                                ? " opacity-25"
                                                : "")
                                        }
                                    >
                                        <div
                                            className="truncate"
                                            title={
                                                comment.BCFNumber
                                                    ? formatBCFNumber(
                                                          comment.BCFNumber,
                                                      ) +
                                                      " " +
                                                      comment.title
                                                    : comment.title
                                            }
                                        >
                                            {comment.BCFNumber
                                                ? formatBCFNumber(
                                                      comment.BCFNumber,
                                                  ) +
                                                  " " +
                                                  comment.title
                                                : comment.title}
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="flex flex-row mt-3">
                                {comment.assignedTo &&
                                    comment.BCFContent &&
                                    !isLightViewer &&
                                    customerConfig !== null && (
                                        <div
                                            className="relative mr-2"
                                            onMouseEnter={(e) =>
                                                setAssignedDisplay(true)
                                            }
                                            onMouseLeave={(e) =>
                                                setAssignedDisplay(false)
                                            }
                                        >
                                            {isFromViewer &&
                                            isBIMCollabAvailable(
                                                customerConfig,
                                            ) ? (
                                                <UserCircle
                                                    guestName={getAssignedUserName()}
                                                />
                                            ) : (
                                                <UserCircle
                                                    user={comment.assignedTo}
                                                />
                                            )}

                                            {isAssignedDisplay && (
                                                <div className="absolute top-[-2px] left-9">
                                                    <Popover
                                                        text={
                                                            <div className="truncate">
                                                                {getAssignedUserName()}
                                                            </div>
                                                        }
                                                        width={200}
                                                        arrowDirection="left"
                                                        isCentered={false}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    )}

                                <div className="flex flex-row my-auto">
                                    {comment.status &&
                                    comment.status === "RESOLVED" ? (
                                        <Badge
                                            text={t("resolu")}
                                            bgColor="#10b981"
                                            textColor="#d1fae5"
                                            isSquare={true}
                                        />
                                    ) : comment.status &&
                                      comment.status === "CLOSED" ? (
                                        <Badge
                                            text={t("ferme")}
                                            bgColor="#7f1d1d"
                                            textColor="#fca5a5"
                                            isSquare={true}
                                        />
                                    ) : (
                                        comment.priority &&
                                        isFromViewer && (
                                            <Badge
                                                text={
                                                    comment.priority[
                                                        "title" + i18n.locale
                                                    ]
                                                }
                                                bgColor={
                                                    comment.priority.lightColor
                                                }
                                                textColor={
                                                    comment.priority.darkColor
                                                }
                                                borderColor={
                                                    comment.priority.darkColor
                                                }
                                                isSquare={true}
                                            />
                                        )
                                    )}

                                    {comment.dueDate && (
                                        <Badge
                                            text={
                                                <div className="flex flex-row">
                                                    <i
                                                        className="material-icons notranslate flex"
                                                        style={{
                                                            fontSize: "1rem",
                                                        }}
                                                    >
                                                        schedule
                                                    </i>
                                                    <div className="my-auto ml-1">
                                                        {fromDateToStringDate(
                                                            comment.dueDate,
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                            bgColor={
                                                isDateAfter(
                                                    Date.now(),
                                                    comment.dueDate,
                                                )
                                                    ? "#ef4444"
                                                    : "#34d399"
                                            }
                                            textColor="#fff"
                                            isSquare={true}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {isFromViewer && optionMenuContent}

                    <div
                        className={
                            (isFromViewer && !isLightViewer ? "p-4" : "") +
                            (isSmall ? " hover:cursor-pointer" : "")
                        }
                        onClick={(e) => {
                            if (isTilesView || !isSmall || !isFromViewer)
                                return;

                            applyBCF(
                                world,
                                comment,
                                comment._id,
                                setActiveBCF,
                                set2DPlanVisible,
                            );
                            isFromMobile && setListMode(false);
                        }}
                    >
                        {!isSmall && isFromViewer && !isLightViewer && (
                            <div
                                style={{
                                    backgroundImage:
                                        "url(" +
                                        getBCFImage(comment.BCFContent) +
                                        ")",
                                }}
                                className={
                                    "relative flex w-full h-[250px] sm:h-[300px] md:h-[180px] rounded-xl background-img" +
                                    (comment.status === "CLOSED"
                                        ? " opacity-25"
                                        : "")
                                }
                                onDoubleClick={(e) => {
                                    if (isTilesView || isFromMobile) return;

                                    applyBCF(
                                        world,
                                        comment,
                                        comment._id,
                                        setActiveBCF,
                                        set2DPlanVisible,
                                    );
                                }}
                                onClick={(e) => {
                                    if (isTilesView || !isFromMobile) return;

                                    applyBCF(
                                        world,
                                        comment,
                                        comment._id,
                                        setActiveBCF,
                                    );
                                    setListMode(false);
                                }}
                            >
                                {!comment.BCFContent?.image && (
                                    <i
                                        className="material-icons notranslate m-auto"
                                        style={{ fontSize: "5rem" }}
                                    >
                                        no_photography
                                    </i>
                                )}
                            </div>
                        )}

                        {(!comment.BCFContent ||
                            (isFromViewer && isLightViewer)) &&
                            comment.image && (
                                <div
                                    style={{
                                        backgroundImage:
                                            "url(" + comment.image + ")",
                                    }}
                                    className="relative flex w-full h-[250px] sm:h-[300px] md:h-[180px] rounded-xl background-img hover:cursor-pointer"
                                    onClick={(e) => {
                                        setInformationModal({
                                            content: (
                                                <img
                                                    src={comment.image}
                                                    alt=""
                                                    className="w-full"
                                                />
                                            ),
                                            customWidth:
                                                "max-w-[750px] lg:max-w-[1050px]",
                                            closeReset: setInformationModal,
                                        });
                                    }}
                                ></div>
                            )}

                        <div
                            className={
                                "flex flex-row mt-3 mb-5" +
                                (comment.status === "CLOSED"
                                    ? " opacity-25"
                                    : "")
                            }
                        >
                            <UserCircle
                                user={comment.author}
                                guestName={comment.guestName}
                            />
                            <div className="ml-2 text-xs text-black">
                                <div className="font-bold">
                                    {getCommentUserName(
                                        comment,
                                        isFromViewer &&
                                            isBIMCollabAvailable(
                                                customerConfig,
                                            ),
                                    )}
                                </div>
                                <div>
                                    {fromDateToStringDateHours(
                                        comment.creationDate,
                                        true,
                                    )}
                                </div>
                            </div>
                        </div>

                        {Number.isInteger(commentIndex) && (
                            <div className="text-sm mb-3 text-slate-400">
                                #{commentIndex}
                            </div>
                        )}

                        {matchedVisa && (
                            <div className="mb-4">
                                <VisaBadge visa={matchedVisa} />
                            </div>
                        )}

                        <div className="mt-1 text-xs">
                            {isEdit ? (
                                <CommentTextarea
                                    value={formData.content}
                                    fieldsError={fieldsError}
                                    isEdit={editedSubComment}
                                    loader={loader}
                                    mentionsList={profile && mentionsList}
                                    onChange={onChange}
                                    onChangeMentions={
                                        profile && onChangeMentions
                                    }
                                />
                            ) : (
                                <div
                                    className={
                                        "whitespace-pre-wrap break-words" +
                                        (comment.status === "CLOSED"
                                            ? " opacity-25"
                                            : "")
                                    }
                                    dangerouslySetInnerHTML={{
                                        __html: formatMentions(comment.content),
                                    }}
                                ></div>
                            )}
                        </div>

                        {isEdit ? (
                            <div className="flex flex-row mt-5">
                                <div className="w-1/2 mr-1">
                                    <Button
                                        text={t("enregistrer")}
                                        onClick={(e) => onSave()}
                                    />
                                </div>
                                <div className="w-1/2 ml-1">
                                    <Button
                                        text={t("annuler")}
                                        onClick={(e) => onCancel()}
                                        isReversed={true}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="relative flex flex-row mt-4">
                                {profile &&
                                    !isBIMCollabAvailable(customerConfig) && (
                                        <Fragment>
                                            {reactions?.length > 0 && (
                                                <div className="my-auto">
                                                    <ReactionsList
                                                        reactions={reactions}
                                                    />
                                                </div>
                                            )}

                                            <button
                                                type="button"
                                                className="flex p-1 mr-1"
                                                onClick={(e) =>
                                                    setShowReactions(
                                                        !showReactions,
                                                    )
                                                }
                                            >
                                                <i
                                                    className="material-icons notranslate m-auto"
                                                    style={{
                                                        fontSize: "1rem",
                                                    }}
                                                >
                                                    add_reaction
                                                </i>
                                            </button>

                                            {showReactions && (
                                                <div className="absolute z-[1] left-0 top-[-7px]">
                                                    <EmojisReactions
                                                        setVisible={
                                                            setShowReactions
                                                        }
                                                        handleReaction={
                                                            handleReaction
                                                        }
                                                        activeCode={
                                                            reactions.find(
                                                                (reaction) =>
                                                                    reaction.author ===
                                                                    profile.user
                                                                        ._id,
                                                            )?.code
                                                        }
                                                    />
                                                </div>
                                            )}
                                        </Fragment>
                                    )}
                                {(!isFromViewer ||
                                    (isFromViewer && isLightViewer)) && (
                                    <ul className="flex flex-row gap-2 text-2xs text-slate-600 my-auto">
                                        {canEdit && (
                                            <li>
                                                <button
                                                    type="button"
                                                    onClick={(e) =>
                                                        setEdit(!isEdit)
                                                    }
                                                >
                                                    {t("modifier")}
                                                </button>
                                            </li>
                                        )}

                                        {(!profile ||
                                            !comment.author ||
                                            profile?.user._id !==
                                                comment.author?._id) && (
                                            <li>
                                                <button
                                                    type="button"
                                                    onClick={(e) =>
                                                        respondToComment(
                                                            comment,
                                                        )
                                                    }
                                                >
                                                    {t("repondre")}
                                                </button>
                                            </li>
                                        )}

                                        {(canDelete ||
                                            (comment.author &&
                                                comment.author._id ===
                                                    profile?.user?._id) ||
                                            (!comment.author &&
                                                comment.guestEmail ===
                                                    localStorage.getItem(
                                                        "guestEmail",
                                                    ))) && (
                                            <li>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        setConfirmationModal({
                                                            title: t(
                                                                "voulezVousVraimentSupprimerCeCommentaire?",
                                                            ),
                                                            callBackFunc:
                                                                deleteComment,
                                                            closeReset:
                                                                setConfirmationModal,
                                                        });
                                                    }}
                                                >
                                                    {t("supprimer")}
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                )}
                            </div>
                        )}

                        {!isEdit && isFromViewer && !isLightViewer && (
                            <div className="inline-block mt-4">
                                <button
                                    type="button"
                                    className="text-primary text-xs font-bold"
                                    onClick={(e) =>
                                        setAnswersVisible(!isAnswersVisible)
                                    }
                                >
                                    {t(
                                        !isAnswersVisible
                                            ? "afficherLhistorique"
                                            : "masquerLhistorique",
                                    )}
                                </button>
                            </div>
                        )}

                        {isAnswersVisible && !isSmall && (
                            <Fragment>
                                {comment.comments.length > 0 && (
                                    <SubCommentList
                                        profile={profile}
                                        files={files}
                                        parentComment={comment}
                                        comments={comment.comments}
                                        editedSubComment={editedSubComment}
                                        setEditedSubComment={
                                            setEditedSubComment
                                        }
                                    />
                                )}
                                {!comment.BCFContent &&
                                    comment.status !== "CLOSED" &&
                                    !isEdit && (
                                        <div className="mb-4">
                                            <SubCommentForm
                                                parentComment={comment}
                                                editedSubComment={
                                                    editedSubComment
                                                }
                                                setEditedSubComment={
                                                    setEditedSubComment
                                                }
                                                guestEmail={localStorage.getItem(
                                                    "guestEmail",
                                                )}
                                                guestName={localStorage.getItem(
                                                    "guestName",
                                                )}
                                                fileId={files[0]._id}
                                                mentionsList={
                                                    profile && mentionsList
                                                }
                                                onChangeMentions={
                                                    profile && onChangeMentions
                                                }
                                            />
                                        </div>
                                    )}
                            </Fragment>
                        )}
                    </div>

                    {confirmationModal && (
                        <ConfirmationModal
                            title={confirmationModal.title}
                            callBackFunc={confirmationModal.callBackFunc}
                            closeReset={confirmationModal.closeReset}
                        />
                    )}

                    {informationModal && (
                        <InformationModal
                            title={informationModal.title}
                            content={informationModal.content}
                            closeReset={informationModal.closeReset}
                            isNoPadding={informationModal.isNoPadding}
                            customWidth={informationModal.customWidth}
                        />
                    )}

                    {loader && <Spinner />}
                </div>
            </DriveToolsContext.Provider>
        )
    );
};

export default CommentItem;
