import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";

import { useTimesheetStore } from "../TimesheetStore";

import { Card, Divider, Flex, Input, List, theme } from "antd";

const SelectProjectTask = ({ timeEntry, setTimeEntry, isPreview = false, setLoader = () => {}, isFromAdmin }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { userProjects, adminTasks } = useTimesheetStore();

    const { addModalData } = useContext(ModalContext);
    const getProjects = useCallback(async () => {
        let entryProject =
            timeEntry.project && timeEntry.project?.id !== "RECOVERY"
                ? typeof timeEntry.project === "string"
                    ? userProjects.find((e) => e._id === timeEntry.project) || userProjects[0]
                    : userProjects.find((e) => e._id === timeEntry.project._id) || userProjects[0]
                : userProjects[0];

        let entryTask = timeEntry.timesheetTask
            ? typeof timeEntry.timesheetTask === "string"
                ? adminTasks.find((e) => e._id === timeEntry.timesheetTask)
                : adminTasks.find((e) => e._id === timeEntry.timesheetTask._id)
            : entryProject?.allowedTimesheetTasks[0];

        setTimeEntry((e) => ({
            ...e,
            project: entryProject,
            timesheetTask: entryTask,
        }));
    }, [timeEntry, userProjects, adminTasks]);

    useEffect(() => {
        if (timeEntry?.isRecovery) {
            setTimeEntry((e) => ({
                ...e,
                project: { _id: "RECOVERY", title: t("recuperationDheures") },
                timesheetTask: null,
                fields: [],
                data: [],
            }));
        } else {
            getProjects();
        }
    }, [timeEntry?.isRecovery, timeEntry?._id]);

    useEffect(() => {
        if (!!timeEntry.timesheetTask?._id && !timeEntry?.isRecovery) {
            axios.get(apiURL.getTimesheetFieldsFromTask + timeEntry.timesheetTask._id).then(({ data: fields }) => {
                setTimeEntry((e) => ({
                    ...e,
                    fields,
                    data: fields.map((e) =>
                        timeEntry.data.find((f) => f.timesheetField?._id === e._id)
                            ? timeEntry.data.find((f) => f.timesheetField?._id === e._id)
                            : {
                                  timesheetField: e,
                                  title: e.title,
                                  type: e.type,
                                  value: e.defaultValue || undefined,
                              },
                    ),
                }));
            });
        }
    }, [timeEntry.timesheetTask]);

    const customerName = timeEntry?.project?.customer?.name;

    if (!isFromAdmin && userProjects?.length === 0)
        return (
            <div className="pb-4 text-justify">
                <span className="font-bold">
                    Vous ne pouvez pas choisir de tâche à remplir car vous n'avez pas de dossier autorisés pour la saisie de temps. Vous
                    pouvez :
                </span>
                <ul className="text-left mt-4">
                    <li>- Demander à votre responsable d'ajouter au moins une tâche au dossier</li>
                    <li>- Demander à votre responsable d'assigner le dossier comme étant une feuille de temps</li>
                </ul>
            </div>
        );
    return (
        <>
            <Card
                bordered={false}
                style={{
                    border: "2px solid black",
                    overflow: "hidden",
                    opacity: timeEntry._id && !isPreview && timeEntry?.sendDate && !isFromAdmin ? 0.7 : 1,
                }}
                styles={{
                    body: {
                        padding: 0,
                    },
                }}
                cover={null}
                title={null}>
                <Flex
                    align="center"
                    className="cursor-pointer"
                    gap="large"
                    onClick={() => {
                        if (!isPreview && (!timeEntry?.sendDate || isFromAdmin))
                            addModalData({
                                key: "selectProject",
                                title: t("choisir") + " : " + t("dossier"),
                                content: <SelectProject setTimeEntry={setTimeEntry} />,
                            });
                    }}
                    style={{
                        padding: token.padding,
                    }}>
                    <Flex vertical style={{}}>
                        {customerName && (
                            <Text size="small" type="secondary">
                                {t("client")}
                            </Text>
                        )}
                        <Text bold>{t("dossier")}</Text>
                    </Flex>

                    <Flex
                        style={{
                            flex: 1,
                            display: "grid",
                        }}>
                        {customerName && (
                            <Text
                                size="small"
                                type="secondary"
                                ellipsis
                                style={{
                                    textAlign: "right",
                                }}>
                                {customerName}
                            </Text>
                        )}
                        <Text
                            bold
                            style={{
                                textAlign: "justify",
                                direction: "rtl",
                            }}
                            size="small">
                            {timeEntry?.project?.title}
                        </Text>
                    </Flex>

                    <MaterialIcons
                        style={{
                            flexShrink: 0,
                        }}
                        name="arrow_forward_ios"
                    />
                </Flex>

                {timeEntry?.project?._id !== "RECOVERY" && (
                    <>
                        <Divider
                            style={{
                                margin: 0,
                            }}
                        />
                        <Flex
                            style={{
                                padding: token.padding,
                            }}
                            align="center"
                            className="cursor-pointer"
                            gap="middle"
                            onClick={() => {
                                if (!isPreview && (!timeEntry?.sendDate || isFromAdmin))
                                    addModalData({
                                        key: "selectTask",
                                        title: t("choisir") + " : " + t("tache"),
                                        content: (
                                            <SelectTask
                                                setTimeEntry={setTimeEntry}
                                                options={timeEntry.project.allowedTimesheetTasks.sort((a, b) =>
                                                    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                                                )}
                                            />
                                        ),
                                    });
                            }}>
                            <Text bold>{t("tache")}</Text>
                            <div
                                style={{
                                    flex: 1,
                                    display: "grid",
                                }}>
                                <Text
                                    bold
                                    ellipsis
                                    size="small"
                                    style={{
                                        textAlign: "right",
                                    }}>
                                    {timeEntry?.timesheetTask?.title}
                                </Text>
                            </div>
                            <MaterialIcons name="arrow_forward_ios" />
                        </Flex>
                    </>
                )}
            </Card>
        </>
    );
};

const SelectTask = ({ setTimeEntry, options }) => {
    const { removeModal } = useContext(ModalContext);

    const selectTask = (task) => {
        setTimeEntry((e) => ({
            ...e,
            timesheetTask: task,
        }));
        removeModal("selectTask");
    };

    return (
        <List
            dataSource={options}
            renderItem={(option) => (
                <List.Item
                    style={{
                        cursor: "pointer",
                    }}
                    onClick={() => selectTask(option)}>
                    <List.Item.Meta avatar={<MaterialIcons name={"description"} />} title={option?.title} />
                </List.Item>
            )}
        />
    );
};

const SelectProject = ({ setTimeEntry }) => {
    const { t } = useTranslation();
    const { removeModal } = useContext(ModalContext);
    const { profile: userProfile } = useProfileStore();
    const { userProjects } = useTimesheetStore();
    const { token } = theme.useToken();
    const [searchTerm, setSearchTerm] = useState("");
    const filterOptions = useCallback(
        (options) =>
            options
                ?.filter((e) => e.isTimesheet)
                .filter(
                    (option) =>
                        option.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        (option.customer?.name || "").toLowerCase().includes(searchTerm.toLowerCase()),
                ) || [],
        [searchTerm],
    );

    const recents = filterOptions(userProfile.recentlyUsed).slice(0, 3);

    const selectProject = (project) => {
        if (project._id === "RECOVERY")
            setTimeEntry((e) => ({
                ...e,
                timesheetTask: null,
                isRecovery: true,
            }));
        else
            setTimeEntry((e) => ({
                ...e,
                project: project,
                timesheetTask: project.allowedTimesheetTasks[0],
                isRecovery: false,
            }));
        removeModal("selectProject");
    };

    return (
        <Flex vertical gap="middle">
            <div
                style={{
                    position: "sticky",
                    top: -token.paddingLG,
                    background: "white",
                    zIndex: 1,
                    paddingBottom: 8,
                    paddingTop: 8,
                }}>
                <Input.Search
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    placeholder={t("rechercherUnDossier")}
                    size="large"
                />
            </div>

            <Flex
                align="center"
                gap="small"
                style={{
                    cursor: "pointer",
                }}
                onClick={() => selectProject({ _id: "RECOVERY", title: t("recuperationDheures") })}>
                <MaterialIcons name={"pending_actions"} />
                <Text bold size="small">
                    {t("recuperationDheures")}
                </Text>
            </Flex>

            {!!recents?.length && (
                <List
                    header={
                        <Text size="small" type="secondary">
                            {t("recents")}
                        </Text>
                    }
                    dataSource={recents.map((e) => ({
                        ...e,
                        allowedTimesheetTasks: userProjects.find((o) => o._id === e._id)?.allowedTimesheetTasks,
                    }))}
                    renderItem={(option) => (
                        <List.Item style={{ cursor: "pointer" }} onClick={() => selectProject(option)}>
                            <Flex align="center" gap="small">
                                <MaterialIcons size="sm" name={"folder"} />
                                <Flex vertical>
                                    <Text bold size="small">
                                        {option?.title}
                                    </Text>
                                    <Text type="secondary" size="small">
                                        {option.customer?.name}
                                    </Text>
                                </Flex>
                            </Flex>
                        </List.Item>
                    )}
                />
            )}

            <List
                header={
                    <Text size="small" type="secondary">
                        {t("mesProjets")}
                    </Text>
                }
                // dataSource={filterOptions([...userProjects, ...userProjects, ...userProjects, ...userProjects, ...userProjects, ...userProjects])}
                dataSource={filterOptions(userProjects)}
                renderItem={(option) => (
                    <List.Item
                        style={{
                            cursor: "pointer",
                            paddingLeft: userProjects.find((e) => e._id === option.parentProject) ? 20 * option.childrenLevel : 0,
                        }}
                        onClick={() => selectProject(option)}>
                        <Flex align="center" gap="small">
                            <MaterialIcons size="sm" name={"folder"} />
                            <Flex vertical>
                                <Text bold size="small">
                                    {option?.title}
                                </Text>
                                <Text type="secondary" size="small">
                                    {option.customer?.name}
                                </Text>
                            </Flex>
                        </Flex>
                    </List.Item>
                )}
            />
        </Flex>
    );
};

export default SelectProjectTask;
