import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Constants from "utils/constants";

import Notifications from "components/notification/Notifications";

import NavBarMenu from "./NavBarMenu";
import SideMenu from "./SideMenu";
import UserMenu from "./UserMenu";

const NavBar = ({
    auth: { isAuthenticated },
    profile: { profile },
    isBlackText = true,
}) => {
    const { t } = useTranslation();

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const mobileMenu = <SideMenu profile={isAuthenticated && profile} />;

    return (
        <nav className={"h-[50px] w-full z-10" + (!isBlackText ? "" : "")}>
            {mdMedia ? (
                <div className="px-3 flex flex-wrap justify-between items-center mx-auto h-full">
                    <Link
                        to={isAuthenticated ? "/drive" : "/home"}
                        className="flex items-center"
                    >
                        <img
                            src={
                                isBlackText && mdMedia
                                    ? "/img/logo-big-black.png"
                                    : "/img/logo-big.png"
                            }
                            className="w-[150px] h-[50px]"
                            alt={t("applicationTitle")}
                        />
                    </Link>
                    <NavBarMenu
                        profile={profile}
                        isBlackText={isBlackText}
                        isAuthenticated={isAuthenticated}
                    />
                </div>
            ) : (
                <div className="flex flex-row w-full h-full px-[5px]">
                    {!isAuthenticated ? (
                        <div className="ml-[3px] mt-[7px]">{mobileMenu}</div>
                    ) : (
                        <Link
                            to="/drive"
                            className="min-w-[50px] w-[50px] inline-block ml-2 mt-0.5"
                        >
                            <img
                                src={
                                    isBlackText
                                        ? "/img/logo-black.svg"
                                        : "/img/logo.svg"
                                }
                                alt={t("applicationTitle")}
                                className="w-full"
                            />
                        </Link>
                    )}

                    <div className="w-full justify-end pr-3">
                        {isAuthenticated ? (
                            <div className="relative h-[50px] flex w-full justify-end">
                                <Notifications profile={profile} />
                                <UserMenu />
                            </div>
                        ) : (
                            <Link
                                to="/home"
                                className="inline-block ml-3 mt-0.5"
                            >
                                <img
                                    src={
                                        isBlackText
                                            ? "/img/logo-black.svg"
                                            : "/img/logo.svg"
                                    }
                                    alt={t("applicationTitle")}
                                    className="w-[50px]"
                                />
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </nav>
    );
};

NavBar.propTypes = {
    auth: PropTypes.object,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile,
});

export default connect(mapStateToProps, null)(NavBar);
