import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";
import { fromMongoToStringHoursMinutes } from "helpers/time";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { Flex, Tooltip } from "antd";

const ActionInfos = ({ updatedTime }) => {
    const { t } = useTranslation();

    const types = [
        { title: t("jourTravailles"), color: "bg-white" },
        // { title: "regrouper", color: "slate-300" },
        // { title: "champsPersonnalises", color: "slate-100" },
        { title: capitalize(t("weekend")), color: "bg-blue-100" },
        { title: t("joursFeries"), color: "bg-yellow-100" },
        { title: t("joursCompenses"), color: "bg-green-100" },
        { title: t("vacances") + " / " + t("absences"), color: "bg-red-100" },
    ];

    return (
        <Flex align="center" gap={6}>
            <Text type="secondary" size="small">
                {capitalize(t("misAJour"))} : {fromMongoToStringHoursMinutes(updatedTime)}
            </Text>
            <Tooltip
                placement="bottom"
                color="white"
                mouseLeaveDelay={0.5}
                title={
                    <Flex vertical gap={6}>
                        {types.map((type, index) => (
                            <Flex key={index} gap={6} align="center">
                                <div
                                    className={type.color}
                                    style={{
                                        width: 30,
                                        height: 20,
                                        borderRadius: 2,
                                        border: "1px solid #d9d9d9",
                                    }}
                                />

                                <Text size="small">{t(type.title)}</Text>
                            </Flex>
                        ))}
                    </Flex>
                }
            >
                <div>
                    <MaterialIcons
                        style={{
                            userSelect: "none",
                        }}
                        name="info"
                        type="secondary"
                        size="sm"
                    />
                </div>
            </Tooltip>
        </Flex>
    );
};

export default ActionInfos;
