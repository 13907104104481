import { useTranslation } from "react-i18next";

import PortalApplicationItem from "./PortalApplicationItem";

const PortalApplications = () => {
    const { t } = useTranslation();

    return (
        <div className="w-[328px] sm:w-full sm:flex sm:flex-row inline-block justify-center translate-y-[-35px] sm:translate-y-[-45px] mx-auto text-left">
            <PortalApplicationItem
                link="/drive"
                icon="folder"
                text={t("mesProjets")}
            />
            <PortalApplicationItem
                link="/timesheet/summary"
                icon="timer"
                text={t("saisieDeTemps")}
            />
            <PortalApplicationItem
                link="/timesheet/absences"
                icon="sunny"
                text={t("absences")}
            />
            <PortalApplicationItem
                link="/photos"
                icon="photo_camera"
                text={t("photos")}
            />
        </div>
    );
};

export default PortalApplications;
