import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { absencesStatutOptions } from "helpers/timesheet";

import { DriveToolsContext } from "components/drive/Drive";

import { Checkbox, Tag, theme } from "antd";

const FilterByStatut = () => {
    const { t } = useTranslation();
    const { setToolsData, toolsData } = useContext(DriveToolsContext);
    const { token } = theme.useToken();

    return (
        <Checkbox.Group
            value={toolsData.filters.statuts}
            onChange={(statuts) => {
                setToolsData((k) => ({
                    ...k,
                    filters: {
                        ...k.filters,
                        statuts,
                    },
                }));
            }}
            style={{
                flexDirection: "column",
                gap: token.paddingXS,
            }}
            options={absencesStatutOptions.map((statut) => ({
                ...statut,
                label: <Tag color={statut.color}>{t(statut.label)}</Tag>,
            }))}
        />
    );
};

export default FilterByStatut;
