import i18n from "i18next";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr";

export function fromDayMonthYearToDate(date) {
    var formatedDate = null;

    if (date.day && date.month && date.year)
        formatedDate = date.year + "-" + date.month + "-" + date.day;

    return formatedDate;
}

export function fromDateToDayMonthYearString(date, toRemove = []) {
    // On prend la locale EN qui correspond au format BDD

    let formatedDate = {
        dayName: moment(date).locale(i18n.locale).format("dddd") + ", ",
        dayNumber: moment(date).locale(i18n.locale).format("Do"),
        monthName: moment(date).locale(i18n.locale).format("MMMM").slice(0, 3),
        yearNumber: moment(date).locale(i18n.locale).format("YYYY"),
    };

    toRemove.forEach((element) => {
        formatedDate[element] = "";
    });

    return `${formatedDate.dayName} ${formatedDate.dayNumber} ${formatedDate.monthName}. ${formatedDate.yearNumber}`;
}

export function fromDateToDayMonthYear(date) {
    // On prend la locale EN qui correspond au format BDD

    var formatedDate = {
        day: moment(date).locale("EN").get("date"),
        // Le mois commence à 0, donc on ajoute 1
        month: moment(date).locale("EN").get("month") + 1,
        year: moment(date).locale("EN").get("year"),
    };

    return formatedDate;
}

export function fromDateToStringDate(date) {
    // On définit la locale de l'utilisateur
    // L'utilisation du paramètre "L" permet de formater la date selon la locale

    let formatedDate = moment(date).locale(i18n.locale).format("L");

    return formatedDate;
}

export function fromDateToStringDateHours(date, withoutSeconds = false) {
    let formatedDate = moment(date)
        .locale(i18n.locale)
        .format(!withoutSeconds ? "L HH:mm:ss" : "L HH:mm");

    return formatedDate;
}

export function getAgeFromDate(date) {
    var today = new Date();
    var birthDate = new Date(date);

    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }

    return age;
}

// Compare deux DateTime pour l'expiration d'un token

export function hasTokenDateExpire(date) {
    var now = moment().locale(i18n.locale).format("L HH:mm:ss");
    var tokenDate = moment(date).locale(i18n.locale).format("L HH:mm:ss");

    if (tokenDate < now) return true;

    return false;
}

// Récup des jours pour un mois et une année

export function getDaysInMonth(month, year) {
    var date = new Date(year, month, 1);
    var days = [];

    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return days;
}

// Compare deux dates pour checker qu'elles sont égales

export function isDatesEquals(date1, date2, unit = "day") {
    date1 = moment(date1);
    date2 = moment(date2);

    return moment(date1).isSame(date2, unit);
}

// Compare une date est plus récente qu'une autre

export function isDateAfter(date1, date2) {
    date1 = moment(date1);
    date2 = moment(date2);

    return moment(date1).isAfter(date2, "day");
}

// Vérifie si le mois et l'année sont les mêmes que ceux d'une date

export function isDateMonthYearEqual(month, year, date) {
    date = moment(date);

    if (date.month() === month && date.year() === year) return true;

    return false;
}

// Vérifie si l'année est la mêmes que celle d'une date

export function isDateYearEqual(year, date) {
    date = moment(date);

    if (date.year() === year) return true;

    return false;
}

export function getMonthsList() {
    const list = [
        i18n.t("months:janvier"),
        i18n.t("months:fevrier"),
        i18n.t("months:mars"),
        i18n.t("months:avril"),
        i18n.t("months:mai"),
        i18n.t("months:juin"),
        i18n.t("months:juillet"),
        i18n.t("months:aout"),
        i18n.t("months:septembre"),
        i18n.t("months:octobre"),
        i18n.t("months:novembre"),
        i18n.t("months:decembre"),
    ];

    return list;
}

export function getCurrentDate() {
    return moment().locale(i18n.locale).format("L");
}

export function fromTimestampToDate(timestamp) {
    // On utilise la locale EN pour format de base de données

    return moment.unix(timestamp).locale("EN").format("L HH:mm:ss");
}

export function getDaysBetweenTwoDates(date1, date2) {
    var dateBegin = moment(date1).startOf("day");
    var dateEnd = moment(date2).startOf("day");

    var days = dateBegin.diff(dateEnd, "days");

    return days;
}

export function daysInYear(year) {
    return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
}

export function getLastOpenBetweenTwoDates(date1, date2) {
    const nbDays = getDaysBetweenTwoDates(date1, date2);
    const nbWeeks = moment(date1).diff(date2, "week");
    const nbMonths = moment(date1).diff(date2, "month");
    const nbYears = moment(date1).diff(date2, "year");

    let res = "";

    if (nbDays === 0) {
        res = i18n.t("ouvert") + " " + i18n.t("aujourdhui");
    } else if (nbDays === 1) {
        res = i18n.t("ouvert") + " " + i18n.t("hier");
    } else if (nbDays > 1 && nbWeeks === 0) {
        res = i18n.t("ouvertIlYa", {
            number: nbDays,
            unit: i18n.t("jours"),
        });
    } else if (nbWeeks > 0 && nbWeeks < 4) {
        res = i18n.t("ouvertIlYa", {
            number: nbWeeks,
            unit: nbWeeks === 1 ? i18n.t("semaine") : i18n.t("semaines"),
        });
    } else if (nbMonths > 0 && nbMonths < 12) {
        res = i18n.t("ouvertIlYa", {
            number: nbMonths,
            unit: nbMonths === 1 ? i18n.t("mois") : i18n.t("moisPluriel"),
        });
    } else if (nbYears > 0) {
        res = i18n.t("ouvertIlYa", {
            number: nbYears,
            unit: nbYears === 1 ? i18n.t("an") : i18n.t("ans"),
        });
    }

    return res;
}

export function fromDateToDatePickerValue(date) {
    const formatedDate = moment(date).locale(i18n.locale).format("YYYY-MM-DD");

    return formatedDate;
}

export function isDateOneMonthBeforeNow(date) {
    const momentInputDate = moment(date);

    const currentDate = moment();

    return momentInputDate.isBefore(currentDate.subtract(1, "months"));
}

// Ajoute un nombre de jours à la date courante
export function addDaysToDate(
    daysToAdd,
    originalDate = Date.now(),
    openDaysOnly = true,
) {
    const currentDate = moment(originalDate);
    const deadlineDate = currentDate.clone().add(daysToAdd, "days");
    deadlineDate.endOf("day");

    if (openDaysOnly) {
        let tempDate = currentDate.clone();

        while (tempDate.isBefore(deadlineDate)) {
            // On ajoute un jour à la date finale pour chaque jour de weekend
            if (tempDate.day() === 0 || tempDate.day() === 6) {
                deadlineDate.add(1, "days");
            }
            tempDate.add(1, "day");
        }
    }

    return deadlineDate.locale(i18n.locale).format("YYYY-MM-DDTHH:mm");
}
