import { configureStore } from "@reduxjs/toolkit";

// Local storage :

// Session storage :
// import storageSession from 'redux-persist/lib/storage/session'

import auth from "./reducers/auth";
import paymentTransaction from "./reducers/paymentTransaction";
import profile from "./reducers/profile";
import user from "./reducers/user";

// const usePersistConfig = (reducer, key) =>
//     persistReducer(
//         {
//             key: key,
//             storage,
//             // storageSession
//         },
//         reducer,
//     );

export const store = configureStore({
    reducer: {
        // auth: usePersistConfig(auth, "auth"),
        // user: usePersistConfig(user, "user"),
        // profile: usePersistConfig(profile, "profile"),
        auth,
        user,
        profile,
        paymentTransaction,
    },
    // devTools: process.env.NODE_ENV !== "production",
    // middleware: (getDefaultMiddleware) =>
    //     getDefaultMiddleware({
    //         serializableCheck: {
    //             ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
    //         },
    //     }),
});

// export const persistor = persistStore(store);
