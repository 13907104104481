const Select = ({
    text,
    placeholder = "",
    name,
    options,
    value,
    isRequired = false,
    onChange,
    errorMsg,
    isDisabled = false,
}) => {
    return (
        <div className="relative">
            {text && (
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={name}
                >
                    {text}
                </label>
            )}

            <div className="relative">
                <select
                    className={
                        "shadow appearance-none border rounded-lg w-full h-[38px] px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:cursor-pointer pr-6" +
                        (errorMsg ? " border border-red-500" : "") +
                        (isDisabled ? " bg-slate-200" : "")
                    }
                    id={name}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                >
                    {(!isRequired || placeholder !== "") && (
                        <option
                            disabled={placeholder !== "" ? true : false}
                            value=""
                        >
                            {placeholder}
                        </option>
                    )}

                    {options.length > 0 &&
                        options.map((option) => (
                            <option key={option.val} value={option.val}>
                                {option.label}
                            </option>
                        ))}
                </select>
                <i
                    className="material-icons notranslate absolute right-2 top-[calc(50%-8px)] pointer-events-none"
                    style={{ fontSize: "1rem" }}
                >
                    expand_more
                </i>
            </div>

            {errorMsg && (
                <div className="error-msg font-bold text-red-500 text-xs italic mt-2">
                    {errorMsg}
                </div>
            )}
        </div>
    );
};

export default Select;
