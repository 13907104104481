import { Fragment, useEffect, useRef, useState } from "react";

function InfoTooltip({ text = "", customWidth, alignRight = false }) {
    const refTooltip = useRef(null);
    const [isVisible, setVisible] = useState(false);

    // Si on clique ailleurs que sur le tooltip ou son contenu, on le ferme

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (refTooltip.current) {
            if (!refTooltip.current.contains(e.target)) setVisible(false);
            else if (e.target.tagName === "UL") setVisible(true);
            else setVisible(false);
        }
    };

    return (
        <Fragment>
            <div ref={refTooltip} className="ml-2">
                <i
                    className="material-icons notranslate text-primary hover:cursor-pointer align-middle"
                    style={{ fontSize: "1.5rem" }}
                    onClick={(e) => setVisible(true)}
                >
                    info
                </i>
            </div>
            <div className={"absolute top-8" + (alignRight ? " right-0" : "")}>
                {isVisible && (
                    <div className="card text-left p-2 border-2 border-primary text-sm relative z-10 pr-[20px]">
                        <i
                            className="material-icons notranslate absolute right-1 hover:cursor-pointer text-primary"
                            style={{ fontSize: "1.3rem" }}
                            onClick={(e) => setVisible(false)}
                        >
                            close
                        </i>
                        <div className={customWidth ? customWidth : "w-full"}>
                            {text}
                        </div>
                    </div>
                )}
            </div>
        </Fragment>
    );
}

export default InfoTooltip;
