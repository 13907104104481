import { useProfileStore } from "providers/ProviderProfile";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import {
    convertDataToPrint,
    deleteAnswerForm,
    formModalKey,
    newAnswerForm,
    saveAnswerForm,
    sendAnswerForm,
    validateAnswerForm,
} from "helpers/forms";
import { getAppImage } from "helpers/image";
import { getCustomerConfig } from "helpers/user";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import SuccessButton from "components/common/SuccessButton";
import Text from "components/common/Text";
import { AvatarUser } from "components/filters/FilterByUsers";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { functionExportToPDF } from "./ExportFormPDF";
import { BooleanAnswer } from "./fields/Boolean";
import { DateAnswer } from "./fields/Date";
import { NumberAnswer } from "./fields/Number";
import { ParagraphAnswer } from "./fields/Paragraph";
import { PictureAnswer } from "./fields/Picture";
import { ReferenceAnswer } from "./fields/Reference";
import { SelectCheckboxAnswer } from "./fields/SelectCheckbox";
import { SelectListAnswer } from "./fields/SelectList";
import { SelectRadioAnswer } from "./fields/SelectRadio";
import { SeparatorAnswer } from "./fields/Separator";
import { SignatureAnswer } from "./fields/Signature";
import { StateAnswer } from "./fields/State";
import { SubtitleAnswer } from "./fields/Subtitle";
import { TextLongAnswer } from "./fields/TextLong";
import { TextShortAnswer } from "./fields/TextShort";
import { TimeAnswer } from "./fields/Time";
import { TitleAnswer } from "./fields/Title";
import { WeatherAnswer } from "./fields/Weather";
import { ZoneAnswer } from "./fields/Zone";

import { Button, Divider, Flex, Form, Tag, theme } from "antd";

export const TitleFormAnswer = ({ templateData, author }) => {
    const form = Form.useFormInstance();
    const { customerConfig } = useProfileStore();
    const { wrapperDownload } = useContext(PopupContext);

    return (
        <Flex vertical gap="small">
            <Text bold size="md">
                {templateData.reference ? templateData.reference + " - " + templateData.title : templateData.title}
            </Text>
            <Flex align="center" justify="space-between" gap="small">
                <AvatarUser user={author} />
                {templateData.creationDate && (
                    <Button
                        type="text"
                        icon={<MaterialIcons name="download" />}
                        children=".PDF"
                        disabled={false}
                        onClick={() => {
                            wrapperDownload(async () => {
                                const formValues = form.getFieldsValue();

                                const formData = await convertDataToPrint({
                                    title: templateData.template?.title || templateData.title,
                                    description: templateData.template?.description || templateData.description,
                                    reference: templateData.reference,
                                    creationDate: templateData.creationDate,
                                    updatedDate: templateData.updatedDate,
                                    fields: formValues.fields?.map((e, i) => ({
                                        type: templateData.fields[i].type,
                                        value: e?.value || "",
                                        title: templateData.fields[i].title,
                                        options: templateData.fields[i].options,
                                        requiredUser: templateData.fields[i].requiredUser,
                                    })),
                                    customerConfig,
                                });
                                await functionExportToPDF(formData);
                            });
                        }}
                    />
                )}
            </Flex>
        </Flex>
    );
};

export const FormAnswer = ({ templateData }) => {
    const [customerConfig, setcustomerConfig] = useState();
    useEffect(() => {
        if (!customerConfig)
            getCustomerConfig(
                templateData?.author
                    ? {
                          authorId: templateData?.author?._id,
                      }
                    : {},
            ).then((data) => {
                setcustomerConfig(data);
            });
    }, [customerConfig]);

    return (
        <>
            {customerConfig && (
                <img
                    src={
                        customerConfig?.logoEmail
                            ? getAppImage("customers/" + customerConfig.logoEmail)
                            : "/img/logo-big.png"
                    }
                    className="m-auto h-[60px]"
                    alt={customerConfig ? customerConfig.name : t("applicationTitle")}
                />
            )}
            <Text
                style={{
                    whiteSpace: "pre-wrap",
                }}>
                {templateData.description || templateData.template?.description}
            </Text>
            <Divider />

            {templateData.fields?.map((field, i) => (
                <WrapperFieldAnswer field={field} index={i} key={i} />
            ))}
        </>
    );
};

export const WrapperFieldAnswer = ({ preview = false, index, field }) => {
    const { customerConfig, permissionsTools } = useProfileStore();
    const users = [...customerConfig.forms.workerUsers, ...customerConfig.forms.adminUsers];
    const requiredUser = users.find((u) => u._id === field.requiredUser);
    const { user } = useProfileStore();
    const isAdmin = permissionsTools?.formsAdmin?.canView;
    const { token } = theme.useToken();
    const form = Form.useFormInstance();
    const status = form.getFieldValue("status");
    const isLocked = requiredUser
        ? !isAdmin && requiredUser?._id !== user?._id
        : false || (status === "APPROVED" && !isAdmin) || preview;

    if (!requiredUser) {
        return <RenderFieldAnswer index={index} {...field} disabled={isLocked} />;
    }

    if (requiredUser)
        return (
            <Tag
                style={{
                    paddingTop: token.paddingSM,
                    margin: 0,
                    marginBottom: token.paddingLG,
                    width: "100%",
                    fontWeight: "initial",
                }}
                color={isLocked ? "default" : "primary"}>
                <Flex
                    align="start"
                    gap="small"
                    style={{
                        marginBottom: token.paddingSM,
                    }}>
                    <MaterialIcons size="xs" type="secondary" name={isLocked ? "lock" : "lock_open_right"} />
                    <Text bold size="sm" type="secondary" ellipsis>
                        {requiredUser?.firstname} {requiredUser?.lastname} - {requiredUser?.email}
                    </Text>
                </Flex>
                <RenderFieldAnswer index={index} {...field} disabled={isLocked} />
            </Tag>
        );
};

const RenderFieldAnswer = (props) => {
    switch (props.type) {
        case "TITLE":
            return <TitleAnswer {...props} />;
        case "SUBTITLE":
            return <SubtitleAnswer {...props} />;
        case "PARAGRAPH":
            return <ParagraphAnswer {...props} />;
        case "SEPARATOR":
            return <SeparatorAnswer {...props} />;
        case "TEXT_SHORT":
            return <TextShortAnswer {...props} />;
        case "TEXT_LONG":
            return <TextLongAnswer {...props} />;
        case "NUMBER":
            return <NumberAnswer {...props} />;
        case "DATE":
            return <DateAnswer {...props} />;
        case "TIME":
            return <TimeAnswer {...props} />;
        case "BOOLEAN":
            return <BooleanAnswer {...props} />;
        case "SELECT_CHECKBOX":
            return <SelectCheckboxAnswer {...props} />;
        case "SELECT_RADIO":
            return <SelectRadioAnswer {...props} />;
        case "SELECT_LIST":
            return <SelectListAnswer {...props} />;
        case "ZONE":
            return <ZoneAnswer {...props} />;
        case "PICTURE":
            return <PictureAnswer {...props} />;
        case "SIGNATURE":
            return <SignatureAnswer {...props} />;
        case "STATE":
            return <StateAnswer {...props} />;
        case "WEATHER":
            return <WeatherAnswer {...props} />;
        case "REFERENCE":
            return <ReferenceAnswer {...props} />;
        default:
            return <>Type de champ inconnu</>;
    }
};

export const FooterAnswerForm = ({ _id, submit, templateData, planOptions }) => {
    const { t } = useTranslation();

    const { addModalData, removeModal } = useContext(ModalContext);
    const { wrapperSyncElement } = useContext(PopupContext);
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const [id, setid] = useState(_id);
    const { user, permissionsTools } = useProfileStore();

    const form = Form.useFormInstance();
    const { status, author } = form.getFieldsValue();
    const isAdmin = !!permissionsTools?.formsAdmin?.canView;

    return (
        <Flex justify="end" gap="small" align="center" wrap>
            <Form.Item name="_id" hidden />
            <Form.Item name="status" hidden />
            <Form.Item name="author" hidden />
            <Form.Item name="description" hidden />
            <Form.Item name="template" hidden />

            {((status === "DRAFT" && author._id === user._id) || isAdmin) && id && (
                <Flex
                    justify="start"
                    style={{
                        marginRight: "auto",
                        width: 150,
                    }}>
                    <Button
                        variant="text"
                        color="danger"
                        children={t("supprimer")}
                        onClick={() => {
                            addModalData({
                                key: "delete-form",
                                title: "Supprimer le formulaire",
                                content: "Êtes-vous sûr de vouloir supprimer ce formulaire ?",
                                confirmText: "Supprimer",
                                handleConfirm: async () => {
                                    await wrapperSyncElement(async () => {
                                        await deleteAnswerForm({
                                            id: id,
                                        });
                                        await submit();
                                    });
                                },
                            });
                        }}
                    />
                </Flex>
            )}

            <Button
                children={t("annuler")}
                type="text"
                onClick={() => {
                    removeModal(formModalKey);
                }}
            />

            {status === "PENDING" && isAdmin && (
                <>
                    <Button
                        icon={<MaterialIcons name="close" />}
                        size={mdMedia ? "large" : "middle"}
                        variant="outlined"
                        color="danger"
                        children={t("refuser")}
                        onClick={() => {
                            wrapperSyncElement(async () => {
                                await validateAnswerForm({
                                    id: _id,
                                    isAccepted: false,
                                });
                                await submit();
                            });
                        }}
                    />
                    <SuccessButton
                        icon={<MaterialIcons name="check" />}
                        size={mdMedia ? "large" : "middle"}
                        variant="outlined"
                        color="primary"
                        children={t("valider")}
                        onClick={() => {
                            wrapperSyncElement(async () => {
                                await validateAnswerForm({
                                    id: _id,
                                    isAccepted: true,
                                });
                                await submit();
                            });
                        }}
                    />
                </>
            )}

            {(status === "DRAFT" || status === "REJECTED" || (status === "APPROVED" && isAdmin)) && id && (
                <Button
                    size={mdMedia ? "large" : "middle"}
                    variant="solid"
                    color="primary"
                    icon={<MaterialIcons name="send" />}
                    children={t("enValidation")}
                    onClick={() => {
                        wrapperSyncElement(async () => {
                            await sendAnswerForm({
                                id: _id,
                                send: status === "DRAFT" || status === "REJECTED" || (status === "APPROVED" && isAdmin),
                            });
                            await submit();
                        });
                    }}
                />
            )}

            {(status !== "APPROVED" || isAdmin) && (
                <Button
                    size={mdMedia ? "large" : "middle"}
                    variant="solid"
                    color="primary"
                    icon={<MaterialIcons name="save" />}
                    children={t("enregistrer")}
                    onClick={() => {
                        wrapperSyncElement(async () => {
                            const values = form.getFieldsValue();
                            const fields = Array.from(values.fields);
                            if (!id) {
                                const newForm = await newAnswerForm({ id: templateData._id });
                                await saveAnswerForm({
                                    id: newForm._id,
                                    position: !!planOptions
                                        ? {
                                              file: planOptions.selectedPlan._id,
                                              marker: planOptions.positionClick,
                                          }
                                        : null,
                                    fields:
                                        fields?.map((e, i) => ({
                                            type: templateData.fields[i].type,
                                            value: e?.value === undefined ? "" : e?.value,
                                            title: templateData.fields[i].title,
                                            options: templateData.fields[i].options,
                                            requiredUser: templateData.fields[i].requiredUser,
                                            templateField: templateData.fields[i]._id,
                                        })) || [],
                                });
                            } else {
                                await saveAnswerForm({
                                    id: id,
                                    fields:
                                        fields?.map((e, i) => ({
                                            type: templateData.fields[i].type,
                                            value: e?.value === undefined ? "" : e?.value,
                                            options: templateData.fields[i].options,
                                            title: templateData.fields[i].title,
                                            requiredUser: templateData.fields[i].requiredUser,
                                            templateField: templateData.fields[i].templateField,
                                        })) || [],
                                });
                            }
                            await submit();
                        });
                    }}
                />
            )}
        </Flex>
    );
};
