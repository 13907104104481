import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, Form, Input, Row, Switch, theme } from "antd";

export function BooleanAnswer({ index, title, disabled }) {
    return (
        <Form.Item name={["fields", index, "value"]} label={<Text bold>{title} :</Text>}>
            <Switch disabled={disabled} />
        </Form.Item>
    );
}
export function BooleanTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Valeur par défaut :" name={[index, "defaultValue"]} valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Col>
        </Row>
    );
}

export function BooleanPrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.value ? "Oui" : "Non"}
        </TextPrint>
    );
}
