import Checkbox from "components/input/Checkbox";

const RenderCheckboxField = ({ title, _id, value, onChange }) => {
    return (
        <Checkbox
            text={<span className="font-bold">{title}</span>}
            name={_id}
            placeholder={title}
            value={value || false}
            onChange={(e) => onChange(e.target.checked)}
        />
    );
};

export default RenderCheckboxField;
