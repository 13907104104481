import { useContext } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";

import { Avatar, Flex, Table, theme } from "antd";

// Filter by Filter sidepanel Users
export const applyFiltersUsers = (dataList, filter) =>
    filter.length === 0
        ? dataList
        : dataList.filter((e) =>
              // Apply filters users
              filter.find(
                  (k) =>
                      k === e.userId ||
                      k === e.user?._id ||
                      k === e.author?._id,
              ),
          );

const FilterByUsers = () => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { setToolsData, toolsData } = useContext(DriveToolsContext);

    const listMembers = toolsData.filtersStore.users.map((e) => ({
        ...e,
        role: {
            _id: "/",
        },
        user: e,
    }));

    if (listMembers.length === 0) return <div>{t("aucuneDonneeAFiltrer")}</div>;
    return (
        <Table
            size="small"
            dataSource={listMembers}
            pagination={false}
            rowKey={(record) => record.user._id}
            rowSelection={{
                selectedRowKeys: toolsData.filters.users,
                onChange: (e, v) => {
                    setToolsData((k) => ({
                        ...k,
                        filters: {
                            ...k.filters,
                            users: e,
                        },
                    }));
                },
            }}
            onRow={(record) => {
                return {
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => {
                        setToolsData((k) => ({
                            ...k,
                            filters: {
                                ...k.filters,
                                users: toolsData.filters.users.includes(
                                    record.user._id,
                                )
                                    ? toolsData.filters.users.filter(
                                          (key) => key !== record.user._id,
                                      )
                                    : [
                                          ...toolsData.filters.users,
                                          record.user._id,
                                      ],
                            },
                        }));
                    },
                };
            }}
            columns={[
                {
                    title: "Membres",
                    dataIndex: "user",
                    key: "user",
                    render: (user) => <AvatarUser user={user} />,
                },
            ]}
        />
    );
};

export const AvatarUser = ({ user }) => (
    <Flex gap="small" align="center">
        <Avatar
            style={{
                backgroundColor: "black",
            }}
        >
            {user.firstname[0]}
            {user.lastname[0]}
        </Avatar>
        <Flex
            vertical
            style={{
                flex: 1,
                display: "grid",
            }}
        >
            <Text bold ellipsis>
                {user.firstname} {user.lastname}
            </Text>
            <Text size="small" ellipsis>
                {user.email}
            </Text>
        </Flex>
    </Flex>
);

export default FilterByUsers;
