import { Fragment } from "react";

import PasswordFieldValidation from "./PasswordFieldValidation";

const TextField = ({
    text,
    placeholder = "",
    name,
    value,
    maxLength,
    type = "text",
    onChange,
    errorMsg,
    isRequired = false,
    autoComplete = "on",
    isDisabled = false,
    enablePasswordValidation = false,
    handleKeyDown,
    isTransparent = false,
}) => {
    return (
        <Fragment>
            {text && (
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={name}
                >
                    {isRequired && <span className="text-red-500">*</span>}{" "}
                    {text}
                </label>
            )}

            <input
                placeholder={placeholder}
                className={
                    "border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline h-[38px]" +
                    (errorMsg ? " border border-red-500" : "") +
                    (isDisabled ? " opacity-50" : "") +
                    (isTransparent ? " bg-transparent border-2" : " shadow")
                }
                id={name}
                name={name}
                value={value}
                maxLength={maxLength}
                type={type}
                onChange={(e) => onChange && onChange(e)}
                onKeyDown={handleKeyDown && handleKeyDown}
                autoComplete={autoComplete}
                disabled={isDisabled}
                min={0}
            />
            {errorMsg && (
                <div className="error-msg font-bold text-red-500 text-xs italic mt-2">
                    {errorMsg}
                </div>
            )}
            {type === "password" && enablePasswordValidation && (
                <PasswordFieldValidation value={value} />
            )}
        </Fragment>
    );
};

export default TextField;
