import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { getProjectparentsList } from "helpers/project";

import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";

import { Breadcrumb, Dropdown } from "antd";

const ProjectBreadcrumb = ({
    project,
    onClick,
    readOnly = false,
    isFromLocation = false,
    profile,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [breadcrumb, setBreadcrumb] = useState([]);

    const driveContext = useContext(DriveToolsContext);

    const profileUsed = profile || driveContext?.userProfile;

    const rootPath = {
        key: "mesProjets",
        title: onClick ? (
            <div
                style={{
                    cursor: readOnly ? "default" : "pointer",
                }}
                onClick={() => !readOnly && onClick && onClick(null)}
            >
                {t("mesProjets")}
            </div>
        ) : !readOnly ? (
            <Link to={"/drive"}>{t("mesProjets")}</Link>
        ) : (
            <Text>{t("mesProjets")}</Text>
        ),
    };

    useEffect(() => {
        const generateBreadcrumb = async (project) => {
            let parents = await getProjectparentsList(project._id);
            const breadcrumbItems = [];

            let canViewChild = false;
            parents = parents.filter((item) => {
                // restrictedUsers is filtered in the backend
                if (canViewChild) return true;
                if (
                    item.users?.find((e) => e.user === profileUsed?.user?._id)
                ) {
                    canViewChild = true;
                    return true;
                } else return false;
            });
            // "mesProjets" as the first item in the breadcrumb
            breadcrumbItems.push(rootPath);

            const addedFolders = new Set();

            if (parents && parents.length > 0) {
                for (let i = 0; i < parents.length; i++) {
                    const parent = parents[i];

                    if (
                        parents.length > 2 &&
                        i === 1 &&
                        i !== parents.length - 1
                    ) {
                        const menuItems = parents
                            .slice(1, -1)
                            .map((item) => {
                                if (addedFolders.has(item._id)) {
                                    return null;
                                }

                                addedFolders.add(item._id);

                                return {
                                    key: item._id,
                                    label: (
                                        <div
                                            onClick={() =>
                                                !readOnly &&
                                                (onClick
                                                    ? onClick(item)
                                                    : navigate(
                                                          "/drive/project/" +
                                                              item._id,
                                                      ))
                                            }
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                cursor: readOnly
                                                    ? "default"
                                                    : "pointer",
                                            }}
                                        >
                                            <i
                                                className="material-icons notranslate mr-2"
                                                style={{
                                                    fontSize: "1.1rem",
                                                    color: item.color,
                                                }}
                                            >
                                                folder
                                            </i>
                                            <span>{item.title}</span>
                                        </div>
                                    ),
                                };
                            })
                            .filter(Boolean);

                        breadcrumbItems.push({
                            key: "ellipsis",
                            title: (
                                <Dropdown
                                    menu={{ items: menuItems }}
                                    placement="bottom"
                                    arrow
                                >
                                    <span
                                        style={{
                                            cursor: readOnly
                                                ? "default"
                                                : "pointer",
                                        }}
                                    >
                                        ...
                                    </span>
                                </Dropdown>
                            ),
                        });
                        continue;
                    }

                    if (!addedFolders.has(parent._id)) {
                        addedFolders.add(parent._id);
                        breadcrumbItems.push({
                            key: parent._id,
                            title: onClick ? (
                                <div
                                    onClick={() =>
                                        !readOnly && onClick && onClick(parent)
                                    }
                                    style={{
                                        cursor: readOnly
                                            ? "default"
                                            : "pointer",
                                    }}
                                >
                                    {parent.title}
                                </div>
                            ) : (
                                <Link to={"/drive/project/" + parent._id}>
                                    {parent.title}
                                </Link>
                            ),
                        });
                    }
                }
            }

            if (readOnly && project?.title) {
                // In readOnly mode, add the current folder title to the breadcrumb
                breadcrumbItems.push({
                    key: project._id,
                    title: (
                        <div style={{ cursor: "default", fontWeight: "bold" }}>
                            {project.title}
                        </div>
                    ),
                });
            }

            setBreadcrumb(breadcrumbItems);
        };

        if (project) {
            generateBreadcrumb(project);
        } else {
            setBreadcrumb([rootPath]);
        }
    }, [project, readOnly, onClick]);

    return (
        <Fragment>
            <div className="relative flex flex-row text-xs sm:text-base text-slate-500">
                <Breadcrumb
                    items={breadcrumb}
                    style={{
                        margin: "4px",
                    }}
                    itemRender={(route) => ({
                        ...route.title,
                        props: {
                            ...route.title.props,
                            className: "truncate",
                            style: {
                                ...route.title.props.style,
                                cursor: readOnly ? "default" : "pointer",
                                display: "inline",
                            },
                        },
                    })}
                />
            </div>
            {!readOnly && (
                <div
                    className={
                        "text-sm truncate ml-1" +
                        (!isFromLocation
                            ? " font-bold sm:text-lg hover:cursor-default"
                            : "")
                    }
                    onClick={(e) =>
                        isFromLocation &&
                        navigate("/drive/project/" + project?._id)
                    }
                >
                    {isFromLocation ? " / " + project?.title : project?.title}
                </div>
            )}
        </Fragment>
    );
};

export default ProjectBreadcrumb;
