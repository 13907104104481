import i18n from "i18next";

import { useTranslation } from "react-i18next";

import { isConstraintWithDescription } from "helpers/automation";
import { getFileExtensionIcon } from "helpers/image";
import Constants from "utils/constants";

import Popover from "components/utils/Popover";
import Spinner from "components/utils/Spinner";

import CompleteNomenclature from "./CompleteNomenclature";

const DriveNomenclatureTable = ({
    nomenclatureData,
    answers,
    setAnswers,
    projectToUpload,
    oldFile,
    filesToImport,
    importStates,
    processedFiles,
    currentProcessedFile,
    errorMessage,
    showErrorMessage,
    fieldsError,
    removeLine,
}) => {
    const { t } = useTranslation();

    return (
        <table className="text-black text-left text-sm">
            <thead>
                <tr>
                    <th className="pb-4 px-2">{t("nomDuFichier")}</th>
                    <th className="pb-4 px-2">{t("import")}</th>
                    {nomenclatureData.actions.map((action, i) => (
                        <th key={i} className="pb-4 px-2">
                            {action.description &&
                            isConstraintWithDescription(action.constraint.code)
                                ? action.description
                                : action.constraint["title" + i18n.locale]}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {filesToImport.map((file, fileIndex) => (
                    <tr key={fileIndex}>
                        <td className="relative p-2 whitespace-nowrap">
                            <button
                                className="absolute flex top-[-2px] left-0 p-1"
                                type="button"
                                onClick={(e) => removeLine(fileIndex)}
                            >
                                <i
                                    className="material-icons notranslate m-auto bg-slate-200 rounded-full p-0.5"
                                    style={{ fontSize: "1rem" }}
                                >
                                    close
                                </i>
                            </button>
                            <div className="flex flex-row">
                                <div className="w-[40px]">
                                    <img
                                        src={getFileExtensionIcon(file)}
                                        loading="lazy"
                                        alt={file.name}
                                        className="w-full"
                                    ></img>
                                </div>
                                <div className="my-auto ml-1">
                                    {file.name
                                        .substr(0, file.name.lastIndexOf("."))
                                        .substring(
                                            0,
                                            Constants.maxLengthFileTitle,
                                        )}
                                </div>
                            </div>
                        </td>
                        <td className="p-2">
                            {currentProcessedFile === fileIndex ? (
                                <Spinner
                                    isSmall={true}
                                    widthClass=" w-8"
                                    heightClass=" w-8"
                                />
                            ) : (
                                <div
                                    className="relative"
                                    onMouseEnter={(e) => {
                                        showErrorMessage(fileIndex);
                                    }}
                                    onMouseLeave={(e) => {
                                        showErrorMessage(null);
                                    }}
                                    onClick={(e) => {
                                        showErrorMessage((currentIndex) =>
                                            currentIndex === fileIndex
                                                ? null
                                                : fileIndex,
                                        );
                                    }}
                                >
                                    <div
                                        className={
                                            "border-2 text-sm rounded-md p-1 hover:cursor-pointer" +
                                            (importStates[fileIndex] === ""
                                                ? " text-green-500 border-green-500"
                                                : " border-red-500 text-red-500")
                                        }
                                    >
                                        {t(
                                            processedFiles[fileIndex]
                                                ? "importe"
                                                : processedFiles[fileIndex] ===
                                                    false
                                                  ? "erreur"
                                                  : importStates[fileIndex] ===
                                                      ""
                                                    ? "valide"
                                                    : "erreur",
                                        )}
                                    </div>

                                    {importStates[fileIndex] !== "" &&
                                        errorMessage === fileIndex && (
                                            <Popover
                                                text={importStates[fileIndex]}
                                                width={250}
                                            />
                                        )}
                                </div>
                            )}
                        </td>
                        {nomenclatureData.actions.map((action, i) => (
                            <td key={i} className="p-2">
                                <CompleteNomenclature
                                    action={action}
                                    indexField={i + "_" + fileIndex}
                                    projectToUpload={projectToUpload}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    oldFile={oldFile}
                                    fileName={file.name
                                        .substr(0, file.name.lastIndexOf("."))
                                        .substring(
                                            0,
                                            Constants.maxLengthFileTitle,
                                        )}
                                />
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DriveNomenclatureTable;
