import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";

const MenuItem = ({
    to,
    text,
    isFromMobile = false,
    isBlackText = true,
    subMenu,
}) => {
    const itemRef = useRef(null);

    const [isShowSubMenu, showSubMenu] = useState(false);

    return (
        <li
            ref={itemRef}
            className="relative my-auto px-2 font-bold"
            onMouseMove={(e) => subMenu && !isFromMobile && showSubMenu(true)}
            onMouseLeave={(e) => subMenu && !isFromMobile && showSubMenu(false)}
            onClick={(e) =>
                subMenu && isFromMobile && showSubMenu(!isShowSubMenu)
            }
        >
            <NavLink
                to={to ? to : ""}
                className={({ isActive }) =>
                    "flex flex-row p-3 md:p-0 md:py-1 h-full md:text-xs" +
                    (isFromMobile ? " text-black hover:text-primary" : "") +
                    (isActive && isFromMobile && to
                        ? " text-primary"
                        : isActive &&
                            !!to &&
                            !isFromMobile &&
                            isBlackText &&
                            !subMenu
                          ? " border-b-2 border-black"
                          : isActive &&
                              !isFromMobile &&
                              !isBlackText &&
                              !subMenu
                            ? " border-b-2 border-white"
                            : !subMenu && !isFromMobile
                              ? " hover:border-b-2"
                              : "") +
                    (isBlackText
                        ? " text-black hover:border-black"
                        : " text-white hover:border-white")
                }
            >
                {text}
                {subMenu && isFromMobile && (
                    <div className="w-full text-right">
                        {isShowSubMenu ? (
                            <i
                                className="material-icons notranslate"
                                style={{ fontSize: "1.8rem" }}
                            >
                                expand_more
                            </i>
                        ) : (
                            <i
                                className="material-icons notranslate"
                                style={{ fontSize: "1.8rem" }}
                            >
                                chevron_right
                            </i>
                        )}
                    </div>
                )}
            </NavLink>
            {isShowSubMenu && subMenu && subMenu}
        </li>
    );
};

export default MenuItem;
