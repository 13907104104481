import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";
import { create } from "zustand";

import { useNavigate, useParams } from "react-router";

import apiURL from "utils/apiURL";

import FormAnswerList from "./FormAnswerList";
import FormTemplate from "./FormTemplate";
import FormTemplateList from "./FormTemplateList";

import { Tabs } from "antd";

export default function FormView() {
    const { formId } = useParams();
    const navigate = useNavigate();

    const { permissionsTools } = useProfileStore();

    if (!permissionsTools.formsAdmin.canView && !permissionsTools.formsUser.canView) {
        navigate("/drive");
        return <></>;
    }

    if (formId) return <FormTemplate formId={formId} />;

    return (
        <Tabs
            id="tabs-forms"
            animated
            activeKey={location.pathname}
            destroyInactiveTabPane
            onTabClick={(key) => navigate(key)}
            items={[
                // permissionsTools.formsAdmin.canView && {
                //     label: "Journal",
                //     key: "/forms/answers",
                //     children: <FormAnswerList isAdmin />,
                // },
                (permissionsTools.formsAdmin.canView || permissionsTools.formsUser.canView) && {
                    label: "Journal des formulaires",
                    key: "/forms",
                    children: <FormAnswerList />,
                },
                permissionsTools.formsAdmin.canView && {
                    label: "Modèles de formulaires",
                    key: "/forms/templates",
                    children: <FormTemplateList isAdmin />,
                },
            ]}
        />
    );
}

export const useFormsStore = create((set, get) => ({
    loading: true,
    // Data
    listAnswers: null,
    listTemplates: null,

    templateRef: false,
    answerRef: false,
    // Actions

    // Admin / Templates
    getTemplatesForms: async () => {
        const { data } = await axios.get(apiURL.formTemplates);
        set({ listTemplates: data });
        return data;
    },
    setFormTemplateRef: async (form) => {
        set({ templateRef: form });
    },
    setLoading: async (loading) => {
        set({ loading });
    },

    // User / Answers
    getAnwsersForm: async () => {
        const { data } = await axios.get(apiURL.formAnswers);
        set({ listAnswers: data });
        return data;
    },
    setFormAnswerRef: async (form) => {
        set({ answerRef: form });
    },
}));
