import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { getActualSeason } from "helpers/timesheet";

import { ModalContext } from "layout/LayoutModal";

import ModalEntry from "../../modalEntry/ModalEntry";

const TableNewRow = ({ onAddInline }) => {
    const { t } = useTranslation();
    const { addModalData, removeModal } = useContext(ModalContext);
    const { scheduleConfiguration } = useProfileStore();
    const actualSeason = getActualSeason(scheduleConfiguration);

    return (
        <tr
            className="h-[40px] cursor-pointer group"
            onClick={() => {
                addModalData({
                    key: "modalEntry",
                    title: t("nouvelleSaisieDeTemps"),
                    isForm: false,
                    content: (
                        <ModalEntry
                            canSelectDate
                            canSelectProject
                            canSelectUsers
                            isFromAdmin
                            onClose={async (entryData) => {
                                await Promise.all(
                                    entryData.forUsers.map((user) =>
                                        onAddInline({
                                            data: entryData.data.map((e) => ({
                                                ...e,
                                                timesheetField: e.timesheetField?._id,
                                            })),
                                            timesheetTask: entryData.timesheetTask?._id,
                                            timesheetTaskTitle: entryData.timesheetTask?.title,
                                            project: entryData.isRecovery ? null : entryData.project?._id,
                                            startDate: entryData.startDate,
                                            endDate: entryData.endDate,
                                            author: user,
                                            isRecovery: entryData.isRecovery,
                                        }),
                                    ),
                                );
                                removeModal("modalEntry");
                            }}
                            preData={{
                                startDate: moment()
                                    .set({
                                        hours: parseInt(actualSeason.workDayStartHour.split(":")[0]),
                                        minute: parseInt(actualSeason.workDayStartHour.split(":")[1]),
                                        second: 0,
                                    })
                                    .toISOString(),
                                endDate: moment()
                                    .set({
                                        hours: parseInt(actualSeason.workDayEndHour.split(":")[0]),
                                        minute: parseInt(actualSeason.workDayEndHour.split(":")[1]),
                                        second: 0,
                                    })
                                    .toISOString(),
                            }}
                        />
                    ),
                });
            }}>
            {/* BUTTON ADD  */}
            <td className="duration-200 group-hover:text-slate-800 align-middle border text-xs whitespace-nowrap  text-gray-300 group-hover:border-slate-800">
                <button className="w-full" onClick={async () => {}}>
                    <i className="material-icons notranslate align-middle" style={{ fontSize: "1.2rem" }}>
                        add
                    </i>
                </button>
            </td>

            <td
                colSpan="8"
                className="duration-200 group-hover:text-slate-800 align-middle px-3 justify-center border text-2xs text-gray-300 whitespace-nowrap group-hover:border-slate-800">
                Saisir une nouvelle entrée
            </td>
        </tr>
    );
};

export default TableNewRow;
