import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

import { getFileExtension, isIfcFile, isPcdFile } from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";

import VisaBadge from "./VisaBadge";

const VisasTableLine = ({
    profile,
    item,
    visasCols,
    onItemClick,
    setRenderedDocument,
}) => {
    const navigate = useNavigate();

    const handleClick = (e) => {
        if (onItemClick) onItemClick(item._id, false, true);
        else {
            if (isIfcFile(item) || isPcdFile(item))
                window.open("/viewer/" + item._id);
            else setRenderedDocument(item._id);
        }
    };
    const handleDoubleClick = (e) => {
        if (onItemClick) {
            if (isIfcFile(item) || isPcdFile(item))
                window.open("/viewer/" + item._id);
            else setRenderedDocument(item._id);
        }
    };

    const refreshItem = () => {
        onItemClick(item._id, false);
    };

    return (
        <tr
            key={item._id}
            className="group border-t-2 border-b-2 hover:bg-slate-200 text-xs"
        >
            <td className="sticky left-0 z-[1] group-hover:bg-slate-200 w-[150px] sm:w-[300px] lg:-[400px] xl:w-[500px]">
                <div
                    onClick={(e) => handleClick(e)}
                    onDoubleClick={(e) => handleDoubleClick(e)}
                    className="py-4 px-2 sm:p-2 block hover:cursor-pointer truncate"
                >
                    <div className="flex flex-row">
                        <div className="relative w-10 flex items-center">
                            {!!item.versions?.length && !item.isOldVersion && (
                                <Fragment>
                                    <img
                                        src={getFileExtensionIcon(item)}
                                        alt=""
                                        width="26"
                                        className="mx-auto absolute ml-2 opacity-20"
                                    />
                                    <img
                                        width="26"
                                        src={getFileExtensionIcon(item)}
                                        alt=""
                                        className="mx-auto absolute ml-1 opacity-50"
                                    />
                                </Fragment>
                            )}
                            <img
                                width="26"
                                src={getFileExtensionIcon(item)}
                                alt=""
                                className="mx-auto absolute"
                            />
                        </div>
                        <div className="flex flex-row">
                            <div className="w-full my-auto" title={item.title}>
                                {item.title +
                                    (item.file
                                        ? "." + getFileExtension(item.file)
                                        : item.compiledFiles?.length > 0
                                          ? ".ifc"
                                          : "")}
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            {visasCols.map((col) => (
                <td key={col.id}>
                    <div
                        onClick={(e) => handleClick(e)}
                        onDoubleClick={(e) => handleDoubleClick(e)}
                        className="p-4 sm:p-2 block hover:cursor-pointer"
                    >
                        {item.visas
                            .filter((visa) => visa.visa)
                            .find((visa) => visa.visa._id === col.id) && (
                            <VisaBadge
                                visa={item.visas
                                    .filter((visa) => visa.visa)
                                    .find((visa) => visa.visa._id === col.id)}
                                user={profile.user}
                                file={item}
                                refreshAction={refreshItem}
                            />
                        )}
                    </div>
                </td>
            ))}
        </tr>
    );
};

export default VisasTableLine;
