import axios from "axios";
import { useAppStore } from "providers/ProviderApp";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";

import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import FilesList from "components/project/FilesList";
import SkeletonFileItem from "components/skeleton/SkeletonFileItem";

import { Col, Row } from "antd";

const DriveFilteredList = ({
    type,
    profile,
    refreshAction,
    addSelectedFile,
    selectedFiles,
    setSelectedFiles,
    currentItem,
    userRoles,
}) => {
    const { t } = useTranslation();

    const { onItemClick } = useContext(DriveToolsContext);
    const { viewMode } = useAppStore();
    const isListMode = viewMode === "list";

    const [files, setFiles] = useState([]);

    const [isSkeletonVisible, showSkeleton] = useState(false);

    useEffect(() => {
        onItemClick(null);
        setSelectedFiles([]);
        showSkeleton(true);

        try {
            if (type === "flow") {
                document.title = t("flux") + " - " + t("applicationTitle");

                const getFlow = async () => {
                    const resFlow = await axios.get(apiURL.getProfileFlow);

                    setFiles(resFlow.data);
                    showSkeleton(false);
                };

                getFlow();
            } else if (type === "recents") {
                document.title = t("recents") + " - " + t("applicationTitle");

                const getRecents = async () => {
                    const resRecents = await axios.get(
                        apiURL.getProfileRecents,
                    );

                    setFiles(resRecents.data);
                    showSkeleton(false);
                };

                getRecents();
            }
            if (type === "favorites") {
                document.title = t("favoris") + " - " + t("applicationTitle");

                const getFavorites = async () => {
                    const resFavorites = await axios.get(
                        apiURL.getProfileFavorites,
                    );

                    setFiles(resFavorites.data);
                    showSkeleton(false);
                };

                getFavorites();
            }
        } catch (e) {
            setFiles([]);
            showSkeleton(false);
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    }, [type]);

    return isSkeletonVisible ? (
        <div className={isListMode ? "mt-16" : "mt-10"}>
            {type === "flow" ? (
                <RenderSkeleton
                    isListMode={isListMode}
                    folderList={[...Array(12)]}
                    fileList={[]}
                />
            ) : type === "recents" ? (
                <RenderSkeleton
                    isListMode={isListMode}
                    folderList={profile.recentlyOpened}
                    fileList={profile.recentlyViewed}
                />
            ) : (
                type === "favorites" && (
                    <RenderSkeleton
                        isListMode={isListMode}
                        folderList={profile.favoritesFolders}
                        fileList={profile.favoritesFiles}
                    />
                )
            )}
        </div>
    ) : files.length > 0 ? (
        <FilesList
            profile={profile}
            files={files}
            refreshAction={refreshAction}
            addSelectedFile={addSelectedFile}
            selectedFiles={selectedFiles}
            setSelectedFiles={setSelectedFiles}
            isListMode={isListMode}
            onItemClick={onItemClick}
            currentItem={currentItem}
            userRoles={userRoles}
            showLocation={true}
            showLastOpenDate={type === "recents"}
        />
    ) : (
        <div className="text-center mt-20">
            <Text size="md">
                {type === "flow"
                    ? t("fluxIndisponible")
                    : type === "recents"
                      ? t("aucuneActivite")
                      : type === "favorites" && t("aucunFavoris")}
            </Text>
        </div>
    );
};

const RenderSkeleton = ({ isListMode, folderList, fileList }) => {
    return (
        <Fragment>
            {isListMode ? (
                folderList
                    .concat(fileList)
                    .map((_, i) => <SkeletonFileItem key={i} />)
            ) : (
                <Row
                    gutter={[24, 24]}
                    style={{
                        padding: "24px 0",
                    }}
                >
                    {folderList.concat(fileList).map((_, i) => (
                        <Col xxl={6} xl={8} lg={12} md={12} xs={24} key={i}>
                            <SkeletonFileItem />
                        </Col>
                    ))}
                </Row>
            )}
        </Fragment>
    );
};

export default DriveFilteredList;
