import DOMPurify from "dompurify";
import PropTypes from "prop-types";

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router";

import { toastAlert } from "helpers/alert";
import { fieldValidation } from "helpers/fieldValidation";
import { capitalize } from "helpers/string";
import Constants from "utils/constants";

import Button from "components/input/Button";
import ContactFormButton from "components/input/ContactFormButton";
import TextField from "components/input/TextField";
import AcceptConditions from "components/institutional/AcceptConditions";
import Spinner from "components/utils/Spinner";

import { login } from "actions/auth";
import { getCurrentProfile } from "actions/profile";

import ResetPassword from "../ResetPassword";
import LoginRegisterForm from "./LoginRegisterForm";

import { Alert } from "antd";

const Login = ({
    login,
    getCurrentProfile,
    isFromFile = false,
    closeModal,
    defaultRegister = false,
    isLoginPage = false,
}) => {
    const { t } = useTranslation();
    const location = useLocation();

    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState({
        loginEmail: "",
        loginPassword: "",
        guestEmail: "",
        guestName: "",
    });

    const [fieldsError, setFieldsError] = useState({
        loginEmail: "",
        loginPassword: "",
        guestEmail: "",
        guestName: "",
    });

    const { loginEmail, loginPassword, guestEmail, guestName } = formData;

    const [asGuest, setAsGuset] = useState(isFromFile);
    const [isRegisterForm, setRegisterForm] = useState(
        location.state?.noAccount ? true : defaultRegister,
    );
    const [isResetFormVisible, showResetForm] = useState(false);

    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            if (guestEmail !== "" && guestName !== "") {
                // On se connecte en tant qu'invité pour pouvoir laisser des commentaire sur les fichiers

                localStorage.setItem("guestEmail", guestEmail);
                localStorage.setItem("guestName", guestName);
                closeModal();
            } else {
                try {
                    const loginRes = await login(loginEmail, loginPassword);

                    if (loginRes.errors) {
                        loginRes.errors.map((error) => {
                            return toastAlert("error", t(error.msg));
                        });
                    } else {
                        getCurrentProfile();
                        closeModal && closeModal();
                    }
                } catch (error) {
                    return null;
                }
            }
        }

        setLoader(false);
    };

    const fieldsValidation = async () => {
        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        if (asGuest) {
            validation = fieldValidation(formData.guestEmail, "required|email");
            updatedFieldsError.guestEmail = validation;
            if (validation !== "") nbErrors++;

            validation = fieldValidation(formData.guestName, "required");
            updatedFieldsError.guestName = validation;
            if (validation !== "") nbErrors++;
        } else {
            validation = fieldValidation(formData.loginEmail, "required|email");
            updatedFieldsError.loginEmail = validation;
            if (validation !== "") nbErrors++;

            validation = fieldValidation(formData.loginPassword, "required");
            updatedFieldsError.loginPassword = validation;
            if (validation !== "") nbErrors++;
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const continueAsContent = (
        <Fragment>
            <div className="text-center my-5">
                <div className="font-bold text-slate-400 text-xl mb-1">
                    {capitalize(t("ou"))}
                </div>
            </div>

            <div className="my-8">
                <Button
                    text={
                        asGuest
                            ? t("seConnecterAvecUnCompteBimono")
                            : t("continuerEnTantQuInvite")
                    }
                    onClick={(e) => setAsGuset(!asGuest)}
                    addPadding={true}
                />
            </div>
        </Fragment>
    );

    return (
        <div
            className={
                "w-full min-h-[600px] max-w-[1200px] m-auto" +
                (!isFromFile && !isLoginPage ? " md:flex md:flex-row " : "") +
                (isLoginPage ? " h-auto" : " h-full")
            }
        >
            {!isFromFile && !isLoginPage && (
                <figure
                    className="relative md:w-[55%] rounded-t-xl md:rounded-l-xl md:rounded-tr-none flex bg-center background-img"
                    style={{
                        backgroundImage: "url(/img/login.svg)",
                    }}
                >
                    <div className="text-white lg:text-lg m-auto py-14 px-6">
                        <div className="text-3xl md:text-5xl text-center font-light">
                            {t("bienvenue!")}
                        </div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                    t("notreMission") +
                                        " : <b>" +
                                        t(
                                            "simplifierLeQuotidienDesProfessionnelsDeLaConstruction",
                                        ) +
                                        "</b>.",
                                ),
                            }}
                            className="mt-4 text-center text-sm"
                        ></p>
                    </div>
                </figure>
            )}

            <div
                className={
                    "relative p-4 md:p-0 md:flex rounded-b-xl md:rounded-r-xl md:rounded-bl-none bg-white pb-16" +
                    (!isFromFile && !isLoginPage ? " md:w-[45%]" : "")
                }
            >
                <div
                    className={
                        "w-full my-auto" +
                        (isFromFile ? " lg:mx-8" : " px-4 md:px-8")
                    }
                >
                    {isLoginPage && (
                        <img
                            src={"/img/logo-big-black.png"}
                            width="200"
                            alt={t("applicationTitle")}
                            className="m-auto"
                        />
                    )}
                    <h5 className="text-black mt-3 text-center text-xl md:text-2xl md:max-w-[250px] m-auto">
                        {!asGuest && !isRegisterForm
                            ? t("connectezVousAVotreCompte")
                            : !asGuest && isRegisterForm
                              ? t("creerUnCompte")
                              : t("apporterUnCommentaire")}
                    </h5>
                    {isRegisterForm ? (
                        <LoginRegisterForm setRegisterForm={setRegisterForm} />
                    ) : isResetFormVisible ? (
                        <ResetPassword showResetForm={showResetForm} />
                    ) : (
                        <Fragment>
                            <form
                                className="mt-8 lg:mt-0"
                                onSubmit={(e) => onSubmit(e)}
                            >
                                {location.state?.filePermissionRequired ? (
                                    <Alert
                                        message={t(
                                            "uneAutorisationEstNecessaire",
                                        )}
                                        description={
                                            t(
                                                "vousNetesPasEncoreMembreDuDossierVeuillezContacterLeProprietaire",
                                            ) + "."
                                        }
                                        type="warning"
                                        showIcon
                                        className="mt-8"
                                    />
                                ) : (
                                    location.state?.registerForJoinFolder && (
                                        <Alert
                                            message={t("rejoindreLeDossier")}
                                            description={
                                                t(
                                                    "vousAvezBienEteAjouteAuxMembresDuDossier",
                                                ) +
                                                ' "' +
                                                location.state.folderName +
                                                '". ' +
                                                t(
                                                    "IlNeVousRestePlusQuaVousConnecterOuACreerUnComptePourYAcceder",
                                                ) +
                                                "."
                                            }
                                            type="info"
                                            showIcon
                                            className="mt-8"
                                        />
                                    )
                                )}
                                {asGuest ? (
                                    <Fragment>
                                        <h6 className="font-bold text-black mt-6">
                                            {t("continuerEnTantQuInvite")}
                                        </h6>
                                        <div className="my-6">
                                            <TextField
                                                text={t("nom")}
                                                name="guestName"
                                                onChange={onChange}
                                                maxLength={
                                                    Constants.maxLengthFirstname +
                                                    Constants.maxLengthLastname
                                                }
                                                errorMsg={fieldsError.guestName}
                                            />
                                        </div>

                                        <div className="mb-6">
                                            <TextField
                                                text={t("email")}
                                                name="guestEmail"
                                                type="email"
                                                onChange={onChange}
                                                maxLength={
                                                    Constants.maxLengthEmail
                                                }
                                                errorMsg={
                                                    fieldsError.guestEmail
                                                }
                                            />
                                        </div>

                                        <div className="inline-block">
                                            <Button
                                                type="submit"
                                                text={t("continuer")}
                                                onClick={onSubmit}
                                                isDisabled={loader}
                                                addPadding={true}
                                            />
                                        </div>
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <div className="my-6">
                                            <TextField
                                                text={t("email")}
                                                name="loginEmail"
                                                type="email"
                                                onChange={onChange}
                                                maxLength={
                                                    Constants.maxLengthEmail
                                                }
                                                errorMsg={
                                                    fieldsError.loginEmail
                                                }
                                            />
                                        </div>
                                        <div className="mb-6">
                                            <TextField
                                                text={t("motDePasse")}
                                                name="loginPassword"
                                                type="password"
                                                onChange={onChange}
                                                maxLength={
                                                    Constants.maxLengthPassword
                                                }
                                                errorMsg={
                                                    fieldsError.loginPassword
                                                }
                                            />
                                        </div>
                                        <div className="inline-block w-full mb-6">
                                            <button
                                                type="button"
                                                className="text-sm float-right"
                                                onClick={(e) =>
                                                    showResetForm(true)
                                                }
                                            >
                                                {t("motDePasseOublie?")}
                                            </button>
                                        </div>

                                        <div
                                            className={
                                                isFromFile ? "inline-block" : ""
                                            }
                                        >
                                            <Button
                                                type="submit"
                                                text={t("sAuthentifier")}
                                                onClick={onSubmit}
                                                isDisabled={loader}
                                                addPadding={true}
                                            />
                                        </div>

                                        {isFromFile && continueAsContent}
                                    </Fragment>
                                )}
                            </form>

                            <Fragment>
                                {asGuest && continueAsContent}

                                <div
                                    className={
                                        "w-full sm:text-center md:text-left lg:text-center" +
                                        (!isFromFile ? " mt-5" : "")
                                    }
                                >
                                    <div className="inline-block text-xs text-black">
                                        {t("pasEncoreMembre?")}
                                    </div>

                                    <div className="font-bold underline text-xs inline-block ml-2 hover:cursor-pointer">
                                        {location.state
                                            ?.registerForJoinFolder ? (
                                            <button
                                                type="button"
                                                onClick={(e) =>
                                                    setRegisterForm(true)
                                                }
                                                className="text-primary underline whitespace-nowrap"
                                            >
                                                {t("creerUnCompte")}
                                            </button>
                                        ) : (
                                            <ContactFormButton
                                                isTextOnly={true}
                                                text={t("demanderUneDemo")}
                                            />
                                        )}
                                    </div>
                                </div>

                                {isFromFile && (
                                    <div className="mt-8 text-center">
                                        <AcceptConditions isTextOnly={true} />
                                    </div>
                                )}
                            </Fragment>
                        </Fragment>
                    )}
                </div>
                {!isFromFile && !isLoginPage && (
                    <img
                        src="/img/logo-black.svg"
                        width="50"
                        className="absolute bottom-2 right-4"
                        alt={t("applicationTitle")}
                    />
                )}
            </div>

            {loader && <Spinner />}
        </div>
    );
};

Login.propTypes = {
    login: PropTypes.func.isRequired,
    getCurrentProfile: PropTypes.func.isRequired,
};

export default connect(null, { login, getCurrentProfile })(Login);
