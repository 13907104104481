import { useTranslation } from "react-i18next";

import { Divider, Form } from "antd";

export function SeparatorAnswer({}) {
    return <Divider />;
}

export function SeparatorTemplate({ index }) {
    const { t } = useTranslation();

    return (
        <Form.Item name={["fields", index, "value"]}>
            <Divider />
        </Form.Item>
    );
}

export function SeparatorPrint({ field, index }) {
    return <></>;
}
