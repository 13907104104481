import { Fragment, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { takeScreenshot } from "helpers/viewer";
import Constants from "utils/constants";

import BCFForm from "components/bcf/BCFForm";
import Button from "components/input/Button";
import InformationModal from "components/modal/InformationModal";
import Login from "components/user/login/Login";

import { CommentsContext } from "./CommentsContent";

const NoComments = ({
    files,
    setShowCommentForm,
    profile,
    toggleBcfTiles,
    compiledFile,
    hasBIMCollabRole,
}) => {
    const { t } = useTranslation();

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const { isTilesView, isFromViewer, set2DPlanVisible } =
        useContext(CommentsContext);

    const [informationModal, setInformationModal] = useState(null);

    const onClick = async () => {
        if (profile || localStorage.getItem("guestName")) {
            if (isTilesView) toggleBcfTiles();
            if (isFromViewer) {
                const image = await takeScreenshot();

                setInformationModal({
                    title: t("ajouterUnCommentaire"),
                    content: (
                        <BCFForm
                            profile={profile && profile}
                            files={files}
                            compiledId={compiledFile && compiledFile._id}
                            set2DPlanVisible={set2DPlanVisible}
                            image={image}
                            onClose={() => setInformationModal()}
                        />
                    ),
                    closeReset: setInformationModal,
                    customWidth: "max-w-[750px] lg:max-w-[1050px]",
                });
            } else setShowCommentForm(true);
        } else {
            setInformationModal({
                content: (
                    <Login
                        isFromFile={true}
                        closeModal={() => setInformationModal()}
                    />
                ),
                closeReset: setInformationModal,
            });
        }
    };

    return (
        <div className="m-4 py-10 mx-auto">
            {hasBIMCollabRole && !mdMedia ? (
                <div className="max-w-[200px] mx-auto text-center">
                    <Fragment>
                        <div className="mb-4 text-sm">
                            {t("ajoutezDesCommentairesAVotreFichier")}
                        </div>

                        <div className="h-full">
                            <Button
                                text={t("ajouterUnCommentaire")}
                                onClick={onClick}
                            />
                        </div>
                    </Fragment>
                </div>
            ) : (
                <div className="mb-4 text-sm text-center">
                    {t("aucunCommentaireDisponible")}
                </div>
            )}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    customWidth={informationModal.customWidth}
                />
            )}
        </div>
    );
};

export default NoComments;
