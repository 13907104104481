const RenderMultiSelectField = ({
    title,
    _id,
    options,
    value = [],
    onChange,
}) => {
    const originValues = options || [];

    const checkedValues = value && Array.isArray(value) ? value : [];
    return (
        <div className="mt-4">
            <h3 className="font-bold text-sm">{title}</h3>

            <ul className="flex flex-col gap-4 my-4">
                {originValues.map((option) => (
                    <li
                        key={option}
                        className="flex items-center gap-2 cursor-pointer"
                    >
                        <input
                            type="checkbox"
                            id={option}
                            name={_id}
                            value={option}
                            className="w-4 h-4 cursor-pointer"
                            checked={checkedValues?.includes(option)}
                            onChange={() => {
                                if (checkedValues?.includes(option))
                                    onChange(
                                        checkedValues?.filter(
                                            (e) => e !== option,
                                        ),
                                    );
                                else onChange([...checkedValues, option]);
                            }}
                        />
                        <label htmlFor={option} className="cursor-pointer">
                            {option}
                        </label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RenderMultiSelectField;
