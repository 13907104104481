import axios from "axios";

import { uploadToS3 } from "helpers/awsS3";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import { getFileExtension } from "./file";
import { generateRandomToken } from "./token";

// CONSTANTS

export const formModalKey = "answerForm";

export const formMaxWidth = 800;

export const formStatus = {
    DRAFT: {
        label: "En cours",
        color: "orange",
        variant: "warning",
        icon: "edit",
        planIcon: "sms",
    },
    PENDING: {
        label: "En validation",
        color: "blue",
        variant: "processing",
        icon: "hourglass_top",
    },
    APPROVED: {
        label: "Accepté",
        color: "green",
        variant: "success",
        icon: "check",
    },
    REJECTED: {
        label: "Rejeté",
        color: "red",
        variant: "error",
        icon: "close",
    },
};

export const fieldsTypes = [
    {
        code: "TITLE",
        name: "Titre",
        icon: "title",
        group: "plain",
    },
    {
        code: "SUBTITLE",
        name: "Sous-titre",
        icon: "titlecase",
        group: "plain",
    },
    {
        code: "PARAGRAPH",
        name: "Paragraphe",
        icon: "format_align_left",
        group: "plain",
    },
    {
        code: "SEPARATOR",
        name: "Séparateur",
        icon: "remove",
        group: "plain",
    },
    {
        code: "TEXT_SHORT",
        name: "Texte court",
        icon: "short_text",
        group: "input",
    },
    {
        code: "TEXT_LONG",
        name: "Texte long",
        icon: "short_text",
        group: "input",
    },
    {
        code: "NUMBER",
        name: "Nombre",
        icon: "123",
        group: "input",
    },
    {
        code: "DATE",
        name: "Date",
        icon: "calendar_month",
        group: "input",
    },
    {
        code: "TIME",
        name: "Horaire",
        icon: "schedule",
        group: "input",
    },
    {
        code: "BOOLEAN",
        name: "Oui / Non",
        icon: "toggle_on",
        group: "input",
    },
    {
        code: "SELECT_CHECKBOX",
        name: "Cases à cocher",
        icon: "check_box",
        group: "input",
    },
    {
        code: "SELECT_RADIO",
        name: "Liste boutons",
        icon: "radio_button_checked",
        group: "input",
    },
    {
        code: "SELECT_LIST",
        name: "Liste déroulante",
        icon: "segment",
        group: "input",
    },
    {
        code: "ZONE",
        name: "Zone",
        icon: "activity_zone",
        group: "input",
    },
    {
        code: "PICTURE",
        name: "Photo / Image",
        icon: "photo_camera",
        group: "input",
    },
    {
        code: "SIGNATURE",
        name: "Signature",
        icon: "signature",
        group: "input",
    },
    {
        code: "REFERENCE",
        name: "Ref. Formulaire",
        icon: "assignment_return",
        group: "custom",
    },
    {
        code: "STATE",
        name: "État",
        icon: "traffic",
        group: "custom",
    },
    {
        code: "WEATHER",
        name: "Météo",
        icon: "partly_cloudy_day",
        group: "custom",
    },
];

// FUNCTIONS

export const convertTemplateToAnswerDefault = (template) => {
    return {
        ...template,
        fields: template.fields.map((field) => {
            if (field?.type === "PICTURE") {
                return {
                    ...field,
                    value: [],
                };
            }
            if (field?.type === "SELECT_LIST" && field?.defaultValue) {
                return {
                    ...field,
                    value: [],
                };
            }
            return {
                ...field,
                value: field.defaultValue,
            };
        }),
    };
};

export const convertDataToForm = async (data) => {
    let result = data;

    for (const field of result.fields) {
        switch (field.type) {
            // case "SIGNATURE":
            //     if (Array.isArray(field?.value)) {
            //         const pictures = await Promise.all(
            //             field.value.map((e) =>
            //                 axios.post(apiURL.getFilePresignedWithoutId, {
            //                     fileName: e,
            //                 }),
            //             ),
            //         );
            //         field.value = pictures.map((e, index) => ({
            //             uid: index,
            //             name: field.value[index],
            //             status: "done",
            //             url: e.data,
            //         }));
            //     } else {
            //         field.value = [];
            //     }
            //     break;
            default:
                break;
        }
    }

    return result;
};

export const convertDataToPrint = async (data, usedId = []) => {
    let result = data;
    for (const field of result.fields) {
        if (field === undefined) {
            continue;
        }
        if (field.type === "REFERENCE" && !usedId.includes(result?._id)) {
            if (field.value) {
                const answer = await getAnswerForm({ id: field.value });
                if (answer)
                    field.value = await convertDataToPrint(answer, [
                        ...usedId,
                        result?._id,
                    ]);
            }
        } else if (field.type === "PICTURE") {
            let resultPictures = [];
            for (const fileName of field.value) {
                const image = fileName?.uid ? fileName.name : fileName;
                const { data } = await axios.post(
                    apiURL.getFilePresignedWithoutId,
                    {
                        fileName: image,
                    },
                );
                const response = await axios.get(data, {
                    responseType: "arraybuffer",
                });
                const base64 = Buffer.from(response.data, "binary").toString(
                    "base64",
                );
                const dataUrl = `data:image/${getFileExtension(image)};base64,${base64}`;
                resultPictures.push(dataUrl);
            }
            field.value = resultPictures;
        } else if (field.type === "ZONE" || field.type === "SIGNATURE") {
            const image = field.value?.uid ? field.value.name : field.value;
            if (image) {
                const { data } = await axios.post(
                    apiURL.getFilePresignedWithoutId,
                    {
                        fileName: image,
                    },
                );
                const response = await axios.get(data, {
                    responseType: "arraybuffer",
                });
                const base64 = Buffer.from(response.data, "binary").toString(
                    "base64",
                );
                const dataUrl = `data:image/${getFileExtension(image)};base64,${base64}`;
                field.value = dataUrl;
            }
        }
    }

    return result;
};

// API CALLS

export async function newTemplateForm({ title }) {
    const { data } = await axios.post(apiURL.formTemplates, {
        title,
    });
    return data;
}

export async function getTemplateForm({ id }) {
    const { data } = await axios.get(apiURL.formTemplates + id);
    return data;
}

export async function saveTemplateForm({ values, id }) {
    // const id = values._id;
    const dataToSend = {
        ...values,
        fields: values.fields?.map((field) =>
            field._id?.length === 10 ? { ...field, _id: undefined } : field,
        ),
    };
    const { data } = await axios.patch(apiURL.formTemplates + id, dataToSend);
    return data;
}

export async function deleteTemplateForm({ id }) {
    const { data } = await axios.delete(apiURL.formTemplates + id);
    return data;
}

export async function duplicateTemplateForm({ id }) {
    const { data } = await axios.post(apiURL.duplicateFormTemplate, { id });
    return data;
}

export async function getAnswerForm({ id }) {
    const { data } = await axios.get(apiURL.formAnswers + id);
    return data;
}

export async function newAnswerForm({ id }) {
    const { data } = await axios.post(apiURL.formAnswers, {
        id,
    });
    return data;
}

export async function deleteAnswerForm({ id }) {
    const { data } = await axios.delete(apiURL.formAnswers + id);
    return data;
}

export async function sendAnswerForm({ id, send }) {
    const { data } = await axios.post(apiURL.sendFormAnswers + id, { send });
    return data;
}

export async function validateAnswerForm({ id, isAccepted }) {
    const { data } = await axios.post(apiURL.validateFormAnswers + id, {
        isAccepted,
    });
    return data;
}

export async function saveAnswerForm({ fields, id, ...rest }) {
    for (const field of fields) {
        if (
            (field?.type === "SIGNATURE" || field?.type === "ZONE") &&
            field?.value &&
            field?.value.name
        ) {
            const resUploadFile = await uploadToS3(
                constants.awsS3BucketFiles[process.env.REACT_APP_NODE_ENV],
                field.value.name,
                field.value,
            );
            field.value = resUploadFile.Key;
        }
        if (field?.type === "PICTURE" && Array.isArray(field?.value)) {
            const result = await Promise.all(
                field.value.map((e) =>
                    e instanceof File
                        ? uploadToS3(
                              constants.awsS3BucketFiles[
                                  process.env.REACT_APP_NODE_ENV
                              ],
                              Date.now() +
                                  generateRandomToken(10) +
                                  "." +
                                  getFileExtension(e.name),
                              e,
                          )
                        : { Key: e.name },
                ),
            );
            field.value = result.map((e) => e.Key);
        }
    }

    const { data } = await axios.patch(apiURL.formAnswers + id, {
        fields,
        ...rest,
    });
    return data;
}
