import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { getAppImage } from "helpers/image";

export const pdfStyles = {
    tableFix: {
        position: "relative",
        top: 1,
        left: 1,
        marginLeft: -1,
        marginTop: -1,
    },

    tableCell: {
        border: "1px solid #EEE",
        paddingHorizontal: 4,
        paddingVertical: 4,
        fontSize: 4,
    },
    flexLine: {
        display: "flex",
        flexDirection: "row",
    },
};

Font.register({
    family: "Montserrat",
    fonts: [
        {
            src: "https://fonts.gstatic.com/s/montserrat/v6/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf",
            fontWeight: 400,
        },
        {
            src: "https://fonts.gstatic.com/s/montserrat/v6/IQHow_FEYlDC4Gzy_m8fcvEr6Hm6RMS0v1dtXsGir4g.ttf",
            fontWeight: 700,
        },
    ],
});

const TemplatePDF = ({
    title,
    subtitle,
    columns,
    customerConfig, // TOWAIT Global Context / CustomerConfig dans la Modal ( Export TemplatePDF )
    pdfContent,
    orientation,
}) => {
    const { t } = useTranslation();
    return (
        <Document title={title}>
            <Page
                wrap
                size="A4"
                style={{
                    fontSize: 10,
                    flexDirection: "column",
                    fontFamily: "Montserrat",
                    paddingBottom: 40,
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
                orientation={orientation}
            >
                {/* ------------------------------------------------------------- HEADER  */}
                <View fixed>
                    <View
                        style={{
                            ...pdfStyles.flexLine,
                            justifyContent: "space-between",
                            paddingBottom: 10,
                        }}
                    >
                        <Image
                            src={{
                                uri: customerConfig?.logoEmail
                                    ? getAppImage(
                                          "customers/" +
                                              customerConfig.logoEmail,
                                      )
                                    : "/img/logo-email.png",
                                method: "GET",
                                headers: {
                                    "Cache-Control": "no-cache",
                                },
                                body: "",
                            }}
                            style={{
                                width: 100,
                                objectFit: "contain",
                                objectPosition: "left",
                            }}
                        />
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column-reverse",
                                alignItems: "flex-end",
                                fontSize: 8,
                            }}
                        >
                            <Text>
                                {`${t("dateDeLexport")} : ${moment().format(
                                    "DD-MM-YYYY",
                                )}`}
                            </Text>
                            <Text>{subtitle}</Text>
                        </View>
                    </View>

                    <View style={pdfStyles.flexLine}>
                        {columns.map((col, i) => (
                            <View
                                key={i}
                                style={{
                                    ...pdfStyles.tableCell,
                                    ...pdfStyles.tableFix,
                                    width: col.width || null,
                                    flex: col.width ? null : 1,
                                    backgroundColor: col.color || "#F2F5FD",
                                }}
                            >
                                <Text>{col.title}</Text>
                            </View>
                        ))}
                    </View>
                </View>

                {/* ------------------------------------------------------------- CONTENT TABLE */}
                {pdfContent}

                {/* ------------------------------------------------------------- FOOTER */}
                <View
                    fixed
                    style={{
                        position: "absolute",
                        bottom: 20,
                        right: 20,
                        fontSize: 8,
                    }}
                >
                    <Text
                        render={({ pageNumber, totalPages }) =>
                            `Page ${pageNumber} / ${totalPages}`
                        }
                    />
                </View>
            </Page>
        </Document>
    );
};

export default TemplatePDF;
