import * as OBC from "@thatopen/components";
import * as OBCF from "@thatopen/components-front";
import axios from "axios";
import {
    Box3,
    Color,
    DoubleSide,
    LineBasicMaterial,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Sphere,
    Vector2,
    Vector3,
} from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";

import apiURL from "utils/apiURL";

import { scrollToComment } from "./comment";
import { loadPointcloudFile } from "./pointcloud";

// Initialisation de la scène IFC avec chargement des éléments nécessaires
export function initBimComponents(isEmbed) {
    const container = document.getElementById("viewer-container");

    const components = new OBC.Components();
    const worlds = components.get(OBC.Worlds);

    const world = worlds.create(
        OBC.SimpleScene,
        OBC.OrthoPerspectiveCamera,
        OBCF.PostproductionRenderer,
    );

    world.scene = new OBC.SimpleScene(components);
    world.renderer = new OBCF.PostproductionRenderer(components, container);

    initCamera(world);

    world.renderer.postproduction.enabled = true;
    world.renderer.postproduction.customEffects.tolerance = 7;
    world.renderer.postproduction.customEffects.glossEnabled = false;

    const casters = components.get(OBC.Raycasters);
    casters.get(world);

    components.init();

    const scene = world.scene;

    // Initialize measurements and clipping if not in embed mode
    initMeasurements(world);

    if (!isEmbed) {
        const clipper = world.components.get(OBC.Clipper);
        clipper.enabled = true;

        const faceMeasurement = world.components.get(OBCF.FaceMeasurement);
        faceMeasurement.selectionMaterial.color.set("#3CE1B9");
    }

    // Setup the lighting and scene
    world.scene.setup();
    scene.three.background = new Color("rgb(203, 213, 225)");

    const fragments = world.components.get(OBC.FragmentsManager);
    const fragmentStreamerLoader = world.components.get(OBCF.IfcStreamer);
    const indexer = components.get(OBC.IfcRelationsIndexer);
    const classifier = components.get(OBC.Classifier);

    fragmentStreamerLoader.world = world;
    fragmentStreamerLoader.useCache = false;
    fragmentStreamerLoader.clearCache();
    fragmentStreamerLoader.culler.threshold = 0;
    fragmentStreamerLoader.culler.maxHiddenTime = 5000;
    fragmentStreamerLoader.culler.maxLostTime = 3000;

    world.camera.controls.addEventListener("sleep", () => {
        fragmentStreamerLoader.cancel = true;
        fragmentStreamerLoader.culler.needsUpdate = true;
    });

    fragments.onFragmentsLoaded.add(async (model) => {
        if (model.hasProperties) {
            await indexer.process(model);
            classifier.byEntity(model);
        }
    });

    fragments.onFragmentsDisposed.add(({ fragmentIDs }) => {
        for (const fragmentID of fragmentIDs) {
            const mesh = [...world.meshes].find(
                (mesh) => mesh.uuid === fragmentID,
            );
            if (mesh) {
                world.meshes.delete(mesh);
                mesh.geometry.dispose();
                mesh.material.dispose();
            }
        }
    });

    return world;
}

function initCamera(world) {
    world.camera = new OBC.OrthoPerspectiveCamera(world.components);
    const camera = world.camera;

    camera.three.far = 5000;
    camera.updateAspect();

    camera.controls.draggingSmoothTime = 0;
    camera.controls.dollySpeed = 0.4;
    camera.controls.maxDistance = 5000;

    camera.controls.addEventListener("change", () => {
        const distanceToTarget = camera.position.distanceTo(
            camera.controls.target,
        );
        if (distanceToTarget < 0.5) {
            camera.position.copy(
                camera.controls.target.clone().add(new Vector3(0, 1, 1)),
            ); // Move back a bit
            camera.controls.update();
        }
    });

    // Si on zoom, on désactive le infinityDolly pour pouvoir passer à travers les objets
    document.addEventListener("wheel", function (event) {
        if (!camera?.controls) return;
        if (event.deltaY > 0) {
            camera.controls.infinityDolly = false;
        } else {
            camera.controls.infinityDolly = true;
        }
    });

    var initialDistance = null;

    document.addEventListener("touchstart", function (event) {
        if (event.touches.length === 2) {
            initialDistance = Math.hypot(
                event.touches[0].clientX - event.touches[1].clientX,
                event.touches[0].clientY - event.touches[1].clientY,
            );
        }
    });

    document.addEventListener("touchmove", function (event) {
        if (event.touches.length === 2 && initialDistance !== null) {
            var currentDistance = Math.hypot(
                event.touches[0].clientX - event.touches[1].clientX,
                event.touches[0].clientY - event.touches[1].clientY,
            );

            if (currentDistance > initialDistance) {
                world.components.camera.controls.infinityDolly = true;
            } else if (currentDistance < initialDistance) {
                world.components.camera.controls.infinityDolly = false;
            }
        }
    });

    document.addEventListener("touchend", function (event) {
        initialDistance = null;
    });
}

// Initialisation des outils de mesure
function initMeasurements(world) {
    const lengthMeasurement = world.components.get(OBCF.LengthMeasurement);
    lengthMeasurement.world = world;

    const faceMeasurement = world.components.get(OBCF.FaceMeasurement);
    faceMeasurement.world = world;

    const areaMeasurement = world.components.get(OBCF.AreaMeasurement);
    areaMeasurement.world = world;

    const edgeMeasurement = world.components.get(OBCF.EdgeMeasurement);
    edgeMeasurement.world = world;
}

// Initialisation du surligneur d'éléments
export async function initHighlighter(world) {
    const highlighter = world.components.get(OBCF.Highlighter);
    if (highlighter.selection.select) return;

    highlighter.config.world = world;
    highlighter.setup();

    highlighter.config.hoverColor.set("#35b596");
    highlighter.config.selectionColor.set("#3CE1B9");

    return highlighter;
}

// Initialisation du chargement des éléments visible d'un modèle uniquement
export function updateCuller(components, model) {
    const culler = components.get(OBC.Cullers);

    const meshes = [];

    for (const fragment of model.items) {
        meshes.push(fragment.mesh);
        culler.add(fragment.mesh);
    }

    culler.needsUpdate = true;
}

export function initMiniMap(world, zoomLevel) {
    const maps = world.components.get(OBC.MiniMaps);
    let map = Array.from(maps.list.values()).pop();
    if (!map) map = maps.create(world);

    const mapContainer = document.getElementById("minimap");
    const canvas = map.renderer.domElement;

    map.renderer.setSize(360, 192);
    mapContainer.append(canvas);

    map.resize(new Vector2(360, 192));

    map.enabled = true;
    map.zoom = zoomLevel;
    map.backgroundColor = new Color("rgb(241, 245, 249)");
}

export async function toggle2DPlan(world, isVisible, models, levelId = 0) {
    const highlighter = world.components.get(OBCF.Highlighter);
    const plans = world.components.get(OBCF.Plans);

    const classifier = world.components.get(OBC.Classifier);

    models.forEach((model) => {
        classifier.byModel(model.uuid, model);
        classifier.byEntity(model);
    });

    const modelItems = classifier.find({
        models: [models.map((model) => model.uuid)],
    });
    const edges = world.components.get(OBCF.ClipEdges);

    if (isVisible) {
        world.renderer.postproduction.enabled = true;
        world.renderer.postproduction.customEffects.outlineEnabled = true;

        plans.world = world;
        await Promise.all(
            models.map(async (model) => {
                await plans.generate(model);
            }),
        );

        const fragments = world.components.get(OBC.FragmentsManager);

        const thickItems = classifier.find({
            entities: ["IFCWALLSTANDARDCASE", "IFCWALL"],
        });

        const thinItems = classifier.find({
            entities: ["IFCDOOR", "IFCWINDOW", "IFCPLATE", "IFCMEMBER"],
        });

        const grayFill = new MeshBasicMaterial({ color: "gray", side: 2 });
        const blackLine = new LineBasicMaterial({ color: "black" });
        const blackOutline = new MeshBasicMaterial({
            color: "black",
            opacity: 0.5,
            side: 2,
            transparent: true,
        });

        edges.styles.create(
            "thick",
            new Set(),
            world,
            blackLine,
            grayFill,
            blackOutline,
        );

        for (const fragID in thickItems) {
            const foundFrag = fragments.list.get(fragID);
            if (!foundFrag) continue;
            const { mesh } = foundFrag;
            edges.styles.list.thick.fragments[fragID] = new Set(
                thickItems[fragID],
            );
            edges.styles.list.thick.meshes.add(mesh);
        }

        edges.styles.create("thin", new Set(), world);

        for (const fragID in thinItems) {
            const foundFrag = fragments.list.get(fragID);
            if (!foundFrag) continue;
            const { mesh } = foundFrag;
            edges.styles.list.thin.fragments[fragID] = new Set(
                thinItems[fragID],
            );
            edges.styles.list.thin.meshes.add(mesh);
        }

        await edges.update(true);

        const whiteColor = new Color("white");

        world.renderer.postproduction.customEffects.minGloss = 0.1;
        highlighter.backupColor = whiteColor;
        classifier.setColor(modelItems, whiteColor);
        world.scene.three.background = whiteColor;
        plans.goTo(plans.list[levelId].id);
    } else {
        world.renderer.postproduction.customEffects.outlineEnabled = false;

        const minGloss = world.renderer.postproduction.customEffects.minGloss;

        highlighter.backupColor = null;
        world.renderer.postproduction.customEffects.minGloss = minGloss;
        classifier.resetColor(modelItems);
        world.scene.three.background = new Color("rgb(203, 213, 225)");
        plans.exitPlanView();
    }
}

export async function apply2DPlan(world, model, levelIndex) {
    const plans = world.components.get(OBCF.Plans);

    const classifier = world.components.get(OBC.Classifier);

    classifier.byModel(model.uuid, model);
    classifier.byEntity(model);

    plans.world = world;
    await plans.generate(model);

    plans.goTo(plans.list[levelIndex].id);
}

export async function initGetPropertiesLightViewerOnClick(
    components,
    model,
    setSelectedItem,
) {
    // On récupère le premier node, et on se base dessus pour afficher les infos au clique
    const classifier = components.get(OBC.Classifier);

    if (!classifier.list.models) return;

    const node = classifier.list.models[model.uuid].map;

    const fragmentID = Object.keys(node)[0];
    const expressID = [...node[fragmentID]][0];
    const infos = await getModelProperties(components, model, expressID);
    setSelectedItem(infos ? infos : null);
}

// Initialisation de l'action au clic d'un élément du modèle, qui permet de récupérer ses propriétés
export async function initGetPropertiesOnClick(
    components,
    fragments,
    setSelectedItem,
) {
    const highlighter = components.get(OBCF.Highlighter);

    const highlighterEvents = highlighter.events;

    try {
        highlighterEvents.select.onHighlight.add(async (selection) => {
            const fragmentID = Object.keys(selection)[0];
            const expressID = [...selection[fragmentID]][0];

            let model;
            for (const group of fragments.groups) {
                for (const [_key, value] of group[1].keyFragments) {
                    if (value === fragmentID) {
                        model = group[1];
                        break;
                    }
                }
            }

            if (model) {
                const infos = await getModelProperties(
                    components,
                    model,
                    expressID,
                );
                setSelectedItem(infos ? infos : null);
            }
        });
    } catch (e) {}
}

// Récupération des infos nécessaires pour afficher l'élément sélectionné
export async function getModelProperties(components, model, expressID) {
    const properties = await model.getProperties(expressID);

    if (properties && (properties.ObjectType || properties.ObjectPlacement)) {
        let res = {
            fileId: model.compiledFileID,
            title: properties.Name.value,
            description: properties.Description
                ? properties.Description.value
                : null,
            properties: [],
        };

        const indexer = components.get(OBC.IfcRelationsIndexer);

        const psets = indexer.getEntityRelations(
            model,
            expressID,
            "IsDefinedBy",
        );

        if (psets) {
            for (const expressID of psets) {
                const propPromises = [];

                await OBC.IfcPropertiesUtils.getPsetProps(
                    model,
                    expressID,
                    (propExpressID) => {
                        // Collect each promise and push it to propPromises
                        const propPromise = model
                            .getProperties(propExpressID)
                            .then((prop) => {
                                res.properties.push({
                                    isProperty: true,
                                    datas: prop,
                                });
                            });
                        propPromises.push(propPromise);
                    },
                );

                await Promise.all(propPromises);
            }
        }
        return res;
    }

    return null;
}

// Chargement des modèles via la méthode Fragments
async function loadModelByFragments(world, fileBuffer) {
    const loader = world.components.get(OBC.IfcLoader);

    //await loader.setup();

    loader.settings.wasm = {
        path: "https://unpkg.com/web-ifc@0.0.66/",
        absolute: true,
    };

    loader.settings.webIfc.COORDINATE_TO_ORIGIN = true;
    loader.settings.webIfc.OPTIMIZE_PROFILES = true;

    const model = await loader.load(fileBuffer);

    const indexer = world.components.get(OBC.IfcRelationsIndexer);
    await indexer.process(model);

    for (const child of model.children) {
        if (child instanceof Mesh) {
            world.meshes.add(child);
        }
    }

    model.traverse((mesh) => {
        if (mesh.isMesh) {
            if (mesh.material) {
                mesh.material.forEach((material) => {
                    material.side = DoubleSide;
                });
            }
        }
    });

    world.scene.three.add(model);

    return model;
}

// Chargement des modèles via la méthode Tiles

async function loadModelByTiles(world, fileId, setIsStreaming, updateProgress) {
    // Check if the file has already been processed
    const isProcessed = await axios.get(apiURL.checkIsProcessedFile + fileId);
    if (!isProcessed?.data) {
        setIsStreaming(true);

        const generateTilesRes = await axios.get(
            apiURL.generateFilesTiles + fileId,
        );

        setIsStreaming(false);

        if (!generateTilesRes?.data) return;
    }

    // Chargement du modèle une fois le fichier processisé
    return new Promise(async (resolve) => {
        const fragmentStreamerLoader = world.components.get(OBCF.IfcStreamer);
        const name = fileId;

        const addToScene = async (fileName) => {
            fragmentStreamerLoader.url = apiURL.getProcessedFiles;

            const geometryURL =
                apiURL.getProcessedFiles + `${fileName}.ifc-processed.json`;
            const propertiesURL =
                apiURL.getProcessedFiles +
                `${fileName}.ifc-processed-properties.json`;

            const model = await loadModel(
                fragmentStreamerLoader,
                geometryURL,
                propertiesURL,
            );

            resolve(model);
        };

        await addToScene(name);

        async function loadModel(loader, geometryURL, propertiesURL) {
            try {
                const rawGeometryData = await fetch(geometryURL);
                let geometryData = await rawGeometryData.json();

                let propertiesData;

                if (propertiesURL) {
                    const rawPropertiesData = await fetch(propertiesURL);
                    propertiesData = await rawPropertiesData.json();
                }

                const model = await loader.load(
                    geometryData,
                    true,
                    propertiesData,
                );
                return model;
            } catch (e) {}
        }
    });
}

export async function loadIfcFile(
    file,
    world,
    modelsBuffers,
    setModelsBuffers,
    fileId,
    useTiles = true,
    setIsStreaming,
    updateProgress,
) {
    let model;

    try {
        world.camera.controls.minDistance = 0;

        if (modelsBuffers[file]) {
            if (useTiles) {
                try {
                    model = await loadModelByTiles(
                        world,
                        fileId,
                        setIsStreaming,
                        updateProgress,
                    );
                } catch (e) {
                    return null;
                }
            } else {
                model = await loadModelByFragments(world, modelsBuffers[file]);
            }
        } else {
            let resFileContentBuffer;

            // Fetch du contenu de l'IFC, que l'on va formater en amont du chargement pour s'assurer d'avoir les bonnes couleurs
            // Si on utilise BIM Tiles, et que le fichier a déjà été streamé, cette étape est inutile

            let isProcessed = false;

            if (useTiles) {
                isProcessed = await axios.get(
                    apiURL.checkIsProcessedFile + fileId,
                );
            }

            if (!isProcessed?.data) {
                const resFile = await axios.get(file, {
                    responseType: "arraybuffer",
                });

                resFileContentBuffer = resFile.data;
            }

            const buffer =
                resFileContentBuffer && new Uint8Array(resFileContentBuffer);

            if (buffer) {
                setModelsBuffers((oldBuffers) => {
                    const updatedBuffers = { ...oldBuffers, [file]: buffer };
                    return updatedBuffers;
                });
            }

            if (useTiles) {
                try {
                    model = await loadModelByTiles(
                        world,
                        fileId,
                        setIsStreaming,
                        updateProgress,
                    );
                } catch (e) {
                    return null;
                }
            } else model = await loadModelByFragments(world, buffer);
        }
    } catch (err) {}

    resizeViewer();

    return model;
}

// Génération de l'arbre d'arborescence du modèle
export async function generateModelTree(components, model, modelTitle, fileId) {
    const treeStructure = {
        title: modelTitle || "",
        model: modelTitle && model,
        classifierQuery: {
            models: [model.uuid],
        },
        datas: {},
    };

    if (model.isPoints) {
        treeStructure.isPointCloud = true;
        return treeStructure;
    }

    const classifier = components.get(OBC.Classifier);

    classifier.byModel(model.uuid, model);
    classifier.byEntity(model);

    let propertiesMap = new Map();

    if (fileId) {
        propertiesMap = await loadTilesPropertiesData(fileId);
        if (propertiesMap.size === 0) return treeStructure;
    }

    const groupsKeys = Object.keys(classifier.list.entities);

    await Promise.all(
        groupsKeys.map(async (groupName) => {
            treeStructure.datas[groupName] = {
                title: groupName,
                classifierQuery: {
                    entities: [groupName],
                    models: [model.uuid],
                },
                datas: [],
            };

            const groupProps = classifier.list.entities[groupName]?.map;
            if (!groupProps) return;

            const propertyPromises = [];

            for (const [keyProps, entitySet] of Object.entries(groupProps)) {
                for (const propId of entitySet) {
                    propertyPromises.push(
                        fetchEntityPropertiesWithExclusion(
                            propId,
                            model,
                            propertiesMap,
                            classifier,
                            groupName,
                            keyProps,
                            treeStructure,
                        ),
                    );
                }
            }

            await Promise.all(propertyPromises);
        }),
    );

    propertiesMap.clear();

    return treeStructure;
}

async function fetchEntityPropertiesWithExclusion(
    propId,
    model,
    propertiesMap,
    classifier,
    groupName,
    keyProps,
    treeStructure,
) {
    try {
        let properties =
            propertiesMap.get(propId) || (await model.getProperties(propId));

        if (!properties) return;

        if (
            treeStructure.datas[groupName].datas.some(
                (item) => item.title === properties.Name?.value,
            )
        )
            return;

        if (!classifier.list.models[model.uuid]?.map[keyProps]) return;

        const excluded = {};
        const currentCateg = classifier.list.entities[groupName];

        for (const [key, value] of Object.entries(currentCateg.map)) {
            if (!value.has(properties.expressID)) {
                excluded[key] = new Set(value);
            }
        }

        treeStructure.datas[groupName].datas.push({
            title: properties.Name.value,
            classifierQuery: {
                entities: [groupName],
                models: [model.uuid],
            },
            excludedElements: excluded,
            datas: null,
        });
    } catch (err) {}
}

async function loadTilesPropertiesData(fileId) {
    const propertiesMap = new Map();
    let index = 1;

    while (true) {
        try {
            const response = await axios.get(
                `${apiURL.getProcessedFiles}${fileId}.ifc-processed-properties-${index}`,
            );
            if (!response?.data) break;

            Object.entries(response.data).forEach(([key, value]) => {
                propertiesMap.set(value.expressID, value);
            });

            index++;
        } catch (error) {
            break;
        }
    }

    return propertiesMap;
}

// Récupération de la liste des groupes d'éléments de l'IFC s'ils sont présents dans la scène
export async function getModelCategories(components, model) {
    const classifier = await components.components.get(OBC.Classifier);

    classifier.byEntity(model);

    const classifications = classifier.list;

    const classes = {};
    const classNames = Object.keys(classifications.entities);
    for (const name of classNames) {
        classes[name] = true;
    }

    let existingClasses = {};

    for (const name in classes) {
        const found = await classifier.find({
            entities: [name],
        });

        if (found) {
            existingClasses[name] = true;
        }
    }

    return existingClasses;
}

export async function takeScreenshot(removePrefix = false) {
    await new Promise((resolve) => {
        requestAnimationFrame(resolve);
    });
    const container = document.getElementById("viewer-container");
    const canvas = container.querySelector("canvas");
    if (canvas) {
        let dataURL = canvas.toDataURL("image/jpeg");

        // On retire le prefix Base64 si nécessaire
        if (removePrefix)
            dataURL = dataURL.replace(/^data:image\/\w+;base64,/, "");

        return dataURL;
    }
}

export function getBCFImage(bcfContent) {
    if (!bcfContent) return null;

    return bcfContent.image;
}

// Génréation du contenu du BCF pour le formulaire de création / modif
export async function generateBCFContent(world) {
    // Récupération du positionnement de la caméra
    const cameraControls = world.camera.controls;

    const cameraPosition = [];
    const positionDatas = cameraControls.getPosition();
    cameraPosition[0] = positionDatas.x;
    cameraPosition[1] = positionDatas.y;
    cameraPosition[2] = positionDatas.z;

    const cameraRotation = [];
    const directionDatas = cameraControls.getTarget();
    cameraRotation[0] = directionDatas.x;
    cameraRotation[1] = directionDatas.y;
    cameraRotation[2] = directionDatas.z;

    const up = [];
    up[0] = cameraControls.camera.up.x;
    up[1] = cameraControls.camera.up.y;
    up[2] = cameraControls.camera.up.z;

    const cameraDatas = {
        position: cameraPosition,
        direction: cameraRotation,
        upVector: up,
        fov: cameraControls.camera.fov,
    };

    return {
        camera: cameraDatas,
    };
}

export function exportBCF(viewer, formData, file) {
    /*
    // On prend un screenshot de la visionneuse qui sera l'image d'aperçu du BCF
    const bcfScreenshot = takeScreenshot(viewer);
    // Récupération du positionnement de la caméra
    const viewerCamera = viewer.context.getCamera();
    const cameraPosition = viewerCamera.position.toArray();
    const cameraRotation = viewerCamera.rotation.toArray();

    const cameraDatas = {
        camera_view_point: cameraPosition,
        camera_direction: cameraRotation,
        camera_up_vector: viewerCamera.up.toArray(),
        field_of_view: viewerCamera.fov,
    };

    const bcfData = {
        project: {
            name: file.title,
        },
        version: "2.1",
        topics: [
            {
                guid: "topic-guid-1",
                title: formData.title,
                creation_author: "Author 1",
                creation_date: "2023-05-15T12:00:00Z",
                modified_author: "Author 1",
                modified_date: "2023-05-15T14:30:00Z",
                status: "open",
                priority: "high",
                reference_link: "http://www.example.com/reference",
                assigned_to: "User 1",
                description: formData.content,
                bitmaps: [
                    {
                        bitmap_type: "snapshot",
                        bitmap_data: bcfScreenshot,
                    },
                ],
                comments: [
                    {
                        guid: "comment-guid-1",
                        author: "User 2",
                        date: "2023-05-15T13:30:00Z",
                        comment: "Comment 1",
                    },
                    {
                        guid: "comment-guid-2",
                        author: "User 3",
                        date: "2023-05-15T14:00:00Z",
                        comment: "Comment 2",
                    },
                ],
            },
        ],
        viewpoints: [
            {
                guid: "viewpoint-guid-1",
                perspective_camera: cameraDatas,
                lines: [],
                clipping_planes: [],
                bitmaps: [
                    {
                        bitmap_type: "snapshot",
                        bitmap_data: "",
                    },
                ],
                reference_link: "http://www.example.com/viewpoint",
            },
        ],
    };

    return bcfData;
    */
}

// Positionne la caméra de la scène sur les données renseignées dans le BCF
export function applyBCF(
    world,
    comment,
    bcfId,
    setActiveBCF,
    set2DPlanVisible,
) {
    if (!comment.BCFContent) return;

    // Reset des éléments de parametrage
    const clipper = world.components.get(OBC.Clipper);
    clipper.deleteAll();

    deleteAllMeasures(world.components);

    // On applique les différents outils si jamais des settings sont renseignés pour le BCF
    if (comment.settings) {
        let isCameraModeDefined = false;

        comment.settings.forEach((setting) => {
            if (setting.type === "PLANE") {
                let settingDatas = JSON.parse(setting.datas);
                const clipper = world.components.get(OBC.Clipper);

                clipper.createFromNormalAndCoplanarPoint(
                    world,
                    new Vector3(
                        settingDatas.normal.x,
                        settingDatas.normal.y,
                        settingDatas.normal.z,
                    ),
                    new Vector3(
                        settingDatas.origin.x,
                        settingDatas.origin.y,
                        settingDatas.origin.z,
                    ),
                );
            } else if (setting.type === "LENGTH_MEASURE") {
                let settingDatas = JSON.parse(setting.datas);
                const measurement = world.components.get(
                    OBCF.LengthMeasurement,
                );

                measurement.createOnPoints(
                    new Vector3(
                        settingDatas.p1.x,
                        settingDatas.p1.y,
                        settingDatas.p1.z,
                    ),
                    new Vector3(
                        settingDatas.p2.x,
                        settingDatas.p2.y,
                        settingDatas.p2.z,
                    ),
                );
            } else if (setting.type === "CAMERA_MODE") {
                world.camera.set(setting.datas);
                isCameraModeDefined = true;
            } else if (setting.type === "2D_PLAN") {
                localStorage.setItem("viewer2DPlanView", setting.datas);
                set2DPlanVisible(true);
            } /*else if (setting.type === "FACE_MEASURE") {
                let settingDatas = JSON.parse(setting.datas);
                const measurement = world.components.get(OBC.FaceMeasurement);
                measurement.set([settingDatas]);
            } else if (setting.type === "AREA_MEASURE") {
                let settingDatas = JSON.parse(setting.datas);
                const measurement = world.components.get(
                    OBC.AreaMeasureElement
                );
                measurement.createOnPoints(
                    new Vector3(
                        settingDatas.p1.x,
                        settingDatas.p1.y,
                        settingDatas.p1.z
                    ),
                    new Vector3(
                        settingDatas.p2.x,
                        settingDatas.p2.y,
                        settingDatas.p2.z
                    )
                );
            }*/
        });

        // On passe toutes les coupes en isSaved = true

        clipper.list.forEach((plane) => {
            plane.isSaved = true;
            plane.update();
        });

        if (!isCameraModeDefined) world.camera.set("Orbit");

        if (!comment.viewpointDatasAPI) {
            // Fonctionnement classique avec DB

            const camera = world.camera;
            const cameraPositionDatas = comment.BCFContent.camera.position;
            const cameraDirectionDatas = comment.BCFContent.camera.direction;

            camera.controls.setLookAt(
                cameraPositionDatas[0],
                cameraPositionDatas[1],
                cameraPositionDatas[2],
                cameraDirectionDatas[0],
                cameraDirectionDatas[1],
                cameraDirectionDatas[2],
                true,
            );
        } else {
            // API BIM Collab

            // Création du viewpoint
            const viewpoints = world.components.get(OBC.Viewpoints);

            const viewpoint = viewpoints.create(
                world,
                comment.viewpointDatasAPI,
            );

            // Gestion des coupes
            if (comment.viewpointDatasAPI.clipping_planes.length > 0) {
                const clipper = world.components.get(OBC.Clipper);

                const fragments = world.components.get(OBC.FragmentsManager);

                comment.viewpointDatasAPI.clipping_planes.forEach((plane) => {
                    const locationVector = new Vector3(
                        plane.location.x,
                        plane.location.z,
                        -plane.location.y,
                    );

                    fragments.applyBaseCoordinateSystem(
                        locationVector,
                        new Matrix4(),
                    );

                    clipper.createFromNormalAndCoplanarPoint(
                        world,
                        new Vector3(
                            -plane.direction.x,
                            -plane.direction.z,
                            plane.direction.y,
                        ),
                        new Vector3(
                            locationVector.x,
                            locationVector.y,
                            locationVector.z,
                        ),
                    );
                });

                clipper.list.forEach((plane) => {
                    plane.isSaved = true;
                    plane.update();
                });
            }

            // Gestion des mesures
            // On peut appliquer les mesures enregistrées avec le BCF UNIQUEMENT SI CELUI-CI AVAIT ETE CREE / MODIFIE DEPUIS BIMONO
            axios
                .get(apiURL.getCommentByBimcollabId + comment._id)
                .then((res) => {
                    if (res.data) {
                        const measurement = world.components.get(
                            OBCF.LengthMeasurement,
                        );

                        res.data.settings
                            .filter(
                                (setting) => setting.type === "LENGTH_MEASURE",
                            )
                            .forEach((setting) => {
                                let settingDatas = JSON.parse(setting.datas);

                                measurement.createOnPoints(
                                    new Vector3(
                                        settingDatas.p1.x,
                                        settingDatas.p1.y,
                                        settingDatas.p1.z,
                                    ),
                                    new Vector3(
                                        settingDatas.p2.x,
                                        settingDatas.p2.y,
                                        settingDatas.p2.z,
                                    ),
                                );
                            });
                    }
                })
                .catch();

            if (!comment.BCFContent.camera) return;

            viewpoint.camera = {
                aspectRatio: 0,
                fov: comment.BCFContent.fov,
                direction: {
                    x: comment.BCFContent.camera.direction[0],
                    y: comment.BCFContent.camera.direction[2],
                    z: -comment.BCFContent.camera.direction[1],
                },
                position: {
                    x: comment.BCFContent.camera.position[0],
                    y: comment.BCFContent.camera.position[2],
                    z: -comment.BCFContent.camera.position[1],
                },
            };

            viewpoint.go(world);
        }
    }

    setActiveBCF(bcfId);
}

// Ajout d'un marker dans la scène par rapport à la position du BCF associé
export function addBCFMarker(
    world,
    comment,
    addMarkerToList,
    setActiveBCF,
    set2DPlanVisible,
) {
    const bcfContent = comment.BCFContent;

    if (!bcfContent?.camera) return;

    const cameraPositionDatas = bcfContent.camera.position;

    const markerElement = document.createElement("div");
    markerElement.classList.add(
        "viewer-marker",
        "relative",
        "pointer-events-auto",
        "w-[50px]",
        "h-[50px]",
        "rounded-full",
        "flex",
        "hover:cursor-pointer",
        "hover:w-[90px]",
        "hover:h-[90px]",
        "group",
    );
    markerElement.id = "marker-" + comment._id;
    markerElement.style.backgroundColor =
        comment.status === "CLOSED" ? "#9ca3af" : comment.priority.darkColor;

    markerElement.innerHTML =
        "<div class='marker-arrow absolute w-[30px] h-[30px] left-[calc(50%-15px)] top-[23px] rotate-45'></div><div class='marker-content absolute bg-white w-[40px] h-[40px] rounded-full left-[5px] top-[5px] flex background-img group-hover:w-[80px] group-hover:h-[80px]' style='background-image: url(" +
        getBCFImage(bcfContent) +
        ");'><div class='m-auto'></div></div>";

    const markerArrowElement = markerElement.querySelector(".marker-arrow");
    if (markerArrowElement) {
        markerArrowElement.style.backgroundColor =
            comment.status === "CLOSED"
                ? "#9ca3af"
                : comment.priority.darkColor;
    }

    const markerObject = new CSS2DObject(markerElement);
    markerObject.commentId = comment._id;

    const scene = world.scene;

    scene.three.add(markerObject);

    markerObject.position.set(
        cameraPositionDatas[0],
        cameraPositionDatas[1],
        cameraPositionDatas[2],
    );

    markerObject.element.addEventListener("click", () => {
        applyBCF(world, comment, comment._id, setActiveBCF, set2DPlanVisible);
        scrollToComment(comment._id);
    });

    // Ajout du marker à la liste pour pouvoir le retirer de la scène par la suite
    addMarkerToList(markerObject);
}

// Suppression de l'ensemble des markers de la scène
export function removeAllMarkers(components, markersList, clearBCFMarkers) {
    const scene = components.scene;

    markersList.forEach((markerObject) => {
        scene.three.remove(markerObject);
    });
    clearBCFMarkers();
    document
        .querySelectorAll(".viewer-marker")
        .forEach((el) => el.parentNode.removeChild(el));
}

// Resize de la scène en gardant le ratio
export function resizeViewer() {
    const resizeEvent = new Event("resize");
    window.dispatchEvent(resizeEvent);
}

export function formatBCFNumber(bcfNumber) {
    let formatedNumber = bcfNumber.toString();

    while (formatedNumber.length < 5) formatedNumber = "0" + formatedNumber;

    return "#" + formatedNumber;
}

export function pickPlane(world) {
    const clipper = world.components.get(OBC.Clipper);

    const casters = world.components.get(OBC.Raycasters);
    const caster = casters.get(world);

    const meshes = getAllPlaneMeshes(world);

    const intersects = caster.castRay(meshes);
    if (intersects) {
        const found = intersects.object;
        return clipper.list.find((p) => p.meshes.includes(found));
    }
    return undefined;
}

export function getAllPlaneMeshes(world) {
    const clipper = world.components.get(OBC.Clipper);

    const meshes = [];
    for (const plane of clipper.list) {
        meshes.push(...plane.meshes);
    }
    return meshes;
}

export function refocusCamera(world, model) {
    const fragmentBbox = world.components.get(OBC.BoundingBoxer);
    fragmentBbox.add(model);
    const bbox = fragmentBbox.getMesh();
    world.camera.controls.fitToSphere(bbox, false);
}

export function saveSceneSettings(world) {
    let settings = [];

    // Coupes
    const clipper = world.components.get(OBC.Clipper);

    if (clipper.list.length > 0) {
        clipper.list
            .filter((plane) => plane.isSaved)
            .forEach((plane) => {
                settings.push({
                    type: "PLANE",
                    datas: JSON.stringify({
                        origin: plane._helper.position,
                        normal: plane.normal,
                    }),
                });
            });
    }

    // Mesures
    let measurement = world.components.get(OBCF.LengthMeasurement);

    if (measurement.list.length > 0) {
        measurement.list.forEach((measure) => {
            settings.push({
                type: "LENGTH_MEASURE",
                datas: JSON.stringify({
                    p1: measure._start,
                    p2: measure._end,
                }),
            });
        });
    }

    // Caméra
    settings.push({
        type: "CAMERA_MODE",
        datas: world.camera.mode.id,
    });

    // Plan 2D
    if (localStorage.getItem("viewer2DPlanView")) {
        settings.push({
            type: "2D_PLAN",
            datas: localStorage.getItem("viewer2DPlanView"), // Il s'agit du level sur lequel on se trouve au niveau du plan
        });
    }

    return settings;
}

export function deleteAllMeasures(components) {
    let measurement;
    measurement = components.get(OBCF.LengthMeasurement);
    measurement.deleteAll();

    measurement = components.get(OBCF.FaceMeasurement);
    measurement.deleteAll();

    measurement = components.get(OBCF.AreaMeasurement);
    measurement.deleteAll();
}

export async function loadCloudpointFile(fileContent, world, projectFile) {
    if (!projectFile) return;

    world.camera.controls.minDistance = 0;

    const model = await loadPointcloudFile(world, projectFile, fileContent);

    const { excludedMeshes } = world.renderer.postproduction.customEffects;
    if (model) excludedMeshes.push(model);

    return model;
}

// Permet de détecter qu'un modèle est bien chargé (et visible dans la scène)
export async function waitForModelToBeVisible(model) {
    return new Promise((resolve) => {
        const checkVisibility = () => {
            try {
                let boundingSphere = new Sphere();
                if (model.children[0]?.geometry) {
                    model.children[0].geometry.computeBoundingSphere();
                    boundingSphere = model.children[0].geometry.boundingSphere;

                    if (boundingSphere && boundingSphere.radius > 0) {
                        setTimeout(() => {
                            resolve();
                        }, 100);

                        return;
                    }
                } else resolve();
            } catch (e) {}

            requestAnimationFrame(checkVisibility);
        };

        checkVisibility();
    });
}

// Recentre la caméra sur le premier nuage de point dans la scène (uniquement si aucun IFC chargé)
export function resetCameraToPointCloud(camera, pointCloud) {
    try {
        const boundingBox = new Box3().setFromObject(pointCloud);

        const center = boundingBox.getCenter(new Vector3());
        const size = boundingBox.getSize(new Vector3());

        const maxDimension = Math.max(size.x, size.y, size.z);
        const distance = maxDimension * 2;

        camera.controls.setLookAt(
            center.x,
            center.y,
            center.z + distance,
            center.x,
            center.y,
            center.z,
        );
    } catch (e) {}
}
