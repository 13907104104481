import { useTranslation } from "react-i18next";

import { getFileExtensionIcon } from "helpers/image";

import { Avatar, Flex, Typography } from "antd";

export const TabExportToCSV = (props) => {
    const { t } = useTranslation();

    return {
        key: "csv",
        label: `${t("format")} CSV`,
        icon: (
            <Avatar
                shape="square"
                size={32}
                src={getFileExtensionIcon({ name: "file.csv" })}
            />
        ),
        style: { height: "50vh" },
        children: <PreviewExportToCSV {...props} />,
    };
};

export const functionExportToCSV = async ({ title, dataSource, columns }) => {
    const csvContent =
        columns.map((e) => e.title).join(",") +
        "\n" +
        dataSource
            .map((line) => columns.map((k) => line[k.dataIndex]).join(","))
            .join("\n");

    const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", title);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
};

export const PreviewExportToCSV = ({ dataSource, columns }) => (
    <Flex vertical>
        <Typography.Text>
            {columns.map((e) => e.title).join(",")}
        </Typography.Text>
        {dataSource.map((data, i) => (
            <Typography.Text key={i}>
                {columns.map((k) => data[k.dataIndex]).join(",")}
            </Typography.Text>
        ))}
    </Flex>
);
