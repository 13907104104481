import { Text, View } from "@react-pdf/renderer";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "layout/LayoutModal";

import ModalExport from "./ModalExport";
import { pdfStyles } from "./TemplatePDF";

import { Button } from "antd";

const ButtonExportHourCounter = (props) => {
    const { t } = useTranslation();

    const { addModalData } = useContext(ModalContext);

    return (
        <Button
            style={{ marginLeft: "auto" }}
            type="text"
            onClick={() => {
                addModalData({
                    key: "export",
                    title: `${t("exporter")} ${t("laVueActuelle")}`,
                    content: (
                        <ModalExport
                            {...props}
                            pdfContent={<PDFContentHourCounter {...props} />}
                        />
                    ),
                    width: 1200,
                    okText: t("telecharger"),
                });
            }}
        >
            {t("exporter")}
        </Button>
    );
};

export const PDFContentHourCounter = ({ dataSource, columns }) =>
    dataSource.map((line, index) => (
        <View key={index} style={pdfStyles.flexLine}>
            {columns.map((col, i) => (
                <View
                    key={i}
                    style={{
                        ...pdfStyles.tableCell,
                        ...pdfStyles.tableFix,
                        width: col.width || null,
                        flex: col.width ? null : 1,
                    }}
                >
                    <Text>{line[col.dataIndex]}</Text>
                </View>
            ))}
        </View>
    ));

export default ButtonExportHourCounter;
