import axios from "axios";

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fromDateToStringDate, getLastOpenBetweenTwoDates } from "helpers/date";
import { fieldValidation } from "helpers/fieldValidation";
import { isCompiledFilesExists } from "helpers/file";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import Text from "components/common/Text";
import Button from "components/input/Button";
import TextField from "components/input/TextField";
import Spinner from "components/utils/Spinner";

import FileImage from "./FileImage";
import ProjectSelection from "./ProjectSelection";

import { Card, Divider, Flex, theme } from "antd";

const FilesCompilation = ({ profile, files, defaultProject, onConfirm }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [loader, setLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [step, setStep] = useState(1);

    const [fieldsError, setFieldsError] = useState({
        title: "",
    });

    const fieldsValidation = () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        validation = fieldValidation(title, "required");
        updatedFieldsError.title = validation;
        if (validation !== "") nbErrors++;

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const handleNextStep = () => {
        const resValidation = fieldsValidation();

        if (resValidation === 0) setStep(2);
    };

    const handleConfirm = async (destinationFolderId) => {
        const isExists = await isCompiledFilesExists(
            files.map((selectedFile) => selectedFile._id),
            destinationFolderId,
        );

        if (isExists) {
            toastAlert(
                "warning",
                t("cetteCompilationDeFichiersExisteDejaDansCeDossier"),
            );
        } else {
            setLoader(true);

            try {
                // Update du projet
                const projectFields = {
                    id: destinationFolderId,
                    fileTitle: title,
                    compiledFiles: files.map(
                        (selectedFile) => selectedFile._id,
                    ),
                    filePublicToken: generateRandomToken(24), // Sera enregistré que si le user fait partie d'une configuration client particulière avec l'option "isDefaultPublicToken" activée
                };

                const resFile = await axios.put(
                    apiURL.addProjectFile,
                    projectFields,
                );

                if (resFile && resFile.data) onConfirm(true);
                else toastAlert("error", t("uneErreurEstSurvenue"));
            } catch (e) {
                toastAlert("error", t("uneErreurEstSurvenue"));
            }

            setLoader(false);
        }
    };

    return step === 1 ? (
        <Fragment>
            <div className="sm:flex sm:flex-row">
                <div className="w-full">
                    <TextField
                        name="title"
                        placeholder={t("nomDuRegroupement")}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength={Constants.maxLengthFileTitle}
                        errorMsg={fieldsError.title}
                    />
                </div>

                <div className="mt-4 sm:mt-0 sm:ml-4">
                    <Button
                        text={t("continuer")}
                        onClick={(e) => handleNextStep()}
                        isDisabled={title.trim().length === 0}
                    />
                </div>
            </div>

            <Fragment>
                <Divider />
                <Flex vertical gap={16}>
                    <Text bold>{t("fichiersConcernes")}</Text>
                    {files.map((compiledFile) => (
                        <Card
                            key={compiledFile._id}
                            bordered
                            size="small"
                            cover={
                                <FileImage
                                    hasCurrent={true}
                                    file={compiledFile}
                                    user={{}}
                                />
                            }
                            styles={{
                                body: {
                                    padding: token.paddingSM,
                                },
                            }}
                        >
                            <Flex vertical style={{ flex: 1, display: "grid" }}>
                                <Text
                                    strong
                                    ellipsis
                                    children={compiledFile.title}
                                    size="small"
                                    title={compiledFile.title}
                                />
                                <Text
                                    ellipsis
                                    type="secondary"
                                    size="small"
                                    children={
                                        compiledFile.viewedDate
                                            ? getLastOpenBetweenTwoDates(
                                                  Date.now(),
                                                  compiledFile.viewedDate,
                                              )
                                            : `${
                                                  (compiledFile.author
                                                      ?.firstname || "") +
                                                  " " +
                                                  (compiledFile.author
                                                      ?.lastname || "")
                                              } ${
                                                  compiledFile.author ? "|" : ""
                                              } ${fromDateToStringDate(
                                                  compiledFile.creationDate,
                                              )}`
                                    }
                                />
                            </Flex>
                        </Card>
                    ))}
                </Flex>
            </Fragment>
        </Fragment>
    ) : (
        <Fragment>
            <div className="font-bold text-black mb-4">
                {t("dossierDeDestination")}
            </div>
            <ProjectSelection
                profile={profile}
                currentProject={defaultProject}
                file={files[0]}
                onConfirm={handleConfirm}
                isCurrentSelectedDefault={true}
                minFolderRole="EDITOR"
            />
            {loader && <Spinner />}
        </Fragment>
    );
};

export default FilesCompilation;
