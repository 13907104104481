import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import PortalApplications from "./PortalApplications";
import PortalNav from "./PortalNav";

const Portal = ({ profile }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = t("portail") + " - " + t("applicationTitle");
        navigate("/portal");
    }, [t]);

    return (
        profile && (
            <div className="h-[100dvh] bg-gray-100 flex flex-col">
                <div className="bg-gradient-to-r from-blue-700 to-teal-300 h-[360px] sm:text-center">
                    <PortalNav profile={profile} />
                    <div className="inline-block text-white mt-3 px-7 max-w-[300px] sm:max-w-full">
                        <h1 className="font-bold text-3xl">
                            Hello {profile.user.firstname},
                        </h1>
                        <h2 className="mt-1 text-lg">
                            {t("bienvenueSurTaPlateformeDeTravail")}
                        </h2>
                    </div>
                </div>
                <div className="h-full flex text-center">
                    <PortalApplications />
                </div>
            </div>
        )
    );
};

export default Portal;
