import { Emoji } from "emoji-picker-react";

import { useEffect, useRef } from "react";

const EmojiReactionItem = ({ code, activeCode, onClick }) => {
    return (
        <li className="my-auto flex">
            <button
                type="button"
                className={
                    "transition-all" +
                    (activeCode === code
                        ? " hover:scale-75"
                        : " hover:scale-125") +
                    (activeCode !== "" && activeCode !== code
                        ? " opacity-25 hover:opacity-100"
                        : "")
                }
                onClick={(e) => onClick(code)}
            >
                <Emoji unified={code} size={activeCode === code ? 18 : 16} />
            </button>
        </li>
    );
};

const EmojisReactions = ({ setVisible, handleReaction, activeCode = "" }) => {
    const listRef = useRef(null);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (listRef.current) {
            if (!listRef.current.contains(e.target)) {
                setVisible(false);
            }
        }
    };

    const onClick = (code) => {
        handleReaction(code);
        setVisible(false);
    };

    return (
        <ul ref={listRef} className="flex flex-row gap-2 card rounded-full p-2">
            <EmojiReactionItem
                code="1f44d"
                activeCode={activeCode}
                onClick={onClick}
            />
            <EmojiReactionItem
                code="2764-fe0f"
                activeCode={activeCode}
                onClick={onClick}
            />
            <EmojiReactionItem
                code="1f603"
                activeCode={activeCode}
                onClick={onClick}
            />
            <EmojiReactionItem
                code="1f622"
                activeCode={activeCode}
                onClick={onClick}
            />
            <EmojiReactionItem
                code="1f64f"
                activeCode={activeCode}
                onClick={onClick}
            />

            <EmojiReactionItem
                code="1f44e"
                activeCode={activeCode}
                onClick={onClick}
            />
            <EmojiReactionItem
                code="1f621"
                activeCode={activeCode}
                onClick={onClick}
            />
        </ul>
    );
};

export default EmojisReactions;
