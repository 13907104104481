import moment from "moment";

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";

import { fromBase64ToFile } from "helpers/image";

import MaterialIcons from "components/common/MaterialIcons";
import { DriveToolsContext } from "components/drive/Drive";

import FormNewPhoto from "./FormNewPhoto";

import { Alert, Button, Drawer, Flex, FloatButton, Result, Spin, Typography, theme } from "antd";

const PhotosUser = () => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { userProfile, getCurrentProfile } = useContext(DriveToolsContext);

    // Files
    const [filesTaken, setFilesTaken] = useState([]);
    const previewUrl = useMemo(() => {
        if (filesTaken.length === 0) return null;
        const url = URL.createObjectURL(filesTaken[filesTaken.length - 1]);
        return url;
    }, [filesTaken]);

    // Camera
    const [isFacingModeUser, setIsFacingModeUser] = useState(false);
    const webcamRef = useRef(null);
    const [animationScreenshot, setAnimationScreenshot] = useState(false);
    const [permissionCamera, setPermissionCamera] = useState(); // "ACCEPTED" | "DENIED"

    const [videoConstraints, setVideoConstraints] = useState();
    useEffect(() => {
        setVideoConstraints({
            facingMode: isFacingModeUser
                ? "user"
                : true // TOFIX Photos - Webcam d'ordinateur facingMode: { exact : "environment" } not working
                  ? { exact: "environment" }
                  : "environment",
            width: 1280,
            height: 720,
            aspectRatio: 1,
            frameRate: { ideal: 30, max: 60 },
        });
    }, [isFacingModeUser]);

    const handleCameraAccess = useCallback(async () => {
        setPermissionCamera();
        try {
            if (navigator.mediaDevices) {
                const stream = await navigator.mediaDevices.getUserMedia(videoConstraints);
                if (stream) {
                    setPermissionCamera("ACCEPTED");
                } else {
                    setPermissionCamera("DENIED");
                }
            } else {
                setPermissionCamera("DENIED");
            }
        } catch (err) {
            const status = await navigator.permissions.query({
                name: "camera",
            });
            if (status.state === "granted") setPermissionCamera("ACCEPTED");
            else if (err.name !== "NotReadableError") {
                setPermissionCamera("DENIED");
            }
        }
    }, []);

    React.useEffect(() => {
        if (videoConstraints && !permissionCamera) handleCameraAccess(videoConstraints);
    }, [videoConstraints, permissionCamera]);

    const [openDrawer, setopenDrawer] = useState(false);

    return (
        <Flex
            vertical
            gap={12}
            style={{
                height: "100%",
            }}>
            <GeolocationPicture />

            <div
                style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: permissionCamera === "ACCEPTED" ? "black" : "white",
                    position: "relative",
                }}>
                {permissionCamera === "DENIED" && <ScreenDENIED />}
                {permissionCamera === undefined && <ScreenPENDING />}

                <div
                    style={{
                        position: "absolute",
                        backgroundColor: animationScreenshot ? "#ffffff99" : "transparent",
                        width: "100%",
                        height: "100%",
                        transition: animationScreenshot ? "all 0" : "background-color 0.2s",
                    }}></div>
                {videoConstraints && (
                    <Webcam
                        audio={false}
                        autoPlay={true}
                        ref={webcamRef}
                        imageSmoothing
                        // forceScreenshotSourceSize
                        disablePictureInPicture
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                        onUserMedia={(e, v) => {
                            setPermissionCamera("ACCEPTED");
                        }}>
                        {({ getScreenshot }) => (
                            <FloatButton
                                shape="circle"
                                type="primary"
                                style={{
                                    width: 64,
                                    height: 64,
                                    left: `calc(50% - ${32}px)`,
                                    bottom: token.paddingLG,
                                }}
                                icon={<MaterialIcons name="photo_camera" size="xl" />}
                                onClick={() => {
                                    const imageSrc = getScreenshot();
                                    if (imageSrc) {
                                        const fileParsed = fromBase64ToFile(
                                            imageSrc,
                                            moment().format("DD/MM/YYYY-HH:mm:ss") + ".jpeg",
                                        );
                                        setFilesTaken((prev) => [...prev, fileParsed]);
                                        // if (
                                        //     permissionGeolocation ===
                                        //     "ACCEPTED"
                                        // ) {
                                        //     navigator.geolocation.getCurrentPosition(
                                        //         (position) => {
                                        //             const latitude =
                                        //                 position.coords
                                        //                     .latitude;
                                        //             const longitude =
                                        //                 position.coords
                                        //                     .longitude;
                                        //             fileParsed.geolocation =
                                        //                 latitude +
                                        //                 "," +
                                        //                 longitude;
                                        //         },
                                        //     );
                                        // }

                                        setAnimationScreenshot(true);
                                        setTimeout(() => {
                                            setAnimationScreenshot(false);
                                        }, 200);
                                    }
                                }}
                            />
                        )}
                    </Webcam>
                )}

                <Drawer
                    title={<Typography.Title level={5}>{t("envoyer")}</Typography.Title>}
                    destroyOnClose
                    placement="bottom"
                    height="auto"
                    closable={false}
                    onClose={() => setopenDrawer(false)}
                    open={openDrawer}
                    extra={
                        <Button
                            shape="circle"
                            type="text"
                            onClick={() => setopenDrawer(false)}
                            icon={<MaterialIcons name="close" />}
                        />
                    }>
                    <FormNewPhoto
                        filesTaken={filesTaken}
                        setFilesTaken={setFilesTaken}
                        userProfile={userProfile}
                        getCurrentProfile={getCurrentProfile}
                    />
                </Drawer>

                <FloatButton
                    type="primary"
                    shape="circle"
                    onClick={() => setIsFacingModeUser(!isFacingModeUser)}
                    style={{
                        width: 48,
                        height: 48,
                        right: token.paddingLG,
                        bottom: token.paddingLG,
                    }}
                    icon={<MaterialIcons name="cameraswitch" />}
                />
                <div
                    style={{
                        transform: `scale(${animationScreenshot ? 1.2 : 1})`,
                        transition: "transform 0.2s",
                        border: animationScreenshot ? `4px solid ${token.colorPrimary}` : `2px solid white`,
                        borderRadius: token.borderRadiusLG,
                        objectFit: "cover",
                        width: 64,
                        height: 64,
                        left: token.paddingLG,
                        bottom: token.paddingLG,
                        position: "fixed",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                    }}
                    onClick={() => {
                        setopenDrawer(true);
                    }}>
                    {previewUrl ? (
                        <img
                            style={{
                                width: "100%",
                                height: "100%",
                            }}
                            src={previewUrl}
                        />
                    ) : (
                        <MaterialIcons name="add" type="white" size="xl" />
                    )}
                </div>
            </div>
        </Flex>
    );
};

const ScreenDENIED = () => {
    const { t } = useTranslation();

    return (
        <Result
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "white",
                zIndex: 2,
            }}
            status="warning"
            title={t("lesPermissionsDeLaCameraSontRequises")}
            extra={
                <Button
                    size="large"
                    type="primary"
                    style={{
                        textWrap: "auto",
                        height: "auto",
                    }}
                    href="https://www.google.com/search?q=Comment+activer+la+caméra+sur+mon+navigateur+%3F&client=firefox-b-d&ei=1KHGYsWYG7GKlwSppY-oAg&ved=0ahUKEwjF0s-Wtub4AhUxxYUKHanSAyUQ4dUDCA0&uact=5&oq=Comment+activer+la+g%C3%A9olocalisation+sur+mon+navigateur+%3F"
                    target="_blank"
                    children={t("commentActiverLaCameraSurMonNavigateur")}
                />
            }
        />
    );
};

const ScreenPENDING = () => {
    const { t } = useTranslation();

    return (
        <Result
            style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: "white",
                zIndex: 2,
            }}
            icon={<Spin />}
            title={t("chargementDesPermissions")}
        />
    );
};

const GeolocationPicture = () => {
    // Geolocation
    const [permissionGeolocation, setPermissionGeolocation] = useState(); // "ACCEPTED" | "DENIED" | "UNAVAILABLE" | "TIMEOUT"
    // INCASE Photos Géolocalisation
    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 setPermissionGeolocation("ACCEPTED");
    //             },
    //             (error) => {
    //                 switch (error.code) {
    //                     case error.PERMISSION_DENIED:
    //                         setPermissionGeolocation("DENIED");
    //                         break;
    //                     case error.POSITION_UNAVAILABLE:
    //                         setPermissionGeolocation("UNAVAILABLE");
    //                         break;
    //                     case error.TIMEOUT:
    //                         setPermissionGeolocation("TIMEOUT");
    //                         break;
    //                     case error.UNKNOWN_ERROR:
    //                         setPermissionGeolocation("UNAVAILABLE");
    //                         break;
    //                 }
    //             }
    //         );
    //     } else {
    //         setPermissionGeolocation("UNAVAILABLE");
    //     }
    // }, []);

    if (permissionGeolocation)
        return (
            <Alert
                onClick={() => {
                    if (permissionGeolocation === "DENIED")
                        window.open(
                            "https://www.google.com/search?q=Comment+activer+la+localisation+sur+mon+navigateur+%3F&client=firefox-b-d&ei=1KHGYsWYG7GKlwSppY-oAg&ved=0ahUKEwjF0s-Wtub4AhUxxYUKHanSAyUQ4dUDCA0&uact=5&oq=Comment+activer+la+g%C3%A9olocalisation+sur+mon+navigateur+%3F",
                            "_blank",
                        );
                }}
                message={
                    <Flex gap={4} align="center">
                        <MaterialIcons
                            name={
                                permissionGeolocation === "DENIED"
                                    ? "location_off"
                                    : permissionGeolocation === "UNAVAILABLE"
                                      ? "location_off"
                                      : permissionGeolocation === "TIMEOUT"
                                        ? "location_off"
                                        : "location_on"
                            }
                            size="xs"
                        />
                        <Typography.Text>
                            {permissionGeolocation === "DENIED"
                                ? t("geolocalisationRefusee")
                                : permissionGeolocation === "UNAVAILABLE"
                                  ? t("geolocalisationIndisponible")
                                  : permissionGeolocation === "TIMEOUT"
                                    ? t("geolocalisationTimeout")
                                    : t("geolocalisationActivee")}
                        </Typography.Text>
                    </Flex>
                }
                type={
                    permissionGeolocation === "DENIED"
                        ? "warning"
                        : permissionGeolocation === "UNAVAILABLE"
                          ? "error"
                          : permissionGeolocation === "TIMEOUT"
                            ? "warning"
                            : "success"
                }
                showIcon={false}
            />
        );
};

export default PhotosUser;
