import { useAppStore } from "providers/ProviderApp";

import { useMediaPredicate } from "react-media-hook";

import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";

import { Segmented } from "antd";

const DriveModeRender = ({ style = {}, spaced, onChange }) => {
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");
    const { viewMode, toggleViewMode } = useAppStore();

    return (
        <Segmented
            size={mdMedia ? "large" : "small"}
            // vertical={!mdMedia}
            value={viewMode}
            onChange={(e) => {
                onChange?.(e);
                toggleViewMode(e);
            }}
            style={{
                marginLeft: spaced ? "auto" : 0,
                height: "min-content",
                ...style,
            }}
            options={[
                {
                    value: "grid",
                    label: (
                        <MaterialIcons
                            style={{
                                paddingTop: mdMedia ? 0 : 4,
                                paddingBottom: mdMedia ? 0 : 4,
                            }}
                            size={mdMedia ? "md" : "sm"}
                            name="grid_view"
                            isOutlined
                        />
                    ),
                },
                {
                    value: "list",
                    icon: (
                        <MaterialIcons
                            style={{
                                paddingTop: mdMedia ? 0 : 4,
                                paddingBottom: mdMedia ? 0 : 4,
                            }}
                            size={mdMedia ? "md" : "sm"}
                            name="view_list"
                            isOutlined
                        />
                    ),
                },
            ]}
        />
    );
};

export default DriveModeRender;
