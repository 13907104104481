import axios from "axios";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import apiURL from "utils/apiURL";

import { useProfileStore } from "./ProviderProfile";

export const useDriveStore = create(
    persist(
        (set, get) => ({
            // DATA
            userProjects: [],

            currentProject: null,
            currentRoles: [],
            selectedItems: [],

            // ACTIONS
            testFunction: async () => {},

            // Change le projet courant dans la vue drive
            changeCurrentProject: async () => {
                // set({ currentProject: projectId });
            },

            // Toggle les données selectionnés pour les actions de selection multiple
            toggleSelectedItem: async () => {
                // set({ selectedItems: [...selectedItems, itemId] });
            },

            // Computed property: Filters adminProjects dynamically

            getMyProjects: async () => {
                const user = useProfileStore.getState().user;
                if (user) {
                    const { data } = await axios.get(apiURL.getMyProjects);
                    set({ userProjects: data });
                }
            },
        }),
        {
            name: "drive-storage",
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export const adminProjects = () => {
    const { userProjects } = useDriveStore.getState();
    return userProjects;
};
