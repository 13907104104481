import { useContext, useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import { scrollToComment } from "helpers/comment";
import Constants from "utils/constants";

import { DriveToolsContext } from "components/drive/Drive";

export default function MarkersComments({ file, scale, index }) {
    const { toolsData, setToolsData } = useContext(DriveToolsContext);
    const [groups, setGroups] = useState([]);
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    useEffect(() => {
        return () => {
            setToolsData((e) => ({
                ...e,
                markers: {
                    isMarkerMode: false,
                    addedMarker: null,
                    commentsMarkers: [],
                },
            }));
        };
    }, []);
    // Group markers based on proximity and merge nearby groups
    useEffect(() => {
        if (toolsData.markers?.commentsMarkers) {
            const markers = toolsData.markers.commentsMarkers;

            const groupedMarkers = [];
            const threshold = 5 / scale; // Adjust grouping distance based on scale

            markers.forEach((marker) => {
                const { x, y, page } = marker.position;
                const group = groupedMarkers.find(
                    (g) =>
                        g.page === page &&
                        g.markers.some(
                            (m) =>
                                Math.sqrt(
                                    Math.pow(m.position.x - x, 2) +
                                        Math.pow(m.position.y - y, 2),
                                ) <= threshold,
                        ),
                );

                if (group) {
                    group.markers.push(marker);
                } else {
                    groupedMarkers.push({ page, markers: [marker] });
                }
            });

            // Merge nearby groups
            const mergeNearbyGroups = (groups) => {
                const mergedGroups = [];
                const distanceThreshold = 5 / scale; // Distance threshold to merge groups

                groups.forEach((group) => {
                    let merged = false;
                    // Check if the current group can be merged with any existing group
                    for (const mergedGroup of mergedGroups) {
                        const center1 = getGroupCenter(group);
                        const center2 = getGroupCenter(mergedGroup);

                        const distance = Math.sqrt(
                            Math.pow(center1.x - center2.x, 2) +
                                Math.pow(center1.y - center2.y, 2),
                        );

                        if (distance <= distanceThreshold) {
                            mergedGroup.markers.push(...group.markers);
                            merged = true;
                            break;
                        }
                    }

                    if (!merged) {
                        mergedGroups.push(group);
                    }
                });

                return mergedGroups;
            };

            // Get the center of a group
            const getGroupCenter = (group) => {
                const centerX =
                    group.markers.reduce(
                        (sum, marker) => sum + marker.position.x,
                        0,
                    ) / group.markers.length;
                const centerY =
                    group.markers.reduce(
                        (sum, marker) => sum + marker.position.y,
                        0,
                    ) / group.markers.length;
                return { x: centerX, y: centerY };
            };

            const mergedGroups = mergeNearbyGroups(groupedMarkers);

            // Filter out single-marker groups
            const filteredGroups = mergedGroups.filter(
                (group) => group.markers.length > 1,
            );

            setGroups(filteredGroups);
        }
    }, [toolsData.markers?.commentsMarkers, scale]);

    // Generate group element
    const generateGroupElement = (group, groupIndex) => {
        const { markers } = group;

        let size = lgMedia ? 50 : 40;

        // On agrandit le cercle si il y a beaucoup d'éléments dans le groupe
        if (markers.length <= 20) size += markers.length + 16;
        else size += 20;

        const centerX =
            markers.reduce((sum, marker) => sum + marker.position.x, 0) /
            markers.length;
        const centerY =
            markers.reduce((sum, marker) => sum + marker.position.y, 0) /
            markers.length;

        return (
            <div
                key={`group_${groupIndex}`}
                className="absolute z-[2] flex rounded-full bg-orange-600 -translate-x-1/2 -translate-y-1/2 hover:cursor-pointer hover:scale-150"
                style={{
                    top: `${centerY}%`,
                    left: `${centerX}%`,
                    width: size,
                    height: size,
                    boxShadow: "0px 0px 0px 5px rgba(234,88,12,0.3)",
                    transform: "scale(" + (1 / scale + 1) + ")",
                }}
                onClick={() =>
                    setGroups((prev) =>
                        prev.map((g, i) =>
                            i === groupIndex
                                ? { ...g, expanded: !g.expanded }
                                : g,
                        ),
                    )
                }
            >
                <div className="text-white m-auto lg:text-sm">
                    {markers.length}
                </div>
            </div>
        );
    };

    // Generate marker element
    const generateMarkerElement = (
        markerIndex,
        marker,
        commentId,
        commentNumberIndex,
    ) => {
        return (
            <div
                key={`marker_${markerIndex}`}
                id={commentId ? "marker-" + commentId : null}
                className={
                    "absolute z-[2] -translate-x-1/2 -translate-y-1/2" +
                    (commentId
                        ? " hover:cursor-pointer hover:scale-150 duration-200"
                        : "")
                }
                style={{
                    top: `${marker.y}%`,
                    left: `${marker.x}%`,
                }}
                onClick={(e) => commentId && scrollToComment(commentId)}
            >
                <div
                    className="flex rounded-t-full rounded-br-full bg-orange-600 h-[36px] w-[36px] "
                    style={{
                        transform: "scale(" + 1 / scale + ")",
                        boxShadow: "0px 0px 0px 5px rgba(234,88,12,0.3)",
                    }}
                >
                    {Number.isInteger(commentNumberIndex) && (
                        <div className="text-white m-auto">
                            #{commentNumberIndex}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <>
            {toolsData.markers?.addedMarker &&
                toolsData.markers.addedMarker.page === index + 1 &&
                generateMarkerElement("added", toolsData.markers.addedMarker)}

            {groups
                .filter((group) => group.page === index + 1)
                .map((group, i) =>
                    group.expanded
                        ? group.markers.map((marker, j) =>
                              generateMarkerElement(
                                  `${i}_${j}`,
                                  marker.position,
                                  marker.commentId,
                                  marker.commentNumber,
                              ),
                          )
                        : generateGroupElement(group, i),
                )}

            {toolsData.markers?.commentsMarkers &&
                toolsData.markers.commentsMarkers
                    .filter(
                        (marker) =>
                            !groups
                                .flatMap((g) => g.markers)
                                .includes(marker) &&
                            marker.position.page === index + 1,
                    )
                    .map((marker, i) =>
                        generateMarkerElement(
                            `${index + 1}_solo_${i}`,
                            marker.position,
                            marker.commentId,
                            marker.commentNumber,
                        ),
                    )}
        </>
    );
}
