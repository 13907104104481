import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Checkbox, Col, Form, Input, Row, theme } from "antd";

export function WeatherAnswer({ title, index, required, disabled }) {
    const options = ["Beau", "Faible Pluie", "Pluie", "Neige", "Autre"];

    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}>
            <Checkbox.Group className="gap-4" disabled={disabled}>
                {options.map((option, index) => (
                    <Checkbox key={index} value={option}>
                        {option}
                    </Checkbox>
                ))}
            </Checkbox.Group>
        </Form.Item>
    );
}

export function WeatherTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    );
}

export function WeatherPrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.value}
        </TextPrint>
    );
}
