import { Fragment, useEffect, useRef, useState } from "react";
import { isDesktop } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Link, useNavigate } from "react-router-dom";

import { getFileExtension, isIfcFile, isPcdFile } from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import Constants from "utils/constants";

import DocumentReader from "components/documents/DocumentReader";
import Spinner from "components/utils/Spinner";

const SearchBar = ({
    profile,
    onChange,
    isShowResults,
    showResults,
    resultsList,
    refreshAction,
    noBackground = false,
    placeholder = "",
    enableSearchSpinner = false,
    enableDebounce = true,
}) => {
    const smMedia = useMediaPredicate("(min-width: " + Constants.smSize + ")");

    const { t } = useTranslation();
    const navigate = useNavigate();

    const searchRef = useRef(null);
    const [searchValue, setSearchValue] = useState("");
    const [hasSearchStarted, setSearchStarted] = useState(false);

    const [renderDocumentFile, setRenderDocumentFile] = useState(null);

    useEffect(() => {
        const timeoutId = setTimeout(
            () => {
                onChange(searchValue);
                if (enableSearchSpinner) setSearchStarted(true);
            },
            enableDebounce ? 500 : 0,
        );
        return () => clearTimeout(timeoutId);
    }, [searchValue]);

    useEffect(() => {
        setSearchStarted(false);
    }, [resultsList]);

    const handleClick = (file) => {
        if (isIfcFile(file) || isPcdFile(file))
            window.open("/viewer/" + file._id);
        else setRenderDocumentFile(file);

        searchRef.current.value = null;
    };

    const closeReader = () => {
        setRenderDocumentFile(null);
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value.trim());
    };

    const searchBarElem = (
        <div
            className={
                "m-0 h-full text-sm p-2 flex flex-row w-full text-black" +
                (noBackground
                    ? " rounded-md shadow-sm bg-transparent border text-xs w-full"
                    : " float-right bordered-card rounded-2xl")
            }
        >
            {!noBackground && (
                <i
                    className="material-icons notranslate"
                    style={{ fontSize: "1.4rem" }}
                >
                    search
                </i>
            )}

            <input
                type="search"
                className="ml-2 bg-transparent border-transparent focus-visible:outline-0 w-full"
                placeholder={
                    placeholder === ""
                        ? smMedia
                            ? t("rechercherParMotsClesOuUtilisateurs")
                            : t("rechercher")
                        : placeholder
                }
                onChange={(e) => handleInputChange(e)}
                onBlur={(e) => {
                    setTimeout(() => showResults && showResults(false), 200);
                }}
                ref={searchRef}
                autoComplete="off"
            />
        </div>
    );

    return (
        <Fragment>
            {searchBarElem}

            {(hasSearchStarted || isShowResults) &&
                searchRef.current.value.length > 0 && (
                    <div
                        className={
                            "fixed sm:absolute card bg-slate-100 p-2 rounded-md text-black max-h-[calc(80vh-50px)] overflow-y-auto w-full top-[64px] sm:top-[46px] right-0 "
                        }
                    >
                        {hasSearchStarted ? (
                            <Spinner
                                isSmall={true}
                                widthClass=" w-8"
                                heightClass=" w-8"
                            />
                        ) : resultsList.length > 0 ? (
                            <ul className="text-sm">
                                {resultsList.map((row) => {
                                    return (
                                        <li key={row._id}>
                                            {!row.file && !row.compiledFiles ? (
                                                <Link
                                                    to={
                                                        "/drive/project/" +
                                                        row._id
                                                    }
                                                    className="block py-1"
                                                    onClick={(e) =>
                                                        (searchRef.current.value =
                                                            null)
                                                    }
                                                >
                                                    <div className="hover:bg-slate-300 h-full flex min-h-[40px] rounded-md truncate">
                                                        <i
                                                            className="material-icons notranslate ml-2 my-auto"
                                                            style={{
                                                                fontSize:
                                                                    "1.5rem",
                                                                color:
                                                                    row.color &&
                                                                    row.color,
                                                            }}
                                                        >
                                                            folder
                                                        </i>

                                                        <div className="my-auto ml-2 truncate">
                                                            {row.title}
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <div
                                                    className="w-full hover:cursor-pointer hover:bg-slate-300 h-full flex flex-col rounded-md group"
                                                    onClick={(e) =>
                                                        handleClick(row)
                                                    }
                                                >
                                                    <div className="hover:bg-slate-300 h-full flex min-h-[40px] rounded-md truncate">
                                                        <img
                                                            src={getFileExtensionIcon(
                                                                row,
                                                            )}
                                                            loading="lazy"
                                                            width="30"
                                                            className="ml-1"
                                                            alt={
                                                                row.title +
                                                                "." +
                                                                (row
                                                                    .compiledFiles
                                                                    .length > 0
                                                                    ? "ifc"
                                                                    : getFileExtension(
                                                                          row.file,
                                                                      ))
                                                            }
                                                        />
                                                        <div className="my-auto ml-2 truncate">
                                                            {row.title +
                                                                "." +
                                                                (row
                                                                    .compiledFiles
                                                                    .length > 0
                                                                    ? "ifc"
                                                                    : getFileExtension(
                                                                          row.file,
                                                                      ))}
                                                        </div>
                                                    </div>

                                                    {row.project?.title &&
                                                        isDesktop && (
                                                            <div className="group-hover:block hidden my-auto mr-2 truncate text-right">
                                                                <Link
                                                                    to={
                                                                        "/drive/project/" +
                                                                        row
                                                                            .project
                                                                            ._id
                                                                    }
                                                                    className="inline-flex p-1 hover:text-primary"
                                                                    onClick={(
                                                                        e,
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        searchRef.current.value =
                                                                            null;
                                                                    }}
                                                                >
                                                                    <i
                                                                        className="material-icons notranslate mr-2 my-auto"
                                                                        style={{
                                                                            fontSize:
                                                                                "1.5rem",
                                                                            color:
                                                                                row
                                                                                    .project
                                                                                    .color &&
                                                                                row
                                                                                    .project
                                                                                    .color,
                                                                        }}
                                                                    >
                                                                        folder
                                                                    </i>
                                                                    <div className="my-auto">
                                                                        {
                                                                            row
                                                                                .project
                                                                                .title
                                                                        }
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )}
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : (
                            <div className="w-full block text-center p-2">
                                {t("aucunResultat")}
                            </div>
                        )}
                    </div>
                )}

            {renderDocumentFile && (
                <DocumentReader
                    fileId={renderDocumentFile._id}
                    closeReader={closeReader}
                    profile={profile}
                    refreshAction={refreshAction}
                />
            )}
        </Fragment>
    );
};

export default SearchBar;
