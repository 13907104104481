import axios from "axios";
import PropTypes from "prop-types";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { toastAlert } from "helpers/alert";
import ConnectionAPIClient, { isBIMCollabAvailable } from "helpers/bcf";
import { fieldValidation } from "helpers/fieldValidation";
import apiURL from "utils/apiURL";

import CommentTextarea from "./CommentTextarea";
import { CommentsContext } from "./CommentsContent";

const SubCommentForm = ({
    auth: { user, isAuthenticated },
    parentComment,
    editedSubComment,
    setEditedSubComment,
    guestEmail,
    guestName,
    fileId,
    mentionsList,
    onChangeMentions,
}) => {
    const { t } = useTranslation();

    const {
        comments,
        setComments,
        customerConfig,
        currentBIMCollabProject,
        isLightViewer,
        isFromViewer,
    } = useContext(CommentsContext);

    const apiClient = new ConnectionAPIClient(
        customerConfig ? customerConfig.BIMCollab.url : "",
    );

    const [loader, setLoader] = useState(false);

    const initialFormData = {
        content: "",
        image: null,
    };
    const [formData, setFormData] = useState(initialFormData);

    const onChange = (e, name) => {
        setFormData({
            ...formData,
            [name ? name : e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (editedSubComment)
            setFormData({ ...formData, content: editedSubComment.content });
        else setFormData({ ...formData, content: "" });
    }, [editedSubComment]);

    const onSave = async () => {
        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            try {
                let commentRes;

                if (isFromViewer && isBIMCollabAvailable(customerConfig)) {
                    // Création du BCF dans BIMCollab
                    commentRes = await apiClient.createTopicComment(
                        currentBIMCollabProject,
                        parentComment._id,
                        {
                            comment: formData.content,
                            image: formData.image,
                        },
                    );
                } else {
                    commentRes = await axios.put(apiURL.addSubComment, {
                        id: parentComment._id,
                        content: formData.content,
                        image: formData.image,
                        author: isAuthenticated && user,
                        guestEmail: !isAuthenticated && guestEmail,
                        guestName: !isAuthenticated && guestName,
                        subCommentId: editedSubComment
                            ? editedSubComment._id
                            : null,
                        fileId,
                        isLightViewer,
                    });

                    commentRes = commentRes.data;
                }

                if (commentRes) {
                    // On remplace le commentaire parmi la liste complète
                    let commentIndex = comments.findIndex(
                        (item) => item._id === parentComment._id,
                    );

                    let updatedComments = comments;
                    updatedComments[commentIndex] = commentRes;

                    setComments([...updatedComments]);
                } else toastAlert("error", t("uneErreurEstSurvenue"));
            } catch (err) {
                toastAlert("error", t("uneErreurEstSurvenue"));
            }
        }

        setFormData(initialFormData);
        setEditedSubComment(null);

        setLoader(false);
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;
        var validation = "";

        if (!formData.image) {
            validation = fieldValidation(formData.content, "required");
            if (validation !== "") nbErrors++;
        }

        return nbErrors;
    };

    const onUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Get the Base64 string from the result
                const base64String = reader.result;

                setFormData({ ...formData, image: base64String });
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <form className="mt-4">
            <CommentTextarea
                value={formData.content}
                isEdit={editedSubComment}
                loader={loader}
                mentionsList={!isLightViewer && mentionsList}
                onChangeMentions={!isLightViewer && onChangeMentions}
                onChange={onChange}
                onSave={onSave}
                height={52}
                rows={2}
                onImageUpload={onUpload}
            />

            {formData.image && (
                <div className="relative">
                    <button
                        type="button"
                        onClick={(e) =>
                            setFormData({ ...formData, image: null })
                        }
                        className="absolute top-0 right-0 p-1"
                    >
                        <i
                            className="material-icons notranslate mr-1"
                            style={{ fontSize: "1rem" }}
                        >
                            close
                        </i>
                    </button>
                    <img
                        src={formData.image}
                        alt=""
                        className="mt-4 rounded-lg shadow-md"
                    />
                </div>
            )}
        </form>
    );
};

SubCommentForm.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(SubCommentForm);
