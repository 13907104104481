function UserCircle({ user, guestName }) {
    return (
        <div className="my-auto flex bg-black rounded-full p-1 min-w-[30px] min-h-[30px] w-[30px] h-[30px]">
            <div className="text-xs m-auto text-center text-white font-bold uppercase">
                {user
                    ? user.firstname?.substring(0, 1) +
                      user.lastname?.substring(0, 1)
                    : guestName && guestName.substring(0, 1)}
            </div>
        </div>
    );
}

export default UserCircle;
