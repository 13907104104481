import { useProfileStore } from "providers/ProviderProfile";

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { formModalKey } from "helpers/forms";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import {
    FooterAnswerForm,
    FormAnswer,
    TitleFormAnswer,
} from "components/forms/FormAnswer";
import { usePlansStore } from "components/plans/PlansView";

import { ModalContext } from "layout/LayoutModal";

import { theme } from "antd";

export default function MarkerForms({ scale }) {
    const { permissionsTools } = useProfileStore();
    const { positionsForms } = usePlansStore();
    if (
        permissionsTools.formsAdmin.canView ||
        permissionsTools.formsUser.canView
    )
        return positionsForms.map((form, i) => (
            <Marker key={i} form={form} scale={scale} />
        ));
}

function Marker({ form, scale }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");
    const { addModalData, removeModal } = useContext(ModalContext);
    const { getMarkers } = usePlansStore();
    const { status, position } = form;

    let markerScale = 4 - scale + 0.4;
    // Custom scale based on zoom level
    if (scale < 1) markerScale = 4 - scale + 1;
    else if (scale < 2) markerScale = 4 - scale - 0.4;
    else if (scale < 3) markerScale = 4 - scale;
    else if (scale < 5) markerScale = markerScale = 4 - scale + 0.4;
    if (position.marker)
        return (
            <div
                onClick={() => {
                    addModalData({
                        key: formModalKey,
                        okText: t("envoyer"),
                        title: form.title,
                        width: 800,
                        fullScreen: !mdMedia,
                        title: (
                            <TitleFormAnswer
                                author={form.author}
                                templateData={form}
                            />
                        ),
                        content: <FormAnswer templateData={form} />,
                        footer: (
                            <FooterAnswerForm
                                _id={form._id}
                                templateData={form}
                            />
                        ),
                        initialValues: form,
                        handleConfirm: async (values) => {
                            await getMarkers();
                            removeModal(formModalKey);
                        },
                    });
                }}
                style={{
                    transform: "scale(" + markerScale + ")",
                    position: "absolute",
                    zIndex: 2,
                    top: `${position.marker.y}%`,
                    left: `${position.marker.x}%`,
                    translate: "150% -200%",
                }}
            >
                <MaterialIcons
                    name={
                        status === "DRAFT"
                            ? "sms"
                            : status === "PENDING"
                              ? "chat_info"
                              : status === "APPROVED"
                                ? "mark_chat_read"
                                : "chat_error"
                    }
                    type={
                        status === "DRAFT"
                            ? "warning"
                            : status === "PENDING"
                              ? "info"
                              : status === "APPROVED"
                                ? "success"
                                : "error"
                    }
                    style={{
                        backgroundColor:
                            status === "DRAFT"
                                ? token.colorWarning + 33
                                : status === "PENDING"
                                  ? token.colorPrimary + 33
                                  : status === "APPROVED"
                                    ? token.colorSuccess + 33
                                    : token.colorError + 33,
                    }}
                    className={
                        "hover:cursor-pointer hover:scale-150 duration-200 rounded "
                    }
                />
            </div>
        );
}
