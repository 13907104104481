import Textarea from "components/input/Textarea";

const RenderTextField = ({ title, value, onChange }) => {
    return (
        <Textarea
            text={title}
            placeholder={title}
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            showCharactersCount={false}
        />
    );
};

export default RenderTextField;
