import axios from "axios";
import moment from "moment";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { getProjectparentsList } from "helpers/project";
import apiURL from "utils/apiURL";

import { Input, Select } from "antd";

const CompleteNomenclature = ({
    action,
    indexField,
    projectToUpload,
    answers,
    setAnswers,
    oldFile,
    fileName,
}) => {
    const { t } = useTranslation();

    const code = action.constraint.code;
    const versions = oldFile?.versions || [];

    const getFieldValue = (fieldKey) => {
        // Helper function to get the current value of a field from `answers`
        return answers[fieldKey] || "";
    };

    useEffect(() => {
        if (code === "FILENAME_LIST" && oldFile?.title) {
            const prechargedSelection = action.nomenclature.find((e) =>
                oldFile.title.includes(e),
            );
            if (prechargedSelection) {
                setAnswers((prevAnswers) => ({
                    ...prevAnswers,
                    [`FILENAME_LIST_${indexField}`]: prechargedSelection,
                }));
            }
        }
    }, [oldFile, code, indexField, action.nomenclature, setAnswers]);

    const [currentGED, setCurrentGED] = useState("-");

    useEffect(() => {
        if (code === "FILENAME_GED" && projectToUpload._id) {
            const getGEDDatas = async () => {
                const parents = await getProjectparentsList(
                    projectToUpload._id,
                );

                if (parents.length > 0) {
                    const rootProject = parents.find(
                        (e) => e.parentProject === undefined,
                    );
                    setCurrentGED(rootProject.indexGED);
                } else {
                    const resFolder = await axios.get(
                        apiURL.getProjectById + projectToUpload._id,
                    );

                    if (resFolder?.data) setCurrentGED(resFolder.data.indexGED);
                }
            };

            getGEDDatas();
        }
    }, [projectToUpload._id, code]);

    // Handle different field types with respective onChange events
    const dateref = useRef(null);
    if (code === "FILENAME_DATE") {
        return <Input value={moment().format("YYYY-MM-DD")} disabled />;
    }
    if (code === "FILENAME_DATE_SELECT") {
        return (
            <div
                className="relative"
                onClick={() => {
                    dateref.current.showPicker(); // Triggers the date input on click
                }}
            >
                <Input
                    value={
                        getFieldValue(`FILENAME_DATE_SELECT_${indexField}`)
                            ? getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(0, 4) +
                              "-" +
                              getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(4, 6) +
                              "-" +
                              getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(6)
                            : new Date().toISOString().split("T")[0]
                    }
                />
                <input
                    ref={dateref}
                    type="date"
                    className="absolute opacity-0 left-0 bottom-0"
                    value={
                        getFieldValue(`FILENAME_DATE_SELECT_${indexField}`)
                            ? getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(0, 4) +
                              "-" +
                              getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(4, 6) +
                              "-" +
                              getFieldValue(
                                  `FILENAME_DATE_SELECT_${indexField}`,
                              ).slice(6)
                            : new Date().toISOString().split("T")[0]
                    }
                    onChange={(e) => {
                        setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            [`FILENAME_DATE_SELECT_${indexField}`]:
                                e.target.value.replace(/-/g, ""),
                        }));
                    }}
                />
            </div>
        );
    } else if (code === "FILENAME_INDEX_ALPHA") {
        let letter = "";
        let index = versions.length;
        if (index === 0) letter = "0";
        else {
            while (index > 0) {
                index--;
                letter = String.fromCharCode((index % 26) + 65) + letter;
                index = Math.floor(index / 26);
            }
        }
        return (
            <Input
                value={
                    getFieldValue(`FILENAME_INDEX_ALPHA_${indexField}`) ||
                    letter
                }
                disabled // No onChange needed as this is a calculated value
            />
        );
    } else if (code === "FILENAME_INDEX_NUMBER") {
        return (
            <Input
                value={
                    getFieldValue(
                        `FILENAME_INDEX_NUMBER_${indexField}`,
                    )?.padStart(4, "0") ||
                    versions.length.toString().padStart(4, "0")
                }
                disabled // No onChange needed as this is a calculated value
            />
        );
    } else if (code === "FILENAME_LIST") {
        return (
            <Select
                value={
                    getFieldValue(`FILENAME_LIST_${indexField}`) || undefined
                }
                options={action.nomenclature.map((value) => ({
                    value: value,
                    label: value,
                }))}
                onChange={(e) => {
                    setAnswers((prevAnswers) => ({
                        ...prevAnswers,
                        [`FILENAME_LIST_${indexField}`]: e,
                    }));
                }}
                placeholder={t("choisissezUneValeur")}
            />
        );
    } else if (code === "FILENAME_FOLDER") {
        return <Input value={projectToUpload.title} disabled />;
    } else if (code === "FILENAME_FILE") {
        return (
            <Input
                value={getFieldValue(`FILENAME_FILE_${indexField}`) || fileName}
                onChange={(e) => {
                    setAnswers((prevAnswers) => ({
                        ...prevAnswers,
                        [`FILENAME_FILE_${indexField}`]: e.target.value,
                    }));
                }}
                placeholder={t("saisissezUneValeur")}
            />
        );
    } else if (code === "FILENAME_GED") {
        return (
            <Input
                value={
                    currentGED === "-"
                        ? currentGED
                        : currentGED.toString().padStart(6, "0")
                }
                disabled // No onChange needed as this is a calculated value
            />
        );
    } else if (code === "FILENAME_SET") {
        return (
            <Input
                value={getFieldValue(`FILENAME_SET_${indexField}`)}
                onChange={(e) => {
                    if (!e.target.value.includes("_")) {
                        setAnswers((prevAnswers) => ({
                            ...prevAnswers,
                            [`FILENAME_SET_${indexField}`]: e.target.value,
                        }));
                    }
                }}
                placeholder={t("saisissezUneValeur")}
            />
        );
    } else if (code === "FILENAME_CUSTOM") {
        return (
            <Input
                disabled
                value={
                    getFieldValue(`FILENAME_CUSTOM_${indexField}`) ||
                    action.nomenclature
                }
                onChange={(e) =>
                    setAnswers((prevAnswers) => ({
                        ...prevAnswers,
                        [`FILENAME_CUSTOM_${indexField}`]: e.target.value,
                    }))
                }
            />
        );
    }
};

export default CompleteNomenclature;
