import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { Card, Empty, Flex, Input } from "antd";

export default function ModalSelectForm({ listTemplates, onSelect }) {
    const { t } = useTranslation();
    const [searchValue, setSearchValue] = useState();

    const filteredList = useMemo(
        () =>
            listTemplates.filter(
                (template) =>
                    !searchValue ||
                    template?.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
                    template?.description?.toLowerCase().includes(searchValue.toLowerCase()),
            ),
        [listTemplates, searchValue],
    );

    return (
        <Flex vertical gap="large">
            <Input.Search
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={t("rechercher")}
                size="large"
            />
            <Flex
                vertical
                gap="small"
                style={{
                    height: "50vh",
                    overflowY: "auto",
                    paddingRight: 14,
                    paddingBottom: 30,
                    paddingTop: 10,
                }}>
                {filteredList?.length ? (
                    filteredList.map((template) => (
                        <Card
                            onClick={() => onSelect(template)}
                            size="small"
                            hoverable
                            bordered={false}
                            key={template._id}>
                            <Flex
                                style={{
                                    cursor: "pointer",
                                }}
                                gap="small">
                                <MaterialIcons size="xl" name="description" />
                                <Flex vertical>
                                    <Text bold>{template.title}</Text>
                                    <Text
                                        paragraph
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            margin: 0,
                                        }}
                                        size="sm"
                                        type="secondary">
                                        {template.description}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Card>
                    ))
                ) : (
                    <Empty />
                )}
            </Flex>
        </Flex>
    );
}
