import { useDroppable } from "@dnd-kit/core";
import {
    SortableContext,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { useMemo } from "react";

import RenderFieldItem from "./RenderFieldItem";
import RenderFormItem from "./RenderFormItem";

import { Flex, Form } from "antd";

export default function ColumnForm({ previewsField, sortableItems }) {
    const { setNodeRef } = useDroppable({
        id: "form",
    });

    const items = useMemo(() => {
        if (previewsField) return [previewsField._id, ...sortableItems];
        return sortableItems;
    }, [sortableItems, previewsField]);

    return (
        <SortableContext
            id={"form"}
            items={items}
            strategy={verticalListSortingStrategy}
        >
            <Form.List name="fields">
                {(fields, { add, remove }, { errors }) => (
                    <Flex
                        vertical
                        gap={16}
                        ref={setNodeRef}
                        style={{
                            paddingBottom: "20vh",
                        }}
                    >
                        {previewsField && (
                            <RenderFieldItem
                                _id={previewsField._id}
                                type={previewsField.code}
                            />
                        )}
                        {fields.map(({ key, name }, index) => (
                            <RenderFormItem
                                key={index}
                                index={index}
                                add={add}
                                remove={remove}
                            />
                        ))}
                    </Flex>
                )}
            </Form.List>
        </SortableContext>
    );
}
