import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import ButtonFilters from "components/filters/ButtonFilters";
import Button from "components/input/Button";
import TextField from "components/input/TextField";

import { TableInlineContext } from "./Table";

const TableHeader = ({ setClientFilterSearch, hasFilters }) => {
    const { fields } = useContext(TableInlineContext);

    return (
        <thead className="sticky top-0 bg-slate-50 z-[5]">
            {hasFilters && (
                <tr>
                    <td
                        className="border text-xs whitespace-nowrap text-left  px-4"
                        colSpan="100%"
                    >
                        <ButtonFilters />
                    </td>
                </tr>
            )}
            <tr>
                <th className="border  text-xs whitespace-nowrap text-left w-[36px] min-w-[36px]"></th>
                {fields.map((field, index) => (
                    <RenderCellHeader
                        setClientFilterSearch={setClientFilterSearch}
                        field={field}
                        key={index}
                        isLast={index === fields.length - 1 && !field.width}
                    />
                ))}
            </tr>
        </thead>
    );
};

const RenderCellHeader = ({ field, isLast, setClientFilterSearch }) => {
    const { t } = useTranslation();

    const [searchInput, setSearchInput] = useState("");
    const [openSearch, setOpenSearch] = useState(false);

    const onFilterTable = (value) => {
        setSearchInput(value);
        setClientFilterSearch((e) => ({
            ...e,
            [field.key]: value,
        }));
    };

    return (
        <th
            key={field.key}
            className="border p-3 text-xs whitespace-nowrap text-left truncate relative align-baseline"
            style={{
                maxWidth: isLast ? "auto" : field.width,
                width: isLast ? "auto" : field.width,
                minWidth: "150px",
            }}
        >
            <div className="flex justify-between">
                <span>{field.label}</span>
                {field.isSearchable && (
                    <div>
                        <Button
                            withoutBorder
                            text={
                                <i
                                    className="material-icons notranslate align-middle font-bold"
                                    style={{ fontSize: "1rem" }}
                                >
                                    {openSearch ? "close" : "search"}
                                </i>
                            }
                            removePadding
                            isReversed
                            isBgTransparent
                            onClick={() => {
                                if (openSearch) {
                                    setSearchInput("");
                                    setClientFilterSearch((e) => ({
                                        ...e,
                                        [field.key]: "",
                                    }));
                                }
                                setOpenSearch((e) => !e);
                            }}
                        />
                    </div>
                )}
            </div>
            {openSearch && (
                <div className="mt-2">
                    <TextField
                        placeholder={t("rechercher")}
                        value={searchInput}
                        onChange={(e) => onFilterTable(e.target.value)}
                    />
                </div>
            )}
        </th>
    );
};

export default TableHeader;
