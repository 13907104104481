import axios from "axios";
import { useAppStore } from "providers/ProviderApp";

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { capitalize } from "helpers/string";
import { getAdminTeam } from "helpers/timesheet";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import DocumentReader from "components/documents/DocumentReader";
import { DriveToolsContext } from "components/drive/Drive";
import ButtonFilters from "components/filters/ButtonFilters";

import PhotosDataGrid from "./PhotosDataGrid";
import PhotosDataTable from "./PhotosDataTable";

import { Badge, Flex, Select, Typography, theme } from "antd";

const Photos = ({ selectedFiles, setSelectedFiles }) => {
    const { pathname } = useLocation();
    const { userProfile, toolsData, setToolsData } = useContext(DriveToolsContext);
    const { viewMode } = useAppStore();
    const isListMode = viewMode === "list";

    const [renderDocumentFile, setRenderDocumentFile] = useState();

    useEffect(() => {
        if (viewMode === "grid") setSorter({});
    }, [viewMode]);

    useEffect(() => {
        getAdminTeam().then((res) => {
            setToolsData((e) => ({
                ...e,
                filtersStore: {
                    users: res.sort((a, b) => {
                        // Compare by firstname first
                        if (a.firstname < b.firstname) return -1;
                        if (a.firstname > b.firstname) return 1;
                        // If firstnames are equal, compare by lastname
                        if (a.lastname < b.lastname) return -1;
                        if (a.lastname > b.lastname) return 1;
                        return 0;
                    }),
                },
            }));
        });
    }, [setToolsData]);

    const [loading, setLoading] = useState(false);
    const [containerLoading, setContainerLoading] = useState(true);
    const [data, setData] = useState([]);
    const [sorter, setSorter] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const limitNbFiles = 48;

    const fetchData = useCallback(
        async ({ page, reset = false }) => {
            if (!loading) {
                if (reset) setContainerLoading(true);
                setLoading(true);
                try {
                    const response = await axios.post(apiURL.getImagesFromFilters, {
                        page,
                        sorter: sorter,
                        filters: {
                            dateRange: toolsData.filters?.dateRange,
                            projects: toolsData.filters?.folders?.map((e) => e._id) || [],
                            users: toolsData.filters?.users?.map((e) => e._id) || [],
                        },
                    });

                    if (reset) setData(response.data);
                    else setData((prev) => [...prev, ...response.data]);
                    setHasMore(response.data.length === limitNbFiles);
                } catch (error) {
                } finally {
                    setLoading(false);
                    setContainerLoading(false);
                }
            }
        },
        [toolsData.filters, loading, sorter],
    );

    const handleScroll = (e) => {
        if (
            parseInt(e.target.scrollHeight - e.target.scrollTop) <= e.target.clientHeight + 100 &&
            !loading &&
            hasMore
        ) {
            const nextPage = page + 1;
            setPage(nextPage);
            fetchData({ page: nextPage });
        }
    };

    const changeSorter = (newPagination, newFilters, newSorter) => {
        setSorter(newSorter);
    };

    useEffect(() => {
        if (toolsData.filters.location === pathname) {
            setPage(0);
            fetchData({ page: 0, reset: true });
        }
    }, [toolsData.filters, sorter, pathname]);

    return (
        <Flex
            vertical
            gap={12}
            style={{
                paddingBottom: "5vh",
            }}>
            <Flex align="center">
                <ButtonFilters />
                <DateFilter />
            </Flex>

            {renderDocumentFile && (
                <DocumentReader
                    fileId={renderDocumentFile._id}
                    closeReader={() => setRenderDocumentFile(null)}
                    profile={userProfile}
                    refreshAction={() => {}}
                />
            )}
            {isListMode ? (
                <PhotosDataTable
                    data={data}
                    handleScroll={handleScroll}
                    containerLoading={containerLoading}
                    changeSorter={changeSorter}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setRenderDocumentFile={setRenderDocumentFile}
                    hasMore={hasMore}
                />
            ) : (
                <PhotosDataGrid
                    data={data}
                    handleScroll={handleScroll}
                    containerLoading={containerLoading}
                    changeSorter={changeSorter}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    setRenderDocumentFile={setRenderDocumentFile}
                    hasMore={hasMore}
                />
            )}
        </Flex>
    );
};

const DateFilter = () => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { toolsData, setToolsData } = useContext(DriveToolsContext);
    return (
        <Badge dot={toolsData.filters.dateRange !== "all" ? 1 : 0} color={token.colorPrimary}>
            <Select
                onChange={(value) => {
                    setToolsData((e) => ({
                        ...e,
                        filters: {
                            ...e.filters,
                            dateRange: !value ? "all" : value,
                        },
                    }));
                }}
                allowClear={toolsData.filters?.dateRange !== "all"}
                variant="borderless"
                value={toolsData.filters?.dateRange || "all"}
                labelRender={({ value, label }) => {
                    return (
                        <Flex style={{ minWidth: 200 }} align="center" gap={8}>
                            <MaterialIcons name="swap_vert" />
                            <Typography.Text>
                                {value === "all" ? t("dateDeTelechargement") : label || value}
                            </Typography.Text>
                        </Flex>
                    );
                }}
                options={[
                    { value: "all", label: t("toutAfficher") },
                    { value: "byDay", label: capitalize(t("aujourdhui")) },
                    { value: "byWeek", label: t("cetteSemaine") },
                    { value: "byMonth", label: t("ceMoisCi") },
                    { value: "byWeek-before", label: t("laSemaineDerniere") },
                    { value: "byMonth-before", label: t("leMoisDernier") },
                ]}
            />
        </Badge>
    );
};

export default Photos;
