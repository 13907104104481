import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { fromDateToDayMonthYearString } from "helpers/date";
import { capitalize } from "helpers/string";
import { getActualSeason } from "helpers/timesheet";

import Text from "components/common/Text";

import { Divider, Flex, Select, Typography, theme } from "antd";

const SelectDate = ({
    isPreview = false,
    canSelectDate,
    timeEntry,
    setTimeEntry,
    readOnly,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const { scheduleConfiguration } = useProfileStore();
    const actualSeason = getActualSeason(scheduleConfiguration);

    const updateDate = (event) => {
        const splittedNewDate = event.target.value.split("-");
        const newDateObj = {
            year: splittedNewDate[0],
            month: splittedNewDate[1] - 1, // Month is 0-indexed
            date: splittedNewDate[2],
        };

        if (!readOnly)
            setTimeEntry((e) => ({
                ...e,
                startDate: moment(e.startDate).set(newDateObj).toISOString(),
                endDate: moment(e.endDate).set(newDateObj).toISOString(),
            }));
    };

    const updateStartTime = (event, checkEnd = false) => {
        const splittedNewTime = event.target.value.split(":");
        const newTimeObj = {
            hour: splittedNewTime[0],
            minute: splittedNewTime[1],
        };

        if (checkEnd) {
            if (!readOnly)
                setTimeEntry((e) => ({
                    ...e,
                    startDate: moment(e.startDate)
                        .set(newTimeObj)
                        .toISOString(),
                    endDate: moment(e.startDate)
                        .set(newTimeObj)
                        .isSameOrAfter(e.endDate)
                        ? moment(e.startDate)
                              .set(newTimeObj)
                              .add(15, "minute")
                              .toISOString()
                        : e.endDate,
                }));
        } else {
            if (!readOnly)
                setTimeEntry((e) => ({
                    ...e,
                    startDate: moment(e.startDate)
                        .set(newTimeObj)
                        .toISOString(),
                }));
        }
    };

    const updateEndTime = (event) => {
        const time = timeOptionsAfter.find((e) => e.value === event);
        if (!readOnly)
            setTimeEntry((e) => ({
                ...e,
                endDate: time.data,
            }));
    };

    let dataList = [];
    for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 60; m += 15) {
            if (h === 0 && m === 0) continue;
            const hour = h.toString().padStart(2, "0");
            const minute = m.toString().padStart(2, "0");
            dataList.push({
                label: `${hour}:${minute}`,
                value: `${hour}:${minute}`,
                // disabled: true/false,
            });
            if (`${hour}:${minute}` === actualSeason.nightTimeStart)
                dataList.push({
                    label: (
                        <Divider orientation="left" plain>
                            <Text bold>Heure de jour</Text>
                        </Divider>
                    ),
                    options: [],
                });
            if (`${hour}:${minute}` === actualSeason.nightTimeEnd)
                dataList.push({
                    label: (
                        <Divider orientation="left" plain>
                            <Text bold>Heure de nuit</Text>
                        </Divider>
                    ),
                    options: [],
                });
        }
    }

    const timeOptionsAfter = useMemo(() => {
        const startTime = moment(timeEntry.startDate);
        const endTime = moment(timeEntry.startDate).add(18, "hours");
        const options = [];

        while (startTime.isBefore(endTime)) {
            options.push({
                label: startTime.format("HH:mm"),
                value: startTime.format("HH:mm"),
                data: startTime.toISOString(),
            });
            startTime.add(15, "minutes");

            if (startTime.format("HH:mm") === actualSeason.nightTimeStart)
                options.push({
                    label: (
                        <Divider orientation="left" plain>
                            <Text bold>Heure de jour</Text>
                        </Divider>
                    ),
                    options: [],
                });
            if (startTime.format("HH:mm") === actualSeason.nightTimeEnd)
                options.push({
                    label: (
                        <Divider orientation="left" plain>
                            <Text bold>Heure de nuit</Text>
                        </Divider>
                    ),
                    options: [],
                });

            if (startTime.format("HH:mm") === "00:00")
                options.push({
                    label: (
                        <Divider orientation="left" plain>
                            <Text bold>
                                {capitalize(
                                    fromDateToDayMonthYearString(startTime, [
                                        "yearNumber",
                                    ]),
                                )}
                            </Text>
                        </Divider>
                    ),
                    options: [],
                });
        }

        return options;
    }, [timeEntry.startDate]);

    return (
        <Flex vertical gap="small">
            {/* {!canSelectDate && (
                <DatePicker
                    style={{
                        width: "100%",
                        height: 50,
                    }}
                    allowClear={false}
                    prefix={<b>{t("date")}</b>}
                    value={moment(timeEntry.startDate)}
                    onChange={(e) => updateDate(e)}
                    format="dddd, Do MMMM"
                    placement="bottomRight"
                /> 
            )} */}
            {canSelectDate && (
                <div className="relative flex justify-between px-4 py-3 rounded-xl border-2 border-black w-full hover:text-black">
                    <span className="font-bold">{t("date")}</span>
                    <span className="font-bold capitalize">
                        {fromDateToDayMonthYearString(timeEntry.startDate, [
                            "yearNumber",
                        ])}
                    </span>
                    <input
                        value={moment(timeEntry.startDate).format("YYYY-MM-DD")}
                        type="date"
                        className="opacity-0  h-full w-full top-0 left-0 absolute cursor-pointer"
                        onChange={updateDate}
                        onClick={(e) => {
                            if (!isPreview) e.target.showPicker();
                        }}
                    />
                </div>
            )}

            <Flex align="center" gap="small" className="pl-4">
                <Text bold>{t("debut")}</Text>
                <Select
                    style={{
                        marginLeft: "auto",
                        maxWidth: 100,
                        width: "50%",
                    }}
                    options={[
                        {
                            label: "00:00",
                            value: "00:00",
                        },
                        ...dataList,
                    ]}
                    disabled={isPreview || readOnly}
                    className="border-2 border-black text-center hover:bg-slate-100 rounded-xl"
                    size="large"
                    variant="borderless"
                    value={moment(timeEntry.startDate).format("HH:mm")}
                    onChange={(e) =>
                        updateStartTime({ target: { value: e } }, true)
                    }
                    suffixIcon={null}
                    virtual={false}
                    popupMatchSelectWidth={300}
                    placement="bottomRight"
                    optionRender={(option) => (
                        <div
                            style={{
                                padding: `${token.padding / 4}px ${token.padding}px`,
                            }}
                        >
                            <Typography.Text
                                style={{
                                    fontSize: 18,
                                }}
                            >
                                {option.label}
                            </Typography.Text>
                        </div>
                    )}
                />
            </Flex>

            <Flex align="center" gap="small" className="pl-4">
                <Text bold>{t("fin")}</Text>
                <Text
                    style={{
                        marginLeft: "auto",
                    }}
                    size="small"
                    type="secondary"
                >
                    ({" "}
                    {capitalize(
                        fromDateToDayMonthYearString(timeEntry.endDate, [
                            "yearNumber",
                        ]),
                    )}{" "}
                    )
                </Text>
                <Select
                    style={{
                        width: "50%",
                        maxWidth: 100,
                    }}
                    options={timeOptionsAfter}
                    className="border-2 border-red-500 text-center hover:bg-slate-100 rounded-xl"
                    size="large"
                    disabled={isPreview || readOnly}
                    status="error"
                    variant="borderless"
                    value={moment(timeEntry.endDate).format("HH:mm")}
                    onChange={(e) => updateEndTime(e)}
                    suffixIcon={null}
                    virtual={false}
                    popupMatchSelectWidth={300}
                    placement="bottomRight"
                    optionRender={(option) => (
                        <div
                            style={{
                                padding: `${token.padding / 4}px ${token.padding}px`,
                            }}
                        >
                            <Typography.Text
                                style={{
                                    fontSize: 18,
                                }}
                            >
                                {option.label}
                            </Typography.Text>
                        </div>
                    )}
                />
            </Flex>
        </Flex>
    );
};

export default SelectDate;
