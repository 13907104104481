import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { getProjectFileSize, getProjectSize } from "helpers/size";

import Checkbox from "components/input/Checkbox";
import ConfirmationModal from "components/modal/ConfirmationModal";

import FileDetailsLine from "./FileDetailsLine";

const FilesDetailsTable = ({
    items,
    profile,
    refreshAction,
    onItemClick,
    currentItem,
    userRoles,
    isDnd,
    addSelectedFile,
    selectedFiles,
    setSelectedFiles,
    showLocation,
    showLastOpenDate,
}) => {
    const { t } = useTranslation();

    const [confirmationModal, setConfirmationModal] = useState(null);
    const { permissionsTools } = useProfileStore();

    const [sortedItems, setSortedItems] = useState(items);
    const [sortConfig, setSortConfig] = useState(null);
    const [isAllSelected, setAllSelected] = useState(false);

    const [visibleVersionsFile, setVisibleVersionsFile] = useState(null);

    useMemo(() => {
        const sortItems = async () => {
            let sortableItems = JSON.parse(JSON.stringify(items));

            if (sortConfig !== null) {
                await Promise.all(
                    sortableItems.map(async (a) => {
                        if (sortConfig.key === "size") {
                            const getFileSize = async (item) => {
                                let resSize;

                                if (!item.files)
                                    resSize = await getProjectFileSize(item);
                                else resSize = await getProjectSize(item._id);

                                return resSize;
                            };

                            let sortSize = await getFileSize(
                                a.title ? a : a.file,
                            );

                            if (a.title) a.sortSize = sortSize;
                            else a.file.sortSize = sortSize;
                        }
                    }),
                );

                // Separate folders and files
                const folders = sortableItems.filter((item) => item.files);
                const files = sortableItems.filter((item) => !item.files);

                const sortFunction = (a, b) => {
                    a = a.title ? a : a.file;
                    b = b.title ? b : b.file;

                    if (sortConfig.key === "author") {
                        let aItem = a.author
                            ? (
                                  a.author.firstname + a.author.lastname
                              ).toLowerCase()
                            : "";
                        let bItem = b.author
                            ? (
                                  b.author.firstname + b.author.lastname
                              ).toLowerCase()
                            : "";

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "size") {
                        let aItem = a.sortSize ? parseFloat(a.sortSize) : 0;
                        let bItem = b.sortSize ? parseFloat(b.sortSize) : 0;

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "updatedDate") {
                        let aItem = a.updatedDate
                            ? a.updatedDate
                            : a.creationDate;
                        let bItem = b.updatedDate
                            ? b.updatedDate
                            : b.creationDate;

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "visas") {
                        let aItem = a[sortConfig.key].reduce(
                            (accumulator, currentValue) => {
                                return accumulator + currentValue.visa.title;
                            },
                            "",
                        );
                        let bItem = b[sortConfig.key].reduce(
                            (accumulator, currentValue) => {
                                return accumulator + currentValue.visa.title;
                            },
                            "",
                        );

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else {
                        return compareValues(
                            a[sortConfig.key].toLowerCase(),
                            b[sortConfig.key].toLowerCase(),
                            sortConfig.direction,
                        );
                    }
                };

                // Sort folders and files independently
                folders.sort(sortFunction);
                files.sort(sortFunction);

                sortableItems = [...folders, ...files];
            }

            setSortedItems(sortableItems);
        };

        sortItems();
    }, [items, sortConfig]);

    const compareValues = (a, b, direction) => {
        if (a < b) {
            return direction === "ascending" ? 1 : -1;
        }
        if (a > b) {
            return direction === "ascending" ? -1 : 1;
        }
        return 0;
    };

    const requestSort = (key) => {
        if (!sortConfig || (sortConfig && sortConfig.key !== key))
            setSortConfig({ key, direction: "descending" });
        else if (sortConfig && sortConfig.direction === "descending")
            setSortConfig({ key, direction: "ascending" });
        else if (sortConfig && sortConfig.direction === "ascending") {
            setSortConfig(null);
        }
    };

    const handleVisibleVersionsFile = (fileId) => {
        if (visibleVersionsFile === fileId) setVisibleVersionsFile(null);
        else setVisibleVersionsFile(fileId);
    };

    const selectAll = (e) => {
        let checked = e.target.checked;
        setAllSelected(checked);

        if (checked) setSelectedFiles(sortedItems);
        else setSelectedFiles([]);
    };

    return (
        <Fragment>
            <table
                className="table-auto text-left mt-8 w-full max-w-full text-sm"
                style={{
                    position: "relative",
                    zIndex: 1,
                }}
            >
                <thead>
                    <tr>
                        {setSelectedFiles && (
                            <th className="p-2 w-[40px]">
                                <Checkbox
                                    onChange={selectAll}
                                    value={isAllSelected}
                                    removeMarging={true}
                                />
                            </th>
                        )}

                        <th className="p-2 whitespace-nowrap">
                            <button
                                type="button"
                                className="w-full text-left"
                                onClick={(e) => requestSort("title")}
                            >
                                {t("nom")}
                                <i
                                    className="material-icons notranslate inline ml-1 align-middle"
                                    style={{ fontSize: "1rem" }}
                                >
                                    {!sortConfig ||
                                    (sortConfig && sortConfig.key !== "title")
                                        ? "unfold_more"
                                        : sortConfig.direction === "ascending"
                                          ? "expand_less"
                                          : sortConfig.direction ===
                                                "descending" && "expand_more"}
                                </i>
                            </button>
                        </th>
                        <th className="hidden 2xl:table-cell p-2 whitespace-nowrap">
                            <button
                                type="button"
                                className="w-full text-left"
                                onClick={(e) => requestSort("author")}
                            >
                                {t("proprietaire")}
                                <i
                                    className="material-icons notranslate inline ml-1 align-middle"
                                    style={{ fontSize: "1rem" }}
                                >
                                    {!sortConfig ||
                                    (sortConfig && sortConfig.key !== "author")
                                        ? "unfold_more"
                                        : sortConfig.direction === "ascending"
                                          ? "expand_less"
                                          : sortConfig.direction ===
                                                "descending" && "expand_more"}
                                </i>
                            </button>
                        </th>
                        {showLastOpenDate ? (
                            <th className="hidden 2xl:table-cell p-2 whitespace-nowrap">
                                <button
                                    type="button"
                                    className="w-full text-left"
                                    onClick={(e) => requestSort("updatedDate")}
                                >
                                    {t("derniereConsultation")}
                                    <i
                                        className="material-icons notranslate inline ml-1 align-middle"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        {!sortConfig ||
                                        (sortConfig &&
                                            sortConfig.key !== "updatedDate")
                                            ? "unfold_more"
                                            : sortConfig.direction ===
                                                "ascending"
                                              ? "expand_less"
                                              : sortConfig.direction ===
                                                    "descending" &&
                                                "expand_more"}
                                    </i>
                                </button>
                            </th>
                        ) : (
                            <th className="hidden 2xl:table-cell p-2 whitespace-nowrap">
                                <button
                                    type="button"
                                    className="w-full text-left"
                                    onClick={(e) => requestSort("updatedDate")}
                                >
                                    {t("derniereModification")}
                                    <i
                                        className="material-icons notranslate inline ml-1 align-middle"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        {!sortConfig ||
                                        (sortConfig &&
                                            sortConfig.key !== "updatedDate")
                                            ? "unfold_more"
                                            : sortConfig.direction ===
                                                "ascending"
                                              ? "expand_less"
                                              : sortConfig.direction ===
                                                    "descending" &&
                                                "expand_more"}
                                    </i>
                                </button>
                            </th>
                        )}

                        {showLocation ? (
                            <th className="hidden xl:table-cell p-2 whitespace-nowrap">
                                {t("emplacement")}
                            </th>
                        ) : (
                            <Fragment>
                                <th className="hidden xl:table-cell p-2 whitespace-nowrap">
                                    <button
                                        type="button"
                                        className="w-full text-left"
                                        onClick={(e) => requestSort("size")}
                                    >
                                        {t("taille")}
                                        <i
                                            className="material-icons notranslate inline ml-1 align-middle"
                                            style={{ fontSize: "1rem" }}
                                        >
                                            {!sortConfig ||
                                            (sortConfig &&
                                                sortConfig.key !== "size")
                                                ? "unfold_more"
                                                : sortConfig.direction ===
                                                    "ascending"
                                                  ? "expand_less"
                                                  : sortConfig.direction ===
                                                        "descending" &&
                                                    "expand_more"}
                                        </i>
                                    </button>
                                </th>
                                {permissionsTools?.visas?.canView && (
                                    <th className="hidden xl:table-cell p-2 whitespace-nowrap">
                                        <button
                                            type="button"
                                            className="w-full text-left"
                                            onClick={(e) =>
                                                requestSort("visas")
                                            }
                                        >
                                            {t("visas")}
                                            <i
                                                className="material-icons notranslate inline ml-1 align-middle"
                                                style={{ fontSize: "1rem" }}
                                            >
                                                {!sortConfig ||
                                                (sortConfig &&
                                                    sortConfig.key !== "visas")
                                                    ? "unfold_more"
                                                    : sortConfig.direction ===
                                                        "ascending"
                                                      ? "expand_less"
                                                      : sortConfig.direction ===
                                                            "descending" &&
                                                        "expand_more"}
                                            </i>
                                        </button>
                                    </th>
                                )}
                            </Fragment>
                        )}

                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedItems
                        .map((e) =>
                            e.isOldVersion
                                ? {
                                      ...e,
                                      isOldVersion: false,
                                      versions: [],
                                      rowKey: e._id + "-ogfile",
                                  }
                                : { ...e, rowKey: e._id },
                        )
                        // Opens the versions of currentItem
                        .map((e) =>
                            e.versions?.length && visibleVersionsFile === e._id
                                ? [
                                      e,
                                      ...e.versions
                                          .filter((k) => k._id !== e._id)
                                          .map((e) => ({
                                              ...e,
                                              rowKey: e._id,
                                          })),
                                  ]
                                : e,
                        )
                        .flat()
                        .map((item) => {
                            return (
                                <FileDetailsLine
                                    key={item.rowKey}
                                    item={item.title ? item : item.file}
                                    isFolder={item.files ? true : false}
                                    profile={profile}
                                    refreshAction={refreshAction}
                                    onItemClick={onItemClick}
                                    currentItem={currentItem}
                                    setConfirmationModal={setConfirmationModal}
                                    userRoles={userRoles}
                                    isDnd={isDnd}
                                    addSelectedFile={addSelectedFile}
                                    selectedFiles={selectedFiles}
                                    handleVisibleVersionsFile={
                                        handleVisibleVersionsFile
                                    }
                                    showLocation={showLocation}
                                    showLastOpenDate={showLastOpenDate}
                                />
                            );
                        })}
                </tbody>
            </table>

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                    successText={confirmationModal.successText}
                    errorText={confirmationModal.errorText}
                    customWidth={confirmationModal.customWidth}
                />
            )}
        </Fragment>
    );
};

export default FilesDetailsTable;
