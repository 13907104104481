import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PasswordFieldValidation = ({ value }) => {
    const { t } = useTranslation();

    const isUpperCase = (string) => /^[A-Z]*$/.test(string);

    const [validations, setValidations] = useState({
        length: false,
        uppercase: false,
        number: false,
    });

    useEffect(() => {
        handleValidation();
    }, [value]);

    const handleValidation = () => {
        let updatedValidations = {};

        updatedValidations.length = value.trim().length >= 8 ? true : false;

        var nbNumeric = 0;
        var nbUppercase = 0;

        for (var i = 0; i < value.length; i++) {
            if (!isNaN(value[i])) nbNumeric++;

            if (isUpperCase(value[i])) nbUppercase++;
        }

        updatedValidations.number = nbNumeric > 0 ? true : false;
        updatedValidations.uppercase = nbUppercase > 0 ? true : false;

        setValidations(updatedValidations);
    };

    return (
        <div className="text-xs text-slate-300 mt-4">
            <div className={validations.length && "text-green-500"}>
                {t("huitCaracteresMinimum")}
            </div>
            <div className={validations.uppercase && "text-green-500"}>
                {t("unCaractereMajuscule")}
            </div>
            <div className={validations.number && "text-green-500"}>
                {t("unChiffre")}
            </div>
        </div>
    );
};

export default PasswordFieldValidation;
