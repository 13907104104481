import { Button, ConfigProvider, theme } from "antd";

export default function SuccessButton(props) {
    const { token } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: token.colorSuccess,
                },
            }}
        >
            <Button {...props} />
        </ConfigProvider>
    );
}
