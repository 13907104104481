import axios from "axios";
import PropTypes from "prop-types";
import { useAppStore } from "providers/ProviderApp";
import { useDriveStore } from "providers/ProviderDrive";
import { useProfileStore } from "providers/ProviderProfile";
import { Resizable } from "re-resizable";

import {
    Fragment,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";

import {
    fileUploadProcess,
    fromIfcZipToIfc,
    getFileExtension,
    isLimitedDriveSize,
} from "helpers/file";
import { debounce } from "helpers/input";
import { getProjectparentsList } from "helpers/project";
import { generateRandomToken } from "helpers/token";
import { getLastParameterFromURL } from "helpers/url";
import { getMemberRoles } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import Automations from "components/automations/Automations";
import DriveBackgroundCard from "components/background/DriveBackgroundCard";
import DocumentMobileBottomContent from "components/documents/DocumentMobileBottomContent";
import FiltersSidePanel from "components/filters/FiltersSidePanel";
import FormTemplateHeader from "components/forms/FormTemplateHeader";
import FormView from "components/forms/FormView";
import IllustratedModalContent from "components/modal/IllustratedModalContent";
import InformationModal from "components/modal/InformationModal";
import Photos from "components/photos/Photos";
import PhotosUser from "components/photos/PhotosUser";
import PlansView, { TitlePlanView } from "components/plans/PlansView";
import Portal from "components/portal/Portal";
import ViewProject, { TitleViewProject } from "components/project/ViewProject";
import Statistics from "components/statistics/Statistics";
import TimeSheetView from "components/timesheet/TimesheetViewContext";
import PreviewSidePanel from "components/timesheet/viewAdmin/PreviewSidePanel";
import Spinner from "components/utils/Spinner";
import Visas from "components/visa/Visas";

import { getCurrentProfile } from "actions/profile";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";
import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import DriveDashboard from "./DriveDashboard";
import DriveFilteredList from "./DriveFilteredList";
import DriveModeRender from "./DriveModeRender";
import DriveNav from "./DriveNav";
import DriveNomenclatureModal from "./DriveNomenclatureModal";
import DriveSameNameModal from "./DriveSameNameModal";
import DriveSideMenu from "./DriveSideMenu";
import DriveSidePanel from "./DriveSidePanel";
import NewActions from "./NewActions";

import { Card, Flex, Layout, theme } from "antd";

export const DriveToolsContext = createContext(null);

const Drive = ({
    profile: { profile, loading },
    getCurrentProfile,
    toolView = "",
}) => {
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");
    const landscapeMobileMedia = useMediaPredicate(
        "(max-height: " + 300 + "px)",
    );

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const { id, formId } = useParams();

    const [loader, setLoader] = useState(false);

    const [informationModal, setInformationModal] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const [currentItem, setCurrentItem] = useState(null);
    const [isOpenPanel, setIsOpenPanel] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    const [userRoles, setUserRoles] = useState({});

    const [isWorkerPortal, setIsWorkerPortal] = useState(null);

    const { toastAlert } = useContext(PopupContext);
    const { addModalData, removeModal } = useContext(ModalContext);
    const { successDriveSyncMessage, runNotification } =
        useContext(PopupContext);
    const { updateFileProgressIndicator, removeStream } = useContext(
        BackgroundActionsContext,
    );

    const { menuWidth, setMenuWidth } = useAppStore();
    const { permissionsTools, getCompleteProfile, customerConfig } =
        useProfileStore();
    const { getMyProjects } = useDriveStore();

    useEffect(() => {
        getCompleteProfile().then(() => {
            getMyProjects();
        });
    }, []);

    const [toolsData, setToolsData] = useState({
        timesheetAdmin: {
            projects: [],
            users: [],
            tasks: [],
            fields: [],
        },
        visas: {
            updatedVisa: null,
        },
        filters: {
            dateRange: "byMonth",
            pagination: new Date().toISOString().split("T")[0],
            users: [],
            projects: [],
            statuts: [],
            folders: [],
        },
        filtersStore: {},
        filtersOpen: false,
        markers: {
            isMarkerMode: false,
            addedMarker: null,
            commentsMarkers: [],
        },
        customerConfig: null,
        toolView,
        loaded: false,

        // OLD
        pagination: new Date().toISOString().split("T")[0],
    });

    // Manage permissions for tools
    const isTimesheetView =
        toolView === "timesheet" &&
        (permissionsTools.timesheetUser.canView ||
            permissionsTools.timesheetAdmin.canView);
    const isAutomationsView =
        toolView === "automations" && permissionsTools.automations.canView;
    const isVisasView = toolView === "visas" && permissionsTools.visas.canView;
    const isStatsView =
        toolView === "statistics" && customerConfig?.isLightViewer;
    const isPhotosView =
        toolView === "photos" &&
        (permissionsTools.photosUser.canView ||
            permissionsTools.photosAdmin.canView);
    const isPlansView =
        toolView === "plans" &&
        (permissionsTools.plansUser.canView ||
            permissionsTools.plansAdmin.canView);
    const isFormsView =
        toolView === "forms" &&
        (permissionsTools.formsUser.canView ||
            permissionsTools.formsAdmin.canView);
    const isFlowView = toolView === "flow";
    const isRecentsView = toolView === "recents";
    const isFavoritesView = toolView === "favorites";

    // Get permissions for viewing tools views
    useEffect(() => {
        if (customerConfig === null) {
            setIsWorkerPortal(false);
            setToolsData((e) => ({
                ...e,
                loaded: true,
            }));
            return;
        }

        const getToolsPermissions = async () => {
            if (profile?.user?.email) {
                setLoader(true);

                const {
                    data: { hasRoleAdmin, hasRoleWorker },
                } = await axios.get(apiURL.checkTimesheetPermissions);

                const timesheetAdminUsers =
                    customerConfig?.timesheet?.isActive &&
                    customerConfig.timesheet.adminUsers.map(
                        (adminUser) => adminUser.email,
                    );
                const timesheetWorkerUsers =
                    customerConfig?.timesheet?.isActive &&
                    customerConfig.timesheet.workerUsers.map(
                        (workerUser) => workerUser.email,
                    );

                const photosAdminUsers =
                    customerConfig?.photos?.isActive &&
                    customerConfig.photos.adminUsers.map(
                        (adminUser) => adminUser.email,
                    );

                const photosWorkerUsers =
                    customerConfig?.photos?.isActive &&
                    customerConfig.photos.workerUsers.map(
                        (workerUser) => workerUser.email,
                    );

                setToolsData((e) => ({
                    ...e,
                    customerConfig,
                    loaded: true,
                }));

                const isWorkerCondition =
                    hasRoleWorker &&
                    [
                        ...Constants.demoBimonoMails,
                        ...(timesheetWorkerUsers ? timesheetWorkerUsers : []),
                        ...(photosWorkerUsers ? photosWorkerUsers : []),
                    ].includes(profile?.user?.email);

                const isAdminCondition =
                    hasRoleAdmin &&
                    [
                        ...Constants.demoBimonoMails,
                        ...(timesheetAdminUsers ? timesheetAdminUsers : []),
                        ...(photosAdminUsers ? photosAdminUsers : []),
                    ].includes(profile?.user?.email);

                if (
                    isWorkerCondition &&
                    !isAdminCondition &&
                    (location.pathname === "/drive" ||
                        location.pathname === "/portal") &&
                    !location.state?.isPortalChoice
                ) {
                    setIsWorkerPortal(true);
                    localStorage.setItem("isWorker", true);
                    navigate("/portal");
                } else {
                    setIsWorkerPortal(false);
                    localStorage.removeItem("isWorker");
                    if (location.pathname === "/portal") navigate("/drive");
                }
                setLoader(false);
            }
        };
        getToolsPermissions();
    }, [profile?.user?.email, customerConfig]);

    useEffect(() => {
        if (
            location.state?.isPortalChoice &&
            isWorkerPortal &&
            location.pathname !== "/portal"
        )
            setIsWorkerPortal(false);
        else if (location.pathname === "/portal") setIsWorkerPortal(true);
    }, [location.pathname]);

    // Manage saved filters
    // TODO optimize for limit size localstorage usage
    const [initFiltersStorage, setInitFiltersStorage] = useState(false);
    useEffect(() => {
        setInitFiltersStorage(false);
        let savedFilters = localStorage.getItem("filters-" + location.pathname);
        if (savedFilters) {
            savedFilters = JSON.parse(savedFilters);
            setToolsData((e) => ({
                ...e,
                filters: savedFilters,
            }));
            setInitFiltersStorage(true);
        } else {
            setToolsData((e) => ({
                ...e,
                filters: {
                    dateRange: "byMonth",
                    pagination: new Date().toISOString().split("T")[0],
                    users: [],
                    projects: [],
                    statuts: [],
                    folders: [],
                    location: location.pathname,
                },
            }));
            setInitFiltersStorage(true);
        }
    }, [location]);

    useEffect(() => {
        if (initFiltersStorage)
            localStorage.setItem(
                "filters-" + location.pathname,
                JSON.stringify({
                    ...toolsData.filters,
                    location: location.pathname,
                }),
            );
    }, [toolsData.filters, location.pathname, initFiltersStorage]);

    // Load profile
    useEffect(() => {
        if (!profile) getCurrentProfile();

        // On vérifie si l'utilisateur doit être ajouté à des projets qu'il a souhaité rejoindre
        // On possède cette info en local storage, avec les projets qu'il aurait pu rejoindre quand il n'avait pas de compte utilisateur
        const joinProjects = () => {
            const projectsInvitations = JSON.parse(
                localStorage.getItem("projectsInvitations"),
            );

            const processJoin = async (i) => {
                const invitation = projectsInvitations[i];
                await axios.get(
                    apiURL.joinProject + invitation.id + "/" + invitation.token,
                );

                if (i + 1 < projectsInvitations.length) processJoin(i + 1);
                else {
                    refreshAction();
                    setLoader(false);
                }
            };

            processJoin(0);

            localStorage.removeItem("projectsInvitations");
        };

        if (localStorage.getItem("projectsInvitations")) {
            joinProjects();
        } else setLoader(false);
    }, []);

    // Get informations for project id Selected
    useEffect(() => {
        if (id) getProject();
        else setCurrentProject(null);

        setCurrentItem(null);
        setSelectedFiles([]);
    }, [id]);

    // Get roles for current project
    useEffect(() => {
        if (!currentProject) return;
        document.querySelector(".body-container")?.scrollTo({
            top: 0,
            behavior: "smooth",
        });

        if (profile) {
            const checkAccess = async () => {
                // Check si le user peut accéder à ce dossier (s'il n'est pas restreint sur celui-ci ou un parent)
                if (
                    currentProject.restrictedUsers?.some(
                        (restrictedUser) =>
                            restrictedUser.toString() ===
                            profile.user._id.toString(),
                    )
                )
                    return navigate("/drive");

                if (currentProject.parentProject) {
                    const parents = await getProjectparentsList(
                        currentProject._id,
                    );

                    if (
                        parents.some((parent) =>
                            parent.restrictedUsers.some(
                                (restrictedUser) =>
                                    restrictedUser.toString() ===
                                    profile.user._id.toString(),
                            ),
                        )
                    )
                        return navigate("/drive");
                }
            };

            checkAccess();

            const getRoles = async () => {
                const resRoles = await getMemberRoles(
                    currentProject,
                    profile.user,
                );
                setUserRoles(resRoles);
            };

            getRoles();
        }

        setCurrentItem(null);
    }, [profile, currentProject]);

    // Manage document title
    useEffect(() => {
        if (location.pathname === "/drive")
            document.title = t("drive") + " - " + t("applicationTitle");
        if (!location.pathname.includes("/drive")) setUserRoles({});
    }, [location.pathname]);

    // Permission denied
    useEffect(() => {
        if (location.state && location.state.filePermissionRequired) {
            setInformationModal({
                content: (
                    <IllustratedModalContent
                        title={t("bienvenue!")}
                        text={
                            t("notreMission") +
                            " : <b>" +
                            t(
                                "simplifierLeQuotidienDesProfessionnelsDeLaConstruction",
                            ) +
                            "</b>."
                        }
                        content={
                            <div className="m-auto">
                                <p className="text-lg font-bold text-black mb-2">
                                    {t("uneAutorisationEstNecessaire")}
                                </p>
                                {t(
                                    "vousNetesPasEncoreMembreDuDossierVeuillezContacterLeProprietaire",
                                )}
                                .
                            </div>
                        }
                    />
                ),
                closeReset: setInformationModal,
                isNoPadding: true,
                customWidth: "max-w-[750px] lg:max-w-[1050px]",
            });

            // Reset du state pour ne pas réafficher le message après refresh de la page
            navigate({ state: {} });
        }
    }, [location]);

    // Manage switch media responsive
    useEffect(() => {
        if (!lgMedia) setCurrentItem(null);
    }, [lgMedia]);

    const getProject = async (projectId) => {
        try {
            const projectIdInUrl = getLastParameterFromURL(
                window.location.href,
            );

            const resProject = await axios.get(
                apiURL.getProjectById + (projectIdInUrl ? projectIdInUrl : id),
            );

            if (resProject && resProject.data) {
                setCurrentProject(resProject.data);

                document.title =
                    resProject.data.title + " - " + t("applicationTitle");
            } else {
                navigate("/drive");
            }
        } catch (err) {
            navigate("/drive");
        }
    };

    // Est appelée si on modifie / supprime / ajoute un élément du projet courant
    const refreshAction = debounce(
        async (removeCurrentItem, newCurrentItem, resfreshIfInside) => {
            const refreshActualProject =
                !resfreshIfInside ||
                resfreshIfInside?.includes(currentProject?._id);

            if (id && refreshActualProject) await getProject();
            await getCurrentProfile();

            if (removeCurrentItem) setCurrentItem(null);
            if (newCurrentItem) setCurrentItem(newCurrentItem);
            successDriveSyncMessage();
            return true;
        },
        1000,
    );

    const onUpload = async (files, isFolderImport = false) => {
        // if (!!currentProject.tender) {
        //     addModalData({
        //         key: "tender-update",
        //         title: t("attention"),
        //         content: t(
        //             "vousNePouvezPasAjouterDeFichierDansUnDossierDAppelDOffres",
        //         ),
        //     });
        // }

        if (isFolderImport) return await handleFolderUpload(files);
        else return await handleFiles(Array.from(files), currentProject, 0);
    };

    const handleFolderUpload = async (files = []) => {
        let filesToUpload = Array.from(files)?.sort((a, b) => a.size - b.size);
        try {
            let folderStructure = [];
            // Recursive folder creation logic
            const processFiles = () => {
                filesToUpload.forEach((file) => {
                    const pathParts = file.webkitRelativePath
                        ? file.webkitRelativePath.split("/")
                        : file.path.split("/");

                    let currentFolder = folderStructure;

                    pathParts.forEach((part, index) => {
                        const isFolder = index < pathParts.length - 1;

                        const existingFolder = currentFolder.find(
                            (item) =>
                                item.type === "folder" && item.name === part,
                        );

                        if (existingFolder) {
                            // Dossier existant
                            currentFolder = existingFolder.children;
                        } else {
                            if (
                                !isFolder &&
                                !Constants.allowedFilesTypes.includes(
                                    getFileExtension(file.name),
                                )
                            )
                                return;

                            // Nouveau dossier
                            const newFolder = {
                                name: part,
                                type: isFolder ? "folder" : "file",
                                children: isFolder ? [] : null,
                                fileItem: isFolder ? null : file, // On consèrve l'objet File pour l'upload
                            };
                            currentFolder.push(newFolder);
                            currentFolder = isFolder
                                ? newFolder.children
                                : currentFolder;
                        }
                    });
                });
            };

            processFiles();

            let rootProjectId;
            let nbErrors = 0;
            let resfreshIfInside = [];

            const handleFoldersRecursive = async (project, parentProjectId) => {
                if (project.type === "folder") {
                    const projectFields = {
                        projectTitle: project.name,
                        email: profile.user.email,
                        language: profile.user.language.code,
                        parentProject: parentProjectId,
                        userId: profile.user?._id,
                        sendNotification: !rootProjectId ? true : false,
                    };

                    const resProject = await axios.post(
                        apiURL.createProject,
                        projectFields,
                    );

                    if (resProject && resProject.data) {
                        resfreshIfInside.push(resProject.data?._id);
                        if (!rootProjectId) {
                            rootProjectId = parentProjectId;
                            resfreshIfInside.push(parentProjectId);
                        }

                        const files = project.children
                            ? project.children.filter(
                                  (child) => child.type === "file",
                              )
                            : [];

                        await handleFiles(
                            files.map((file) => file.fileItem),
                            resProject.data,
                            true,
                        );
                        // if (folderStructure.length > 1) // INCASE for enter folder if unique
                        refreshAction(false, false, resfreshIfInside);
                    } else {
                        nbErrors++;
                    }

                    if (project.children) {
                        await Promise.all(
                            project.children.map(async (child) => {
                                await handleFoldersRecursive(
                                    child,
                                    resProject.data?._id,
                                );
                            }),
                        );
                    }
                }
            };

            await Promise.all(
                folderStructure.map((structure) =>
                    handleFoldersRecursive(
                        structure,
                        currentProject ? currentProject?._id : null,
                    ),
                ),
            );

            if (nbErrors > 0) {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLaCreationDuDossier"),
                );
            }

            // INCASE for enter folder if unique
            // if (folderStructure.length <= 1)
            //     navigate("/drive/project/" + rootProjectId);
            return true;
        } catch (err) {
            toastAlert(
                "error",
                t("uneErreurEstSurvenueLorsDeLaCreationDuDossier"),
            );
        }

        setLoader(false);
    };

    // Ajout d'un nouveau fichier au projet courant
    const handleFiles = async (
        files = [],
        targetProject = null,
        isFolderImport = false,
        existingFileId = false,
        action = false,
    ) => {
        let filesToUpload = Array.from(files)
            ?.sort((a, b) => a.size - b.size)
            .map((file) => ({
                file,
                streamId: file.name + generateRandomToken(10),
            }));
        const projectToUpload = targetProject || currentProject;
        await Promise.all(
            filesToUpload
                .filter((e) => e.isEmptyFolder)
                .map((emptyFolder) =>
                    axios.post(apiURL.createProject, {
                        projectTitle: emptyFolder.name,
                        email: profile.user.email,
                        language: profile.user.language.code,
                        parentProject: projectToUpload?._id || null,
                        userId: profile.user?._id,
                        sendNotification: false,
                    }),
                ),
        );
        filesToUpload = filesToUpload.filter((e) => !e.isEmptyFolder);

        // If empty
        if (!filesToUpload.length) return "No files to upload";
        if (!projectToUpload) return "No project to upload";

        if (action !== "replace") {
            // Setup initial upload data
            const { data } = await axios.get(
                apiURL.getAutomationFromFolder + projectToUpload._id,
            );
            // Nomenclature Modal
            const nomenclatureData = data?.find((nomenclature) =>
                nomenclature.actions.some(
                    (action) => action.type.code === "ADD_NOMENCLATURE",
                ),
            );
            if (action !== "version" && nomenclatureData) {
                const keyModal = "nomenclature-" + generateRandomToken(10);
                addModalData({
                    key: keyModal,
                    title: `${t("importDeFichiersDans")} "${
                        projectToUpload.title
                    }"`,
                    okText: t("valider"),
                    width: 1200,
                    handleCancel: async () => {
                        await Promise.all(
                            filesToUpload.map((file) =>
                                removeStream(file.streamId),
                            ),
                        );
                        removeModal("nomenclature");
                    },
                    content: (
                        <DriveNomenclatureModal
                            user={profile.user}
                            setInformationModal={setInformationModal}
                            setLoader={setLoader}
                            updateFileProgressIndicator={
                                updateFileProgressIndicator
                            }
                            refreshAction={refreshAction}
                            oldFile={{ versions: [] }}
                            projectToUpload={projectToUpload}
                            folderName={projectToUpload.title}
                            files={files}
                            isFolderImport={isFolderImport}
                            nomenclatureData={nomenclatureData}
                            filesToUpload={filesToUpload}
                            keyModal={keyModal}
                            existingFileId={existingFileId}
                            action={action}
                        />
                    ),
                });
                return "File need Nomenclature Data";
            }
        }

        filesToUpload.forEach(({ file, streamId }) => {
            updateFileProgressIndicator({
                status: "calculating",
                percentage: 1,
                streamId,
                file,
            });
        });

        // Validations
        const isLimited = await isLimitedDriveSize(
            filesToUpload.map(({ file }) => file),
            projectToUpload,
            addModalData,
            t,
        );

        if (isLimited) {
            await Promise.all(
                filesToUpload.map((file) => removeStream(file.streamId)),
            );
            return "File size limit exceeded";
        }
        filesToUpload = filesToUpload.filter(({ file, streamId }) => {
            if (
                !Constants.allowedFilesTypes.includes(
                    getFileExtension(file.name),
                )
            ) {
                runNotification({
                    type: "error",
                    title: "File type not allowed",
                    content:
                        "File types allowed : " + Constants.allowedFilesTypes,
                });
                removeStream(streamId);
                return false;
            } else return true;
        });

        if (action === "replace" && existingFileId) {
            try {
                const fileTarget = filesToUpload[0];
                const response = await fileUploadProcess({
                    project: projectToUpload,
                    file: fileTarget.file,
                    existingFileId,
                    startPdfConversion: true,
                    readStream: (progress) => {
                        const fileTarget = filesToUpload[0];
                        updateFileProgressIndicator({
                            ...progress,
                            streamId: fileTarget.streamId,
                            file: fileTarget.file,
                        });
                    },
                });
                if (response === true && !isFolderImport)
                    refreshAction(false, false, [projectToUpload._id]);
                return "File uploaded";
            } catch (err) {}
        }

        filesToUpload.forEach(async ({ file, streamId }, index) => {
            const readStreamFunc = (progress) => {
                updateFileProgressIndicator({
                    ...progress,
                    streamId,
                    file,
                });
            };

            // Gère les fichiers .ifcZip
            // Gérer les fichiers .ifcZip dans le cas d'un import de dossier
            if (getFileExtension(file.name) === "ifczip") {
                file = await fromIfcZipToIfc(file);
                if (!file) {
                    toastAlert("error", t("aucunFichierIFCTrouve"));
                    return;
                }
            }

            if (action === "version" && existingFileId) {
                try {
                    const response = await fileUploadProcess({
                        project: projectToUpload,
                        file,
                        existingFileId,
                        action,
                        startPdfConversion: true,
                        readStream: (progress) => {
                            const fileTarget = filesToUpload.find(
                                (item) => item.file === file,
                            );
                            updateFileProgressIndicator({
                                ...progress,
                                streamId: fileTarget.streamId,
                                file,
                            });
                        },
                    });
                    if (response === true && !isFolderImport)
                        refreshAction(false, false, [projectToUpload._id]);
                    return "File uploaded";
                } catch (err) {}
            }

            // Check si le fichier existe déjà dans le projet
            const sameNameFileInProject = projectToUpload.files.find(
                (e) =>
                    e.title.includes(
                        file.name.slice(0, file.name.lastIndexOf(".")).trim(),
                    ) &&
                    getFileExtension(file.name) === getFileExtension(e.file),
            );
            if (sameNameFileInProject) {
                addModalData({
                    title: `${t("attention")}`,
                    key: streamId,
                    handleCancel: () => removeStream(streamId),
                    content: (
                        <DriveSameNameModal
                            file={file}
                            readStreamFunc={readStreamFunc}
                            refreshAction={refreshAction}
                            sameNameFileInProject={sameNameFileInProject}
                            projectToUpload={projectToUpload}
                            files={filesToUpload}
                            index={index}
                            isFolderImport={isFolderImport}
                            fileName={streamId}
                        />
                    ),
                    props: {
                        okText: t("importer"),
                    },
                });
                return "File already exists";
            }
            const { data } = await axios.get(
                apiURL.getAutomationFromFolder + projectToUpload._id,
            );
            const visaData = data?.find((nomenclature) =>
                nomenclature.actions.some(
                    (action) => action.type.code === "ADD_VISA",
                ),
            );
            // Uploader le fichier
            try {
                const response = await fileUploadProcess({
                    project: projectToUpload,
                    file,
                    createVersionData: visaData && {
                        visas: visaData.actions.map(
                            (action) => action.visa._id,
                        ),
                    },
                    startPdfConversion: files.length === 1,
                    readStream: readStreamFunc,
                });
                if (response === true && !isFolderImport)
                    refreshAction(false, false, [projectToUpload._id]);
            } catch (err) {}
            return "File uploaded";
        });
    };

    useEffect(() => {
        return () => {
            if (location.pathname === "/visas") {
                setCurrentItem(null);
            }
        };
    }, [location]);

    const onItemClick = async (itemId, isFolder, openPanel = false) => {
        try {
            if (!itemId) {
                setCurrentItem(null);
                setIsOpenPanel(false);
                return;
            }

            let newItem;
            if (isFolder)
                newItem = await axios.get(apiURL.getProjectById + itemId);
            else newItem = await axios.get(apiURL.getProjectFileById + itemId);

            if (newItem && newItem.data) {
                setCurrentItem(newItem.data);
                setIsOpenPanel(openPanel);
            }
        } catch (err) {}
    };

    const isSidePanelVisible = () => {
        return (
            lgMedia &&
            ((currentItem && isOpenPanel) ||
                selectedFiles.length > 0 ||
                toolsData.filtersOpen ||
                location.pathname === "/timesheet/fields")
        );
    };

    const addSelectedFile = (file, isChecked) => {
        if (isChecked) setSelectedFiles([...selectedFiles, file]);
        else {
            let fileIndex = selectedFiles.findIndex(
                (selectedFile) => file._id === selectedFile._id,
            );

            if (fileIndex > -1) {
                setSelectedFiles([
                    ...selectedFiles.slice(0, fileIndex),
                    ...selectedFiles.slice(fileIndex + 1),
                ]);
            }
        }
    };

    const spinnerContent = (
        <div className="fixed top-0 left-0 w-full h-full z-10 bg-black bg-opacity-25">
            <Spinner isSmall={true} />
        </div>
    );

    const viewsWithPanel = ["visas", "flow", "recents", "favorites", "photos"];

    const panelContent =
        toolView && !viewsWithPanel.includes(toolView) ? (
            <Fragment>
                {toolsData.filtersOpen && <FiltersSidePanel />}
                {location.pathname === "/timesheet/fields" && (
                    <PreviewSidePanel />
                )}
            </Fragment>
        ) : (
            <>
                {toolsData.filtersOpen && <FiltersSidePanel />}
                <DriveSidePanel
                    item={currentItem}
                    currentProject={currentProject}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    closePanel={setCurrentItem}
                    refreshAction={
                        !viewsWithPanel.includes(toolView) && refreshAction
                    }
                    profile={profile}
                    userRoles={userRoles}
                    toolView={toolView}
                />
            </>
        );

    const { token } = theme.useToken();

    return !profile || !toolsData.loaded || isWorkerPortal === null ? (
        <Spinner />
    ) : (
        profile && (
            <DriveToolsContext.Provider
                value={{
                    toolsData,
                    setToolsData,
                    userProfile: profile,
                    userRoles,
                    setInformationModal,
                    loader,
                    setLoader,
                    customerConfig,
                    refreshAction,
                    handleFiles,
                    onUpload,
                    getCurrentProfile,
                    currentItem,
                    onItemClick,
                    currentProject,
                    toolView,
                }}
            >
                {isWorkerPortal ? (
                    <Portal profile={profile} />
                ) : (
                    <Layout style={{ height: "100vh" }}>
                        {mdMedia && (
                            <Resizable
                                size={{ width: menuWidth, height: "100%" }}
                                minWidth={275}
                                maxWidth={700}
                                boundsByDirection={true}
                                enable={{
                                    right: lgMedia,
                                }}
                                onResizeStop={(e, direction, ref, d) => {
                                    setMenuWidth(menuWidth + d.width);
                                }}
                            >
                                <Layout.Sider width="100%">
                                    <DriveSideMenu
                                        profile={profile}
                                        currentProject={currentProject}
                                        refreshAction={refreshAction}
                                        userRoles={userRoles}
                                        toolView={toolView}
                                    />
                                </Layout.Sider>
                            </Resizable>
                        )}
                        <Layout>
                            <Layout.Header
                                className="bg-white lg:bg-transparent"
                                style={{ padding: 0 }}
                            >
                                <DriveNav
                                    profile={profile}
                                    currentProject={currentProject}
                                    refreshAction={refreshAction}
                                    userRoles={userRoles}
                                    customerConfig={customerConfig}
                                    toolView={toolView}
                                />
                            </Layout.Header>
                            <Layout>
                                <Layout.Content
                                    style={{
                                        position: "relative",
                                        padding: lgMedia ? token.paddingLG : 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    <Card
                                        id="layout-content"
                                        style={{
                                            borderBottomLeftRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: lgMedia
                                                ? ""
                                                : 0,
                                            borderTopRightRadius: lgMedia
                                                ? ""
                                                : 0,
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                        }}
                                        styles={{
                                            body: {
                                                display: "flex",
                                                flexDirection: "column",
                                                paddingTop:
                                                    (!mdMedia &&
                                                        isPhotosView) ||
                                                    isPlansView
                                                        ? 0
                                                        : token.paddingLG,
                                                paddingLeft:
                                                    (!mdMedia &&
                                                        isPhotosView) ||
                                                    isPlansView
                                                        ? 0
                                                        : token.paddingLG,
                                                paddingRight:
                                                    (!mdMedia &&
                                                        isPhotosView) ||
                                                    isPlansView
                                                        ? 0
                                                        : token.paddingLG,
                                                paddingBottom: 0,
                                                overflowY: "auto",
                                                height: "100%",
                                                position: "relative",
                                            },
                                            header: {
                                                paddingTop: lgMedia
                                                    ? token.paddingLG
                                                    : token.paddingLG / 2,
                                                paddingBottom: lgMedia
                                                    ? token.paddingLG
                                                    : token.paddingLG / 2,
                                                paddingLeft: token.paddingLG,
                                                paddingRight: token.paddingLG,
                                                fontSize: lgMedia ? "" : 16,
                                            },
                                        }}
                                        classNames={{
                                            body: "body-container",
                                        }}
                                        title={
                                            <Flex gap="large" wrap>
                                                <Flex
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                    align="center"
                                                    gap="small"
                                                    wrap
                                                >
                                                    {id &&
                                                    currentProject &&
                                                    profile ? (
                                                        <TitleViewProject
                                                            currentProject={
                                                                currentProject
                                                            }
                                                            urlProjectId={id}
                                                            profile={profile}
                                                            refreshAction={
                                                                refreshAction
                                                            }
                                                            showInfosInPanel={
                                                                onItemClick
                                                            }
                                                            userRoles={
                                                                userRoles
                                                            }
                                                        />
                                                    ) : isTimesheetView ? (
                                                        t("feuilleDeTemps")
                                                    ) : isAutomationsView ? (
                                                        t("automatisations")
                                                    ) : isVisasView ? (
                                                        t("visas")
                                                    ) : isPhotosView ? (
                                                        t("photos")
                                                    ) : isStatsView ? (
                                                        t("statistiques")
                                                    ) : isFlowView ? (
                                                        t("flux")
                                                    ) : isRecentsView ? (
                                                        t("recents")
                                                    ) : isFavoritesView ? (
                                                        t("favoris")
                                                    ) : isPlansView ? (
                                                        <TitlePlanView />
                                                    ) : isFormsView ? (
                                                        formId ? (
                                                            <FormTemplateHeader />
                                                        ) : (
                                                            t("formulaires")
                                                        )
                                                    ) : (
                                                        t("mesProjets")
                                                    )}
                                                    {((isPhotosView &&
                                                        mdMedia) ||
                                                        !!currentProject ||
                                                        isFlowView ||
                                                        isFavoritesView ||
                                                        isRecentsView ||
                                                        !toolView) && (
                                                        <DriveModeRender
                                                            spaced
                                                        />
                                                    )}
                                                </Flex>
                                            </Flex>
                                        }
                                    >
                                        <DriveBackgroundCard
                                            currentProject={currentProject}
                                            isPadding={Boolean(
                                                !!currentItem ||
                                                    selectedFiles.length > 0,
                                            )}
                                        />

                                        {id && profile ? (
                                            currentProject ? (
                                                <ViewProject
                                                    urlProjectId={id}
                                                    project={currentProject}
                                                    profile={profile}
                                                    selectedFiles={
                                                        selectedFiles
                                                    }
                                                    setSelectedFiles={
                                                        setSelectedFiles
                                                    }
                                                    handleFiles={handleFiles}
                                                    refreshAction={
                                                        refreshAction
                                                    }
                                                    spinnerContent={
                                                        spinnerContent
                                                    }
                                                    currentItem={currentItem}
                                                    userRoles={userRoles}
                                                    addSelectedFile={
                                                        addSelectedFile
                                                    }
                                                />
                                            ) : null
                                        ) : isFlowView ? (
                                            <DriveFilteredList
                                                type="flow"
                                                profile={profile}
                                                refreshAction={refreshAction}
                                                addSelectedFile={
                                                    addSelectedFile
                                                }
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={
                                                    setSelectedFiles
                                                }
                                                currentItem={currentItem}
                                                userRoles={userRoles}
                                            />
                                        ) : isRecentsView ? (
                                            <DriveFilteredList
                                                type="recents"
                                                profile={profile}
                                                refreshAction={refreshAction}
                                                addSelectedFile={
                                                    addSelectedFile
                                                }
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={
                                                    setSelectedFiles
                                                }
                                                currentItem={currentItem}
                                                userRoles={userRoles}
                                            />
                                        ) : isFavoritesView ? (
                                            <DriveFilteredList
                                                type="favorites"
                                                profile={profile}
                                                refreshAction={refreshAction}
                                                addSelectedFile={
                                                    addSelectedFile
                                                }
                                                selectedFiles={selectedFiles}
                                                setSelectedFiles={
                                                    setSelectedFiles
                                                }
                                                currentItem={currentItem}
                                                userRoles={userRoles}
                                            />
                                        ) : isTimesheetView ? (
                                            <TimeSheetView />
                                        ) : isAutomationsView ? (
                                            <Automations />
                                        ) : isVisasView ? (
                                            <Visas
                                                onItemClick={onItemClick}
                                                refreshAction={refreshAction}
                                            />
                                        ) : isFormsView ? (
                                            <FormView />
                                        ) : isPhotosView ? (
                                            mdMedia && !landscapeMobileMedia ? (
                                                <Photos
                                                    selectedFiles={
                                                        selectedFiles
                                                    }
                                                    setSelectedFiles={
                                                        setSelectedFiles
                                                    }
                                                />
                                            ) : (
                                                <PhotosUser />
                                            )
                                        ) : isStatsView ? (
                                            <Statistics />
                                        ) : isPlansView ? (
                                            <PlansView />
                                        ) : (
                                            <DriveDashboard
                                                profile={profile}
                                                refreshAction={refreshAction}
                                                currentItem={currentItem}
                                            />
                                        )}
                                    </Card>
                                    {!lgMedia &&
                                        (selectedFiles.length > 0 ||
                                            currentItem) && (
                                            <DocumentMobileBottomContent
                                                content={panelContent}
                                            />
                                        )}
                                </Layout.Content>
                                {/* TOFIX Animation Sidepanel */}
                                {isSidePanelVisible() && (
                                    <Layout.Sider
                                        width={
                                            isSidePanelVisible()
                                                ? (toolsData.filtersOpen ||
                                                  location.pathname ===
                                                      "/timesheet/fields"
                                                      ? 375
                                                      : 300) + token.paddingLG
                                                : 0
                                        }
                                        style={{
                                            padding: isSidePanelVisible()
                                                ? `${token.paddingLG}px ${token.paddingLG}px ${token.paddingLG}px 0`
                                                : 0,
                                            transition:
                                                token.motionDurationFast,
                                        }}
                                    >
                                        {panelContent}
                                    </Layout.Sider>
                                )}
                            </Layout>
                        </Layout>
                        {(!currentProject ||
                            (currentProject && userRoles.EDITOR)) &&
                            toolView === "" &&
                            !mdMedia && (
                                <NewActions
                                    user={profile.user}
                                    currentProject={currentProject}
                                    userRoles={userRoles}
                                />
                            )}
                        {informationModal && (
                            <InformationModal
                                title={informationModal.title}
                                content={informationModal.content}
                                closeReset={informationModal.closeReset}
                                isNoPadding={informationModal.isNoPadding}
                                customWidth={informationModal.customWidth}
                            />
                        )}
                    </Layout>
                )}
            </DriveToolsContext.Provider>
        )
    );
};

Drive.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(Drive);
