import { useEffect, useRef } from "react";

import { makeResizable } from "helpers/resizer";

import IconButton from "components/input/IconButton";

const DocumentMobileBottomContent = ({ content, closeContent }) => {
    const refContent = useRef(null);
    const refResizer = useRef(null);

    useEffect(() => {
        makeResizable(
            refContent.current,
            refResizer.current,
            refContent.current.parentNode.clientHeight - 100,
        );
    }, []);

    return (
        <div
            className="card absolute bottom-0 z-[100] h-[calc(100%-120px)] w-full rounded-t-2xl overflow-hidden flex flex-col"
            ref={refContent}
        >
            <div
                className="absolute top-0 left-[calc(50%-75px)] z-[101] hover:cursor-ns-resize h-[15px] w-full max-w-[150px] rounded-b-xl bg-black"
                ref={refResizer}
            />

            {closeContent && (
                <div className="absolute right-3 top-2 z-[1]">
                    <IconButton onClick={closeContent} icon="close" isNeutral />
                </div>
            )}

            <div className="overflow-hidden flex-grow">{content}</div>
        </div>
    );
};

export default DocumentMobileBottomContent;
