import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { createURLFile, fileUploadProcess } from "helpers/file";
import constants from "utils/constants";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { Form, Input } from "antd";

const ModalURLRedirection = ({
    project,
    refreshAction,
    updateFileProgressIndicator,
}) => {
    const { t } = useTranslation();

    const [title, setTitle] = useState("");
    const [url, setURL] = useState("");

    const { setHandleConfirm } = useContext(ModalContext);
    const { startDriveSyncMessage } = useContext(PopupContext);

    useEffect(() => {
        setHandleConfirm("createRedirection", async () => {
            try {
                startDriveSyncMessage();
                const file = createURLFile(url, title);

                const response = await fileUploadProcess({
                    project,
                    file,
                    readStream: (progress) => {
                        updateFileProgressIndicator({
                            ...progress,
                            streamId: file.streamId,
                            file,
                        });
                    },
                });
                if (response === true) refreshAction();

                return true;
            } catch (err) {
                return false;
            }
        });
    }, [url, project]);

    return (
        <>
            <Form.Item
                hasFeedback
                name="title"
                validateTrigger="onChange"
                rules={[
                    {
                        max: constants.maxLengthFileTitle,
                        required: true,
                        transform: (value) => value.trim(),
                    },
                ]}
            >
                <Input
                    placeholder={t("nomDuFichier")}
                    addonAfter=".url"
                    size="large"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name="url"
                validateTrigger="onChange"
                rules={[
                    {
                        max: constants.maxLengthURLFileType,
                        required: true,
                        type: "url",
                        transform: (value) => value.trim(),
                    },
                ]}
            >
                <Input
                    placeholder={t("lienURL")}
                    size="large"
                    value={url}
                    onChange={(e) => setURL(e.target.value)}
                    required
                />
            </Form.Item>
        </>
    );
};

export default ModalURLRedirection;
