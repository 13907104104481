import { Fragment, useEffect, useState } from "react";

import SubCommentItem from "./SubCommentItem";

const SubCommentList = ({
    profile,
    files,
    parentComment,
    comments,
    editedSubComment,
    setEditedSubComment,
    isFromForm = false,
}) => {
    const [subComments, setSubComments] = useState([]);

    useEffect(() => {
        setSubComments(comments);
    }, [comments]);

    return (
        <div
            className={
                "mt-3" + (!isFromForm ? " max-h-[400px] overflow-auto" : "")
            }
        >
            {subComments
                .filter(
                    (comment) =>
                        !editedSubComment ||
                        editedSubComment._id === comment._id,
                )
                .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
                .map((comment, i) => (
                    <Fragment key={i}>
                        <SubCommentItem
                            profile={profile}
                            files={files}
                            parentComment={parentComment}
                            subComment={comment}
                            subComments={subComments}
                            setSubComments={setSubComments}
                            editedSubComment={editedSubComment}
                            setEditedSubComment={setEditedSubComment}
                            isFromForm={isFromForm}
                        />

                        {subComments[i + 1] && <hr />}
                    </Fragment>
                ))}
        </div>
    );
};

export default SubCommentList;
