import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { copyTextToClipboard } from "helpers/text";

import MaterialIcons from "components/common/MaterialIcons";

import { Button } from "antd";

const ClipboardCopy = ({
    text,
    isSmall = false,
    isVerySmall = false,
    isEmbed = false,
}) => {
    const { t } = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const handleClick = () => {
        copyTextToClipboard(text)
            .then(() => {
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {});
    };

    return (
        <div className="flex flex-row">
            {!isSmall && !isVerySmall && !isEmbed ? (
                <Fragment>
                    <div className="w-[calc(100%-40px)] max-w-[300px] text-sm text-slate-400">
                        {isCopied ? (
                            <div className="bg-white border border-r-0 rounded-l-lg pl-4 py-2 w-full text-primary font-bold">
                                {t("lienCopie!")}
                            </div>
                        ) : (
                            <input
                                type="text"
                                value={text}
                                readOnly
                                className="bg-white border border-r-0 rounded-l-lg pl-4 py-2 w-full"
                            />
                        )}
                    </div>
                    <div className="w-[40px] flex bg-white rounded-r-lg border border-l-0 text-slate-400">
                        <button
                            type="button"
                            onClick={(e) => handleClick()}
                            className="w-[30px] m-auto flex"
                            title={t("copier")}
                        >
                            <i
                                className="material-icons notranslate m-auto"
                                style={{ fontSize: "1.5rem" }}
                            >
                                content_copy
                            </i>
                        </button>
                    </div>
                </Fragment>
            ) : isSmall ? (
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={(e) => handleClick()}
                    icon={<MaterialIcons name="link" />}
                >
                    {isCopied ? t("lienCopie!") : t("copierLeLien")}
                </Button>
            ) : isVerySmall ? (
                <div className="relative flex flex-row">
                    {isCopied && (
                        <div className="text-2xs whitespace-nowrap my-auto mr-2">
                            {t("lienCopie!")}
                        </div>
                    )}
                    <button
                        type="button"
                        className="flex bg-slate-100 hover:bg-gray-200 border-0 w-[26px] h-[26px] text-black rounded-full"
                        onClick={(e) => handleClick()}
                        title={t("copierLeLien")}
                    >
                        <i
                            className="material-icons notranslate m-auto"
                            style={{ fontSize: "1rem" }}
                        >
                            link
                        </i>
                    </button>
                </div>
            ) : (
                isEmbed && (
                    <div className="flex flex-row">
                        {isCopied && (
                            <div className="my-auto">{t("lienCopie!")}</div>
                        )}
                        <button
                            type="button"
                            onClick={(e) => handleClick()}
                            className="mr-2 px-2"
                        >
                            <i
                                className="material-icons notranslate"
                                style={{ fontSize: "1.5rem" }}
                            >
                                link
                            </i>
                        </button>
                    </div>
                )
            )}
        </div>
    );
};

export default ClipboardCopy;
