import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { iFrameGenerator } from "helpers/file";

import CodeDisplay from "components/utils/CodeDisplay";
import InfoMessage from "components/utils/InfoMessage";

const ShareIFrame = ({ file, embedUrl }) => {
    const { t } = useTranslation();

    return embedUrl !== "" ? (
        <Fragment>
            {file.project.isAssociated && !file.publicToken ? (
                <div className="my-4">
                    <InfoMessage
                        type="warning"
                        title={t("attention")}
                        content={
                            t(
                                "ceFichierNePossedePasDeLienDePartagePublicAfinDeLeConsulterIlFautEtreMembreDuDossier",
                            ) + "."
                        }
                    />
                </div>
            ) : (
                file.isPasswordAccess && (
                    <div className="my-4">
                        <InfoMessage
                            type="warning"
                            title={t("attention")}
                            content={
                                t(
                                    "lAccesACeFichierViaLeLienPublicNecessiteUnMotDePasse",
                                ) + "."
                            }
                        />
                    </div>
                )
            )}
            <CodeDisplay code={iFrameGenerator(embedUrl)} />
        </Fragment>
    ) : null;
};

export default ShareIFrame;
