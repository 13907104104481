import { useEffect } from "react";

import MaterialIcons from "components/common/MaterialIcons";

import { ConfigProvider, Spin, theme } from "antd";
import frFR from "antd/locale/fr_FR";

Spin.setDefaultIndicator(
    // <Spinner isSmall widthClass=" w-6" heightClass=" h-6" />
    <MaterialIcons name="progress_activity" spin size="xl" isOutlined />,
);

// ...
const AntdProvider = ({ children }) => {
    const { token } = theme.useToken();

    // HACK Material Symbols qui s'affichent en texte
    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            const isMac = /MacIntel|MacPPC|Mac68K|Mac/.test(navigator.platform);
            const isWebKit =
                "WebkitAppearance" in document.documentElement.style; // WebKit-specific CSS property
            const isNotChrome = !navigator.userAgent.includes("Chrome");
            const isNotEdge = !navigator.userAgent.includes("Edg");
            document.body.classList.add("font-loading");
            if (!isNotChrome) {
                document.fonts.onloadingdone = (e) => {
                    if (
                        e?.fontfaces?.find(
                            (e) => e.family === "Material Symbols Outlined",
                        )
                    ) {
                        document.body.classList.remove("font-loading");
                    }
                };
            } else {
                setTimeout(() => {
                    document.body.classList.remove("font-loading");
                }, 500);
            }
        }
    }, []);

    return (
        <ConfigProvider
            locale={frFR}
            theme={{
                cssVar: true,
                token: {
                    // INCASE : antd theme from json
                    // ...configTheme,
                    colorPrimary: "#0038cb",
                    colorInfo: "#0038cb",
                    colorSuccess: "#3ce1b9",
                    // colorWarning: "#fff500",
                    colorError: "#ff0047",
                    // colorBgBase: "#FAFBFD", // "#fafbfd",
                    colorBgLayout: "#eef2ff",
                    // colorTextBase: "#010527",
                    fontFamily: "Montserrat",
                    boxShadow: "3px 5px 9px 1px rgba(0,0,0,0.20)",
                    boxShadowSecondary: "3px 5px 9px 1px rgba(0,0,0,0.20)",
                    boxShadowTertiary: "3px 5px 9px 1px rgba(0,0,0,0.20)",

                    motionDurationSlow: "0.1s",

                    colorTextDisabled: "#555555",
                },
                components: {
                    Button: {
                        // borderRadius: 2,
                        // borderRadiusLG: 4,
                        fontWeight: 700,
                    },
                    Badge: {
                        dotSize: "10px",
                    },
                    FloatButton: {
                        zIndex: 1,
                        zIndexBase: 1,
                        zIndexPopupBase: 1,
                    },
                    Card: {
                        colorBorderSecondary: token.colorBorder,
                        colorBgBase: "#ffffff",
                        borderRadiusLG: 12,
                        headerFontSize: 18,
                        // headerHeight: "auto",
                    },
                    Layout: {
                        // headerHeight: 80,
                        headerHeight: "auto",
                        headerBg: "transparent",
                        siderBg: "transparent",
                        colorBgLayout: "transparent",
                        headerPadding: `0 ${token.paddingLG / 2}px`,
                    },
                    Modal: {},
                    Message: {},
                    Menu: {
                        darkItemBg: "transparent",
                        darkItemSelectedBg: "rgb(100, 116, 139)",
                        darkItemColor: "white",
                        // darkItemSelectedColor: "white",
                        subMenuItemSelectedColor: "white",
                        darkItemHoverColor: token.colorPrimaryBgHover,

                        darkSubMenuItemBg: "transparent",
                    },
                    Collapse: {},
                    Tabs: {
                        // horizontalMargin: 24,
                        // horizontalMargin: "0 0 24px 0",
                        horizontalItemGutter: 0,
                        titleFontSize: 16,
                    },
                    Form: {
                        // itemMarginBottom: 0,
                    },
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default AntdProvider;
