import { useEffect, useRef } from "react";

const useHorizontalScroll = () => {
    const elRef = useRef();

    useEffect(() => {
        const el = elRef.current;

        if (el) {
            const onWheel = (e) => {
                if (e.deltaY === 0) return;
                if (Math.abs(e.deltaX) <= Math.abs(e.deltaY)) {
                    el.scrollTo({
                        left: el.scrollLeft + e.deltaY * 2,
                        behavior: "smooth",
                    });
                    e.preventDefault();
                }
            };

            el.addEventListener("wheel", onWheel);
            return () => el.removeEventListener("wheel", onWheel);
        }
    }, []);

    return elRef;
};

export default useHorizontalScroll;
