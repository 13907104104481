import { Text as TextPrint } from "@react-pdf/renderer";
import moment from "moment";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, DatePicker, Form, Input, Row, theme } from "antd";

export function DateAnswer({ title, index, required, disabled }) {
    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}
            getValueProps={(value) => {
                return {
                    value: value ? moment(value) : null,
                };
            }}>
            <DatePicker
                disabled={disabled}
                style={{
                    width: "100%",
                }}
            />
        </Form.Item>
    );
}

export function DateTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item
                    label="Valeur par défaut :"
                    name={[index, "defaultValue"]}
                    getValueProps={(value) => {
                        return {
                            value: value ? moment(value) : null,
                        };
                    }}>
                    <DatePicker
                        placeholder={"Valeur par défaut :"}
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
            </Col>
            {/* <Col span={24} lg={12}>
                <Form.Item
                
                    label="Date minimum :"
                    name={[index, "defaultValue"]}
                >
                    <DatePicker
                        placeholder={"Date minimum"}
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item
               
                    label="Date maximum :"
                    name={[index, "defaultValue"]}
                >
                    <DatePicker
                        placeholder={"Date maximum"}
                        style={{
                            width: "100%",
                        }}
                    />
                </Form.Item>
            </Col> */}
        </Row>
    );
}

export function DatePrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.value ? moment(field.value).format("DD - MM - YYYY") : ""}
        </TextPrint>
    );
}
