import { useEffect, useState } from "react";

export const ItemTypes = {
    FILE: "FILE",
    PROJECT: "PROJECT",
};

export default function useLongPress(callback = () => {}, ms = 300) {
    const [startLongPress, setStartLongPress] = useState(false);
    const [eventTrigger, setEventTrigger] = useState();

    useEffect(() => {
        let timerId;
        if (startLongPress) {
            timerId = setTimeout(() => {
                callback(eventTrigger);
            }, ms);
        } else {
            clearTimeout(timerId);
        }

        return () => {
            clearTimeout(timerId);
        };
    }, [callback, ms, startLongPress]);

    return {
        onMouseDown: (e) => {
            setEventTrigger(e);
            setStartLongPress(true);
        },
        onMouseUp: () => setStartLongPress(false),
        onMouseLeave: () => setStartLongPress(false),
        onTouchStart: (e) => {
            setEventTrigger(e);
            setStartLongPress(true);
        },
        onTouchEnd: () => setStartLongPress(false),
    };
}
