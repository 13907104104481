const MobileMenuOverlay = ({ height, setVisible }) => {
    return (
        <div
            className={
                "screen bg-black bg-opacity-75 fixed z-10 w-full left-0 top-0 " +
                height
            }
            onClick={(e) => {
                setVisible(false);
            }}
        ></div>
    );
};

export default MobileMenuOverlay;
