import axios from "axios";
import i18n from "i18next";

import apiURL from "utils/apiURL";

import { generateRandomToken } from "./token";

export async function sendEmail(url, email, content) {
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    const mailData = {
        email,
        content,
        language: i18n.locale,
    };

    try {
        const sendEmail = await axios.post(url, mailData, config);

        // Retourne true ou false en fonction du succès de l'envoi du mail

        return sendEmail.data;
    } catch (err) {
        return false;
    }
}

// Envoi de l'email lors de la création du compte
export async function sendEmailActivation(email, transactionId) {
    let isSended = false;
    try {
        let token = generateRandomToken(20);

        if (token) {
            await sendEmail(apiURL.emailActivateAccount, email, {
                token,
                transactionId,
            })
                .then(async (res) => {
                    if (res === true) isSended = true;
                })
                .catch((err) => {});
        }
    } catch (err) {}

    return isSended;
}
