import axios from "axios";
import DOMPurify from "dompurify";
import i18n from "i18next";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import Checkbox from "components/input/Checkbox";
import InformationModal from "components/modal/InformationModal";

const AcceptConditions = ({ value, onCheck, errorMsg, isTextOnly = false }) => {
    const { t } = useTranslation();

    const [informationModal, setInformationModal] = useState(null);

    const [instit, setInstit] = useState(null);

    useEffect(() => {
        const getInstit = async () => {
            try {
                const resInstit = await axios.get(
                    apiURL.getInstitutionalByCode + "GENERAL_CONDITIONS",
                );

                if (resInstit) setInstit(resInstit.data);
            } catch (err) {
                return null;
            }
        };

        getInstit();
    }, []);

    return (
        instit && (
            <div className="mb-6">
                {!isTextOnly ? (
                    <Fragment>
                        <div className="inline-block align-middle">
                            <Checkbox
                                text={t("jaccepteLes")}
                                name="okConditions"
                                removeMarging={true}
                                value={value}
                                onChange={onCheck}
                                errorMsg={errorMsg}
                            />
                        </div>

                        <div
                            className="inline-block align-middle text-primary hover:cursor-pointer ml-1 text-sm"
                            onClick={(e) =>
                                setInformationModal({
                                    title: instit["title" + i18n.locale],
                                    content: (
                                        <div
                                            className="text-left institutional-content"
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    instit[
                                                        "content" + i18n.locale
                                                    ],
                                                ),
                                            }}
                                        ></div>
                                    ),

                                    closeReset: setInformationModal,
                                    customWidth: "lg:max-w-[1200px]",
                                })
                            }
                        >
                            {t("conditionsDutilisationEtDeVente")}
                        </div>
                    </Fragment>
                ) : (
                    <div className="text-black text-sm">
                        {t("enContinuantVousAcceptezLes")}
                        <div
                            className="inline-block underline hover:cursor-pointer ml-1 text-sm"
                            onClick={(e) =>
                                setInformationModal({
                                    title: instit["title" + i18n.locale],
                                    content: (
                                        <div
                                            className="text-left institutional-content"
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                    instit[
                                                        "content" + i18n.locale
                                                    ],
                                                ),
                                            }}
                                        ></div>
                                    ),

                                    closeReset: setInformationModal,
                                    customWidth: "lg:max-w-[1200px]",
                                })
                            }
                        >
                            {t(
                                "conditionsDutilisationEtLaPolitiqueDeConfidentialite",
                            )}
                        </div>
                    </div>
                )}

                {informationModal && (
                    <InformationModal
                        title={informationModal.title}
                        content={informationModal.content}
                        closeReset={informationModal.closeReset}
                        customWidth={informationModal.customWidth}
                    />
                )}
            </div>
        )
    );
};

export default AcceptConditions;
