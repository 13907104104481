import { useAppStore } from "providers/ProviderApp";

import { Card, Skeleton } from "antd";

const SkeletonFileItem = () => {
    const { viewMode } = useAppStore();

    return viewMode !== "list" ? (
        <Card
            size="small"
            cover={
                <Skeleton.Node
                    rootClassName="w-full"
                    style={{ width: "100%", height: 180 }}
                    active
                />
            }
        >
            <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: "80%" }}
            />
        </Card>
    ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
            <Skeleton.Node
                style={{ width: 20, height: 20, marginRight: 10 }}
                active
            />

            <Skeleton
                active
                title={false}
                paragraph={{ rows: 1, width: "100%" }}
                style={{ flexGrow: 1 }}
            />
        </div>
    );
};

export default SkeletonFileItem;
