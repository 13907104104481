import i18n from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import ButtonExportTimesheet from "components/exports/ButtonExportTimesheet";
import ButtonExportTimesheetByUser from "components/exports/ButtonExportTimesheetByUser";
import ButtonFilters from "components/filters/ButtonFilters";

import ActionGroup from "./ActionGroup";
import ActionInfos from "./ActionInfos";
import ActionPagination from "./ActionPagination";

import { Button, Dropdown } from "antd";

const TableHeader = ({ filterDateRange, updatedTime, daysInRange, formatedDataList }) => {
    const { t } = useTranslation();

    const { toolsData } = useContext(DriveToolsContext);

    const { scheduleConfiguration, customerConfig } = useProfileStore();

    const heightRow = " h-[40px]";

    const subtitle = useMemo(() => {
        if (toolsData.filters.dateRange === "byDay") {
            return (
                t("periode") +
                " : " +
                capitalize(moment(toolsData.pagination).locale(i18n.locale).format("dddd Do MMMM YYYY"))
            );
        }
        if (toolsData.filters.dateRange === "byWeek") {
            return (
                t("periode") +
                " : " +
                t("semaine") +
                " " +
                moment(toolsData.pagination).locale(i18n.locale).format("W YYYY")
            );
        }
        if (toolsData.filters.dateRange === "byMonth") {
            return (
                t("periode") + " : " + capitalize(moment(toolsData.pagination).locale(i18n.locale).format("MMMM YYYY"))
            );
        }
    }, [toolsData.pagination, toolsData.filters.dateRange, i18n.locale]);

    return (
        <thead className="sticky top-0 bg-slate-50 z-[5]">
            <tr>
                <td colSpan="4" className="border text-xs px-2 min-w-[600px]">
                    <div className="flex items-center gap-8">
                        <ActionPagination />
                        <ButtonFilters />
                        <ActionGroup />
                    </div>
                </td>
                <td colSpan="2" className="border text-xs px-4  min-w-[300px]">
                    <div className="flex justify-between items-center">
                        <ActionInfos updatedTime={updatedTime} />

                        <Dropdown
                            menu={{
                                items: [
                                    ButtonExportTimesheet({
                                        dataSource: formatedDataList,
                                        daysInRange,
                                        customerConfig,
                                        scheduleConfiguration,
                                        subtitle,
                                    }),
                                    ButtonExportTimesheetByUser({
                                        dataSource: formatedDataList,
                                        daysInRange,
                                        customerConfig,
                                        scheduleConfiguration,
                                        subtitle,
                                    }),
                                ],
                            }}
                            placement="bottomRight"
                        >
                            <Button type="text">{t("exporter")}</Button>
                        </Dropdown>
                    </div>
                </td>
            </tr>
            <tr className={"" + heightRow}>
                <td className="border px-3 text-2xs min-w-[36px]"></td>
                <td className="border px-3 text-2xs capitalize">{t("utilisateur")}</td>
                <td className="border px-3 text-2xs">{t("dossier")}</td>
                <td className="border px-3 text-2xs">{t("nomDeLaTache")}</td>
                {/* <td className="border px-3 text-2xs min-w-[65px]">HS</td> */}
                {/* <td className="border px-3 text-2xs min-w-[65px] ">HP</td> */}
                <td className="border text-2xs w-full max-w-[0px]">
                    <div className="overflow-x-scroll h-full timeline-row">
                        <div className={"flex cursor-col-resize w-max" + heightRow}>
                            {daysInRange.map((e, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={
                                            "text-center px-3 border-r capitalize flex items-center justify-center flex-col" +
                                            (filterDateRange === "byDay" ? " w-[200px]" : "") +
                                            (filterDateRange === "byWeek" ? " w-[74px]" : "") +
                                            (filterDateRange === "byMonth" ? " w-[40px]" : "") +
                                            (e.isToday
                                                ? " bg-blue-100"
                                                : e.isWeekend
                                                  ? " bg-blue-50"
                                                  : !!e.isHoliday?.length
                                                    ? " bg-green-50"
                                                    : !!e.isCompendated?.length
                                                      ? " bg-yellow-50"
                                                      : !!e.isVacation
                                                        ? " bg-red-50"
                                                        : "")
                                        }
                                    >
                                        <RenderTextTime date={e.date} filterDateRange={filterDateRange} />
                                        <Text size={8}>{moment(e.date).format("dd")}</Text>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </td>
                <td className="border px-3 text-2xs min-w-[60px] font-bold">{t("total")}</td>
            </tr>
        </thead>
    );
};

const RenderTextTime = ({ date, filterDateRange }) => {
    switch (filterDateRange) {
        case "byDay":
            return (
                moment(date).locale(i18n.locale).format("dddd ") +
                " " +
                moment(date).locale(i18n.locale).format("Do") +
                " " +
                moment(date).locale(i18n.locale).format("MMMM") +
                " " +
                moment(date).locale(i18n.locale).format("YYYY")
            );
        case "byWeek":
            return (
                moment(date).locale(i18n.locale).format("D") +
                " " +
                moment(date).locale(i18n.locale).format("dddd ").slice(0, 3)
            );
        case "byMontd":
            return moment(date).locale(i18n.locale).format("DD");
        default:
            return moment(date).locale(i18n.locale).format("DD");
    }
};

export default TableHeader;
