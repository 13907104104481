import { useContext, useEffect } from "react";

import { ModalContext } from "layout/LayoutModal";

import { TabExportToCSV, functionExportToCSV } from "./ExportToCSV";
import { TabExportToPDF, functionExportToPDF } from "./ExportToPDF";
import { TabExportToXLSX, functionExportToXLSX } from "./ExportToXLSX";

import { Tabs } from "antd";

const ModalExport = (
    props,
    // = {
    //     columns: [
    //         {
    //             title: "",
    //             dataIndex: "",
    //             width: number || string || undefined,
    //         },
    //     ],
    //     dataSource: [
    //         {
    //             key1: ...,
    //             key2: ...,
    //         },
    //     ],
    //     title: "",
    //     subtitle: "",
    //     customerConfig : Object,
    //     pdfContent: React.Node,
    // }
) => {
    const { setHandleConfirm } = useContext(ModalContext);

    useEffect(() => {
        changeTypeExport("pdf");
    }, []);

    const changeTypeExport = (key) => {
        switch (key) {
            case "pdf":
                setHandleConfirm("export", async () => {
                    return await functionExportToPDF(props);
                });
                break;
            case "csv":
                setHandleConfirm("export", async () => {
                    return await functionExportToCSV(props);
                });
                break;
            case "xlsx":
                setHandleConfirm("export", async () => {
                    return await functionExportToXLSX(props);
                });
                break;
            default:
                break;
        }
    };

    return (
        <Tabs
            destroyInactiveTabPane
            onChange={changeTypeExport}
            defaultActiveKey="pdf"
            items={[
                TabExportToPDF(props),
                TabExportToXLSX(props),
                TabExportToCSV(props),
            ]}
        />
    );
};

export default ModalExport;
