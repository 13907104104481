import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { fromDateToDayMonthYearString } from "helpers/date";
import { capitalize } from "helpers/string";
import { secondsToHms } from "helpers/time";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import useHorizontalScroll from "components/utils/useHorizontalScroll";

const daysRange = 16;

const DateViewer = ({ dateFilter, setDateFilter, refreshToday }) => {
    const smMedia = useMediaPredicate("(min-width: " + Constants.smSize + ")");

    const { t } = useTranslation();
    const timeSelectorRef = useHorizontalScroll(null);

    const scrollToToday = useCallback(() => {
        if (timeSelectorRef.current) {
            const activeDate =
                timeSelectorRef.current.querySelector(".activeDate");
            if (activeDate) {
                timeSelectorRef.current.scrollLeft =
                    activeDate.offsetLeft - activeDate.offsetWidth * 7;
            }
        }
    }, [timeSelectorRef]);

    const isFilterToday =
        moment(dateFilter).format("YYYY-MM-DD") ===
        moment().format("YYYY-MM-DD");
    const isFilterTomorrow =
        moment(dateFilter).format("YYYY-MM-DD") ===
        moment().add(1, "day").format("YYYY-MM-DD");
    const isFilterYesterday =
        moment(dateFilter).format("YYYY-MM-DD") ===
        moment().subtract(1, "day").format("YYYY-MM-DD");

    const [daysToShow, setDaysToShow] = useState([]);

    const updateDaysToShow = useCallback(() => {
        // On affiche uniquement la semaine courante, et on change de semaine en semaine
        const startOfWeek = moment(dateFilter).startOf("week");
        const currentWeekDays = Array.from({ length: 7 }, (_, i) =>
            startOfWeek.clone().add(i, "days").format("YYYY-MM-DD"),
        );
        setDaysToShow(currentWeekDays);
    }, [dateFilter, smMedia]);

    useEffect(() => {
        updateDaysToShow();
        setTimeout(() => {
            scrollToToday();
        }, 100);
    }, [dateFilter, smMedia, updateDaysToShow]);

    const handlePrevWeek = () => {
        setDateFilter(
            moment(dateFilter).subtract(1, "week").format("YYYY-MM-DD"),
        );
    };

    const handleNextWeek = () => {
        setDateFilter(moment(dateFilter).add(1, "week").format("YYYY-MM-DD"));
    };

    return (
        <div>
            <div className="flex flex-row my-2">
                <div className="w-full">
                    {smMedia && (
                        <Fragment>
                            {isFilterToday &&
                                `${capitalize(t("aujourdhui"))} : `}
                            {isFilterTomorrow &&
                                `${capitalize(t("demain"))} : `}
                            {isFilterYesterday && `${capitalize(t("hier"))} : `}
                        </Fragment>
                    )}
                    {capitalize(
                        fromDateToDayMonthYearString(dateFilter, [
                            "yearNumber",
                        ]),
                    )}
                </div>
                <div className="flex flex-row">
                    <button
                        type="button"
                        className="flex px-1"
                        onClick={handlePrevWeek}
                    >
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "2rem" }}
                        >
                            chevron_left
                        </i>
                    </button>
                    <button
                        type="button"
                        className="flex px-1"
                        onClick={handleNextWeek}
                    >
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "2rem" }}
                        >
                            chevron_right
                        </i>
                    </button>
                </div>
            </div>

            <div
                ref={timeSelectorRef}
                className="w-full no-scrollbar overflow-x-scroll flex gap-1 sm:gap-6 py-4"
            >
                {daysToShow.map((date, i) => (
                    <DayViewer
                        key={date}
                        date={date}
                        dateFilter={dateFilter}
                        setDateFilter={setDateFilter}
                        forResfreshToday={
                            date === dateFilter ? refreshToday : null
                        }
                    />
                ))}
            </div>
        </div>
    );
};

const DayViewer = ({ date, dateFilter, setDateFilter, forResfreshToday }) => {
    const isToday = date === moment().format("YYYY-MM-DD");
    const isActive = dateFilter === date;
    const { scheduleConfiguration } = useProfileStore();
    const [timeWorked, setTimeWorked] = useState(null);

    useEffect(() => {
        axios
            .post(apiURL.getTimeWorkedTimeEntries, {
                start: date,
                end: date,
                scheduleConfiguration: scheduleConfiguration
                    ? {
                          isMorningBreakHidden:
                              scheduleConfiguration.isMorningBreakHidden,
                          isLunchBreakHidden:
                              scheduleConfiguration.isLunchBreakHidden,
                      }
                    : null,
            })
            .then((e) => setTimeWorked(e.data));
    }, [forResfreshToday, scheduleConfiguration, date]);

    const formattedTime = secondsToHms(timeWorked)
        .replaceAll(" ", "")
        .slice(0, 5);

    return (
        <div
            className={
                "flex flex-col items-center justify-center cursor-pointer scroll-hori flex-1" +
                (isActive ? " activeDate" : " ")
            }
            onClick={() => setDateFilter(date)}
        >
            <span className="text-xs text-slate-400 uppercase">
                {moment(date).locale(i18n.locale).format("dddd").slice(0, 3)}
            </span>
            <div
                className={
                    "font-black text-lg my-0.5 rounded-full w-9 h-9 flex items-center justify-center " +
                    (isActive
                        ? "bg-blue-700 text-white"
                        : isToday
                          ? "text-black bg-slate-300"
                          : "text-black bg-transparent")
                }
            >
                {moment(date).format("D")}
            </div>
            <span
                className={
                    "text-xs " +
                    (isActive
                        ? "text-blue-700 "
                        : isToday
                          ? "text-slate-400"
                          : "text-black")
                }
            >
                {timeWorked !== null ? formattedTime : "--:--"}
            </span>
        </div>
    );
};

export default DateViewer;
