import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import Text from "components/common/Text";

import { useTimesheetStore } from "../TimesheetStore";

import { Flex, Select } from "antd";

const SelectUser = ({ timeEntry, setTimeEntry, isPreview = false }) => {
    const { t } = useTranslation();

    const { adminMembers } = useTimesheetStore();

    return (
        <>
            <Text size="large" bold>
                {capitalize(t("utilisateurs"))}
            </Text>
            <Select
                mode="multiple"
                allowClear
                placeholder={t("rechercherParMotsClesOuUtilisateurs")}
                value={timeEntry.forUsers}
                options={adminMembers
                    .map((e) => {
                        const isUserInProject =
                            timeEntry.project?.allowedTimesheetUsers?.includes(e._id) ||
                            timeEntry.project?.users?.find((k) => k.user?._id === e._id) ||
                            timeEntry.isRecovery;

                        return {
                            disabled: !isUserInProject,
                            label: (
                                <Flex
                                    align="center"
                                    gap="small"
                                    style={{
                                        opacity: isUserInProject ? 1 : 0.5,
                                    }}>
                                    <Text>{e.firstname + " " + e.lastname}</Text>
                                    <Text size="small">{e.email}</Text>
                                    {!isUserInProject && (
                                        <Text size="small" type="secondary">
                                            ( {t("lUtilisateurNestPasAutoriseDansLeProjetSelectionne")} )
                                        </Text>
                                    )}
                                </Flex>
                            ),
                            value: e._id,
                        };
                    })
                    .sort((a, b) => a.disabled - b.disabled)}
                onChange={(value) => {
                    setTimeEntry((e) => ({
                        ...e,
                        forUsers: value,
                    }));
                }}
            />
        </>
    );
};

export default SelectUser;
