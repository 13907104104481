import { toast } from "react-toastify";

import Constants from "utils/constants";

// Affichage d'un message d'alerte sous forme de toast

const ImageToast = ({ image, message }) => (
    <div className="toast-image">
        <img src={image} alt="" />
        <div className="mt-2">{message}</div>
    </div>
);

export async function toastAlert(
    type,
    message,
    image = "",
    isCentered = false,
    noExpire = false,
    customClassName = "",
    onCloseCallback = null,
) {
    switch (type) {
        case "success":
            toast.success(
                image === "" ? (
                    message
                ) : (
                    <ImageToast image={image} message={message}></ImageToast>
                ),
                {
                    className:
                        "toast-alert toast-success" +
                        (isCentered ? " text-center" : "") +
                        (customClassName !== "" ? " " + customClassName : ""),
                    position: "bottom-left",
                    autoClose: !noExpire ? Constants.toastDuration : false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    icon: false,
                    onClose: () => (onCloseCallback ? onCloseCallback() : null),
                },
            );

            break;

        case "warning":
            toast.warn(
                image === "" ? (
                    message
                ) : (
                    <ImageToast image={image} message={message}></ImageToast>
                ),
                {
                    className:
                        "toast-alert toast-warning" +
                        (isCentered ? " text-center" : "") +
                        (customClassName !== "" ? " " + customClassName : ""),
                    position: "bottom-left",
                    autoClose: !noExpire ? Constants.toastDuration : false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    icon: false,
                    onClose: () => (onCloseCallback ? onCloseCallback() : null),
                },
            );

            break;

        case "error":
            toast.error(
                image === "" ? (
                    message
                ) : (
                    <ImageToast image={image} message={message}></ImageToast>
                ),
                {
                    className:
                        "toast-alert toast-error" +
                        (isCentered ? " text-center" : "") +
                        (customClassName !== "" ? " " + customClassName : ""),
                    position: "bottom-left",
                    autoClose: !noExpire ? Constants.toastDuration : false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    draggable: true,
                    icon: false,
                    onClose: () => (onCloseCallback ? onCloseCallback() : null),
                },
            );

            break;

        default:
            break;
    }
}
