import axios from "axios";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import { PopupContext } from "layout/LayoutPopup";

import ActionNomenclatureSection from "./form/ActionNomenclatureSection";
import ActionSection from "./form/ActionSection";
import ConditionSection from "./form/ConditionSection";
import TriggerSection from "./form/TriggerSection";

import {
    Alert,
    Button as AntdButton,
    Checkbox,
    Divider,
    Flex,
    Form,
    Input,
    Space,
    theme,
} from "antd";

const AutomationForm = ({ setFormActive, editedAutomation, type = "visa" }) => {
    const { t } = useTranslation();
    const { toastAlert } = useContext(PopupContext);
    const [loader, setLoader] = useState(false);
    const { token } = theme.useToken();
    const initialFormData = {
        title: "",
        allowAutomaticDetection: false,
        trigger: {
            itemType: "",
            itemAction: "",
            folders: [],
        },
        conditions: [],
        actions: [],
    };

    const [formData, setFormData] = useState(null);
    const [folderMemberType, setFolderMemberType] = useState(null);

    useEffect(() => {
        if (editedAutomation && type) {
            const editedFormData = {
                id: editedAutomation._id,
                title: editedAutomation.title,
                allowAutomaticDetection:
                    editedAutomation.allowAutomaticDetection,
                trigger: {
                    itemType: editedAutomation.trigger.type._id,
                    itemAction: editedAutomation.trigger.action._id,
                    folders: editedAutomation.trigger.folders.map(
                        (folder) => folder._id,
                    ),
                },
                conditions: [],
                actions: [],
            };

            editedAutomation.conditions.forEach((condition) => {
                editedFormData.conditions.push({
                    type: condition.type._id,
                    userType: condition.userType?._id,
                    user: condition.user?._id,
                    operator: condition.operator,
                });
            });

            editedAutomation.actions.forEach((action) => {
                editedFormData.actions.push({
                    type: action.type._id,
                    [type]:
                        type === "visa"
                            ? action[type]
                                ? action[type]._id
                                : ""
                            : action[type]
                              ? action[type]
                              : "",
                    constraint: action.constraint._id,
                    deadlineDays: action.deadlineDays,
                    operator: action.operator,
                    description: action.description,
                    visa: type === "visa" ? action.visa?._id : null,
                    // nomenclature:
                    //     type === "nomenclature" ? action.nomenclature : null,
                });
            });

            setFormData(editedFormData);
        } else setFormData(initialFormData);
    }, [editedAutomation, type]);

    useEffect(() => {
        if (!formData) return;
        // Check if editedAutomation is null or if any folder in formData.trigger.folders doesn't match the folders in editedAutomation.trigger.folders
        if (
            !editedAutomation ||
            !formData.trigger.folders.every((folderId) =>
                editedAutomation.trigger.folders
                    .map((f) => f._id)
                    .includes(folderId),
            )
        ) {
            // Update conditions and reset the user field
            const updatedConditions = formData.conditions.map((condition) => ({
                ...condition,
                user: null,
            }));

            setFormData((prevFormData) => ({
                ...prevFormData,
                conditions: updatedConditions,
            }));

            // Update actions and reset the 'type' field (assuming type is dynamic)
            const updatedActions = formData.actions.map((action) => ({
                ...action,
                [type]: action[type] || "",
            }));

            setFormData((prevFormData) => ({
                ...prevFormData,
                actions: updatedActions,
            }));
        }
    }, [formData?.trigger.folders, type]);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async () => {
        setLoader(true);
        // Vérification des champs du form
        if (isFormValid()) {
            if (editedAutomation) {
                const updateRes = await axios.post(
                    apiURL.updateAutomation,
                    formData,
                );

                if (updateRes && updateRes.data) {
                    setFormActive(false);
                    toastAlert("success", t("lAutomatisationABienEteModifiee"));
                } else {
                    toastAlert(
                        "error",
                        t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                    );
                }
            } else {
                const createRes = await axios.post(
                    apiURL.createAutomation,
                    formData,
                );

                if (createRes && createRes.data) {
                    setFormActive(false);
                    toastAlert("success", t("lAutomatisationABienEteAjoutee"));
                } else {
                    toastAlert(
                        "error",
                        t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                    );
                }
            }
        }
        setLoader(false);
    };

    const onCancel = () => {
        setFormActive(false);
    };

    const isFormValid = () => {
        let isValid = true;

        if (formData?.trigger?.folders[0]) {
            // Check des conditions
            if (formData.conditions.length > 0) {
                formData.conditions.forEach((condition) => {
                    if (!condition.type || !condition.userType)
                        return (isValid = false);
                    else if (
                        condition.userType ===
                            folderMemberType?._id.toString() &&
                        !condition.user
                    )
                        return (isValid = false);
                });
            } else isValid = false;
            // Check des actions
            if (formData.actions.length > 0) {
                formData.actions.forEach((action) => {
                    if (
                        !action.type ||
                        !action.constraint ||
                        (type === "visa" && !action.visa)
                        // || action.deadlineDays === 0
                    )
                        return (isValid = false);
                });
            } else isValid = false;
        } else isValid = false;

        if (!formData?.title || formData?.title?.trim() === "") isValid = false;

        return isValid;
    };

    return (
        formData && (
            <Flex
                vertical
                style={{
                    height: "100%",
                }}
                gap={token.paddingLG}
            >
                <Flex gap={token.paddingLG} wrap="wrap-reverse" align="center">
                    <Form.Item
                        label={<b>{t("titreDeLautomatisation")} :</b>}
                        // name="title"
                        style={{
                            margin: 0,
                            maxWidth: 500,
                            width: "100%",
                        }}
                        layout="vertical"
                    >
                        <Input
                            size="large"
                            name="title"
                            value={formData.title}
                            onChange={onChange}
                            placeholder={t("renseignezUnTitre")}
                            required
                            max={Constants.maxLengthAutomationTitle}
                        />
                    </Form.Item>
                    <Space style={{ marginLeft: "auto" }}>
                        <AntdButton
                            size="large"
                            children={t("enregistrer")}
                            onClick={onSubmit}
                            disabled={!isFormValid() || loader}
                            type="primary"
                            loading={loader}
                        />
                        <AntdButton
                            size="large"
                            children={t("annuler")}
                            onClick={onCancel}
                            disabled={loader}
                        />
                    </Space>
                </Flex>
                <Flex
                    vertical
                    gap={token.paddingLG}
                    className="overflow-y-auto scrollbar-hover"
                    style={{
                        paddingBottom: 60,
                        paddingRight: 20, // for scrollbar width
                        flex: 1,
                    }}
                >
                    <TriggerSection
                        formData={formData}
                        setFormData={setFormData}
                        type={type}
                    />
                    {type === "visa" && <Divider />}
                    <ConditionSection
                        formData={formData}
                        setFormData={setFormData}
                        folderId={formData.trigger?.folders[0]}
                        folderMemberType={folderMemberType}
                        setFolderMemberType={setFolderMemberType}
                        type={type}
                    />

                    <Divider />
                    {type === "visa" && (
                        <ActionSection
                            formData={formData}
                            setFormData={setFormData}
                            folderId={formData.trigger?.folders[0]}
                        />
                    )}
                    {type === "nomenclature" && (
                        <>
                            <Checkbox
                                checked={formData.allowAutomaticDetection}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        allowAutomaticDetection:
                                            e.target.checked,
                                    });
                                }}
                            >
                                {t(
                                    "activerLaDetectiionAutomatiqueDeLaNomenclature",
                                )}
                            </Checkbox>
                            {formData.allowAutomaticDetection && (
                                <>
                                    <Alert
                                        message={
                                            t(
                                                "lorsqueLaDetectionAutomatiqueDeLaNomenclatureEstActiveeUnFichierCorrectementNommeEstValideAutomatiquement",
                                            ) + "."
                                        }
                                        type="info"
                                        showIcon
                                    />
                                    <Alert
                                        message={t("attention")}
                                        description={
                                            <div>
                                                {t(
                                                    "laReconnaissanceEchoueraSiLeNombreDunderscoreDansLeNomDuFichierNeCorrespondPasExactementALaStructureAttendue",
                                                ) + "."}
                                                <div className="font-bold mt-2">
                                                    {t(
                                                        "nomenclatureExempleValide",
                                                    )}
                                                </div>
                                                <div>
                                                    20240210_Plan Facade (1
                                                    underscore)
                                                </div>
                                                <div className="font-bold mt-2">
                                                    {t(
                                                        "nomenclatureExempleInvalide",
                                                    )}
                                                </div>
                                                <div>
                                                    20240210_Plan_Facade (3
                                                    underscores)
                                                </div>
                                            </div>
                                        }
                                        type="warning"
                                        showIcon
                                    />
                                </>
                            )}
                            <ActionNomenclatureSection
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </>
                    )}
                </Flex>
            </Flex>
        )
    );
};

export default AutomationForm;
