import { useState } from "react";

import AutoCompleteTextField from "components/input/AutoCompleteTextField";

const RenderListField = ({
    title,
    options,
    _id,
    value,
    onChange,
    isPreview,
}) => {
    const [availablesValues, setAvailablesValues] = useState();

    const onClickChoice = (e) => {
        if (!isPreview) {
            onChange(e);
        }
    };

    const onChangeFilter = (e) => {
        setAvailablesValues(
            options.filter((k) => k.text.includes(e.target.value)),
        );
    };

    return (
        <AutoCompleteTextField
            text={title}
            placeholder={title}
            name={_id}
            defaultValue={value || ""}
            availableValues={availablesValues?.map((e) => ({ id: e, text: e }))}
            setAvailableValues={setAvailablesValues}
            onClickChoice={onClickChoice}
            onChange={onChangeFilter}
            // clearOnClick={isPreview}
            onClickInput={() => {
                setAvailablesValues(options);
            }}
        />
    );
};

export default RenderListField;
