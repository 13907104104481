import i18n from "i18next";
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { sendEmailActivation } from "helpers/email";
import { checkEmailExist, fieldValidation } from "helpers/fieldValidation";
import Constants from "utils/constants";

import Button from "components/input/Button";
import Checkbox from "components/input/Checkbox";
import TextField from "components/input/TextField";
import HoneyPot from "components/utils/HoneyPot";
import Spinner from "components/utils/Spinner";

import { register } from "actions/auth";
import { createProfile } from "actions/profile";

const LoginRegisterForm = ({ register, createProfile, setRegisterForm }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);
    const [formSuccess, setFormSuccess] = useState(false);
    const [formError, setFormError] = useState(false);

    const [formData, setFormData] = useState({
        honeyPot: "",
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordConfirm: "",
        okConditions: false,
        subscription: null,
        isFormProcess: false,
    });

    const [fieldsError, setFieldsError] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordConfirm: "",
        okConditions: "",
    });

    useEffect(() => {
        // On passe les messages de warning si une érreur non critique est survenue (par exmple upload de l'avatar)

        if (formSuccess)
            navigate("/register-success", {
                state: {
                    isFromRegister: true,
                    email: formData.email,
                },
            });
    }, [formSuccess]);

    useEffect(() => {
        // On passe l'info que l'on vient bien du formulaire Register, afin de ne pas rediriger l'utilisateur sur l'accueil

        if (formError)
            navigate("/register-error", { state: { isFromRegister: true } });
    }, [formError]);

    const onChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:
                e.target.name === "email"
                    ? e.target.value.toLowerCase()
                    : e.target.value,
        });
    };

    const onCheck = (e) => {
        if (formData[e.target.name] === false) {
            setFormData({ ...formData, [e.target.name]: true });
        } else {
            setFormData({ ...formData, [e.target.name]: false });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0 && formData.honeyPot === "") {
            var newUser = {
                firstname: formData.firstname,
                lastname: formData.lastname,
                email: formData.email,
                password: formData.password,
                language: i18n.locale,
                subscription: null,
            };

            try {
                // On définit le fait que l'on est en train de traiter le formulaire

                setFormData({ ...formData, isFormProcess: true });
                const registerUser = await register(newUser);

                if (registerUser) {
                    // Création du profil de l'utilisateur

                    const newProfile = {
                        userId: registerUser.data.userId,
                        email: formData.email,
                    };

                    try {
                        const profile = await createProfile(newProfile);
                        let transaction = null;

                        if (profile) {
                            const sendedEmail = await sendEmailActivation(
                                formData.email,
                                transaction && transaction.data
                                    ? transaction.data._id
                                    : null,
                            );
                            if (sendedEmail) setFormSuccess(true);
                            else {
                                // Si erreur lors de l'envoi du mail, on redirige quand même mais avec un message de warning

                                toastAlert(
                                    "warning",
                                    t(
                                        "lEmailNaPasPuEtreEnvoyeVeuillezReesseyaerPlusTard",
                                    ),
                                );
                                setFormSuccess(true);
                            }
                        } else setFormError(true);
                    } catch (error) {
                        setFormError(true);
                    }
                } else {
                    setFormError(true);
                }
            } catch (error) {
                setFormError(true);
            }
        }

        setLoader(false);
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        validation = fieldValidation(formData.firstname, "required");
        updatedFieldsError.firstname = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.lastname, "required");
        updatedFieldsError.lastname = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.email, "required|email");
        updatedFieldsError.email = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.password, "required|password");
        updatedFieldsError.password = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(
            formData.passwordConfirm,
            "required|password|confirmPassword",
            formData.password,
        );
        updatedFieldsError.passwordConfirm = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.okConditions, "termsOfUse");
        updatedFieldsError.okConditions = validation;
        if (validation !== "") nbErrors++;

        setFieldsError({ ...fieldsError, updatedFieldsError });

        // Si aucune erreur classique sur le champ email, on check qu'il n'existe pas déjà en BDD

        if (updatedFieldsError.email === "") {
            try {
                const res = await checkEmailExist(formData.email);

                if (res !== "") {
                    updatedFieldsError.email = res;
                    setFieldsError({
                        ...fieldsError,
                        email: updatedFieldsError.email,
                    });
                    nbErrors++;
                }
            } catch (err) {
                updatedFieldsError.email = "";
                setFieldsError({
                    ...fieldsError,
                    email: updatedFieldsError.email,
                });
            }
        }

        if (nbErrors === 0) {
            // On vérifie que la longueur minimum est bien respectée

            if (formData.firstname.length < Constants.minFirstname) {
                updatedFieldsError.firstname =
                    t("veuillezSaisirAuMoins") +
                    " " +
                    Constants.minFirstname +
                    " " +
                    t("caracteres");
                nbErrors++;
            }

            if (formData.lastname.length < Constants.minLastname) {
                updatedFieldsError.lastname =
                    t("veuillezSaisirAuMoins") +
                    " " +
                    Constants.minLastname +
                    " " +
                    t("caracteres");
                nbErrors++;
            }

            setFieldsError({ ...fieldsError, updatedFieldsError });
        }

        return nbErrors;
    };

    return (
        <form className="mt-8">
            <HoneyPot value={formData.honeyPot} onChange={onChange} />

            <div className="mt-4">
                <TextField
                    placeholder={t("prenom")}
                    name="firstname"
                    maxLength={Constants.maxLengthFirstname}
                    onChange={onChange}
                    value={formData.firstname}
                    errorMsg={fieldsError.firstname}
                />
            </div>

            <div className="mt-4">
                <TextField
                    placeholder={t("nom")}
                    name="lastname"
                    maxLength={Constants.maxLengthLastname}
                    onChange={onChange}
                    value={formData.lastname}
                    errorMsg={fieldsError.lastname}
                />
            </div>

            <div className="mt-4">
                <TextField
                    placeholder={t("email")}
                    name="email"
                    maxLength={Constants.maxLengthEmail}
                    type="email"
                    onChange={onChange}
                    value={formData.email}
                    errorMsg={fieldsError.email}
                />
            </div>

            <div className="mt-4">
                <TextField
                    placeholder={t("motDePasse")}
                    name="password"
                    maxLength={Constants.maxLengthPassword}
                    type="password"
                    onChange={onChange}
                    value={formData.password}
                    errorMsg={fieldsError.password}
                />
            </div>

            <div className="mt-4">
                <TextField
                    placeholder={t("confirmationDuMotDePasse")}
                    name="passwordConfirm"
                    maxLength={Constants.maxLengthPassword}
                    type="password"
                    onChange={onChange}
                    value={formData.passwordConfirm}
                    errorMsg={fieldsError.passwordConfirm}
                />
            </div>

            <div className="inline-block text-black mt-8">
                <Checkbox
                    text={t("jaccepteLes") + " " + t("conditionsDutilisation")}
                    name="okConditions"
                    onChange={onCheck}
                    errorMsg={fieldsError.okConditions}
                />
            </div>

            <div className="mt-4">
                <Button
                    type="submit"
                    text={t("sInscrire")}
                    onClick={onSubmit}
                    isDisabled={loader}
                    addPadding={true}
                />
            </div>

            <div className="w-full sm:text-center md:text-left lg:text-center mt-5">
                <div className="inline-block text-xs text-black">
                    {t("dejaUnCompte?")}
                </div>

                <div className="font-bold underline text-xs inline-block ml-2 hover:cursor-pointer">
                    <button
                        type="button"
                        onClick={(e) => setRegisterForm(false)}
                        className="text-primary underline whitespace-nowrap"
                    >
                        {t("seConnecter")}
                    </button>
                </div>
            </div>

            {loader && <Spinner />}
        </form>
    );
};

LoginRegisterForm.propTypes = {
    register: PropTypes.func.isRequired,
    createProfile: PropTypes.func.isRequired,
};

export default connect(null, {
    register,
    createProfile,
})(LoginRegisterForm);
