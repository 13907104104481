import axios from "axios";
import i18n from "i18next";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getVisaDeadlineDaysList } from "helpers/visa";
import apiURL from "utils/apiURL";

import ActionLine from "./ActionLine";

const ActionSection = ({ formData, setFormData, folderId }) => {
    const { t } = useTranslation();

    const actionItem = {
        type: "",
        visa: "",
        constraint: "",
        deadlineDays: 0,
        operator: "",
    };

    const [actionsList, setActionsList] = useState([]);

    const [actionTypes, setActionTypes] = useState([]);
    const [actionConstraints, setActionConstraints] = useState([]);
    const [visas, setVisas] = useState(null);

    // Nombre de jours possibles pour approuver dans les temps le visa
    const deadlineDays = getVisaDeadlineDaysList();

    useEffect(() => {
        if (actionsList.length === 0) {
            setActionsList(
                formData.actions.length > 0 ? formData.actions : [actionItem],
            );

            return;
        }

        if (formData.conditions.length > 0)
            setFormData({ ...formData, actions: actionsList });
    }, [actionsList]);

    useEffect(() => {
        const getActionTypes = async () => {
            const resTypes = await axios.get(
                apiURL.getAllAutomationActionTypes,
            );

            if (resTypes && resTypes.data) {
                const typesList = [];

                resTypes.data
                    .filter((type) => ["ADD_VISA"].includes(type.code))
                    .sort((a, b) => (a.position > b.position ? 1 : -1))
                    .map((type) => {
                        return typesList.push({
                            val: type._id,
                            label: type["title" + i18n.locale],
                            code: type.code,
                        });
                    });

                setActionTypes(typesList);
            }
        };

        getActionTypes();
    }, []);

    useEffect(() => {
        if (actionTypes.length > 0) {
            const getActionConstraints = async () => {
                const resConstraints = await axios.get(
                    apiURL.getAllAutomationActionConstraints,
                );

                if (resConstraints && resConstraints.data) {
                    const constraintsList = [];

                    resConstraints.data
                        .filter((type) =>
                            ["TO_BE_APPROVED_WITHIN"].includes(type.code),
                        )
                        .sort((a, b) => (a.position > b.position ? 1 : -1))
                        .map((constraint) => {
                            return constraintsList.push({
                                val: constraint._id,
                                label: constraint["title" + i18n.locale],
                            });
                        });

                    setActionConstraints(constraintsList);
                }
            };

            getActionConstraints();
        }
    }, [actionTypes]);

    useEffect(() => {
        if (folderId) {
            const getVisas = async () => {
                try {
                    const resVisas = await axios.get(
                        apiURL.getFolderVisas + folderId,
                    );

                    if (resVisas && resVisas.data) {
                        const visasList = [];

                        resVisas.data.map((visa) => {
                            return visasList.push({
                                val: visa._id,
                                label: visa.title,
                            });
                        });

                        setVisas(visasList);
                    }
                } catch (err) {
                    setVisas([]);
                }
            };

            getVisas();
        } else setVisas([]);
    }, [folderId]);

    const addAction = () => {
        const updatedList = actionsList;
        updatedList.push(actionItem);
        setActionsList([...updatedList]);
    };

    const deleteAction = (index) => {
        const updatedList = [...actionsList];
        updatedList.splice(index, 1);
        setActionsList(updatedList);
    };

    const onChange = (index, e) => {
        const updatedList = [...actionsList];
        updatedList[index][e.target.name] = e.target.value;
        setActionsList(updatedList);
    };

    const updateValue = (index, name, value) => {
        const updatedList = [...actionsList];
        updatedList[index][name] = value;
        setActionsList(updatedList);
    };

    return (
        <div className="card border-0 shadow-sm p-4 mx-2 mt-8 text-sm">
            {actionsList.map((action, i) => (
                <ActionLine
                    key={i}
                    index={i}
                    actionDatas={action}
                    updateValue={updateValue}
                    onChange={onChange}
                    deleteRow={deleteAction}
                    folderId={folderId}
                    actionTypes={actionTypes}
                    actionConstraints={actionConstraints}
                    visas={visas}
                    deadlineDays={deadlineDays}
                />
            ))}
            <div className="flex flex-row">
                <div className="w-[200px]"></div>
                <div className="w-full">
                    <button
                        type="button"
                        className="p-2 mt-2 font-bold text-primary flex"
                        onClick={(e) => addAction()}
                    >
                        <i
                            className="material-icons notranslate mr-1"
                            style={{ fontSize: "1.2rem" }}
                        >
                            add
                        </i>
                        {t("ajouterUneAction")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ActionSection;
