import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Col, Form, Input, Row } from "antd";

const FormInfos = () => {
    const { t } = useTranslation();

    const form = Form.useFormInstance();
    const country = Form.useWatch(["workAddress", "country"], form);

    return (
        <Form.Item
            label={
                <Text size="large" bold>
                    {t("adresseDuChantier")}
                </Text>
            }>
            <Row gutter={[24, 0]}>
                <Col span={24}>
                    <Form.Item name={["workAddress", "street"]} label={t("adresse")}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} span={24}>
                    <Form.Item name={["workAddress", "city"]} label={t("ville")}>
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={8} span={24}>
                    <Form.Item name={["workAddress", "code"]} label={t("codePostal")}>
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col md={8} span={24}>
                    <Form.Item name={["workAddress", "country"]} label={t("pays")}>
                        <ReactFlagsSelect
                            placeholder={t("pays")}
                            selectedSize={14}
                            optionsSize={14}
                            searchable={true}
                            searchPlaceholder={t("rechercher")}
                            className="react-flags"
                            countries={["CH"]}
                            onSelect={(e) => {
                                form.setFieldValue(["workAddress", "country"], e);
                            }}
                            selected={country}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item>
    );
};

export default FormInfos;
