import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { pdf } from "@react-pdf/renderer";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { getAppImage } from "helpers/image";
import { capitalize } from "helpers/string";

import { BooleanPrint } from "./fields/Boolean";
import { DatePrint } from "./fields/Date";
import { NumberPrint } from "./fields/Number";
import { ParagraphPrint } from "./fields/Paragraph";
import { PicturePrint } from "./fields/Picture";
import { ReferencePrint } from "./fields/Reference";
import { SelectCheckboxPrint } from "./fields/SelectCheckbox";
import { SelectListPrint } from "./fields/SelectList";
import { SelectRadioPrint } from "./fields/SelectRadio";
import { SeparatorPrint } from "./fields/Separator";
import { SignaturePrint } from "./fields/Signature";
import { StatePrint } from "./fields/State";
import { SubtitlePrint } from "./fields/Subtitle";
import { TextLongPrint } from "./fields/TextLong";
import { TextShortPrint } from "./fields/TextShort";
import { TimePrint } from "./fields/Time";
import { TitlePrint } from "./fields/Title";
import { WeatherPrint } from "./fields/Weather";
import { ZonePrint } from "./fields/Zone";

export const pdfStyles = {
    title: {
        fontSize: 8,
        fontWeight: "bold",
    },
    text: {
        fontSize: 6,
    },
    margin: {
        marginBottom: 10,
    },
    flexLine: {
        display: "flex",
        flexDirection: "row",
    },
};

Font.register({
    family: "Montserrat",
    fonts: [
        {
            src: "https://fonts.gstatic.com/s/montserrat/v6/zhcz-_WihjSQC0oHJ9TCYC3USBnSvpkopQaUR-2r7iU.ttf",
            fontWeight: 400,
        },
        {
            src: "https://fonts.gstatic.com/s/montserrat/v6/IQHow_FEYlDC4Gzy_m8fcvEr6Hm6RMS0v1dtXsGir4g.ttf",
            fontWeight: 700,
        },
    ],
});

const TemplatePDF = ({ title, reference, updatedDate, creationDate, description, fields, customerConfig }) => {
    const { t } = useTranslation();
    return (
        <Document title={reference + " - " + title}>
            <Page
                wrap
                size="A4"
                style={{
                    fontSize: 10,
                    flexDirection: "column",
                    fontFamily: "Montserrat",
                    paddingBottom: 40,
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
                orientation={"portrait"}>
                {/* ------------------------------------------------------------- HEADER  */}
                <View fixed>
                    <View
                        style={{
                            ...pdfStyles.flexLine,
                            justifyContent: "space-between",
                            paddingBottom: 10,
                        }}>
                        <Image
                            src={{
                                uri: customerConfig?.logoEmail
                                    ? getAppImage("customers/" + customerConfig.logoEmail)
                                    : "/img/logo-email.png",
                            }}
                            style={{
                                width: 100,
                                height: 50,
                                objectFit: "contain",
                                objectPosition: "left",
                            }}
                        />
                        <View
                            style={{
                                fontSize: 8,
                            }}>
                            <Text>
                                {`${t("dateDeLexport")} : ${capitalize(moment().format("dddd DD MM YYYY - HH:mm"))}`}
                            </Text>
                            <Text>
                                {`${t("dateDeCreation")} : ${capitalize(moment(creationDate).format("dddd DD MM YYYY - HH:mm"))}`}
                            </Text>
                            <Text>
                                {`${t("derniereModification")} : ${capitalize(moment(updatedDate).format("dddd DD MM YYYY - HH:mm"))}`}
                            </Text>
                        </View>
                    </View>
                </View>

                <View>
                    <Text
                        style={{
                            fontSize: 12,
                            fontWeight: "bold",
                            marginBottom: 10,
                        }}>
                        {reference} - {title}
                    </Text>
                    <Text
                        style={{
                            fontSize: 8,
                            marginBottom: 10,
                        }}>
                        {description}
                    </Text>
                </View>

                {/* ------------------------------------------------------------- CONTENT TABLE */}

                <View>
                    {fields.map((field, index) => (
                        <RenderWrapperPrint key={index} field={field} index={index} />
                    ))}
                </View>

                {/* ------------------------------------------------------------- FOOTER */}
                <View
                    fixed
                    style={{
                        position: "absolute",
                        bottom: 20,
                        right: 20,
                        fontSize: 8,
                    }}>
                    <Text render={({ pageNumber, totalPages }) => `Page ${pageNumber} / ${totalPages}`} />
                </View>
            </Page>
        </Document>
    );
};

export const functionExportToPDF = async (props) => {
    await pdf(<TemplatePDF {...props} />)
        .toBlob()
        .then((blob) => {
            let exportUrl = URL.createObjectURL(blob);

            window.open(exportUrl);

            // var a = document.createElement("a");
            // document.body.appendChild(a);
            // a.style = "display: none";
            // a.href = exportUrl;
            // a.download = props.title;
            // a.click();
            // window.URL.revokeObjectURL(exportUrl);
        });
};

export const RenderWrapperPrint = ({ field, index }) => {
    if (field.type === "SEPARATOR")
        return (
            <View
                style={{
                    border: "1px solid black",
                    borderLeft: 0,
                    borderRight: 0,
                    padding: 6,
                    paddingTop: 20,
                    borderTop: index === 0 ? "1px solid black" : 0,
                }}></View>
        );

    if (field.type === "REFERENCE") return <RenderFieldPrint field={field} index={index} key={index} />;

    if (field.type === "TITLE" || field.type === "SUBTITLE" || field.type === "PARAGRAPH")
        return (
            <View
                style={{
                    border: "1px solid black",
                    backgroundColor: "#f5f5f5",
                    padding: 6,
                    borderTop: index === 0 ? "1px solid black" : 0,
                }}>
                <RenderFieldPrint field={field} index={index} key={index} />
            </View>
        );

    return (
        <View
            wrap={false}
            style={{
                ...pdfStyles.flexLine,
            }}>
            <View
                style={{
                    width: "30%",
                    border: "1px solid black",
                    backgroundColor: "#f5f5f5",
                    padding: 6,
                    borderTop: index === 0 ? "1px solid black" : 0,
                }}>
                <Text style={pdfStyles.title}>{field.title}</Text>
            </View>
            <View
                style={{
                    width: "70%",
                    border: "1px solid black",
                    backgroundColor: "#f5f5f5",
                    padding: 6,
                    borderLeft: 0,
                    borderTop: index === 0 ? "1px solid black" : 0,
                }}>
                <RenderFieldPrint field={field} index={index} key={index} />
            </View>
        </View>
    );
};

export const RenderFieldPrint = ({ field, index }) => {
    switch (field.type) {
        case "TITLE":
            return <TitlePrint field={field} index={index} />;
        case "SUBTITLE":
            return <SubtitlePrint field={field} index={index} />;
        case "PARAGRAPH":
            return <ParagraphPrint field={field} index={index} />;
        case "SEPARATOR":
            return <SeparatorPrint field={field} index={index} />;
        case "TEXT_SHORT":
            return <TextShortPrint field={field} index={index} />;
        case "TEXT_LONG":
            return <TextLongPrint field={field} index={index} />;
        case "NUMBER":
            return <NumberPrint field={field} index={index} />;
        case "DATE":
            return <DatePrint field={field} index={index} />;
        case "TIME":
            return <TimePrint field={field} index={index} />;
        case "BOOLEAN":
            return <BooleanPrint field={field} index={index} />;
        case "SELECT_CHECKBOX":
            return <SelectCheckboxPrint field={field} index={index} />;
        case "SELECT_RADIO":
            return <SelectRadioPrint field={field} index={index} />;
        case "SELECT_LIST":
            return <SelectListPrint field={field} index={index} />;
        case "ZONE":
            return <ZonePrint field={field} index={index} />;
        case "PICTURE":
            return <PicturePrint field={field} index={index} />;
        case "SIGNATURE":
            return <SignaturePrint field={field} index={index} />;
        case "STATE":
            return <StatePrint field={field} index={index} />;
        case "WEATHER":
            return <WeatherPrint field={field} index={index} />;
        case "REFERENCE":
            return <ReferencePrint field={field} index={index} />;
        default:
            return <>Champ inconnu</>;
    }
};

export default TemplatePDF;
