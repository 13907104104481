const RangeSlider = ({ value, setValue, min, max, step = 1, color }) => {
    return (
        <input
            type="range"
            min={min}
            max={max}
            value={value}
            step={step}
            onChange={(e) => setValue(parseFloat(e.target.value))}
            className={
                "w-full h-2 rounded-lg appearance-none cursor-pointer" +
                (color ? color : " bg-slate-300")
            }
        />
    );
};

export default RangeSlider;
