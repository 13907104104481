import axios from "axios";
import moment from "moment";

import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";

import { copyToClipBoard } from "helpers/input";
import { capitalize } from "helpers/string";
import { tenderStatus } from "helpers/tender";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import SubmitButtonForm from "components/common/SubmitButtonForm";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { Button, Flex, Form, Input, Select, Table, Tooltip } from "antd";

const ModalGuestsTender = ({ project, refreshAction }) => {
    const { t } = useTranslation();
    const { runMessage } = useContext(PopupContext);
    const { addModalData } = useContext(ModalContext);
    const [form] = Form.useForm();
    const sendInvite = ({ emails }) => {
        Promise.all(
            emails.map((email) =>
                axios.post(apiURL.inviteCallTender, {
                    id: project.tender?._id,
                    email,
                }),
            ),
        )
            .then(() => {
                refreshAction();
                form.resetFields();
                runMessage({
                    content: t("vosInvitationsOntEteEnvoyeesParMailVousPouvezEgalementLesRetrouverDansLaListeSuivante"),
                    type: "success",
                });
                axios.get(apiURL.getInvitesCallTender + project.tender?._id).then((res) => {
                    setInvitesList(res.data.invites);
                });
            })
            .catch((err) => {
                runMessage({ content: t("erreur"), type: "error" });
            });
    };

    const [invitesList, setInvitesList] = useState(project?.tender?.invites || []);
    useEffect(() => {
        const fetchInvites = () => {
            axios.get(apiURL.getInvitesCallTender + project.tender?._id).then((res) => {
                setInvitesList(res.data.invites);
            });
        };

        fetchInvites();
        const intervalId = setInterval(fetchInvites, 30 * 1 * 1000); // 30 secondes

        return () => clearInterval(intervalId);
    }, [project]);

    const [searchInput, setSearchInput] = useState("");
    const filteredList = useMemo(
        () =>
            invitesList.filter(
                (invite) =>
                    invite?.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    invite?.email?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    invite?.phone?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    invite?.sendDate?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    invite?.openedDate?.toLowerCase().includes(searchInput.toLowerCase()) ||
                    invite?.companyName?.toLowerCase().includes(searchInput.toLowerCase()),
            ),
        [invitesList, searchInput],
    );

    return (
        <Flex vertical gap="large">
            <Form
                style={{
                    width: "100%",
                    gap: 6,
                }}
                form={form}
                layout="inline"
                onFinish={sendInvite}>
                {/* <Form.Item
                    style={{
                        width: "100%",
                        maxWidth: 400,
                    }}
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input size="large" placeholder={t("personneDeContact")} />
                </Form.Item> */}
                <Form.Item
                    style={{
                        flex: 1,
                    }}
                    name="emails"
                    rules={[
                        {
                            validator: (_, values) => {
                                if (!values || values.every((e) => e.includes("@") && e.includes("."))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t("unDesEmailsEstInvalide")));
                            },
                        },
                        {
                            required: true,
                        },
                    ]}>
                    <Select
                        placeholder={t("indiquezLeOuLesAdressesEmailsDesDestinataires")}
                        size="large"
                        mode="tags"
                        showSearch={false}
                        suffixIcon={null}
                        open={false}
                        style={{
                            cursor: "text",
                        }}
                        name="email"
                        aria-autocomplete="inline"
                    />
                </Form.Item>
                <Form.Item>
                    <SubmitButtonForm form={form} size="large">
                        {t("inviter")}
                    </SubmitButtonForm>
                </Form.Item>
            </Form>
            <Text size="md" bold>
                {t("listeDesInvites")}
            </Text>
            <Input.Search
                size="large"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder={t("rechercherParNomPrenomEmail")}
            />
            <Table
                size="small"
                pagination={false}
                dataSource={filteredList}
                rowKey={(record) => record._id}
                scroll={{ x: 1450, y: 360 }}
                columns={[
                    {
                        title: t("invite"),
                        dataIndex: "name",
                        render: (_, record) => (
                            <Text size="small" ellipsis>
                                {!!record.name && <b>{record.name}</b>}
                                {!!record.name && <br />}
                                {record.email}
                            </Text>
                        ),
                        width: 250,
                        sorter: (a, b) => a.name.localeCompare(b.name),
                    },
                    {
                        width: 200,
                        title: t("nomDeLentreprise"),
                        dataIndex: "companyName",
                        render: (_, { companyName }) => (
                            <Text size="small" ellipsis>
                                {companyName}
                            </Text>
                        ),
                        sorter: (a, b) => (a.companyName || "").localeCompare(b.companyName),
                    },
                    {
                        width: 150,
                        title: t("nDeTelephone"),
                        dataIndex: "phone",
                        render: (_, { phone }) =>
                            phone ? (
                                <PhoneInput
                                    inputStyle={{
                                        fontSize: 12,
                                    }}
                                    value={phone}
                                    country={"ch"}
                                    specialLabel=""
                                    disabled
                                />
                            ) : (
                                "-"
                            ),
                        sorter: (a, b) => (a.phone || "").localeCompare(b.phone || ""),
                    },
                    {
                        title: t("statut"),
                        dataIndex: "status",
                        width: 120,
                        render: (_, { status, sendDate, comment, name, companyName, openedDate, phone, email }) => (
                            <Button
                                color={
                                    status
                                        ? tenderStatus[status].color
                                        : sendDate
                                          ? tenderStatus["ACCEPTED"].color
                                          : openedDate
                                            ? tenderStatus["OPENED"].color
                                            : tenderStatus["PENDING"].color
                                }
                                variant="outlined"
                                size="small"
                                style={{
                                    pointerEvents: status === "REJECTED" && comment ? "auto" : "none",
                                }}
                                iconPosition="end"
                                icon={
                                    status === "REJECTED" && comment ? (
                                        <MaterialIcons name={"mark_unread_chat_alt"} size="xs" />
                                    ) : null
                                }
                                children={
                                    status ? (
                                        <Text color={tenderStatus[status].color} size="sm">
                                            {t(tenderStatus[status].label)}
                                        </Text>
                                    ) : sendDate ? (
                                        <Text color={tenderStatus["ACCEPTED"].color} size="sm">
                                            {t(tenderStatus["ACCEPTED"].label)}
                                        </Text>
                                    ) : openedDate ? (
                                        <Text color={tenderStatus["OPENED"].color} size="sm">
                                            {t(tenderStatus["OPENED"].label)}
                                        </Text>
                                    ) : (
                                        <Text color={tenderStatus["PENDING"].color} size="sm">
                                            {t(tenderStatus["PENDING"].label)}
                                        </Text>
                                    )
                                }
                                onClick={() => {
                                    if (status === "REJECTED")
                                        addModalData({
                                            key: "comment",
                                            title: (
                                                <Flex vertical gap="small">
                                                    <Text bold size="md">
                                                        {capitalize(t("appelDoffre")) + " - " + t("decline")}
                                                    </Text>
                                                    <Text size="sm">
                                                        {name || ""}
                                                        {companyName ? " - " + companyName : ""}
                                                        <br />
                                                        {email || ""}
                                                        <br />
                                                        {phone ? "+" + phone : ""}
                                                    </Text>
                                                </Flex>
                                            ),
                                            content: (
                                                <Flex vertical gap="small">
                                                    <Text bold>
                                                        {capitalize(moment(sendDate).format("dddd DD/MM/YYYY - HH:mm"))}
                                                    </Text>
                                                    <Text paragraph>{comment}</Text>
                                                </Flex>
                                            ),
                                            cancelText: t("fermer"),
                                            okText: t("repondre"),
                                            handleConfirm: async (comment) => {
                                                return true;
                                            },
                                            okButtonProps: {
                                                variant: "outlined",
                                                color: "primary",
                                                href:
                                                    "mailto:" +
                                                    email +
                                                    "?subject=" +
                                                    `${capitalize(t("appelDoffre"))} : ${project.title}`,
                                                target: "_blank",
                                            },
                                        });
                                }}
                            />
                        ),
                        sorter: (a, b) => a.status.localeCompare(b.status),
                    },
                    {
                        title: t("inviteLe"),
                        width: 120,
                        dataIndex: "creationDate",
                        render: (creationDate) => (
                            <Text size="sm">
                                {creationDate ? (
                                    <>
                                        {moment(creationDate).format("DD/MM/YYYY")} <br />
                                        {moment(creationDate).format("HH:mm")}
                                    </>
                                ) : (
                                    "/"
                                )}
                            </Text>
                        ),
                        sorter: (a, b) => new Date(a.creationDate) - new Date(b.creationDate),
                    },
                    {
                        title: t("consulteLe"),
                        dataIndex: "openedDate",
                        width: 120,
                        render: (_, { openedDate }) => (
                            <Text size="sm">
                                {openedDate ? (
                                    <>
                                        {moment(openedDate).format("DD/MM/YYYY")} <br />
                                        {moment(openedDate).format("HH:mm")}
                                    </>
                                ) : (
                                    "/"
                                )}
                            </Text>
                        ),
                        sorter: (a, b) => new Date(a.openedDate) - new Date(b.openedDate),
                    },
                    {
                        title: t("telechargeLe"),
                        dataIndex: "downloadDate",
                        width: 120,
                        render: (_, { downloadDate }) => (
                            <Text size="sm">
                                {downloadDate ? (
                                    <>
                                        {moment(downloadDate).format("DD/MM/YYYY")} <br />
                                        {moment(downloadDate).format("HH:mm")}
                                    </>
                                ) : (
                                    "/"
                                )}
                            </Text>
                        ),
                        sorter: (a, b) => new Date(a.openedDate) - new Date(b.openedDate),
                    },
                    {
                        title: t("reponduLe"),
                        dataIndex: "sendDate",
                        width: 120,
                        render: (_, { sendDate }) => (
                            <Text size="sm">
                                {sendDate ? (
                                    <>
                                        {moment(sendDate).format("DD/MM/YYYY")} <br />
                                        {moment(sendDate).format("HH:mm")}
                                    </>
                                ) : (
                                    "/"
                                )}
                            </Text>
                        ),
                        sorter: (a, b) => new Date(a.sendDate) - new Date(b.sendDate),
                    },

                    {
                        width: 120,
                        dataIndex: "",
                        render: (_, record) => (
                            <ColumnAction {...record} project={project} setInvitesList={setInvitesList} />
                        ),
                    },
                ]}
            />
        </Flex>
    );
};

const ColumnAction = ({ publicToken, openedDate, project, _id, setInvitesList }) => {
    const { t } = useTranslation();
    const { runMessage, wrapperSyncElement } = useContext(PopupContext);
    const { addModalData } = useContext(ModalContext);
    // Prevent spam email
    const [sendInviteDisabled, setSendInviteDisabled] = useState(false);

    return (
        <Flex align="center" gap="small">
            <Tooltip title={t("copierLeLien")}>
                <Button
                    style={{
                        marginLeft: "auto",
                    }}
                    shape="circle"
                    type="primary"
                    size="small"
                    onClick={() => {
                        copyToClipBoard(
                            process.env.REACT_APP_BASE_URL + "tender/" + project.tender?._id + "/" + publicToken,
                        )
                            .then(() => {
                                runMessage({
                                    content: t("copieDansLePressePapier"),
                                    type: "success",
                                });
                            })
                            .catch((err) => {
                                runMessage({
                                    content: t("erreur"),
                                    type: "error",
                                });
                            });
                    }}
                    children={<MaterialIcons name="link" size="xs" />}
                />
            </Tooltip>
            <Tooltip
                title={
                    <>
                        {t("envoyerLeMailANouveau")}
                        {openedDate && (
                            <>
                                <br />
                                <Text size="xs" color="white">
                                    {t("consulteLe")} {moment(openedDate).format("DD/MM/YYYY - HH:mm")}
                                </Text>
                            </>
                        )}
                    </>
                }>
                <Button
                    shape="circle"
                    type="primary"
                    size="small"
                    disabled={sendInviteDisabled || !!openedDate}
                    onClick={() => {
                        setSendInviteDisabled(true);
                        axios
                            .post(apiURL.emailInviteTender, {
                                tenderId: project.tender?._id,
                                publicToken,
                            })
                            .then(() => {
                                runMessage({
                                    content: t("unEmailAEteEnvoyeALadresse"),
                                    type: "success",
                                });
                            })
                            .catch((err) => {
                                setSendInviteDisabled(false);
                                runMessage({
                                    content: "error",
                                    type: "error",
                                });
                            })
                            .finally(() => {
                                setTimeout(() => {
                                    setSendInviteDisabled(false);
                                }, 5000);
                            });
                    }}
                    children={<MaterialIcons name="outgoing_mail" size="xs" />}
                />
            </Tooltip>
            <Tooltip title={t("supprimer")}>
                <Button
                    shape="circle"
                    type="text"
                    size="small"
                    onClick={() => {
                        addModalData({
                            key: "invite-delete",
                            title: t("supprimerDefinitivement"),
                            content: <Text>{t("voulezVousVraimentSupprimerCetElement?")}</Text>,
                            handleConfirm: async () => {
                                await wrapperSyncElement(async () => {
                                    await axios.delete(apiURL.deleteInviteCallTender + project.tender?._id + "/" + _id);
                                    setInvitesList((prev) => prev.filter((i) => i._id !== _id));
                                });
                            },
                        });
                    }}
                    children={<MaterialIcons name="delete" size="xs" />}
                />
            </Tooltip>
        </Flex>
    );
};

export default ModalGuestsTender;
