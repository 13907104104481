import axios from "axios";
import i18n from "i18next";

import apiURL from "utils/apiURL";

import { getProjectparentsList } from "./project";
import { getCustomerConfig } from "./user";

export function bytesToSize(bytes) {
    const sizes = ["B", "Ko", "Mo", "Go", "To"];
    if (bytes === 0) return "0 Byte";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
}

export function bytesToMegabytes(bytes) {
    const megabytes = bytes / (1024 * 1024);
    return megabytes.toFixed(2);
}

export function bytesToGigabytes(bytes) {
    const gigabytes = bytes / (1024 * 1024 * 1024);
    return gigabytes.toFixed(2);
}

export function gigabytesToMegabytes(gigabytes) {
    const mb = gigabytes * 1024;

    // Check if the last two digits are not "00"
    if (mb % 100 !== 0) {
        return Math.round(mb / 100) * 100; // Round to the nearest 100 MB
    } else {
        return mb; // No rounding needed if already ending in "00 MB"
    }
}

export function megabytesToGigabytes(megabytes) {
    const gigabytes = megabytes / 1024;

    // Check if the last two digits are not "00"
    if (gigabytes % 0.01 !== 0) {
        return parseFloat(gigabytes.toFixed(2)); // Round to 2 decimal places
    } else {
        return gigabytes; // No rounding needed if already exact
    }
}

// Save en BDD et retourne la taille du fichier IFC
export async function getProjectFileSize(projectFile) {
    if (projectFile.size) return bytesToMegabytes(projectFile.size);
    else {
        const sizeRes = await axios.get(
            apiURL.getProjectFileSize + projectFile._id,
        );

        if (sizeRes && sizeRes.data) return bytesToMegabytes(sizeRes.data);
    }

    return null;
}

export async function getProjectSize(projectId, toMegabytes = true) {
    const projectSize = await axios.get(apiURL.getProjectSize + projectId);

    if (projectSize && projectSize.data && projectSize.data > 0)
        return toMegabytes
            ? bytesToMegabytes(projectSize.data)
            : bytesToGigabytes(projectSize.data);

    return null;
}

// Permet de vérifier que l'upload du fichier ne va pas faire dépasser l'espace disponible du Drive
// Uniquement utilisée pour les formules FREE, les autres n'ont pas de limite
export async function isFileUploadAllowed(project, fileSize) {
    const totalStorageUsedSize = await getParentFolderUserStorage(project._id);

    const parents = await getProjectparentsList(project._id);

    let maxStorageSize = 0;

    if (parents.length > 0) {
        if (parents[0].author) {
            if (parents[0].author.subscription.code !== "FREE") return true;

            maxStorageSize = parents[0].author.subscription.storage.size;
        }
    } else if (project.author) {
        if (project.author.subscription.code !== "FREE") return true;

        maxStorageSize = project.author.subscription.storage.size;
    }

    const previsionalSize =
        parseFloat(bytesToGigabytes(totalStorageUsedSize)) +
        parseFloat(bytesToGigabytes(fileSize));

    if (previsionalSize > maxStorageSize) {
        // Si l'utilisateur fait partie d'une config il n'a pas de limite d'upload
        const customerConfig = await getCustomerConfig({});

        if (customerConfig?.isActive) return true;
    }

    return previsionalSize < maxStorageSize;
}

export async function getParentFolderUserStorage(projectId) {
    const parents = await getProjectparentsList(projectId);

    if (parents) {
        let rootProject = projectId;

        if (parents.length > 0) {
            rootProject = parents[0]._id;
        }

        const storageUsedSize = await axios.get(
            apiURL.getProjectAuthorUsedStorage + rootProject,
        );

        return storageUsedSize ? storageUsedSize.data : 0;
    }

    return 0;
}

// Permet d'afficher la taille dans sa bonne unité selon sa valeur
export function formatSizeToDisplay(size) {
    if (size < 1000) return size + " " + i18n.t("mo");
    else if (size >= 1000)
        return megabytesToGigabytes(size) + " " + i18n.t("go");
}
