import { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const Button = ({
    type = "button",
    id,
    text,
    onClick,
    onChange,
    to,
    target = "",
    state,
    icon,
    isDisabled = false,
    isReversed = false,
    isNeutral = false,
    accept,
    fullHeight = false,
    children,
    isMultiple = false,
    isFolderImport = false,
    addPadding = false,
    removePadding = false,
    isBgTransparent = false,
    isWhite = false,
    withoutBorder = false,
}) => {
    const inputRef = useRef(null);

    const [isShowChildren, showChildren] = useState(false);

    useEffect(() => {
        if (children)
            document.addEventListener("click", handleClickOutside, true);

        return () => {
            if (children)
                document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (inputRef.current) {
            if (!inputRef.current.contains(e.target))
                setTimeout(() => showChildren(false));
            else handleChildren();
        }
    };

    const handleChildren = () => {
        var absoluteDiv = document.getElementById("absoluteDiv");

        // Calculate the position relative to the button
        var buttonRect = inputRef.current.getBoundingClientRect();
        absoluteDiv.style.top = buttonRect.top + window.scrollY + "px";
        absoluteDiv.style.left = buttonRect.right + window.scrollX + 24 + "px";

        showChildren(true);
    };

    return to ? (
        <Link
            id={id}
            className={
                "font-bold rounded-lg focus:outline-none focus:shadow-outline w-full border-[3px] text-xs text-center block" +
                (!isReversed && !isNeutral && !isDisabled
                    ? " bg-primary border-primary hover:bg-secondary text-white hover:text-white hover:border-secondary"
                    : !isNeutral && !isDisabled
                      ? (isBgTransparent ? " bg-transparent" : " bg-white") +
                        (isWhite
                            ? " text-white border-white"
                            : " text-primary border-primary")
                      : "") +
                (isBgTransparent ? " bg-transparent" : "") +
                (fullHeight ? " h-full" : "") +
                (addPadding ? " p-4" : removePadding ? " py-1 px-2" : " p-2") +
                (isNeutral ? " bg-white border-white text-black" : "") +
                (isDisabled && !isReversed
                    ? " text-white bg-blue-900 border-blue-900 hover:bg-blue-900 hover:border-blue-900 hover:cursor-default"
                    : isDisabled && isReversed
                      ? " text-blue-900 bg-blue-900 border-blue-900 hover:cursor-default"
                      : "")
            }
            to={!isDisabled ? to : ""}
            target={target}
            state={state}
        >
            {icon && (
                <div className="inline-flex align-middle mr-1">
                    <i
                        className="material-icons notranslate"
                        style={{ fontSize: "1.5rem" }}
                    >
                        {icon}
                    </i>
                </div>
            )}
            <div className="inline-block align-middle">{text}</div>
        </Link>
    ) : onClick || children ? (
        <Fragment>
            <button
                id={id}
                ref={inputRef}
                className={
                    "font-bold rounded-lg focus:outline-none focus:shadow-outline w-full border-[3px] text-xs" +
                    (!isReversed && !isNeutral && !isDisabled
                        ? " bg-primary border-primary hover:bg-secondary text-white hover:border-secondary"
                        : !isNeutral && !isDisabled
                          ? (isBgTransparent
                                ? " bg-transparent "
                                : " bg-white") +
                            (isWhite
                                ? " text-white border-white"
                                : " text-primary border-primary")
                          : "") +
                    (isBgTransparent
                        ? " bg-transparent hover:bg-transparent"
                        : "") +
                    (fullHeight ? " h-full" : "") +
                    (addPadding
                        ? " p-4"
                        : removePadding
                          ? " py-1 px-2"
                          : " p-2") +
                    (isNeutral ? " bg-white border-white text-black" : "") +
                    (isDisabled
                        ? " text-white bg-blue-900 border-blue-900 hover:bg-blue-900 hover:border-blue-900"
                        : "") +
                    (withoutBorder ? " border-none" : "")
                }
                type={type}
                onClick={(e) => onClick && onClick(e)}
                disabled={isDisabled}
            >
                {icon && (
                    <div className="inline-flex align-middle mr-1">
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "1.5rem" }}
                        >
                            {icon}
                        </i>
                    </div>
                )}
                <div className="inline-block align-middle">{text}</div>
            </button>
            {children && (
                <div
                    className={
                        "fixed z-10 card bg-primary top-0 left-[calc(100%+50px)] text-white border-0" +
                        (isShowChildren ? " block" : " hidden")
                    }
                    id="absoluteDiv"
                >
                    {children}
                </div>
            )}
        </Fragment>
    ) : (
        type === "file" && (
            <Fragment>
                <button
                    id={id}
                    className={
                        "font-bold p-2 rounded-lg focus:outline-none focus:shadow-outline w-full border-[3px] border-primary text-xs" +
                        (!isReversed && !isNeutral
                            ? " bg-primary hover:bg-secondary text-white hover:border-secondary"
                            : !isNeutral
                              ? " bg-white text-primary"
                              : "") +
                        (fullHeight ? " h-full" : "")
                    }
                    type="button"
                    onClick={(e) => inputRef.current.click()}
                    disabled={isDisabled}
                >
                    {icon && (
                        <div className="inline-flex align-middle mr-1">
                            <i
                                className="material-icons notranslate"
                                style={{ fontSize: "1.5rem" }}
                            >
                                {icon}
                            </i>
                        </div>
                    )}
                    <div className="inline-block align-middle">{text}</div>
                </button>
                <input
                    type={type}
                    accept={accept && accept}
                    multiple={isMultiple}
                    webkitdirectory={isFolderImport ? "true" : null}
                    mozdirectory={isFolderImport ? "true" : null}
                    directory={isFolderImport ? "true" : null}
                    ref={inputRef}
                    onClick={(e) => {
                        e.target.value = null;
                    }}
                    onChange={(e) => onChange(e)}
                    className="hidden"
                />
            </Fragment>
        )
    );
};

export default Button;
