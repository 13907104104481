import { Fragment, useEffect, useState } from "react";

const InputCellText = ({
    placeholder = "",
    name,
    value,
    maxLength,
    type = "text",
    onBlur,
    onKeyDown,
    rows = 1,
    onClick,
    errorMsg,
    setErrorMsg,
    isRequired = false,
    autoComplete = "on",
    isDisabled = false,
    isLastCell = false,
}) => {
    const [defaultValue, setDefaultValue] = useState(value);

    useEffect(() => {
        if (value !== null) setDefaultValue(value);
    }, [value]);

    const Comp = type === "textarea" ? "textarea" : "input";

    return (
        <Fragment>
            <Comp
                placeholder={placeholder}
                className={
                    "w-full text-gray-700 focus:outline-none focus:shadow-outline whitespace-pre-wrap truncate bg-transparent " +
                    (isDisabled ? " opacity-85 " : "")
                }
                id={name}
                name={name}
                value={defaultValue}
                maxLength={maxLength}
                type={type}
                rows={rows}
                onKeyDown={(e) => onKeyDown && onKeyDown(e)}
                onBlur={(e) => onBlur && onBlur(e)}
                onChange={(e) => setDefaultValue(e.target.value)}
                required={isRequired}
                autoComplete={autoComplete}
                readOnly={isDisabled}
                onClick={onClick}
            />
            {errorMsg && !isDisabled && (
                <i
                    onClick={() => {
                        setErrorMsg();
                        setDefaultValue(value);
                    }}
                    className={
                        "hover:cursor-pointer material-icons notranslate text-sm "
                    }
                    style={{ fontSize: "1.2rem" }}
                >
                    refresh
                </i>
            )}
        </Fragment>
    );
};

export default InputCellText;
