import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { useState } from "react";

import RenderFieldItem from "./RenderFieldItem";

export default function GrabContext({ children, setPreviewsField, sortableItems, setSortableItems, form }) {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [activeField, setactiveField] = useState();

    function handleDragStart({ active, over }) {
        if (sortableItems.includes(active.id)) {
        } else {
            setactiveField(active.data.current.item);
        }
    }

    function handleDragOver({ active, over }) {
        const isFieldDragged = active.data.current.type === "fields";

        const isTargetForm = over?.data?.current?.sortable?.containerId === "form" || over?.id === "form";

        if (isFieldDragged && isTargetForm) setPreviewsField(active.data.current.item);
    }

    function handleDragEnd({ active, over }) {
        const isTargetForm = over?.data?.current?.sortable?.containerId === "form" || over?.id === "form";

        const isFieldDragged = active.data.current.type === "fields";

        //  Drag : Fields Column => Form Column
        if (isFieldDragged && isTargetForm) {
            let fields = form.getFieldValue("fields");
            const target = fields.findIndex((e) => e._id === over?.id) + 1;
            const source = active.data.current.item;

            fields.splice(target, 0, convertToNewFieldByType(source));
            form.setFieldValue("fields", fields);
            setSortableItems(fields.map((e) => e._id));
        }

        //  Drag : Form Column => Form Column
        if (!isFieldDragged && isTargetForm) {
            let fields = form.getFieldValue("fields");
            const target = fields.findIndex((e) => e._id === over?.id);
            const source = fields.find((e) => e._id === active.id);
            fields = fields.filter((e) => e._id !== active.id);
            fields.splice(target, 0, source);
            form.setFieldValue("fields", fields);
            setSortableItems(fields.map((e) => e._id));
        }

        setPreviewsField(null);
        setactiveField(null);
    }

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
            autoScroll>
            {children}
            <DragOverlay>
                {activeField && <RenderFieldItem _id={activeField._id} type={activeField.code} />}
            </DragOverlay>
        </DndContext>
    );
}

const convertToNewFieldByType = (field) => {
    if (field.code === "REFERENCE") {
        return {
            type: field.code,
            title: "",
            description: "",
            options: {
                templates: [],
                status: ["DRAFT", "PENDING", "APPROVED", "REJECTED"],
            },
            required: false,
            defaultValue: "",
            _id: field._id,
        };
    }

    return {
        type: field.code,
        title: "",
        description: "",
        options: [],
        required: false,
        defaultValue: "",
        _id: field._id,
    };
};
