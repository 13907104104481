import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import Constants from "utils/constants";

import Button from "components/input/Button";
import DropDownButton from "components/input/DropDownButton";

import DocumentMobileBottomContent from "./DocumentMobileBottomContent";
import DocumentReader from "./DocumentReader";

const DocumentCompare = ({
    isFromViewer = false,
    setIsCompareDocuments,
    fileProps,
}) => {
    const { t } = useTranslation();
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    const availableVersions = fileProps.file.versions.filter(
        (e) => e._id !== fileProps.file._id,
    );

    const [selectedFiles, setSelectedFiles] = useState([
        fileProps.file,
        availableVersions[0],
    ]);

    return (
        <div
            style={{
                pointerEvents: "auto",
            }}
            className={
                "fixed w-screen h-screen left-0 top-0 z-[99] bg-black/95 flex flex-col"
            }
        >
            <div className="z-[101] bg-slate-50 w-full flex px-8 h-[80px] md:h-[100px] py-3 md:py-4">
                <div className="flex w-full">
                    <div className="md:w-1/2 flex flex-col">
                        <div className="mt-auto font-bold text-base md:text-xl ml-4">
                            {t("comparaisonDesVersions")}
                        </div>
                        <div className="mt-auto font-bold w-max">
                            <DropDownButton
                                isTransparent
                                text={
                                    <span className="text-sm md:text-lg mr-4">
                                        {selectedFiles[0].title}
                                    </span>
                                }
                                items={fileProps.file.versions.map((e) => ({
                                    text: (
                                        <span
                                            className={
                                                selectedFiles[0]._id === e._id
                                                    ? "text-primary"
                                                    : ""
                                            }
                                        >
                                            {e.title}
                                        </span>
                                    ),
                                    action: () => {
                                        setSelectedFiles([e, selectedFiles[1]]);
                                    },
                                }))}
                                orientation="left"
                            />
                        </div>
                    </div>
                    <div className="flex flex-1 md:w-1/2 justify-between">
                        <div className="hidden md:block mt-auto font-bold pl-4">
                            <DropDownButton
                                isTransparent
                                text={
                                    <span className="text-sm md:text-lg mr-4">
                                        {selectedFiles[1].title}
                                    </span>
                                }
                                items={fileProps.file.versions.map((e) => ({
                                    text: (
                                        <span
                                            className={
                                                selectedFiles[1]._id === e._id
                                                    ? "text-primary"
                                                    : ""
                                            }
                                        >
                                            {e.title}
                                        </span>
                                    ),
                                    action: () => {
                                        setSelectedFiles([selectedFiles[0], e]);
                                    },
                                }))}
                                orientation="left"
                            />
                        </div>
                        <div className="my-auto ml-auto">
                            <Button
                                isReversed
                                withoutBorder
                                isBgTransparent
                                text={
                                    <span className="text-xs md:text-sm">
                                        {t("quitterLaComparaison")}
                                    </span>
                                }
                                onClick={() => setIsCompareDocuments(false)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {lgMedia ? (
                <div
                    className="flex flex-grow overflow-hidden"
                    style={{ height: "inherit" }}
                >
                    <div className="w-1/2 bg-black h-full relative">
                        <DocumentReader
                            fileId={selectedFiles[0]._id}
                            isFromCompare={true}
                            isFromViewer
                        />
                    </div>
                    <div className="w-1/2 bg-black h-full relative">
                        <DocumentReader
                            fileId={selectedFiles[1]._id}
                            isFromCompare={true}
                            isFromViewer
                        />
                    </div>
                </div>
            ) : (
                <div className="bg-black h-full relative">
                    <DocumentReader
                        fileId={selectedFiles[0]._id}
                        isFromCompare={true}
                        isFromViewer
                    />
                </div>
            )}

            {!lgMedia && (
                <DocumentMobileBottomContent
                    content={
                        <Fragment>
                            <div className="font-bold pl-4 mt-4 z-[102] relative">
                                <DropDownButton
                                    isTransparent
                                    text={
                                        <span className="text-sm md:text-lg mr-4">
                                            {selectedFiles[1].title}
                                        </span>
                                    }
                                    items={fileProps.file.versions.map((e) => ({
                                        text: (
                                            <span
                                                className={
                                                    selectedFiles[1]._id ===
                                                    e._id
                                                        ? "text-primary"
                                                        : ""
                                                }
                                            >
                                                {e.title}
                                            </span>
                                        ),
                                        action: () => {
                                            setSelectedFiles([
                                                selectedFiles[0],
                                                e,
                                            ]);
                                        },
                                    }))}
                                    orientation="left"
                                />
                            </div>
                            <div className="bg-black h-[calc(100vh-100px)] relative">
                                <DocumentReader
                                    fileId={selectedFiles[1]._id}
                                    isFromCompare={true}
                                    isFromViewer
                                />
                            </div>
                        </Fragment>
                    }
                />
            )}
        </div>
    );
};

export default DocumentCompare;
