import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { PopupContext } from "layout/LayoutPopup";

import BreakFields from "./BreakFields";
import RenderFields from "./RenderFields";
import SelectDate from "./SelectDate";
import SelectProjectTask from "./SelectProjectTask";
import SelectUser from "./SelectUser";

import { Button, Flex, Tag, theme } from "antd";

const initTimeEntry = {
    project: null,
    timesheetTask: null,
    startDate: moment()
        .set({
            hour: 8,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .toISOString(),
    endDate: moment()
        .set({
            hour: 15,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .toISOString(),
    sendDate: false,
    fields: [],
    data: [],
    forUsers: [],
    breakLunch: 0,
    isRecovery: false,
};

const ModalEntry = ({
    onClose,
    onDelete,
    preData = null,
    getCurrentProfile,
    isPreview = false,
    readOnly = false,
    isFromAdmin = false,
    isMultiple = false,
    canSelectUsers = false,
    canSelectProject = false,
    canSelectDate = false,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const entryRef = useRef(null);
    const [timeEntry, setTimeEntry] = useState({
        ...initTimeEntry,
        ...preData,
    });

    const { scheduleConfiguration } = useProfileStore();

    const [isExistingHours, setExistingHours] = useState(false);
    const { wrapperSyncElement, startElementSyncMessage, successElementSyncMessage } = useContext(PopupContext);

    // Disable all inputs if readOnly
    useEffect(() => {
        if (readOnly) {
            [
                ...entryRef.current.querySelectorAll("select"),
                ...entryRef.current.querySelectorAll("input"),
                ...entryRef.current.querySelectorAll("textarea"),
                ...entryRef.current.querySelectorAll("button"),
            ].forEach((e) => {
                e.disabled = true;
                e.style.cursor = "not-allowed";
            });
        }
    }, [readOnly]);

    const disabledButton = useMemo(() => {
        if (canSelectUsers) {
            if (timeEntry.forUsers?.length === 0) return true;
        }
        if (canSelectProject) {
            if (!timeEntry.project && !timeEntry?.isRecovery) return true;
        }
        if (canSelectDate) {
            if (!timeEntry.startDate || !timeEntry.endDate) return true;
        }
        return false;
    }, [canSelectUsers, canSelectProject, canSelectDate, timeEntry]);

    // Save the entry
    const saveEntry = useCallback(async () => {
        if (isPreview) return;

        startElementSyncMessage();

        // Check si on ne crée pas un duplicata d'heures déjà saisies
        // const isExists = await checkHours();
        // if (isExists) {
        //     setExistingHours(true);
        //     setLoader(false);
        //     entryRef.current.scrollTo({
        //         top: 0,
        //         behavior: "smooth",
        //     });
        //     return;
        // }

        if (isFromAdmin || isMultiple) {
            await onClose(timeEntry);
        } else if (timeEntry._id) {
            const { data } = await axios.patch(apiURL.updateTimesheetEntry + timeEntry._id, {
                data: timeEntry.data.map((e) => ({
                    ...e,
                    timesheetField: e.timesheetField?._id,
                })),
                timesheetTask: timeEntry.timesheetTask,
                project: timeEntry?.isRecovery ? null : timeEntry.project,
                startDate: timeEntry.startDate,
                endDate: timeEntry.endDate,
                breakLunch: timeEntry.breakLunch,
                comment: timeEntry.comment,
                isRecovery: timeEntry?.isRecovery,
            });
            await onClose(data);
        } else {
            await axios.put(apiURL.addRecentlyUsedProject, {
                project: timeEntry.project._id,
            });
            const { data } = await axios.post(apiURL.createTimesheetEntry, {
                data: timeEntry.data.map((e) => ({
                    ...e,
                    timesheetField: e.timesheetField?._id,
                })),
                timesheetTask: timeEntry.timesheetTask?._id,
                timesheetTaskTitle: timeEntry.timesheetTask?.title,
                project: timeEntry?.isRecovery ? null : timeEntry.project._id,
                startDate: timeEntry.startDate,
                endDate: timeEntry.endDate,
                breakLunch: timeEntry.breakLunch,
                comment: timeEntry.comment,
                isRecovery: timeEntry?.isRecovery,
            });
            await onClose(data);
            await getCurrentProfile();
        }
        successElementSyncMessage();
    }, [timeEntry, isFromAdmin, isPreview]);

    const deleteEntry = () => {
        wrapperSyncElement(async () => {
            if (onDelete) await onDelete(timeEntry._id);
            else {
                await axios.delete(apiURL.deleteTimesheetEntries + timeEntry._id);
                onClose();
            }
        });
    };
    // Permet de ne pas saisir deux fois des horaires similaires pour une même journée
    const checkHours = async () => {
        try {
            const isExists = await axios.post(apiURL.checkExistingHoursTimesheetEntries, {
                startDate: timeEntry.startDate,
                endDate: timeEntry.endDate,
                entryId: timeEntry._id,
            });

            return isExists?.data ? true : false;
        } catch (err) {
            return false;
        }
    };
    return (
        <Flex
            vertical
            gap="large"
            style={{
                opacity: readOnly ? 0.6 : 1,
                minHeight: "100%",
            }}
            ref={entryRef}>
            {!readOnly && timeEntry._id && (
                <Button
                    variant="outlined"
                    style={{
                        width: "min-content",
                    }}
                    color="danger"
                    onClick={deleteEntry}
                    icon={<MaterialIcons name="delete" />}>
                    {t("supprimerLaSaisie")}
                </Button>
            )}
            {canSelectUsers && <SelectUser timeEntry={timeEntry} setTimeEntry={readOnly ? () => {} : setTimeEntry} />}
            {canSelectProject && (
                <SelectProjectTask
                    timeEntry={timeEntry}
                    setTimeEntry={setTimeEntry}
                    isFromAdmin={isFromAdmin}
                    isPreview={isPreview}
                />
            )}
            <SelectDate
                timeEntry={timeEntry}
                setTimeEntry={setTimeEntry}
                canSelectDate={canSelectDate}
                isPreview={isPreview}
                readOnly={readOnly}
            />
            {isExistingHours && (
                <Tag
                    color="warning"
                    style={{
                        padding: "6px 16px",
                    }}>
                    <Flex align="center" gap="small">
                        <MaterialIcons name="warning" />
                        <Text
                            color={token.orange}
                            size="small"
                            bold
                            style={{
                                whiteSpace: "pre-wrap",
                            }}>
                            {t("attention") + " - " + t("plageHoraireDejaSaisieVeuillezVerifierEtCorrier")}
                        </Text>
                    </Flex>
                </Tag>
            )}
            {timeEntry?.editionDetails?.wasEdited && (
                <Tag
                    color="processing"
                    style={{
                        padding: "6px 16px",
                    }}>
                    <Flex align="center" gap="small">
                        <MaterialIcons name="info" />
                        <Text
                            color={token.colorPrimary}
                            size="small"
                            style={{
                                whiteSpace: "pre-wrap",
                            }}>
                            {t("cetteSaisieDeTempsAeteEditeePar") +
                                " " +
                                timeEntry.editionDetails.author.firstname +
                                " " +
                                timeEntry.editionDetails.author.lastname +
                                " - " +
                                moment(timeEntry.editionDetails.date).format("DD/MM/YYYY HH:mm")}
                        </Text>
                    </Flex>
                </Tag>
            )}
            {!timeEntry?.isRecovery && (
                <BreakFields
                    timeEntry={timeEntry}
                    setTimeEntry={readOnly ? () => {} : setTimeEntry}
                    configuration={scheduleConfiguration}
                    isPreview={isPreview}
                    readOnly={readOnly}
                />
            )}
            <RenderFields
                setTimeEntry={readOnly ? () => {} : setTimeEntry}
                timeEntry={timeEntry}
                isPreview={isPreview}
            />
            {!readOnly && (
                <Button
                    style={{
                        margin: "auto auto 0 auto",
                        width: 250,
                        maxWidth: "100%",
                    }}
                    variant="solid"
                    color="primary"
                    size="large"
                    disabled={disabledButton}
                    children={t("valider")}
                    onClick={saveEntry}
                />
            )}
        </Flex>
    );
};

export default ModalEntry;
