import axios from "axios";

import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fieldValidation } from "helpers/fieldValidation";
import { isIfcFile, isPcdFile } from "helpers/file";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import CommentTextarea from "components/comment/CommentTextarea";
import { DriveToolsContext } from "components/drive/Drive";
import Button from "components/input/Button";

const VisaTreatment = ({
    visaDatas,
    members,
    user,
    file,
    loader,
    setLoader,
    setIsTreated,
    setIsTreatmentOpen,
}) => {
    const { t } = useTranslation();

    const { setToolsData, refreshAction, toolView } =
        useContext(DriveToolsContext);

    const treatmentRef = useRef(null);

    const [formData, setFormData] = useState({
        content: "",
        image: null,
    });

    const [mentionsList, setMentionsList] = useState([]);

    const initialFieldsError = {
        content: "",
    };
    const [fieldsError, setFieldsError] = useState(initialFieldsError);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (treatmentRef.current) {
            if (!treatmentRef.current.contains(e.target))
                setIsTreatmentOpen(null);
        }
    };

    const onChangeMentions = async (e) => {
        if (e.slice(1).length < 2) {
            setMentionsList([]);
            return;
        }

        const terms = e.slice(1).toLowerCase();

        let resMembersByTerm = [];

        resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (
            resMembersByTerm.filter((item) => item.email !== user.email)
                .length > 0
        ) {
            let updatedList = [];

            resMembersByTerm
                .filter((item) => item.email !== user.email)
                .forEach((user) => {
                    updatedList.push({
                        id: user.email,
                        display:
                            user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.email +
                            ")",
                    });
                });

            setMentionsList(updatedList);
        } else setMentionsList([]);
    };

    const onUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Get the Base64 string from the result
                const base64String = reader.result;

                setFormData({ ...formData, image: base64String });
            };

            reader.readAsDataURL(file);
        }
    };

    const onChange = (e, name) => {
        setFormData({
            ...formData,
            [name ? name : e.target.name]: e.target.value,
        });
    };

    const fieldsValidation = () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        if (visaDatas.status.isCommented || formData.image) {
            validation = fieldValidation(formData.content, "required");
            updatedFieldsError.content = validation;
            if (validation !== "") nbErrors++;
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const onConfirm = async () => {
        setLoader(true);

        try {
            const resValidation = fieldsValidation();

            if (resValidation === 0) {
                setIsTreatmentOpen(false);
                setIsTreated(true);

                const resUpdated = await axios.post(apiURL.updateVisa, {
                    fileId: file._id,
                    visaId: visaDatas.visa._id,
                    isApproved: visaDatas.status.isApproved,
                    isRefused: visaDatas.status.isRefused,
                    isCommented: visaDatas.status.isCommented,
                    isNotConcerned: visaDatas.status.isNotConcerned,
                    comment: formData.content,
                    image: formData.image,
                });

                if (resUpdated && resUpdated.data) {
                    // Si le fichier n'est pas un IFC, on crée également un commentaire lié au visa
                    if (
                        formData.content &&
                        !isIfcFile(file) &&
                        !isPcdFile(file)
                    ) {
                        await axios.post(apiURL.createComment, {
                            title: "",
                            content: formData.content,
                            image: formData.image,
                            author: user,
                            projectFiles: [file._id],
                            visa: visaDatas.visa.visa._id,
                        });
                    }

                    if (toolView !== "visas" && refreshAction)
                        refreshAction(resUpdated.data);
                    else {
                        // Contexte de la page des visas, on va update la bonne entrée dans la liste pour ne pas tout recharger
                        setToolsData((e) => ({
                            ...e,
                            visas: {
                                ...e.visas,
                                updatedVisa: resUpdated.data,
                            },
                        }));
                    }
                } else toastAlert("error", t("uneErreurEstSurvenue"));
            }
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    return (
        <div ref={treatmentRef}>
            {visaDatas?.status && (
                <div className="mt-4">
                    <CommentTextarea
                        value={formData.content}
                        fieldsError={fieldsError}
                        loader={loader}
                        mentionsList={mentionsList}
                        onChange={onChange}
                        onChangeMentions={onChangeMentions}
                    />

                    {!isIfcFile(file) &&
                        !isPcdFile(file) &&
                        formData.content.trim() !== "" && (
                            <>
                                <div className="mt-2">
                                    <button
                                        type="button"
                                        className="p-1"
                                        title={t("importerUnFichier")}
                                        onClick={(e) => {
                                            document
                                                .getElementById(
                                                    "import-file-comment",
                                                )
                                                .click();
                                        }}
                                    >
                                        <i
                                            className="material-icons notranslate"
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            add_photo_alternate
                                        </i>
                                    </button>
                                    <div className="hidden">
                                        <Button
                                            id="import-file-comment"
                                            type="file"
                                            accept={constants.imagesList}
                                            onChange={onUpload}
                                        />
                                    </div>
                                </div>

                                {formData.image && (
                                    <div
                                        style={{
                                            backgroundImage:
                                                "url(" + formData.image + ")",
                                        }}
                                        className="relative flex w-full h-[250px] sm:h-[300px] md:h-[180px] rounded-xl background-img"
                                    >
                                        <button
                                            className="absolute flex right-[-12px] top-[-14px] p-1"
                                            type="button"
                                            onClick={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    image: null,
                                                })
                                            }
                                        >
                                            <i
                                                className="material-icons notranslate m-auto bg-slate-200 rounded-full p-0.5"
                                                style={{ fontSize: "1rem" }}
                                            >
                                                close
                                            </i>
                                        </button>
                                    </div>
                                )}
                            </>
                        )}

                    <div className="mt-3 justify-end">
                        <Button
                            text={t("enregistrer")}
                            onClick={onConfirm}
                            isDisabled={
                                visaDatas?.status?.isCommented &&
                                formData.content.trim().length === 0
                            }
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisaTreatment;
