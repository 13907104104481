export default {
    // Tailes Media Queries
    smSize: "640px",
    mdSize: "768px",
    lgSize: "1024px",
    xlSize: "1280px",

    // Form
    minLengthFirstname: 2,
    minLengthLastname: 2,
    minLengthSubject: 10,
    minLengthMessage: 50,
    maxLengthFirstname: 50,
    maxLengthLastname: 100,
    maxLengthEmail: 200,
    maxLengthPassword: 50,
    maxLengthSubject: 100,
    maxLengthMessage: 2000,
    maxLengthProjectTitle: 50,
    maxLengthFileTitle: 200,
    maxLengthZipCode: 10,
    maxLengthCity: 100,
    maxLengthCommentTitle: 50,
    maxLengthCommentContent: 500,
    maxLengthMailMessage: 1000,
    maxLengthVisaTitle: 10,
    maxLengthAutomationTitle: 100,
    maxLengthURLFileType: 300,

    // Limits
    nbCommentsPageLimit: 50,

    // Paiements
    stripePublicKey: {
        development:
            "pk_test_51MdyvYBFmx4NgVlCHXCpLqOH154yqRZsqFPClWqqDeYKDptMWPrSkVN8Tj1Uh0MlLHlDPS72t8YoHTlSkaaHGawU00mgFIHdh7",
        test: "pk_test_51MdyvYBFmx4NgVlCHXCpLqOH154yqRZsqFPClWqqDeYKDptMWPrSkVN8Tj1Uh0MlLHlDPS72t8YoHTlSkaaHGawU00mgFIHdh7",
        production:
            "pk_live_51MdyvYBFmx4NgVlCEKX7jfAkU7UgamDpeb0MfcIFGxZqeAf5A2SNYXjoj5pClp0HQst1by75sBm0yCF2FdWdaOLi00u38O2vVd",
    },
    stripeClass: "STRIPE",
    yearFrequency: 1,
    monthFrequency: 2,

    // AWS
    awsRegion: "eu-central-2",

    awsS3BucketUsers: {
        development: "bimono-avatars-test",
        test: "bimono-avatars-test",
        production: "bimono-avatars-prod",
    },

    awsS3BucketFiles: {
        development: "bimono-files-test",
        test: "bimono-files-test",
        production: "bimono-files-prod",
    },

    awsS3BucketFilesPreviews: {
        development: "bimono-files-previews-test",
        test: "bimono-files-previews-test",
        production: "bimono-files-previews-prod",
    },

    awsS3BucketFilesProcessed: {
        development: "bimono-files-processed-test",
        test: "bimono-files-processed-test",
        production: "bimono-files-processed-prod",
    },

    // Alertes / Toast
    toastDuration: 5000,

    // Images
    defaultAvatarMan: "avatar-man.png",
    defaultAvatarWoman: "avatar-woman.png",
    avatarS3Path: {
        development:
            "https://bimono-avatars-test.s3.eu-central-2.amazonaws.com/",
        test: "https://bimono-avatars-test.s3.eu-central-2.amazonaws.com/",
        production:
            "https://bimono-avatars-prod.s3.eu-central-2.amazonaws.com/",
    },
    imageS3Path: {
        development: "https://bimono-img-test.s3.eu-central-2.amazonaws.com/",
        test: "https://bimono-img-test.s3.eu-central-2.amazonaws.com/",
        production: "https://bimono-img-prod.s3.eu-central-2.amazonaws.com/",
    },
    paymentPlatformsPath: "payments/",

    // JSON Web token
    jwtSecret: "secrettoken",

    // IFC File size limit (in MB)
    ifcFileMaxSize: 100,
    // IFC File max allowed size to load in Viewer App (in MB)
    ifcFileMaxViewerAllowed: 500,
    allowedFilesTypes: [
        "ifc",
        "IFC",
        "ifczip",
        "IFCZIP",
        "pdf",
        "PDF",
        "doc",
        "DOC",
        "docx",
        "DOCX",
        "csv",
        "CSV",
        "xls",
        "XLS",
        "xlsx",
        "XLSX",
        "mpp",
        "MPP",
        "ppt",
        "PPT",
        "pptx",
        "PPTX",
        "dwg",
        "DWG",
        "rvt",
        "RVT",
        "rfa",
        "RFA",
        "nwc",
        "NWC",
        "nwd",
        "NWD",
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "png",
        "PNG",
        "webp",
        "WEBP",
        "svg",
        "SVG",
        "gif",
        "GIF",
        "vcf",
        "VCF",
        "vsd",
        "VSD",
        "vsdx",
        "VSDX",
        "crbx",
        "CRBX",
        "pcd",
        "PCD",
        "xyz",
        "XYZ",
        "las",
        "LAS",
        "laz",
        "LAZ",
        "obj",
        "OBJ",
        "mp4",
        "MP4",
        "mov",
        "MOV",
        "zip",
        "ZIP",
        "01s",
        "01S",
        "eps",
        "EPS",
        "rcp",
        "RCP",
        "pln",
        "PLN",
        "psd",
        "PSD",
        "pla",
        "PLA",
        "ai",
        "AI",
        "tif",
        "TIF",
        "e57",
        "E57",
        "indd",
        "INDD",
        "tiff",
        "TIFF",
        "lgs",
        "LGS",
        "msg",
        "MSG",
        "url",
        "URL",
        "3dm",
        "3DM",
        "3drhino",
        "3DRHINO",
    ],
    allowedExtensionsList:
        ".ifc,.IFC,.ifczip,.IFCZIP,.pdf,.PDF,.doc,.DOC,.docx,.DOCX,.csv,.CSV,.xls,.XLS,.xlsx,.XLSX,.mpp,.MPP,.ppt,.PPT,.pptx,.PPTX,.dwg,.DWG,.rvt,.RVT,.rfa,.RFA,.nwc,.NWC,.nwd,.NWD,.jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp,.WEBP,.svg,.SVG,.gif,.GIF,.vcf,.VCF,.vsd,.VSD,.vsdx,.VSDX,.crbx,.CRBX,.pcd,.PCD,.xyz,.XYZ,.las,.LAS,.laz,.LAZ,.obj,.OBJ,.mp4,.MP4,.mov,.MOV,.zip,.ZIP,.01s,.01S,.eps,.EPS,Rrcp,.RCP,.pln,.PLN,.psd,.PSD,.pla,.PLA,.ai,.AI,.tif,.TIF,.e57,.E57,.indd,.INDD,.tiff,.TIFF,.lgs,.LGS,.msg,.MSG,.url,.URL,.3dm,.3DM,3drhino,.3DRHINO",
    imagesTypes: [
        "jpg",
        "JPG",
        "jpeg",
        "JPEG",
        "png",
        "PNG",
        "webp",
        "WEBP",
        "svg",
        "SVG",
        "gif",
        "GIF",
    ],
    imagesList:
        ".jpg,.JPG,.jpeg,.JPEG,.png,.PNG,.webp,.WEBP,.svg,.SVG,.gif,.GIF",
    videosTypes: ["mp4", "MP4", "mov", "MOV"],
    notReadableFilesTypes: [
        "dwg",
        "DWG",
        "rvt",
        "RVT",
        "rfa",
        "RFA",
        "nwc",
        "NWC",
        "nwd",
        "NWD",
        "vcf",
        "VCF",
        "mpp",
        "MPP",
        "vsd",
        "VSD",
        "vsdx",
        "VSDX",
        "crbx",
        "CRBX",
        "zip",
        "ZIP",
        "01s",
        "01S",
        "eps",
        "EPS",
        "rcp",
        "RCP",
        "pln",
        "PLN",
        "psd",
        "PSD",
        "pla",
        "PLA",
        "ai",
        "AI",
        "tif",
        "TIF",
        "e57",
        "E57",
        "indd",
        "INDD",
        "tiff",
        "TIFF",
        "lgs",
        "LGS",
        "msg",
        "MSG",
        "url",
        "URL",
        "3dm",
        "3DM",
        "3drhino",
        "3DRHINO",
    ],

    // Viewer sections
    viewerSections: {
        infos: "viewerSectionInfos",
        structure: "viewerSectionStructure",
        comments: "viewerSectionComments",
        miniMap: "viewerSectionMiniMap",
    },
    googleAnalyticsTrackingId: "G-S4KLT07DE9",
    demoBimonoMails: [
        "y.mermin@bimono.io",
        "bastien.chantrel.pro@gmail.com",
        "s.molinas@bimono.io",
        "simon.molinas@bloomagence.com",
        "d.tairraz@bimono.io",
    ],
    timesheetMaxLunchBreakDuration: 60,
    timesheetMaxMorningBreakDuration: 15,
};
