import { useContext, useState } from "react";

import InputCellText from "./InputCellText";
import { TableInlineContext } from "./Table";

const TableNewRow = () => {
    const { dataList, fields, refTableBody, onAddInline } =
        useContext(TableInlineContext);

    return (
        <tr>
            {/* BUTTON ADD  */}
            <td className="hover:text-black align-middle border text-xs whitespace-nowrap  text-gray-300 hover:border-blue-500">
                <button
                    className="w-full py-2"
                    onClick={async () => {
                        await onAddInline({});
                        refTableBody.current.children[dataList.length]
                            .querySelector("input")
                            .focus();
                    }}
                >
                    <i
                        className="material-icons notranslate align-middle"
                        style={{ fontSize: "1.2rem" }}
                    >
                        add
                    </i>
                </button>
            </td>

            {/* FIELDS ADD */}
            {fields.map((field, index) =>
                index === 0 ? (
                    <td
                        key={field.key}
                        className="align-middle justify-center border text-xs whitespace-nowrap hover:border-blue-500 px-2"
                    >
                        <RenderNewText field={field} />
                    </td>
                ) : (
                    <td
                        key={field.key}
                        className="align-middle justify-center border text-xs whitespace-nowrap "
                    ></td>
                ),
            )}
        </tr>
    );
};

const RenderNewText = ({ field }) => {
    const { onAddInline } = useContext(TableInlineContext);

    const [resetValue, setresetValue] = useState("");

    return (
        <InputCellText
            name="newdataLine"
            onKeyDown={async (e) => {
                if (e.target.value && e.key === "Enter") {
                    setresetValue(null);
                    await onAddInline({
                        [field.key]: e.target.value,
                    });
                    setresetValue("");
                }
            }}
            onBlur={async (e) => {
                if (e.target.value) {
                    setresetValue(null);
                    await onAddInline({
                        [field.key]: e.target.value,
                    });
                    setresetValue("");
                }
            }}
            value={resetValue}
            placeholder="Nouvelle saisie ..."
        />
    );
};

export default TableNewRow;
