import { ConfigProvider } from "antd";
import { theme } from "antd";

const DarkThemeComponent = ({ children }) => {
    const { token } = theme.useToken();

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.darkAlgorithm, // Automatic dark theme from previous tokens
                token: {
                    colorBgBase: "#010527",
                    colorTextBase: token.colorBgBase,
                    colorBgContainer: "#010527",
                },
            }}
        >
            {children}
        </ConfigProvider>
    );
};

export default DarkThemeComponent;
