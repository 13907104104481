import axios from "axios";
import JSZip from "jszip";

import apiURL from "utils/apiURL";

import { downloadFile } from "./file";

export function isFavoriteFolder(project, profile) {
    if (
        profile.favoritesFolders
            .filter((favorite) => favorite.project)
            .some((favorite) => favorite.project._id === project._id)
    )
        return true;

    return false;
}

export async function getProjectparentsList(projectId) {
    const parents = await axios.get(apiURL.getProjectParents + projectId);

    if (parents && parents.data) return parents.data;

    return [];
}

export const handleDownloadMultipleEntities = async (
    selectedFiles,
    title = "download",
    guestName = false,
) => {
    try {
        const filesToDownload = selectedFiles
            .filter((selectedFile) => !selectedFile.files)
            .map((selectedFile) => selectedFile._id);
        const foldersToDownload = selectedFiles
            .filter((selectedFile) => selectedFile.files)
            .map((selectedFile) => selectedFile._id);

        // Create a new ZIP instance for the final output
        const mainZip = new JSZip();

        let isFilesDownloaded = false;
        let isFoldersDownloaded = false;

        // Download files if any are selected
        if (filesToDownload.length > 0) {
            const resFiles = await fetch(apiURL.downloadFiles, {
                method: "POST",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ files: filesToDownload, guestName }),
            });

            if (!resFiles.ok) {
                throw new Error("Failed to download files");
            }

            const fileStream = resFiles.body;
            const fileChunks = [];
            let receivedLength = 0;

            // Process the stream in chunks
            const reader = fileStream.getReader();
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                fileChunks.push(value);
                receivedLength += value.length;
            }

            const fullArray = new Uint8Array(receivedLength);
            let position = 0;
            for (let chunk of fileChunks) {
                fullArray.set(chunk, position);
                position += chunk.length;
            }

            // Create a Blob from the array and add to the main zip
            const fileZipBlob = new Blob([fullArray], {
                type: "application/zip",
            });

            const fileZip = await JSZip.loadAsync(fileZipBlob);
            fileZip.forEach((relativePath, file) => {
                mainZip.file(relativePath, file.async("nodebuffer"));
            });

            isFilesDownloaded = true;
        }

        // Download folders if any are selected
        if (foldersToDownload.length > 0) {
            const resFolders = await fetch(apiURL.downloadFolders, {
                method: "POST",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ folders: foldersToDownload, guestName }),
            });

            if (!resFolders.ok) {
                throw new Error("Failed to download folders");
            }

            const folderStream = resFolders.body;
            const folderChunks = [];
            let folderReceivedLength = 0;

            // Process the stream in chunks for folders
            const folderReader = folderStream.getReader();
            while (true) {
                const { done, value } = await folderReader.read();
                if (done) break;
                folderChunks.push(value);
                folderReceivedLength += value.length;
            }

            const fullFolderArray = new Uint8Array(folderReceivedLength);
            let folderPosition = 0;
            for (let chunk of folderChunks) {
                fullFolderArray.set(chunk, folderPosition);
                folderPosition += chunk.length;
            }

            // Create a Blob from the array and add to the main zip
            const folderZipBlob = new Blob([fullFolderArray], {
                type: "application/zip",
            });

            const folderZip = await JSZip.loadAsync(folderZipBlob);
            folderZip.forEach((relativePath, file) => {
                mainZip.file(relativePath, file.async("nodebuffer"));
            });

            isFoldersDownloaded = true;
        }

        // Check if we need to generate the final zip
        if (isFilesDownloaded || isFoldersDownloaded) {
            // Generate the main ZIP file
            const zipBlob = await mainZip.generateAsync({ type: "blob" });

            // Trigger download of the main ZIP
            downloadFile(title + ".zip", zipBlob, "application/zip");

            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
};
