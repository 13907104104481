import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

// import Button from "components/input/Button";
import { toastAlert } from "helpers/alert";
import {
    downloadFileById,
    getFileExtension,
    isCompiledFilesExists,
    isFavoriteFile,
    isIfcFile,
    isPcdFile,
} from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import ShareProjectFile from "components/share/ShareProjectFile";
import AddVisa from "components/visa/AddVisa";
import RemoveVisa from "components/visa/RemoveVisa";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import FileRelatedCompiled from "./FileRelatedCompiled";
import ModalRename from "./ModalRename";
import ProjectSelection from "./ProjectSelection";

import { Button, Dropdown, Flex, Spin, theme } from "antd";

const OptionsFile = ({
    file,
    refreshAction,
    showInfosInPanel,
    userRoles = [],
    isFromSideVersion,
    setRenderDocument,
    children,
    placement = "topLeft",
    trigger = "click",
}) => {
    const { t } = useTranslation();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const { permissionsTools } = useProfileStore();
    const { userProfile, handleFiles } = useContext(DriveToolsContext);
    const { addModalData, removeModal } = useContext(ModalContext);
    const { startDriveSyncMessage, runMessage } = useContext(PopupContext);

    const [loader, setLoader] = useState(false);

    const [visasToAdd, setVisasToAdd] = useState(null);
    const [visasToRemove, setVisasToRemove] = useState(null);

    const { token } = theme.useToken();

    useEffect(() => {
        if (loader) {
            startDriveSyncMessage();
        }
        // else {
        //     refreshAction();
        // }
    }, [loader]);

    useEffect(() => {
        if (permissionsTools?.visas?.canView && file?.project?._id) getVisas();
    }, [file]);

    const getVisas = async () => {
        const resVisas = await axios.get(
            apiURL.getFolderVisas + file.project._id,
        );

        if (resVisas?.data) {
            setVisasToAdd(
                resVisas.data.filter(
                    (visa) =>
                        !file.visas.some((item) => item.visa?._id === visa._id),
                ),
            );

            setVisasToRemove(
                resVisas.data.filter((visa) =>
                    file.visas.some(
                        (item) =>
                            item.visa?._id === visa._id &&
                            !(
                                item.approvedDate ||
                                item.refusedDate ||
                                item.commentedDate ||
                                item.notConcernedDate
                            ),
                    ),
                ),
            );
        }
    };

    const fileHandlerRef = useRef(null);
    const [actionFile, setActionFile] = useState("replace");

    const deleteFile = async () => {
        setLoader(true);
        try {
            let deletedFiles = [];

            if (localStorage.getItem("deletedFiles"))
                deletedFiles = JSON.parse(localStorage.getItem("deletedFiles"));

            deletedFiles.push(file._id);

            localStorage.setItem("deletedFiles", JSON.stringify(deletedFiles));

            await axios.delete(
                apiURL.deleteProjectFile + file.project._id + "/" + file._id,
            );

            refreshAction();
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    // Ajout d'un nouveau fichier au projet courant
    const handleFile = async (e, existingFileId, action) => {
        const uploadedFile = e.target.files[0];
        if (
            action === "version" &&
            existingFileId &&
            getFileExtension(file.file).toLowerCase() !==
                getFileExtension(uploadedFile.name).toLowerCase()
        ) {
            toastAlert("error", t("leFichierDoitAvoirLaMemeExtension"));
        } else {
            handleFiles([uploadedFile], false, false, existingFileId, action);
        }
    };

    // Copie d'un fichier vers un autre projet
    const onConfirmCopy = async (projectId) => {
        setLoader(true);

        try {
            const date = Date.now();
            const prefixFileName = date + generateRandomToken(10);
            const fileName = prefixFileName + "." + getFileExtension(file.file);
            const imageName = prefixFileName + ".jpeg";

            const datas = {
                projectId,
                fileId: file._id,
                newFileName: fileName,
                newPreviewImage: file.previewImage ? imageName : null,
            };

            const resCopy = await axios.post(apiURL.copyProjectFile, datas);

            if (resCopy && resCopy.data) {
                toastAlert("success", t("leFichierABienEteCopie"));
                refreshAction();
                removeModal("copyFile");
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    // Déplacement d'un fichier vers un autre projet
    const onConfirmMoving = async (projectId) => {
        setLoader(true);

        try {
            // Si c'est un fichier compilé, on check qu'il n'existe pas déjà dans le dossier de destination
            const isExists = await isCompiledFilesExists(
                file.compiledFiles.map((selectedFile) => selectedFile._id),
                projectId,
            );

            if (isExists) {
                toastAlert(
                    "warning",
                    t("cetteCompilationDeFichiersExisteDejaDansCeDossier"),
                );
            } else {
                const resMoving = await axios.get(
                    apiURL.moveProjectFile + file._id + "/" + projectId,
                );

                if (resMoving && resMoving.data) {
                    toastAlert("success", t("leFichierABienEteDeplace"));
                    refreshAction();
                    removeModal("moveFile");
                } else toastAlert("error", t("uneErreurEstSurvenue"));
            }
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    // Ajout / Suppression d'un fichier aux favoris
    const toggleFavorite = async () => {
        setLoader(true);

        try {
            const resFavorite = await axios.put(apiURL.addFavorite, {
                id: file._id,
                type: "file",
            });

            if (resFavorite && resFavorite.data) {
                if (resFavorite.data) refreshAction();
                else toastAlert("error", t("uneErreurEstSurvenue"));
            } else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        } catch (e) {
            setLoader(false);
        }
    };

    const handleOpenFile = (e) => {
        if (isFromSideVersion && showInfosInPanel) {
            showInfosInPanel(file._id);
        } else if (isIfcFile(file) || isPcdFile(file))
            window.open("/viewer/" + file._id);
        else setRenderDocument(true);
    };

    const [open, setopen] = useState();

    const shareItems = [
        {
            label: (
                <Flex align="center" gap={8}>
                    <MaterialIcons size="sm" name="share" />
                    <Text>{t("partagerAvecBimono")}</Text>
                </Flex>
            ),
            onClick: () => {
                addModalData({
                    key: "shareFile",
                    title: t("partager") + ' "' + file.title + '"',
                    width: 1000,
                    isForm: false,
                    content: (
                        <ShareProjectFile
                            files={[file]}
                            filesIds={[file._id]}
                            refreshAction={refreshAction}
                        />
                    ),
                });
            },
        },
        {
            label: (
                <Flex align="center" gap={8}>
                    <MaterialIcons size="sm" name="qr_code" />
                    <Text>{t("genererUnQRCode")}</Text>
                </Flex>
            ),
            onClick: () => {
                addModalData({
                    key: "shareFile",
                    title: t("partager") + ' "' + file.title + '"',
                    isForm: false,
                    content: (
                        <ShareProjectFile
                            files={[file]}
                            filesIds={[file._id]}
                            refreshAction={refreshAction}
                            isQRCode={true}
                        />
                    ),
                });
            },
        },
    ];

    const organizeItems = [
        !isFromSideVersion &&
            !file.isOldVersion &&
            userRoles.MODERATOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons size="sm" name="drive_file_move" />
                        <Text>{t("deplacerVers")}</Text>
                    </Flex>
                ),
                onClick: () => {
                    addModalData({
                        key: "moveFile",
                        title: t("deplacerVers"),
                        content: (
                            <ProjectSelection
                                profile={userProfile}
                                currentProject={file.project}
                                file={file}
                                onConfirm={onConfirmMoving}
                                isMoving={true}
                            />
                        ),
                        isForm: false,
                    });
                },
            },
        file.compiledFiles?.length === 0 &&
            !isFromSideVersion &&
            userRoles.MODERATOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons size="sm" name="content_copy" />
                        <Text>{t("copierVers")}</Text>
                    </Flex>
                ),
                onClick: () => {
                    addModalData({
                        key: "copyFile",
                        title: t("copierVers"),
                        content: (
                            <ProjectSelection
                                profile={userProfile}
                                currentProject={file.project}
                                file={file}
                                onConfirm={onConfirmCopy}
                            />
                        ),
                        isForm: false,
                    });
                },
            },
        file.compiledFiles?.length === 0 &&
            !isFromSideVersion &&
            (userRoles.EDITOR ||
                (file.author && file.author._id === userProfile.user._id)) && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="cached"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("remplacer")}</Text>
                    </Flex>
                ),
                key: "remplacer",
                onClick: () => {
                    setActionFile("replace");
                    fileHandlerRef.current.click();
                },
            },
        file.compiledFiles?.length === 0 &&
            !isFromSideVersion &&
            !file.isOldVersion &&
            userRoles.EDITOR && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="history"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("ajouterUneVersion")}</Text>
                    </Flex>
                ),
                key: "ajouter-une-version",
                onClick: () => {
                    setActionFile("version");
                    fileHandlerRef.current.click();
                },
            },
        userProfile &&
            !file.isOldVersion && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="favorite"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                            color={
                                isFavoriteFile(file, userProfile)
                                    ? "#ef4444"
                                    : ""
                            }
                        />
                        <Text>
                            {!isFavoriteFile(file, userProfile)
                                ? t("ajouterAuxFavoris")
                                : t("retirerDesFavoris")}
                        </Text>
                    </Flex>
                ),
                key: "ajouter-aux-favoris",
                onClick: toggleFavorite,
            },
    ];

    const visaItems = [
        permissionsTools?.visas?.canView &&
            !file.isOldVersion &&
            (!userRoles.MODERATOR
                ? visasToAdd?.length > 0 &&
                  file.author?._id === userProfile.user._id
                : true) && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons size="sm" name="add" />
                        <Text>{t("demanderUnVisa")}</Text>
                    </Flex>
                ),
                onClick: () => {
                    addModalData({
                        key: "addVisaFile",
                        title: t("demanderUnVisa"),
                        content: (
                            <AddVisa
                                project={file.project}
                                files={[file]}
                                onClose={() => removeModal("addVisaFile")}
                                refreshAction={refreshAction}
                                userRoles={userRoles}
                                visas={visasToAdd}
                            />
                        ),
                        isForm: false,
                    });
                },
            },
        permissionsTools?.visas?.canView &&
            file.visas?.length > 0 &&
            file.visas?.filter(
                (visa) =>
                    visa.approvedDate ||
                    visa.refusedDate ||
                    visa.commentedDate ||
                    visa.notConcernedDate,
            ).length !== file.visas.length &&
            !file.isOldVersion &&
            (userRoles.MODERATOR ||
                file.author?._id === userProfile.user._id) && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons size="sm" name="remove" />
                        <Text>{t("retirerLesVisas")}</Text>
                    </Flex>
                ),
                onClick: () => {
                    addModalData({
                        key: "removeVisaFile",
                        title: t("retirerLesVisas"),
                        content: (
                            <RemoveVisa
                                files={[file]}
                                onClose={() => removeModal("removeVisaFile")}
                                refreshAction={refreshAction}
                                visas={visasToRemove}
                            />
                        ),
                        isForm: false,
                    });
                },
            },
    ];

    const menuItems = [
        {
            label: (
                <Flex align="center" gap={8}>
                    <img
                        src={getFileExtensionIcon(file)}
                        loading="lazy"
                        alt=""
                        className="w-[16px]"
                        style={{
                            marginRight: token.marginXS,
                        }}
                    />
                    <Text>{t("ouvrir")}</Text>
                </Flex>
            ),
            key: "ouvrir",
            onClick: handleOpenFile,
        },
        file.compiledFiles?.length === 0 && {
            label: (
                <Flex align="center" gap={8}>
                    <MaterialIcons
                        name="download"
                        size="xs"
                        style={{
                            marginRight: token.marginXS,
                        }}
                    />
                    <Text>{t("telecharger")}</Text>
                </Flex>
            ),
            key: "telecharger",
            onClick: async () => {
                runMessage({
                    content: t("telechargementEnCours"),
                    duration: 0,
                    type: "loading",
                });
                await downloadFileById(file._id, file.title);
                runMessage({
                    reset: true,
                    content: t("telechargementEnCours"),
                    type: "success",
                    duration: 1,
                });
            },
        },
        !isFromSideVersion &&
            !file.isOldVersion &&
            !file.project?.automations
                ?.filter((automation) => automation?.isActive)
                .some((automation) =>
                    automation?.actions?.some(
                        (action) => action?.type?.code === "ADD_NOMENCLATURE",
                    ),
                ) &&
            (userRoles.EDITOR ||
                (file.author && file.author._id === userProfile.user._id)) && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="edit"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("renommer")}</Text>
                    </Flex>
                ),
                key: "renommer",
                onClick: () =>
                    addModalData({
                        key: "rename",
                        title: `${t("renommer")} ${t("leFichier")}`,
                        okText: t("confirmer"),
                        content: (
                            <ModalRename
                                file={file}
                                refreshAction={refreshAction}
                            />
                        ),
                    }),
            },
        {
            type: "divider",
        },
        {
            label: (
                <Flex align="center" gap={8}>
                    <Text>{t("partager")}</Text>
                </Flex>
            ),
            key: "partager",
            children: setKeyMenu(shareItems, "share"),
        },
        {
            label: (
                <Flex align="center" gap={8}>
                    <Text>{t("organiser")}</Text>
                </Flex>
            ),
            key: "organize",
            children: setKeyMenu(organizeItems, "organize"),
        },
        permissionsTools?.visas?.canView &&
            (userRoles.MODERATOR ||
                (file.author?._id === userProfile.user._id &&
                    (visasToAdd?.length > 0 || file.visas?.length > 0))) && {
                label: (
                    <Flex align="center" gap={8}>
                        <Text>{t("visas")}</Text>
                    </Flex>
                ),
                key: "visas",
                children: setKeyMenu(visaItems, "visas"),
            },
        {
            type: "divider",
        },
        showInfosInPanel &&
            !isFromSideVersion && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="info"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("informations")}</Text>
                    </Flex>
                ),
                key: "informations",
                onClick: () => showInfosInPanel(file._id, false, true),
            },
        ((file.author && file.author._id === userProfile?.user._id) ||
            userRoles.MODERATOR) &&
            !isFromSideVersion &&
            (!file.versions?.length || userRoles.MODERATOR) && {
                label: (
                    <Flex align="center" gap={8}>
                        <MaterialIcons
                            name="delete"
                            size="xs"
                            style={{
                                marginRight: token.marginXS,
                            }}
                        />
                        <Text>{t("supprimer")}</Text>
                    </Flex>
                ),
                key: "supprimer",
                onClick: () => {
                    addModalData({
                        key: "deleteFile",
                        title: t("voulezVousVraimentSupprimerCeFichier?"),
                        okText: t("confirmer"),
                        cancelText: t("annuler"),
                        content: <FileRelatedCompiled file={file} />,
                        handleConfirm: () => deleteFile(),
                    });
                },
            },
    ].filter(Boolean);

    return (
        <Fragment>
            {loader ? (
                <Spin />
            ) : (
                <Dropdown
                    overlayClassName={!mdMedia && "options-drawer"}
                    autoAdjustOverflow={mdMedia}
                    placement={mdMedia ? placement : "topRight"}
                    open={open}
                    onOpenChange={(e) => setopen(e)}
                    destroyPopupOnHide
                    dropdownRender={(menu) => {
                        return (
                            <Fragment>
                                <div
                                    className="mask"
                                    onClick={() => {
                                        setopen(false);
                                    }}
                                    onContextMenu={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setopen(false);
                                    }}
                                />
                                {{
                                    ...menu,
                                    props: {
                                        ...menu.props,
                                        onContextMenu: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        },
                                        onClick: (e) => {
                                            e.domEvent.stopPropagation();
                                            e.domEvent.preventDefault();
                                        },
                                    },
                                }}
                            </Fragment>
                        );
                    }}
                    trigger={trigger}
                    menu={{
                        triggerSubMenuAction: mdMedia ? "hover" : "click",
                        subMenuCloseDelay: 0.3,
                        rootClassName: !mdMedia && "options-drawer",
                        items: menuItems,
                    }}
                >
                    {children || (
                        <Button
                            shape="circle"
                            type="text"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                        >
                            <MaterialIcons name="more_vert" />
                        </Button>
                    )}
                </Dropdown>
            )}
            <input
                type="file"
                accept={"." + getFileExtension(file.file)}
                onChange={(e) => {
                    handleFile(e, file._id, actionFile);
                }}
                className="hidden"
                ref={fileHandlerRef}
                onClick={(e) => {
                    e.target.value = null;
                }}
            />
        </Fragment>
    );
};

const setKeyMenu = (array, keyName) => {
    if (keyName === "file") {
        return array
            .filter((e) => !!e?.content)
            .map((e, i) => ({
                key: keyName + "-" + i,
                label: e.content,
            }));
    }
    return array
        .filter((e) => !!e)
        .map((e, i) => ({
            key: keyName + "-" + i,
            ...e,
        }));
};

export default OptionsFile;
