import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Spinner from "components/utils/Spinner";

import { TableInlineContext } from "./Table";

const InputCellSelect = ({ isDisabled, dataLine, field, value, onChange }) => {
    const { t } = useTranslation();
    const { onEditInline } = useContext(TableInlineContext);

    const [optionSelected, setOptionSelected] = useState();
    const [listOptions, setListOptions] = useState([]);
    const [loader, setLoader] = useState(true);

    const fieldRef = useRef(null);
    const isMultiple = Boolean(field.multiple && field.multiple(dataLine.type));

    useEffect(() => {
        fetchOptions();
    }, [dataLine]);

    useEffect(() => {
        setOptionSelected(
            isMultiple
                ? listOptions.filter((option) => {
                      return value?.includes(option.val);
                  })
                : listOptions.find((option) => {
                      if (typeof value == "object" && value !== null)
                          return option.val === !!value;
                      else return option.val === value;
                  }),
        );
    }, [value, listOptions, isMultiple]);

    const fetchOptions = useCallback(async () => {
        setLoader(true);
        const options = await field.getOptions(dataLine);
        setListOptions(options);
        setLoader(false);
    }, [field, setListOptions, setLoader, value, dataLine]);

    const [isOptionOpen, setIsOptionOpen] = useState(false);
    useEffect(() => {
        if (isOptionOpen) {
            window.addEventListener("click", detectClickOutside);
        } else {
            window.removeEventListener("click", detectClickOutside);
        }
    }, [isOptionOpen]);

    const detectClickOutside = useCallback(
        (e) => {
            if (
                !fieldRef?.current?.contains(e.target) &&
                !e?.target.classList.value.includes("deleteCreatedOption")
            ) {
                setIsOptionOpen(false);
            }
        },
        [fieldRef],
    );

    if (loader) return <Spinner widthClass=" w-6" heightClass=" h-6" isSmall />;

    return (
        <div ref={fieldRef} className="relative w-full">
            <div
                className={
                    "h-full w-full p-2 flex justify-between items-center " +
                    (!isDisabled && "hover:cursor-pointer")
                }
                onClick={() => setIsOptionOpen(!isOptionOpen)}
            >
                {optionSelected ? (
                    isMultiple ? (
                        <MultipleField options={optionSelected} />
                    ) : (
                        <SimpleField option={optionSelected} />
                    )
                ) : (
                    <span>
                        {t("selectionner")} {field.label}
                    </span>
                )}
                {!isDisabled && (
                    <i
                        className="hover:cursor-pointer material-icons notranslate mr-2 text-sm "
                        style={{ fontSize: "1.2rem" }}
                    >
                        expand_more
                    </i>
                )}
            </div>

            {isOptionOpen && !isDisabled && (
                <div className="absolute z-[10] max-h-[50vh] -right-4 min-w-full text-xs text-left card  overflow-auto font-light p-4 mb-12">
                    <div className="font-bold flex justify-between items-center">
                        {t("selectionner")} {field.label}
                        <i
                            onClick={() => setIsOptionOpen(false)}
                            className="hover:cursor-pointer material-icons notranslate text-sm ml-4 "
                            style={{ fontSize: "1.2rem" }}
                        >
                            close
                        </i>
                    </div>
                    <ul className="mt-4">
                        {listOptions?.length === 0 && (
                            <span>{t("veuillezDefinirDesOptions")}</span>
                        )}

                        {!!listOptions?.length &&
                            field.key === "defaultValue" && (
                                <li
                                    className={
                                        "px-2 py-3 sm:py-2 hover:cursor-pointer hover:bg-slate-100 rounded-lg" +
                                        (value === null
                                            ? " border border-blue-300"
                                            : " border-transparent")
                                    }
                                    onClick={async (e) => {
                                        await onChange(null);
                                    }}
                                >
                                    {t("aucuneValeur")}
                                </li>
                            )}

                        {listOptions.map((option) => {
                            const CustomOptionsComponent =
                                option.customOptionsComponent;
                            return (
                                <li
                                    key={option.val}
                                    className={
                                        "px-2  py-3 sm:py-2 hover:cursor-pointer border hover:bg-slate-100 rounded-lg " +
                                        (optionSelected?.val === option.val ||
                                        (isMultiple &&
                                            optionSelected?.find(
                                                (e) => e.val === option.val,
                                            ))
                                            ? " border-blue-300"
                                            : " border-transparent")
                                    }
                                    onClick={async (e) => {
                                        if (optionSelected?.val === option.val)
                                            return;
                                        if (option.customOptionsComponent) {
                                            await onChange(option.val);
                                            return;
                                        } else if (isMultiple) {
                                            let newVal = value;
                                            if (
                                                typeof value == "object" &&
                                                value !== null
                                            ) {
                                                if (
                                                    value.includes(option.val)
                                                ) {
                                                    newVal = value.filter(
                                                        (val) =>
                                                            val !== option.val,
                                                    );
                                                } else {
                                                    newVal = [
                                                        ...value,
                                                        option.val,
                                                    ];
                                                }
                                            } else {
                                                newVal = [option.val];
                                            }
                                            await onChange(newVal);
                                        } else {
                                            await onChange(option.val);
                                            detectClickOutside(e);
                                            return;
                                        }
                                    }}
                                >
                                    {option.customOptionsComponent ? (
                                        <div>
                                            <span className="flex items-center">
                                                <i
                                                    className="hover:cursor-pointer material-icons notranslate mr-2 text-sm "
                                                    style={{
                                                        fontSize: "1.2rem",
                                                    }}
                                                >
                                                    {option.iconMaterialKey}
                                                </i>
                                                {option.label}
                                            </span>
                                            {optionSelected?.val ===
                                                option.val && (
                                                <div className="py-4">
                                                    <CustomOptionsComponent
                                                        closeOptions={() =>
                                                            setIsOptionOpen(
                                                                false,
                                                            )
                                                        }
                                                        dataLine={dataLine}
                                                        editData={async (
                                                            listCreatedOptions,
                                                        ) => {
                                                            return await onEditInline(
                                                                dataLine,
                                                                "options",
                                                                listCreatedOptions,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <SimpleField option={option} />
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

const MultipleField = ({ options }) => {
    return (
        <span className="mr-2 ">
            {`${options.map((e) => e.label).join(", ")}`}
        </span>
    );
};

const SimpleField = ({ option }) => {
    return (
        <span className="flex items-center">
            {option.iconMaterialKey && (
                <i
                    className="hover:cursor-pointer material-icons notranslate mr-2 text-sm "
                    style={{ fontSize: "1.2rem" }}
                >
                    {option.iconMaterialKey}
                </i>
            )}
            {option.label}
        </span>
    );
};

export default InputCellSelect;
