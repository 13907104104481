export const tenderStatus = {
    PENDING: {
        label: "envoye",
        color: "orange",
        variant: "warning",
        icon: "hourglass_top",
    },
    OPENED: {
        label: "consulte",
        color: "blue",
        variant: "processing",
        icon: "hourglass_top",
    },
    ACCEPTED: {
        label: "accepte",
        color: "green",
        variant: "success",
        icon: "check",
    },
    REJECTED: {
        label: "decline",
        color: "red",
        variant: "error",
        icon: "close",
    },
};
