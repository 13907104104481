import axios from "axios";
import i18n from "i18next";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";

import ConditionLine from "./ConditionLine";

import { Button, Flex, theme } from "antd";

const ConditionSection = ({
    formData,
    setFormData,
    folderId,
    folderMemberType,
    setFolderMemberType,
    type,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const conditionItem = {
        type: "",
        userType: "",
        user: null,
        operator: "",
    };

    const [conditionsList, setConditionsList] = useState([]);

    const [conditionTypes, setConditionTypes] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [members, setMembers] = useState(null);
    useEffect(() => {
        if (conditionsList.length === 0) {
            setConditionsList(
                formData.conditions.length > 0
                    ? formData.conditions
                    : [conditionItem],
            );
            return;
        }
        setFormData({ ...formData, conditions: conditionsList });
    }, [conditionsList]);

    useEffect(() => {
        axios.get(apiURL.getAllAutomationConditionTypes).then(({ data }) => {
            const typesList = [];
            data.sort((a, b) => (a.position > b.position ? 1 : -1)).map(
                (type) => {
                    return typesList.push({
                        val: type._id,
                        label: type["title" + i18n.locale],
                    });
                },
            );
            setConditionTypes(typesList);
        });
    }, []);

    useEffect(() => {
        if (conditionTypes.length > 0) {
            const getUserTypes = async () => {
                const resTypes = await axios.get(
                    apiURL.getAllAutomationUserTypes,
                );

                if (resTypes && resTypes.data) {
                    const typesList = [];

                    resTypes.data
                        .sort((a, b) => (a.position > b.position ? 1 : -1))
                        .filter((userType) =>
                            type === "nomenclature"
                                ? userType.code !== "FOLDER_MEMBER"
                                : true,
                        )
                        .map((type) => {
                            return typesList.push({
                                val: type._id,
                                label: type["title" + i18n.locale],
                            });
                        });

                    setUserTypes(typesList);

                    // On stock le type "Membre du dossier" car on s'en sert pour afficher un autre champ
                    const folderMemberTypeRes = resTypes.data.find(
                        (type) => type.code === "FOLDER_MEMBER",
                    );

                    if (folderMemberTypeRes) {
                        setFolderMemberType(folderMemberTypeRes);
                    }
                }
            };

            getUserTypes();
        }
    }, [conditionTypes]);

    useEffect(() => {
        if (folderId) {
            axios
                .get(apiURL.getProjectMembers + folderId)
                .then(({ data }) => {
                    setMembers(data || []);
                })
                .catch(() => {
                    setMembers([]);
                });
        } else {
            setMembers([]);
        }
    }, [folderId]);

    const addCondition = () => {
        const updatedList = conditionsList;
        updatedList.push(conditionItem);
        setConditionsList([...updatedList]);
    };

    const deleteCondition = (index) => {
        const updatedList = [...conditionsList];
        updatedList.splice(index, 1);
        setConditionsList(updatedList);
    };

    const onChange = (index, value, name) => {
        const updatedList = [...conditionsList];
        updatedList[index][name] = value;
        setConditionsList(updatedList);
    };

    const updateValue = (index, name, value) => {
        const updatedList = [...conditionsList];
        updatedList[index][name] = value;
        setConditionsList(updatedList);
    };

    useEffect(() => {
        if (
            type === "nomenclature" &&
            userTypes?.length &&
            conditionTypes?.length
        ) {
            setConditionsList([
                {
                    type: conditionTypes[0].val,
                    userType: userTypes[0].val,
                    user: null,
                    operator: "OR",
                },
            ]);
        }
    }, [type, userTypes, conditionTypes]);
    if (type === "nomenclature") return null;

    return (
        <Flex vertical gap={token.paddingLG}>
            {conditionsList.map((condition, i) => (
                <ConditionLine
                    key={i}
                    index={i}
                    conditionDatas={condition}
                    updateValue={updateValue}
                    onChange={onChange}
                    deleteRow={deleteCondition}
                    folderId={folderId}
                    folderMemberType={folderMemberType}
                    conditionTypes={conditionTypes}
                    userTypes={userTypes}
                    members={members}
                />
            ))}
            {type !== "nomenclature" && (
                <Button
                    style={{
                        marginLeft: 150 + token.paddingLG,
                        justifyContent: "flex-start",
                        color: token.colorPrimary,
                    }}
                    icon={<MaterialIcons name="add" />}
                    type="ghost"
                    onClick={(e) => addCondition()}
                >
                    {t("ajouterUneCondition")}
                </Button>
            )}
        </Flex>
    );
};

export default ConditionSection;
