import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/input/Button";
import Select from "components/input/Select";
import TextField from "components/input/TextField";

import { TableInlineContext } from "./Table";

const CustomTimeCondition = ({ dataLine, closeOptions }) => {
    const { t } = useTranslation();

    const { onEditInline } = useContext(TableInlineContext);

    const [conditionForm, setConditionForm] = useState(
        Array.isArray(dataLine.timeCondition)
            ? dataLine.timeCondition
            : [
                  {
                      time: 15,
                      modifier: "ADD",
                      valueToMatch: "",
                  },
              ],
    );

    return (
        <div>
            <ul className="flex flex-col gap-2">
                {conditionForm.map((condition, index) => (
                    <RenderLineCondition
                        conditionForm={conditionForm}
                        setConditionForm={setConditionForm}
                        key={index}
                        index={index}
                        dataLine={dataLine}
                    />
                ))}
                <li className="w-max">
                    <Button
                        isReversed
                        isBgTransparent
                        withoutBorder
                        icon={"add"}
                        text={t("ajouterUneCondition")}
                        onClick={() => {
                            setConditionForm((k) => [
                                ...k,
                                {
                                    time: 15,
                                    modifier: "ADD",
                                    valueToMatch: "",
                                },
                            ]);
                        }}
                    />
                </li>
            </ul>
            <div className="flex gap-4 justify-end mt-6 w-max ml-auto px-4">
                <Button
                    text={t("annuler")}
                    isReversed={true}
                    onClick={() => {
                        closeOptions();
                    }}
                />
                <Button
                    text={t("enregistrer")}
                    onClick={async () => {
                        const noConditionsFiltered = conditionForm
                            .map((u, i) => {
                                if (
                                    !!u.valueToMatch &&
                                    u.valueToMatch !== false &&
                                    u.valueToMatch !== 0 &&
                                    u.time !== 0
                                ) {
                                    return u;
                                } else return null;
                            })
                            .filter((e) => !!e);

                        setConditionForm(noConditionsFiltered);
                        await onEditInline(
                            dataLine,
                            "timeCondition",
                            noConditionsFiltered?.length
                                ? noConditionsFiltered
                                : null,
                        );
                        closeOptions();
                    }}
                />
            </div>
        </div>
    );
};

const RenderLineCondition = ({
    dataLine,
    conditionForm,
    setConditionForm,
    index,
}) => {
    const { t } = useTranslation();
    const condition = conditionForm[index];
    const isTypeSelect = ["MULTISELECT"].includes(dataLine.type);
    const selectOptions = ["TEXT", "NUMBER"].includes(dataLine.type)
        ? false
        : ["CHECKBOX"].includes(dataLine.type)
          ? [
                {
                    val: true,
                    label: "Cochée",
                },
                {
                    val: false,
                    label: "Non-cochée",
                },
            ]
          : dataLine.options.map((e) => ({
                val: e,
                label: e,
            }));

    return (
        <li className="flex items-center gap-2 px-4 text-2xs">
            <span className={index === 0 ? "opacity-0" : ""}>{t("ou")}</span>
            <span>{t("siReponseAuChamp")}</span>
            <span>{isTypeSelect ? "inclut la réponse" : "est égal(e) à"}</span>
            {selectOptions ? (
                <div className="w-[150px]">
                    <Select
                        placeholder="Valeur"
                        options={selectOptions}
                        value={condition.valueToMatch}
                        onChange={(e) => {
                            let valueToMatch = e.target.value;
                            if (valueToMatch === "true") valueToMatch = true;
                            if (valueToMatch === "false") valueToMatch = false;

                            setConditionForm((k) =>
                                k.map((u, i) =>
                                    i === index
                                        ? { ...u, valueToMatch: e.target.value }
                                        : u,
                                ),
                            );
                        }}
                    />
                </div>
            ) : (
                <div className="w-[150px]">
                    <TextField
                        type={dataLine.type}
                        value={condition.valueToMatch}
                        onChange={(e) => {
                            setConditionForm((k) =>
                                k.map((u, i) =>
                                    i === index
                                        ? { ...u, valueToMatch: e.target.value }
                                        : u,
                                ),
                            );
                        }}
                    />
                </div>
            )}

            <span className="lowercase">, {t("alors")} </span>
            <div className="w-[100px]">
                <Select
                    placeholder="Modificateur"
                    options={[
                        { val: "ADD", label: "Ajouter" },
                        { val: "SUBSTRACT", label: "Soustraire" },
                    ]}
                    value={condition.modifier}
                    onChange={(e) => {
                        setConditionForm((k) =>
                            k.map((u, i) =>
                                i === index
                                    ? { ...u, modifier: e.target.value }
                                    : u,
                            ),
                        );
                    }}
                />
            </div>
            <div className="w-[60px]">
                <TextField
                    type="number"
                    value={condition.time}
                    onChange={(e) => {
                        setConditionForm((k) =>
                            k.map((u, i) =>
                                i === index
                                    ? { ...u, time: e.target.value }
                                    : u,
                            ),
                        );
                    }}
                />
            </div>
            <span>{t("minutes")}</span>
            <div className="w-[30px] flex justify-end">
                {index !== 0 && (
                    <i
                        onClick={() =>
                            setConditionForm((k) =>
                                k.filter((u, i) => i !== index),
                            )
                        }
                        className="deleteCreatedOption hover:cursor-pointer  text-slate-300 hover:text-red-500 material-icons notranslate text-sm "
                        style={{ fontSize: "1.2rem" }}
                    >
                        delete
                    </i>
                )}
            </div>
        </li>
    );
};

export default CustomTimeCondition;
