import axios from "axios";
import i18n from "i18next";

import apiURL from "utils/apiURL";

const isUpperCase = (string) => /^[A-Z]*$/.test(string);

export function fieldValidation(value, rules, same = "") {
    var errorMsg = "";

    rules = rules.split("|");

    rules.forEach((rule) => {
        switch (rule) {
            case "required":
                if (!value) errorMsg = i18n.t("ceChampEstObligatoire");
                else {
                    if (typeof value === "object") {
                        if (value instanceof File) break;
                        if (Object.keys(value).length === 0)
                            errorMsg = i18n.t("ceChampEstObligatoire");
                    } else if (typeof value === "string") {
                        if (value.trim() === "")
                            errorMsg = i18n.t("ceChampEstObligatoire");
                    } else if (value === "")
                        errorMsg = i18n.t("ceChampEstObligatoire");
                }

                break;

            case "email":
                if (!isEmail(value))
                    errorMsg = i18n.t("veuillezRenseignerUnEmailValide");

                break;

            case "password":
                if (value.trim().length >= 8) {
                    var nbNumeric = 0;
                    var nbUppercase = 0;

                    for (var i = 0; i < value.length; i++) {
                        if (!isNaN(value[i])) nbNumeric++;

                        if (isUpperCase(value[i])) nbUppercase++;
                    }

                    if ((nbNumeric === 0) | (nbUppercase === 0))
                        errorMsg = i18n.t(
                            "leMotDePasseDoitContenirAuMoinsUnChiffreEtUneMajuscule",
                        );
                } else
                    errorMsg = i18n.t(
                        "leMotDePasseDoitFaireAuMoinsHuitCaracteres",
                    );

                break;

            case "confirmPassword":
                if (same !== "") {
                    if (value !== same)
                        errorMsg = i18n.t(
                            "laConfirmationEstDifferenteDuMotDePasse",
                        );
                }

                break;

            case "termsOfUse":
                if (value === false)
                    errorMsg = i18n.t(
                        "veuillezAccepterLesConditionsDutilisation",
                    );

                break;

            case "price":
                if (value > 9999.99) errorMsg = "priceError";

                break;

            default:
                break;
        }
    });

    return errorMsg;
}

export async function checkEmailExist(email) {
    try {
        const res = await axios.get(apiURL.getUserByEmail + email);

        if (res.data.email) return i18n.t("cetteAdresseEmailEstDejaUtilisee");
        else return "";
    } catch (err) {
        return "";
    }
}

export function isEmail(email) {
    var regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email.toLowerCase());
}
