import { useTranslation } from "react-i18next";

import SearchBar from "components/nav/SearchBar";

const CommentSearchBar = ({ term, setTerm, isTilesView }) => {
    const { t } = useTranslation();

    const onChange = (value) => {
        setTerm(value);
    };

    return (
        <div className="p-2 mt-1 w-full flex flex-row">
            {!isTilesView ? (
                <input
                    type="text"
                    placeholder={t("rechercherParNumeroOuTitre")}
                    value={term}
                    onChange={(e) => onChange(e.target.value)}
                    className="rounded-md shadow-sm bg-transparent border px-3 py-2 text-xs w-full"
                />
            ) : (
                <SearchBar
                    placeholder={t("rechercherParNumeroOuTitre")}
                    onChange={onChange}
                />
            )}

            {term.length > 0 && !isTilesView && (
                <button
                    type="button"
                    className="p-1"
                    onClick={(e) => setTerm("")}
                >
                    <i
                        className="material-icons notranslate flex"
                        style={{ fontSize: "1.2rem" }}
                    >
                        close
                    </i>
                </button>
            )}
        </div>
    );
};

export default CommentSearchBar;
