import { useState } from "react";
import { useDrag } from "react-dnd";
import { useNavigate } from "react-router-dom";

import { ItemTypes } from "helpers/dnd";
import { getFileExtension, isIfcFile, isPcdFile } from "helpers/file";

import DocumentReader from "components/documents/DocumentReader";

const ProjectArboItemFile = ({
    file,
    project,
    currentFile,
    profile,
    refreshAction,
    closeMenu,
    importFile,
    isWhiteText,
}) => {
    const navigate = useNavigate();

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.FILE,
        item: { type: ItemTypes.FILE, id: file._id, project: project },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [isRenderDocument, setRenderDocument] = useState(false);

    const handleClick = (e) => {
        if (importFile) importFile(file);
        else if (isIfcFile(file) || isPcdFile(file))
            window.open("/viewer/" + file._id);
        else setRenderDocument(true);
        closeMenu && closeMenu(false);
    };

    const closeReader = () => {
        setRenderDocument(false);
    };

    return (
        <div ref={drag}>
            <div
                onClick={(e) => handleClick()}
                className={
                    "flex flex-row rounded-lg p-2 pl-4 overflow-hidden hover:cursor-pointer" +
                    (currentFile && currentFile._id === file._id
                        ? " bg-slate-300"
                        : isWhiteText
                          ? " hover:bg-slate-500"
                          : " hover:bg-slate-300") +
                    (isDragging ? " opacity-50" : "")
                }
            >
                <div
                    className="truncate"
                    title={
                        file.title +
                        "." +
                        (file.compiledFiles.length > 0
                            ? "ifc"
                            : getFileExtension(file.file))
                    }
                >
                    {file.title +
                        "." +
                        (file.compiledFiles.length > 0
                            ? "ifc"
                            : getFileExtension(file.file))}
                </div>
            </div>

            {isRenderDocument && (
                <DocumentReader
                    fileId={file._id}
                    closeReader={closeReader}
                    profile={profile}
                    refreshAction={refreshAction}
                />
            )}
        </div>
    );
};

export default ProjectArboItemFile;
