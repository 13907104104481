import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Form, Select } from "antd";

const FormTasks = ({ optionsTasks }) => {
    const { t } = useTranslation();

    return (
        <Form.Item
            name="allowedTimesheetTasks"
            label={
                <Text size="large" bold>
                    {t("taches")}
                </Text>
            }
            getValueProps={(value) => {
                return optionsTasks ? { value: value } : "";
            }}>
            <Select
                allowClear
                mode="multiple"
                optionFilterProp="label"
                placeholder={t("selectionnerUneOuPlusieursTaches")}
                options={optionsTasks?.map((e) => ({
                    value: e._id,
                    label: e.title,
                }))}
            />
        </Form.Item>
    );
};

export default FormTasks;
