import axios from "axios";

import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { getFileExtensionIcon } from "helpers/image";
import apiURL from "utils/apiURL";

import ProjectBreadcrumb from "components/project/ProjectBreadcrumb";

import { Flex, Input, Table } from "antd";

const Statistics = () => {
    const { t } = useTranslation();

    const initialLoad = useRef(true);

    const [step, setStep] = useState(0);
    const [isLimitReached, setIsLimitReached] = useState(false);

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [sorter, setSorter] = useState({ field: null, order: null });

    const [searchTerms, setSearchTerms] = useState("");

    useEffect(() => {
        setStep(0);
        setIsLimitReached(false);
        getFiles();
    }, [sorter]);

    useEffect(() => {
        if (initialLoad.current) {
            initialLoad.current = false;
            return;
        }

        if (searchTerms.length > 0) {
            const timeoutId = setTimeout(() => {
                getFiles();
            }, 500);
            return () => clearTimeout(timeoutId);
        } else {
            setStep(0);
            setIsLimitReached(false);
            getFiles();
        }
    }, [searchTerms]);

    const generateFileTitleCell = (file) => {
        return (
            <div>
                <div className="flex flex-row">
                    <img
                        src={getFileExtensionIcon(file)}
                        alt=""
                        className="w-[26px]"
                    />
                    <div className="my-auto ml-1">{file.title}</div>
                </div>
                <ProjectBreadcrumb project={file.project} readOnly={true} />
            </div>
        );
    };

    const getFiles = async () => {
        setLoading(true);

        const filesRes = await axios.post(apiURL.filesStatistics, {
            start: 0,
            filter: {
                field: sorter.field,
                order: sorter.order,
            },
            terms: searchTerms,
        });

        if (filesRes.data) {
            setData(
                filesRes.data.map((file) => ({
                    ...file,
                    title: generateFileTitleCell(file),
                })),
            );

            setStep(1);
        }

        setTimeout(() => {
            // On laisse un léger interval pour que le scroll ne déclenche pas un autre fetch en parallèle
            setLoading(false);
        }, 100);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
    };

    const handleScroll = async (e) => {
        if (step === 0 || loading) return;

        if (
            e.target.scrollHeight - e.target.scrollTop ===
                e.target.clientHeight &&
            !isLimitReached
        ) {
            setLoading(true);

            const filesRes = await axios.post(apiURL.filesStatistics, {
                start: step,
                filter: {
                    field: sorter.field,
                    order: sorter.order,
                },
                terms: searchTerms,
            });

            if (filesRes?.data) {
                if (filesRes.data.length === 0) setIsLimitReached(true);
                else {
                    setData((oldData) => [
                        ...oldData,
                        ...filesRes.data.map((file) => ({
                            ...file,
                            title: generateFileTitleCell(file),
                        })),
                    ]);

                    setStep(step + 1);
                }
            }

            setLoading(false);
        }
    };

    const onChange = (e) => {
        setSearchTerms(e.target.value);
    };

    const columns = useMemo(
        () => [
            {
                title: t("nom"),
                dataIndex: "title",
                width: 180,
                sorter: true,
            },
            {
                title: t("vues"),
                dataIndex: "viewsNumber",
                width: 40,
                sorter: true,
            },
            {
                title: t("telechargements"),
                dataIndex: "downloadsNumber",
                width: 40,
                sorter: true,
            },
            {
                title: t("emailsInternes"),
                dataIndex: "internalSharesNumber",
                width: 40,
                sorter: true,
            },
            {
                title: t("emailsExternes"),
                dataIndex: "externalSharesNumber",
                width: 40,
                sorter: true,
            },
        ],
        [data],
    );

    return (
        <Flex vertical gap={12}>
            <Input
                placeholder={t("commencezAEcrireLeNomDuFichier")}
                allowClear
                onChange={onChange}
                value={searchTerms}
                style={{
                    marginBottom: "10px",
                    maxWidth: "400px",
                }}
            />

            <Flex align="center" gap={24}>
                <Table
                    pagination={false}
                    bordered
                    loading={loading}
                    rowKey={(record) => record._id}
                    scroll={{
                        x: "1500px",
                        y: "60vh",
                    }}
                    dataSource={data}
                    columns={columns}
                    onChange={handleTableChange}
                    onScroll={handleScroll}
                />
            </Flex>
        </Flex>
    );
};

export default Statistics;
