import { useContext } from "react";
import { useDrag, useDrop } from "react-dnd";

import { TableInlineContext } from "./Table";
import { CellWrapper } from "./TableCellRender";

const TableRowData = ({
    dataLine,
    indexRow,
    orderingFunction,
    ButtonOptions,
}) => {
    const { fields, onOrderingInline } = useContext(TableInlineContext);

    const [{ isDragging }, drag] = useDrag({
        type: "row",
        item: (e) => dataLine,
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: "row",
        drop: async (source) => {
            await onOrderingInline(source, dataLine);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    return (
        <tr
            ref={drop}
            className={`relative h-[40px]  duration-200 bg-transparent ${
                isOver
                    ? "bg-gradient-to-r from-blue-200 via-20%  via-transparent to-transparent"
                    : ""
            }`}
        >
            <th
                ref={drag}
                className="align-middle border hover:border-blue-500 text-xs whitespace-nowrap "
            >
                {orderingFunction ? (
                    <button className=" p-2 pr-4 hover:cursor-grab">
                        <i
                            className="material-icons notranslate align-middle text-slate-300 mr-2"
                            style={{ fontSize: "1.2rem" }}
                        >
                            drag_indicator
                        </i>
                        {indexRow + 1}
                    </button>
                ) : (
                    <span className="w-[36px] min-w-[36px]">
                        {indexRow + 1}
                    </span>
                )}
            </th>

            {fields.map((field, indexField) => (
                <CellWrapper
                    key={indexField}
                    dataLine={dataLine}
                    field={field}
                    indexField={indexField}
                    ButtonOptions={ButtonOptions}
                />
            ))}
        </tr>
    );
};

export default TableRowData;
