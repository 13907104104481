import DOMPurify from "dompurify";

import { useTranslation } from "react-i18next";

const IllustratedModalContent = ({ title, text, content }) => {
    const { t } = useTranslation();

    return (
        <div
            style={{
                pointerEvents: "auto",
            }}
            className="modal w-full h-full md:flex md:flex-row md:min-h-[550px]"
        >
            <figure
                className="relative md:w-[50%] background-img"
                style={{ backgroundImage: "url(/img/login.svg)" }}
            >
                <div className="md:absolute text-white lg:text-lg w-full md:max-w-[70%] p-6 text-left py-14 px-6">
                    <div className="font-bold text-3xl">{title}</div>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(text),
                        }}
                        className="mt-4"
                    ></p>
                </div>
            </figure>
            <div className="relative md:w-[50%] p-4 md:p-0 md:flex mb-16">
                <div className="w-full my-auto px-4 lg:mr-12">{content}</div>
                <img
                    src="/img/logo-black.svg"
                    width="50"
                    className="absolute bottom-[-56px] right-4"
                    alt={t("applicationTitle")}
                />
            </div>
        </div>
    );
};

export default IllustratedModalContent;
