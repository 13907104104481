import axios from "axios";
import moment from "moment";
import { useDriveStore } from "providers/ProviderDrive";

import apiURL from "utils/apiURL";

export async function getAdminProjects() {
    const { data } = await axios.get(apiURL.getTimesheetProjects);
    return data;
}

export async function getAdminTasks() {
    const { data } = await axios.get(apiURL.getTimesheetTasks);
    return data;
}

export async function getAdminFields() {
    const { data } = await axios.get(apiURL.getTimesheetFields);
    return data;
}
export async function getAdminTeam() {
    const { data } = await axios.get(apiURL.getTeamTimesheetProject);
    return data;
}

export async function getAdminCustomers() {
    const { data } = await axios.get(apiURL.getTimesheetCustomers);
    return data;
}

export function getActualSeason(configuration) {
    const season = configuration.seasons.find((e) => {
        const now = new Date();
        const start = new Date(e.startDate);
        const end = new Date(e.endDate);
        if (now >= start && now <= end) {
            return e;
        }
    });
    return season || configuration.seasons[0];
}

export const absencesTypesOptions = [
    {
        value: "VACATION",
        label: "vacances",
        code: "",
        letters: {
            fr: "Vac",
            en: "Vac",
        },
    },
    {
        value: "SICKNESS",
        label: "maladie",
        code: "",
        letters: {
            fr: "Mal",
            en: "Sic",
        },
    },
    {
        value: "ACCIDENT",
        label: "accident",
        code: "",
        letters: {
            fr: "Acc",
            en: "Acc",
        },
    },
    {
        value: "FORMATION",
        label: "formation",
        code: "",
        letters: {
            fr: "For",
            en: "For",
        },
    },
    {
        value: "FAMILY",
        label: "paternite/maternite",
        code: "",
        letters: {
            fr: "Fam",
            en: "Fam",
        },
    },
    {
        value: "RELOCATION",
        label: "demenagement",
        code: "",
        letters: {
            fr: "Dem",
            en: "Rel",
        },
    },
    {
        value: "DEATH",
        label: "deces",
        code: "",
        letters: {
            fr: "Dec",
            en: "Dea",
        },
    },
    // { value: "RECOVERY", label: "recuperationDheures" },
];

export const absencesStatutOptions = [
    {
        value: "PENDING",
        label: "enValidation",
        color: "blue",
    },
    {
        value: "ACCEPTED",
        label: "accepte",
        color: "green",
    },
    {
        value: "DENIED",
        label: "refuse",
        color: "red",
    },
];

export const getMyTimesheetProjects = () => {
    const { userProjects } = useDriveStore.getState();
    return userProjects.filter((project) => project.isTimesheet);
};

export function isMorningConcerned(startDate, endDate) {
    return (
        moment(startDate).isBefore(
            moment(startDate).startOf("day").add(9, "hours"),
        ) &&
        moment(endDate).isAfter(
            moment(startDate).startOf("day").add(9, "hours"),
        )
    );
}

export function isLunchConcerned(startDate, endDate) {
    return (
        moment(endDate).isAfter(
            moment(startDate).startOf("day").add(12, "hours"),
        ) &&
        moment(startDate).isBefore(
            moment(startDate).startOf("day").add(12, "hours"),
        )
    );
}
