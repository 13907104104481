import Painterro from "painterro";

import { useEffect, useRef } from "react";

const ImageEditor = ({ image, onSave, onClose }) => {
    const painterroRef = useRef(null);

    useEffect(() => {
        if (painterroRef.current) {
            painterroRef.current.show(image);
        } else {
            painterroRef.current = Painterro({
                saveHandler: (image, done) => {
                    const dataURL = image.asDataURL();
                    if (onSave) onSave(dataURL);
                    if (onClose) onClose(false);

                    done(true);
                },
                onClose: () => {
                    if (onClose) onClose(false);
                },
                defaultTool: "brush",
                hiddenTools: [
                    "select",
                    "pixelize",
                    "crop",
                    "resize",
                    "open",
                    "eraser",
                    "clear",
                    "zoomin",
                    "zoomout",
                    "bucket",
                    "settings",
                    "fillColor",
                    "shadow",
                ],
                toolbarPosition: "top",
                defaultLineWidth: 2,
                defaultArrowLength: 10,
            });
            painterroRef.current.show(image);
        }
    }, [image, onSave, onClose]);

    return <div id="painterro-container"></div>;
};

export default ImageEditor;
