// Permet de rendre un élément redimensionnable sur l'axe vertical
export function makeResizable(element, resizer, maximumHeight) {
    const minimumHeight = 60;
    if (!maximumHeight) maximumHeight = element.parentNode.clientHeight;
    let originalHeight = minimumHeight;
    let originalMouseY = 0;
    let mouseDownDate;
    let mouseUpDate;

    const handleDown = (e) => {
        e.preventDefault();
        mouseDownDate = new Date();
        originalHeight = parseFloat(
            getComputedStyle(element, null)
                .getPropertyValue("height")
                .replace("px", ""),
        );
        originalMouseY = e.pageY ? e.pageY : e.changedTouches[0].pageY;

        window.addEventListener("mousemove", resize);
        window.addEventListener("touchmove", resize);

        window.addEventListener("mouseup", stopResize);
        window.addEventListener("touchend", stopResize);
    };

    resizer.addEventListener("mousedown", handleDown);
    resizer.addEventListener("touchstart", handleDown);

    function resize(e) {
        let pageY =
            e.pageY && e.pageY > 0
                ? e.pageY
                : e.changedTouches
                  ? e.changedTouches[0].pageY
                  : 0;

        let height = originalHeight - (pageY - originalMouseY);
        if (height > minimumHeight && height < maximumHeight) {
            element.style.height = height + "px";
        }
    }

    function stopResize(e) {
        window.removeEventListener("mousemove", resize);
        window.removeEventListener("touchmove", resize);
        mouseUpDate = new Date();

        if (mouseUpDate.getTime() - mouseDownDate.getTime() < 200)
            handleClick(e);
    }

    // Permet de définir la hauteur minimale / maximale selon si l'on clique quand la hauteur était en dessous / dessus de la moitié de la hauteur maximale
    function handleClick(e) {
        let pageY = e.pageY ? e.pageY : e.changedTouches[0].pageY;
        if (pageY < maximumHeight / 2)
            element.style.height = minimumHeight + "px";
        else element.style.height = maximumHeight + "px";
    }
}
