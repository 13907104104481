import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getActualSeason } from "helpers/timesheet";
import apiURL from "utils/apiURL";

import { DriveToolsContext } from "components/drive/Drive";

import { ModalContext } from "layout/LayoutModal";

import ModalEntry from "../modalEntry/ModalEntry";
import DateViewer from "./summary/DateViewer";
import DraftsList from "./summary/DraftsList";
import SameDay from "./summary/SameDay";
import TimeWorked from "./summary/TimeWorked";

import { Button, Flex } from "antd";

const TabSummary = () => {
    const { t } = useTranslation();

    const { setLoader, getCurrentProfile } = useContext(DriveToolsContext);
    const { scheduleConfiguration } = useProfileStore();
    const actualSeason = getActualSeason(scheduleConfiguration);
    const [dateFilter, setDateFilter] = useState(moment().format("YYYY-MM-DD"));
    const { addModalData, removeModal } = useContext(ModalContext);
    const [filteredEntries, setFilteredEntriesEntries] = useState([]);
    useEffect(() => {
        getFilteredEntries(dateFilter).then(setFilteredEntriesEntries);
    }, [dateFilter]);

    const getFilteredEntries = useCallback(
        async (dateFilter) => {
            setLoader(true);
            const { data } = await axios.post(apiURL.getMyTimeEntries, {
                date: dateFilter,
            });
            setLoader(false);
            return data;
        },
        [setLoader],
    );

    const sendEntries = useCallback(async () => {
        setLoader(true);
        const { data } = await axios.post(apiURL.sendTimesheetEntries, {
            entries: filteredEntries.filter((e) => !e.sendDate),
        });

        setFilteredEntriesEntries((e) =>
            e.map((entry) =>
                data.find((e) => e._id === entry._id)?.sendDate
                    ? {
                          ...entry,
                          sendDate: data.find((e) => e._id === entry._id)?.sendDate,
                      }
                    : entry,
            ),
        );

        setLoader(false);
        return data;
    }, [setLoader, filteredEntries]);

    const canValidate = filteredEntries?.filter((e) => !e.sendDate).length > 0;
    const [refreshToday, setrefreshToday] = useState(true);
    useEffect(() => {
        setrefreshToday(!refreshToday);
    }, [filteredEntries]);

    return (
        <Flex
            vertical
            style={{
                height: "100%",
                paddingBottom: 12,
                maxWidth: 550,
            }}>
            <DateViewer dateFilter={dateFilter} setDateFilter={setDateFilter} refreshToday={refreshToday} />
            <TimeWorked dateFilter={dateFilter} canValidate={canValidate} sendEntries={sendEntries} />
            <SameDay
                dateFilter={dateFilter}
                setLoader={setLoader}
                setFilteredEntriesEntries={setFilteredEntriesEntries}
            />
            <DraftsList
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
                entries={filteredEntries}
                setFilteredEntriesEntries={setFilteredEntriesEntries}
            />
            <Button
                children={t("saisirDuTemps")}
                size="large"
                variant="solid"
                color="primary"
                onClick={() => {
                    addModalData({
                        key: "modalEntry",
                        isForm: false,
                        title: t("nouvelleSaisieDeTemps"),
                        content: (
                            <ModalEntry
                                canSelectProject
                                canSelectDate
                                getCurrentProfile={getCurrentProfile}
                                onClose={(data) => {
                                    if (moment(data.startDate).isSame(dateFilter, "day")) {
                                        setFilteredEntriesEntries((e) => [...e, data]);
                                    } else {
                                        setDateFilter(moment(data.startDate).format("YYYY-MM-DD"));
                                    }
                                    removeModal("modalEntry");
                                }}
                                preData={{
                                    startDate: moment(dateFilter)
                                        .set({
                                            hours: parseInt(actualSeason.workDayStartHour.split(":")[0]),
                                            minute: parseInt(actualSeason.workDayStartHour.split(":")[1]),
                                            second: 0,
                                        })
                                        .toISOString(),
                                    endDate: moment(dateFilter)
                                        .set({
                                            hours: parseInt(actualSeason.workDayEndHour.split(":")[0]),
                                            minute: parseInt(actualSeason.workDayEndHour.split(":")[1]),
                                            second: 0,
                                        })
                                        .toISOString(),
                                }}
                            />
                        ),
                    });
                }}
            />
            {/* <SummaryInfos
                    setLoader={setLoader}
                    filteredEntries={filteredEntries}
                /> */}
        </Flex>
    );
};

export default TabSummary;
