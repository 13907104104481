import { Text as TextPrint, View } from "@react-pdf/renderer";
import moment from "moment";

import { Fragment, cloneElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { formStatus } from "helpers/forms";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { RenderWrapperPrint, pdfStyles } from "../ExportFormPDF";
import { WrapperFieldAnswer } from "../FormAnswer";
import { RenderTextFieldAnswer } from "../FormAnswerList";
import { useFormsStore } from "../FormView";

import { Card, Checkbox, Col, Divider, Flex, Form, Input, Row, Select, Tag, theme } from "antd";

export function ReferenceAnswer({ index, title, required, templateField, options, disabled }) {
    const { listAnswers, listTemplates } = useFormsStore();
    const form = Form.useFormInstance();
    const value = Form.useWatch(["fields", index, "value"], form);
    const _id = Form.useWatch("_id", form);
    const template = Form.useWatch("template", form);

    const templateOptions = template
        ? listTemplates?.find((e) => e._id === template._id)?.fields?.find((e) => e._id === templateField)?.options
        : options;

    const selectedForm = listAnswers?.find((answer) => answer._id === value);
    const [showForm, setshowForm] = useState(false);

    const usedsForm = listAnswers
        ?.filter((a) => a._id !== _id)
        .reduce((acc, answer) => {
            answer.fields.forEach((e) => {
                if (e.type === "REFERENCE" && e.value && e.value !== value) {
                    acc.push(e.value);
                }
            });
            return acc;
        }, []);

    return (
        <>
            <Form.Item
                name={["fields", index, "value"]}
                rules={[
                    {
                        required,
                    },
                ]}
                label={
                    <Flex
                        align="center"
                        gap="small"
                        justify="space-between"
                        style={{
                            width: "100%",
                        }}
                        wrap>
                        <Text bold>{title} :</Text>
                        <Checkbox onChange={(e) => setshowForm(e.target.checked)} checked={showForm}>
                            <Text type="secondary">Afficher le formulaire</Text>
                        </Checkbox>
                    </Flex>
                }
                help={
                    showForm &&
                    selectedForm && (
                        <Card
                            size="small"
                            style={{
                                backgroundColor: "#f0f2f5",
                                marginBottom: 30,
                            }}>
                            <Form initialValues={selectedForm} disabled={true}>
                                <Flex vertical gap="small">
                                    {selectedForm.fields?.map((field, i) => (
                                        <WrapperFieldAnswer preview field={field} index={i} key={i} />
                                    ))}
                                </Flex>
                            </Form>
                        </Card>
                    )
                }>
                <Select
                    disabled={disabled}
                    showSearch
                    style={{
                        height: "min-content",
                    }}
                    onChange={(value) => {
                        if (!!value && showForm) {
                            setshowForm(false);
                            setTimeout(() => {
                                setshowForm(true);
                            }, 10);
                        }
                    }}
                    allowClear
                    labelRender={(answer) => {
                        return answer.label ? (
                            cloneElement(answer.label, {
                                isLabel: true,
                            })
                        ) : (
                            <Text type="secondary">Rechercher un formulaire</Text>
                        );
                    }}
                    options={listAnswers
                        ?.filter((e) => !usedsForm.includes(e?._id))
                        ?.filter((answer) =>
                            templateOptions?.templates
                                ? templateOptions?.templates.includes(answer?.template?._id) || answer._id === value
                                : true,
                        )
                        .filter((answer) => {
                            if (answer._id === value) return true;
                            return templateOptions?.status
                                ? templateOptions?.status.includes(answer.status) &&
                                      (answer.fields.some((f) => f.type === "STATE")
                                          ? answer.fields.some(
                                                (f) =>
                                                    f.type === "STATE" &&
                                                    templateOptions.status.includes(f.options[f.value]),
                                            )
                                          : true)
                                : true;
                        })
                        .sort((a, b) => a.updatedDate - b.updatedDate)
                        .map((answer) => ({
                            label: <RenderLabel answer={answer} options={templateOptions} />,
                            value: answer._id,
                        }))}
                />
            </Form.Item>
        </>
    );
}

const RenderLabel = ({ answer, options, isLabel }) => {
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    return (
        <Flex
            vertical
            gap="small"
            style={{
                paddingTop: 10,
                paddingBottom: 10,
            }}>
            <Flex gap="small" align="start" justify="space-between" wrap>
                <Flex vertical gap="small" style={{ flex: 1, width: mdMedia ? 0 : "100%" }}>
                    <Flex gap="small" align="center">
                        <MaterialIcons name="description" />
                        <Text ellipsis size={mdMedia ? "base" : "sm"} bold>
                            {answer.title}
                        </Text>
                    </Flex>
                    <Flex gap="small" align="center">
                        <Text size="sm" bold>
                            {answer.fields.find((e) => e.templateField === options?.fields[0])?.title} :
                        </Text>
                        <RenderTextFieldAnswer
                            field={answer.fields.find((e) => e.templateField === options?.fields[0])}
                        />
                    </Flex>
                    {!isLabel &&
                        options?.fields?.map((el, i) => {
                            if (i === 0) return;
                            const field = answer.fields.find((e) => e.templateField === el);
                            if (field)
                                return (
                                    <Flex key={i} gap="small" align="center">
                                        <Text size="sm" bold>
                                            {field.title} :
                                        </Text>
                                        <RenderTextFieldAnswer field={field} />
                                    </Flex>
                                );
                        })}
                </Flex>

                <Flex
                    vertical
                    gap="small"
                    style={{
                        marginLeft: "auto",
                    }}>
                    <Text type="secondary" size="xs">
                        Dernière modification : {moment(answer.updatedDate).format("DD/MM/YYYY HH:mm")}
                    </Text>
                    <Flex
                        style={{
                            flex: 1,
                        }}
                        justify="end"
                        gap="small"
                        wrap>
                        {answer?.fields
                            ?.filter((e) => e.type === "STATE")
                            ?.map((field) => {
                                return (
                                    <Tag
                                        color={
                                            field?.value === 0
                                                ? "error"
                                                : field?.value === 1
                                                  ? "warning"
                                                  : field?.value === 2
                                                    ? "green"
                                                    : "blue"
                                        }>
                                        <Flex align="center" gap={6}>
                                            {field?.options[field?.value]}
                                        </Flex>
                                    </Tag>
                                );
                            })}

                        <Tag color={formStatus[answer.status].variant}>
                            <Flex align="center" gap={6}>
                                <MaterialIcons name={formStatus[answer.status].icon} size="xs" />
                                {formStatus[answer.status].label}
                            </Flex>
                        </Tag>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export function ReferenceTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    const { listTemplates, getTemplatesForms } = useFormsStore();

    const id = Form.useFormInstance().getFieldValue("_id");
    const template = Form.useWatch(["fields", index, "options", "templates"], Form.useFormInstance());

    useEffect(() => {
        if (!listTemplates) {
            getTemplatesForms();
        }
    }, [listTemplates]);

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}></Col>
            <Col span={24} lg={12}>
                <Flex vertical gap="small">
                    {listTemplates && (
                        <Form.Item label="Modèle de formulaire :" name={[index, "options", "templates"]}>
                            <Select
                                allowClear
                                showSearch
                                mode="multiple"
                                options={listTemplates
                                    ?.filter((template) => template._id !== id)
                                    .map((template) => ({
                                        label: (
                                            <Flex align="center" gap="small">
                                                <MaterialIcons name="description" />
                                                <Text>{template.title}</Text>
                                            </Flex>
                                        ),
                                        value: template._id,
                                        data: template,
                                    }))}
                            />
                        </Form.Item>
                    )}
                    {listTemplates && (
                        <Form.Item label="Champs affichés :" name={[index, "options", "fields"]}>
                            <Select
                                allowClear
                                showSearch
                                mode="multiple"
                                options={listTemplates
                                    ?.filter((e) => template?.includes(e._id))
                                    .map((template) => template.fields)
                                    ?.flat()
                                    .filter(
                                        (field) =>
                                            field.type !== "PICTURE" &&
                                            field.type !== "ZONE" &&
                                            field.type !== "SIGNATURE" &&
                                            field.type !== "TITLE" &&
                                            field.type !== "SUBTITLE" &&
                                            field.type !== "SEPARATOR",
                                    )
                                    .map((field) => ({
                                        label: field.title,
                                        value: field._id,
                                        data: field,
                                    }))}
                            />
                        </Form.Item>
                    )}
                </Flex>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Statut :" name={[index, "options", "status"]}>
                    <Checkbox.Group
                        style={{
                            gap: "8px 30px",
                        }}>
                        {listTemplates
                            ?.filter((e) => template?.includes(e._id) && !!e?.fields?.find((e) => e.type === "STATE"))
                            .map((template) => (
                                <Fragment key={template._id}>
                                    <Divider
                                        orientation="left"
                                        style={{
                                            margin: 10,
                                        }}>
                                        <Text size="sm" type="secondary">
                                            {template.title}
                                        </Text>
                                    </Divider>
                                    {template.fields
                                        .filter((e) => e.type === "STATE")
                                        .map((state, i) =>
                                            state.options.map((state, i) => (
                                                <Checkbox key={i} value={state}>
                                                    <Tag
                                                        color={
                                                            i === 0
                                                                ? "error"
                                                                : i === 1
                                                                  ? "warning"
                                                                  : i === 2
                                                                    ? "green"
                                                                    : "blue"
                                                        }>
                                                        <Flex align="center" gap="small">
                                                            <MaterialIcons name={"docs"} size={12} />
                                                            <Text
                                                                color={
                                                                    i === 0
                                                                        ? "error"
                                                                        : i === 1
                                                                          ? "warning"
                                                                          : i === 2
                                                                            ? "green"
                                                                            : "blue"
                                                                }>
                                                                {state}
                                                            </Text>
                                                        </Flex>
                                                    </Tag>
                                                </Checkbox>
                                            )),
                                        )}
                                </Fragment>
                            ))}
                        <Divider
                            orientation="left"
                            style={{
                                margin: 10,
                            }}>
                            <Text size="sm" type="secondary">
                                Validation
                            </Text>
                        </Divider>
                        {Object.keys(formStatus).map((status) => (
                            <Checkbox key={status} value={status}>
                                <Tag color={formStatus[status].color}>
                                    <Flex align="center" gap="small">
                                        <MaterialIcons name={formStatus[status].icon} size={12} />
                                        <Text color={formStatus[status].color}>{formStatus[status].label}</Text>
                                    </Flex>
                                </Tag>
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                </Form.Item>
            </Col>
        </Row>
    );
}

export function ReferencePrint({ field, index }) {
    return (
        <>
            <View
                style={{
                    ...pdfStyles.flexLine,
                }}>
                <View
                    style={{
                        width: "30%",
                        border: "1px solid black",
                        backgroundColor: "#f5f5f5",
                        padding: 6,
                        borderTop: index === 0 ? "1px solid black" : 0,
                    }}>
                    <TextPrint style={pdfStyles.title}>{field?.title}</TextPrint>
                </View>
                <View
                    style={{
                        width: "70%",
                        border: "1px solid black",
                        backgroundColor: "#f5f5f5",
                        padding: 6,
                        borderLeft: 0,
                        borderTop: index === 0 ? "1px solid black" : 0,
                    }}>
                    <TextPrint
                        style={{
                            ...pdfStyles.title,
                        }}>
                        {field.value?.title}
                    </TextPrint>
                </View>
            </View>

            <View
                style={{
                    border: "1px solid black",
                    backgroundColor: "#e5e5e5",
                    padding: 6,
                    borderTop: 0,
                }}>
                {field.value?.fields?.map((field, index) => (
                    <RenderWrapperPrint field={field} index={index} key={index} />
                ))}
            </View>
        </>
    );
}
