import axios from "axios";
import { create } from "zustand";

import { useEffect } from "react";

import apiURL from "utils/apiURL";
import constants from "utils/constants";

export default function ProviderProfile() {
    const { getCompleteProfile } = useProfileStore();
    useEffect(() => {
        getCompleteProfile();
    }, []);
    return null;
}

export const useProfileStore = create((set, get) => ({
    // DATA
    loading: true,
    user: null,
    profile: null,
    customerConfig: null,
    scheduleConfiguration: null,
    permissionsTools: {
        timesheetAdmin: {
            canView: false,
        },
        timesheetUser: {
            canView: false,
        },
        automations: {
            canView: false,
            canViewVisas: false,
        },
        visas: {
            canView: false,
        },
        photosUser: {
            canView: false,
        },
        photosAdmin: {
            canView: false,
        },
        formsAdmin: {
            canView: false,
        },
        formsUser: {
            canView: false,
        },
        plansAdmin: {
            canView: false,
        },
        plansUser: {
            canView: false,
        },
    },

    // ACTIONS
    // Complete toutes les informations de base pour l'utilisateur
    getCompleteProfile: async () => {
        set({ loading: true });
        try {
            const { data: user } = await axios.get(apiURL.authUser);
            const { data: profile } = await axios.get(apiURL.getUserProfile);
            const { data: customerConfig } = await axios.post(
                apiURL.getCustomerConfiguration,
                {},
            );

            if (customerConfig) {
                const { data: scheduleConfiguration } = await axios.get(
                    apiURL.getScheduleConfiguration +
                        (customerConfig?.timesheet.isActive &&
                        customerConfig?.timesheet.scheduleConfiguration
                            ? customerConfig.timesheet.scheduleConfiguration
                            : ""),
                );
                set({
                    user,
                    profile,
                    customerConfig,
                    scheduleConfiguration,
                });
                get().getPermissionsTools();
            } else {
                set({
                    user,
                    profile,
                });
            }
        } catch (error) {
            // TODO Error handling
            console.error(error.message);
        } finally {
            set({ loading: false });
        }
    },
    getCurrentProfile: async () => {
        set({ loading: true });
        try {
            const profile = await axios.get(apiURL.getUserProfile);
            set({ profile: profile.data });
        } catch (error) {
            // TODO Error handling
            console.error(error.message);
        } finally {
            set({ loading: false });
        }
    },

    // Rafraichit le profil de l'utilisateur ( pour les données qui peuvent changer après un appel )
    refreshProfile: async () => {
        set({ loading: true });
        try {
            const profile = await axios.get(apiURL.getUserProfile);
            set({ profile: profile.data });
        } catch (error) {
            // TODO Error handling
            console.error(error.message);
        } finally {
            set({ loading: false });
        }
    },

    // Se connecter à l'aide de l'email et du mot de passe
    login: async (email, password) => {
        set({ loading: true });
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                },
            };
            const body = JSON.stringify({ email, password });
            await axios.post(apiURL.authUser, body, config);
            await get().getCompleteProfile();
        } catch (e) {
            // TODO Error handling
            console.error(error.message);
        } finally {
            set({ loading: false });
        }
    },

    logout: async () => {
        set({ loading: true });
        try {
            set({
                user: null,
                profile: null,
                customerConfig: null,
                scheduleConfiguration: null,
                permissionsTools: {
                    timesheetAdmin: {
                        canView: false,
                    },
                    timesheetUser: {
                        canView: false,
                    },
                    automations: {
                        canView: false,
                        canViewVisas: false,
                    },
                    visas: {
                        canView: false,
                    },
                    photosUser: {
                        canView: false,
                    },
                    photosAdmin: {
                        canView: false,
                    },
                    formsAdmin: {
                        canView: false,
                    },
                    formsUser: {
                        canView: false,
                    },
                    plansAdmin: {
                        canView: false,
                    },
                    plansUser: {
                        canView: false,
                    },
                },
            });
        } catch (error) {
            // TODO Error handling
            console.error(error.message);
        } finally {
            set({ loading: false });
        }
    },
    setPermissionsTools: async (values) => {
        set({
            permissionsTools: {
                ...get().permissionsTools,
                ...values,
            },
        });
    },
    // Complete les permissions par rapport à la customerConfig et à l'email de l'utilisateur
    getPermissionsTools: async () => {
        const userEmail = await get().user.email;
        if (userEmail) {
            const {
                data: { hasRoleAdmin, hasRoleWorker },
            } = await axios.get(apiURL.checkTimesheetPermissions);
            const customerConfig = await get().customerConfig;

            set({
                permissionsTools: {
                    timesheetAdmin: {
                        canView: Boolean(
                            hasRoleAdmin &&
                                customerConfig?.timesheet?.isActive &&
                                findUser(
                                    customerConfig.timesheet.adminUsers,
                                    userEmail,
                                ),
                        ),
                    },
                    timesheetUser: {
                        canView: Boolean(
                            hasRoleWorker &&
                                customerConfig?.timesheet?.isActive &&
                                findUser(
                                    [
                                        ...customerConfig.timesheet.workerUsers,
                                        ...customerConfig.timesheet.adminUsers,
                                    ],
                                    userEmail,
                                ),
                        ),
                    },
                    automations: {
                        canView: Boolean(
                            findUser(
                                [
                                    ...customerConfig.visas.adminUsers,
                                    ...customerConfig.versioning.adminUsers,
                                ],
                                userEmail,
                            ),
                        ),
                        canViewVisas: Boolean(
                            findUser(
                                [
                                    ...customerConfig.visas.adminUsers,
                                    ...customerConfig.visas.workerUsers,
                                ],
                                userEmail,
                            ),
                        ),
                    },
                    visas: {
                        canView: Boolean(
                            findUser(
                                [
                                    ...customerConfig.visas.adminUsers,
                                    ...customerConfig.visas.workerUsers,
                                ],
                                userEmail,
                            ),
                        ),
                    },
                    photosUser: {
                        canView: Boolean(
                            findUser(
                                [
                                    ...customerConfig.timesheet.workerUsers,
                                    ...customerConfig.photos.workerUsers,
                                ],
                                userEmail,
                            ),
                        ),
                    },
                    photosAdmin: {
                        canView: Boolean(
                            hasRoleAdmin &&
                                findUser(
                                    [
                                        ...customerConfig.timesheet.adminUsers,
                                        ...customerConfig.photos.adminUsers,
                                    ],
                                    userEmail,
                                ),
                        ),
                    },

                    formsUser: {
                        canView: Boolean(
                            findUser(
                                [...customerConfig.forms.workerUsers],
                                userEmail,
                            ),
                        ),
                    },
                    formsAdmin: {
                        canView: Boolean(
                            customerConfig.forms.adminUsers.find(
                                (user) => user.email === userEmail,
                            ),
                        ),
                    },
                    plansUser: {
                        canView: Boolean(
                            customerConfig.plans.workerUsers.find(
                                (user) => user.email === userEmail,
                            ),
                        ),
                    },
                    plansAdmin: {
                        canView: Boolean(
                            customerConfig.plans.adminUsers.find(
                                (user) => user.email === userEmail,
                            ),
                        ),
                    },
                },
            });
        }
    },
}));

const findUser = (users, email) => {
    return (
        users.find((user) => user.email === email) ||
        constants.demoBimonoMails.includes(email)
    );
};
