import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { isLunchConcerned, isMorningConcerned } from "helpers/timesheet";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";

import ModalEntry from "../../modalEntry/ModalEntry";

import { Empty, Flex, Tag } from "antd";

const DraftsList = ({ dateFilter, setDateFilter, entries, setFilteredEntriesEntries }) => {
    const { t } = useTranslation();
    const { scheduleConfiguration } = useProfileStore();
    const { addModalData, removeModal } = useContext(ModalContext);

    return (
        <Flex
            vertical
            gap="middle"
            className="my-2"
            style={{
                flex: 1,
                overflow: "auto",
            }}>
            {entries?.length ? (
                entries
                    .sort((a, b) => a.creationDate - b.creationDate)
                    // .sort((entry) => (entry.sendDate ? -1 : 1))
                    .map((entry) => {
                        let timeTotal = moment.duration(moment(entry.endDate).diff(moment(entry.startDate)));

                        if (
                            scheduleConfiguration &&
                            !scheduleConfiguration.isMorningBreakHidden &&
                            isMorningConcerned(entry.startDate, entry.endDate)
                        ) {
                            const morningBreakDuration =
                                Constants.timesheetMaxMorningBreakDuration - entry.breakMorning;
                            timeTotal.subtract(moment.duration(morningBreakDuration, "minutes"));
                        }

                        if (
                            scheduleConfiguration &&
                            !scheduleConfiguration.isLunchBreakHidden &&
                            isLunchConcerned(entry.startDate, entry.endDate)
                        ) {
                            const lunchBreakDuration = Constants.timesheetMaxLunchBreakDuration - entry.breakLunch;
                            timeTotal.subtract(moment.duration(lunchBreakDuration, "minutes"));
                        }

                        timeTotal = moment.utc(timeTotal.asMilliseconds()).format("HH:mm");

                        return (
                            <li
                                key={entry._id}
                                className={
                                    "px-4 py-3 rounded-lg bg-slate-100 shadow text-xs cursor-pointer" +
                                    (entry.sendDate ? " opacity-70 " : "")
                                }
                                onClick={async () => {
                                    addModalData({
                                        key: "modalEntry",
                                        title: !!entry.sendDate
                                            ? t("saisieDeTemps") + " - " + t("envoyee")
                                            : t("modifierMaSaisieDeTemps"),
                                        isForm: false,
                                        content: (
                                            <ModalEntry
                                                canSelectProject
                                                canSelectDate
                                                preData={entry}
                                                readOnly={!!entry.sendDate}
                                                onDelete={async (id) => {
                                                    await axios.delete(apiURL.deleteTimesheetEntries + id);
                                                    setFilteredEntriesEntries((e) =>
                                                        e.filter((e) => e._id !== entry._id),
                                                    );
                                                    removeModal("modalEntry");
                                                }}
                                                onClose={async (data) => {
                                                    if (moment(data.startDate).isSame(dateFilter, "day")) {
                                                        setFilteredEntriesEntries((e) =>
                                                            e.map((e) => (e._id === data._id ? data : e)),
                                                        );
                                                    } else {
                                                        setDateFilter(moment(data.startDate).format("YYYY-MM-DD"));
                                                    }
                                                    removeModal("modalEntry");
                                                }}
                                            />
                                        ),
                                    });
                                }}>
                                {entry.isRecovery ? (
                                    <>
                                        <Flex justify="space-between" align="center" gap="small">
                                            <Text size="small" bold>
                                                {t("recuperationDheures")}
                                            </Text>
                                            {entry?.editionDetails?.wasEdited && (
                                                <MaterialIcons name="info" size="md" type="primary" />
                                            )}
                                            <Tag color="default">{timeTotal}</Tag>
                                        </Flex>
                                    </>
                                ) : (
                                    <>
                                        <h3 className="gap-2 font-bold flex w-full ">
                                            <span className="w-1/2">{entry.project?.title}</span>
                                            <span className="w-1/2 ml-2 font-normal text-right truncate">
                                                {entry.project?.customer?.name &&
                                                    "( " + entry.project.customer.name + " )"}
                                            </span>
                                        </h3>
                                        <div className="gap-2 mt-2 flex justify-between items-center ">
                                            <span className="flex-1">
                                                {entry?.timesheetTask?.title || entry?.timesheetTaskTitle}
                                            </span>
                                            {entry?.editionDetails?.wasEdited && (
                                                <MaterialIcons name="info" size="md" type="primary" />
                                            )}
                                            <Tag color="default">{timeTotal}</Tag>
                                        </div>
                                    </>
                                )}
                            </li>
                        );
                    })
            ) : (
                <Empty
                    style={{
                        margin: "auto 0",
                    }}
                />
            )}
        </Flex>
    );
};

export default DraftsList;
