import axios from "axios";

import { useCallback, useContext } from "react";

import apiURL from "utils/apiURL";

import { DriveToolsContext } from "components/drive/Drive";

import { useTimesheetStore } from "../TimesheetStore";
import { TimesheetContext } from "../TimesheetViewContext";
import TableTimeline from "./tableTimeline/Table";

const TabEntries = () => {
    const { setToolsData } = useContext(DriveToolsContext);
    const { userTasks, userFields, userTeam } = useContext(TimesheetContext);

    const { adminProjects } = useTimesheetStore();
    const getTasksDiary = useCallback(
        async ({ filters, pagination }) => {
            const { data } = await axios.post(apiURL.getFilteredTimeEntries, {
                filters,
                pagination,
            });

            // For Filters
            setToolsData((e) => ({
                ...e,
                filtersStore: {
                    users: userTeam.sort((a, b) => {
                        // Compare by firstname first
                        if (a.firstname < b.firstname) return -1;
                        if (a.firstname > b.firstname) return 1;
                        // If firstnames are equal, compare by lastname
                        if (a.lastname < b.lastname) return -1;
                        if (a.lastname > b.lastname) return 1;
                        return 0;
                    }),
                    projects: adminProjects
                        .filter((project) => project.isTimesheet)
                        .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)),
                },
            }));

            return {
                entries: data,
                users: userTeam,
                projects: adminProjects,
                tasks: userTasks,
                fields: userFields,
            };
        },
        [adminProjects, userTasks, userFields, userTeam],
    );

    const addTime = useCallback(async (newItem) => {
        const { data } = await axios.post(apiURL.createTimesheetEntry, newItem);
        const sendedItem = await axios.post(apiURL.sendTimesheetEntries, {
            entries: [data],
            isAdmin: true,
        });
        return { ...sendedItem.data[0] };
    }, []);

    const editTime = useCallback(async (newItem) => {
        const { data } = await axios.patch(apiURL.updateTimesheetEntry + newItem._id, newItem);
        return data;
    }, []);

    const deleteTime = useCallback(async (id) => {
        const { data } = await axios.delete(apiURL.deleteTimesheetEntries + id);
        return data;
    }, []);

    return (
        <TableTimeline
            getFunction={getTasksDiary}
            editFunction={editTime}
            addFunction={addTime}
            deleteFunction={deleteTime}
            //
        />
    );
};

export default TabEntries;
