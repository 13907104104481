import { Image, View } from "@react-pdf/renderer";
import axios from "axios";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";

import { formModalKey } from "helpers/forms";
import { convertPresignedUrlToBase64 } from "helpers/image";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";

import Text from "components/common/Text";

import { Button, Col, Flex, Form, Input, Row, theme } from "antd";

export function ZoneAnswer({ index, title, disabled }) {
    const sigCanvas = useRef({});
    const [canvasProps, setCanvasProps] = useState();
    const form = Form.useFormInstance();

    useEffect(() => {
        let formWidth = document.querySelector(`#formModal-${formModalKey} .ant-modal-body`)?.offsetWidth - 48;
        if (formWidth > 800) formWidth = 800 - 48;
        setCanvasProps({
            width: formWidth,
            height: formWidth > 400 ? 400 : formWidth,
        });
    }, []);

    const clear = () => sigCanvas.current.clear();
    useEffect(() => {
        const fields = form.getFieldsValue().fields;
        const originalValue = fields?.[index].value;
        if (originalValue?.includes(".png") && canvasProps) {
            axios
                .post(apiURL.getFilePresignedWithoutId, {
                    fileName: originalValue,
                })
                .then(async ({ data }) => {
                    const dataImage = await convertPresignedUrlToBase64(data);
                    if (dataImage) sigCanvas.current?.fromDataURL(dataImage, canvasProps);
                });
        }
    }, [form, index, canvasProps]);

    const save = async () => {
        const base64Data = sigCanvas.current.getCanvas().toDataURL("image/png");
        fetch(base64Data)
            .then((res) => res.blob())
            .then((blob) => {
                const file = new File([blob], Date.now() + generateRandomToken(10) + ".png", { type: "image/png" });
                const fields = form.getFieldsValue().fields;
                form.setFieldValue(
                    "fields",
                    fields.map((field, i) =>
                        i === index
                            ? {
                                  ...field,
                                  value: file,
                              }
                            : field,
                    ),
                );
            });
    };
    const [canDraw, setCanDraw] = useState(false);

    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            style={{
                maxWidth: "100%",
            }}>
            <div
                style={{
                    border: canDraw ? "2px solid blue" : "2px solid black",
                    width: "min-content",
                    maxWidth: "100%",
                    position: "relative",
                }}>
                {canvasProps && (
                    <SignatureCanvas
                        onEnd={save}
                        ref={sigCanvas}
                        canvasProps={{
                            ...canvasProps,
                            style: { pointerEvents: canDraw ? "auto" : "none" },
                        }}
                    />
                )}
                <Flex
                    style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "space-between",
                        bottom: 0,
                        width: "100%",
                    }}>
                    <Button disabled={disabled} size="large" onClick={clear}>
                        Effacer
                    </Button>
                    <Button
                        disabled={disabled}
                        size="large"
                        color="primary"
                        variant={canDraw ? "solid" : "outlined"}
                        onClick={() => {
                            setCanDraw(!canDraw);
                        }}>
                        {canDraw ? "Valider" : "Dessiner"}
                    </Button>
                </Flex>
            </div>
        </Form.Item>
    );
}

export function ZoneTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    );
}

export function ZonePrint({ field, index }) {
    return (
        <View
            style={{
                backgroundColor: "white",
                border: "1px solid black",
            }}>
            <Image
                src={{
                    uri: field.value,
                }}
                style={{
                    width: 400,
                    height: 150,
                    objectFit: "contain",
                    objectPosition: "center",
                }}
            />
        </View>
    );
}
