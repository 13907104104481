import axios from "axios";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { getActivityDetails, isBIMCollabAvailable } from "helpers/bcf";
import { getCommentUserName } from "helpers/comment";
import { fromDateToStringDateHours } from "helpers/date";
import { formatMentions } from "helpers/text";
import apiURL from "utils/apiURL";

import ConfirmationModal from "components/modal/ConfirmationModal";
import InformationModal from "components/modal/InformationModal";
import EmojisReactions from "components/reactions/EmojisReactions";
import ReactionsList from "components/reactions/ReactionsList";
import UserCircle from "components/user/UserCircle";

import { CommentsContext } from "./CommentsContent";

const SubCommentItem = ({
    profile,
    files,
    parentComment,
    subComment,
    subComments,
    setSubComments,
    editedSubComment,
    setEditedSubComment,
    isFromForm,
}) => {
    const { customerConfig, isFromViewer } = useContext(CommentsContext);

    const { t } = useTranslation();

    const [informationModal, setInformationModal] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const [reactions, setReactions] = useState(subComment.reactions);
    const [showReactions, setShowReactions] = useState(false);

    const deleteComment = async () => {
        const deletedRes = await axios.delete(
            apiURL.deleteComment + subComment._id,
        );

        if (deletedRes && deletedRes.data) {
            toastAlert("success", t("commentaireSupprime"));

            // Suppression du commentaire parmi la liste des sous-commentaires

            let commentIndex = subComments.findIndex(
                (comment) => comment._id === subComment._id,
            );

            if (commentIndex > -1) {
                setSubComments([
                    ...subComments.slice(0, commentIndex),
                    ...subComments.slice(commentIndex + 1),
                ]);
            }
        } else {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const handleReaction = async (code) => {
        const resReaction = await axios.put(
            apiURL.handleCommentReaction + subComment._id,
            {
                code,
            },
        );

        if (resReaction?.data) setReactions(resReaction.data);
    };

    const options = profile && [
        {
            content: !parentComment.status === "CLOSED" &&
                subComment.author &&
                subComment.author._id === profile.user._id && (
                    <button
                        className="p-2 w-full text-left"
                        onClick={(e) => setEditedSubComment(subComment)}
                    >
                        {t("modifier")}
                    </button>
                ),
        },
        {
            content: ((subComment.author &&
                subComment.author._id === profile.user._id) ||
                profile.projects.some((project) =>
                    files.some((file) => project._id === file.project._id),
                )) && (
                <button
                    className="p-2 w-full text-left"
                    onClick={(e) => {
                        setConfirmationModal({
                            title: t(
                                "voulezVousVraimentSupprimerCeCommentaire?",
                            ),
                            callBackFunc: deleteComment,
                            closeReset: setConfirmationModal,
                        });
                    }}
                >
                    {t("supprimer")}
                </button>
            ),
        },
    ];

    return (
        <div className="mx-2 py-2">
            {/*!editedSubComment && (
                <div className="relative py-1">
                    <OptionMenu options={options} />
                </div>
            )*/}
            <div className="py-2">
                {subComment.image && (
                    <div
                        style={{
                            backgroundImage: "url(" + subComment.image + ")",
                        }}
                        className={
                            "relative w-full rounded-xl background-img hover:cursor-pointer" +
                            (isFromForm
                                ? " h-[250px] sm:h-[400px] md:h-[300px] lg:h-[400px]"
                                : " h-[180px]")
                        }
                        onClick={(e) => {
                            setInformationModal({
                                content: (
                                    <img
                                        src={subComment.image}
                                        alt=""
                                        className="w-full"
                                    />
                                ),
                                customWidth: "max-w-[750px] lg:max-w-[1050px]",
                                closeReset: setInformationModal,
                            });
                        }}
                    ></div>
                )}
                <div className="mt-4">
                    <div className="flex flex-row">
                        <UserCircle
                            user={subComment.author}
                            guestName={subComment.guestName}
                        />
                        <div className="ml-2 text-xs text-black">
                            <div className="font-bold">
                                {parentComment.BCFContent
                                    ? getActivityDetails(
                                          parentComment,
                                          subComment,
                                      )
                                    : getCommentUserName(subComment)}
                            </div>
                            <div>
                                {fromDateToStringDateHours(
                                    subComment.creationDate,
                                    true,
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="relative py-2">
                        {subComment.content && (
                            <div
                                className={
                                    "rounded-xl py-2 text-xs whitespace-pre-wrap" +
                                    (profile &&
                                    (!isFromViewer ||
                                        (isFromViewer &&
                                            !isBIMCollabAvailable(
                                                customerConfig,
                                            )))
                                        ? " pb-4"
                                        : "")
                                }
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: formatMentions(
                                            subComment.content,
                                        ),
                                    }}
                                ></p>
                            </div>
                        )}
                        {profile && !isBIMCollabAvailable(customerConfig) && (
                            <div className="relative flex flex-row">
                                {reactions.length > 0 && (
                                    <div className="my-auto">
                                        <ReactionsList reactions={reactions} />
                                    </div>
                                )}

                                <button
                                    type="button"
                                    className="flex p-1 mr-1"
                                    onClick={(e) =>
                                        setShowReactions(!showReactions)
                                    }
                                >
                                    <i
                                        className="material-icons notranslate m-auto"
                                        style={{
                                            fontSize: "1rem",
                                        }}
                                    >
                                        add_reaction
                                    </i>
                                </button>

                                {showReactions && (
                                    <div className="absolute z-[1] left-0 top-[-7px]">
                                        <EmojisReactions
                                            setVisible={setShowReactions}
                                            handleReaction={handleReaction}
                                            activeCode={
                                                reactions.find(
                                                    (reaction) =>
                                                        reaction.author ===
                                                        profile.user._id,
                                                )?.code
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {informationModal && (
                <InformationModal
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    customWidth={informationModal.customWidth}
                />
            )}

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    callBackFunc={confirmationModal.callBackFunc}
                    closeReset={confirmationModal.closeReset}
                />
            )}
        </div>
    );
};

export default SubCommentItem;
