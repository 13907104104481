import axios from "axios";
import { default as i18n, default as i18next } from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { getDaysBetweenTwoDates } from "helpers/date";
import { capitalize } from "helpers/string";
import { fromMongoToStringHoursMinutes, fromMsToHoursMinutes } from "helpers/time";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import ButtonExportHourCounter from "components/exports/ButtonExportHourCounter";

import { useTimesheetStore } from "../TimesheetStore";
import { applyConfiguration, applyMergeLinesForCounter } from "./tableTimeline/manageTable";

import { Button, DatePicker, Flex, Input, Select, Table } from "antd";

const TabSummaryCouter = () => {
    // Contexts
    const { t } = useTranslation();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const { adminMembers } = useTimesheetStore();
    const { toolsData, setToolsData, customerConfig } = useContext(DriveToolsContext);
    const { scheduleConfiguration } = useProfileStore();
    // Pagination
    const monthsPagination = moment(toolsData?.filters?.pagination).format("YYYY-MM");
    const setMonthsPagination = (date) => {
        setToolsData((tools) => ({
            ...tools,
            filters: {
                ...tools.filters,
                pagination: date.format("YYYY-MM-DD"),
            },
        }));
    };
    const changePagination = useCallback(
        (add) => {
            setLoading(true);
            if (add) setMonthsPagination(moment(monthsPagination).add(1, "month"));
            else setMonthsPagination(moment(monthsPagination).subtract(1, "month"));
        },
        [monthsPagination],
    );

    // Fetch data debounced from pagination
    const [dataList, setDataList] = useState({
        entries: [],
        cost: [],
        vacations: [],
    });
    const [loading, setLoading] = useState(true);
    const [updatedTime, setUpdatedTime] = useState(Date.now());
    const [resfreshAction, setResfreshAction] = useState(false);

    const [absences, setAbsences] = useState();
    useEffect(() => {
        Promise.all(
            adminMembers.map((e) =>
                axios.post(apiURL.getAbsencesAvailables, {
                    user: e._id,
                }),
            ),
        ).then((res) => {
            setAbsences(res.map((e) => e.data).flat());
        });
    }, [adminMembers]);

    useEffect(() => {
        setLoading(true);
        const controller = new AbortController();
        const signal = controller.signal;
        const debounceData = setTimeout(async () => {
            try {
                const { data } = await axios.post(
                    apiURL.getFilteredTimeEntries,
                    {
                        filters: {
                            dateRange: "month",
                        },
                        pagination: monthsPagination,
                    },
                    { signal },
                );

                const vacations = await Promise.all(
                    adminMembers.map((user) =>
                        axios.post(
                            apiURL.getAbsencesValidated,
                            {
                                id: user._id,
                            },
                            {
                                signal,
                            },
                        ),
                    ),
                );

                const costsData = await Promise.all(
                    adminMembers.map((user) =>
                        axios.post(
                            apiURL.getProfileCosts,
                            {
                                id: user._id,
                            },
                            {
                                signal,
                            },
                        ),
                    ),
                );

                setUpdatedTime(Date.now());
                setLoading(false);
                setDataList({
                    entries: data,
                    cost: costsData.map((e, i) => {
                        return {
                            user: adminMembers[i]._id,
                            timesheetCosts: e.data.timesheetCosts,
                        };
                    }),
                    vacations: vacations.map((e, i) => {
                        return {
                            user: adminMembers[i]._id,
                            vacations: e.data,
                        };
                    }),
                });
            } catch (error) {
                if (axios.isCancel(error)) {
                    setLoading(true);
                }
            }
        }, 300);
        return () => {
            clearTimeout(debounceData);
            controller.abort();
        };
    }, [monthsPagination, adminMembers, resfreshAction]);

    // Filter / Manage data
    const filteredData = useMemo(() => {
        let result = [...dataList.entries];
        result = applyMergeLinesForCounter(result);
        result = applyConfiguration(result, scheduleConfiguration);
        // INCASE Pour cacher les utilisateurs qui n'ont pas d'entrées
        // result = applyFiltersUsers(result, toolsData.filters.users);

        return (
            adminMembers
                .sort((a, b) => a.firstname.localeCompare(b.firstname) || a.lastname.localeCompare(b.lastname))
                // .filter((user) =>
                //     toolsData.filters.users?.length ? toolsData.filters.users.map((e) => e._id).includes(user._id) : true,
                // )
                .map((user) => {
                    const extistingUser = result.find((e) => e.userId === user._id);
                    const extistingCosts = dataList.cost.find((e) => e.user === user._id);
                    const extistingVacations = dataList.vacations.find((e) => e.user === user._id);

                    return extistingUser
                        ? {
                              authorId: extistingUser.userId,
                              user: extistingUser.userRender,
                              jt: extistingUser.HSstats.JT, //extistingUser.HSstats.JT,
                              jf: 0,
                              basket: extistingUser.HSstats.JT + " / " + extistingUser.HSstats.baskets,
                              ht: fromMsToHoursMinutes(extistingUser.HSstats.HT),
                              hsup: fromMsToHoursMinutes(extistingUser.HSstats.HS),
                              hsup25: fromMsToHoursMinutes(extistingUser.HSstats.HS_25),
                              hsup50: fromMsToHoursMinutes(extistingUser.HSstats.HS_50),
                              hsup125: fromMsToHoursMinutes(extistingUser.HSstats.HS_125),
                              hsup150: fromMsToHoursMinutes(extistingUser.HSstats.HS_150),
                              vacations:
                                  (absences
                                      ?.filter((e) => e.author === user._id)
                                      .reduce((acc, curr) => {
                                          if (
                                              moment(curr.endDate).isBefore(moment(monthsPagination).endOf("month")) &&
                                              moment(curr.startDate).isAfter(moment(monthsPagination).startOf("month"))
                                          ) {
                                              acc +=
                                                  getDaysBetweenTwoDates(
                                                      moment(curr.endDate).isAfter(
                                                          moment(monthsPagination).endOf("month"),
                                                      )
                                                          ? moment(monthsPagination).endOf("month")
                                                          : moment(curr.endDate),
                                                      moment(curr.startDate).isBefore(
                                                          moment(monthsPagination).startOf("month"),
                                                      )
                                                          ? moment(monthsPagination).startOf("month")
                                                          : moment(curr.startDate),
                                                  ) + 1;
                                          }
                                          return acc;
                                      }, 0) || 0) +
                                  (extistingVacations?.vacations?.reduce((acc, curr) => {
                                      if (
                                          moment(curr.endDate).isBefore(moment(monthsPagination).endOf("month")) &&
                                          moment(curr.startDate).isAfter(moment(monthsPagination).startOf("month"))
                                      ) {
                                          acc += getDaysBetweenTwoDates(moment(curr.endDate), moment(curr.startDate));
                                      }
                                      return acc;
                                  }, 0) || 0),
                              cost:
                                  extistingCosts.timesheetCosts?.find((e) => e.month === monthsPagination)?.cost || "",
                              other:
                                  extistingCosts.timesheetCosts?.find((e) => e.month === monthsPagination)?.other || "",
                          }
                        : {
                              authorId: user._id,
                              user: `${user.firstname} ${user.lastname}`,
                              jt: 0,
                              jf: 0,
                              basket: 0,
                              ht: 0,
                              hsup: 0,
                              hsup25: 0,
                              hsup50: 0,
                              hsup125: 0,
                              hsup150: 0,
                              vacations: 0,
                              cost: "",
                              other: "",
                          };
                })
        );
    }, [dataList, scheduleConfiguration, absences, toolsData.filters.users, adminMembers]);

    const columns = useMemo(
        () => [
            {
                title: capitalize(t("utilisateur")),
                dataIndex: "user",
                width: 150,
                sorter: (a, b) => a.user.localeCompare(b.user),
            },
            // {
            //     title: t("compteur"),
            //     dataIndex: "basket",
            //     width: 60,
            //     sorter: (a, b) => a.basket - b.basket,
            // },
            {
                title: t("paniers"),
                dataIndex: "basket",
                width: 60,
                sorter: (a, b) => a.basket - b.basket,
            },
            {
                title: "JT",
                width: 40,
                dataIndex: "jt",
                sorter: (a, b) => a.jt - b.jt,
            },
            {
                title: "JF",
                width: 40,
                dataIndex: "jf",
                sorter: (a, b) => a.jf - b.jf,
                render: () => {
                    const holidaysInMonth = scheduleConfiguration.holidays.filter((holiday) =>
                        moment(holiday.date).isSame(moment(monthsPagination), "month"),
                    );
                    return loading ? "--" : holidaysInMonth.length;
                },
            },

            {
                title: "HT",
                width: 40,
                dataIndex: "ht",
            },
            {
                title: "HS",
                width: 40,
                dataIndex: "hsup",
            },
            {
                title: "H 25%",
                width: 40,
                dataIndex: "hsup25",
            },
            {
                title: "H 50%",
                width: 40,
                dataIndex: "hsup50",
            },
            {
                title: "H 125%",
                width: 40,
                dataIndex: "hsup125",
            },
            {
                title: "H 150%",
                width: 40,
                dataIndex: "hsup150",
            },
            {
                title: t("vacances"),
                dataIndex: "vacations",
                width: 60,
            },
            {
                title: t("frais"),
                dataIndex: "cost",
                width: 200,
                render: (e, data) => <TextInput monthsPagination={monthsPagination} data={data} type="cost" />,
            },
            {
                title: t("autre"),
                dataIndex: "other",
                width: 200,
                render: (e, data) => <TextInput monthsPagination={monthsPagination} data={data} type="other" />,
            },
        ],
        [dataList, monthsPagination, scheduleConfiguration],
    );

    return (
        <Flex vertical gap={12}>
            <Flex align="center" gap={mdMedia ? 24 : 12} wrap>
                <Flex align="center">
                    <Button
                        onClick={() => changePagination(false)}
                        type="text"
                        icon={<MaterialIcons name={"chevron_left"} />}
                    />
                    <DatePicker
                        picker="month"
                        inputReadOnly
                        variant="borderless"
                        suffixIcon={false}
                        allowClear={false}
                        locale="fr"
                        styles={{
                            textAlign: "center",
                        }}
                        value={moment(monthsPagination)}
                        onChange={(date) => {
                            setMonthsPagination(date);
                        }}
                        format={(time) => capitalize(time.locale(i18n.locale).format("MMMM YYYY"))}
                    />
                    <Button
                        onClick={() => changePagination(true)}
                        type="text"
                        icon={<MaterialIcons name={"chevron_right"} />}
                    />
                </Flex>
                <Select
                    mode="multiple"
                    style={{ width: 400 }}
                    maxTagCount={2}
                    placeholder={t("rechercherParNomPrenomEmail")}
                    value={toolsData.filters.users.map((user) => user._id)}
                    onChange={(e) => {
                        setToolsData((tools) => ({
                            ...tools,
                            filters: {
                                ...tools.filters,
                                users: adminMembers.filter((user) => e.includes(user._id)),
                            },
                        }));
                    }}
                    filterOption={(input, option) => {
                        const user = option.props;
                        const inputLower = input.toLowerCase();
                        return (
                            user.firstname.toLowerCase().includes(inputLower) ||
                            user.lastname.toLowerCase().includes(inputLower) ||
                            user.email.toLowerCase().includes(inputLower)
                        );
                    }}
                    options={adminMembers.map((user, i) => ({
                        value: user._id,
                        label: user.firstname + " " + user.lastname,
                        props: user,
                    }))}
                />
                <Button
                    onClick={() => {
                        setResfreshAction(!resfreshAction);
                    }}
                    icon={<MaterialIcons size="medium" type="secondary" name="refresh" />}
                    type="text">
                    <Text type="secondary">{`${t("misAJourA")} ${fromMongoToStringHoursMinutes(updatedTime)}`}</Text>
                </Button>

                <ButtonExportHourCounter
                    dataSource={filteredData}
                    columns={columns}
                    customerConfig={customerConfig}
                    title={`Export ${t("compteurDheures")} ${moment().format("DD-MM-YYYY")}`}
                    subtitle={`${t("periode")} : ${capitalize(
                        moment(monthsPagination).locale(i18next.locale).format("MMMM YYYY"),
                    )}`}
                    // orientation="landscape"
                />
            </Flex>
            <Table
                size="small"
                pagination={false}
                bordered
                loading={loading}
                rowKey={(record) => record.authorId}
                scroll={{
                    x: 2000,
                    y: "60vh",
                }}
                dataSource={filteredData}
                columns={columns}
            />
        </Flex>
    );
};

const TextInput = ({ data, monthsPagination, type }) => {
    const [value, setValue] = useState(data[type]);
    const { t } = useTranslation();

    useEffect(() => {
        setValue(data[type]);
    }, [monthsPagination, data]);

    return (
        <Input.TextArea
            value={value}
            variant="borderless"
            onChange={(e) => setValue(e.target.value)}
            size="small"
            style={{
                fontSize: 12,
            }}
            onBlur={() => {
                axios.post(apiURL.setProfileTimesheetCosts, {
                    user: data.authorId,
                    month: monthsPagination,
                    [type]: value,
                });
            }}
            placeholder={t("saisieLibre")}
            autoSize={{
                minRows: 1,
                maxRows: 3,
            }}
        />
    );
};

function getWeekdaysInMonth(year, month) {
    let weekdays = [];
    let date = moment([year, month - 1]); // Moment uses zero-based months

    // Loop through the days of the month
    while (date.month() === month - 1) {
        // Check if it's not a Saturday (6) or Sunday (0)
        if (date.day() !== 0 && date.day() !== 6) {
            weekdays.push(moment(date)); // Push the day of the month (1, 2, 3, etc.)
        }
        date.add(1, "days"); // Move to the next day
    }

    return weekdays;
}

export default TabSummaryCouter;
