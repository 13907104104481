import axios from "axios";
import i18n from "i18next";

import apiURL from "utils/apiURL";

import { toastAlert } from "./alert";
import { getProjectparentsList } from "./project";

export async function getUserByEmail(email) {
    try {
        const res = await axios.get(apiURL.getUserByEmail + email);

        if (res.data.email) return res.data;
        else return null;
    } catch (err) {
        return null;
    }
}

export async function getUserByPasswordToken(token) {
    try {
        const res = await axios.get(apiURL.getUserByPasswordToken + token);

        if (res.data._id) return res.data;
        else return null;
    } catch (err) {
        return null;
    }
}

export async function getUserByActivationToken(token) {
    try {
        const res = await axios.get(apiURL.getUserByActivationToken + token);

        if (res.data.email) return res.data;
        else return null;
    } catch (err) {
        return null;
    }
}

export function checkUserPermission(user, codePermission) {
    if (
        user &&
        user.subscription.permissions &&
        user.subscription.permissions.some(
            (permission) => permission.code === codePermission,
        )
    )
        return true;
    else return false;
}

export function isOwnerMember(member) {
    if (member.role.code === "OWNER") return true;

    return false;
}

export async function getMemberRoles(project, user) {
    if (!project || !user) return [];
    if (project.userEmail === user.email)
        return {
            OWNER: "OWNER",
            MODERATOR: "MODERATOR",
            EDITOR: "EDITOR",
            READER: "READER",
        };

    const res = await axios.get(apiURL.getUserProjectRoles + project._id);
    if (res && res.data) return res.data;

    return [];
}

export async function toggleFavorite(setLoader, file, refreshAction) {
    setLoader(true);

    try {
        const resFavorite = await axios.put(apiURL.addFavorite, {
            id: file._id,
            type: "file",
        });

        if (resFavorite && resFavorite.data) {
            if (resFavorite.data) refreshAction();
            else toastAlert("error", i18n.t("uneErreurEstSurvenue"));
        } else toastAlert("error", i18n.t("uneErreurEstSurvenue"));

        setLoader(false);
    } catch (e) {
        setLoader(false);
    }
}

export async function getUserProjects(isOnlyShared = false, allTypes = false) {
    try {
        const res = await axios.post(apiURL.getUserProjects, {
            isOnlyShared,
            allTypes,
        });

        if (res && res.data) return res.data;

        return [];
    } catch (err) {
        return null;
    }
}

// Vérifie si un utilisateur possède un projet dans sa liste (ou un des parents du projet)
export async function userHasAccessToProject(profile, project) {
    if (!profile || !project) return false;

    if (
        !profile.projects.some((userProject) => userProject._id === project._id)
    ) {
        // L'utilisateur n'a pas ce dossier dans sa liste, on test de voir s'il possède un des parents
        const parents = await getProjectparentsList(project._id);

        if (parents.length > 0) {
            // Check si l'on nous a pas retiré l'accès d'un des dossiers parent
            if (
                project.restrictedUsers.some(
                    (restrictedUser) => restrictedUser === profile.user._id,
                )
            )
                return false;

            if (
                parents.some((parent) =>
                    parent.restrictedUsers.some(
                        (restrictedUser) => restrictedUser === profile.user._id,
                    ),
                )
            )
                return false;

            if (
                profile.projects.some((userProject) =>
                    parents.some((parent) => parent._id === userProject._id),
                )
            )
                return true;
        }

        return false;
    }

    return true;
}

export async function getCustomerConfig({ authorId = null, configId = null }) {
    const { data } = await axios.post(apiURL.getCustomerConfiguration, {
        authorId,
        configId,
    });

    return data;
}

// Pemret d'afficher un portail ouvrier à la place du Drive par défaut en se connectant
export function isTimesheetWorker(permissionsTools) {
    if (
        permissionsTools?.timesheetUser.canView &&
        !permissionsTools?.timesheetAdmin.canView
    )
        return true;

    return false;
}
