import axios from "axios";
import PropTypes from "prop-types";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";

import { toastAlert } from "helpers/alert";
import { sendEmail } from "helpers/email";
import { isEmail } from "helpers/fieldValidation";
import { getFileExtension, isIfcFile, isPcdFile } from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import { getProjectparentsList } from "helpers/project";
import { generateRandomToken } from "helpers/token";
import { getMemberRoles } from "helpers/user";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import SwitchButton from "components/input/SwitchButton";
import ClipboardCopy from "components/utils/ClipboardCopy";
import Spinner from "components/utils/Spinner";

import { ModalContext } from "layout/LayoutModal";

import ShareIFrame from "./ShareIFrame";
import ShareQRCode from "./ShareQRCode";
import SharingOptions from "./SharingOptions";

import {
    AutoComplete,
    Button,
    Checkbox,
    Flex,
    Form,
    Input,
    Tag,
    theme,
} from "antd";

const ShareProjectFile = ({
    auth: { isAuthenticated, user },
    files,
    filesIds,
    bcfId,
    refreshAction,
    isQRCode = false,
    isIFrame = false,
}) => {
    const { t } = useTranslation();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const { addModalData, removeModal } = useContext(ModalContext);

    const [form] = Form.useForm();

    const [loader, setLoader] = useState(false);

    const [isPublicEnabled, enablePublic] = useState(
        files[0].publicToken ? true : false,
    );
    const [publicToken, setPublicToken] = useState(files[0].publicToken);

    const [authorSubscription, setAuthorSubscription] = useState(null);
    const [members, setMembers] = useState(null);

    // Email datas
    const [search, setSearch] = useState("");
    const [searchOptions, setSearchOptions] = useState([]);
    const [emailsToSend, setEmailsToSend] = useState([]);

    const [userRoles, setUserRoles] = useState({});

    const [fileUrl, setFileUrl] = useState("");
    const [embedUrl, setEmbedUrl] = useState("");

    useEffect(() => {
        if (fileUrl === "") handleUrl();
    }, [filesIds]);

    useEffect(() => {
        handleUrl(isPublicEnabled && publicToken);
    }, [isPublicEnabled]);

    useEffect(() => {
        // Gestion des droits du user en fonction du role
        const getUserRoles = async () => {
            const resRoles = await getMemberRoles(files[0].project, user);
            setUserRoles(resRoles);
        };

        if (isAuthenticated) getUserRoles();

        const getMembers = async () => {
            if (!isAuthenticated) setMembers([]);
            else {
                const membersRes = await axios.get(
                    apiURL.getProjectMembers + files[0].project._id,
                );

                if (membersRes && membersRes.data) {
                    let updatedMembers = membersRes.data;

                    updatedMembers = updatedMembers.filter(
                        (member) => member.user,
                    );

                    // Check si le user peut accéder à ce dossier (s'il n'est pas restreint sur celui-ci ou un parent)
                    updatedMembers = updatedMembers.filter(
                        (member) =>
                            !files[0].project.restrictedUsers?.some(
                                (restrictedUser) =>
                                    restrictedUser.toString() ===
                                    member.user._id.toString(),
                            ),
                    );

                    if (files[0].project.parentProject) {
                        const parents = await getProjectparentsList(
                            files[0].project._id,
                        );
                        parents.forEach((parent) => {
                            updatedMembers = updatedMembers.filter(
                                (member) =>
                                    !parent.restrictedUsers?.some(
                                        (restrictedUser) =>
                                            restrictedUser.toString() ===
                                            member.user._id.toString(),
                                    ),
                            );
                        });
                    }

                    // On ne prend pas le user courant
                    updatedMembers = updatedMembers.filter(
                        (member) => member.user.email !== user.email,
                    );

                    setMembers(updatedMembers);

                    setSearchOptions(
                        updatedMembers
                            .filter((option) => option.user?._id)
                            .map((member) => {
                                return {
                                    key: member.user._id,
                                    value: member.user.email,
                                    label:
                                        member.user.firstname +
                                        " " +
                                        member.user.lastname +
                                        " (" +
                                        member.user.email +
                                        ")",
                                };
                            }),
                    );
                } else setMembers([]);
            }
        };
        if (!members) getMembers();
    }, []);

    useEffect(() => {
        const getAuthorSubscription = async () => {
            const parents = await getProjectparentsList(files[0].project._id);

            let resSubscription = user.subscription.code;

            if (parents.length > 0) {
                if (parents[0].author)
                    resSubscription = parents[0].author.subscription;
            } else if (files[0].project.author)
                resSubscription = files[0].project.author.subscription;

            setAuthorSubscription(resSubscription);
        };

        if (isAuthenticated && !authorSubscription) getAuthorSubscription();
    }, [files[0]]);

    const handleUrl = (token) => {
        let idToUse = token ? token : filesIds.join("-");
        setFileUrl(
            process.env.REACT_APP_BASE_URL +
                (isIfcFile(files[0]) || isPcdFile(files[0])
                    ? "viewer/"
                    : "document/") +
                idToUse +
                (bcfId ? "/" + bcfId : ""),
        );
        if (isIfcFile(files[0]) || isPcdFile(files[0])) {
            setEmbedUrl(
                process.env.REACT_APP_BASE_URL +
                    "viewer-embed/" +
                    idToUse +
                    (bcfId ? "/" + bcfId : ""),
            );
        }
    };

    const handlePublic = async () => {
        if (!isPublicEnabled) {
            await updatePublicToken(false);
        } else {
            addModalData({
                key: "disableFilePublicLink",
                title: t("confirmation"),
                width: 1000,
                content: (
                    <div>
                        <div>
                            {t(
                                "souhaitezVousDesactiverLeLienDePartagePublicPourCeFichier?",
                            )}
                        </div>
                        <br />
                        <div className="font-bold">
                            {t("ceLienNeSeraPlusUtilisableParLaSuite")}.
                        </div>
                    </div>
                ),
                handleConfirm: () => {
                    updatePublicToken();
                },
            });
        }
    };

    const updatePublicToken = async (isRemoved = true) => {
        setLoader(true);
        try {
            const updatedFile = await axios.post(apiURL.updateProjectFile, {
                id: files[0]._id,
                publicToken: isRemoved ? null : generateRandomToken(24),
            });

            if (updatedFile && updatedFile.data) {
                if (refreshAction) refreshAction();

                if (updatedFile.data.publicToken)
                    setPublicToken(updatedFile.data.publicToken);

                enablePublic(!isPublicEnabled);
            } else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    const onSelect = (data) => {
        setEmailsToSend([...emailsToSend, data]);
        setSearch("");
    };

    const onSubmit = (values) => {
        if (
            isAuthenticated &&
            emailsToSend.filter(
                (email) =>
                    !members.some((member) => member.user.email === email),
            ).length > 0
        ) {
            addModalData({
                key: "shareFileExternalWarning",
                title: t("attention"),
                width: 1000,
                content: (
                    <div>
                        <div>
                            {t(
                                "lesLiensPartagesSerontDisponiblesPubliquementHorsDeLaPlateformePourLesEmailsExternesRenseignes",
                            )}
                            .
                        </div>
                    </div>
                ),
                handleConfirm: () => {
                    handleSendEmail(values, true);
                },
            });
        } else handleSendEmail(values);
    };

    const handleSendEmail = async (values, hasExternalEmails = false) => {
        if (emailsToSend.length === 0) return;

        setLoader(true);

        try {
            const filesExtensionsIcons = [];

            files.forEach((file) => {
                let fileType =
                    file.compiledFiles.length > 0
                        ? "merged"
                        : getFileExtension(file.file).toLowerCase();

                let fileExtensionIcon =
                    process.env.REACT_APP_BASE_URL +
                    getFileExtensionIcon(file, "png");

                filesExtensionsIcons.push({
                    type: fileType,
                    image: fileExtensionIcon,
                });
            });

            const content = {
                emailsList: emailsToSend,
                sender: user
                    ? user.firstname + " " + user.lastname
                    : values.name,
                senderId: user && user._id,
                folderId: files[0].project._id,
                filesIds: files.map((file) => file._id),
                fileLink: fileUrl,
                filesTitles: files.map(
                    (file) =>
                        file.title +
                        "." +
                        (file.compiledFiles.length > 0
                            ? "ifc"
                            : getFileExtension(file.file)),
                ),
                filesExtensionsIcons,
                customMessage:
                    values?.message?.trim() !== "" ? values.message : null,
                hasExternalEmails,
            };

            await sendEmail(
                apiURL.emailSharedFile,
                user ? user.email : values.email,
                content,
            )
                .then((res) => {
                    if (res === true) {
                        toastAlert("success", t("votreFichierABienEtePartage"));
                    } else toastAlert("error", t("uneErreurEstSurvenue"));
                })
                .catch((err) => {
                    toastAlert("error", t("uneErreurEstSurvenue"));
                });

            setEmailsToSend([]);
            removeModal("shareFile");
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    const onCheck = (e) => {
        if (e.target.checked) {
            const newEmails = members
                .filter(
                    (member) =>
                        !emailsToSend.some(
                            (emailToSend) => emailToSend === member.user.email,
                        ),
                )
                .map((member) => member.user.email);

            setEmailsToSend([...emailsToSend, ...newEmails]);
        } else {
            setEmailsToSend((emailsToSend) =>
                emailsToSend.filter(
                    (emailToSend) =>
                        !members.some(
                            (member) => member.user.email === emailToSend,
                        ),
                ),
            );
        }
    };

    const handleValidationFailed = (errorInfo) => {
        console.error("Validation Failed:", errorInfo);
    };

    const { token } = theme.useToken();

    return fileUrl !== "" ? (
        <div>
            {isQRCode ? (
                <ShareQRCode
                    fileTitle={files[0].title}
                    publicToken={publicToken}
                    fileUrl={fileUrl}
                    handleUrl={handleUrl}
                    updatePublicToken={updatePublicToken}
                    userRoles={userRoles}
                    switchDisabled={filesIds.includes(publicToken)}
                />
            ) : isIFrame ? (
                <ShareIFrame file={files[0]} embedUrl={embedUrl} />
            ) : (
                <Fragment>
                    <Form form={form} onFinish={onSubmit} layout="vertical">
                        <AutoComplete
                            placeholder={t(
                                "ajoutezDesMembresDuDossierOuCommencezAEcrireUneAdresseEmail",
                            )}
                            prefix={
                                <MaterialIcons
                                    name="person"
                                    isOutlined={true}
                                />
                            }
                            className="w-full"
                            value={search}
                            onChange={(e) => setSearch(e)}
                            onSelect={onSelect}
                            options={searchOptions
                                .filter(
                                    (option) =>
                                        option.label
                                            .toLowerCase()
                                            .includes(search.toLowerCase()) &&
                                        !emailsToSend.includes(option.value),
                                )
                                .concat(
                                    isEmail(search) &&
                                        !members
                                            .filter((member) => member.user)
                                            .some(
                                                (member) =>
                                                    member.user.email ===
                                                    search,
                                            ) &&
                                        !emailsToSend.includes(search)
                                        ? {
                                              key: search,
                                              value: search,
                                              label: search,
                                          }
                                        : [],
                                )}
                        />

                        {isAuthenticated && (
                            <Checkbox
                                style={{ marginTop: token.marginMD }}
                                onChange={onCheck}
                            >
                                {t("ajouterTousLesMembresDuDossier")}
                            </Checkbox>
                        )}

                        {emailsToSend.length > 0 && (
                            <ul style={{ marginTop: token.marginSM }}>
                                {emailsToSend.map((email) => (
                                    <li
                                        style={{ display: "inline-block" }}
                                        key={email}
                                    >
                                        <Tag
                                            key={email}
                                            color={
                                                members.some(
                                                    (member) =>
                                                        member.user.email ===
                                                        email,
                                                )
                                                    ? "cyan"
                                                    : "red"
                                            }
                                            closable={true}
                                            onClose={(e) =>
                                                setEmailsToSend(
                                                    emailsToSend.filter(
                                                        (addedEmail) =>
                                                            addedEmail !==
                                                            email,
                                                    ),
                                                )
                                            }
                                        >
                                            {email}
                                        </Tag>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {!isAuthenticated && (
                            <Fragment>
                                <Form.Item
                                    name="email"
                                    label={t("votreAdresseEmail")}
                                    labelCol={{
                                        style: { fontWeight: "bold" },
                                    }}
                                    rules={[
                                        {
                                            type: "email",
                                            message: t(
                                                "veuillezRenseignerUnEmailValide",
                                            ),
                                        },
                                        {
                                            required: true,
                                            message: t("ceChampEstObligatoire"),
                                        },
                                        {
                                            max: constants.maxLengthEmail,
                                            message: t(
                                                "longueurMaximaleAtteinte",
                                            ),
                                        },
                                    ]}
                                    style={{ marginTop: token.marginMD }}
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    label={t("nom") + " / " + t("prenom")}
                                    labelCol={{
                                        style: { fontWeight: "bold" },
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("ceChampEstObligatoire"),
                                        },
                                        {
                                            max:
                                                constants.maxLengthFirstname +
                                                constants.maxLengthLastname,
                                            message: t(
                                                "longueurMaximaleAtteinte",
                                            ),
                                        },
                                    ]}
                                    validateTrigger={["onChange", "onBlur"]}
                                >
                                    <Input />
                                </Form.Item>
                            </Fragment>
                        )}

                        <Form.Item
                            name="message"
                            label={t("messageOptionnel")}
                            labelCol={{
                                style: { fontWeight: "bold" },
                            }}
                            rules={[
                                {
                                    max: constants.maxLengthMailMessage,
                                    message: t("longueurMaximaleAtteinte"),
                                },
                            ]}
                            style={{
                                marginTop: token.marginMD,
                            }}
                        >
                            <Input.TextArea
                                rows={4}
                                autoSize={{ minRows: 4, maxRows: 12 }}
                            />
                        </Form.Item>

                        <Flex
                            style={{
                                marginTop: token.marginSM,
                                marginBottom: token.marginSM,
                                justifyContent: "end",
                            }}
                            gap={8}
                        >
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={(e) => removeModal("shareFile")}
                            >
                                {t("annuler")}
                            </Button>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    variant="solid"
                                    disabled={emailsToSend.length === 0}
                                    htmlType="submit"
                                >
                                    {t("partager")}
                                </Button>
                            </Form.Item>
                        </Flex>
                    </Form>

                    <hr
                        style={{
                            marginTop: token.marginMD,
                            marginBottom: token.marginMD,
                        }}
                    />

                    <div className="font-bold text-black">
                        {t("accesGeneral")}
                    </div>

                    <div className="text-left">
                        <Flex vertical={!mdMedia}>
                            <div className="w-full">
                                {userRoles.MODERATOR && (
                                    <Flex
                                        style={{
                                            marginTop: token.marginMD,
                                        }}
                                    >
                                        <label
                                            className="text-lg"
                                            style={{
                                                marginRight: token.marginSM,
                                            }}
                                        >
                                            {t("activerLeLienDePartagePublic")}
                                        </label>
                                        <SwitchButton
                                            onChange={(e) => handlePublic()}
                                            value={isPublicEnabled}
                                        />
                                    </Flex>
                                )}
                                {isPublicEnabled ? (
                                    <div className="text-xs">
                                        {t(
                                            "toutLeMondePeutConsulterVotreFichier",
                                        )}
                                    </div>
                                ) : (
                                    <div className="text-xs">
                                        {t(
                                            "seulesLesPersonnesAvecAccesPeuventOuvrirLeFichier",
                                        )}
                                        .
                                    </div>
                                )}
                            </div>

                            <div
                                className="my-auto"
                                style={{
                                    marginTop: mdMedia ? 0 : token.marginMD,
                                }}
                            >
                                <ClipboardCopy text={fileUrl} isSmall={true} />
                            </div>
                        </Flex>

                        {members && isPublicEnabled && userRoles.MODERATOR && (
                            <SharingOptions
                                user={isAuthenticated && user}
                                file={files[0]}
                                refreshAction={refreshAction}
                                userRoles={userRoles}
                                removeModal={removeModal}
                            />
                        )}
                    </div>
                </Fragment>
            )}

            {loader && <Spinner />}
        </div>
    ) : null;
};

ShareProjectFile.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, null)(ShareProjectFile);
