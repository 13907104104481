import axios from "axios";
import { useAppStore } from "providers/ProviderApp";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { debounce, parseDataTransfert } from "helpers/input";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import DriveEmptyLayout from "components/drive/DriveEmptyLayout";
import SkeletonFileItem from "components/skeleton/SkeletonFileItem";

import { PopupContext } from "layout/LayoutPopup";

import FilesList from "./FilesList";
import OptionsProject from "./OptionsProject";
import ProjectBreadcrumb from "./ProjectBreadcrumb";

import { Button, Col, Empty, Flex, Result, Row, Spin, theme } from "antd";

const limitNbFiles = 48;

const ViewProjectV2 = ({
    urlProjectId,
    project,
    profile,
    selectedFiles,
    setSelectedFiles,
    refreshAction,
    spinnerContent,
    currentItem,
    userRoles,
    addSelectedFile,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const { onUpload, onItemClick } = useContext(DriveToolsContext);
    const { runNotification } = useContext(PopupContext);

    const { viewMode } = useAppStore();
    const isListMode = viewMode === "list";

    const [isSkeletonVisible, showSkeleton] = useState(false);

    const [files, setFiles] = useState(null);
    const [filesStep, setFilesStep] = useState(0);
    const [isFilesLimitReached, setFilesLimitReached] = useState(false);

    // DropZone
    const [canDrop, setcanDrop] = useState(true);
    const [dragOver, setDragOver] = useState(false);
    const [infoDrag, setInfoDrag] = useState(false);

    const [parentHeight, setParentHeight] = useState(0);

    // Get the parent container height to adapt the DropZone height even on scroll
    const updateDropZoneHeight = () => {
        setParentHeight(0);
        const parentElem =
            document.getElementsByClassName("body-container") &&
            document.getElementsByClassName("body-container")[0];
        if (parentElem) {
            setTimeout(() => {
                setParentHeight(parentElem.scrollHeight);
            }, 100);
        }
    };

    useEffect(() => {
        if (!files) return;
        updateDropZoneHeight();
        window.addEventListener("resize", updateDropZoneHeight);
        return () => {
            window.removeEventListener("resize", updateDropZoneHeight);
        };
    }, [files, project]);

    useEffect(() => {
        if (urlProjectId === project._id.toString()) setSelectedFiles([]);
    }, [project]);

    useEffect(() => {
        if (urlProjectId === project._id.toString()) {
            initFilesDatas();
            setInfoDrag(false);
            axios.put(apiURL.addRecentlyViewedFolder, {
                id: project._id,
            });
        }
    }, [project]);

    const initFilesDatas = async () => {
        setFilesStep(0);
        setFilesLimitReached(false);
        showSkeleton(true);

        try {
            const filesRes = await axios.post(
                apiURL.getProjectFiles + urlProjectId,
                {
                    start: 0,
                    filter: "title",
                },
            );
            if (filesRes && filesRes.data) {
                if (filesRes.data.length < limitNbFiles)
                    setFilesLimitReached(true);
                // Hide files part of compiled files
                setFiles(filesRes.data);
            } else setFiles([]);
        } catch (err) {
            setFiles([]);
        }

        showSkeleton(false);
    };

    useEffect(() => {
        const target = document.querySelector(
            "#layout-content > .ant-card-body",
        );
        if (target) {
            target.addEventListener("scroll", handleScroll);
            return () => {
                if (target) target.removeEventListener("scroll", handleScroll);
            };
        }
    }, [filesStep, isFilesLimitReached]);

    const handleScroll = async (e) => {
        if (
            parseInt(e.target.scrollHeight - e.target.scrollTop) ===
                e.target.clientHeight &&
            !isFilesLimitReached
        ) {
            const filesRes = await axios.post(
                apiURL.getProjectFiles + project._id,
                { start: filesStep + 1, filter: "title" },
            );

            if (filesRes && filesRes.data) {
                if (filesRes.data.length < limitNbFiles)
                    setFilesLimitReached(true);
                if (filesRes.data.length) {
                    setFiles((oldFiles) => [...oldFiles, ...filesRes.data]);
                    setFilesStep(filesStep + 1);
                }
            }
        }
    };

    const getDrop = (e) => {
        setDragOver(false);
        if (canDrop && userRoles.EDITOR) {
            e.preventDefault();
            const files = parseDataTransfert(e);
            // Wait for async folder processing
            setTimeout(() => {
                const filesInSameFolder = Array.from(files).filter(
                    (e) => !e.path.includes("/"),
                );
                let filesInChildFolder = Array.from(files).filter((e) =>
                    e.path.includes("/"),
                );
                if (!userRoles.MODERATOR && filesInChildFolder.length) {
                    filesInChildFolder = [];
                    runNotification({
                        title: t("vousNavezPasLesPermissionsNecessaires"),
                        content: (
                            <Text>
                                {t("votreRoleActuel")} {t("neVousPermetPas")}{" "}
                                {t("dImporterModifierSupprimerDes")}{" "}
                                <b>{t("dossiers")}</b>
                            </Text>
                        ),
                        type: "error",
                    });
                }

                if (filesInSameFolder.length)
                    onUpload(filesInSameFolder, false).then((res) => {
                        if (filesInChildFolder.length)
                            onUpload(filesInChildFolder, true);
                    });
                else if (filesInChildFolder.length)
                    onUpload(filesInChildFolder, true);
            }, 100);
        }
    };

    const errorDrag = debounce(() => {
        runNotification({
            title: t("vousNavezPasLesPermissionsNecessaires"),
            content: (
                <Text>
                    {t("votreRoleActuel")} {t("neVousPermetPas")}{" "}
                    {t("dImporterModifierSupprimerDes")}{" "}
                    <b>
                        {t("fichiers")}/{t("dossiers")}
                    </b>
                </Text>
            ),
            type: "error",
        });
    }, 200);

    const onDragOver = (e) => {
        if (canDrop) {
            if (!dragOver) setDragOver(true);

            if (e.target?.id === "dropZoneDrive")
                if (userRoles.EDITOR) {
                    setInfoDrag({
                        type: "success",
                        message: (
                            <Text>
                                {t(
                                    "deposerLesFichiersDossiersPourLesImporterDans",
                                )}
                                <br /> <b>{project.title}</b>
                            </Text>
                        ),
                    });
                } else {
                    errorDrag();
                }
        }
    };
    return (
        <Fragment>
            <Flex
                wrap
                align="center"
                gap={12}
                style={{
                    marginLeft: "auto",
                    position: "relative",
                    zIndex: 1,
                    display: "flex",
                }}
            >
                {!!project.tender && (
                    <OptionsProject
                        trigger="click"
                        project={project}
                        urlProjectId={urlProjectId}
                        profile={profile}
                        refreshAction={refreshAction}
                        showInfosInPanel={onItemClick}
                        userRoles={userRoles}
                        isFromBackground
                        placement="bottomLeft"
                    >
                        <Button
                            color="primary"
                            variant="text"
                            icon={
                                <MaterialIcons
                                    color={token.colorPrimary}
                                    name="package_2"
                                />
                            }
                        >
                            {t("boiteDeDepot")}
                        </Button>
                    </OptionsProject>
                )}
            </Flex>

            {files ? (
                <div
                    style={{
                        flex: 1,
                    }}
                    onDragStart={() => {
                        setcanDrop(false);
                    }}
                    onDragEnter={onDragOver}
                    onDragEnd={() => {
                        setcanDrop(true);
                    }}
                >
                    {parentHeight !== 0 && (
                        <OptionsProject
                            trigger="contextMenu"
                            project={project}
                            urlProjectId={urlProjectId}
                            profile={profile}
                            refreshAction={refreshAction}
                            showInfosInPanel={onItemClick}
                            userRoles={userRoles}
                            isFromBackground
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    height: parentHeight,
                                    left: 0,
                                    top: 0,
                                    zIndex: 0,
                                }}
                            ></div>
                        </OptionsProject>
                    )}

                    {dragOver && (
                        <Flex
                            id="dropZoneDrive"
                            onDrop={getDrop}
                            onDragLeave={(e, v) => {
                                setDragOver(false);
                            }}
                            style={{
                                position: "absolute",
                                width: "100%",
                                height: parentHeight,
                                backgroundColor: userRoles.EDITOR
                                    ? "#ffffff99"
                                    : "transparent",
                                left: 0,
                                top: 0,
                                zIndex: 5,
                                border: userRoles.EDITOR
                                    ? "4px dashed " + token.colorPrimaryBgHover
                                    : "none",

                                // borderRadius: token.borderRadiusLG,
                            }}
                            vertical
                            align="center"
                            justify="end"
                        >
                            <div></div>
                            {infoDrag && (
                                <Result
                                    style={{
                                        pointerEvents: "none",
                                        position: "sticky",
                                        bottom: 0,
                                    }}
                                    subTitle={infoDrag.message}
                                    icon={
                                        infoDrag.type === "error" ? (
                                            <MaterialIcons
                                                className="animate-bounce"
                                                name="report"
                                                type="error"
                                                size={"5vh"}
                                            />
                                        ) : (
                                            <MaterialIcons
                                                className="animate-bounce duration-100"
                                                name="cloud_upload"
                                                type="primary"
                                                size={"5vh"}
                                            />
                                        )
                                    }
                                >
                                    {!!infoDrag.data?.length && (
                                        <Flex vertical>
                                            {infoDrag.data.map((e, i) => (
                                                <Text>{e}</Text>
                                            ))}
                                        </Flex>
                                    )}
                                </Result>
                            )}
                        </Flex>
                    )}

                    {isSkeletonVisible &&
                        (project.files.length > 0 ||
                            project.projects?.length > 0) && (
                            <Row
                                className={isListMode ? "mt-16" : "mt-6"}
                                gutter={[24, 24]}
                                style={{
                                    padding: "24px 0",
                                }}
                            >
                                {[
                                    ...project.files.filter(
                                        (e) => !e.isOldVersion,
                                    ),
                                    ...project.projects,
                                ].map((_, i) => (
                                    <Col
                                        xxl={isListMode ? 24 : 6}
                                        xl={isListMode ? 24 : 8}
                                        lg={isListMode ? 24 : 12}
                                        md={isListMode ? 24 : 12}
                                        xs={24}
                                        key={i}
                                    >
                                        <SkeletonFileItem />
                                    </Col>
                                ))}
                            </Row>
                        )}

                    {!isSkeletonVisible && files.length > 0 && (
                        <FilesList
                            profile={profile}
                            files={files}
                            refreshAction={refreshAction}
                            addSelectedFile={addSelectedFile}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            isListMode={isListMode}
                            onItemClick={onItemClick}
                            currentItem={currentItem}
                            userRoles={userRoles}
                        />
                    )}

                    {!isSkeletonVisible && !isFilesLimitReached && (
                        <div className="w-full flex justify-center items-center py-4">
                            <Spin />
                        </div>
                    )}

                    {!isSkeletonVisible &&
                        files.length === 0 &&
                        (userRoles.EDITOR ? <DriveEmptyLayout /> : <Empty />)}
                </div>
            ) : (
                spinnerContent
            )}
        </Fragment>
    );
};

export const TitleViewProject = ({
    currentProject,
    urlProjectId,
    profile,
    refreshAction,
    showInfosInPanel,
    userRoles,
}) => {
    return (
        <>
            <div
                style={{
                    flex: 1,
                    display: "grid",
                }}
            >
                <ProjectBreadcrumb project={currentProject} />
            </div>
            {urlProjectId && profile && currentProject && (
                <OptionsProject
                    project={currentProject}
                    urlProjectId={urlProjectId}
                    profile={profile}
                    refreshAction={refreshAction}
                    showInfosInPanel={showInfosInPanel}
                    userRoles={userRoles}
                    isProjectGlobal={true}
                    placement="bottomRight"
                    isFromBackground
                    style={{
                        marginLeft: "auto",
                    }}
                >
                    <Button shape="circle" type="text">
                        <MaterialIcons name="more_vert" />
                    </Button>
                </OptionsProject>
            )}
        </>
    );
};

export default ViewProjectV2;
