import { Fragment } from "react";

import CardEntity from "./CardEntity";
import FilesDetailsTable from "./FilesDetailsTable";

import { Col, Row } from "antd";

const FilesList = ({
    profile,
    files,
    refreshAction,
    addSelectedFile,
    selectedFiles,
    setSelectedFiles,
    isListMode = false,
    onItemClick,
    currentItem,
    isReadOnly = false,
    userRoles,
    isDnd,
    hideVersions = false,
    showLocation = false,
    showLastOpenDate = false,
}) => {
    return (
        files.length > 0 && (
            <Fragment>
                {isListMode ? (
                    <FilesDetailsTable
                        items={files}
                        profile={profile}
                        refreshAction={refreshAction}
                        onItemClick={onItemClick}
                        currentItem={currentItem}
                        userRoles={userRoles}
                        isDnd={isDnd}
                        addSelectedFile={addSelectedFile}
                        selectedFiles={selectedFiles}
                        setSelectedFiles={setSelectedFiles}
                        showLocation={showLocation}
                        showLastOpenDate={showLastOpenDate}
                    />
                ) : (
                    <Row
                        gutter={[24, 24]}
                        style={{
                            padding: "24px 0",
                        }}
                    >
                        {files.map((item, i) => {
                            const file = item.title
                                ? item
                                : {
                                      ...item.file,
                                      viewedDate: item.viewedDate,
                                  };
                            const isProject = !!item.files;
                            return (
                                <Col
                                    xxl={6}
                                    xl={8}
                                    lg={12}
                                    md={12}
                                    xs={24}
                                    key={file._id}
                                    style={{
                                        pointerEvents: "none",
                                    }}
                                >
                                    <CardEntity
                                        isFolder={isProject}
                                        item={file}
                                        profile={profile}
                                        addSelectedFile={addSelectedFile}
                                        selectedFiles={selectedFiles}
                                        onItemClick={onItemClick}
                                        currentItem={currentItem}
                                        isReadOnly={isReadOnly}
                                        userRoles={userRoles}
                                        isDnd={isDnd}
                                        usePublicLink={
                                            item.isFromPublic ? true : false
                                        }
                                        hideVersions={hideVersions}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                )}
            </Fragment>
        )
    );
};

export default FilesList;
