import { load } from "@loaders.gl/core";
import { LASLoader } from "@loaders.gl/las";
import { Las } from "las-js";
import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader.js";
import { PCDLoader } from "three/examples/jsm/loaders/PCDLoader";

import { getFileType } from "./file";

export async function loadPointcloudFile(world, file, fileContent) {
    let model;

    switch (getFileType(file)) {
        case "PCD":
            model = loadPCD(fileContent, world.scene);
            break;
        case "XYZ":
            model = loadXYZ(fileContent, world.scene);
            break;
        case "LAS":
            model = await loadLAS(fileContent, world.scene);
            break;
        case "LAZ":
            model = await loadLAS(fileContent, world.scene);
            break;
        case "OBJ":
            model = loadOBJ(fileContent, world.scene);
            break;
        default:
            break;
    }

    return model;
}

function loadPCD(filePath, scene) {
    return new Promise((resolve, reject) => {
        const loader = new PCDLoader();

        loader.load(
            filePath,
            function (points) {
                scene.three.add(points);
                points.isPoints = true;
                resolve(points);
            },
            function (xhr) {},
            function (error) {
                reject(error);
            },
        );
    });
}

function loadXYZ(filePath, scene) {
    return new Promise((resolve, reject) => {
        fetch(filePath)
            .then((response) => response.text())
            .then((data) => {
                const lines = data
                    .split("\n")
                    .filter((line) => line.trim() !== "");
                const firstLine = lines[0].trim().split(/;/);

                if (firstLine.length >= 6) {
                    // Process as a file with XYZ and RGB
                    const positions = [];
                    const colors = [];

                    lines.forEach((line) => {
                        const parts = line.trim().split(/;/);
                        if (parts.length >= 6) {
                            const x = parseFloat(parts[0]);
                            const y = parseFloat(parts[1]);
                            const z = parseFloat(parts[2]);
                            /*const r = parseInt(parts[4]);
                            const g = parseInt(parts[5]);
                            const b = parseInt(parts[6]);*/

                            positions.push(x, y, z);
                            //colors.push(r / 255, g / 255, b / 255);
                            colors.push(0, 0, 0);
                        }
                    });

                    const geometry = new THREE.BufferGeometry();
                    geometry.setAttribute(
                        "position",
                        new THREE.Float32BufferAttribute(positions, 3),
                    );
                    geometry.setAttribute(
                        "color",
                        new THREE.Float32BufferAttribute(colors, 3),
                    );

                    const material = new THREE.PointsMaterial({
                        size: 0.05,
                        vertexColors: true,
                    });

                    const pointCloud = new THREE.Points(geometry, material);
                    scene.three.add(pointCloud);
                    resolve(pointCloud);
                } else {
                    // Process as a simple XYZ file
                    const points = [];

                    lines.forEach((line) => {
                        const parts = line.trim().split(/\s+/);
                        if (parts.length === 3) {
                            const x = parseFloat(parts[0]);
                            const y = parseFloat(parts[1]);
                            const z = parseFloat(parts[2]);
                            points.push(new THREE.Vector3(x, y, z));
                        }
                    });

                    const geometry = new THREE.BufferGeometry().setFromPoints(
                        points,
                    );
                    const material = new THREE.PointsMaterial({
                        size: 0.05,
                        color: 0x000000,
                    });

                    const pointCloud = new THREE.Points(geometry, material);
                    scene.three.add(pointCloud);
                    resolve(pointCloud);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

async function loadLAS(filePath, scene) {
    try {
        // Load the LAS file using loaders.gl
        const lasData = await load(filePath, LASLoader);

        const positions = [];
        if (lasData.attributes && lasData.attributes.POSITION) {
            const positionArray = lasData.attributes.POSITION.value;
            for (let i = 0; i < positionArray.length; i += 3) {
                positions.push(
                    positionArray[i],
                    positionArray[i + 1],
                    positionArray[i + 2],
                );
            }
        }

        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute(
            "position",
            new THREE.Float32BufferAttribute(positions, 3),
        );

        const material = new THREE.PointsMaterial({
            size: 0.05,
            color: 0x00000,
        });
        const pointCloud = new THREE.Points(geometry, material);
        scene.three.add(pointCloud);

        return pointCloud;
    } catch (error) {
        // Try to load 2.0 Las
        try {
            const parser = new Las(filePath);

            const lasData = await parser.data();

            const positions = [];

            lasData.forEach((pointData) => {
                const x = pointData[1];
                const y = pointData[2];
                const z = pointData[3];

                positions.push(x, y, z);
            });

            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute(
                "position",
                new THREE.Float32BufferAttribute(positions, 3),
            );

            const material = new THREE.PointsMaterial({
                size: 0.05,
                color: 0x00000,
            });
            const pointCloud = new THREE.Points(geometry, material);
            scene.three.add(pointCloud);

            return pointCloud;
        } catch (error) {}
    }
}

function loadOBJ(filePath, scene) {
    return new Promise((resolve, reject) => {
        const loader = new OBJLoader();

        loader.load(
            filePath,
            function (object) {
                scene.three.add(object);
                object.isPoints = true;
                resolve(object);
            },
            function (xhr) {},
            function (error) {
                reject(error);
            },
        );
    });
}
