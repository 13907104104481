import axios from "axios";
import moment from "moment";
import { useDriveStore } from "providers/ProviderDrive";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { fileUploadProcess } from "helpers/file";
import { capitalize } from "helpers/string";
import { getMyTimesheetProjects } from "helpers/timesheet";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import useHorizontalScroll from "components/utils/useHorizontalScroll";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";
import { DrawerContext } from "layout/LayoutDrawer";
import { PopupContext } from "layout/LayoutPopup";

import { Button, Flex, Image, Select, Upload } from "antd";

const FormNewPhoto = ({ filesTaken, setFilesTaken, userProfile, getCurrentProfile }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { runNotification } = useContext(PopupContext);
    const { setOpenDrawer } = useContext(DrawerContext);
    const { updateFileProgressIndicator } = useContext(BackgroundActionsContext);
    const { refreshAction } = useContext(DriveToolsContext);
    const galleryRef = useHorizontalScroll();
    const [selectedProject, setSelectedProject] = useState();

    const savePhoto = async () => {
        let filesToUpload = Array.from(filesTaken)
            ?.sort((a, b) => a.size - b.size)
            .map((file) => ({
                file,
                streamId: file.name + generateRandomToken(10),
            }));

        filesToUpload.forEach(({ file, streamId }) => {
            updateFileProgressIndicator({
                status: "calculating",
                percentage: 1,
                streamId,
                file,
            });
        });

        navigate("/drive/project/" + selectedProject);

        for (let fileToUpload of filesToUpload) {
            await fileUploadProcess({
                project: { _id: selectedProject },
                file: fileToUpload.file,
                readStream: (progress) => {
                    updateFileProgressIndicator({
                        ...progress,
                        streamId: fileToUpload.streamId,
                        file: fileToUpload.file,
                    });
                },
            });
            refreshAction(false, false, false, selectedProject);
        }

        // Update recently used project
        await axios.put(apiURL.addRecentlyUsedProject, {
            project: selectedProject,
        });
        await getCurrentProfile();

        setOpenDrawer(false);
    };

    return (
        <>
            <Flex gap="large">
                <SelectProject userProfile={userProfile} setSelectedProject={setSelectedProject} />
                <Button
                    onClick={savePhoto}
                    children={t("enregistrer")}
                    type="primary"
                    size="large"
                    disabled={!selectedProject}
                />
            </Flex>

            <Flex
                style={{
                    marginTop: 20,
                    position: "relative",
                    overflowX: "auto",
                    paddingBottom: 8,
                }}
                gap="small"
                ref={galleryRef}>
                <AddPhoto setFilesTaken={setFilesTaken} />
                <PreviewPictures files={filesTaken} setFilesTaken={setFilesTaken} />
            </Flex>
        </>
    );
};

const SelectProject = ({ userProfile, setSelectedProject }) => {
    const { t } = useTranslation();
    const [isSelectOpen, setisSelectOpen] = useState(false);

    const { userProjects } = useDriveStore();
    const { permissionsTools } = useProfileStore();

    const listProjects =
        permissionsTools.timesheetAdmin.canView || permissionsTools.timesheetUser.canView
            ? getMyTimesheetProjects()
            : userProjects;

    const recentsFolders = userProfile.recentlyUsed
        // ?.filter((e) => e.isPhoto)
        .slice(0, 3)
        .map((e) => ({
            label: e.title,
            value: e._id,
            key: "recents-" + e._id,
        }));

    const listFolders = listProjects?.map((e) => ({
        label: e.title,
        value: e._id,
        key: "folders-" + e._id,
        childrenLevel: e.childrenLevel,
    }));

    const options = useMemo(() => {
        let result = [];
        if (recentsFolders.length > 0)
            result.push({
                label: `${capitalize(t("dossiers"))} ${t("recents")}`,
                title: "recents",
                options: recentsFolders,
            });

        if (listFolders.length > 0)
            result.push({
                label: t("mesProjets"),
                title: "mesProjets",
                options: listFolders,
            });
        return result;
    }, [recentsFolders, listFolders]);

    return (
        <Select
            onMouseDown={(e) => {
                if (!isSelectOpen) e.preventDefault();
            }}
            showSearch={true}
            allowClear={false}
            autoFocus={false}
            popupMatchSelectWidth={false}
            dropdownStyle={{
                width: "90vw",
            }}
            style={{
                flex: 1,
                width: 0,
            }}
            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
            onDropdownVisibleChange={(e) => setisSelectOpen(e)}
            size="large"
            options={options}
            loading={!listProjects}
            optionRender={(node) => (
                <Text
                    style={{
                        paddingLeft: node?.data?.childrenLevel ? node.data.childrenLevel * 10 : 0,
                    }}>
                    {node.label}
                </Text>
            )}
            onChange={(value) => {
                setSelectedProject(value);
            }}
        />
    );
};

const AddPhoto = ({ setFilesTaken }) => {
    const { t } = useTranslation();
    return (
        <Upload
            customRequest={({ file }) => {
                const titleNewFile = moment().format("DD/MM/YYYY-HH:mm:ss") + "." + file.name.split(".").pop();
                let newFile = new File([file], titleNewFile, {
                    type: file.type,
                });
                setFilesTaken((prev) => [...prev, newFile]);
            }}
            showUploadList={false}
            accept="image/*">
            <Button
                type="dashed"
                color="primary"
                variant="dashed"
                style={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    width: 100,
                    height: 100,
                    borderWidth: 2,
                }}>
                <MaterialIcons name="add" type="primary" />
                <Text bold type="primary">
                    {t("ajouter")}
                </Text>
            </Button>
        </Upload>
    );
};

const PreviewPictures = ({ files, setFilesTaken }) => {
    const [indexPreview, setIndexPreview] = useState(0);
    const { t } = useTranslation();

    return (
        <Flex gap="small">
            <Image.PreviewGroup
                preview={{
                    toolbarRender: (
                        _,
                        {
                            transform: { scale },
                            actions: {
                                onActive,
                                onFlipY,
                                onFlipX,
                                onRotateLeft,
                                onRotateRight,
                                onZoomOut,
                                onZoomIn,
                                onReset,
                                onClose,
                            },
                        },
                    ) => (
                        <Flex gap="large">
                            <Button
                                children={t("telecharger")}
                                variant="solid"
                                color="primary"
                                icon={<MaterialIcons name="download" size="xs" />}
                                onClick={() => {
                                    const link = document.createElement("a");
                                    link.href = URL.createObjectURL(files[indexPreview]);
                                    link.download = files[indexPreview].name;
                                    link.click();
                                    link.remove();
                                }}
                            />

                            <Button
                                children={t("supprimer")}
                                variant="solid"
                                color="danger"
                                icon={<MaterialIcons name="delete" size="xs" />}
                                onClick={() => {
                                    const newFiles = files.filter((_, index) => index !== indexPreview);
                                    setFilesTaken(newFiles);
                                    onClose();
                                }}
                            />
                        </Flex>
                    ),
                    onChange: (index) => {
                        setIndexPreview(index);
                    },
                }}>
                {files
                    ?.sort((a, b) => {
                        return b.lastModified - a.lastModified;
                    })
                    .map((file, index) => (
                        <Image
                            key={file.lastModified}
                            wrapperStyle={{
                                width: 100,
                                height: 100,
                            }}
                            style={{
                                width: 100,
                                height: 100,
                                objectFit: "cover",
                            }}
                            src={URL.createObjectURL(file)}
                            onClick={() => {
                                setIndexPreview(index);
                            }}
                        />
                    ))}
            </Image.PreviewGroup>
        </Flex>
    );
};

export default FormNewPhoto;
