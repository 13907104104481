import i18n from "i18next";
import moment from "moment";

// Permet de déduire si un fichier respecte déjà la nomenclature dans son titre, et ainsi de préremplir ou non les champs du formulaire
export function isValidFileForNomenclature(
    nomenclatureData,
    fileName,
    folderTitle,
    currentGED,
) {
    let nameParts = fileName.split("_").map((part) => part.trim());

    let errorMsg = "";
    const errorMsgBase =
        i18n.t("leFichierNeRespectePasLaRegleDeNomenclature") + ' : "';

    nomenclatureData.actions.forEach((action, i) => {
        if (errorMsg !== "") return;

        const ruleName =
            action.description &&
            isConstraintWithDescription(action.constraint.code)
                ? action.description
                : action.constraint["title" + i18n.locale];

        if (!nameParts[i]) {
            errorMsg = errorMsgBase + ruleName + '"';
            return errorMsg;
        }

        if (nomenclatureData.allowAutomaticDetection) {
            if (
                nameParts.length > 1 &&
                nameParts.length < nomenclatureData.actions.length
            ) {
                errorMsg = errorMsgBase + ruleName + '"';
                return;
            } else if (nameParts.length > nomenclatureData.actions.length) {
                errorMsg = i18n.t(
                    "leFichierPossedePlusDeQueLeNombreDeChampsDeLaNomenclature",
                );
                return;
            }
        }
        switch (action.constraint.code) {
            case "FILENAME_DATE":
                if (
                    !moment(
                        nameParts[i],
                        action.nomenclature || "YYYYMMDD",
                        true,
                    ).isValid()
                )
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_DATE_SELECT":
                if (
                    !moment(
                        nameParts[i],
                        action.nomenclature || "YYYYMMDD",
                        true,
                    ).isValid()
                )
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_INDEX_ALPHA":
                const regexAlpha = /^(0|[A-Z]+)$/;
                if (!regexAlpha.test(nameParts[i]))
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_INDEX_NUMBER":
                const regexNumeric = /^\d{0,4}$/;
                if (!regexNumeric.test(nameParts[i]))
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_LIST":
                if (
                    !action.nomenclature.some((value) => value === nameParts[i])
                )
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_FOLDER":
                checkIfFullWordInNameParts(i, nameParts, folderTitle);
                if (nameParts[i] !== folderTitle)
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_FILE":
                checkIfFullWordInNameParts(i, nameParts, fileName);
                if (nameParts[i] === "")
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_GED":
                if (
                    nameParts[i] !==
                    (currentGED === "-"
                        ? currentGED
                        : currentGED.toString().padStart(6, "0"))
                )
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_SET":
                if (nameParts[i] === "")
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            case "FILENAME_CUSTOM":
                checkIfFullWordInNameParts(i, nameParts, action.nomenclature);
                if (
                    nameParts[i].normalize() !== action.nomenclature.normalize()
                )
                    errorMsg = errorMsgBase + ruleName + '"';
                break;
            default:
                break;
        }
    });

    return errorMsg;
}

export function isConstraintWithDescription(code) {
    if (
        code === "FILENAME_LIST" ||
        code === "FILENAME_SET" ||
        code === "FILENAME_CUSTOM"
    )
        return true;

    return false;
}

// Hack
export const checkIfFullWordInNameParts = (initialIndex, nameParts, word) => {
    if (!nameParts) return false;

    const parts = [...nameParts];

    if (parts[initialIndex] === word) return true;

    for (let i = 1; i <= parts.length; i++) {
        const forwardConcat = parts
            .slice(initialIndex, initialIndex + i)
            .join("_");
        const backwardConcat = parts
            .slice(initialIndex - i + 1, initialIndex + 1)
            .join("_");

        if (forwardConcat === word) {
            return true;
        }
        if (backwardConcat === word) {
            return true;
        }
    }

    return false;
};
