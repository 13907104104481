import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Form, Input } from "antd";

export function SubtitleAnswer({ title, index }) {
    return (
        <Form.Item name={["fields", index, "value"]}>
            <Text bold size="md">
                {title}
            </Text>
        </Form.Item>
    );
}
export function SubtitleTemplate({ index }) {
    const { t } = useTranslation();

    return (
        <Form.Item label="Texte fixe :" name={[index, "title"]}>
            <Input
                style={{
                    fontWeight: "bold",
                }}
            />
        </Form.Item>
    );
}

export function SubtitlePrint({ field }) {
    return (
        <TextPrint
            style={{
                fontSize: 8,
                fontWeight: "bold",
            }}>
            {field.title}
        </TextPrint>
    );
}
