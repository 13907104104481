import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import { DriveToolsContext } from "components/drive/Drive";
import { useTimesheetStore } from "components/timesheet/TimesheetStore";
import OptionMenu from "components/utils/OptionMenu";

import { Select } from "antd";

const ActionGroup = () => {
    const { t } = useTranslation();
    const { groupBy, setStoreGroups } = useTimesheetStore();

    return (
        <Select
            style={{
                width: 250,
            }}
            maxTagCount="responsive"
            mode="multiple"
            placeholder={"+ " + t("ajouterUnRegroupement")}
            showSearch={false}
            suffixIcon={null}
            variant={groupBy.length > 0 ? "outlined" : "borderless"}
            value={groupBy}
            onChange={(values) => {
                setStoreGroups(values);
            }}
            options={[
                {
                    value: "user",
                    label: capitalize(t("utilisateurs")),
                },
                {
                    value: "project",
                    label: capitalize(t("dossiers")),
                },
                {
                    value: "task",
                    label: capitalize(t("taches")),
                },
            ]}
        />
    );
};

export default ActionGroup;
