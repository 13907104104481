import { fromDateToStringDate } from "helpers/date";
import { getFileExtension } from "helpers/file";

import Text from "components/common/Text";

import FileImage, { ExtensionImageContent } from "./FileImage";

import { Card, Flex } from "antd";

export default function CardEntityLight({ item }) {
    return (
        <Card
            size="small"
            hoverable
            bordered
            style={{
                position: "relative",
                cursor: "pointer",
                pointerEvents: "none",
                backgroundColor: "initial",
            }}
            // className={
            //     "cursor-pointer " +
            //     ((isOver && canDrop) || currentItem?._id === item._id
            //         ? "card-selected"
            //         : "")
            // }
            cover={
                <FileImage
                    // hasCurrent={!!currentItem}
                    file={item}
                    // user={profile && profile.user}
                />
            }
        >
            <Flex gap="small" align="center">
                <ExtensionImageContent file={item} width={30} />
                <Flex vertical style={{ flex: 1, display: "grid" }}>
                    <Text
                        strong
                        ellipsis
                        size="small"
                        children={
                            item.title +
                            (item.file
                                ? "." + getFileExtension(item.file)
                                : item.compiledFiles?.length > 0
                                  ? ".ifc"
                                  : "")
                        }
                        title={
                            item.title +
                            (item.file
                                ? "." + getFileExtension(item.file)
                                : item.compiledFiles?.length > 0
                                  ? ".ifc"
                                  : "")
                        }
                    />
                    <Text
                        type="secondary"
                        ellipsis
                        size="small"
                        children={fromDateToStringDate(item.creationDate)}
                    />
                </Flex>
            </Flex>
        </Card>
    );
}
