import { utils, writeFile } from "xlsx";

import { useTranslation } from "react-i18next";

import { getFileExtensionIcon } from "helpers/image";

import { Avatar, Table } from "antd";

export const TabExportToXLSX = (props) => {
    const { t } = useTranslation();

    return {
        key: "xlsx",
        label: `${t("format")} XLSX`,
        icon: (
            <Avatar
                shape="square"
                size={32}
                src={getFileExtensionIcon({
                    name: "file.xlsx",
                })}
            />
        ),
        style: { height: "50vh" },
        children: (
            <PreviewExportToXLSX
                {...props}
                dataSource={props.dataSource.map((e, index) => ({
                    ...e,
                    index,
                }))}
            />
        ),
    };
};

export const functionExportToXLSX = async ({ title, dataSource, columns }) => {
    const dataArray = [
        columns.map((e) => e.title),
        ...dataSource.map((line) => columns.map((k) => line[k.dataIndex])),
    ];
    const worksheet = utils.aoa_to_sheet(dataArray);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    writeFile(workbook, title + ".xlsx");
    return true;
};

export const PreviewExportToXLSX = ({ dataSource, columns }) => (
    <Table
        scroll={{
            x: "2500px",
            y: "50vh",
        }}
        bordered
        size="small"
        rowKey={(record) => record.index}
        style={{ width: "100%" }}
        dataSource={dataSource}
        columns={columns.map((e) => ({
            title: e.title,
            dataIndex: e.dataIndex,
            ellipsis: true,
        }))}
        pagination={false}
    />
);
