import { useMemo } from "react";

import { theme } from "antd";

const MaterialIcons = ({
    name = "close",
    size = "",
    type = "",
    color = "",
    spin = false,
    keepOld = false,
    isOutlined = false,
    className = "",
    spaced = false,
    style = {},
    ...rest
}) => {
    const { token } = theme.useToken();
    const getSize = useMemo(() => {
        if (size === "xs") return token.fontSizeHeading5;
        if (size === "sm") return token.fontSizeHeading4;
        if (size === "md") return token.fontSizeHeading3;
        if (size === "lg") return token.fontSizeHeading2;
        if (size === "xl") return token.fontSizeHeading1;
        if (!!size) return size;
        return "";
    }, [size, token]);

    const getColor = useMemo(() => {
        if (type === "success") return token.colorSuccess;
        if (type === "secondary") return token.colorTextSecondary;
        if (type === "primary") return token.colorPrimary;
        if (type === "white") return token.colorBgBase;
        if (type === "error" || type === "danger") return token.colorError;
        if (type === "warning") return token.colorWarning;
        if (type === "info") return token.colorInfo;
        if (type === "disabled") return token.colorTextDisabled;
        if (!!type) return type;
        return "";
    }, [type, token]);

    return (
        <span
            style={{
                color: color || getColor,
                fontSize: getSize,
                verticalAlign: "middle",
                marginRight: spaced ? token.paddingXS : "",
                ...style,
            }}
            className={
                className +
                " notranslate " +
                (spin ? "animate-spin " : "") +
                (keepOld ? "material-icons " : " material-symbols-outlined ") +
                (isOutlined ? "" : "fill ")
            }
            {...rest}
        >
            {name}
        </span>
    );
};

export default MaterialIcons;
