import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { downloadQRCode } from "helpers/image";

import DropDownButton from "components/input/DropDownButton";

import { QRCode } from "antd";

const QRCodeGenerator = ({ url, id, name }) => {
    const { t } = useTranslation();

    const handleJPEG = () => {
        downloadQRCode(id, name, "JPEG");
    };

    const handlePNG = () => {
        downloadQRCode(id, name, "PNG");
    };

    const handleSVG = () => {
        downloadQRCode(id, name, "SVG");
    };

    const dropDownContent = [
        {
            text: "JPEG",
            action: handleJPEG,
        },
        {
            text: "PNG",
            action: handlePNG,
            param: "qrcode",
        },
        {
            text: "SVG",
            action: handleSVG,
            param: "iframe",
        },
    ];

    return (
        <Fragment>
            <QRCode
                value={url}
                size={200}
                bgColor="white"
                id={id}
                className="m-auto"
                icon="/img/logo-black.svg"
            />
            {id && name && (
                <div className="mt-4">
                    <DropDownButton
                        text={t("telecharger")}
                        items={dropDownContent}
                        orientation="top"
                    />
                </div>
            )}
        </Fragment>
    );
};

export default QRCodeGenerator;
