import axios from "axios";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fieldValidation } from "helpers/fieldValidation";
import { isIfcFile, isPcdFile } from "helpers/file";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import CommentTextarea from "components/comment/CommentTextarea";
import { DriveToolsContext } from "components/drive/Drive";
import Button from "components/input/Button";
import Spinner from "components/utils/Spinner";
import useHorizontalScroll from "components/utils/useHorizontalScroll";

import VisaBadge from "./VisaBadge";

const VisasInlineList = ({ user, file }) => {
    const { t } = useTranslation();

    const scrollRef = useHorizontalScroll();

    const [loader, setLoader] = useState(false);
    const [visas, setVisas] = useState([]);
    const [visaDatasToSave, setVisaDatasToSave] = useState(null);

    const [members, setMembers] = useState([]);

    useEffect(() => {
        setVisaDatasToSave(null);

        setVisas(file.visas.filter((item) => item.visa));
    }, [file]);

    useEffect(() => {
        if (visaDatasToSave?.status) {
            const getMembers = async () => {
                const resMembers = await axios.get(
                    apiURL.getProjectMembers + file.project._id,
                );

                if (resMembers && resMembers.data) setMembers(resMembers.data);
                else setMembers([]);
            };

            if (members.length === 0) getMembers();
        }
    }, [visaDatasToSave?.status]);

    return (
        visas && (
            <Fragment>
                <ul className="w-full gap-2" ref={scrollRef}>
                    {visas
                        .sort((a, b) =>
                            a.visa.title.toLowerCase() >
                            b.visa.title.toLowerCase()
                                ? 1
                                : -1,
                        )
                        .map((item) => (
                            <li
                                key={item._id}
                                className="inline-block whitespace-nowrap m-1"
                            >
                                <VisaBadge
                                    visa={item}
                                    user={user}
                                    members={members}
                                    file={file}
                                    visaDatasToSave={
                                        visaDatasToSave &&
                                        visaDatasToSave.visa?._id ===
                                            item._id &&
                                        visaDatasToSave
                                    }
                                    setVisaDatasToSave={setVisaDatasToSave}
                                />
                            </li>
                        ))}
                </ul>

                {loader && <Spinner />}
            </Fragment>
        )
    );
};

export default VisasInlineList;
