import moment from "moment";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import { DriveToolsContext } from "components/drive/Drive";
import Select from "components/input/Select";

const FilterByDateRange = () => {
    const { t } = useTranslation();

    const { toolsData, setToolsData } = useContext(DriveToolsContext);

    const [selectedPagination, setSelectedPagination] = useState(
        toolsData.filters.dateRange,
    );

    const options = [
        {
            val: "byDay",
            label: capitalize(t("aujourdhui")),
            action: () => {
                setToolsData((e) => ({
                    ...e,
                    pagination: moment().format("YYYY-MM-DD"),
                    filters: { ...e.filters, dateRange: "byDay" },
                }));
            },
        },
        {
            val: "byWeek",
            label: t("cetteSemaine"),
            action: () => {
                setToolsData((e) => ({
                    ...e,
                    pagination: moment().format("YYYY-MM-DD"),
                    filters: { ...e.filters, dateRange: "byWeek" },
                }));
            },
        },
        {
            val: "byMonth",
            label: t("ceMoisCi"),
            action: () => {
                setToolsData((e) => ({
                    ...e,
                    pagination: moment().format("YYYY-MM-DD"),
                    filters: { ...e.filters, dateRange: "byMonth" },
                }));
            },
        },
        {
            val: "byWeek-before",
            label: t("laSemaineDerniere"),
            action: () => {
                setToolsData((e) => ({
                    ...e,
                    pagination: moment()
                        .subtract(1, "week")
                        .format("YYYY-MM-DD"),
                    filters: { ...e.filters, dateRange: "byWeek" },
                }));
            },
        },
        {
            val: "byMonth-before",
            label: t("leMoisDernier"),
            action: () => {
                setToolsData((e) => ({
                    ...e,
                    pagination: moment()
                        .subtract(1, "month")
                        .format("YYYY-MM-DD"),
                    filters: { ...e.filters, dateRange: "byMonth" },
                }));
            },
        },
    ];

    const executeFIlter = (event) => {
        const selectedFilter = options.find(
            (e) => e.val === event.target.value,
        );
        setSelectedPagination(event.target.value);
        selectedFilter.action();
    };

    useEffect(() => {
        const actualPagination = moment(toolsData.pagination).format(
            "YYYY-MM-DD",
        );
        if (toolsData.filters.dateRange === "byDay") {
            if (actualPagination === moment().format("YYYY-MM-DD")) {
                setSelectedPagination("byDay");
            } else {
                setSelectedPagination("");
            }
        } else if (toolsData.filters.dateRange === "byWeek") {
            if (
                actualPagination ===
                moment().subtract(1, "week").format("YYYY-MM-DD")
            ) {
                setSelectedPagination("byWeek-before");
            } else if (actualPagination === moment().format("YYYY-MM-DD")) {
                setSelectedPagination("byWeek");
            } else {
                setSelectedPagination("");
            }
        } else if (toolsData.filters.dateRange === "byMonth") {
            if (
                actualPagination ===
                moment().subtract(1, "month").format("YYYY-MM-DD")
            ) {
                setSelectedPagination("byMonth-before");
            } else if (actualPagination === moment().format("YYYY-MM-DD")) {
                setSelectedPagination("byMonth");
            } else {
                setSelectedPagination("");
            }
        }
    }, [toolsData.pagination, toolsData.filtersOpen]);

    return (
        <div>
            <Select
                options={options}
                placeholder={t("selonPagination")}
                name="filterDateRange"
                isRequired={false}
                value={selectedPagination}
                onChange={executeFIlter}
            />
        </div>
    );
};

export default FilterByDateRange;
