import { Fragment, useRef } from "react";
import { useTranslation } from "react-i18next";

import { getElementScreenXPositionInParent } from "helpers/window";

const VisaTableColFilters = ({
    requestSort,
    tableRef,
    colsFilters,
    col,
    handleColsFilters,
    sortConfig,
    setSortConfig,
}) => {
    const { t } = useTranslation();

    const buttonRef = useRef(null);

    const getPositionRelativeToParent = () => {
        const buttonXPosition = getElementScreenXPositionInParent(
            buttonRef.current,
            tableRef.current,
        );

        return buttonXPosition;
    };

    return (
        <Fragment>
            <button
                type="button"
                ref={buttonRef}
                className="flex flex-row"
                onClick={(e) => handleColsFilters(col.id)}
            >
                <div>{col.title}</div>
                <i
                    className="material-icons notranslate inline ml-1"
                    style={{ fontSize: "1.3rem" }}
                >
                    expand_more
                </i>
            </button>

            {colsFilters === col.id && (
                <ul
                    className="absolute z-[2] card text-xs font-light filters-list"
                    style={{
                        left:
                            getPositionRelativeToParent() === "left"
                                ? 0
                                : "auto",
                        right:
                            getPositionRelativeToParent() === "left"
                                ? "auto"
                                : `calc(100% - ${
                                      buttonRef.current.clientWidth + 8
                                  }px)`,
                    }}
                >
                    <li>
                        <button
                            type="button"
                            onClick={(e) => setSortConfig(null)}
                            className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left"
                        >
                            {t("aucunTri")}
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            onClick={(e) =>
                                requestSort("dueDate", "descending")
                            }
                            className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left flex flex-row"
                        >
                            <div className="my-auto w-full">
                                {t("dateDecheanceCroissante")}
                            </div>
                            {sortConfig &&
                                sortConfig.id === col.id &&
                                sortConfig.direction === "descending" && (
                                    <i
                                        className="material-icons notranslate ml-1 text-primary"
                                        style={{ fontSize: "1.1rem" }}
                                    >
                                        check
                                    </i>
                                )}
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            onClick={(e) => requestSort("dueDate", "ascending")}
                            className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left flex flex-row"
                        >
                            <div className="my-auto w-full">
                                {t("dateDecheanceDecroissante")}
                            </div>
                            {sortConfig &&
                                sortConfig.id === col.id &&
                                sortConfig.direction === "ascending" && (
                                    <i
                                        className="material-icons notranslate ml-1 text-primary"
                                        style={{ fontSize: "1.1rem" }}
                                    >
                                        check
                                    </i>
                                )}
                        </button>
                    </li>
                </ul>
            )}
        </Fragment>
    );
};

export default VisaTableColFilters;
