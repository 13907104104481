import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const AutoCompleteTextField = ({
    text,
    placeholder = "",
    name,
    defaultValue,
    availableValues,
    setAvailableValues,
    onClickChoice,
    onChange,
    onClickInput,
    maxLength,
    isDisabled = false,
    isRequired = false,
    isMultiple = false,
    clearOnClick = false,
}) => {
    const { t } = useTranslation();

    const inputRef = useRef(null);
    const [value, setValue] = useState(defaultValue ? defaultValue : "");

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setValue(defaultValue ? defaultValue : "");
    }, [defaultValue]);

    const handleClickOutside = (e) => {
        if (inputRef.current) {
            if (!inputRef.current.contains(e.target)) {
                setTimeout(() => {
                    setAvailableValues(null);
                });
            }
        }
    };

    return (
        <div className="relative">
            {text && (
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor={name}
                >
                    {text}
                </label>
            )}

            <div className="relative">
                <input
                    disabled={isDisabled}
                    ref={inputRef}
                    placeholder={placeholder}
                    className="shadow border rounded-lg w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline pr-[30px] text-sm"
                    id={name}
                    name={name}
                    value={value}
                    maxLength={maxLength}
                    type="text"
                    onChange={(e) => {
                        onChange(e);
                        setValue(e.target.value);
                    }}
                    onClick={() =>
                        isDisabled ? null : onClickInput && onClickInput()
                    }
                    required={isRequired}
                    autoComplete="off"
                />

                {value.length > 0 && !isMultiple && (
                    <button
                        type="button"
                        className="absolute top-[calc(50%-13px)] right-0 p-1"
                        onClick={(e) => {
                            setValue("");
                            onClickChoice(null);
                            setAvailableValues(null);
                        }}
                    >
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "1.2rem" }}
                        >
                            close
                        </i>
                    </button>
                )}
            </div>
            {availableValues && availableValues.length > 0 ? (
                <ul className="absolute z-[10] w-full text-xs text-left card max-h-[200px] overflow-auto font-light">
                    {availableValues.map((row) => {
                        return (
                            <li
                                key={row.id}
                                className="px-2 py-3 sm:py-2 hover:cursor-pointer hover:bg-slate-100"
                                onClick={(e) => {
                                    setValue(clearOnClick ? "" : row.text);
                                    onClickChoice(row.id);
                                    setAvailableValues(null);
                                }}
                            >
                                {row.text}
                            </li>
                        );
                    })}
                </ul>
            ) : (
                availableValues &&
                availableValues.length === 0 &&
                value.length > 1 && (
                    <div className="absolute z-[10] w-full text-sm card max-h-[200px] overflow-auto font-light p-2">
                        {t("aucunResultat")}
                    </div>
                )
            )}
        </div>
    );
};

export default AutoCompleteTextField;
