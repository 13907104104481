import axios from "axios";

import { useEffect, useState } from "react";

import apiURL from "utils/apiURL";

import FormCustomer from "./FormCustomer";
import FormInfos from "./FormInfos";
import FormMembers from "./FormMembers";
import FormTasks from "./FormTasks";

import { Spin } from "antd";

const ModalProject = () => {
    const [loading, setLoading] = useState(true);

    const [clientsList, setClientList] = useState();
    const [optionsTasks, setOptionsTasks] = useState();
    const [projectMembers, setProjectMembers] = useState();

    useEffect(() => {
        setLoading(true);
        Promise.all([
            axios.get(apiURL.getCustomers),
            axios.get(apiURL.getTimesheetTasks),
            axios.get(apiURL.getProjectMembersTimesheet + "undefined"),
        ])
            .then(([{ data: customers }, { data: tasks }, { data: members }]) => {
                setClientList(customers);
                setOptionsTasks(tasks.map((e) => ({ ...e, canSelect: true })));
                setProjectMembers(members);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <Spin spinning={loading}>
            <FormCustomer clientsList={clientsList} />
            <FormTasks optionsTasks={optionsTasks} />
            <FormInfos />
            <FormMembers projectMembers={projectMembers} />
        </Spin>
    );
};

export default ModalProject;
