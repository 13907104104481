import i18n from "i18next";

export function getVisaDeadlineDaysList() {
    const deadlineDays = [];

    for (let i = 1; i <= 30; i++) {
        deadlineDays.push({
            val: i,
            label: i + " " + (i === 1 ? i18n.t("jour") : i18n.t("jours")),
        });
    }

    return deadlineDays;
}
