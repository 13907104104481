import moment from "moment";

import { useTranslation } from "react-i18next";

import { getFileExtension } from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import { debounce } from "helpers/input";
import { bytesToSize } from "helpers/size";

import { Flex, Spin, Table, Typography } from "antd";

const PhotosDataTable = ({
    data,
    containerLoading,
    handleScroll,
    hasMore,
    changeSorter,
    selectedFiles,
    setSelectedFiles,
    setRenderDocumentFile,
}) => {
    const { t } = useTranslation();

    const loadingLineRender = (record, renderNode, activeLoading = false) => {
        if (record?._id === "loading" && activeLoading) {
            return (
                <Flex gap={8} align="center">
                    <Spin />
                    <Typography.Text>
                        {t("chargement")}
                        ...
                    </Typography.Text>
                </Flex>
            );
        }
        if (record?._id === "loading") {
            return "";
        }
        return renderNode;
    };

    return (
        <Table
            tableLayout="fixed"
            bordered
            dataSource={data.concat(
                hasMore
                    ? [
                          {
                              _id: "loading",
                          },
                      ]
                    : [],
            )}
            loading={containerLoading}
            pagination={false}
            onScroll={debounce(handleScroll, 200)}
            onChange={changeSorter}
            scroll={{
                x: 1500,
                y: "65vh",
            }}
            rowKey={(record) => record._id}
            rowSelection={{
                selectedRowKeys: selectedFiles.map((e) => e._id),
                type: "checkbox",
                onChange: (keys) => {
                    setSelectedFiles(data.filter((e) => keys.includes(e._id)));
                },
                selections: [Table.SELECTION_ALL, Table.SELECTION_NONE],
            }}
            onRow={(record, rowIndex) => ({
                style: {
                    cursor: "pointer",
                },
                onClick: ({ target }) => {
                    if (target.querySelector(".ant-checkbox-input")) {
                        setSelectedFiles((prev) =>
                            prev.find((e) => e._id === record._id)
                                ? prev.filter((e) => e._id !== record._id)
                                : [...prev, record],
                        );
                    } else setRenderDocumentFile(record);
                }, // click row
            })}
            columns={[
                {
                    width: 400,
                    title: t("nom"),
                    dataIndex: "title",
                    sorter: true,
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (v, record) =>
                        loadingLineRender(
                            record,
                            <Flex gap={12} align="center">
                                <img
                                    alt={getFileExtension(record.file)}
                                    src={getFileExtensionIcon(record)}
                                    style={{
                                        width: 24,
                                        height: 24,
                                    }}
                                />
                                <Typography.Text ellipsis>
                                    {record.title +
                                        "." +
                                        getFileExtension(record.file)}
                                </Typography.Text>
                            </Flex>,
                            true,
                        ),
                },
                {
                    title: t("proprietaire"),
                    dataIndex: "author",
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (v, record) =>
                        loadingLineRender(
                            record,
                            record.author
                                ? record.author?.firstname +
                                      " " +
                                      record.author?.lastname
                                : "",
                        ),
                    sorter: true,
                },
                {
                    title: t("dateDimportation"),
                    dataIndex: "creationDate",
                    sorter: true,
                    render: (v, record) =>
                        loadingLineRender(
                            record,
                            moment(record.creationDate).format("DD/MM/YYYY"),
                        ),
                },
                {
                    title: t("tailleDuFichier"),
                    dataIndex: "size",
                    sorter: true,
                    render: (v, record) =>
                        loadingLineRender(record, bytesToSize(record.size)),
                },
                {
                    title: t("dossier"),
                    dataIndex: "project",
                    render: (v, record) =>
                        loadingLineRender(record, record.project?.title),
                    sorter: true,
                    ellipsis: {
                        showTitle: false,
                    },
                    width: 400,
                },
            ]}
        />
    );
};

export default PhotosDataTable;
