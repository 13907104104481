import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { fromDateToStringDate } from "helpers/date";
import { ItemTypes } from "helpers/dnd";
import {
    getFileExtension,
    getRelatedCompiled,
    isCompiledFilesExists,
    isIfcFile,
    isPcdFile,
} from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import { getProjectparentsList } from "helpers/project";
import {
    formatSizeToDisplay,
    getProjectFileSize,
    getProjectSize,
} from "helpers/size";
import { getMemberRoles } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import DocumentReader from "components/documents/DocumentReader";
import Checkbox from "components/input/Checkbox";
import Spinner from "components/utils/Spinner";
import VisasInlineList from "components/visa/VisasInlineList";

import FileRelatedCompiled from "./FileRelatedCompiled";
import OptionsFile from "./OptionsFile";
import OptionsProject from "./OptionsProject";
import ProjectBreadcrumb from "./ProjectBreadcrumb";

import { Button, Dropdown, Flex } from "antd";

const FileDetailsLine = ({
    item,
    isFolder = false,
    profile,
    refreshAction,
    onItemClick,
    currentItem,
    setConfirmationModal,
    userRoles,
    isDnd = true,
    addSelectedFile,
    selectedFiles,
    handleVisibleVersionsFile,
    showLocation,
    showLastOpenDate,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { permissionsTools } = useProfileStore();

    const smMedia = useMediaPredicate("(min-width: " + Constants.smSize + ")");
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    const [loader, setLoader] = useState(false);

    const [size, setSize] = useState(null);

    const isOldVersion = item.isOldVersion;

    const [isRenderDocument, setRenderDocument] = useState(false);

    const [clickTimeout, setClickTimeout] = useState(null);

    const OptionsComponent = isFolder ? OptionsProject : OptionsFile;

    const [fileUserRoles, setFileUserRoles] = useState(userRoles);

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: [ItemTypes.FILE, ItemTypes.PROJECT],
            drop: (item) => handleMove(item.type, item.id, item.project),
            canDrop: (item) => handleCanDrop(item.type, item.id, item.project),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [item],
    );

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.FILE,
        item: {
            type: ItemTypes.FILE,
            id: item._id,
            project: item.project && item.project,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const [{ isDraggingProject }, dragProject] = useDrag(() => ({
        type: ItemTypes.PROJECT,
        item: {
            type: ItemTypes.PROJECT,
            id: item._id,
            project: item && item,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        const getFileSize = async () => {
            let resSize;

            if (!isFolder) resSize = await getProjectFileSize(item);
            else resSize = await getProjectSize(item._id);

            setSize(resSize);
        };

        if (!size) getFileSize();
    }, []);

    useEffect(() => {
        // Il peut avrriver que l'on ne puisse pas recevoir les droits du user par props (par exemple les favoris du Dashboard)
        if (!userRoles.READER && profile) {
            const getUserRoles = async () => {
                const resRoles = await getMemberRoles(
                    isFolder ? item : item.project,
                    profile.user,
                );
                setFileUserRoles(resRoles);
            };

            getUserRoles();
        }
    }, [userRoles, profile]);

    const handleMove = async (type, itemId, originalProject) => {
        setLoader(true);

        if (type === ItemTypes.FILE) {
            const projectFile = await axios.get(
                apiURL.getProjectFileById + itemId,
            );

            if (projectFile && projectFile.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectFile.data.project,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !fileUserRoles.MODERATOR) return;

                // Si c'est une compilation, on check si elle n'existe pas déjà dans le dossier
                if (projectFile.data.compiledFiles.length > 0) {
                    const isExists = await isCompiledFilesExists(
                        projectFile.data.compiledFiles.map(
                            (selectedFile) => selectedFile._id,
                        ),
                        item._id,
                    );

                    if (isExists) {
                        toastAlert(
                            "warning",
                            t(
                                "cetteCompilationDeFichiersExisteDejaDansCeDossier",
                            ),
                        );

                        setLoader(false);
                        return;
                    }
                }

                const relatedCompiled = await getRelatedCompiled(
                    projectFile.data._id,
                );

                if (relatedCompiled.length > 0) {
                    setLoader(false);

                    setConfirmationModal({
                        title: t("confirmation"),
                        content: (
                            <FileRelatedCompiled
                                file={projectFile.data}
                                isMoving={true}
                            />
                        ),
                        callBackFunc: moveFile,
                        callBackParams: itemId,
                        closeReset: setConfirmationModal,
                        successText: t("confirmer"),
                        errorText: t("annuler"),
                        customWidth: "max-w-[1000px]",
                    });
                } else await moveFile(itemId);
            }
        } else if (type === ItemTypes.PROJECT) {
            // On check si le projet dans lequel on déplace l'élément courant n'est pas un enfant
            const parents = await getProjectparentsList(item._id);

            if (parents.length > 0) {
                if (
                    parents.some((parent) => parent._id === originalProject._id)
                )
                    return;
            }

            const projectToMove = await axios.get(
                apiURL.getProjectById + itemId,
            );

            if (projectToMove && projectToMove.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectToMove.data,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !fileUserRoles.MODERATOR) return;

                await moveProject(itemId);
            }
        }

        setLoader(false);
    };

    // Déplacement du fichier dans le projet ciblé
    const moveFile = async (fileId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProjectFile + fileId + "/" + item._id,
            );
            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    // Déplacement du projet dans le projet ciblé
    const moveProject = async (movedProjectId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProject + movedProjectId + "/" + item._id,
            );
            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    const handleCanDrop = (type, itemId, originalProject) => {
        if (
            originalProject._id === item._id ||
            (type === ItemTypes.PROJECT &&
                itemId !== item._id.toString() &&
                item.projects &&
                item.projects.some(
                    (subProject) => subProject._id === originalProject._id,
                ))
        )
            return false;

        return true;
    };

    const handleClick = (e) => {
        if (
            lgMedia &&
            !isFolder &&
            item.versions.length > 0 &&
            item.versions[0]._id === item._id
        )
            handleVisibleVersionsFile(item._id);

        if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
            handleDoubleClick(item._id);
        } else {
            if (onItemClick && lgMedia) {
                if (!isFolder) onItemClick(item._id, isFolder);
                else {
                    const timeout = setTimeout(() => {
                        setClickTimeout(null);
                        onItemClick(item._id, isFolder);
                    }, 300);
                    setClickTimeout(timeout);
                }
            } else if (!isFolder) {
                if (isIfcFile(item) || isPcdFile(item))
                    window.open("/viewer/" + item._id);
                else setRenderDocument(true);
            } else navigate("/drive/project/" + item._id);
        }
    };
    const handleDoubleClick = (e) => {
        if (onItemClick) {
            if (!isFolder) {
                if (isIfcFile(item) || isPcdFile(item))
                    window.open("/viewer/" + item._id);
                else setRenderDocument(true);
            } else navigate("/drive/project/" + item._id);
        }
    };

    const closeReader = () => {
        setRenderDocument(false);
    };

    const handleSelection = (e) => {
        let checked = e.target.checked;
        addSelectedFile(item, checked);
    };

    const getItemLocation = () => {
        let location;
        let link;

        if (isFolder) {
            if (item.parentProject) {
                location = item.parentProject.title;
                link = "/drive/project/" + item.parentProject._id;
            } else {
                location = t("mesProjets");
                link = "/drive";
            }
        } else {
            location = item.project.title;
            link = "/drive/project/" + item.project._id;
        }

        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            key: item._id,
                            label: (
                                <ProjectBreadcrumb
                                    project={isFolder ? item : item.project}
                                    isFromLocation={true}
                                />
                            ),
                        },
                    ],
                }}
                placement="left"
            >
                <Button
                    color="default"
                    variant="link"
                    style={{ fontWeight: "normal" }}
                    onClick={(e) => navigate(link)}
                >
                    {location}
                </Button>
            </Dropdown>
        );
    };

    const lastCellContent = (
        <td className="relative pl-8">
            <OptionsComponent
                file={item}
                project={item}
                profile={profile}
                refreshAction={refreshAction}
                showInfosInPanel={onItemClick}
                userRoles={fileUserRoles}
                setRenderDocument={setRenderDocument}
                isRenderDocument={isRenderDocument}
            />

            {loader && <Spinner />}
        </td>
    );

    return (
        <Fragment>
            <OptionsComponent
                file={item}
                project={item}
                profile={profile}
                refreshAction={refreshAction}
                showInfosInPanel={onItemClick}
                userRoles={fileUserRoles}
                setRenderDocument={setRenderDocument}
                isRenderDocument={isRenderDocument}
                trigger="contextMenu"
            >
                <tr
                    ref={(node) =>
                        isDnd &&
                        refreshAction &&
                        item.project &&
                        item.compiledFiles
                            ? drag(node)
                            : isDnd && isFolder
                              ? dragProject(drop(node))
                              : null
                    }
                    className={
                        "relative group border-t-2 hover:bg-slate-200" +
                        (currentItem && currentItem._id === item._id
                            ? " bg-slate-200"
                            : "") +
                        (isOver && canDrop ? " bg-blue-100" : "")
                    }
                >
                    {addSelectedFile && (
                        <td>
                            <div className="p-2">
                                <Checkbox
                                    onChange={(e) => handleSelection(e)}
                                    value={selectedFiles.some(
                                        (selectedFile) =>
                                            selectedFile._id === item._id,
                                    )}
                                    removeMarging={true}
                                />
                            </div>
                        </td>
                    )}
                    <td>
                        <div
                            onClick={(e) => handleClick(e)}
                            onDoubleClick={(e) => handleDoubleClick(e)}
                            className={
                                "p-2 block hover:cursor-pointer" +
                                (isOldVersion ? " opacity-40 sm:pl-8 pl-4" : "")
                            }
                        >
                            <Flex align="center" gap="small">
                                {isFolder ? (
                                    !!item?.tender ? (
                                        <MaterialIcons
                                            type={item.color || "secondary"}
                                            name="package_2"
                                        />
                                    ) : !!item?.isResponseTender ? (
                                        <MaterialIcons
                                            type={item.color || "secondary"}
                                            name="deployed_code_account"
                                        />
                                    ) : (
                                        <MaterialIcons
                                            type={item.color || "secondary"}
                                            name="folder"
                                        />
                                    )
                                ) : (
                                    <Flex className="relative">
                                        {!!item.versions?.length &&
                                            !item.isOldVersion && (
                                                <Fragment>
                                                    <img
                                                        src={getFileExtensionIcon(
                                                            item,
                                                        )}
                                                        alt={item.title + "-2"}
                                                        width="26"
                                                        className="absolute ml-2 opacity-20"
                                                    />
                                                    <img
                                                        width="26"
                                                        src={getFileExtensionIcon(
                                                            item,
                                                        )}
                                                        alt={item.title + "-1"}
                                                        className="absolute ml-1 opacity-50"
                                                    />
                                                </Fragment>
                                            )}
                                        <img
                                            width="26"
                                            src={getFileExtensionIcon(item)}
                                            alt={item.title}
                                            style={{
                                                zIndex: 3,
                                            }}
                                        />
                                    </Flex>
                                )}
                                <Text>
                                    {item.title +
                                        (item.file
                                            ? "." + getFileExtension(item.file)
                                            : item.compiledFiles?.length > 0
                                              ? ".ifc"
                                              : "")}
                                </Text>
                            </Flex>
                        </div>
                    </td>
                    <td
                        className={
                            "hidden 2xl:table-cell whitespace-nowrap" +
                            (isOldVersion ? " opacity-40" : "")
                        }
                    >
                        {item.author && (
                            <div
                                onClick={(e) => handleClick(e)}
                                onDoubleClick={(e) => handleDoubleClick(e)}
                                className="p-2 block hover:cursor-pointer"
                            >
                                {item.author.firstname +
                                    " " +
                                    item.author.lastname}
                            </div>
                        )}
                    </td>
                    {!smMedia && lastCellContent}
                    {showLastOpenDate ? (
                        <td
                            className={
                                "hidden 2xl:table-cell whitespace-nowrap" +
                                (isOldVersion ? " opacity-40" : "")
                            }
                        >
                            <div
                                onClick={(e) => handleClick(e)}
                                onDoubleClick={(e) => handleDoubleClick(e)}
                                className="p-2 block hover:cursor-pointer"
                            >
                                {item.lastOpenDate
                                    ? fromDateToStringDate(item.lastOpenDate)
                                    : t("inconnue")}
                            </div>
                        </td>
                    ) : (
                        <td
                            className={
                                "hidden 2xl:table-cell whitespace-nowrap" +
                                (isOldVersion ? " opacity-40" : "")
                            }
                        >
                            <div
                                onClick={(e) => handleClick(e)}
                                onDoubleClick={(e) => handleDoubleClick(e)}
                                className="p-2 block hover:cursor-pointer"
                            >
                                {fromDateToStringDate(
                                    item.updatedDate
                                        ? item.updatedDate
                                        : item.creationDate,
                                )}
                            </div>
                        </td>
                    )}
                    {showLocation ? (
                        <td
                            className={
                                "hidden xl:table-cell whitespace-nowrap" +
                                (isOldVersion ? " opacity-40" : "")
                            }
                        >
                            <div className="p-2 block hover:cursor-pointer">
                                {getItemLocation()}
                            </div>
                        </td>
                    ) : (
                        <Fragment>
                            <td
                                className={
                                    "hidden xl:table-cell whitespace-nowrap" +
                                    (isOldVersion ? " opacity-40" : "")
                                }
                            >
                                {size && (
                                    <div
                                        onClick={(e) => handleClick(e)}
                                        onDoubleClick={(e) =>
                                            handleDoubleClick(e)
                                        }
                                        className="p-2 block hover:cursor-pointer"
                                    >
                                        {formatSizeToDisplay(size)}
                                    </div>
                                )}
                            </td>
                            {permissionsTools?.visas?.canView && (
                                <td
                                    className={
                                        "hidden xl:table-cell whitespace-nowrap" +
                                        (isOldVersion ? " opacity-40" : "")
                                    }
                                >
                                    <div
                                        onClick={(e) => handleClick(e)}
                                        onDoubleClick={(e) =>
                                            handleDoubleClick(e)
                                        }
                                        className={
                                            "block hover:cursor-pointer max-w-[250px] overflow-x-auto" +
                                            (item.visas?.filter(
                                                (item) => item.visa,
                                            ).length > 0
                                                ? " p-0"
                                                : " p-2")
                                        }
                                    >
                                        <VisasInlineList file={item} />
                                    </div>
                                </td>
                            )}
                        </Fragment>
                    )}

                    {smMedia && !selectedFiles?.length > 0 && lastCellContent}
                </tr>
            </OptionsComponent>
            {isRenderDocument && (
                <DocumentReader
                    fileId={item._id}
                    closeReader={closeReader}
                    profile={profile}
                    refreshAction={refreshAction}
                />
            )}
        </Fragment>
    );
};

export default FileDetailsLine;
