import { Text as TextPrint } from "@react-pdf/renderer";

import { useRef } from "react";
import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Button, Col, Flex, Form, Input, Row, Select, Switch, theme } from "antd";

export function SelectListAnswer({ index, title, options, defaultValue, required, disabled }) {
    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}>
            <Select
                disabled={disabled}
                mode={defaultValue ? "multiple" : ""}
                options={options.map((option) => ({
                    label: option,
                    value: option,
                }))}
            />
        </Form.Item>
    );
}
export function SelectListTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();
    const listRef = useRef();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Selection multiple :" name={[index, "defaultValue"]}>
                    <Switch />
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.List name={[index, "options"]}>
                    {(fields, { add, remove }, { errors }) => (
                        <Flex gap={16} wrap ref={listRef}>
                            {fields.map(({ key, name }, index) => (
                                <Form.Item key={key} name={name}>
                                    <Input
                                        style={{
                                            width: 200,
                                        }}
                                        suffix={<MaterialIcons size="sm" name="delete" onClick={() => remove(index)} />}
                                    />
                                </Form.Item>
                            ))}
                            <Button
                                onClick={() => {
                                    add();
                                    setTimeout(() => {
                                        const inputElements = listRef.current.querySelectorAll("input");
                                        if (inputElements.length > 0) {
                                            inputElements[inputElements.length - 1].focus();
                                        }
                                    }, 0);
                                }}
                                icon={<MaterialIcons name="add" />}>
                                Ajouter une option
                            </Button>
                        </Flex>
                    )}
                </Form.List>
            </Col>
        </Row>
    );
}

export function SelectListPrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {Array.isArray(field.value) ? field.value.join(" / ") : field.value}
        </TextPrint>
    );
}
