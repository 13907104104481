import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { downloadFileById, getFileExtension, getFileType } from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import Constants from "utils/constants";

import DropDownButton from "components/input/DropDownButton";
import IconButton from "components/input/IconButton";
import RangeSlider from "components/input/RangeSlider";
import ShareProjectFile from "components/share/ShareProjectFile";

import { ModalContext } from "layout/LayoutModal";

const DocumentActions = ({
    file,
    scale,
    setScale,
    closeReader,
    showComments,
    profile,
    refreshAction,
    userRoles,
    searchedTerm,
    setSearchedTerm,
    incrementSearchedOccurence,
    countOccurences,
    isFromViewer,
    setIsShowVersions,
    setIsCompareDocuments,
    isShowVersions,
    isShowComments,
}) => {
    const minScale = 0.1;
    const maxScale = 4.0;
    const scaleStep = 0.01;
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    const isSmallHeader = !mdMedia && isFromViewer;
    const { t } = useTranslation();

    const { addModalData } = useContext(ModalContext);

    const inputRef = useRef(null);

    const [isSearchVisible, setSearchVisible] = useState(false);

    const handleZoomIn = () => {
        if (scale + scaleStep < maxScale)
            setScale((prevScale) => Math.min(maxScale, prevScale + scaleStep));
    };

    const handleZoomOut = () => {
        setScale((prevScale) => Math.max(minScale, prevScale - scaleStep));
    };

    const toggleSearch = () => {
        setSearchVisible(!isSearchVisible);
    };

    const toggleVersionInfos = () => {
        if (!lgMedia) showComments(false);
        setIsShowVersions((e) => !e);
    };
    const toggleCompare = () => {
        if (!lgMedia) {
            setIsShowVersions(false);
            showComments(false);
        }
        setIsCompareDocuments(true);
    };

    const toggleComments = (e) => {
        if (!lgMedia) setIsShowVersions(false);
        showComments((e) => !e);
    };

    useEffect(() => {
        if (isSearchVisible) inputRef.current.focus();
    }, [isSearchVisible]);

    const shareFile = (type = "") => {
        addModalData({
            key: "shareFile",
            title: t("partager"),
            width: 1000,
            isForm: false,
            content: (
                <ShareProjectFile
                    files={[file]}
                    filesIds={[file._id]}
                    isQRCode={type === "qrcode" ? true : false}
                    isIFrame={type === "iframe" ? true : false}
                    refreshAction={refreshAction}
                />
            ),
        });
    };

    const dropDownContent = [
        {
            text: t("partagerAvecBimono"),
            icon: <img loading="lazy" src="/img/logo-black.svg" alt="" />,
            action: shareFile,
        },
        {
            text: t("genererUnQRCode"),
            icon: (
                <i className="material-icons notranslate my-auto mr-1">
                    qr_code_2
                </i>
            ),
            action: shareFile,
            param: "qrcode",
        },
        getFileExtension(file.file) === "ifc" && {
            text: t("genererUnIFrame"),
            icon: (
                <i className="material-icons notranslate my-auto mr-1">code</i>
            ),
            action: shareFile,
            param: "iframe",
        },
    ].filter((e) => !!e);

    return (
        <div
            className={
                "z-[101] flex w-full p-2 md:p-4" +
                (isFromViewer ? " bg-black text-white" : "  bg-slate-100 ")
            }
        >
            <div className={"w-full sm:px-3 flex flex-row flex-wrap"}>
                <div className="w-full md:w-1/2 my-auto md:pr-4">
                    {!isFromViewer && (
                        <div className="flex flex-row">
                            <img
                                src={getFileExtensionIcon(file)}
                                alt=""
                                width={isSmallHeader ? "20" : "40"}
                            />
                            <div className="my-auto ml-2 whitespace-nowrap text-xs md:text-xl font-semibold truncate">
                                {file.title + "." + getFileExtension(file.file)}
                            </div>
                        </div>
                    )}
                </div>
                <div
                    className={
                        "flex md:w-1/2 justify-end my-auto md:mt-auto gap-2 items-center ml-auto" +
                        (isSmallHeader ? " " : " mt-2")
                    }
                >
                    {getFileType(file) === "PDF" && (
                        <Fragment>
                            <div
                                className={
                                    "lg:inline-flex my-auto mr-2" +
                                    (isSmallHeader
                                        ? " flex-1 scale-75 inline-flex min-w-[50px]"
                                        : isFromViewer
                                          ? " scale-75"
                                          : " hidden w-[200px]")
                                }
                            >
                                <RangeSlider
                                    min={minScale}
                                    max={maxScale}
                                    step={scaleStep}
                                    value={scale}
                                    setValue={setScale}
                                />
                            </div>

                            <IconButton
                                icon="remove"
                                text={t("zoomerMoins")}
                                onClick={handleZoomOut}
                                isSmall={true}
                                isNeutral={true}
                            />

                            <IconButton
                                icon="add"
                                text={t("zoomerPlus")}
                                onClick={handleZoomIn}
                                isSmall={true}
                                isNeutral={true}
                            />
                        </Fragment>
                    )}

                    {!isFromViewer &&
                        !!file.versions?.length &&
                        userRoles.READER && (
                            <Fragment>
                                <IconButton
                                    icon="history"
                                    text={t("versions")}
                                    onClick={toggleVersionInfos}
                                    isSmall={true}
                                    isNeutral={!isShowVersions}
                                />
                                <IconButton
                                    icon="compare"
                                    text={t("comparaisonDesVersions")}
                                    onClick={toggleCompare}
                                    isSmall={true}
                                    isNeutral={true}
                                />
                            </Fragment>
                        )}

                    {!isFromViewer && (
                        <IconButton
                            icon="chat"
                            text={t("commentaires")}
                            onClick={toggleComments}
                            isSmall={true}
                            isNeutral={!isShowComments}
                        />
                    )}

                    {(file.isPublicDownload || userRoles.READER) && (
                        <IconButton
                            icon="download"
                            text={t("telecharger")}
                            onClick={(e) =>
                                downloadFileById(file._id, file.title)
                            }
                            isSmall={true}
                            isNeutral={true}
                        />
                    )}

                    {!isFromViewer && getFileType(file) === "PDF" && (
                        <IconButton
                            icon="search"
                            text={t("rechercher")}
                            onClick={toggleSearch}
                            isSmall={true}
                            isNeutral={true}
                        />
                    )}

                    {isSearchVisible && (
                        <div className="absolute top-[104px] md:top-[73px] right-[12px] w-[calc(100%-12px)] sm:w-[250px] text-black bg-slate-200 border-black rounded-lg text-left flex inline-flex">
                            <input
                                ref={inputRef}
                                type="search"
                                value={searchedTerm}
                                onChange={(e) =>
                                    setSearchedTerm(e.target.value)
                                }
                                onKeyDown={(e) =>
                                    e.key === "Enter"
                                        ? incrementSearchedOccurence(e)
                                        : e.key === "Escape" &&
                                          setSearchVisible(false)
                                }
                                placeholder={t("rechercher")}
                                className="bg-transparent p-2 w-full focus:outline-none focus:shadow-outline"
                            />
                            {countOccurences && (
                                <div className="my-auto px-2 flex flex-row">
                                    <div className="my-auto">
                                        {countOccurences}
                                    </div>
                                    <button
                                        type="button"
                                        className="ml-1 p-1"
                                        onClick={(e) =>
                                            incrementSearchedOccurence(e)
                                        }
                                    >
                                        <i
                                            className="material-icons notranslate flex"
                                            style={{ fontSize: "1.3rem" }}
                                        >
                                            arrow_downward
                                        </i>
                                    </button>
                                    <button
                                        type="button"
                                        className="p-1"
                                        onClick={(e) =>
                                            incrementSearchedOccurence(e, true)
                                        }
                                    >
                                        <i
                                            className="material-icons notranslate flex"
                                            style={{ fontSize: "1.3rem" }}
                                        >
                                            arrow_upward
                                        </i>
                                    </button>
                                </div>
                            )}
                        </div>
                    )}

                    {!isFromViewer && (
                        <div className="inline-flex my-auto">
                            {profile ? (
                                <DropDownButton
                                    text={t("partager")}
                                    icon="share"
                                    items={dropDownContent}
                                    orientation="left"
                                    mobileIcon="share"
                                />
                            ) : (
                                <IconButton
                                    icon="share"
                                    text={t("partager")}
                                    onClick={shareFile}
                                    isSmall={true}
                                />
                            )}
                        </div>
                    )}

                    {closeReader && !isSmallHeader && (
                        <IconButton
                            icon="close"
                            text={t("fermer")}
                            onClick={closeReader}
                            isBlackBtn={isFromViewer}
                            isNeutral={!isFromViewer}
                            isSmall={isSmallHeader}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DocumentActions;
