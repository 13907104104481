import moment from "moment";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { capitalize } from "helpers/string";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";

import CountFilter from "./CountFilter";
import FilterByDateRange from "./FilterByDateRange";
import FilterByProject from "./FilterByProject";
import FilterBySearch from "./FilterBySearch";
import FilterByStatut from "./FilterByStatut";
import FilterByUsers from "./FilterByUsers";

import { Badge, Button, Card, Collapse, Flex, theme } from "antd";

const FiltersSidePanel = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { token } = theme.useToken();
    const { toolsData, setToolsData } = useContext(DriveToolsContext);

    useEffect(() => {
        return () => {
            setToolsData((e) => ({ ...e, filtersOpen: false }));
        };
    }, [location]);

    return (
        <Card
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
            styles={{
                header: {
                    padding: token.paddingSM,
                },
                body: {
                    paddingRight: 0,
                    paddingLeft: 0,
                    overflow: "auto",
                },
            }}
            extra={
                <Button
                    shape="circle"
                    type="text"
                    icon={<MaterialIcons name="close" />}
                    onClick={(e) => {
                        setToolsData((e) => ({
                            ...e,
                            filtersOpen: !e.filtersOpen,
                        }));
                    }}
                />
            }
            title={
                <CountFilter isRelative>
                    <Text bold size="large">
                        {t("filtres")}
                    </Text>
                </CountFilter>
            }>
            <Flex vertical>
                {(location.pathname === "/timesheet/entries" ||
                    location.pathname === "/photos" ||
                    location.pathname === "/timesheet/absences-validation") && (
                    <FilterItem
                        label={t("utilisateurs")}
                        children={<FilterByUsers />}
                        filterActive={toolsData.filters.users.length}
                    />
                )}
                {location.pathname === "/timesheet/entries" && (
                    <FilterItem
                        label={t("dossiers")}
                        children={<FilterByProject />}
                        filterActive={toolsData.filters.projects.length}
                    />
                )}
                {location.pathname === "/timesheet/absences-validation" && (
                    <FilterItem
                        label={t("statut")}
                        children={<FilterByStatut />}
                        filterActive={toolsData.filters.statuts.length}
                    />
                )}
                {location.pathname === "/timesheet/entries" && (
                    <FilterItem
                        label={t("date")}
                        children={<FilterByDateRange />}
                        filterActive={
                            toolsData.filters.dateRange !== "byMonth" ||
                            toolsData.pagination !== moment().format("YYYY-MM-DD")
                        }
                    />
                )}
                {(location.pathname === "/automations/visa" ||
                    location.pathname === "/photos" ||
                    location.pathname === "/automations/nomenclature") && (
                    <FilterItem
                        label={t("dossiers")}
                        children={<FilterBySearch />}
                        filterActive={toolsData.filters.folders.length}
                    />
                )}
            </Flex>
        </Card>
    );
};

export const FilterItem = ({ label, children, filterActive }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    return (
        <Collapse
            ghost
            items={[
                {
                    key: "1",
                    label: (
                        <Badge color={token.colorPrimary} size="small" dot={filterActive} offset={[10, 0]}>
                            <Text size="md" bold color={token.colorPrimary}>
                                {capitalize(label)}
                            </Text>
                        </Badge>
                    ),
                    children,
                },
            ]}
        />
    );
};

export function applyFiltersAbsencesStatuts(dataList, filters) {
    return dataList.filter((data) => {
        if (filters?.length === 0) return true;
        return filters.includes(data.statut);
    });
}

export default FiltersSidePanel;
