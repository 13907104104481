import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fileUploadProcess } from "helpers/file";

import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";

import { Flex, Radio } from "antd";

const DriveSameNameModal = ({
    file,
    refreshAction,
    sameNameFileInProject,
    projectToUpload,
    files,
    index,
    isFolderImport,
    nomenclatureData,
    answers,
    readStreamFunc,
    fileName,
}) => {
    const { t } = useTranslation();
    const [addType, setAddType] = useState("versionFile");

    const { setHandleConfirm } = useContext(ModalContext);

    useEffect(() => {
        setHandleConfirm(fileName, async () => {
            if (nomenclatureData && !answers) {
                // Nomenclature Modal
            } else {
                const oldFile =
                    addType === "versionFile"
                        ? {
                              ...sameNameFileInProject,
                              versions: sameNameFileInProject.versions?.length
                                  ? sameNameFileInProject.versions
                                  : [""],
                          }
                        : { versions: [] };
                await fileUploadProcess({
                    project: projectToUpload,
                    file,
                    existingFileId: oldFile?._id,
                    createVersionData: answers,
                    readStream: readStreamFunc,
                    startPdfConversion: true,
                    moreData: {
                        incrementTitle: addType === "importFile",
                    },
                });
                refreshAction();
            }
        });
    }, [addType, sameNameFileInProject, projectToUpload, file]);

    return (
        <Flex vertical gap={24}>
            <Text>
                {t(
                    `"${sameNameFileInProject.title}" ${t(
                        "existeDejaACetEmplacement",
                    )}.\n${t(
                        nomenclatureData
                            ? "voulezVousRemplacerLeFichierExistantParUneNouvelleVersionSiOuiLesVisasDeLaVersionPrecedenteSerontConserves"
                            : "voulezVousRemplacerLeFichierExistantOuConserverLesDeux",
                    )}`,
                )}
            </Text>
            <Radio.Group
                onChange={(e) => setAddType(e.target.value)}
                value={addType}
            >
                <Flex vertical gap={12}>
                    <Radio value={"versionFile"}>
                        {t(
                            nomenclatureData
                                ? "ajouterUneVersion"
                                : "remplacerLeFichierExistant",
                        )}
                    </Radio>
                    <Radio value={"importFile"}>
                        {t("conserverLesDeuxFichiers")}
                    </Radio>
                </Flex>
            </Radio.Group>
        </Flex>
    );
};

export default DriveSameNameModal;
