import Text from "components/common/Text";

import RenderCheckboxField from "./fields/RenderCheckboxField";
import RenderListField from "./fields/RenderListField";
import RenderMultiSelectField from "./fields/RenderMultiSelectField";
import RenderNumberField from "./fields/RenderNumberField";
import RenderTextField from "./fields/RenderTextField";
import RenderUniSelectField from "./fields/RenderUniSelectField";

const RenderFields = ({ setTimeEntry, timeEntry, isPreview = false }) => {
    return timeEntry.fields.map((field) => {
        const value = timeEntry.data.find((f) => f.timesheetField?._id === field?._id)?.value;

        let FieldComponent = () => <Text bold>{field.title}</Text>;
        switch (field.type) {
            case "TEXT":
                FieldComponent = RenderTextField;
                break;
            case "NUMBER":
                FieldComponent = RenderNumberField;
                break;
            case "UNISELECT":
                FieldComponent = RenderUniSelectField;
                break;
            case "MULTISELECT":
                FieldComponent = RenderMultiSelectField;
                break;
            case "LIST":
                FieldComponent = RenderListField;
                break;
            case "CHECKBOX":
                FieldComponent = RenderCheckboxField;
                break;
            // case "SWITCH":
            //     FieldComponent = RenderSwitchField;
            //     break;
            default:
                break;
        }

        return (
            <div key={field?._id}>
                <FieldComponent
                    value={value}
                    isPreview={isPreview}
                    {...field}
                    onChange={(e) => {
                        const dataToUpdate = timeEntry.data.find((f) => f.timesheetField?._id === field?._id);
                        setTimeEntry((k) => ({
                            ...k,
                            data: dataToUpdate
                                ? k.data.map((f) => (f.timesheetField?._id === field?._id ? { ...f, value: e } : f))
                                : [
                                      ...k.data,
                                      {
                                          title: field.title,
                                          type: field.type,
                                          timesheetField: field,
                                          value: e,
                                      },
                                  ],
                        }));
                    }}
                />
            </div>
        );
    });
};

export default RenderFields;
