import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { fromDateToStringDate, getLastOpenBetweenTwoDates } from "helpers/date";
import Constants from "utils/constants";

import Text from "components/common/Text";
import FileImage from "components/project/FileImage";
import OptionsFile from "components/project/OptionsFile";

import { Card, Flex, theme } from "antd";

const DriveVersionsPanel = ({
    fileId,
    item,
    currentProject,
    closePanel,
    refreshAction,
    profile,
    userRoles,
    showInfosInPanel,
    setIsShowVersions,
}) => {
    const { t } = useTranslation();
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    return (
        <Fragment>
            <div className="pt-2 md:pt-0 relative bordered-card rounded-2xl w-full md:w-[300px] h-full flex flex-col overflow-auto md:overflow-hidden">
                <button
                    type="button"
                    className="p-1 absolute top-1 right-2 hidden lg:block"
                    onClick={(e) => {
                        closePanel();
                    }}
                >
                    <i
                        className="material-icons notranslate"
                        style={{ fontSize: "1.5rem" }}
                    >
                        close
                    </i>
                </button>

                <Fragment>
                    <div className="text-black text-lg font-bold px-4 my-2 w-[calc(100%-24px)] min-h-[24px]">
                        {t("versions")}
                    </div>
                    <div
                        id="scroll-versions"
                        className={
                            "p-4 md:py-12 flex-grow relative w-full flex flex-col gap-2" +
                            (lgMedia ? " overflow-auto scrollbar-hover" : "")
                        }
                    >
                        {item.versions.map((e) => (
                            <ItemVersion
                                key={e._id}
                                isCurrentVersion={fileId === e._id}
                                item={e}
                                currentProject={currentProject}
                                closePanel={closePanel}
                                refreshAction={refreshAction}
                                profile={profile}
                                userRoles={userRoles}
                                showInfosInPanel={showInfosInPanel}
                                setIsShowVersions={setIsShowVersions}
                            />
                        ))}
                    </div>
                </Fragment>
            </div>
        </Fragment>
    );
};

const ItemVersion = ({
    item,
    closePanel,
    refreshAction,
    profile,
    userRoles,
    isCurrentVersion,
    currentProject,
    showInfosInPanel,
    setIsShowVersions,
}) => {
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");
    const { token } = theme.useToken();

    const Options = ({ trigger, children }) => (
        <OptionsFile
            file={{ ...item, compiledFiles: [] }}
            profile={profile}
            refreshAction={refreshAction}
            userRoles={userRoles}
            isFromSideVersion={true}
            closePanel={closePanel}
            showInfosInPanel={showInfosInPanel}
            trigger={trigger}
        >
            {children}
        </OptionsFile>
    );

    return (
        <Card
            hoverable
            bordered
            size="small"
            onClick={(e) => {
                showInfosInPanel(item._id);
                if (!lgMedia) setIsShowVersions(false);
            }}
            cover={<FileImage file={item} />}
            styles={{
                body: {
                    padding: token.paddingSM,
                },
            }}
            style={{
                backgroundColor: isCurrentVersion
                    ? token.colorPrimaryBg
                    : "initial",
            }}
            className={isCurrentVersion ? "card-selected" : ""}
        >
            <Flex>
                <Flex vertical style={{ flex: 1, display: "grid" }}>
                    <Text
                        strong
                        ellipsis
                        children={item.title}
                        size="small"
                        title={item.title}
                    />
                    <Text
                        ellipsis
                        type="secondary"
                        size="small"
                        children={
                            item.viewedDate
                                ? getLastOpenBetweenTwoDates(
                                      Date.now(),
                                      item.viewedDate,
                                  )
                                : `${
                                      (item.author?.firstname || "") +
                                      " " +
                                      (item.author?.lastname || "")
                                  } ${
                                      item.author ? "|" : ""
                                  } ${fromDateToStringDate(item.creationDate)}`
                        }
                    />
                </Flex>

                <Options />
            </Flex>
        </Card>
    );
};

export default DriveVersionsPanel;
