import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import ActivityItem from "./ActivityItem";

import { Divider } from "antd";

const ActivitiesList = ({ item, activities, userRoles }) => {
    const { t } = useTranslation();

    return (
        <>
            <Divider />

            <Text size="md" bold>
                {t("activites")}
            </Text>

            {activities.length > 0 ? (
                <ul className="overflow-x-auto mt-4">
                    {activities.map((activity) => (
                        <li key={activity._id}>
                            <ActivityItem
                                activity={activity}
                                item={item}
                                userRoles={userRoles}
                            />
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="text-center mt-6">{t("aucuneActivite")}</div>
            )}
        </>
    );
};

export default ActivitiesList;
