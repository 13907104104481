import moment from "moment";

import { Fragment, useEffect } from "react";

import { getFileExtension } from "helpers/file";
import { debounce } from "helpers/input";

import {
    Card,
    Checkbox,
    Col,
    Empty,
    Flex,
    Row,
    Spin,
    Tooltip,
    Typography,
    theme,
} from "antd";

const PhotosDataGrid = ({
    data,
    containerLoading,
    handleScroll,
    hasMore,
    selectedFiles,
    setSelectedFiles,
    setRenderDocumentFile,
}) => {
    const { token } = theme.useToken();

    const scrollDebounce = debounce(handleScroll, 500);

    useEffect(() => {
        const target = document.querySelector(
            "#layout-content > .ant-card-body",
        );
        if (target) {
            target.addEventListener("scroll", scrollDebounce);
            return () => {
                target.removeEventListener("scroll", scrollDebounce);
            };
        }
    }, [handleScroll]);

    return (
        <Spin spinning={containerLoading}>
            <Row gutter={[token.paddingLG, token.paddingLG]}>
                {data?.length === 0 ? (
                    <Empty
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    />
                ) : (
                    data?.map((e) => (
                        <Col
                            key={e._id}
                            xxl={6}
                            xl={8}
                            lg={12}
                            md={12}
                            xs={24}
                            style={{
                                height: 250,
                            }}
                        >
                            <Tooltip
                                placement="bottom"
                                style={{ width: 500 }}
                                align={{ offset: [0, "-200%"] }}
                                title={
                                    <Flex
                                        vertical
                                        onClick={() => {
                                            if (selectedFiles?.length > 0)
                                                setSelectedFiles((prev) =>
                                                    prev.find(
                                                        (el) =>
                                                            el._id === e._id,
                                                    )
                                                        ? prev.filter(
                                                              (el) =>
                                                                  el._id !==
                                                                  e._id,
                                                          )
                                                        : [...prev, e],
                                                );
                                            else setRenderDocumentFile(e);
                                        }}
                                        style={{
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Typography.Text
                                            ellipsis
                                            type="secondary"
                                            style={{
                                                color: "#dddddd",
                                            }}
                                        >
                                            <b>{e.project.title + "/"}</b>
                                            {e.title +
                                                "." +
                                                getFileExtension(e.file)}
                                        </Typography.Text>
                                        <Typography.Text
                                            type="secondary"
                                            style={{
                                                color: "#dddddd",
                                            }}
                                        >
                                            {e.author?.firstname +
                                                " " +
                                                e.author?.lastname +
                                                " " +
                                                moment(e.creationDate).format(
                                                    "DD/MM/YYYY",
                                                )}
                                        </Typography.Text>
                                    </Flex>
                                }
                            >
                                <Card
                                    onClick={() => {
                                        if (selectedFiles?.length > 0)
                                            setSelectedFiles((prev) =>
                                                prev.find(
                                                    (el) => el._id === e._id,
                                                )
                                                    ? prev.filter(
                                                          (el) =>
                                                              el._id !== e._id,
                                                      )
                                                    : [...prev, e],
                                            );
                                        else setRenderDocumentFile(e);
                                    }}
                                    style={{
                                        position: "relative",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                        height: 250,
                                    }}
                                    styles={{
                                        body: {
                                            height: "inherit",
                                            padding: 0,
                                        },
                                    }}
                                    hoverable
                                    size="small"
                                    children={
                                        <Fragment>
                                            <img
                                                loading="lazy"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                                alt={e.file}
                                                src={e.fileURL}
                                            />
                                            <Checkbox
                                                style={{
                                                    position: "absolute",
                                                    top: "10px",
                                                    right: "10px",
                                                    transform: "scale(1.5)",
                                                    zIndex: 2,
                                                }}
                                                checked={selectedFiles.find(
                                                    (el) => el._id === e._id,
                                                )}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setSelectedFiles((prev) =>
                                                        prev.find(
                                                            (el) =>
                                                                el._id ===
                                                                e._id,
                                                        )
                                                            ? prev.filter(
                                                                  (el) =>
                                                                      el._id !==
                                                                      e._id,
                                                              )
                                                            : [...prev, e],
                                                    );
                                                }}
                                            />
                                        </Fragment>
                                    }
                                />
                            </Tooltip>
                        </Col>
                    ))
                )}
                {hasMore && !containerLoading && (
                    <Col
                        span={24}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 250,
                        }}
                    >
                        <Spin />
                    </Col>
                )}
            </Row>
        </Spin>
    );
};

export default PhotosDataGrid;
