import moment from "moment";

import { fromMsToHoursMinutes, getDurationBetweenTwoDates } from "helpers/time";

import { getFieldModifier, getTotalWorkedHours } from "./InputCellTime";

// Function to apply the configuration of modifiers
export const applyConfiguration = (dataList, configuration, targetEntry = false) => {
    return dataList.map((e) => {
        if (e.isDetail)
            return {
                ...e,
                HS: "",
                HP: "",
                total: "",
            };
        else if (e.isGroup) {
            return {
                ...e,
                HS: "",
                HP: "",
                total: getTotalWorkedHours(
                    configuration,
                    e.mergedLines
                        .map((e) => e.mergedLines)
                        .flat()
                        .map((e) => e.entry),
                ),
            };
        } else {
            const { HS, HP, HSstats, mergedLines } = applyConfigForLine(e.mergedLines, configuration, targetEntry);
            return {
                ...e,
                mergedLines,
                HS,
                HP,
                HSstats,
                total: getTotalWorkedHours(
                    configuration,
                    e.isGroup
                        ? mergedLines
                              .map((e) => mergedLines)
                              .flat()
                              .map((e) => e.entry)
                        : targetEntry
                          ? mergedLines.filter((e) => e.entry._id === targetEntry).map((e) => e.entry)
                          : mergedLines.map((e) => e.entry),
                ),
            };
        }
    });
};

// Apply configuration for each merged line
const applyConfigForLine = (mergedLines, configuration, targetEntry = false) => {
    let HS = 0;
    let HP = 0;

    let limitDays = {};
    let limitSpecialDays = {};

    let HSstats = {
        JT: 0,
        HT: 0,
        HS: 0,
        HS_25: 0,
        HS_50: 0,
        HS_125: 0,
        HS_150: 0,
        baskets: 0,
    };

    let timeDueByWeek = {};
    let basketsByWeek = {};

    // Calculate Baskets by week
    const daysInMonth = moment().daysInMonth();
    const daysArray = Array.from({ length: daysInMonth }, (_, i) =>
        moment(mergedLines[0].entry.startDate).startOf("month").add(i, "days").format("YYYY-MM-DD"),
    );
    daysArray.forEach((day) => {
        //////////////////////////////////// SETUP ///////////////////////////////////////
        const weekNumber = moment(day).week();
        const actualSeason =
            moment(day).isAfter(
                moment(configuration.seasons[0].startSate).set({
                    year: moment().year(),
                }),
            ) &&
            moment(day).isBefore(
                moment(configuration.seasons[0].endDate).set({
                    year: moment().year(),
                }),
            )
                ? configuration.seasons[0]
                : configuration.seasons[1];

        const dayTime = 3600 * actualSeason.maxTime * 1000;
        if (moment(day).weekday() !== 5 && moment(day).weekday() !== 6) {
            if (!basketsByWeek[weekNumber]) {
                HSstats.baskets++;
                basketsByWeek[weekNumber] = 1;
                timeDueByWeek[weekNumber] = dayTime;
            } else {
                HSstats.baskets++;
                basketsByWeek[weekNumber] += 1;
                timeDueByWeek[weekNumber] += dayTime;
            }
        }
    });

    // Apply configuration for each entry
    let recordedLines = mergedLines
        .sort((a, b) => {
            return new Date(a.entry.startDate) - new Date(b.entry.startDate);
        })
        .map((line) => {
            //////////////////////////////////// SETUP ///////////////////////////////////////
            const entry = line.entry;
            let record = [];

            const weekNumber = moment(entry.startDate).week();

            let maxBasketsOnThisWeek = Array.from({ length: 7 }, (_, i) =>
                moment(entry.startDate)
                    .set({
                        week: weekNumber,
                    })
                    .startOf("week")
                    .add(i, "days")
                    .format("YYYY-MM-DD"),
            ).filter((day) => {
                const isHolyday = configuration.holidays.find((e) => moment(e.date).isSame(moment(day), "day"));
                const isCompensated = configuration.compensated.find((e) => moment(e.date).isSame(moment(day), "day"));
                const isVacation = configuration.vacations?.find((e) =>
                    moment(day).isBetween(e.startDate, e.endDate, null, "[]"),
                );

                if (
                    moment(day).weekday() === 5 ||
                    moment(day).weekday() === 6 ||
                    isHolyday ||
                    isCompensated ||
                    isVacation
                ) {
                    return false;
                } else return true;
            })?.length;

            // let maxBasketsOnThisMonth = Array.from({ length: moment(entry.startDate).daysInMonth() }, (_, i) =>
            //     moment(entry.startDate)
            //         .set({
            //             month: monthNumber,
            //         })
            //         .startOf("month")
            //         .add(i, "days")
            //         .format("YYYY-MM-DD"),
            // ).filter((day) => {
            //     const isHolyday = configuration.holidays.find((e) => moment(e.date).isSame(moment(day), "day"));
            //     const isCompensated = configuration.compensated.find((e) => moment(e.date).isSame(moment(day), "day"));
            //     if (moment(day).weekday() === 5 || moment(day).weekday() === 6 || isHolyday || isCompensated) {
            //         return false;
            //     } else return true;
            // })?.length;

            let timeDuration =
                getDurationBetweenTwoDates(entry.startDate, getRealEndDate(entry.startDate, entry.endDate)) +
                getFieldModifier(configuration, entry);

            const actualSeason =
                moment(entry.startDate).isAfter(
                    moment(configuration.seasons[0].startSate).set({
                        year: moment().year(),
                    }),
                ) &&
                moment(entry.startDate).isBefore(
                    moment(configuration.seasons[0].endDate).set({
                        year: moment().year(),
                    }),
                )
                    ? configuration.seasons[0]
                    : configuration.seasons[1];

            //////////////////////////////////// APPLY CONFIGURATION ///////////////////////////////////////

            // APPLY : RECOVERY
            if (entry.isRecovery) {
                record.push(`* Récupération d'heures`);
                HSstats.HT += timeDuration;
                return {
                    ...line,
                    entry: {
                        ...line.entry,
                        record,
                    },
                };
            }

            let savedHsStats = HSstats.HS;
            // APPLY : LIMIT MAX TIME
            const limitMaxTime = 60 * 60 * actualSeason.maxTime * 1000; // Get max time in ms
            let timeDurationDay = limitDays[moment(entry.startDate).format("YYYY-MM-DD")];
            if (!timeDurationDay) {
                HSstats.JT++;
                // HSstats.baskets++;
                limitDays[moment(entry.startDate).format("YYYY-MM-DD")] = timeDuration;
                timeDurationDay = 0;
            }
            timeDurationDay += timeDuration;
            if (timeDurationDay > limitMaxTime) {
                record.push(`* Total limite dépassé ( ${actualSeason.maxTime}h ) = heures en + dans HS`);
                // Cas n°2 : Heures sup
                HSstats.HS += timeDurationDay - limitMaxTime;
                HSstats.HT += timeDuration;
            } else {
                // Cas n°1 Mois Classique
                HSstats.HT += timeDuration;
            }
            timeDueByWeek[weekNumber] -= timeDuration;

            // APPLY : SATURDAY
            const isSaturday = moment(entry.startDate).weekday() === 5;
            if (isSaturday) {
                if (timeDueByWeek[weekNumber] < 0) {
                    if (timeDueByWeek[weekNumber] + timeDuration >= 0) {
                        HSstats.HS_125 += timeDuration - (timeDueByWeek[weekNumber] + timeDuration);
                        record.push(`* Travail le samedi en plus d'un jour`);

                        HSstats.HS_25 += timeDueByWeek[weekNumber] + timeDuration;
                        record.push(`* Travail le samedi à la place d'un jour`);
                    } else {
                        HSstats.HS_125 += timeDuration;
                        record.push(`* Travail le samedi en plus d'un jour`);
                    }
                } else {
                    HSstats.HS_25 += timeDuration;
                    record.push(`* Travail le samedi à la place d'un jour`);
                }
                HSstats.HS = savedHsStats;
            }

            // APPLY : SUNDAY
            const isSunday = moment(entry.startDate).weekday() === 6;
            if (isSunday) {
                if (timeDueByWeek[weekNumber] < 0) {
                    if (timeDueByWeek[weekNumber] + timeDuration >= 0) {
                        HSstats.HS_150 += timeDuration - (timeDueByWeek[weekNumber] + timeDuration);
                        record.push(`* Travail le dimanche en plus d'un jour`);

                        HSstats.HS_50 += timeDueByWeek[weekNumber] + timeDuration;
                        record.push(`* Travail le dimanche à la place d'un jour`);
                    } else {
                        HSstats.HS_150 += timeDuration;
                        record.push(`* Travail le dimanche en plus d'un jour`);
                    }
                } else {
                    HSstats.HS_50 += timeDuration;
                    record.push(`* Travail le dimanche à la place d'un jour`);
                }
                HSstats.HS = savedHsStats;
            }

            // APPLY : HOLIDAY
            const isHoliday = configuration.holidays.find(
                (e) => moment(e.date).format("YYYY-MM-DD") === moment(entry.startDate).format("YYYY-MM-DD"),
            );
            if (isHoliday) {
                if (timeDueByWeek[weekNumber] < 0) {
                    if (timeDueByWeek[weekNumber] + timeDuration >= 0) {
                        HSstats.HS_150 += timeDuration - (timeDueByWeek[weekNumber] + timeDuration);
                        record.push(`* Travail un jour férié en plus d'un jour`);

                        HSstats.HS_50 += timeDueByWeek[weekNumber] + timeDuration;
                        record.push(`* Travail un jour férié à la place d'un jour`);
                    } else {
                        HSstats.HS_150 += timeDuration;
                        record.push(`* Travail un jour férié en plus d'un jour`);
                    }
                } else {
                    HSstats.HS_50 += timeDuration;
                    record.push(`* Travail un jour férié à la place d'un jour`);
                }
                HSstats.HS = savedHsStats;
            }

            // APPLY : COMPENSATED
            const isCompensated = configuration.compensated.find(
                (e) => moment(e.date).format("YYYY-MM-DD") === moment(entry.startDate).format("YYYY-MM-DD"),
            );
            if (isCompensated) {
                // Cas n°11 : Travail un jour compensé
                // Reset from over time
                if (timeDurationDay > limitMaxTime) {
                    HSstats.HS -= timeDurationDay - limitMaxTime;
                }

                if (basketsByWeek[weekNumber] > maxBasketsOnThisWeek) {
                    // Setup dans heure supp
                    HSstats.HS += timeDuration;
                    record.push(`* Travail un jour compensé en plus =>  HSUP`);
                } else {
                    if (timeDurationDay > limitMaxTime) {
                        HSstats.HS += timeDurationDay - limitMaxTime;
                    }
                    record.push(
                        `* Travail un jour compensé pour récupération => HT + dépassement selon le mois dans HSUP`,
                    );
                }
            }

            // APPLY : VACATIONS
            const isVacation = configuration.vacations?.find((e) =>
                moment(entry.startDate)
                    .startOf("day")
                    .isBetween(moment(e.startDate).startOf("day"), moment(e.endDate).startOf("day"), null, "[]"),
            );
            if (isVacation) {
                // Cas n°11 : Travail un jour de vacances
                HSstats.HS += timeDuration;
                record.push(`* Travail un jour de vacances =>  HSUP`);
            }

            // APPLY : NIGHT TIME
            if (
                (!limitSpecialDays[moment(entry.startDate).format("YYYY-MM-DD")] &&
                    moment(entry.startDate).format("HH:mm") < actualSeason.nightTimeStart) ||
                moment(entry.endDate).format("HH:mm") > actualSeason.nightTimeEnd
            ) {
                const startNightTime =
                    entry.startDate.slice(0, 10) +
                    "T" +
                    "03:00" + // Replace by actualSeason.nightTimeStart with duration
                    ":00.000Z";
                const diffBefore = moment(startNightTime).isBefore(entry.endDate)
                    ? moment(startNightTime).diff(moment(entry.startDate))
                    : moment(entry.endDate).diff(moment(entry.startDate));
                if (diffBefore > 0) {
                    record.push(`* Heure de nuit = > HS ${actualSeason.nightTimeModifier * 100}%`);
                    if (timeDurationDay > limitMaxTime) {
                        HSstats.HS_150 += diffBefore;
                        HSstats.HS = savedHsStats;
                    } else {
                        HSstats.HS_50 += diffBefore;
                        HSstats.HS = savedHsStats;
                    }
                }

                const endNightTime =
                    entry.startDate.slice(0, 10) +
                    "T" +
                    "18:00" + // Replace by actualSeason.nightTimeEnd with duration
                    ":00.000Z";
                const diffAfter = moment(endNightTime).isAfter(entry.startDate)
                    ? moment(entry.endDate).diff(moment(endNightTime))
                    : moment(entry.endDate).diff(moment(entry.startDate));
                if (diffAfter > 0) {
                    record.push(`* Heure de nuit = > HS ${actualSeason.nightTimeModifier * 100}%`);
                    if (timeDurationDay > limitMaxTime) {
                        HSstats.HS_150 += diffAfter;
                        HSstats.HS = savedHsStats;
                    } else {
                        HSstats.HS_50 += diffAfter;
                        HSstats.HS = savedHsStats;
                    }
                }
            }

            return {
                ...line,
                entry: {
                    ...line.entry,
                    record,
                },
            };
        });

    HS = HSstats.HS;
    HP = HSstats.HT - HSstats.HS - HSstats.HS_25 - HSstats.HS_50 - HSstats.HS_125 - HSstats.HS_150;

    return {
        mergedLines: recordedLines,
        HS: fromMsToHoursMinutes(HS),
        HP: fromMsToHoursMinutes(HP),
        HSstats,
    };
};

// Merge lines with the same project, author and timesheetTask
export const applyMergeLines = (dataList, config) =>
    dataList.reduce((acc, val) => {
        let existingItem = acc.find(
            (e) =>
                e?.taskId === val?.timesheetTask?._id &&
                e?.projectId === val?.project?._id &&
                e?.userId === val?.author?._id,
        );

        const pushedItem = {
            // User
            userId: val.author?._id,
            userRender: val.author?.firstname + " " + val.author?.lastname,
            // Project
            projectId: val.project?._id,
            projectRender: val.project?.title,
            // Task
            taskId: val.timesheetTask?._id,
            taskRender: val.timesheetTask?.title || val.timesheetTaskTitle,
            // Entry
            entry: val,
            fieldsRender: config.fields,
        };

        if (existingItem) {
            existingItem.mergedLines.push(pushedItem);
        } else {
            acc.push({
                uniqueId: val.author?._id + val.project?._id + val.timesheetTask?._id,
                // User
                userId: val.author?._id,
                userRender: val.author?.firstname + " " + val.author?.lastname,
                // Project
                projectId: val.project?._id,
                projectRender: val.project?.title,
                // Task
                taskId: val.timesheetTask?._id,
                taskRender: val.timesheetTask?.title || val.timesheetTaskTitle,
                // Merged lines
                mergedLines: [pushedItem],
                fieldsRender: config.fields,
            });
        }
        return acc;
    }, []);

// Merge lines with the same project, author and timesheetTask
export const applyMergeLinesForCounter = (dataList) =>
    dataList.reduce((acc, val) => {
        let existingItem = acc.find((e) => e?.userId === val?.author?._id);

        const pushedItem = {
            // User
            userId: val.author?._id,
            userRender: val.author?.firstname + " " + val.author?.lastname,
            // Entry
            entry: val,
            // fieldsRender: config.fields,
        };

        if (existingItem) {
            existingItem.mergedLines.push(pushedItem);
        } else {
            acc.push({
                uniqueId: val.author?._id + val.project?._id + val.timesheetTask?._id,
                // User
                userId: val.author?._id,
                userRender: val.author?.firstname + " " + val.author?.lastname,
                // Merged lines
                mergedLines: [pushedItem],
                cost: val.cost,
            });
        }
        return acc;
    }, []);

// Filter by Filter sidepanel Projects
export const applyFiltersProjects = (dataList, filter) =>
    dataList.filter((e) =>
        // Apply filters users
        filter.find((k) => k?._id === e.projectId),
    );

// Filter by Filter sidepanel Users
export const applyFiltersUsers = (dataList, filter) =>
    filter.length === 0
        ? dataList
        : dataList.filter((e) =>
              // Apply filters users
              filter.find((k) => k === e.userId),
          );

// For multiple groups
const recursiveGrouping = (dataList, grouping, result, group, expandedGroups) => {
    if (grouping.length > 0) {
        const groupF = grouping[0];
        const groupedData = dataList.reduce((acc, val) => {
            if (!acc.find((e) => e[groupF + "Id"] === val[groupF + "Id"])) {
                acc.push({
                    ...group,
                    isGroup: true,
                    uniqueId: group.uniqueId + "-" + val[groupF + "Id"],
                    [groupF + "Id"]: val[groupF + "Id"],
                    [groupF + "Render"]: val[groupF + "Render"],
                });
            }
            return acc;
        }, []);
        groupedData.forEach((group) => {
            const mergedLines = [...dataList].filter(
                (e) =>
                    !Object.keys(group)
                        .filter((key) => key !== "uniqueId" && key.includes("Id"))
                        .map((key) => e[key] === group[key])
                        .some((e) => e === false),
            );

            if (mergedLines?.length) {
                result.push({
                    ...group,
                    mergedLines,
                });
            }
            if (grouping.length === 1) {
                if (expandedGroups.includes(group.uniqueId)) result.push(...mergedLines);
            } else {
                if (expandedGroups.includes(group.uniqueId))
                    recursiveGrouping(
                        dataList,
                        grouping.slice(1),
                        result,
                        Object.keys(group).reduce((acc, key) => {
                            if (key.includes("Id")) {
                                acc[key] = group[key];
                            }
                            return acc;
                        }, {}),
                        expandedGroups,
                    );
            }
        });
    } else {
        result.push(...dataList);
    }
};

// For groups
export const applyGroups = (dataList, grouping, expandedGroups) => {
    let result = [];
    recursiveGrouping(
        dataList,
        grouping
            // Hardcode : This for force the order of the grouping
            .sort((a, b) => {
                if (a === "user") return -1;
                else if (a === "project" && b === "task") return -1;
                else if (a === "task") return 1;
                else return 1;
            }),
        result,
        {
            uniqueId: "group",
        },
        expandedGroups,
    );
    return result;
};

// For Expanded details fields
export const applyDetails = (dataList, expandedLines) =>
    dataList.reduce((acc, val) => {
        const findedDetailIndex = expandedLines.findIndex((e) => e === val.uniqueId);
        if (findedDetailIndex !== -1) {
            acc.push(val);

            // acc.push({
            //     ...val,
            //     uniqueId: val.uniqueId + "-detail-pause-matin",
            //     taskRender: "Temps pause matin",
            //     isDetail: true,
            //     isSpecific: true,
            // });

            // if ( isRecovery)
            acc.push({
                ...val,
                uniqueId: val.uniqueId + "-detail-pause-midi",
                taskRender: "Temps pause midi",
                isDetail: true,
                isSpecific: true,
            });

            // INCASE Timesheet Journal / Afficher les champs personalisés en ligne de détail
            // const fields =
            // val?.mergedLines
            //     .map((e) => e.entry?.data)
            //     .flat()
            //     .filter(
            //         (v, i, a) =>
            //             a.findIndex((t) => t.title === v.title) === i
            //     ) || config.fields;
            // fields.forEach((detailData, i) => {
            //     acc.push({
            //         ...val,
            //         uniqueId: val.uniqueId + "-detail-" + detailData._id,
            //         taskRender: detailData.title,
            //         isDetail: true,
            //     });
            // });
        } else {
            acc.push(val);
        }
        return acc;
    }, []);

export const getRealEndDate = (startDate, endDate) => {
    // const startMoment = moment(startDate);
    // const endMoment = moment(endDate);

    // const isMidnight = startMoment
    //     .clone()
    //     .set({ hour: 23, minute: 59, second: 59 })
    //     .isBetween(startMoment, endMoment, null, "[]");

    return moment(endDate);
};
