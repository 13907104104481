import axios from "axios";

import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

import {
    downloadFileById,
    extractUrlFromFile,
    getFileExtension,
    getFileType,
    isImageFile,
    isVideoFile,
} from "helpers/file";
import { getMetaTitleByCode } from "helpers/text";
import { getMemberRoles } from "helpers/user";
import { usePreventModalCloseOnBackButton } from "helpers/window";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import DriveSidePanel from "components/drive/DriveSidePanel";
import DriveVersionsPanel from "components/drive/DriveVersionsPanel";
import Button from "components/input/Button";
import Spinner from "components/utils/Spinner";

import { PopupContext } from "layout/LayoutPopup";

import DocEngine from "./DocEngine";
import DocumentActions from "./DocumentActions";
import DocumentCompare from "./DocumentCompare";
import DocumentMobileBottomContent from "./DocumentMobileBottomContent";
import PdfEngine from "./PdfEngine/PdfEngine";

import { Button as AntdButton } from "antd";

export const innerScale = 5; // mdMedia ? 5 : 5;

const DocumentReader = ({
    fileId,
    commentId,
    closeReader,
    profile,
    refreshAction,
    isFromPage = false,
    isFromViewer = false,
    isFromPublic = false,
    isFromCompare = false,
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { runNotification } = useContext(PopupContext);

    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    const [loader, setLoader] = useState(false);

    const [userRoles, setUserRoles] = useState({});

    const [openedFileId, setOpenedFileId] = useState();
    const [file, setFile] = useState(null);

    // PDF
    const [scale, setScale] = useState(1 / innerScale);
    const [pdfDocument, setPdfDocument] = useState(null);
    const [pdfWidth, setPdfWidth] = useState(0);
    const [pdfHeight, setPdfHeight] = useState(0);

    const [searchedTerm, setSearchedTerm] = useState("");
    const [searchedOccurence, setSearchedOccurence] = useState(0);
    const [totalOccurences, setTotalOccurences] = useState(0);

    const [isShowComments, showComments] = useState(commentId ? true : false);
    const [isShowVersions, setIsShowVersions] = useState(false);
    const [isCompareDocuments, setIsCompareDocuments] = useState(false);

    const pdfContainerRef = useRef(null);

    const [toastShowed, setToastShowed] = useState(false);

    const [isCandidateToSvgEngine, setIsCandidateToSvgEngine] = useState(true);

    useEffect(() => {
        if (
            file?.versions?.length &&
            !isFromCompare &&
            !isFromViewer &&
            !toastShowed &&
            file?.isOldVersion
        ) {
            setToastShowed(true);
            runNotification({
                type: "warning",
                title: t("vousConsultezUneAncienneVersion"),
                content: (
                    <AntdButton
                        type="primary"
                        onClick={() => {
                            document
                                .getElementById("scroll-versions")
                                ?.scrollTo({
                                    top: 0,
                                    behavior: "smooth",
                                });
                            setOpenedFileId(
                                file.versions?.find(
                                    (e) => e.isOldVersion === false,
                                )?._id,
                            );
                        }}
                    >
                        {t("accederALaDerniereVersion")}
                    </AntdButton>
                ),
            });
        }
    }, [
        file?.versions,
        file?.isOldVersion,
        isFromCompare,
        isFromViewer,
        toastShowed,
        lgMedia,
        t,
    ]);

    useEffect(() => {
        setOpenedFileId(fileId);
    }, [fileId]);

    useEffect(() => {
        if (file) {
            const incrementViews = async () => {
                // On ajoute aux fichiers vus récemments
                if (profile) {
                    await axios.put(apiURL.addRecentlyViewedFile, {
                        id: file._id,
                        isFromPublic: fileId === file.publicToken,
                    });
                }

                // Incrémentation du nombre de vues du fichier
                axios.post(apiURL.incrementFileStats, {
                    type: "views",
                    id: file._id,
                });
            };

            incrementViews();

            if (getFileType(file) === "PDF") {
                updatePdfWidth();
            }

            const getMetaTitle = async () => {
                const titleRes = await getMetaTitleByCode("SEO_DOCUMENT");

                if (titleRes !== "")
                    document.title = titleRes + " - " + file.title;
            };

            // On update le titre que si on est sur la page de visionneuse d'un document (hors Drive)
            if (isFromPage) getMetaTitle();
        }
    }, [file, isFromPage, fileId, profile]);

    useEffect(() => {
        if (!lgMedia) showComments(false);
        else if (
            !file?.files &&
            file?.visas.filter((item) => item.visa).length > 0
        )
            showComments(true);
        else if (file?._id) {
            axios
                .post(apiURL.getFilesComments, {
                    start: 0,
                    files: [file._id],
                })
                .then(({ data }) => {
                    if (data?.length && !isFromCompare) {
                        showComments(true);
                    }
                });
        }
    }, [lgMedia, file]);

    useEffect(() => {
        if (profile && file) {
            const getRoles = async () => {
                const resRoles = await getMemberRoles(
                    file.project,
                    profile.user,
                );

                setUserRoles(resRoles);
            };

            if (profile) getRoles();
        }
    }, [file, profile]);

    useEffect(() => {
        const scrollToSearched = async () => {
            for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
                const page = await pdfDocument.getPage(pageNum);
                const textContent = await page.getTextContent();

                for (const item of textContent.items) {
                    if (item.str.includes(searchedTerm)) {
                        const results =
                            document.getElementsByClassName("searched-match");

                        if (results.length > 0) {
                            setTotalOccurences(results.length);

                            return results[searchedOccurence].scrollIntoView({
                                block: "center",
                                inline: "nearest",
                            });
                        }
                    }
                }
            }
        };

        if (pdfDocument && searchedTerm.length > 0) scrollToSearched();
        else {
            setSearchedOccurence(0);
            setTotalOccurences(0);
        }
    }, [searchedTerm, searchedOccurence, pdfDocument]);

    const getFile = useCallback(
        async (fileId) => {
            setLoader(true);
            try {
                const resFile = await axios.get(
                    apiURL.getProjectFileById + fileId,
                );
                if (resFile && resFile.data) setFile(resFile.data);
            } catch (e) {
                if (closeReader) closeReader();
                else navigate("/home");
            }
            setLoader(false);
        },
        [navigate, t],
    );

    useEffect(() => {
        if (openedFileId) {
            setTimeout(() => {
                getFile(openedFileId);
            }, 1000);
        }
    }, [openedFileId, getFile]);

    useEffect(() => {
        if (file && getFileType(file) === "URL") {
            const startRedirection = async () => {
                const urlRes = await axios.get(file.fileURL);

                if (urlRes?.data) {
                    const url = await extractUrlFromFile(urlRes?.data);
                    if (url) {
                        window.open(url, "_blank");
                        if (closeReader) closeReader();
                    }
                }
            };

            startRedirection();
        }
    }, [file]);

    const updatePdfWidth = () => {
        if (!pdfContainerRef.current) return;

        setPdfWidth(pdfContainerRef.current.clientWidth);
        setPdfHeight(pdfContainerRef.current.clientHeight);
    };

    const highlightPattern = (text, pattern) => {
        return text.replace(
            pattern,
            (value) => `<mark class="searched-match">${value}</mark>`,
        );
    };

    const textRenderer = useCallback(
        (textItem) => highlightPattern(textItem.str, searchedTerm),
        [searchedTerm],
    );

    const incrementSearchedOccurence = (e, isDecrement = false) => {
        const results = document.getElementsByClassName("searched-match");
        if (results.length > 0) {
            if (!isDecrement) {
                // Increment
                if (results[searchedOccurence + 1])
                    setSearchedOccurence(searchedOccurence + 1);
                else setSearchedOccurence(0);
            } else {
                // Decrement
                if (results[searchedOccurence - 1])
                    setSearchedOccurence(searchedOccurence - 1);
                else setSearchedOccurence(results.length - 1);
            }
        }
    };

    const handleRefresh = () => {
        getFile();
        refreshAction(true);
    };

    usePreventModalCloseOnBackButton(!!fileId, closeReader);

    if (isCompareDocuments)
        return (
            <DocumentCompare
                setIsCompareDocuments={setIsCompareDocuments}
                fileProps={{
                    file: file,
                    setLoader: setLoader,
                    scale: scale,
                    pdfWidth: pdfWidth,
                    textRenderer: textRenderer,
                    setPdfDocument: setPdfDocument,
                    closeReader: closeReader,
                }}
            />
        );

    return file ? (
        <div
            id="document-reader"
            style={{
                userSelect: "none",
            }}
            className={
                "z-[99] flex flex-col bg-black/95 pointer-events-auto" +
                (isFromCompare || isFromViewer
                    ? " relative h-full w-full"
                    : " fixed w-screen h-screen left-0 top-0")
            }
        >
            <DocumentActions
                file={file}
                scale={scale}
                setScale={setScale}
                closeReader={closeReader}
                showComments={showComments}
                profile={profile}
                refreshAction={refreshAction}
                searchedTerm={searchedTerm}
                userRoles={userRoles}
                setSearchedTerm={setSearchedTerm}
                isFromViewer={isFromViewer || isFromPublic}
                incrementSearchedOccurence={incrementSearchedOccurence}
                countOccurences={
                    totalOccurences > 0 &&
                    searchedOccurence + 1 + "/" + totalOccurences
                }
                setIsShowVersions={setIsShowVersions}
                isShowVersions={isShowVersions}
                isShowComments={isShowComments}
                isCompareDocuments={isCompareDocuments}
                setIsCompareDocuments={setIsCompareDocuments}
            />
            <div className="flex flex-grow overflow-hidden">
                {isShowVersions && userRoles.READER && lgMedia && (
                    <div className="flex justify-center w-[350px] py-[50px] h-full">
                        <DriveVersionsPanel
                            fileId={openedFileId}
                            item={file}
                            currentProject={file.project}
                            closePanel={setIsShowVersions}
                            refreshAction={handleRefresh}
                            profile={profile}
                            userRoles={userRoles}
                            showInfosInPanel={setOpenedFileId}
                            setIsShowVersions={setIsShowVersions}
                        />
                    </div>
                )}

                <div
                    className={
                        " flex-1 " +
                        (getFileType(file) === "PDF" ? " overflow-y-auto " : "")
                    }
                >
                    <div
                        ref={pdfContainerRef}
                        className={
                            "m-auto overflow-hidden " +
                            (getFileType(file) !== "PDF" && !isVideoFile(file)
                                ? " max-w-[800px]"
                                : " h-full flex flex-1") +
                            (isFromCompare || isFromViewer ? " " : " h-full")
                        }
                    >
                        {Constants.notReadableFilesTypes.includes(
                            getFileExtension(file.file),
                        ) ? (
                            <div className="h-[calc(100vh-100px)] md:h-[calc(100vh-170px)] flex">
                                <div className="card m-auto p-4">
                                    {t("renduIndisponiblePourCeTypeDeFichier")}

                                    {(file.isPublicDownload ||
                                        userRoles.READER) && (
                                        <div className="mt-4 text-center">
                                            <div className="inline-block">
                                                <Button
                                                    text={t("telecharger")}
                                                    onClick={(e) =>
                                                        downloadFileById(
                                                            file._id,
                                                            file.title,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : /*: file && isCandidateToSvgEngine ? (
                            <SvgEngine
                                file={file}
                                onConversionError={() =>
                                    setIsCandidateToSvgEngine(false)
                                }
                            />
                        ) */
                        getFileType(file) === "PDF" ? (
                            <PdfEngine
                                file={file}
                                pdfUrl={file.fileURL}
                                setLoader={setLoader}
                                scale={scale}
                                closeReader={closeReader}
                                setScale={setScale}
                                pdfWidth={pdfWidth}
                                pdfHeight={pdfHeight}
                            />
                        ) : isImageFile(file) ? (
                            <div className="h-[calc(100vh-100px)] md:h-[calc(100vh-170px)] flex overflow-auto">
                                <img
                                    src={file.fileURL}
                                    alt={file.title}
                                    className="m-auto"
                                />
                            </div>
                        ) : isVideoFile(file) ? (
                            <div className="h-[calc(100vh-100px)] md:h-[calc(100vh-170px)] flex m-auto">
                                <video PanPinchControls autoPlay>
                                    <source
                                        src={file.fileURL}
                                        type="video/mp4"
                                    />
                                </video>
                            </div>
                        ) : (
                            <DocEngine file={file} />
                        )}
                    </div>
                </div>

                {isShowComments && lgMedia && (
                    <div className="flex justify-center w-[380px] px-[15px] py-[50px]  h-full">
                        <DriveSidePanel
                            item={file}
                            currentProject={file.project}
                            closePanel={showComments}
                            refreshAction={handleRefresh}
                            profile={profile}
                            userRoles={userRoles}
                            isFromDocument={true}
                            commentId={commentId}
                        />
                    </div>
                )}

                {!lgMedia &&
                    ((isShowVersions && userRoles.READER) ||
                        isShowComments) && (
                        <DocumentMobileBottomContent
                            closeContent={() => {
                                setIsShowVersions(false);
                                showComments(false);
                            }}
                            content={
                                isShowVersions && profile ? (
                                    <DriveVersionsPanel
                                        fileId={openedFileId}
                                        item={file}
                                        currentProject={file.project}
                                        closePanel={setIsShowVersions}
                                        refreshAction={handleRefresh}
                                        profile={profile}
                                        userRoles={userRoles}
                                        showInfosInPanel={setOpenedFileId}
                                        setIsShowVersions={setIsShowVersions}
                                    />
                                ) : (
                                    <DriveSidePanel
                                        item={file}
                                        currentProject={file.project}
                                        closePanel={showComments}
                                        refreshAction={handleRefresh}
                                        profile={profile}
                                        userRoles={userRoles}
                                        isFromDocument={true}
                                    />
                                )
                            }
                        />
                    )}
            </div>

            {loader && <Spinner isSmall={isFromViewer} />}
        </div>
    ) : (
        <Spinner isSmall={isFromViewer} />
    );
};

export default DocumentReader;
