import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getAppImage } from "helpers/image";
import { usePreventModalCloseOnBackButton } from "helpers/window";

import DriveSideMenu from "components/drive/DriveSideMenu";
import IconButton from "components/input/IconButton";

import NavBarMenu from "./NavBarMenu";

import { theme } from "antd";

const SideMenu = ({
    showDriveMenu = false,
    profile,
    currentProject,
    onUpload,
    refreshAction,
    userRoles,
    customerConfig,
    toolView,
}) => {
    const { t } = useTranslation();

    const [isSideMenuVisible, setSideMenuVisible] = useState(false);

    const toggleSideMenu = () => {
        setSideMenuVisible(!isSideMenuVisible);
    };

    const closeSideMenu = () => {
        setSideMenuVisible(false);
    };

    usePreventModalCloseOnBackButton(isSideMenuVisible, closeSideMenu);

    const { token } = theme.useToken();

    return (
        <Fragment>
            <IconButton onClick={toggleSideMenu} icon="menu" isNeutral={true} />
            <div
                className={
                    "fixed z-30 top-0 w-[300px] h-screen flex flex-col" +
                    (isSideMenuVisible
                        ? " transition-all ease-in-out duration-50 left-0"
                        : " left-[-300px]")
                }
                style={{
                    backgroundColor: showDriveMenu
                        ? token["purple-10"]
                        : token.colorBgBase,
                    height: "100vh",
                    overflowY: "auto",
                }}
            >
                <div className="flex justify-between items-center p-1 pl-2 border-b border-slate-300">
                    <IconButton
                        onClick={toggleSideMenu}
                        icon="menu"
                        isNeutral={true}
                    />
                    <Link to={profile ? "/drive" : "/home"}>
                        <img
                            src={
                                customerConfig?.logo
                                    ? getAppImage(
                                          "customers/" + customerConfig.logo,
                                      )
                                    : "/img/logo-big.png"
                            }
                            alt={t("applicationTitle")}
                            className="w-[100px] mr-2"
                        />
                    </Link>
                </div>

                {!profile && (
                    <NavBarMenu
                        profile={profile}
                        isFromMobile={true}
                        toggleSideMenu={toggleSideMenu}
                    />
                )}

                {showDriveMenu && (
                    <DriveSideMenu
                        profile={profile}
                        onlyContent={true}
                        closeSideMenu={closeSideMenu}
                        currentProject={currentProject}
                        onUpload={onUpload}
                        refreshAction={refreshAction}
                        userRoles={userRoles}
                        toolView={toolView}
                    />
                )}
            </div>

            {isSideMenuVisible && (
                <Fragment>
                    <div
                        className="opacity-50 fixed inset-0 z-20 bg-black"
                        onClick={(e) => setSideMenuVisible(false)}
                    ></div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default SideMenu;
