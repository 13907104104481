import { PDFViewer, pdf } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import { getFileExtensionIcon } from "helpers/image";

import TemplatePDF from "./TemplatePDF";

import { Avatar } from "antd";

export const TabExportToPDF = (props) => {
    const { t } = useTranslation();

    return {
        key: "pdf",
        style: { height: "50vh" },
        label: `${t("format")} PDF`,
        icon: (
            <Avatar
                shape="square"
                size={32}
                src={getFileExtensionIcon({
                    name: "file.pdf",
                })}
            />
        ),
        children: <PreviewExportToPDF {...props} />,
    };
};

export const functionExportToPDF = async (props) => {
    await pdf(<TemplatePDF {...props} />)
        .toBlob()
        .then((blob) => {
            let exportUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = exportUrl;
            a.download = props.title;
            a.click();
            window.URL.revokeObjectURL(exportUrl);
        });
};

export const PreviewExportToPDF = (props) => (
    <PDFViewer width="100%" height="100%">
        <TemplatePDF {...props} />
    </PDFViewer>
);
