import axios from "axios";

import { useEffect, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";

import { addDaysToDate, getDaysBetweenTwoDates } from "helpers/date";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";

import VisaTreatment from "./VisaTreatment";

import { Card, theme } from "antd";

const VisaBadge = ({
    visa,
    user,
    members,
    file,
    visaDatasToSave,
    setVisaDatasToSave,
}) => {
    const { t } = useTranslation();

    const actionsRef = useRef(null);

    const [visaDatas, setVisaDatas] = useState(visa);

    const [textColor, setTextColor] = useState("");
    const [borderColor, setBorderColor] = useState("");
    const [bgColor, setBgColor] = useState("");

    const [isUserVisa, setUserVisa] = useState(false);
    const [isActionsOpen, setIsActionsOpen] = useState(false);
    const [isTreatmentOpen, setIsTreatmentOpen] = useState(false);

    const [loader, setLoader] = useState(false);
    const [isTreated, setIsTreated] = useState(false);
    const [showConfettis, setShowConfettis] = useState(false);

    const { token } = theme.useToken();

    useEffect(() => {
        const deadlineDate = addDaysToDate(
            visaDatas.deadlineDays,
            visaDatas.startedDate,
        );

        // Règles selon lesquelles on détermine la couleur / le statut du visa
        if (getDaysBetweenTwoDates(deadlineDate, Date.now()) > 1) {
            setTextColor("text-primary");
            setBorderColor("border-primary");
            setBgColor("bg-transparent");
        } else if (
            getDaysBetweenTwoDates(deadlineDate, Date.now()) <= 1 &&
            getDaysBetweenTwoDates(deadlineDate, Date.now()) >= 0
        ) {
            setTextColor("text-yellow-400");
            setBorderColor("border-yellow-400");
            setBgColor("bg-transparent");
        } else if (getDaysBetweenTwoDates(deadlineDate, Date.now()) < 0) {
            setTextColor("text-red-500");
            setBorderColor("border-red-500");
            setBgColor("bg-transparent");
        }

        if (visaDatasToSave?.status?.isApproved) {
            setTextColor("text-white");
            setBorderColor("border-emerald-400");
            setBgColor("bg-emerald-400");
        } else if (visaDatasToSave?.status?.isRefused) {
            setTextColor("text-white");
            setBorderColor("border-red-500");
            setBgColor("bg-red-500");
        } else if (visaDatasToSave?.status?.isCommented) {
            setTextColor("text-white");
            setBorderColor("border-yellow-400");
            setBgColor("bg-yellow-400");
        } else if (visaDatasToSave?.status?.isNotConcerned) {
            setTextColor("text-white");
            setBorderColor("border-fuchsia-500");
            setBgColor("bg-fuchsia-500");
        } else if (visaDatas.approvedDate) {
            setTextColor("text-white");
            setBorderColor("border-emerald-400");
            setBgColor("bg-emerald-400");
        } else if (visaDatas.refusedDate) {
            setTextColor("text-white");
            setBorderColor("border-red-500");
            setBgColor("bg-red-500");
        } else if (visaDatas.commentedDate) {
            setTextColor("text-white");
            setBorderColor("border-yellow-400");
            setBgColor("bg-yellow-400");
        } else if (visaDatas.notConcernedDate) {
            setTextColor("text-white");
            setBorderColor("border-fuchsia-500");
            setBgColor("bg-fuchsia-500");
        }
    }, [visaDatas, visaDatasToSave]);

    useEffect(() => {
        setVisaDatas(visa);
    }, [visa]);

    useEffect(() => {
        if (file && user && visa) {
            const checkVisa = async () => {
                const checkVisaRes = await axios.get(
                    apiURL.checkVisaUserAssociated + visa.visa._id,
                );

                if (
                    !visaDatas.approvedDate &&
                    !visaDatas.refusedDate &&
                    checkVisaRes?.data &&
                    setVisaDatasToSave
                ) {
                    setVisaDatasToSave({ visa: visaDatas });
                    setUserVisa(true);
                }
            };

            checkVisa();
        }
    }, [file]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (visaDatasToSave?.status && isTreatmentOpen === null) {
            if (
                visaDatasToSave.status.isApproved ||
                visaDatasToSave.status.isRefused ||
                visaDatasToSave.status.isCommented ||
                visaDatasToSave.status.isNotConcerned
            ) {
                setVisaDatasToSave({ visa: visaDatas });
            }
        }
    }, [isTreatmentOpen]);

    useEffect(() => {
        if (isTreated) setShowConfettis(true);
    }, [isTreated]);

    const handleClickOutside = (e) => {
        if (actionsRef.current) {
            if (!actionsRef.current.contains(e.target)) setIsActionsOpen(false);
        }
    };

    const handleVisaAction = async (actionType) => {
        let updatedDatas = {
            visa: visaDatas,
            status: {
                isApproved: actionType === "APPROVED",
                isRefused: actionType === "REFUSED",
                isCommented: actionType === "COMMENTED",
                isNotConcerned: actionType === "NOT_CONCERNED",
            },
        };

        setVisaDatasToSave(updatedDatas);
        setIsActionsOpen(false);
        setIsTreatmentOpen(true);
    };

    return (
        <div className="relative whitespace-nowrap" ref={actionsRef}>
            <div
                onClick={(e) =>
                    isUserVisa &&
                    !isTreated &&
                    !file?.isOldVersion &&
                    setIsActionsOpen(!isActionsOpen)
                }
                className={
                    "inline-block border-2 rounded-md text-2xs font-bold " +
                    textColor +
                    " " +
                    borderColor +
                    " " +
                    bgColor +
                    (isUserVisa && !isTreated && !file?.isOldVersion
                        ? " pl-1 hover:cursor-pointer"
                        : " px-1") +
                    (file?.isOldVersion ? " opacity-50" : "")
                }
            >
                <div className="flex flex-row">
                    <div className="my-auto">{visaDatas.visa.title}</div>
                    {isUserVisa && !isTreated && !file?.isOldVersion && (
                        <div className="h-[16px] translate-y-[-2px]">
                            <MaterialIcons name="expand_more" size="sm" />
                        </div>
                    )}
                </div>
            </div>

            {isTreatmentOpen ? (
                <Card
                    style={{ position: "absolute", zIndex: 1, minWidth: 250 }}
                    styles={{
                        body: {
                            padding: token.paddingSM,
                        },
                    }}
                >
                    <VisaTreatment
                        visaDatas={visaDatasToSave}
                        members={members}
                        user={user}
                        file={file}
                        loader={loader}
                        setLoader={setLoader}
                        setIsTreated={setIsTreated}
                        setIsTreatmentOpen={setIsTreatmentOpen}
                    />
                </Card>
            ) : (
                isActionsOpen && (
                    <Card
                        style={{ position: "absolute", zIndex: 1 }}
                        styles={{
                            body: {
                                padding: 0,
                            },
                        }}
                    >
                        <ul className="text-sm">
                            <li>
                                <button
                                    type="button"
                                    onClick={(e) =>
                                        handleVisaAction("APPROVED")
                                    }
                                    className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left"
                                >
                                    {t("approuve")}
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    onClick={(e) => handleVisaAction("REFUSED")}
                                    className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left"
                                >
                                    {t("refuse")}
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    onClick={(e) =>
                                        handleVisaAction("COMMENTED")
                                    }
                                    className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left"
                                >
                                    {t("commentaire")}
                                </button>
                            </li>
                            <li>
                                <button
                                    type="button"
                                    onClick={(e) =>
                                        handleVisaAction("NOT_CONCERNED")
                                    }
                                    className="p-2 hover:bg-blue-50 hover:text-primary w-full text-left"
                                >
                                    {t("nonConcerne")}
                                </button>
                            </li>
                        </ul>
                    </Card>
                )
            )}

            {showConfettis && (
                <ConfettiExplosion
                    force={0.5}
                    duration={3000}
                    particleCount={40}
                    width={500}
                    zIndex={100}
                    onAnimationEnd={(e) => setShowConfettis(false)}
                />
            )}
        </div>
    );
};

export default VisaBadge;
