import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { sendEmail } from "helpers/email";
import { checkEmailExist, fieldValidation } from "helpers/fieldValidation";
import { generateRandomToken } from "helpers/token";
import { getUserByEmail } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import Button from "components/input/Button";
import TextField from "components/input/TextField";

function ResetPassword({ showResetForm }) {
    const { t } = useTranslation();

    const [email, setEmail] = useState("");
    const [emailSended, setEmailSended] = useState(false);

    const [loading, setLoading] = useState(false);

    const [fieldsError, setFieldsError] = useState({
        email: "",
    });

    const onChange = (e) => {
        setEmail(e.target.value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            const resValidation = await fieldsValidation();

            if (resValidation === 0) {
                // Envoi de l'email de recup du mot de passe
                // Recup du user pour passer son pseudo

                try {
                    setLoading(true);

                    const user = await getUserByEmail(email);

                    if (user) {
                        let token = generateRandomToken(20);

                        if (token) {
                            const content = {
                                pseudo: user.firstname + " " + user.lastname,
                                token,
                            };

                            await sendEmail(
                                apiURL.emailResetPassword,
                                email,
                                content,
                            )
                                .then((res) => {
                                    if (res === true) {
                                        setEmailSended(true);
                                    } else
                                        toastAlert(
                                            "error",
                                            t(
                                                "lEmailNaPasPuEtreEnvoyeVeuillezReesseyaerPlusTard",
                                            ),
                                        );
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    toastAlert(
                                        "error",
                                        t(
                                            "lEmailNaPasPuEtreEnvoyeVeuillezReesseyaerPlusTard",
                                        ),
                                    );
                                });
                        } else {
                            toastAlert(
                                "error",
                                t(
                                    "lEmailNaPasPuEtreEnvoyeVeuillezReesseyaerPlusTard",
                                ),
                            );
                        }
                    } else {
                        toastAlert(
                            "error",
                            t("cetteAdresseEmailNestPasUtilisee"),
                        );
                    }

                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastAlert(
                        "error",
                        t("lEmailNaPasPuEtreEnvoyeVeuillezReesseyaerPlusTard"),
                    );
                }
            }
        } catch (err) {}
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        validation = fieldValidation(email, "required|email");
        updatedFieldsError.email = validation;
        if (validation !== "") nbErrors++;

        setFieldsError({ ...fieldsError, updatedFieldsError });

        // Si aucune erreur classique sur le champ email, on check qu'il n'existe pas déjà en BDD

        if (updatedFieldsError.email === "") {
            try {
                const res = await checkEmailExist(email);

                // Si l'email n'existe pas, on affiche un message d'erreur

                if (res === "") {
                    updatedFieldsError.email = t(
                        "cetteAdresseEmailNestPasUtilisee",
                    );
                    setFieldsError({
                        ...fieldsError,
                        email: updatedFieldsError.email,
                    });
                    nbErrors++;

                    return nbErrors;
                } else {
                    return nbErrors;
                }
            } catch (err) {
                updatedFieldsError.email = "";
                setFieldsError({
                    ...fieldsError,
                    email: updatedFieldsError.email,
                });
            }
        }

        return nbErrors;
    };

    return (
        <Fragment>
            <div className="text-xl sm:text-2xl inline-block pb-1 align-middle">
                {t("modifierMonMotDePasse")}
            </div>

            <div className="text-left">
                {emailSended === false ? (
                    <Fragment>
                        <h3 className="mt-6 mb-6">
                            {t(
                                "renseignezVotreAdresseEmailPourRecevoirLeLienDeChangementDeMotDePasse",
                            )}
                        </h3>

                        <form
                            className="m-auto max-w-full sm:max-w-[500px]"
                            onSubmit={(e) => onSubmit(e)}
                        >
                            <TextField
                                name="email"
                                text={t("email")}
                                value={email}
                                maxLength={Constants.maxLengthEmail}
                                onChange={onChange}
                                isRequired={true}
                                errorMsg={fieldsError.email}
                            />

                            <div className="mt-8 flex flex-row">
                                <div className="w-1/2 mr-2">
                                    <Button
                                        type="submit"
                                        text={t("annuler")}
                                        onClick={(e) => showResetForm(false)}
                                        isDisabled={loading}
                                        isReversed={true}
                                    />
                                </div>
                                <div className="w-1/2 ml-2">
                                    <Button
                                        type="submit"
                                        text={t("envoyer")}
                                        onClick={onSubmit}
                                        isDisabled={loading}
                                    />
                                </div>
                            </div>
                        </form>
                    </Fragment>
                ) : (
                    emailSended === true && (
                        <Fragment>
                            <div className="text-center">
                                <i
                                    className="material-icons notranslate text-primary mt-6"
                                    style={{ fontSize: "6rem" }}
                                >
                                    mail
                                </i>
                            </div>

                            <p className="mt-6">
                                {t("unEmailAEteEnvoyeALadresse")} <b>{email}</b>
                                .{" "}
                                {t(
                                    "cliquezSurLeLienQuiSyTrouvePourModifierVotreMotDePasse",
                                )}
                                .
                            </p>
                        </Fragment>
                    )
                )}
            </div>
        </Fragment>
    );
}

export default ResetPassword;
