import { defaultFields, useFiltersStore } from "providers/ProviderFilters";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { useFormsStore } from "components/forms/FormView";

import { FilterItem } from "./FiltersSidePanel";

import { Checkbox, Divider, Flex, Select, Spin } from "antd";

export default function FilterByForm() {
    const { listAnswers } = useFormsStore();
    const { filters } = useFiltersStore();
    const filterPage = filters[window.location.pathname];

    if (!listAnswers) return <Spin />;
    return (
        <FilterItem
            label={"Formulaires et champs"}
            filterActive={filterPage?.forms || filterPage?.formField}
            children={
                <Flex vertical gap="small">
                    <SearchForms />
                    <RenderFiedsCheckboxes />
                </Flex>
            }
        />
    );
}

export const SearchForms = () => {
    const { listTemplates } = useFormsStore();
    const { filters, setFilters } = useFiltersStore();
    const filterPage = filters[window.location.pathname];

    if (!listTemplates)
        return (
            <Spin
                style={{
                    marginLeft: "auto",
                }}
            />
        );
    return (
        <Select
            size="large"
            style={{
                width: "100%",
                maxWidth: 400,
                marginLeft: "auto",
            }}
            placeholder="Filtrer par formulaire"
            showSearch
            allowClear
            options={listTemplates
                .filter((template) => template.active)
                .map((template) => ({
                    label: template.title,
                    value: template._id,
                    data: template,
                }))}
            value={filterPage?.forms?._id}
            onChange={(value, record) => {
                setFilters({
                    forms: value ? record.data : null,
                });
                document.activeElement.blur();
            }}
            optionRender={(record) => (
                <Flex align="start" gap="small">
                    <MaterialIcons name="docs" size="sm" />
                    <Text>{record.label}</Text>
                </Flex>
            )}
            labelRender={(props) => (
                <Flex align="start" gap="small">
                    <MaterialIcons type="primary" name="docs" size="sm" />
                    <Text bold color="primary" ellipsis>
                        {props.label}
                    </Text>
                </Flex>
            )}
        />
    );
};

export const RenderFiedsCheckboxes = () => {
    const { filters, setFilters } = useFiltersStore();
    const filterPage = filters[window.location.pathname];
    const fields =
        filterPage?.forms?.fields?.filter(
            (field) =>
                field.type !== "TITLE" &&
                field.type !== "SUBTITLE" &&
                field.type !== "PARAGRAPH" &&
                field.type !== "SEPARATOR" &&
                field.type !== "PICTURE" &&
                field.type !== "SIGNATURE" &&
                field.type !== "ZONE",
        ) || [];

    const filterForms = filterPage?.formColumns || [];

    return (
        <>
            <Checkbox.Group
                style={{
                    flexDirection: "column",
                }}
                onChange={(value) => {
                    const newFields = [...defaultFields, ...fields].filter((field) => value.includes(field._id));
                    setFilters({
                        formColumns: newFields,
                    });
                }}
                value={filterForms?.map((field) => field._id)}>
                {defaultFields.map((field, i) => (
                    <Checkbox key={i} value={field._id}>
                        {field.title}
                    </Checkbox>
                ))}

                <Divider style={{ margin: 10 }} />

                {filterPage?.forms?.fields ? (
                    fields.map((field, i) => (
                        <Checkbox key={i} value={field._id}>
                            {field.title}
                        </Checkbox>
                    ))
                ) : (
                    <Text>Veuillez filtrer par formulaire</Text>
                )}
            </Checkbox.Group>
        </>
    );
};

export const applyFilterByFieldForm = (data, filters) => {
    if (!filters) return data;

    if (filters.forms) {
        return data.filter((e) => e.template?._id === filters.forms._id);
    }
    return data;
};
