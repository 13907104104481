import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { createContext } from "react";

export const AppContext = createContext(null);

export default function ProviderApp() {
    return null;
}

export const useAppStore = create(
    persist(
        (set, get) => ({
            // DATA
            loading: true,
            data: null,
            theme: "light",
            menuWidth: 275,
            viewMode: "grid", // "grid" || "list"

            // ACTIONS

            // Toggle le theme dark de l'app
            toggleThemeApp: async () => {
                set({ theme: get().theme === "light" ? "dark" : "light" });
            },

            // Change la lagreur de la sidebar quand elle est resize
            setMenuWidth: async (newWidth) => {
                set({ menuWidth: newWidth });
            },

            // Change la vue de la liste des données pour toute l'app
            toggleViewMode: async () => {
                set({ viewMode: get().viewMode === "grid" ? "list" : "grid" });
            },
        }),
        {
            name: "app-preferences",
            storage: createJSONStorage(() => localStorage),
        },
    ),
);
