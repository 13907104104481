import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Form, Select } from "antd";

const FormCustomer = ({ clientsList }) => {
    const { t } = useTranslation();

    const form = Form.useFormInstance();
    const customerLocked = form.getFieldValue("customerLocked");

    return (
        <Form.Item
            name="customer"
            label={
                <Text size="large" bold>
                    {t("client")}
                </Text>
            }
            getValueProps={(value) => {
                return clientsList ? { value: value } : "";
            }}>
            <Select
                allowClear
                showSearch
                disabled={customerLocked}
                placeholder={t("selectionnerUnClient")}
                options={clientsList?.map((e) => ({
                    value: e._id,
                    label: e.name,
                }))}
            />
        </Form.Item>
    );
};

export default FormCustomer;
