import axios from "axios";
import moment from "moment";

import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import { getFileExtension, isIfcFile, isPcdFile } from "helpers/file";
import { debounce } from "helpers/input";
import { capitalize } from "helpers/string";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import DocumentReader from "components/documents/DocumentReader";
import { ExtensionImageContent } from "components/project/FileImage";

import { DriveToolsContext } from "./Drive";

import { AutoComplete, Collapse, Flex, Input } from "antd";

export default function SearchBar() {
    const { t } = useTranslation();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const { userProfile, refreshAction } = useContext(DriveToolsContext);

    const [isRenderDocument, setisRenderDocument] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropDownOpen, setisDropDownOpen] = useState(false);
    const [searchResults, setSearchResults] = useState({
        recents: [],
        folders: [],
        files: [],
        pages: [],
    });

    const onSearch = (value) => {
        if (!value) {
            axios
                .get(apiURL.getProfileRecents)
                .then(({ data }) => {
                    setSearchResults({
                        recents: data.slice(0, 12),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
            // INCASE get recents by profile
            // setSearchResults({
            //     recents: [
            //         ...userProfile.recentlyViewed,
            //         ...userProfile.recentlyOpened,
            //     ].slice(0, 10),
            // });
            // setIsLoading(false);
        } else
            axios
                .post(apiURL.searchProject, {
                    terms: value.toLowerCase(),
                })
                .then(({ data }) => {
                    setSearchResults({
                        folders: data.filter((e) => e.files),
                        files: data.filter((e) => !e.files),
                        pages: [],
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const options = useMemo(() => {
        let options = [];
        if (searchResults.recents?.length)
            options.push({
                label: (
                    <Text bold type="secondary">
                        {capitalize(t("recents"))}
                    </Text>
                ),
                options: searchResults.recents.map((e) => ({
                    ...e,
                    key: e._id,
                    label: (
                        <RenderLine
                            setisRenderDocument={setisRenderDocument}
                            {...e}
                            key={e._id}
                        />
                    ),
                })),
            });
        if (searchResults.folders?.length)
            options.push({
                label: (
                    <Text bold type="secondary">
                        {capitalize(t("dossiers"))}
                    </Text>
                ),
                options: searchResults.folders.map((e) => ({
                    ...e,
                    key: e._id,
                    label: (
                        <RenderLine
                            setisRenderDocument={setisRenderDocument}
                            {...e}
                            key={e._id}
                        />
                    ),
                })),
            });
        if (searchResults.files?.length)
            options.push({
                label: (
                    <Text bold type="secondary">
                        {capitalize(t("fichiers"))}
                    </Text>
                ),
                options: searchResults.files.map((e) => ({
                    ...e,
                    key: e._id,
                    label: (
                        <RenderLine
                            setisRenderDocument={setisRenderDocument}
                            {...e}
                            key={e._id}
                        />
                    ),
                })),
            });
        if (searchResults.pages?.length)
            options.push({
                label: capitalize(t("pages")),
                options: searchResults.pages,
            });
        return options;
    }, [searchResults]);

    const deboundedHandleInput = useCallback(
        debounce((e) => {
            onSearch(e);
        }, 800),
        [debounce],
    );

    return (
        <div
            id="search-bar"
            style={{
                flex: 1,
                maxWidth: 500,
                height: 40,
            }}
        >
            {isRenderDocument && (
                <DocumentReader
                    fileId={isRenderDocument}
                    closeReader={() => setisRenderDocument(false)}
                    profile={userProfile}
                    refreshAction={refreshAction}
                />
            )}
            <AutoComplete
                style={{
                    width: "100%",
                }}
                options={options}
                virtual={true}
                onChange={(e) => {
                    setIsLoading(true);
                    deboundedHandleInput(e);
                }}
                onFocus={(e) => {
                    if (!e.target.value) {
                        setIsLoading(true);
                        onSearch("");
                    }
                }}
                listHeight={600}
                dropdownStyle={
                    mdMedia
                        ? null
                        : {
                              width: "96%",
                              left: "2%",
                          }
                }
                open={isDropDownOpen}
                onDropdownVisibleChange={(e, v) => {
                    setisDropDownOpen(e);
                }}
            >
                <Input
                    addonAfter={
                        isLoading ? (
                            <MaterialIcons
                                name="progress_activity"
                                spin
                                type="primary"
                            />
                        ) : isDropDownOpen ? (
                            <MaterialIcons
                                onMouseDown={(e) => {
                                    setisDropDownOpen(false);
                                }}
                                name="close"
                            />
                        ) : (
                            <MaterialIcons name="search" />
                        )
                    }
                    size="large"
                    placeholder={
                        mdMedia
                            ? t("rechercherParMotsClesOuUtilisateurs")
                            : t("rechercher")
                    }
                    onClear={() => {
                        document.querySelector("#search-bar input")?.blur();
                        setisDropDownOpen(false);
                    }}
                    allowClear
                />
            </AutoComplete>
        </div>
    );
}

const RenderLine = ({
    title,
    _id,
    updatedDate,
    parentProject,
    projects,
    project,
    files,
    setisRenderDocument,
    ...data
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpen, setisOpen] = useState(false);
    const type = files ? "folder" : "file";

    return (
        <div
            onClick={(e) => {
                document.querySelector("#search-bar input")?.blur();
                if (e.target.closest("a")) return;

                if (type === "folder") {
                    navigate(`/drive/project/${_id}`);
                } else if (type === "file") {
                    if (isIfcFile(data) || isPcdFile(data)) {
                        window.open("/viewer/" + _id, "_blank");
                    } else setisRenderDocument(_id);
                }
            }}
            onMouseEnter={() => setisOpen(true)}
            onMouseLeave={() => setisOpen(false)}
        >
            <Collapse
                bordered={false}
                ghost
                activeKey={isOpen ? _id : ""}
                onChange={(e) => setisOpen(!isOpen)}
                style={{
                    padding: 0,
                }}
                className="search-item"
                expandIcon={() => null}
                // expandIconPosition="end"
                items={[
                    {
                        key: _id,
                        children:
                            type === "folder" ? (
                                <Flex align="center" gap={6}>
                                    {parentProject?.title && (
                                        <Link
                                            to={`/drive/project/${parentProject?._id}`}
                                        >
                                            <Flex align="center" gap={6}>
                                                <MaterialIcons name="folder" />
                                                <Text size="sm">
                                                    {parentProject?.title}
                                                </Text>
                                            </Flex>
                                        </Link>
                                    )}
                                    {/* <ul>
                                        {projects.map((e) => (
                                            <li>
                                                <Link
                                                    to={`/drive/project/${e._id}`}
                                                >
                                                    <MaterialIcons name="folder" />
                                                    <Text size="sm">
                                                        {e?.title}
                                                    </Text>
                                                </Link>
                                            </li>
                                        ))}
                                    </ul> */}
                                    <Text
                                        size="sm"
                                        type="secondary"
                                        style={{ marginLeft: "auto" }}
                                    >
                                        {capitalize(t("misAJour"))}{" "}
                                        {moment(updatedDate).format(
                                            "DD/MM/YYYY HH:mm",
                                        )}
                                    </Text>
                                </Flex>
                            ) : (
                                <Flex align="center" gap={6}>
                                    <Link to={`/drive/project/${project?._id}`}>
                                        <Flex align="center" gap={6}>
                                            <MaterialIcons name="folder" />
                                            <Text size="sm">
                                                {project?.title}
                                            </Text>
                                        </Flex>
                                    </Link>
                                    <Text
                                        size="sm"
                                        type="secondary"
                                        style={{ marginLeft: "auto" }}
                                    >
                                        {capitalize(t("misAJour"))}{" "}
                                        {moment(updatedDate).format(
                                            "DD/MM/YYYY HH:mm",
                                        )}
                                    </Text>
                                </Flex>
                            ),
                        label: (
                            <Flex gap={6}>
                                {type === "folder" ? (
                                    <MaterialIcons name="folder" />
                                ) : (
                                    <ExtensionImageContent
                                        file={data}
                                        width={24}
                                    />
                                )}
                                <Text>
                                    {type === "folder"
                                        ? title
                                        : !data.compiledFiles.length > 0
                                          ? title +
                                            "." +
                                            getFileExtension(data.file)
                                          : title + ".ifc"}
                                </Text>
                            </Flex>
                        ),
                    },
                ]}
            />
        </div>
    );
};
