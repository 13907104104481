import i18n from "i18next";

import Constants from "utils/constants";

import { scrollToElementById } from "./scroller";

export function getCommentUserName(comment, isBIMCollabAvailable) {
    return comment.author
        ? comment.author.firstname + " " + comment.author.lastname
        : (comment.bimCollabName ? comment.bimCollabName : comment.guestName) +
              (!isBIMCollabAvailable ? " (" + i18n.t("invite") + ")" : "");
}

export function scrollToComment(bcfId) {
    if (window.innerWidth > parseInt(Constants.mdSize, 10))
        scrollToElementById("comment-" + bcfId);

    setTimeout(function () {
        const commentElement = document.getElementById("comment-" + bcfId);

        commentElement?.classList.add("bg-yellow-200");
        setTimeout(function () {
            commentElement?.classList.remove("bg-yellow-200");
        }, 1000);
    }, 100);
}
