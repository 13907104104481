import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { DriveToolsContext } from "components/drive/Drive";

import ModalEntry from "../modalEntry/ModalEntry";

import { Card } from "antd";

const PreviewSidePanel = () => {
    const { t } = useTranslation();
    const { toolsData } = useContext(DriveToolsContext);

    return (
        <Card
            title={t("previsualisation")}
            style={{
                height: "100%",
                flexDirection: "column",
                display: "flex",
            }}
            styles={{
                body: {
                    overflow: "auto",
                    flex: 1,
                },
            }}>
            <ModalEntry
                canSelectDate
                canSelectProject
                isPreview
                preData={{
                    fields: toolsData?.fields || [],
                }}
            />
        </Card>
    );
};

export default PreviewSidePanel;
