import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

import { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { useLocation } from "react-router";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import useLongPress from "helpers/dnd";
import { debounce } from "helpers/input";

import { DriveToolsContext } from "components/drive/Drive";
import { usePlansStore } from "components/plans/PlansView";

import MarkerForms from "../markers/MarkerForms";
import MarkerNewItem from "../markers/MarkerNewItem";
import MarkersComments from "../markers/MarkersComments";

export default function PdfEnginePage({
    file,
    scale,
    setScale,
    pdfDimensions,
    mdMedia,
}) {
    const [documentReady, setDocumentReady] = useState();
    const transformRef = useRef(null);

    const [groups, setGroups] = useState([]);

    const zoomPluginInstance = zoomPlugin();
    const { zoomTo } = zoomPluginInstance;

    return (
        <ZoomWrapper
            mdMedia={mdMedia}
            scale={scale}
            setScale={setScale}
            pdfDimensions={pdfDimensions}
            transformRef={transformRef}
        >
            <Worker workerUrl="/pdf.worker.min.js">
                <Viewer
                    renderLoader={() => <></>}
                    theme="light"
                    fileUrl={file.fileURL}
                    defaultScale={4}
                    // defaultScale={"PageWidth"}
                    // pageLayout={{
                    //     transformSize: ({ size }) => ({
                    //         height: size.height,
                    //         width: pdfDimensions?.width * 2,
                    //     }),
                    // }}
                    // plugins={[zoomPluginInstance]}
                    onDocumentLoad={() => {
                        setDocumentReady(true);
                    }}
                    renderPage={(renderPageProps) => (
                        <CustomPageLayer
                            renderPageProps={renderPageProps}
                            groups={groups}
                            documentReady={documentReady}
                            scale={scale}
                            setGroups={setGroups}
                            file={file}
                        />
                    )}
                />
            </Worker>
        </ZoomWrapper>
    );
}

const CustomPageLayer = ({ renderPageProps, scale, file }) => {
    const textLayerRef = useRef(null);
    const index = renderPageProps.pageIndex;
    const { setPositionClick, positionClick } = usePlansStore();
    const { pathname } = useLocation();
    const { toolsData, setToolsData } = useContext(DriveToolsContext);

    const cancelAction = () => {
        setToolsData((e) => ({
            ...e,
            markers: {
                ...e.markers,
                isMarkerMode: false,
                addedMarker: null,
            },
        }));
    };
    // Handle PDF click to add marker
    const handlePdfClick = (event) => {
        if (toolsData.markers?.isMarkerMode) {
            const boundingRect = event.target.getBoundingClientRect();
            const x =
                ((event.clientX - boundingRect.left) / boundingRect.width) *
                100;
            const y =
                ((event.clientY - boundingRect.top) / boundingRect.height) *
                100;

            setToolsData((e) => ({
                ...e,
                markers: {
                    ...e.markers,
                    addedMarker: {
                        x,
                        y,
                        page: index + 1,
                    },
                },
            }));
        }
    };

    // Handle PDF click to add marker
    const handlePdfContextMenu = (event) => {
        if (toolsData.markers?.isMarkerMode) {
            cancelAction();
        }
        if (pathname == "/plans") {
            setPositionClick(null);
            setTimeout(() => {
                const boundingRect = event.target.getBoundingClientRect();
                const x =
                    ((event.clientX - boundingRect.left) / boundingRect.width) *
                    100;
                const y =
                    ((event.clientY - boundingRect.top) / boundingRect.height) *
                    100;
                setPositionClick({ x, y, page: index + 1 });
            }, 100);
        }
    };

    const backspaceLongPress = useLongPress((e) => {
        if (pathname == "/plans") {
            setPositionClick(null);
            setTimeout(() => {
                const boundingRect = e.target.getBoundingClientRect();
                const x =
                    ((e.clientX - boundingRect.left) / boundingRect.width) *
                    100;
                const y =
                    ((e.clientY - boundingRect.top) / boundingRect.height) *
                    100;
                setPositionClick({ x, y, page: index + 1 });
            }, 100);
        }
    }, 900);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && toolsData.markers?.isMarkerMode) {
                cancelAction();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [toolsData.markers?.isMarkerMode]);

    return (
        <div
            className={
                toolsData.markers?.isMarkerMode ? "pdf-cursor-crosshair" : ""
            }
            onClick={handlePdfClick}
            onContextMenu={handlePdfContextMenu}
            {...backspaceLongPress}
        >
            <MarkersComments
                file={file}
                scale={scale}
                index={renderPageProps.pageIndex}
            />
            <MarkerForms scale={scale} />
            {positionClick && <MarkerNewItem scale={scale} />}

            {renderPageProps.canvasLayer.children}
            <div ref={textLayerRef}>{renderPageProps.textLayer.children}</div>
            {renderPageProps.annotationLayer.children}
        </div>
    );
};

const ZoomWrapper = ({
    children,
    transformRef,
    scale,
    setScale,
    pdfDimensions,
    mdMedia,
}) => {
    const config = mdMedia
        ? {
              initialScale: 0.4,
              minScale: 0.2,
          }
        : {
              initialScale: 0.14,
              minScale: 0.1,
          };

    useEffect(() => {
        const api = transformRef.current;
        if (!api) return;

        const wrapper = api.instance.wrapperComponent;
        const prevScale = api.instance.transformState.scale;

        const rect = wrapper.getBoundingClientRect();
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const offsetX =
            (centerX - api.instance.transformState.positionX) / prevScale;
        const offsetY =
            (centerY - api.instance.transformState.positionY) / prevScale;

        const newPosX = centerX - offsetX * scale;
        const newPosY = centerY - offsetY * scale;
        api.setTransform(newPosX, newPosY, scale);
    }, [scale]);

    return (
        <TransformWrapper
            ref={transformRef}
            {...config}
            onZoom={debounce(({ state }) => {
                setScale(state.scale);
            }, 200)}
            limitToBounds={true}
            disablePadding={true}
            maxScale={4}
            panning={{
                allowRightClickPan: true,
                wheelPanning: true,
            }}
            wheel={{
                wheelDisabled: true,
            }}
            doubleClick={{
                disabled: mdMedia,
            }}
        >
            <TransformComponent
                wrapperStyle={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                }}
                wrapperProps={{
                    onContextMenu: (e) => e.preventDefault(),
                }}
                contentStyle={{
                    width: pdfDimensions?.width * 4,
                    padding: mdMedia ? "50px 0" : "20px 0",
                }}
            >
                {children}
            </TransformComponent>
        </TransformWrapper>
    );
};
