import axios from "axios";
import moment from "moment";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { toastAlert } from "helpers/alert";
import {
    checkIfFullWordInNameParts,
    isValidFileForNomenclature,
} from "helpers/automation";
import { fieldValidation } from "helpers/fieldValidation";
import { fileUploadProcess } from "helpers/file";
import { getProjectparentsList } from "helpers/project";
import { isFileUploadAllowed } from "helpers/size";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import Button from "components/input/Button";
import ContactFormButton from "components/input/ContactFormButton";

import { ModalContext } from "layout/LayoutModal";

import DriveNomenclatureMobile from "./DriveNomenclatureMobile";
import DriveNomenclatureTable from "./DriveNomenclatureTable";

import { Button as AntdButton, Divider } from "antd";

const DriveNomenclatureModal = ({
    user,
    setInformationModal,
    setLoader,
    handleFiles,
    refreshAction,
    oldFile,
    projectToUpload,
    folderName,
    files,
    isFolderImport,
    nomenclatureData,
    currentFolderLevel,
    updateFileProgressIndicator,
    keyModal,
    existingFileId,
    action,
}) => {
    const { t } = useTranslation();

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const [filesToImport, setFilesToImport] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [answers, setAnswers] = useState({});
    const [currentGED, setCurrentGED] = useState("-");

    const [importStates, setImportStates] = useState([]);
    const [currentProcessedFile, setCurrentProcessedFile] = useState(null);
    const [processedFiles, setProcessedFiles] = useState([]);
    const [existingFileNames, setExistingFileNames] = useState([]);
    const [createdFolders, setCreatedFolders] = useState([]);
    const [errorMessage, showErrorMessage] = useState(null);

    const [fieldsError, setFieldsError] = useState({});
    const answersRef = useRef(answers);
    const { setHandleConfirm, setValidation, addModalData } =
        useContext(ModalContext);

    useEffect(() => {
        if (files && Number.isInteger(currentFolderLevel)) {
            setFilesToImport(
                files.find(
                    (item) =>
                        item.level === currentFolderLevel && !item.isCompleted,
                ).files,
            );
        } else if (files) setFilesToImport(files);
    }, [files]);

    useEffect(() => {
        const getGEDDatas = async () => {
            const parents = await getProjectparentsList(projectToUpload._id);

            if (parents.length > 0) {
                const rootProject = parents.find(
                    (e) => e.parentProject === undefined,
                );
                setCurrentGED(rootProject.indexGED);
            } else {
                const resFolder = await axios.get(
                    apiURL.getProjectById + projectToUpload._id,
                );

                if (resFolder?.data) setCurrentGED(resFolder.data.indexGED);
            }
        };

        getGEDDatas();
    }, [projectToUpload]);

    // Préchargement automatique des champs si le nom du fichier respecte déjà la structure des règles de nomenclature
    useEffect(() => {
        let filesStates = [];

        filesToImport
            // .filter(
            //     (file, fileIndex) =>
            //         !importStates[fileIndex] &&
            //         !!importStates[fileIndex] !== "",
            // )
            .forEach((file, fileIndex) => {
                const fileName = file.name
                    .substr(0, file.name.lastIndexOf("."))
                    .substring(0, Constants.maxLengthFileTitle);

                filesStates[fileIndex] =
                    importStates[fileIndex] === ""
                        ? ""
                        : isValidFileForNomenclature(
                              nomenclatureData,
                              fileName,
                              (Number.isInteger(currentFolderLevel) &&
                                  files.find(
                                      (item) =>
                                          item.level === currentFolderLevel &&
                                          !item.isCompleted,
                                  )?.name) ||
                                  projectToUpload.title,
                              currentGED,
                          );
                const versions = oldFile?.versions || [];

                // If the file is valid, precharge values
                const nameParts =
                    filesStates[fileIndex] === ""
                        ? fileName.split("_").map((part) => part.trim())
                        : null;

                if (nameParts && importStates[fileIndex] !== "")
                    nomenclatureData.actions.forEach((action, i) => {
                        const indexField = `${i}_${fileIndex}`;

                        switch (action.constraint.code) {
                            case "FILENAME_DATE":
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_DATE_${indexField}`]:
                                        (nameParts && nameParts[i]) ||
                                        moment().format(
                                            action.nomenclature || "YYYYMMDD",
                                        ),
                                }));
                                break;
                            case "FILENAME_DATE_SELECT":
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_DATE_SELECT_${indexField}`]:
                                        (nameParts && nameParts[i]) ||
                                        moment().format(
                                            action.nomenclature || "YYYYMMDD",
                                        ),
                                }));
                                break;
                            case "FILENAME_LIST":
                                const validListValue =
                                    nameParts &&
                                    action.nomenclature.includes(nameParts[i])
                                        ? nameParts[i]
                                        : "";
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_LIST_${indexField}`]:
                                        validListValue,
                                }));
                                break;
                            case "FILENAME_FOLDER":
                                checkIfFullWordInNameParts(
                                    i,
                                    nameParts,
                                    projectToUpload.title,
                                );
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_FOLDER_${indexField}`]:
                                        projectToUpload.title,
                                }));
                                break;
                            case "FILENAME_FILE":
                                checkIfFullWordInNameParts(
                                    i,
                                    nameParts,
                                    fileName,
                                );
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_FILE_${indexField}`]: fileName,
                                }));
                                break;
                            case "FILENAME_INDEX_ALPHA":
                                let letter = "";
                                let index = versions.length;
                                while (index > 0) {
                                    index--;
                                    letter =
                                        String.fromCharCode((index % 26) + 65) +
                                        letter;
                                    index = Math.floor(index / 26);
                                }
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_INDEX_ALPHA_${indexField}`]:
                                        (nameParts && nameParts[i]) ||
                                        letter ||
                                        "0",
                                }));
                                break;
                            case "FILENAME_INDEX_NUMBER":
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_INDEX_NUMBER_${indexField}`]:
                                        (nameParts &&
                                            nameParts[i]?.padStart(4, "0")) ||
                                        (versions.length + 1)
                                            .toString()
                                            .padStart(4, "0"),
                                }));
                                break;
                            case "FILENAME_GED":
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_GED_${indexField}`]:
                                        currentGED === "-"
                                            ? "-"
                                            : currentGED
                                                  .toString()
                                                  .padStart(6, "0"),
                                }));
                                break;
                            case "FILENAME_CUSTOM":
                                checkIfFullWordInNameParts(
                                    i,
                                    nameParts,
                                    action.nomenclature,
                                );
                                setAnswers((prevAnswers) => ({
                                    ...prevAnswers,
                                    [`FILENAME_CUSTOM_${indexField}`]:
                                        (nameParts && nameParts[i]) ||
                                        action.nomenclature,
                                }));
                                break;
                            case "FILENAME_SET":
                                setAnswers((prevAnswers) => {
                                    return {
                                        ...prevAnswers,
                                        [`FILENAME_SET_${indexField}`]:
                                            (nameParts && nameParts[i]) || "",
                                    };
                                });
                                break;
                            default:
                                break;
                        }
                    });
            });

        setImportStates(filesStates);
    }, [filesToImport, nomenclatureData, projectToUpload, currentGED]);

    // Vérification du respect des règles de nomenclature lors du changement de la valeur d'un champ
    useEffect(() => {
        let updatedFilesStates = [...importStates];
        let filesGeneratedNames = [];

        nomenclatureData.actions.forEach((action, actionIndex) => {
            filesToImport.forEach((file, fileIndex) => {
                const fieldIndex = actionIndex + "_" + fileIndex;

                const fieldCode = action.constraint.code;
                const fullKey = `${fieldCode}_${fieldIndex}`;
                const versions = oldFile?.versions || [];

                let fieldValue = "";

                if (answers.hasOwnProperty(fullKey)) {
                    fieldValue = answers[fullKey];
                } else {
                    switch (fieldCode) {
                        case "FILENAME_DATE":
                            fieldValue = moment().format("YYYYMMDD");
                            break;
                        case "FILENAME_DATE_SELECT":
                            fieldValue = moment().format("YYYYMMDD");
                            break;
                        case "FILENAME_INDEX_ALPHA":
                            let letter = "";
                            let index = versions.length;
                            if (index === 0) letter = "0";
                            else {
                                while (index > 0) {
                                    index--;
                                    letter =
                                        String.fromCharCode((index % 26) + 65) +
                                        letter;
                                    index = Math.floor(index / 26);
                                }
                            }
                            fieldValue = letter;
                            break;
                        case "FILENAME_INDEX_NUMBER":
                            fieldValue = versions.length
                                .toString()
                                .padStart(4, "0");
                            break;
                        case "FILENAME_FOLDER":
                            fieldValue = projectToUpload.title;
                            break;
                        case "FILENAME_FILE":
                            fieldValue = filesToImport[fileIndex].name.slice(
                                0,
                                filesToImport[fileIndex].name.lastIndexOf("."),
                            );
                            break;
                        case "FILENAME_GED":
                            fieldValue =
                                currentGED === "-"
                                    ? currentGED
                                    : currentGED.toString().padStart(6, "0");
                            break;
                        case "FILENAME_CUSTOM":
                            fieldValue = action.nomenclature || "";
                            break;
                        default:
                            // Champ libre, on ajoute directement la valeur
                            fieldValue =
                                action.nomenclature &&
                                action.nomenclature instanceof String
                                    ? action.nomenclature
                                    : "";
                            break;
                    }
                }

                if (filesGeneratedNames[fileIndex])
                    filesGeneratedNames[fileIndex] =
                        filesGeneratedNames[fileIndex] + "_" + fieldValue;
                else filesGeneratedNames[fileIndex] = fieldValue;
            });
        });

        let updatedFileNames = [];

        filesToImport.forEach((file, fileIndex) => {
            updatedFilesStates[fileIndex] = isValidFileForNomenclature(
                nomenclatureData,
                filesGeneratedNames[fileIndex],
                projectToUpload.title,
                currentGED,
            );

            if (updatedFilesStates[fileIndex] === "") {
                if (
                    !updatedFileNames.some(
                        (item) =>
                            item.fileIndex !== fileIndex &&
                            item.name === filesGeneratedNames[fileIndex],
                    )
                ) {
                    if (
                        !updatedFileNames.some(
                            (item) => item.fileIndex === fileIndex,
                        )
                    ) {
                        // Add new entry
                        updatedFileNames.push({
                            fileIndex,
                            name: filesGeneratedNames[fileIndex],
                        });
                    } else {
                        // Update the name for the existing entry with matching fileIndex
                        const currentNameIndex = updatedFileNames.findIndex(
                            (item) => item.fileIndex === fileIndex,
                        );

                        if (currentNameIndex > -1) {
                            updatedFileNames[currentNameIndex] = {
                                fileIndex,
                                name: filesGeneratedNames[fileIndex],
                            };
                        }
                    }
                } else {
                    updatedFilesStates[fileIndex] = t(
                        "ligneDejaPresenteAvecUnNomIdentique",
                    );
                }
            }
        });

        setExistingFileNames(updatedFileNames);
        setImportStates(updatedFilesStates);
        answersRef.current = answers;
    }, [answers]);

    useEffect(() => {
        setValidation(
            keyModal,
            importStates.some((item) => item !== ""),
        );
    }, [importStates, keyModal]);

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";
        if (nomenclatureData.allowAutomaticDetection) {
            filesToImport.forEach((file, fileIndex) => {
                nomenclatureData.actions.forEach((action, i) => {
                    if (action.constraint.code === "FILENAME_SET") {
                        validation = fieldValidation(
                            answers["FILENAME_SET_" + i + "_" + fileIndex],
                            "required",
                        );
                        updatedFieldsError[
                            "FILENAME_SET_" + i + "_" + fileIndex
                        ] = validation;
                        if (validation !== "") nbErrors++;
                    }
                });
            });
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    useEffect(() => {
        setHandleConfirm(keyModal, async () => {
            return await onConfirm();
        });
    }, [answers]);

    const onConfirm = async () => {
        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            // Check limite de stockage
            let totalSize = 0;

            filesToImport.forEach((file) => {
                totalSize += file.size;
            });

            const isUploadAllowed = await isFileUploadAllowed(
                projectToUpload,
                totalSize,
            );

            if (!isUploadAllowed) {
                const key = generateRandomToken(10);
                addModalData({
                    key,
                    type: "illustrated",
                    title: t("limiteDeStockage"),
                    subtitle: t("votreDriveEstPresquePlein!"),
                    content: (
                        <div className="m-auto text-center">
                            <p className="text-lg font-bold text-black mb-8">
                                {t(
                                    "votreEspaceDeStockageArriveASonMaximumPassezALaLicenceSuperieurePourEnObtenirDavantage",
                                )}
                                .
                            </p>
                            <div className="m-auto inline-block">
                                <ContactFormButton
                                    text={t("augmenterMonEspace").toUpperCase()}
                                />
                            </div>
                        </div>
                    ),
                    width: 1000,
                });

                return;
            }

            // On va afficher le nombre de fichiers importés / le nombre en erreur à la fin de l'import
            let nbSuccess = 0;
            let nbError = 0;

            let filesProject = projectToUpload;

            // Si on importe un dossier, on doit d'abord le créer
            if (Number.isInteger(currentFolderLevel)) {
                const currentFolder = files.find(
                    (item) =>
                        item.level === currentFolderLevel && !item.isCompleted,
                );

                if (currentFolder) {
                    // Si on trouve dans les dossiers récemment créé le parent du dossier courant, on l'utilise
                    const parentFolder = createdFolders.find(
                        (folder) =>
                            folder.level === currentFolderLevel - 1 &&
                            folder.data.title === currentFolder.parentId,
                    );

                    const project = await createFolder(
                        currentFolder.name,
                        parentFolder?.data || projectToUpload,
                        true,
                    );

                    if (project) filesProject = project;
                }
            }

            for (
                let fileIndex = 0;
                fileIndex < filesToImport.length;
                fileIndex++
            ) {
                // On défini la ligne qui est en cours d'import
                setCurrentProcessedFile(fileIndex);

                try {
                    const file = filesToImport[fileIndex];
                    const currentLineAnswers = {};

                    // Process line by line, keeping only the elements of the current line
                    for (let key in answers) {
                        const lastUnderscore = key.lastIndexOf("_");

                        if (key.endsWith("_" + fileIndex)) {
                            const newKey = key.slice(0, lastUnderscore);
                            currentLineAnswers[newKey] = answers[key];
                        }
                    }

                    let filesToUpload = Array.from(filesToImport)
                        ?.sort((a, b) => a.size - b.size)
                        .map((file) => ({
                            file,
                            streamId: file.name + generateRandomToken(10),
                        }));
                    const fileTarget = filesToUpload.find(
                        (item) => item.file === file,
                    );
                    updateFileProgressIndicator({
                        status: "calculating",
                        percentage: 1,
                        streamId: fileTarget.streamId,
                        file,
                    });
                    if (action === "version" && existingFileId) {
                        try {
                            const response = await fileUploadProcess({
                                project: filesProject,
                                file,
                                existingFileId,
                                createVersionData: currentLineAnswers,
                                startPdfConversion: filesToImport.length === 1,
                                readStream: (progress) => {
                                    updateFileProgressIndicator({
                                        ...progress,
                                        streamId: fileTarget.streamId,
                                        file,
                                    });
                                },
                            });
                            if (response === true && !isFolderImport)
                                refreshAction(false, false, [
                                    projectToUpload._id,
                                ]);
                            return "File uploaded";
                        } catch (err) {}
                    }

                    await fileUploadProcess({
                        project: filesProject,
                        file,
                        createVersionData: currentLineAnswers,
                        startPdfConversion: filesToImport.length === 1,
                        readStream: (progress) => {
                            updateFileProgressIndicator({
                                ...progress,
                                streamId: fileTarget.streamId,
                                file,
                            });
                        },
                    });

                    setProcessedFiles((prevProcessed) => {
                        const newProcessed = [...prevProcessed];
                        newProcessed[fileIndex] = true;
                        return newProcessed;
                    });

                    nbSuccess++;
                } catch (err) {
                    setProcessedFiles((prevProcessed) => {
                        const newProcessed = [...prevProcessed];
                        newProcessed[fileIndex] = false;
                        return newProcessed;
                    });

                    nbError++;
                }

                if (!filesToImport[fileIndex + 1]) {
                    setCurrentProcessedFile(null);

                    if (nbSuccess > 0) {
                        toastAlert(
                            "success",
                            t("fichiersCharges") + " : " + nbSuccess,
                        );
                    }
                    if (nbError > 0) {
                        toastAlert(
                            "warning",
                            t("fichiersEnErreur") + " : " + nbError,
                        );
                    }

                    if (Number.isInteger(currentFolderLevel)) {
                        // On passe au traitement du dossier suivant
                        // On flag le dossier courant comme déjà traité (utile si on a plusieurs dossiers au même niveau)
                        const updatedFiles = Array.from(files);
                        const completedFolderIndex = updatedFiles.findIndex(
                            (item) =>
                                item.level === currentFolderLevel &&
                                !item.isCompleted,
                        );

                        if (completedFolderIndex > -1) {
                            updatedFiles[completedFolderIndex].isCompleted =
                                true;
                        }

                        // Dernier dossier, on refresh la page et on ferme le modal
                        if (
                            updatedFiles.filter((item) => !item.isCompleted)
                                .length === 0
                        ) {
                            setInformationModal();
                            refreshAction();
                            return;
                        }

                        let nextLevel;
                        // Si il reste un dossier à ce niveau, on incrémente pas pour le prochain traitement
                        if (
                            updatedFiles.some(
                                (item) =>
                                    item.level === currentFolderLevel &&
                                    !item.isCompleted,
                            )
                        )
                            nextLevel = currentFolderLevel;
                        else nextLevel = currentFolderLevel + 1;

                        // Rest des datas après le passage sur un dossier suivant
                        setImportStates([]);
                        setProcessedFiles([]);
                        setExistingFileNames([]);
                        showErrorMessage(null);
                        setAnswers({});

                        setInformationModal({
                            content: (
                                <DriveNomenclatureModal
                                    user={user}
                                    setInformationModal={setInformationModal}
                                    setLoader={setLoader}
                                    handleFiles={handleFiles}
                                    refreshAction={refreshAction}
                                    oldFile={null}
                                    projectToUpload={filesProject}
                                    folderName={folderName}
                                    files={updatedFiles}
                                    isFolderImport={true}
                                    nomenclatureData={nomenclatureData}
                                    currentFolderLevel={nextLevel}
                                />
                            ),
                            isNoPadding: true,
                        });

                        return;
                    }
                }
            }

            setInformationModal();
            refreshAction();
        }
    };

    const handleAddMoreFiles = (e) => {
        setFilesToImport([...filesToImport, ...e.target.files]);
    };

    const handleClose = () => {
        setLoader(false);
        if (!isLoaded) setIsLoaded(true);
        else {
            setInformationModal(null);
            // Si on a importé au moins le premier dossier, on doit refresh lors de la fermeture du modal
            if (Number.isInteger(currentFolderLevel) && currentFolderLevel > 0)
                refreshAction();
        }
    };

    const createFolder = async (
        folderName,
        parentProject,
        isRootFolder = false,
    ) => {
        const projectFields = {
            projectTitle: folderName,
            email: user.email,
            language: user.language.code,
            parentProject,
            userId: user._id,
            sendNotification: isRootFolder,
        };

        const resProject = await axios.post(
            apiURL.createProject,
            projectFields,
        );

        if (resProject?.data) {
            setCreatedFolders((oldFolders) => [
                ...oldFolders,
                { level: currentFolderLevel, data: resProject.data },
            ]);
            return resProject.data;
        }

        return null;
    };

    // Pour la suppression d'une des lignes, un recalcul des différents states est nécessaire pour réajuster les indexations
    const removeLine = (fileIndexToRemove) => {
        const updatedFilesToImport = filesToImport.filter(
            (_, index) => index !== fileIndexToRemove,
        );

        const updatedAnswers = Object.keys(answers)
            .filter((key) => !key.endsWith(`_${fileIndexToRemove}`))
            .reduce((obj, key) => {
                const updatedKey = key.replace(/_(\d+)$/, (match, p1) => {
                    const index = parseInt(p1, 10);
                    return index > fileIndexToRemove ? `_${index - 1}` : match;
                });
                obj[updatedKey] = answers[key];
                return obj;
            }, {});

        const updatedImportStates = importStates.filter(
            (_, index) => index !== fileIndexToRemove,
        );

        const updatedExistingFileNames = existingFileNames
            .filter((item) => item.fileIndex !== fileIndexToRemove)
            .map((item) => ({
                ...item,
                fileIndex:
                    item.fileIndex > fileIndexToRemove
                        ? item.fileIndex - 1
                        : item.fileIndex,
            }));

        const updatedProcessedFiles = processedFiles.filter(
            (_, index) => index !== fileIndexToRemove,
        );

        setFilesToImport(updatedFilesToImport);
        setAnswers(updatedAnswers);
        setImportStates(updatedImportStates);
        setExistingFileNames(updatedExistingFileNames);
        setProcessedFiles(updatedProcessedFiles);

        if (updatedFilesToImport.length === 0) handleClose();
    };

    return (
        <Fragment>
            {files && Number.isInteger(currentFolderLevel) && (
                <div className="text-black mb-6 ml-2">
                    <i
                        className="material-icons notranslate inline mr-2 align-middle text-black"
                        style={{
                            fontSize: "1.3rem",
                        }}
                    >
                        folder
                    </i>
                    {
                        files.find(
                            (item) =>
                                item.level === currentFolderLevel &&
                                !item.isCompleted,
                        ).name
                    }
                </div>
            )}
            {mdMedia ? (
                <DriveNomenclatureTable
                    nomenclatureData={nomenclatureData}
                    answers={answers}
                    setAnswers={setAnswers}
                    projectToUpload={projectToUpload}
                    oldFile={oldFile}
                    filesToImport={filesToImport}
                    importStates={importStates}
                    processedFiles={processedFiles}
                    currentProcessedFile={currentProcessedFile}
                    errorMessage={errorMessage}
                    showErrorMessage={showErrorMessage}
                    fieldsError={fieldsError}
                    removeLine={removeLine}
                />
            ) : (
                <DriveNomenclatureMobile
                    nomenclatureData={nomenclatureData}
                    answers={answers}
                    setAnswers={setAnswers}
                    projectToUpload={projectToUpload}
                    oldFile={oldFile}
                    filesToImport={filesToImport}
                    importStates={importStates}
                    processedFiles={processedFiles}
                    currentProcessedFile={currentProcessedFile}
                    errorMessage={errorMessage}
                    showErrorMessage={showErrorMessage}
                    fieldsError={fieldsError}
                    removeLine={removeLine}
                />
            )}

            <AntdButton
                children={t("ajouterUnFichier")}
                icon={<MaterialIcons name="add" />}
                type="text"
                onClick={(e) => {
                    document.getElementById("import-more-file").click();
                }}
            />

            <div className="hidden">
                <Button
                    id="import-more-file"
                    type="file"
                    accept={Constants.allowedExtensionsList}
                    onChange={handleAddMoreFiles}
                    isMultiple={true}
                />
            </div>

            {mdMedia && <Divider />}

            <Text
                size="sm"
                style={{
                    textAlign: "right",
                    display: "block",
                }}
            >
                {"*  " +
                    t(
                        "leCaractereEstInterditDansLeContenuDesChampsCarIlFaitOfficeDeSeparateur",
                    ) +
                    "."}
            </Text>
        </Fragment>
    );
};

export default DriveNomenclatureModal;
