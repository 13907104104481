import axios from "axios";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fieldValidation } from "helpers/fieldValidation";
import { getProjectparentsList } from "helpers/project";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import TextField from "components/input/TextField";
import ConfirmationModal from "components/modal/ConfirmationModal";
import Badge from "components/utils/Badge";
import Spinner from "components/utils/Spinner";

const VisaForm = ({
    projectId,
    isChild = false,
    onRefresh,
    onlyForm = false,
}) => {
    const { t } = useTranslation();

    const [confirmationModal, setConfirmationModal] = useState(null);
    const [loader, setLoader] = useState(false);

    const [title, setTitle] = useState("");
    const [visas, setVisas] = useState([]);

    const [rootProjectId, setRootProjectId] = useState(!isChild && projectId);

    const [fieldsError, setFieldsError] = useState({
        title: "",
    });

    useEffect(() => {
        if (rootProjectId) getVisasList();
        else {
            const getParents = async () => {
                const parents = await getProjectparentsList(projectId);

                if (parents.length > 0) setRootProjectId(parents[0]._id);
            };

            getParents();
        }
    }, [rootProjectId]);

    const getVisasList = async () => {
        if (!rootProjectId) return;

        const folderVisasRes = await axios.get(
            apiURL.getFolderVisas + rootProjectId,
        );

        if (folderVisasRes && folderVisasRes.data)
            setVisas(folderVisasRes.data);
    };

    const onChange = (e) => {
        setTitle(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (
            // Ajout de l'élément avec les touches "ENTER" / "TAB"
            e.keyCode === 13 ||
            e.keyCode === 9
        ) {
            e.preventDefault();
            addVisa();
        }
    };

    const addVisa = async () => {
        const resValidation = await fieldsValidation();

        // on vérifie que le champ honeyPot est vide

        if (resValidation === 0) {
            setLoader(true);
            const resCreated = await axios.post(apiURL.createVisa, {
                title,
                projectId: rootProjectId,
            });

            // Update de la liste pour ce dossier
            if (resCreated && resCreated.data) {
                setTitle("");
                getVisasList();
                onRefresh && onRefresh();
                toastAlert("success", t("leVisaABienEteAjoute"));
            } else toastAlert("error", t("uneErreurEstSurvenue"));

            setLoader(false);
        }
        setTitle((e) => e.trim());
    };

    const deleteVisa = (id) => {
        setConfirmationModal({
            title: t("voulezVousVraimentSupprimerCeVisa?"),
            content:
                t(
                    "celaEntraineraLaSuppressionDesAutomatisationsLieesACeluiCiEtIlNeSeraPlusAppliqueAAucunFichier",
                ) + ".",
            callBackFunc: confirmDeleteVisa,
            callBackParams: id,
            closeReset: setConfirmationModal,
        });
    };

    const confirmDeleteVisa = async (id) => {
        const resDeleted = await axios.delete(apiURL.deleteVisa + id);

        if (resDeleted && resDeleted.data) {
            getVisasList();
            onRefresh && onRefresh();
            toastAlert("success", t("leVisaABienEteSupprime"));
        }
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        validation = fieldValidation(title, "required");
        updatedFieldsError.title = validation;
        if (validation !== "") nbErrors++;

        if (nbErrors === 0) {
            // Check si ce visa n'existe pas déjà dans le dossier
            if (visas.some((visa) => visa.title === title)) {
                updatedFieldsError.title = t("ceVisaEstDejaUtilise");
                nbErrors++;
            }
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    return (
        <div className={"mt-4" + (!onlyForm ? " border-t-2" : "")}>
            {!onlyForm && (
                <h6 className="my-3 font-bold text-lg">{t("visasDuProjet")}</h6>
            )}

            <div className="flex flex-row">
                <div className="text-sm w-full">
                    <TextField
                        placeholder={t("ajouterUnVisa")}
                        onChange={onChange}
                        value={title}
                        maxLength={Constants.maxLengthVisaTitle}
                        errorMsg={fieldsError.title}
                        handleKeyDown={handleKeyDown}
                        isTransparent={true}
                    />
                </div>

                <button
                    type="button"
                    onClick={addVisa}
                    className="flex my-auto p-1 text-primary"
                >
                    <i
                        className="material-icons notranslate"
                        style={{ fontSize: "1.5rem" }}
                    >
                        add
                    </i>
                </button>
            </div>

            {visas && visas.length > 0 && (
                <ul className="w-full mt-2 py-2">
                    {visas
                        .sort((a, b) =>
                            a.title.toLowerCase() > b.title.toLowerCase()
                                ? 1
                                : -1,
                        )
                        .map((visa) => (
                            <li key={visa._id} className="inline-block my-1">
                                <Badge
                                    text={visa.title}
                                    bgColor="#d1d5db"
                                    textColor="#fff"
                                    deleteClick={(e) => deleteVisa(visa._id)}
                                />
                            </li>
                        ))}
                </ul>
            )}

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                />
            )}

            {loader && <Spinner />}
        </div>
    );
};

export default VisaForm;
