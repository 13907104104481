import axios from "axios";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import { DriveToolsContext } from "components/drive/Drive";
import SearchBar from "components/nav/SearchBar";
import Badge from "components/utils/Badge";

const FilterBySearch = () => {
    const { t } = useTranslation();

    const { toolsData, setToolsData } = useContext(DriveToolsContext);

    const [searchRes, setSearchRes] = useState([]);

    const onChange = async (terms) => {
        if (terms.length > 0) {
            const resSearch = await axios.post(apiURL.searchProject, {
                terms: terms.toLowerCase(),
                isOnlyFolders: true,
            });

            if (resSearch && resSearch.data) {
                setSearchRes(resSearch.data);
            } else setSearchRes([]);
        } else setSearchRes([]);
    };

    const handleFolder = (folder) => {
        if (toolsData.filters.folders.some((item) => item._id === folder._id)) {
            setToolsData((e) => ({
                ...e,
                filters: {
                    ...e.filters,
                    folders: toolsData.filters.folders.filter(
                        (item) => item._id !== folder._id,
                    ),
                },
            }));
        } else {
            let updatedFoldersList = toolsData.filters.folders;
            updatedFoldersList.push(folder);
            setToolsData((e) => ({
                ...e,
                filters: {
                    ...e.filters,
                    folders: [...updatedFoldersList],
                },
            }));
        }
    };

    return (
        <div className="">
            <SearchBar
                onChange={onChange}
                noBackground={true}
                placeholder={t("rechercherUnDossier")}
            />
            {searchRes.length > 0 && (
                <ul className="card max-h-[300px] overflow-y-auto overflow-x-hidden">
                    {searchRes.map((item) => (
                        <li
                            key={item._id}
                            className={
                                "flex flex-row p-2 hover:cursor-pointer hover:bg-blue-50 hover:text-primary text-sm" +
                                (toolsData.filters.folders.some(
                                    (folder) => item._id === folder._id,
                                )
                                    ? " bg-blue-50 text-primary"
                                    : "")
                            }
                            onClick={(e) => handleFolder(item)}
                        >
                            <div className="w-full truncate">{item.title}</div>

                            {toolsData.filters.folders.some(
                                (folder) => item._id === folder._id,
                            ) && (
                                <div className="flex">
                                    <i
                                        className="material-icons notranslate"
                                        style={{
                                            fontSize: "1.2rem",
                                        }}
                                    >
                                        check
                                    </i>
                                </div>
                            )}
                        </li>
                    ))}
                </ul>
            )}

            <ul className="flex flex-row w-full overflow-x-auto p-2 pt-4 flex-wrap gap-2">
                {toolsData.filters.folders.map((folder, i) => (
                    <li key={i}>
                        <Badge
                            text={folder.title}
                            deleteClick={(e) => handleFolder(folder)}
                            bgColor="#d1d5db"
                            textColor="#fff"
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FilterBySearch;
