import { useProfileStore } from "providers/ProviderProfile";

import { useTranslation } from "react-i18next";

import { isLunchConcerned } from "helpers/timesheet";

import Select from "components/input/Select";

const BreakFields = ({ setTimeEntry, timeEntry, isPreview, isReadOnly }) => {
    const { t } = useTranslation();
    const { scheduleConfiguration } = useProfileStore();
    const canShowLunch = isLunchConcerned(timeEntry.startDate, timeEntry.endDate);

    const isConfigVisibleField = (type) => {
        if (type === "lunch") {
            if (scheduleConfiguration?.isLunchBreakHidden) return false;
        }

        return true;
    };

    return (
        canShowLunch &&
        isConfigVisibleField("lunch") && (
            <Select
                isDisabled={isPreview || isReadOnly}
                text={t("travailLeMidi") + " (Min 15 min)"}
                name="breakLunch"
                value={timeEntry.breakLunch}
                onChange={(e) =>
                    setTimeEntry((prev) => ({
                        ...prev,
                        breakLunch: Number(e.target.value),
                    }))
                }
                options={[
                    { label: "0 min", val: 0 },
                    { label: "15 min", val: 15 },
                    { label: "30 min", val: 30 },
                    { label: "45 min", val: 45 },
                    { label: "60 min", val: 60 },
                ]}
                isRequired
            />
        )
    );
};

export default BreakFields;
