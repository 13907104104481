import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useContext, useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { fromDateToStringDate, getLastOpenBetweenTwoDates } from "helpers/date";
import { ItemTypes } from "helpers/dnd";
import {
    getFileExtension,
    getRelatedCompiled,
    isCompiledFilesExists,
    isIfcFile,
    isPcdFile,
} from "helpers/file";
import { getProjectparentsList } from "helpers/project";
import { getMemberRoles } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import DocumentReader from "components/documents/DocumentReader";
import { DriveToolsContext } from "components/drive/Drive";
import ConfirmationModal from "components/modal/ConfirmationModal";
import VisasInlineList from "components/visa/VisasInlineList";

import { PopupContext } from "layout/LayoutPopup";

import FileImage, { ExtensionImageContent } from "./FileImage";
import FileRelatedCompiled from "./FileRelatedCompiled";
import OptionsFile from "./OptionsFile";
import OptionsProject from "./OptionsProject";

import { Button, Card, Checkbox, Flex, theme } from "antd";

const CardEntity = ({
    item,
    isFolder = false,
    profile,
    addSelectedFile,
    selectedFiles,
    isReadOnly = false,
    onItemClick,
    currentItem,
    userRoles,
    isDnd = true,
    usePublicLink = false,
    hideVersions = false,
}) => {
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { startDriveSyncMessage } = useContext(PopupContext);

    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (loader) {
            startDriveSyncMessage();
        }
        // else {
        //     refreshAction();
        // }
    }, [loader]);

    const [confirmationModal, setConfirmationModal] = useState(null);
    const { refreshAction } = useContext(DriveToolsContext);
    const { permissionsTools } = useProfileStore();
    const { token } = theme.useToken();
    const [isRenderDocument, setRenderDocument] = useState(false);
    const [clickTimeout, setClickTimeout] = useState(null);

    const hasVersions = !!item.versions?.length && !hideVersions;
    const [areVersionsVisible, setVersionsVisible] = useState(false);
    useEffect(() => {
        if (
            item.versions &&
            currentItem?._id &&
            !item.versions?.find((e) => e._id === currentItem._id)
        ) {
            setVersionsVisible(false);
        }
    }, [currentItem, item]);

    const [fileUserRoles, setFileUserRoles] = useState(userRoles);

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: [ItemTypes.FILE, ItemTypes.PROJECT],
            drop: (item) => handleMove(item.type, item.id, item.project),
            canDrop: (item) => handleCanDrop(item.type, item.id, item.project),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [item],
    );

    const [{ isDragging }, drag] = useDrag(() => ({
        type: isFolder ? ItemTypes.PROJECT : ItemTypes.FILE,
        item: {
            type: isFolder ? ItemTypes.PROJECT : ItemTypes.FILE,
            id: item._id,
            project: isFolder ? item : item.project && item.project,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));
    const [{ isDraggingProject }, dragProject] = useDrag(() => ({
        type: ItemTypes.PROJECT,
        item: {
            type: ItemTypes.PROJECT,
            id: item._id,
            project: item && item,
        },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    useEffect(() => {
        // Il peut avrriver que l'on ne puisse pas recevoir les droits du user par props (par exemple les favoris du Dashboard)
        if (!userRoles?.READER && profile) {
            const getUserRoles = async () => {
                const resRoles = await getMemberRoles(
                    isFolder ? item : item.project,
                    profile.user,
                );
                setFileUserRoles(resRoles);
            };

            getUserRoles();
        }
    }, [userRoles, profile]);

    const { runMessage } = useContext(PopupContext);

    const handleMove = async (type, itemId, originalProject) => {
        setLoader(true);

        if (type === ItemTypes.FILE) {
            const projectFile = await axios.get(
                apiURL.getProjectFileById + itemId,
            );

            if (projectFile && projectFile.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectFile.data.project,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !fileUserRoles.MODERATOR) {
                    runMessage({
                        type: "error",
                        content: t(
                            "vousNavezPasLesDroitsPourDeplacerCeFichier",
                        ),
                    });
                    return;
                }

                if (projectFile.data.compiledFiles.length > 0) {
                    const isExists = await isCompiledFilesExists(
                        projectFile.data.compiledFiles.map(
                            (selectedFile) => selectedFile._id,
                        ),
                        item._id,
                    );

                    if (isExists) {
                        toastAlert(
                            "warning",
                            t(
                                "cetteCompilationDeFichiersExisteDejaDansCeDossier",
                            ),
                        );

                        setLoader(false);
                        return;
                    }
                }

                const relatedCompiled = await getRelatedCompiled(
                    projectFile.data._id,
                );

                if (relatedCompiled.length > 0) {
                    setLoader(false);

                    setConfirmationModal({
                        title: t("confirmation"),
                        content: (
                            <FileRelatedCompiled
                                file={projectFile.data}
                                isMoving={true}
                            />
                        ),
                        callBackFunc: moveFile,
                        callBackParams: itemId,
                        closeReset: setConfirmationModal,
                        successText: t("confirmer"),
                        errorText: t("annuler"),
                        customWidth: "max-w-[1000px]",
                    });
                } else await moveFile(itemId);
            }
        } else if (type === ItemTypes.PROJECT) {
            // On check si le projet dans lequel on déplace l'élément courant n'est pas un enfant
            const parents = await getProjectparentsList(item._id);

            if (parents.length > 0) {
                if (
                    parents.some((parent) => parent._id === originalProject._id)
                )
                    return;
            }

            const projectToMove = await axios.get(
                apiURL.getProjectById + itemId,
            );

            if (projectToMove && projectToMove.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectToMove.data,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !fileUserRoles.MODERATOR) return;

                await moveProject(itemId);
            }
        }

        setLoader(false);
    };

    // Déplacement du fichier dans le projet ciblé
    const moveFile = async (fileId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProjectFile + fileId + "/" + item._id,
            );
            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    // Déplacement du projet dans le projet ciblé
    const moveProject = async (movedProjectId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProject + movedProjectId + "/" + item._id,
            );
            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    // Vérification si l'on peut déplacer l'élément dans la zone de dépose
    const handleCanDrop = (type, itemId, originalProject) => {
        if (
            originalProject._id === item._id ||
            (type === ItemTypes.PROJECT &&
                itemId !== item._id.toString() &&
                item.projects &&
                item.projects.some(
                    (subProject) => subProject._id === originalProject._id,
                ))
        )
            return false;

        return true;
    };

    // Ajouter / Supprimer un fichier de la liste des fichiers cochés dans un projet (pour ouvrir plusieurs fichiers dans la visionneuse)
    const handleSelection = (e) => {
        let checked = e.target.checked;
        addSelectedFile(item, checked);
    };

    const handleClick = (e) => {
        const isInOptions = e.target.closest(".options-drawer");
        if (isInOptions) return;

        if (selectedFiles?.length > 0) {
            if (
                selectedFiles.find(
                    (selectedFile) => selectedFile._id === item._id,
                )
            ) {
                addSelectedFile(item, false);
            } else {
                addSelectedFile(item, true);
            }
        }
        if (hasVersions && lgMedia && !isReadOnly)
            setVersionsVisible(!areVersionsVisible);
        if (clickTimeout) {
            clearTimeout(clickTimeout);
            setClickTimeout(null);
            handleDoubleClick(item._id);
        } else {
            if (isReadOnly) e.preventDefault();
            else if (onItemClick && lgMedia) {
                if (!isFolder) onItemClick(item._id, isFolder);
                else onItemClick(item._id, isFolder);
            } else if (!isReadOnly) {
                if (!isFolder) {
                    if (isIfcFile(item) || isPcdFile(item))
                        window.open(
                            "/viewer/" +
                                (usePublicLink && item.publicToken
                                    ? item.publicToken
                                    : item._id),
                        );
                    else setRenderDocument(true);
                } else navigate("/drive/project/" + item._id);
            }
        }
    };

    const handleDoubleClick = (targetId) => {
        if (onItemClick && lgMedia) {
            if (!isFolder) {
                if (isIfcFile(item) || isPcdFile(item))
                    window.open(
                        "/viewer/" +
                            (usePublicLink && item.publicToken
                                ? item.publicToken
                                : targetId
                                  ? targetId
                                  : item._id),
                    );
                else setRenderDocument(true);
            } else
                navigate("/drive/project/" + (targetId ? targetId : item._id));
        }
    };
    const closeReader = () => {
        setRenderDocument(false);
    };

    const OptionsComponent = isFolder ? OptionsProject : OptionsFile;
    return (
        <Fragment>
            <OptionsComponent
                file={item}
                project={item}
                profile={profile}
                refreshAction={refreshAction}
                showInfosInPanel={onItemClick}
                userRoles={fileUserRoles}
                setRenderDocument={setRenderDocument}
                isRenderDocument={isRenderDocument}
                trigger="contextMenu"
            >
                <Card
                    size="small"
                    hoverable
                    bordered
                    ref={(node) =>
                        isDnd && item.project && item.compiledFiles
                            ? drag(node)
                            : isDnd && isFolder
                              ? dragProject(drop(node))
                              : null
                    }
                    onClick={(e) => handleClick(e)}
                    onDoubleClick={(e) => handleDoubleClick()}
                    style={{
                        position: "relative",
                        cursor: "pointer",
                        pointerEvents: isReadOnly ? "none" : "auto",
                        opacity: isDragging ? 0.5 : 1,
                        backgroundColor:
                            (isOver && canDrop) || currentItem?._id === item._id
                                ? token.colorPrimaryBg
                                : "initial",
                    }}
                    className={
                        "cursor-pointer " +
                        ((isOver && canDrop) || currentItem?._id === item._id
                            ? "card-selected"
                            : "")
                    }
                    cover={
                        <FileImage
                            hasCurrent={!!currentItem}
                            file={item}
                            user={profile && profile.user}
                        />
                    }
                >
                    {item.project &&
                        item.visas.filter((item) => item.visa).length > 0 &&
                        permissionsTools?.visas?.canView && (
                            <VisasInlineList file={item} />
                        )}

                    <Flex gap={16} align="center">
                        <ExtensionImageContent file={item} width={30} />

                        <Flex vertical style={{ flex: 1, display: "grid" }}>
                            <Text
                                strong
                                ellipsis
                                children={
                                    item.title +
                                    (item.file
                                        ? "." + getFileExtension(item.file)
                                        : item.compiledFiles?.length > 0
                                          ? ".ifc"
                                          : "")
                                }
                                size="small"
                                title={
                                    item.title +
                                    (item.file
                                        ? "." + getFileExtension(item.file)
                                        : item.compiledFiles?.length > 0
                                          ? ".ifc"
                                          : "")
                                }
                            />
                            <Text
                                ellipsis
                                type="secondary"
                                size="small"
                                children={
                                    isFolder
                                        ? `${item.files.length} ${
                                              item.files.length > 1
                                                  ? t("fichiers")
                                                  : t("fichier").toLowerCase()
                                          } | ${item.projects.length} ${
                                              item.projects.length > 1
                                                  ? t("dossiers")
                                                  : t("dossier").toLowerCase()
                                          }`
                                        : item.viewedDate
                                          ? getLastOpenBetweenTwoDates(
                                                Date.now(),
                                                item.viewedDate,
                                            )
                                          : `${
                                                (item.author?.firstname || "") +
                                                " " +
                                                (item.author?.lastname || "")
                                            } ${
                                                item.author ? "|" : ""
                                            } ${fromDateToStringDate(
                                                item.creationDate,
                                            )}`
                                }
                            />
                        </Flex>

                        {!selectedFiles?.length > 0 && (
                            <OptionsComponent
                                file={item}
                                project={item}
                                profile={profile}
                                refreshAction={refreshAction}
                                showInfosInPanel={onItemClick}
                                userRoles={fileUserRoles}
                                setRenderDocument={setRenderDocument}
                                isRenderDocument={isRenderDocument}
                                renderDrawer={true}
                            />
                        )}
                    </Flex>
                    {!isFolder &&
                        hasVersions &&
                        areVersionsVisible &&
                        item.versions
                            ?.filter((e) => e._id !== item._id)
                            ?.map((item) => (
                                <Flex
                                    className={
                                        currentItem?._id === item._id
                                            ? "opacity-100"
                                            : "opacity-50 hover:opacity-100 group"
                                    }
                                    key={item._id}
                                    style={{
                                        backgroundColor:
                                            currentItem?._id === item._id
                                                ? token.colorPrimaryBg
                                                : "initial",
                                        border:
                                            currentItem?._id === item._id
                                                ? "1px solid " +
                                                  token.colorPrimaryBgHover
                                                : "1px solid transparent",
                                        borderRadius: 8,
                                        // padding: "4px 0 4px 6px",
                                    }}
                                    gap={16}
                                    align="center"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        // e.preventDefault();
                                        onItemClick(item._id, false);
                                    }}
                                    onDoubleClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleDoubleClick(item._id);
                                    }}
                                >
                                    <ExtensionImageContent
                                        file={item}
                                        width={30}
                                    />

                                    <Flex
                                        vertical
                                        style={{ flex: 1, display: "grid" }}
                                    >
                                        <Text
                                            strong
                                            ellipsis
                                            size="small"
                                            children={
                                                item.title +
                                                (item.file
                                                    ? "." +
                                                      getFileExtension(
                                                          item.file,
                                                      )
                                                    : item.compiledFiles
                                                            ?.length > 0
                                                      ? ".ifc"
                                                      : "")
                                            }
                                            title={
                                                item.title +
                                                (item.file
                                                    ? "." +
                                                      getFileExtension(
                                                          item.file,
                                                      )
                                                    : item.compiledFiles
                                                            ?.length > 0
                                                      ? ".ifc"
                                                      : "")
                                            }
                                        />
                                        {/* <Text
                                            type="secondary"
                                            ellipsis
                                            size="small"
                                            children={fromDateToStringDate(
                                                item.creationDate
                                            )}
                                        /> */}
                                    </Flex>

                                    {!selectedFiles?.length > 0 && (
                                        <div
                                            className={
                                                "cursor-pointer " +
                                                (currentItem?._id === item._id
                                                    ? ""
                                                    : "group-hover:opacity-100 opacity-0")
                                            }
                                        >
                                            <OptionsFile
                                                file={item}
                                                profile={profile}
                                                refreshAction={refreshAction}
                                                showInfosInPanel={onItemClick}
                                                userRoles={fileUserRoles}
                                                setRenderDocument={
                                                    setRenderDocument
                                                }
                                                isRenderDocument={
                                                    isRenderDocument
                                                }
                                                renderDrawer={true}
                                            >
                                                <Button
                                                    shape="circle"
                                                    type="text"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <MaterialIcons name="more_vert" />
                                                </Button>
                                            </OptionsFile>
                                        </div>
                                    )}
                                </Flex>
                            ))}
                    {addSelectedFile && (
                        <Checkbox
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                transform: "scale(1.5)",
                                zIndex: 2,
                            }}
                            onChange={(e) => {
                                handleSelection(e);
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            checked={selectedFiles.some(
                                (selectedFile) => selectedFile._id === item._id,
                            )}
                        />
                    )}
                </Card>
            </OptionsComponent>

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                    successText={confirmationModal.successText}
                    errorText={confirmationModal.errorText}
                    customWidth={confirmationModal.customWidth}
                />
            )}

            {isRenderDocument && (
                <DocumentReader
                    fileId={
                        usePublicLink && item.publicToken
                            ? item.publicToken
                            : currentItem?._id || item._id
                    }
                    closeReader={closeReader}
                    profile={profile}
                    refreshAction={refreshAction}
                    usePublicLink={usePublicLink}
                />
            )}
        </Fragment>
    );
};

export default CardEntity;
