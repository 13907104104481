import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, Form, Input, Radio, Row, Select, theme } from "antd";

export function StateAnswer({ title, index, options = ["Non", "En attente", "Oui"], required, disabled }) {
    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}>
            <Radio.Group
                disabled={disabled}
                style={{
                    fontWeight: "bold",
                    display: "flex",
                    flexWrap: "wrap",
                }}
                size="large"
                block
                optionType="button"
                buttonStyle="solid"
                className="btn-group-form-state">
                <Radio.Button
                    style={{
                        height: "auto",
                        whiteSpace: "pre-wrap",
                        maxWidth: "100%",
                    }}
                    className="btn-success"
                    value={0}>
                    {options[0]}
                </Radio.Button>
                <Radio.Button
                    style={{
                        height: "auto",
                        whiteSpace: "pre-wrap",
                        maxWidth: "100%",
                    }}
                    value={1}>
                    {options[1]}
                </Radio.Button>
                <Radio.Button
                    style={{
                        height: "auto",
                        maxWidth: "100%",
                        whiteSpace: "pre-wrap",
                    }}
                    value={2}>
                    {options[2]}
                </Radio.Button>
            </Radio.Group>
        </Form.Item>
    );
}
export function StateTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Valeur par défaut :" name={[index, "defaultValue"]}>
                    <Select
                        placeholder={"Valeur par défaut"}
                        defaultActiveFirstOption
                        options={[
                            {
                                value: null,
                                label: <Text>Aucune</Text>,
                            },
                            {
                                value: 0,
                                label: (
                                    <Text bold type="danger">
                                        Option 1
                                    </Text>
                                ),
                            },
                            {
                                value: 1,
                                label: (
                                    <Text bold type="warning">
                                        Option 2
                                    </Text>
                                ),
                            },
                            {
                                value: 2,
                                label: (
                                    <Text bold type="success">
                                        Option 3
                                    </Text>
                                ),
                            },
                        ]}
                    />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label={
                        <Text bold type="danger">
                            Option 1 :
                        </Text>
                    }
                    name={[index, "options", 0]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label={
                        <Text bold type="warning">
                            Option 2 :
                        </Text>
                    }
                    name={[index, "options", 1]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
            <Col span={8}>
                <Form.Item
                    label={
                        <Text bold type="success">
                            Option 3 :
                        </Text>
                    }
                    name={[index, "options", 2]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
        </Row>
    );
}

export function StatePrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.options[+field.value]}
        </TextPrint>
    );
}
