import { Link, Image as PrintImage, View } from "@react-pdf/renderer";
import axios from "axios";

import { useEffect, useState } from "react";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, Flex, Form, Image, Input, Row, Switch, Upload, theme } from "antd";

export function PictureAnswer({ title, index, options, required, disabled }) {
    const form = Form.useFormInstance();

    const isMultiple = !!options[0];

    const normFile = ({ file, fileList }) => {
        if (isMultiple) return fileList.map((e) => e.originFileObj || e);
        else return [file];
    };

    const fileList = form.getFieldValue(["fields", index, "value"]);
    const [dynamicFileList, setDynamicFileList] = useState([]);

    const [initPictures, setInitPictures] = useState(false);
    useEffect(() => {
        if (fileList) {
            if (!initPictures) {
                formatFileList(fileList).then((res) => setDynamicFileList(res));
            }
            setInitPictures(true);
        }
    }, [initPictures, fileList]);

    const formatFileList = async (fileList) => {
        for (let i = 0; i < fileList.length; i++) {
            let file = fileList[i];
            if (typeof file === "string") {
                const { data } = await axios.post(apiURL.getFilePresignedWithoutId, {
                    fileName: file,
                });
                fileList[i] = {
                    uid: file,
                    name: file,
                    status: "done",
                    url: data,
                };
            }
        }
        return fileList;
    };

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    return (
        <>
            <Form.Item
                getValueFromEvent={normFile}
                name={["fields", index, "value"]}
                label={<Text bold>{title} :</Text>}
                rules={[
                    {
                        required,
                    },
                ]}>
                <Upload
                    disabled={disabled}
                    fileList={dynamicFileList}
                    listType="picture-card"
                    beforeUpload={() => false}
                    onPreview={handlePreview}
                    onChange={({ fileList }) => {
                        setDynamicFileList(fileList);
                    }}>
                    {(isMultiple || dynamicFileList.length === 0) && !disabled ? (
                        <Flex vertical>
                            <MaterialIcons name="add_a_photo" />
                            <Text>Ajouter</Text>
                        </Flex>
                    ) : null}
                </Upload>
            </Form.Item>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
}

export function PictureTemplate({ index }) {
    const { token } = theme.useToken();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input placeholder={"Titre du champ"} />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Plusieurs images :" name={[index, "options", 0]} valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Col>
        </Row>
    );
}

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export function PicturePrint({ field, index }) {
    return (
        <View
            style={{
                ...pdfStyles.flexLine,
                gap: 5,
            }}>
            {field.value.map((e, i) => (
                <Link key={i} src={e}>
                    <PrintImage
                        src={{
                            uri: e,
                        }}
                        style={{
                            width: 180,
                            objectFit: "contain",
                            objectPosition: "center",
                        }}
                    />
                </Link>
            ))}
        </View>
    );
}
