import { Fragment, useEffect, useRef, useState } from "react";
import { pdfjs } from "react-pdf";

import { getFileType, isImageFile, isVideoFile } from "helpers/file";
import { getFileExtensionIcon, getPreviewPresignedFile } from "helpers/image";

import FolderIcon from "components/utils/FolderIcon";

import { Flex } from "antd";

const FileImage = ({ file, isFromInfos = false, user, comment }) => {
    const fileImageRef = useRef(null);
    const hasVersions = file.versions?.length > 0;

    const [presignedFile, setPresignedFile] = useState("");
    const [isPdfLoaded, setPdfLoaded] = useState(false);
    const canvaRef = useRef(null);
    const renderTaskRef = useRef(null); // Référence au PDFRenderTask pour annuler le rendu

    const renderPage = async (url) => {
        const canvas = canvaRef.current;
        if (canvas) {
            const ctx = canvas.getContext("2d", { willReadFrequently: true });

            const pdfdocument = await pdfjs.getDocument(url).promise;
            const page = await pdfdocument.getPage(1);
            const viewport = page.getViewport({ scale: 0.8 }); // Définir la mise à l'échelle
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            // ctx.willReadFrequently = true;

            const renderContext = {
                canvasContext: ctx,
                viewport: viewport,
            };
            renderTaskRef.current = page.render(renderContext);
            try {
                await renderTaskRef.current.promise; // Attendre la fin du rendu
                setPdfLoaded(true);
            } catch (error) {}
        }
    };

    useEffect(() => {
        if (file) {
            if (
                isImageFile(file) ||
                getFileType(file) === "PDF" ||
                isVideoFile(file) ||
                file.previewImage
            ) {
                const getPresigned = async () => {
                    const presignedFile = await getPreviewPresignedFile(
                        file._id,
                    );
                    setPresignedFile(presignedFile);
                    if (getFileType(file) === "PDF" && !file.previewImage)
                        renderPage(presignedFile);
                };

                getPresigned();

                // Nettoyage pour annuler le rendu en cours si le composant est démonté
                return () => {
                    if (renderTaskRef.current) {
                        renderTaskRef.current.cancel();
                    }
                };
            } else setPresignedFile("");
        }
    }, [file._id, file.previewImage]);

    const extensionImageContent = (multiple = false) => (
        <div
            className={
                "flex background-img w-full bg-indigo-50 m-auto items-center justify-center h-[180px]" +
                (isFromInfos ? " rounded-lg" : " rounded-t-lg")
            }
        >
            {multiple ? (
                <Fragment>
                    <img
                        loading="lazy"
                        src={getFileExtensionIcon(file)}
                        alt=""
                        className="w-[100px] mx-auto absolute ml-12 opacity-20"
                    ></img>
                    <img
                        loading="lazy"
                        src={getFileExtensionIcon(file)}
                        alt=""
                        className="w-[100px] mx-auto absolute ml-6 opacity-50"
                    ></img>
                    <img
                        loading="lazy"
                        src={getFileExtensionIcon(file)}
                        alt=""
                        className="w-[100px] mx-auto absolute"
                    ></img>
                </Fragment>
            ) : file.files ? (
                <FolderIcon
                    // isChildTender={file.isChildTender}
                    isResponseTender={file.isResponseTender}
                    isShared={
                        (file.author && file.author._id !== user._id) ||
                        (!file.author && file.userEmail !== user.email)
                    }
                    isTender={!!file.tender}
                    fillColor={file.color && file.color}
                />
            ) : (
                <img
                    src={getFileExtensionIcon(file)}
                    alt=""
                    className="w-[100px] mx-auto"
                />
            )}
        </div>
    );

    return (
        <div
            ref={fileImageRef}
            className={"relative min-h-[180px] max-h-[180px] overflow-y-hidden"}
        >
            {getFileType(file) === "PDF" && !file.previewImage ? (
                <Fragment>
                    <canvas
                        ref={canvaRef}
                        style={{
                            width: "100%",
                            height: "100%",
                            display: isPdfLoaded ? "block" : "none",
                        }}
                    />
                    {!isPdfLoaded && extensionImageContent(hasVersions)}
                </Fragment>
            ) : !file.compiledFiles ||
              file.compiledFiles.length === 0 ||
              (file.compiledFiles.length > 0 && file.previewImage) ? (
                <Fragment>
                    {isVideoFile(file) && presignedFile ? (
                        <video preload="metadata" className="h-[180px]">
                            <source src={presignedFile} type="video/mp4" />
                        </video>
                    ) : (
                        <div
                            style={{
                                backgroundImage:
                                    comment &&
                                    comment.BCFContent &&
                                    comment.BCFContent.image
                                        ? "url(" +
                                          comment.BCFContent.image +
                                          ")"
                                        : presignedFile !== "" &&
                                          "url(" + presignedFile + ")",
                                backgroundPosition:
                                    getFileType(file) === "PDF" &&
                                    file.previewImage
                                        ? "top"
                                        : "center",
                                backgroundSize:
                                    // INCASE Image contain
                                    // imagesTypes.includes(
                                    //     getFileExtension(file.file),
                                    // )
                                    //     ? "contain"
                                    //     :
                                    "cover",
                            }}
                            className={
                                "background-img w-full m-auto h-[180px]" +
                                (isFromInfos ? " rounded-lg" : " rounded-t-lg")
                            }
                        >
                            {!file.previewImage &&
                                presignedFile === "" &&
                                extensionImageContent(hasVersions)}
                        </div>
                    )}
                </Fragment>
            ) : (
                extensionImageContent(hasVersions)
            )}
        </div>
    );
};

export const ExtensionImageContent = ({ file, width = "100%" }) => {
    if (file.versions?.length && !file.isOldVersion)
        return (
            <Flex style={{ position: "relative", width }}>
                <img
                    loading="lazy"
                    src={getFileExtensionIcon(file)}
                    alt=""
                    style={{
                        position: "relative",
                        width: width,
                        height: width,
                        left: "0%",
                        zIndex: 1,
                    }}
                />
                <img
                    loading="lazy"
                    src={getFileExtensionIcon(file)}
                    alt=""
                    style={{
                        position: "relative",
                        width: width,
                        height: width,
                        left: "-80%",
                        opacity: 0.5,
                    }}
                />
                <img
                    loading="lazy"
                    src={getFileExtensionIcon(file)}
                    alt=""
                    style={{
                        position: "relative",
                        width: width,
                        height: width,
                        left: "-160%",
                        opacity: 0.25,
                    }}
                />
            </Flex>
        );
    if (!file.files)
        return (
            <img
                loading="lazy"
                style={{ width }}
                src={getFileExtensionIcon(file)}
                alt=""
            />
        );

    return null;
};

export default FileImage;
