import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fromDateToStringDate, getLastOpenBetweenTwoDates } from "helpers/date";
import {
    checkIfCompiledFile,
    getFileExtension,
    getFileType,
} from "helpers/file";
import { formatSizeToDisplay, getProjectFileSize } from "helpers/size";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { PopupContext } from "layout/LayoutPopup";

import FileImage from "./FileImage";

import { Card, Divider, Flex, Switch, theme } from "antd";

const FileInfos = ({ file, isFromDocument = false }) => {
    const isCompiled = checkIfCompiledFile(file);

    return (
        <Flex vertical gap="small">
            <Text bold size="sm">
                {file.title +
                    "." +
                    (file.compiledFiles.length > 0
                        ? "ifc"
                        : getFileExtension(file.file))}
            </Text>

            {!isFromDocument && <FileImage file={file} isFromInfos={true} />}

            <FileProperties file={file} />

            {isCompiled && <FileCompiled file={file} />}
        </Flex>
    );
};

const FileCompiled = ({ file }) => {
    const { t } = useTranslation();

    const { token } = theme.useToken();
    return (
        <Fragment>
            <Divider />
            <Flex vertical gap={16}>
                <Text bold>{t("fichiersConcernes")}</Text>
                {file.compiledFiles.map((compiledFile) => (
                    <Card
                        onClick={() =>
                            window.open(`/viewer/${compiledFile._id}`)
                        }
                        hoverable
                        key={compiledFile._id}
                        bordered
                        size="small"
                        cover={
                            <FileImage
                                hasCurrent={true}
                                file={compiledFile}
                                user={{}}
                            />
                        }
                        styles={{
                            body: {
                                padding: token.paddingSM,
                            },
                        }}
                    >
                        <Flex vertical style={{ flex: 1, display: "grid" }}>
                            <Text
                                strong
                                ellipsis
                                children={compiledFile.title}
                                size="small"
                                title={compiledFile.title}
                            />
                            <Text
                                ellipsis
                                type="secondary"
                                size="small"
                                children={
                                    compiledFile.viewedDate
                                        ? getLastOpenBetweenTwoDates(
                                              Date.now(),
                                              compiledFile.viewedDate,
                                          )
                                        : `${
                                              (compiledFile.author?.firstname ||
                                                  "") +
                                              " " +
                                              (compiledFile.author?.lastname ||
                                                  "")
                                          } ${
                                              compiledFile.author ? "|" : ""
                                          } ${fromDateToStringDate(
                                              compiledFile.creationDate,
                                          )}`
                                }
                            />
                        </Flex>
                    </Card>
                ))}
            </Flex>
        </Fragment>
    );
};

const FileProperties = ({ file }) => {
    const { t } = useTranslation();

    return (
        <Flex vertical gap="small">
            <Text size="sm" ellipsis>
                <b>{t("dossier")} - </b>
                {file.project.title}
            </Text>
            <Text size="sm" ellipsis>
                <b>{t("type")} - </b>
                {getFileType(file)}
            </Text>
            <Text size="sm" ellipsis>
                <b>{t("proprietaire")} - </b>
                {file.author
                    ? file.author.firstname + " " + file.author.lastname
                    : t("inconnu")}
            </Text>
            {(file.isPasswordAccess ||
                (file.publicToken && file.expireTmpAccessToken)) && (
                <>
                    <Text size="sm" ellipsis>
                        <b>{t("restrictionDacces")} :</b>
                    </Text>
                    {file.isPasswordAccess && (
                        <Flex gap={4}>
                            <MaterialIcons size="xs" name="lock" />
                            <Text size="sm" ellipsis>
                                {t("motDePasse")}
                            </Text>
                        </Flex>
                    )}
                    {file.publicToken && file.expireTmpAccessToken && (
                        <Flex align="center">
                            <MaterialIcons size="sm" name="calendar_month" />
                            <Text size="sm" ellipsis>
                                {t("possedeUnLienDaccesTemporaire")}
                            </Text>
                        </Flex>
                    )}
                </>
            )}
            <Text size="sm" ellipsis>
                <b>{t("derniereModification")} - </b>
                {fromDateToStringDate(
                    file.updatedDate ? file.updatedDate : file.creationDate,
                )}
            </Text>
            <Text size="sm" ellipsis>
                <b>{t("dateDeCreation")} - </b>
                {fromDateToStringDate(file.creationDate)}
            </Text>

            <SizeFile file={file} />
            <SwitchIsPlanGeneral file={file} />
        </Flex>
    );
};

const SizeFile = ({ file }) => {
    const isCompiled = checkIfCompiledFile(file);
    const [size, setSize] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isCompiled) {
            getProjectFileSize(file).then((resSize) => setSize(resSize));
        } else setSize(null);
    }, [file, isCompiled]);

    if (size)
        return (
            <Text size="sm" ellipsis>
                <b>{t("taille")} - </b>
                {formatSizeToDisplay(size)}
            </Text>
        );
};

const SwitchIsPlanGeneral = ({ file }) => {
    const { t } = useTranslation();
    const { wrapperSyncDrive } = useContext(PopupContext);
    const { permissionsTools } = useProfileStore();

    const isCompiled = checkIfCompiledFile(file);
    const hasPermission = permissionsTools.plansAdmin.canView;
    const isPdf = getFileType(file) === "PDF";

    const [isPlan, setIsPlan] = useState(file.isPlan);
    useEffect(() => {
        setIsPlan(file.isPlan);
    }, [file]);

    if (!isCompiled && hasPermission && isPdf)
        return (
            <Flex gap={4}>
                <MaterialIcons size="xs" name="edit_location_alt" />
                <Text size="sm" ellipsis>
                    <b>{t("plan")} - </b>
                </Text>
                <Switch
                    size="small"
                    value={isPlan}
                    onChange={(value) => {
                        wrapperSyncDrive(async () => {
                            await axios.post(apiURL.updateProjectFile, {
                                id: file._id,
                                isPlan: value,
                            });
                            setIsPlan(value);
                        });
                    }}
                />
            </Flex>
        );
};

export default FileInfos;
