import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import apiURL from "utils/apiURL";

import { DriveToolsContext } from "components/drive/Drive";
import { useTimesheetStore } from "components/timesheet/TimesheetStore";
import Spinner from "components/utils/Spinner";

import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";
import TableRowData from "./TableRowData";
import TableNewRow from "./TableRowNew";
import {
    applyConfiguration,
    applyDetails,
    applyFiltersProjects,
    applyFiltersUsers,
    applyGroups,
    applyMergeLines,
} from "./manageTable";

const Table = ({
    getFunction = async () => [],
    addFunction = false,
    editFunction = async () => {},
    deleteFunction = async () => {},
}) => {
    const { toolsData } = useContext(DriveToolsContext);
    const { groupBy } = useTimesheetStore();
    const { scheduleConfiguration } = useProfileStore();

    const [dataList, setDataList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [inlineLoader, setInlineLoader] = useState(true);
    const [emptyRowsConfig, setEmptyRowsConfig] = useState();
    const [absences, setAbsences] = useState();

    const [updatedTime, setUpdatedTime] = useState(new Date());
    const [expandedLines, setExpandedLines] = useState([]);
    const [expandedGroups, setExpandedGroups] = useState([]);
    const [savedExpanded, setSavedExpanded] = useState(false);
    const [totalOpen, setTotalOpen] = useState(false);

    // Pagination functions
    const filters = toolsData?.filters;
    const pagination = toolsData?.pagination;

    useEffect(() => {
        if (savedExpanded) {
            sessionStorage.setItem("expandedLines", JSON.stringify(expandedLines));
            sessionStorage.setItem("expandedGroups", JSON.stringify(expandedGroups));
        } else {
            Promise.all([
                JSON.parse(sessionStorage.getItem("expandedLines")) || [],
                JSON.parse(sessionStorage.getItem("expandedGroups")) || [],
            ]).then((e) => {
                setExpandedLines(e[0]);
                setExpandedGroups(e[1]);
                setSavedExpanded(true);
            });
        }
    }, [expandedLines, expandedGroups, savedExpanded]);

    useEffect(() => {
        setInlineLoader(true);
        setUpdatedTime(new Date());
        setExpandedLines([]);
        getFunction({ filters, pagination })
            .then(({ entries, users, projects, tasks, fields }) => {
                setEmptyRowsConfig({
                    users,
                    projects,
                    tasks,
                    fields,
                });
                setDataList(entries);
            })
            .finally(() => setInlineLoader(false));
    }, [filters.dateRange, pagination]);

    const getAbsences = useCallback(() => {
        if (dataList?.length) {
            const users = dataList
                .map((e) => e.author)
                .filter((item, i, allItems) => i === allItems.findIndex((k) => k._id === item._id));
            Promise.all(
                users.map((e) =>
                    axios.post(apiURL.getAbsencesAvailables, {
                        user: e._id,
                    }),
                ),
            ).then((res) => {
                setAbsences(res.map((e) => e.data).flat());
            });
        }
    }, [dataList]);

    useEffect(() => {
        if (dataList?.length) {
            getAbsences();
        }
    }, [dataList]);

    const formatedDataList = useMemo(() => {
        let result = [...dataList];
        if (emptyRowsConfig) result = applyMergeLines(result, emptyRowsConfig); // Required

        if (filters.users?.length > 0) {
            result = applyFiltersUsers(result, filters.users);
        }
        if (filters.projects?.length > 0) {
            result = applyFiltersProjects(result, filters.projects);
        }
        if (groupBy?.length > 0) {
            result = applyGroups(result, groupBy, expandedGroups);
        }
        if (expandedLines?.length > 0 && emptyRowsConfig) {
            result = applyDetails(result, expandedLines, emptyRowsConfig);
        }
        if (scheduleConfiguration) {
            result = applyConfiguration(result, scheduleConfiguration);
        }

        return result;
    }, [
        addFunction,
        emptyRowsConfig,
        groupBy,
        filters.users,
        filters.projects,
        expandedLines,
        expandedGroups,
        dataList,
        scheduleConfiguration,
    ]);

    const onEditInline = useCallback(
        async (newValue) => {
            setLoader(true);
            const data = await editFunction(newValue);
            if (data) setDataList((e) => e.map((el) => (el._id === newValue._id ? { ...newValue, ...data } : el)));
            setLoader(false);
        },
        [setDataList, setLoader, editFunction],
    );

    const onAddInline = useCallback(
        async (newValue) => {
            setLoader(true);
            const data = await addFunction(newValue);
            if (data) setDataList((e) => [...e, data]);
            setLoader(false);
        },
        [setDataList, setLoader, addFunction],
    );

    const onDeleteInline = useCallback(
        async (id) => {
            setLoader(true);
            const data = await deleteFunction(id);
            if (data) setDataList((e) => e.filter((el) => el._id !== id));
            setLoader(false);
        },
        [setDataList, setLoader, deleteFunction],
    );

    const daysInRange = useMemo(() => {
        if (scheduleConfiguration)
            switch (filters?.dateRange) {
                case "byDay":
                    const day = moment(pagination);
                    return [
                        {
                            date: day.format("YYYY-MM-DD"),
                            isWeekend: day.weekday() === 5 || day.weekday() === 6,
                            isToday: day.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"),
                            isVacation: scheduleConfiguration.vacations.find(
                                (e) => moment(day).isAfter(e.startDate) && moment(day).isBefore(e.endDate),
                            ),
                            isHoliday: scheduleConfiguration.holidays.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                            isCompendated: scheduleConfiguration.compensated.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                        },
                    ];
                case "byWeek":
                    return [...Array(7)].map((e, i) => {
                        const day = moment(pagination).weekday(0).add(i, "day");
                        return {
                            date: day.format("YYYY-MM-DD"),
                            isWeekend: day.weekday() === 5 || day.weekday() === 6,
                            isToday: day.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"),
                            isVacation: scheduleConfiguration.vacations.find(
                                (e) => moment(day).isAfter(e.startDate) && moment(day).isBefore(e.endDate),
                            ),
                            isHoliday: scheduleConfiguration.holidays.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                            isCompendated: scheduleConfiguration.compensated.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                        };
                    });
                case "byMonth":
                    return [...Array(moment(pagination).daysInMonth())].map((e, i) => {
                        const day = moment(pagination).set("date", i + 1);
                        return {
                            date: day.format("YYYY-MM-DD"),
                            isWeekend: day.weekday() === 5 || day.weekday() === 6,
                            isToday: day.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD"),
                            isVacation: scheduleConfiguration.vacations.find(
                                (e) => moment(day).isAfter(e.startDate) && moment(day).isBefore(e.endDate),
                            ),
                            isHoliday: scheduleConfiguration.holidays.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                            isCompendated: scheduleConfiguration.compensated.filter(
                                (e) => moment(e.date).format("YYYY-MM-DD") === day.format("YYYY-MM-DD"),
                            ),
                        };
                    });
                default:
                    return [];
            }
        else return [];
    }, [filters, pagination, scheduleConfiguration]);

    const [addMultiple, setAddMultiple] = useState();

    return (
        <ScrollTimeLineWrapper awareToChange={{ formatedDataList, totalOpen, daysInRange }}>
            {loader && <Spinner />}
            <table
                id="table-timeline"
                className="border-separate border-spacing-0 table-fixed"
                style={{
                    paddingBottom: 60,
                }}>
                <TableHeader
                    filterDateRange={filters?.dateRange}
                    updatedTime={updatedTime}
                    daysInRange={daysInRange}
                    formatedDataList={formatedDataList}
                />

                <tbody
                    onMouseLeave={() => {
                        setAddMultiple();
                    }}>
                    {inlineLoader && (
                        <tr>
                            <td></td>
                            <td className="py-4" colSpan="8">
                                <Spinner isSmall />
                            </td>
                        </tr>
                    )}

                    {!inlineLoader &&
                        !!formatedDataList?.length &&
                        formatedDataList.map((dataLine, i) => (
                            <TableRowData
                                key={dataLine.uniqueId}
                                index={i}
                                dataLine={dataLine}
                                daysInRange={daysInRange}
                                isLineExpanded={[...expandedLines, ...expandedGroups].find(
                                    (e) => e === dataLine.uniqueId,
                                )}
                                setExpandedLines={setExpandedLines}
                                setExpandedGroups={setExpandedGroups}
                                onEditInline={onEditInline}
                                onAddInline={onAddInline}
                                configuration={scheduleConfiguration}
                                addMultiple={addMultiple}
                                setAddMultiple={setAddMultiple}
                                onDeleteInline={onDeleteInline}
                                absences={absences}
                                getAbsences={getAbsences}
                            />
                        ))}
                </tbody>
                <tfoot className="bg-slate-50">
                    <TableNewRow onAddInline={onAddInline} />
                    <TableFooter
                        filterDateRange={filters?.dateRange}
                        totalOpen={totalOpen}
                        setTotalOpen={setTotalOpen}
                        daysInRange={daysInRange}
                        formatedDataList={formatedDataList}
                    />
                </tfoot>
            </table>
        </ScrollTimeLineWrapper>
    );
};

const ScrollTimeLineWrapper = ({ children, awareToChange }) => {
    const wheelEvent = (e) => {
        if (document.querySelector(".recap")?.contains(e.target)) {
        } else {
            document.querySelectorAll(".timeline-row").forEach((el) => {
                el.scrollLeft += e.deltaY < 0 ? -30 : 30;
            });
            e.preventDefault();
            e.stopPropagation();
        }
    };

    const scrollEvent = (e) => {
        document.querySelectorAll(".timeline-row").forEach((el) => {
            el.scrollLeft = e.target.scrollLeft;
        });
        e.preventDefault();
        e.stopPropagation();
    };

    useEffect(() => {
        document.querySelectorAll(".timeline-row").forEach((el) => {
            el.addEventListener("scroll", scrollEvent);
            el.parentNode.addEventListener("wheel", wheelEvent);
            el.scrollLeft = document.querySelector(".timeline-row").scrollLeft;
        });

        return () => {
            document.querySelectorAll(".timeline-row").forEach((el) => {
                el?.parentNode?.removeEventListener("scroll", scrollEvent);
                el?.parentNode?.removeEventListener("wheel", wheelEvent);
            });
        };
    }, [awareToChange]);

    return children;
};

export default Table;
