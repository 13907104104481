import axios from "axios";
import i18n from "i18next";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";

import ActionNomenclatureLine from "./ActionNomenclatureLine";
import DragOrder from "./DragOrder";

import { Button, Flex, theme } from "antd";

const ActionNomenclatureSection = ({ formData, setFormData }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    // Always be [{ code : ADD_NOMENCLATURE, ... }]
    const [actionTypes, setActionTypes] = useState([]);
    useEffect(() => {
        axios.get(apiURL.getAllAutomationActionTypes).then(({ data }) => {
            setActionTypes(data.filter((e) => e.code === "ADD_NOMENCLATURE"));
            setFormData((e) => ({
                ...e,
                actions: e.actions.map((a) => ({
                    ...a,
                    type: data.find((t) => t.code === "ADD_NOMENCLATURE")._id,
                })),
            }));
        });
    }, []);

    // Load action constraints with code containing "FILENAME_"
    const [actionConstraints, setActionConstraints] = useState([]);
    useEffect(() => {
        axios.get(apiURL.getAllAutomationActionConstraints).then(({ data }) =>
            setActionConstraints(
                data
                    .filter((type) => type.code.includes("FILENAME"))
                    .map((constraint) => ({
                        val: constraint._id,
                        label: constraint["title" + i18n.locale],
                        code: constraint.code,
                    })),
            ),
        );
    }, []);

    // Suppression des actions de type "FILENAME_GED" dans le cas ou l'on a plusieurs dossiers dans le trigger
    useEffect(() => {
        if (formData.trigger.folders.length > 1) {
            const gedActionConstraint = actionConstraints.find(
                (constraint) => constraint.code === "FILENAME_GED",
            );

            if (gedActionConstraint) {
                formData.actions.forEach((action, i) => {
                    if (action.constraint === gedActionConstraint.val)
                        deleteAction(i);
                });
            }
        }
    }, [formData.trigger.folders, actionConstraints]);

    const addAction = () => {
        setFormData({
            ...formData,
            actions: [
                ...formData.actions,
                {
                    type: actionTypes[0]._id,
                    constraint:
                        formData.actions.length === 0
                            ? actionConstraints.find(
                                  (e) => e.code === "FILENAME_FILE",
                              ).val
                            : actionConstraints.find(
                                  (e) => e.code === "FILENAME_INDEX_NUMBER",
                              ).val,
                    operator: "AND",
                    description: "",
                    nomenclature: null,
                },
            ],
        });
        setTimeout(() => {
            document
                .querySelector("#tabs-automations .overflow-y-auto")
                .scrollTo({
                    behavior: "smooth",
                    top: document.querySelector(
                        "#tabs-automations .overflow-y-auto",
                    ).scrollHeight,
                });
        }, 100);
    };

    const deleteAction = (index) => {
        setFormData({
            ...formData,
            actions: formData.actions.filter((e, i) => i !== index),
        });
    };

    const updateAction = (index, name, value) => {
        const sanitizedValue =
            typeof value === "string" ? value.replaceAll("_", "") : value;
        const updatedList = [...formData.actions];
        updatedList[index][name] = sanitizedValue;
        setFormData({
            ...formData,
            actions: updatedList,
        });
    };

    const orderAction = (value, source) => {
        setFormData({
            ...formData,
            actions: formData.actions.map((e) =>
                e === value ? source : e === source ? value : e,
            ),
        });
    };
    return (
        <Flex vertical gap={token.paddingLG}>
            <DragOrder formData={formData} orderAction={orderAction}>
                {formData.actions.map((action, i) => (
                    <ActionNomenclatureLine
                        key={i}
                        index={i}
                        action={action}
                        updateAction={updateAction}
                        deleteAction={deleteAction}
                        orderAction={orderAction}
                        actionConstraints={actionConstraints.filter(
                            (constraint) =>
                                formData.trigger.folders.length > 1
                                    ? constraint.code !== "FILENAME_GED"
                                    : true,
                        )}
                        canMove={formData.actions?.length > 1}
                        folderId={formData.trigger.folders[0]}
                    />
                ))}
            </DragOrder>
            <Button
                style={{
                    marginLeft: 150 + token.paddingLG,
                    justifyContent: "flex-start",
                    color: token.colorPrimary,
                }}
                icon={<MaterialIcons name="add" />}
                type="ghost"
                onClick={(e) => addAction()}
            >
                {t("ajouterUneRegle")}
            </Button>
        </Flex>
    );
};

export default ActionNomenclatureSection;
