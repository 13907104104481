import { t } from "i18next";

import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useMediaPredicate } from "react-media-hook";
import { useLocation } from "react-router";

import { secondsToStringToRemain } from "helpers/time";
import constants from "utils/constants";

import DarkThemeComponent from "components/common/DarkThemeComponent";
import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";

import DriveBackgroundItem from "./DriveBackgroundItem";

import { Button, Card, Empty, Flex, Upload, theme } from "antd";

const DriveBackgroundCard = ({ isPadding, currentProject }) => {
    const { token } = theme.useToken();
    const { pathname } = useLocation();
    const { onUpload } = useContext(DriveToolsContext);

    const [isVisible, setIsVisible] = useState(false);

    const {
        filesProgressIndicator,
        updateFileProgressIndicator,
        expanded,
        setExpanded,
    } = useContext(BackgroundActionsContext);

    useEffect(() => {
        if (filesProgressIndicator.length > 0) {
            setIsVisible(true);
        }
    }, [filesProgressIndicator.length]);

    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const closeCard = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setExpanded(false);
        setIsVisible(false);
    };

    const currentTotalLength = filesProgressIndicator.filter(
        (e) =>
            (e.status === "processing" || e.status === "calculating") &&
            e.percentage !== 100,
    )?.length;

    const getLengthString = useMemo(() => {
        if (currentTotalLength !== 0)
            return (
                t("importationDe") +
                " " +
                currentTotalLength +
                " " +
                t(currentTotalLength > 1 ? "elements" : "element")
            );
        return "";
    }, [currentTotalLength, filesProgressIndicator]);

    const getTimeIntervalString = useMemo(() => {
        if (filesProgressIndicator.some((e) => e.status === "processing")) {
            return secondsToStringToRemain(
                filesProgressIndicator.reduce((acc, curr) => {
                    if (curr.status === "processing")
                        acc += curr.secondsRemaining;
                    return acc;
                }, 0),
            );
        }
        if (filesProgressIndicator?.length === 0) return "";
        if (filesProgressIndicator.every((e) => e.status === "success"))
            return t("termine");
        return "";
    }, [filesProgressIndicator]);

    const refList = useRef(null);
    useEffect(() => {
        if (expanded) {
            refList.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [expanded]);

    // Event on quit page
    const abortAllUnfinishedStreams = useCallback(async () => {
        await Promise.all(
            filesProgressIndicator.map((file) => {
                if (file.status === "processing") {
                    updateFileProgressIndicator({
                        streamId: file.streamId,
                        status: "cancelled",
                    });
                    return file.abortStream();
                }
            }),
        );
    }, [filesProgressIndicator]);
    useEffect(() => {
        window.addEventListener("unload", abortAllUnfinishedStreams);
        return () => {
            window.removeEventListener("unload", abortAllUnfinishedStreams);
        };
    }, []);

    // Prevent quit page
    useEffect(() => {
        const unloadCallback = (event) => {
            if (
                filesProgressIndicator.some((e) =>
                    ["processing", "calculating"].includes(e.status),
                )
            ) {
                event.preventDefault();
                event.returnValue = "";
            }
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => {
            window.removeEventListener("beforeunload", unloadCallback);
        };
    }, [filesProgressIndicator]);

    return (
        <DarkThemeComponent>
            <Card
                bordered={false}
                style={{
                    position: "fixed",
                    width: mdMedia ? 500 : "100%",
                    zIndex: 9,
                    bottom:
                        isVisible && pathname.includes("/drive")
                            ? mdMedia
                                ? token.paddingLG + 20
                                : 0
                            : -500,
                    right: mdMedia
                        ? isPadding
                            ? 350 + 20
                            : token.paddingLG * 3
                        : 0,
                    padding: 0,
                }}
                styles={{
                    header: {
                        padding: 0,
                        overflow: "hidden",
                        minHeight: "auto",
                        borderBottom: "none",
                    },
                    body: {
                        padding: 0,
                    },
                }}
                title={
                    <Flex
                        justify="space-between"
                        align="center"
                        className="w-full cursor-pointer"
                        onClick={() => setExpanded(!expanded)}
                        style={{
                            padding: `${token.paddingLG / 2}px ${
                                token.paddingLG
                            }px`,
                        }}
                    >
                        <Text strong>Importations</Text>
                        <Flex align="center">
                            <Button
                                icon={
                                    <MaterialIcons
                                        name="chevron_left"
                                        style={{
                                            transform: expanded
                                                ? "rotate(-90deg)"
                                                : "rotate(0deg)",
                                            transitionDuration: "0.2s",
                                        }}
                                    />
                                }
                                type="text"
                                shape="circle"
                            />

                            <Button
                                onClick={closeCard}
                                disabled={currentTotalLength}
                                icon={
                                    currentTotalLength ? (
                                        <MaterialIcons
                                            name="progress_activity"
                                            spin
                                            isOutlined
                                        />
                                    ) : (
                                        <MaterialIcons name="close" />
                                    )
                                }
                                type="text"
                                shape="circle"
                            />
                        </Flex>
                    </Flex>
                }
            >
                <Flex
                    ref={refList}
                    vertical
                    style={{
                        maxHeight: expanded ? 300 : 0,
                        overflowY: "scroll",
                        transition: "max-height 0.5s",
                    }}
                >
                    {filesProgressIndicator?.length ? (
                        filesProgressIndicator
                            .sort((a, b) => a.percentage - b.percentage)
                            .map((indicator, index) => (
                                <DriveBackgroundItem
                                    key={index}
                                    {...indicator}
                                />
                            ))
                    ) : (
                        <Empty
                            description={t("aucuneImportationEnCours")}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </Flex>
                <Flex
                    justify="space-between"
                    align="center"
                    style={{
                        padding: `${token.paddingLG / 2}px ${
                            token.paddingLG
                        }px`,
                    }}
                >
                    <Flex vertical>
                        <Text>{getLengthString}</Text>
                        <Text type="secondary" size="small">
                            {getTimeIntervalString}
                        </Text>
                    </Flex>

                    {!!currentProject && (
                        <Upload
                            accept={constants.allowedExtensionsList}
                            multiple
                            showUploadList={false}
                            beforeUpload={(file) => {
                                onUpload([file], false);
                                return false;
                            }}
                        >
                            <Button type="text">Ajouter</Button>
                        </Upload>
                    )}
                </Flex>
            </Card>
        </DarkThemeComponent>
    );
};

export default DriveBackgroundCard;
