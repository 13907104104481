import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, Form, Input, Row, theme } from "antd";

export function TextShortAnswer({ index, title, required, disabled }) {
    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}>
            <Input disabled={disabled} />
        </Form.Item>
    );
}

export function TextShortTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Titre du champ :" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item label="Valeur par défaut :" name={[index, "defaultValue"]}>
                    <Input placeholder={"Valeur par défaut :"} />
                </Form.Item>
            </Col>
        </Row>
    );
}

export function TextShortPrint({ field }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.value}
        </TextPrint>
    );
}
