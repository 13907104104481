import { Text, View } from "@react-pdf/renderer";
import i18n from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import { getResultCell } from "components/timesheet/viewAdmin/tableTimeline/InputCellTime";

import { ModalContext } from "layout/LayoutModal";

import ModalExport from "./ModalExport";
import { pdfStyles } from "./TemplatePDF";

const ButtonExportTimesheet = ({
    dataSource,
    daysInRange,
    customerConfig,
    subtitle,
}) => {
    const { t } = useTranslation();
    const { scheduleConfiguration } = useProfileStore();

    const { addModalData } = useContext(ModalContext);

    const formatedProps = {
        dataSource: dataSource.map((e) => ({
            ...e,
            // INCASE  TimesheetExport / Ajout de la couleur de fond GROUP/DETAIL dans l'export
            // color: e.isGroup
            //     ? "rgb(241, 245, 249)"
            //     : e.isDetail
            //     ? "rgb(248, 250, 252)"
            //     : "white",
            ...Object.fromEntries(
                daysInRange.map((d, i) => [
                    `day${i}`,
                    getResultCell({
                        day: d,
                        dataLine: e,
                        configuration: scheduleConfiguration,
                    }),
                ]),
            ),
        })),
        columns: [
            {
                title: capitalize(t("utilisateur")),
                dataIndex: "userRender",
            },
            {
                title: t("dossier"),
                dataIndex: "projectRender",
            },
            {
                title: t("nomDeLaTache"),
                dataIndex: "taskRender",
            },
            ...daysInRange.map((e, i) => {
                return {
                    title: moment(e.date).locale(i18n.locale).format("DD"),
                    dataIndex: `day${i}`,
                    color: e.isToday
                        ? "rgb(239, 246, 255)"
                        : e.isWeekend
                          ? "rgb(239, 246, 255)"
                          : !!e.isHoliday?.length
                            ? "rgb(240, 253, 244)"
                            : !!e.isCompendated?.length
                              ? "rgb(254, 252, 232)"
                              : !!e.isVacation
                                ? "rgb(254, 242, 242)"
                                : null,
                    width: 22,
                };
            }),
        ],
        customerConfig,
        title: `Export ${t("feuilleDeTemps")} ${moment().format("DD-MM-YYYY")}`,
        subtitle,
        orientation: "landscape",
    };

    return {
        key: "byPeriod",
        label: `${t("exporter")} ${t("laVueActuelle")}`,
        onClick: () =>
            addModalData({
                key: "export",
                title: `${t("exporter")} ${t("laVueActuelle")}`,
                content: (
                    <ModalExport
                        {...formatedProps}
                        pdfContent={<PDFContentTimesheet {...formatedProps} />}
                    />
                ),
                width: 1200,
                okText: t("telecharger"),
            }),
    };
};

export const PDFContentTimesheet = ({ dataSource, columns }) =>
    dataSource.map((line, index) => (
        <View key={index} style={pdfStyles.flexLine}>
            {columns.map((col, i) => (
                <View
                    key={i}
                    style={{
                        ...pdfStyles.tableCell,
                        ...pdfStyles.tableFix,
                        width: col.width || null,
                        flex: col.width ? null : 1,
                        backgroundColor: col.color || line.color || "white",
                        fontSize: col.fontSize || 4,
                    }}
                >
                    <Text>{line[col.dataIndex]}</Text>
                </View>
            ))}
        </View>
    ));

export default ButtonExportTimesheet;
