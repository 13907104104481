import axios from "axios";

import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import InformationModal from "components/modal/InformationModal";

import NotificationsList from "./NotificationsList";

const Notifications = ({ profile }) => {
    const { t } = useTranslation();

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const buttonRef = useRef(null);

    const [notifications, setNotifications] = useState([]);
    const [isShowNotifications, setShowNotifications] = useState(false);

    useEffect(() => {
        const getNotifications = async () => {
            const resNotifications = await axios.get(
                apiURL.getProfileNotifications,
            );

            if (resNotifications && resNotifications.data)
                setNotifications(resNotifications.data);
        };

        if (profile) getNotifications(profile);
    }, [profile]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (buttonRef.current) {
            if (!buttonRef.current.contains(e.target))
                setTimeout(() => setShowNotifications(false));
            else setShowNotifications(true);
        }
    };

    return (
        <div ref={buttonRef} className="my-auto">
            <button type="button" className="relative my-auto flex p-1">
                <i
                    className="material-icons notranslate"
                    style={{ fontSize: "1.6rem" }}
                >
                    {notifications.length === 0
                        ? "notifications"
                        : "notifications_active"}
                </i>
                {notifications.length > 0 && (
                    <div className="absolute right-[-2px] top-[1px] w-[10px] h-[10px] rounded-full bg-red-500"></div>
                )}
            </button>
            {isShowNotifications && (
                <Fragment>
                    {mdMedia ? (
                        <NotificationsList
                            user={profile.user}
                            notifications={notifications}
                            setNotifications={setNotifications}
                        />
                    ) : (
                        <InformationModal
                            title={t("notifications")}
                            content={
                                <NotificationsList
                                    user={profile.user}
                                    notifications={notifications}
                                    setNotifications={setNotifications}
                                />
                            }
                            closeReset={setShowNotifications}
                        />
                    )}
                </Fragment>
            )}
        </div>
    );
};

export default Notifications;
