const Checkbox = ({
    text,
    name,
    value,
    onChange,
    errorMsg,
    removeMarging = false,
    type = "checkbox",
}) => {
    return (
        <div className={"cursor-pointer" + (!removeMarging ? " mb-4" : "")}>
            <div className="relative flex items-center">
                <input
                    id={name}
                    name={name}
                    type={type}
                    checked={value}
                    className="w-4 h-4"
                    onChange={(e) => onChange(e)}
                />
                <label
                    htmlFor={name}
                    className="ml-2 flex-1 text-sm font-medium whitespace-nowrap cursor-pointer"
                >
                    {text}
                </label>
            </div>
            {errorMsg && (
                <div className="absolute error-msg font-bold text-red-500 text-xs italic">
                    {errorMsg}
                </div>
            )}
        </div>
    );
};

export default Checkbox;
