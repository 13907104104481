import axios from "axios";

import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import NotificationItem from "./NotificationItem";

const NotificationsList = ({ user, notifications, setNotifications }) => {
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const { t } = useTranslation();

    const allMarkAsRead = async () => {
        const readNotification = await axios.get(
            apiURL.markAllNotificationsAsRead,
        );

        if (readNotification && readNotification.data) setNotifications([]);
    };

    return (
        <div
            className={
                mdMedia
                    ? "absolute top-full right-0 z-10 card w-[300px] max-h-[80vh] overflow-y-auto"
                    : ""
            }
        >
            <div className="hidden md:block">
                <div className="font-bold text-lg p-2">
                    {t("notifications")}
                </div>
                <hr />
            </div>

            {notifications.length === 0 ? (
                <div className="text-center p-4">{t("aucuneNotification")}</div>
            ) : (
                <Fragment>
                    {notifications.length > 0 && (
                        <div className="text-right">
                            <button
                                type="button"
                                className="text-primary text-sm p-2 mb-1"
                                onClick={(e) => allMarkAsRead()}
                            >
                                {t("toutMarquerCommeLu")}
                            </button>
                        </div>
                    )}

                    <ul className="text-sm">
                        {notifications.map((notification) => (
                            <li key={notification._id}>
                                <NotificationItem
                                    user={user}
                                    notification={notification}
                                    setNotifications={setNotifications}
                                />
                            </li>
                        ))}
                    </ul>
                </Fragment>
            )}
        </div>
    );
};

export default NotificationsList;
