import "@react-pdf-viewer/core/lib/styles/index.css";

import { useEffect, useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import constants from "utils/constants";

import Spinner from "components/utils/Spinner";

import PdfEnginePage from "./PdfEnginePage";
import PdfEnginePlan from "./PdfEnginePlan";

export default function PdfEngine({
    file,
    pdfUrl,
    setLoader,
    scale,
    closeReader,
    setScale,
    pdfHeight,
    pdfWidth,
}) {
    const [isPlan, setIsPlan] = useState(false);
    const [pdfDimensions, setPdfDimensions] = useState();
    const isPDFWider = pdfDimensions?.width > pdfDimensions?.height;
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    const getDimensions = async () => {
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1); // Get the first page
        const viewport = page.getViewport({ scale: 1 });
        const isFormatA4 =
            Number((viewport.height / viewport.width).toFixed(2)) <= 1.41 &&
            Number((viewport.height / viewport.width).toFixed(2)) >= 1.39;

        if (pdf.numPages > 1 || isFormatA4) {
            setIsPlan(false);
        } else {
            setIsPlan(true);
        }
        setPdfDimensions({
            width: viewport.width,
            height: viewport.height,
        });
    };
    useEffect(() => {
        getDimensions();
    }, [pdfUrl]);

    useEffect(() => {
        if (isPlan) {
            setScale(0.2);
        } else {
            if (mdMedia) setScale(0.4);
            else setScale(0.14);
        }
    }, [isPlan, mdMedia]);

    if (!pdfDimensions) return <Spinner />;
    return isPlan ? (
        <PdfEnginePlan
            file={file}
            scale={scale}
            pdfUrl={pdfUrl}
            setLoader={setLoader}
            closeReader={closeReader}
            setScale={setScale}
            pdfDimensions={pdfDimensions}
            pdfHeight={pdfHeight}
            pdfWidth={pdfWidth}
            isPDFWider={isPDFWider}
            mdMedia={mdMedia}
        />
    ) : (
        <PdfEnginePage
            scale={scale}
            file={file}
            pdfUrl={pdfUrl}
            setLoader={setLoader}
            closeReader={closeReader}
            setScale={setScale}
            pdfDimensions={pdfDimensions}
            pdfHeight={pdfHeight}
            pdfWidth={pdfWidth}
            mdMedia={mdMedia}
            isPDFWider={isPDFWider}
        />
    );
}
