import { useTranslation } from "react-i18next";

const SwitchButton = ({
    text,
    name,
    value,
    onChange,
    isDisabled,
    isUserOnly = false,
}) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-row">
            <label className="inline-flex items-center my-auto cursor-pointer w-full min-w-[36px]">
                <input
                    type="checkbox"
                    name={name}
                    checked={value}
                    className="sr-only peer"
                    disabled={isDisabled}
                    onChange={(e) => onChange(e)}
                />
                <div
                    className={
                        "relative min-w-[36px] w-9 h-5 peer-focus:outline-none rounded-full peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all border-gray-600" +
                        (isDisabled
                            ? " bg-gray-200 peer-checked:bg-slate-400"
                            : " bg-gray-700 peer-checked:bg-blue-600")
                    }
                ></div>
                <span
                    className={
                        "ml-3 text-sm font-medium" +
                        (!isDisabled ? " text-black" : " text-gray-300")
                    }
                >
                    {text}
                </span>
            </label>
            {isUserOnly && (
                <div>
                    <div
                        className="bg-yellow-400 w-[30px] h-[30px] rounded-full flex ml-2 hover:cursor-default"
                        title={t("fonctionnaliteReserveeAuxMembres")}
                    >
                        <i
                            className="material-icons notranslate text-white m-auto "
                            style={{
                                fontSize: "1.1rem",
                            }}
                        >
                            lock
                        </i>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SwitchButton;
