import { useEffect, useRef, useState } from "react";
import { useMediaPredicate } from "react-media-hook";

import { getElementScreenYPosition } from "helpers/window";
import Constants from "utils/constants";

const OptionMenu = ({
    options,
    contentOrientation = "bottom",
    customButton,
    forceFullWidth = false,
    fixTo = "left",
    alignTo = "right",
    addBgColor = false,
}) => {
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const [isOpen, setOpen] = useState(false);

    const refButton = useRef(null);
    const refMenu = useRef(null);

    // Si on clique ailleurs que sur le tooltip ou son contenu, on le ferme

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleClickOutside = (e) => {
        if (refMenu.current) {
            if (!refMenu.current.contains(e.target)) setOpen(false);
            else if (e.target.tagName === "UL") setOpen(true);
            else setOpen(false);
        }
    };

    return (
        options.filter((option) => option && option.content).length > 0 && (
            <div
                className={
                    "absolute bottom-0 p-1 text-left h-full flex" +
                    (alignTo === "left"
                        ? " left-0"
                        : alignTo === "right"
                          ? " right-0"
                          : "")
                }
            >
                <button
                    ref={refButton}
                    onClick={(e) => {
                        setOpen(!isOpen);
                        e.stopPropagation();
                    }}
                    className={
                        (!customButton
                            ? "flex hover:bg-slate-200 rounded-full my-auto w-[30px] h-[30px]"
                            : "") + (addBgColor ? " bg-slate-50" : "")
                    }
                >
                    {customButton ? (
                        customButton
                    ) : (
                        <i
                            className="material-icons notranslate m-auto"
                            style={{ fontSize: "1.4rem" }}
                        >
                            more_vert
                        </i>
                    )}
                </button>
                <ul
                    ref={refMenu}
                    className={
                        "card border-0 bottom-0 py-1 z-10 table" +
                        (!mdMedia || forceFullWidth
                            ? " fixed left-0 w-screen rounded-t-xl rounded-b-none z-20"
                            : " absolute left-full md:left-auto md:right-full" +
                              (fixTo === "left"
                                  ? " left-full md:left-auto md:right-full"
                                  : fixTo === "right"
                                    ? " right-full md:right-auto md:left-full"
                                    : fixTo === "top"
                                      ? " top-full md:top-auto md:bottom-full"
                                      : fixTo === "bottom"
                                        ? " bottom-full md:bottom-auto md:top-full "
                                        : "")) +
                        (!isOpen ? " hidden" : "") +
                        (mdMedia &&
                        !forceFullWidth &&
                        getElementScreenYPosition(
                            refButton.current,
                            contentOrientation,
                        ) === "top"
                            ? " bottom-0"
                            : mdMedia &&
                                !forceFullWidth &&
                                getElementScreenYPosition(
                                    refButton.current,
                                    contentOrientation,
                                ) === "bottom"
                              ? " top-0"
                              : "")
                    }
                >
                    {options
                        .filter((option) => option && option.content)
                        .map((option, i) => {
                            return (
                                <li
                                    key={i}
                                    className={
                                        "hover:bg-slate-100 whitespace-nowrap text-sm text-black" +
                                        (!mdMedia || forceFullWidth
                                            ? " p-1"
                                            : "")
                                    }
                                >
                                    {option.content}
                                </li>
                            );
                        })}
                </ul>
                {isOpen && (!mdMedia || forceFullWidth) && (
                    <div
                        className="opacity-50 fixed inset-0 z-10 bg-black h-full"
                        onClick={(e) => setOpen(false)}
                    ></div>
                )}
            </div>
        )
    );
};

export default OptionMenu;
