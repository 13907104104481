import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { fieldsTypes } from "helpers/forms";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { Card, Flex, theme } from "antd";

export default function RenderFieldItem({ _id, type }) {
    const { code, name, icon, group } = fieldsTypes?.find((field) => field.code === type) || {
        code: "",
        name: "",
        icon: "",
        group: "",
    };

    const { token } = theme.useToken();
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: _id,
        data: {
            type: "fields",
            item: {
                code,
                name,
                icon,
                group,
                _id,
            },
        },
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        color: getColorGroup(group, token),
    };

    return (
        <Card hoverable size="small" ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <Flex align="center" gap={6}>
                <MaterialIcons name={icon} />
                <Text bold>{name}</Text>
                <MaterialIcons name={"drag_indicator"} style={{ marginLeft: "auto" }} />
            </Flex>
        </Card>
    );
}

export const getColorGroup = (group, token) => {
    return group === "plain"
        ? token.colorSuccess
        : group === "input"
          ? token.colorPrimary
          : group === "custom"
            ? token.colorWarning
            : token.colorTextTertiary;
};
