import { useProfileStore } from "providers/ProviderProfile";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { capitalize } from "helpers/string";

import TabAutomation from "./TabAutomation";

import { Tabs } from "antd";

export const getPageAutomationsItems = (permissionsTools) => {
    return [
        (!permissionsTools || permissionsTools.automations.canViewVisas) && {
            title: "visa",
            route: "/automations/visa",
        },
        {
            title: "nomenclature",
            route: "/automations/nomenclature",
        },
    ];
};

const Automations = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { permissionsTools } = useProfileStore();

    const tabToRender = getPageAutomationsItems(permissionsTools).find(
        (e) => e.route === location.pathname,
    );

    // Prevent unknown page
    useEffect(() => {
        if (!tabToRender) navigate("/drive");
    }, [tabToRender]);

    // Manage title page
    const titlePage = capitalize(t(tabToRender?.title));
    useEffect(() => {
        document.title = titlePage + " - " + t("applicationTitle");
    }, [titlePage]);

    return (
        <Tabs
            id="tabs-automations"
            animated
            defaultActiveKey="1"
            destroyInactiveTabPane
            items={getPageAutomationsItems(permissionsTools).map((e) => ({
                label: capitalize(t(e.title)),
                key: e.route,
                children: <TabAutomation view={e.title} />,
            }))}
        />
    );
};

export default Automations;
