import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import { getFileType } from "helpers/file";

const DocEngine = ({ file }) => {
    const docs = [
        {
            uri: file.fileURL,
            fileType: getFileType(file).toLowerCase(),
            fileName: file.title,
        },
    ];

    return (
        <div className="h-[calc(100vh-100px)] md:h-[calc(100vh-70px)]">
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
        </div>
    );
};

export default DocEngine;
