import TextField from "components/input/TextField";

const RenderNumberField = ({ title, value, onChange }) => {
    return (
        <div className="w-max">
            <TextField
                text={title}
                placeholder={title}
                type="number"
                value={value || 0}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
};

export default RenderNumberField;
