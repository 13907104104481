import axios from "axios";
import moment from "moment";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";
import constants from "utils/constants";

import ModalCallTender from "components/call-tender/ModalCallTender";
import MaterialIcons from "components/common/MaterialIcons";
import IconButton from "components/input/IconButton";
import ModalCreateProject from "components/project/ModalCreateProject";
import ModalURLRedirection from "components/project/ModalURLRedirection";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";
import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { DriveToolsContext } from "./Drive";

import { Button, Dropdown, Flex } from "antd";

const NewActions = ({
    currentProject,
    closeSideMenu,
    userRoles,
    isTimesheetView = false,
    isFirstFolder = false,
}) => {
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");
    const { t } = useTranslation();
    const { onUpload, userProfile, refreshAction, customerConfig } =
        useContext(DriveToolsContext);

    const { startDriveSyncMessage } = useContext(PopupContext);
    const { addModalData } = useContext(ModalContext);
    const { updateFileProgressIndicator } = useContext(
        BackgroundActionsContext,
    );

    // Create the menu items dynamically
    const menuItems = [];

    const createProject = async (formData) => {
        try {
            const projectFields = {
                projectTitle: formData.title,
                email: userProfile.user.email,
                language: userProfile.user.language.code,
                parentProject: currentProject?._id || null,
                userId: userProfile.user._id,
                sendNotification: true,
            };

            const resProject = await axios.post(
                apiURL.createProject,
                projectFields,
            );
            if (resProject && resProject.data) refreshAction();
            else {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLaCreationDuDossier"),
                );
            }
        } catch (error) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    if (
        (!currentProject || (currentProject && userRoles.MODERATOR)) &&
        !isTimesheetView
    ) {
        menuItems.push({
            key: "creer",
            type: "group",
            label: t("creer"),
            children: [
                {
                    key: "dossier",
                    label: (
                        <div
                            onClick={() => {
                                addModalData({
                                    key: "createFolder",
                                    title: t("nouveauDossier"),
                                    okText: t("confirmer"),
                                    method: "BLOCK",
                                    content: (
                                        <ModalCreateProject
                                            profile={userProfile}
                                            refreshAction={refreshAction}
                                        />
                                    ),
                                    // handleCancel: closeSideMenu,
                                    handleConfirm: createProject,
                                });
                            }}
                            style={{ width: "100%" }}
                        >
                            {t("dossier")}
                        </div>
                    ),
                },
            ],
        });
    }

    if (
        (!currentProject || (currentProject && userRoles.MODERATOR)) &&
        !isTimesheetView &&
        !currentProject?.tender &&
        !!customerConfig?.callForTender?.isActive
        // TODO check if parents tender
    ) {
        const indexMenu = menuItems.findIndex((item) => item.key === "creer");
        menuItems[indexMenu]?.children.push({
            key: "call-tender",
            label: (
                <div
                    onClick={() => {
                        addModalData({
                            key: "call-tender",
                            title: t("creerUneBoiteDeDepot"),
                            content: <ModalCallTender />,
                            okText: t("creer"),
                            handleConfirm: async (values) => {
                                startDriveSyncMessage();
                                const newProject = await axios.post(
                                    apiURL.createProject,
                                    {
                                        projectTitle: values.title,
                                        email: userProfile.user.email,
                                        language:
                                            userProfile.user.language.code,
                                        parentProject:
                                            currentProject?._id || null,
                                        userId: userProfile.user._id,
                                        sendNotification: true,
                                    },
                                );
                                await axios.post(apiURL.createCallTender, {
                                    project: newProject.data._id,
                                    projectTitle: newProject.data.title,
                                    allowLateSubmission:
                                        !!values.allowLateSubmission,
                                    description: values.description || "",
                                    requiredFiles: values.requiredFiles || [],
                                    termDate: values.termDateEnabled
                                        ? moment(values.termDate).set({
                                              hour: moment(
                                                  values.termTime,
                                              ).hour(),
                                              minute: moment(
                                                  values.termTime,
                                              ).minute(),
                                              second: moment(
                                                  values.termTime,
                                              ).second(),
                                          })
                                        : null,
                                    password: values.passwordEnabled
                                        ? values.password
                                        : null,
                                });
                                refreshAction();
                            },
                        });
                    }}
                    style={{ width: "100%" }}
                >
                    {t("boiteDeDepot")}
                </div>
            ),
        });
    }

    if (
        constants.demoBimonoMails.includes(userProfile.user.email) ||
        userProfile.user.email === "ff@scale-arch.ch"
    ) {
        const indexMenu = menuItems.findIndex((item) => item.key === "creer");
        menuItems[indexMenu]?.children.push({
            key: "createRedirection",
            label: (
                <div
                    onClick={() => {
                        addModalData({
                            key: "createRedirection",
                            title: t("redirectionURL"),
                            okText: t("confirmer"),
                            method: "BLOCK",
                            content: (
                                <ModalURLRedirection
                                    project={currentProject}
                                    refreshAction={refreshAction}
                                    updateFileProgressIndicator={
                                        updateFileProgressIndicator
                                    }
                                />
                            ),
                        });
                    }}
                    style={{ width: "100%" }}
                >
                    {t("redirectionURL")}
                </div>
            ),
        });
    }

    if (!isTimesheetView) {
        const addSubItems = [];

        if (currentProject) {
            addSubItems.push({
                key: "importer-un-fichier",
                label: (
                    <div
                        onClick={() => {
                            document.getElementById("import-file-btn").click();
                            closeSideMenu && closeSideMenu();
                        }}
                        style={{ width: "100%" }}
                    >
                        {t("importerUnFichier")}
                    </div>
                ),
            });
        }

        if (!currentProject || (currentProject && userRoles.MODERATOR)) {
            addSubItems.push({
                key: "importer-un-dossier",
                label: (
                    <div
                        onClick={() => {
                            document
                                .getElementById("import-folder-btn")
                                .click();
                            closeSideMenu && closeSideMenu();
                        }}
                        style={{ width: "100%" }}
                    >
                        {t("importerUnDossier")}
                    </div>
                ),
            });
        }

        menuItems.push({
            key: "ajouter",
            type: "group",
            label: t("ajouter"),
            children: addSubItems,
        });
    }

    return (
        <Fragment>
            <input
                id="import-file-btn"
                type="file"
                accept={Constants.allowedExtensionsList}
                style={{ display: "none" }}
                onChange={(event) => {
                    const files = event.target?.files;
                    onUpload(files, false);
                }}
                multiple
            />
            <input
                id="import-folder-btn"
                type="file"
                accept={Constants.allowedExtensionsList}
                style={{ display: "none" }}
                onChange={(event) => {
                    const files = event.target?.files;
                    onUpload(files, true);
                }}
                webkitdirectory="true"
                directory="true"
            />

            <Dropdown
                menu={{ items: menuItems }}
                trigger={["click"]}
                placement={mdMedia ? "bottomRight" : "topLeft"}
                align={{ offset: mdMedia ? [270, -32] : [-32, 0] }}
                rootClassName="primary-dropdown"
            >
                {isFirstFolder ? (
                    <Flex align="center" gap={12}>
                        <MaterialIcons name={"add"} size="xs" />
                        <span>{t("creezVotrePremierDossier")}</span>
                    </Flex>
                ) : mdMedia ? (
                    <Button
                        type="primary"
                        block
                        icon={<MaterialIcons name="add" />}
                    >
                        {t("nouveau")}
                    </Button>
                ) : (
                    <div className="fixed z-[2] right-4 bottom-4">
                        <IconButton icon="add" text={t("nouveau")} />
                    </div>
                )}
            </Dropdown>
        </Fragment>
    );
};

export default NewActions;
