import { Text as TextPrint } from "@react-pdf/renderer";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Form, Input } from "antd";

export function ParagraphAnswer({ title, index }) {
    return (
        <Form.Item name={["fields", index, "value"]}>
            <Text
                style={{
                    textAlign: "justify",
                }}
                paragraph>
                {title}
            </Text>
        </Form.Item>
    );
}

export function ParagraphTemplate({ index }) {
    const { t } = useTranslation();

    return (
        <Form.Item label="Texte fixe :" name={[index, "title"]}>
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 8 }} />
        </Form.Item>
    );
}

export function ParagraphPrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.title}
        </TextPrint>
    );
}
