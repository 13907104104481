import { fieldsTypes } from "helpers/forms";
import { generateRandomToken } from "helpers/token";

import Text from "components/common/Text";

import RenderFieldItem from "./RenderFieldItem";

import { Flex, theme } from "antd";

export default function ColumnFields() {
    const { token } = theme.useToken();

    return (
        <Flex vertical gap={token.padding}>
            <Text bold size="md">
                Champs disponibles
            </Text>
            <Flex
                vertical
                gap={12}
                style={{
                    paddingBottom: token.paddingLG,
                }}>
                {fieldsTypes.map((field) => (
                    <RenderFieldItem _id={generateRandomToken(10)} type={field.code} key={field.code} />
                ))}
            </Flex>
        </Flex>
    );
}
