import { useContext } from "react";
import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import { DriveToolsContext } from "components/drive/Drive";

import CountFilter from "./CountFilter";

import { Button } from "antd";

const ButtonFilters = () => {
    const { toolsData, setToolsData } = useContext(DriveToolsContext);
    const { t } = useTranslation();

    return (
        <CountFilter>
            <Button
                type={toolsData.filtersOpen ? "default" : "text"}
                onClick={() =>
                    setToolsData((e) => ({ ...e, filtersOpen: !e.filtersOpen }))
                }
                icon={<MaterialIcons size="small" name="filter_alt" />}
                style={{
                    fontWeight: "bold",
                }}
            >
                {t("filtres")}
            </Button>
        </CountFilter>
    );
};

export default ButtonFilters;
