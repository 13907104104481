import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/input/Button";

import { getTotalWorkedHours } from "./InputCellTime";

const TableFooter = ({ filterDateRange, daysInRange, formatedDataList, totalOpen, setTotalOpen }) => {
    const { t } = useTranslation();
    const { scheduleConfiguration } = useProfileStore();
    const heightRow = " h-[40px]";

    const summData = useMemo(
        () => [
            {
                title: `${t("total")} ${t("heures")} ${t("travailles")}`,

                getValue: (e) =>
                    getTotalWorkedHours(
                        scheduleConfiguration,
                        formatedDataList.some((k) => k.isGroup)
                            ? formatedDataList
                                  .filter((k) => {
                                      return k.isGroup;
                                  })
                                  .map((k) => k.mergedLines.map((e) => e.mergedLines.map((e) => e.entry)).flat())
                                  .flat()
                                  .filter((entry) => moment(entry.startDate).format("YYYY-MM-DD") === e.date)
                                  .reduce((acc, curr) => {
                                      if (!acc.some((item) => item._id === curr._id)) {
                                          acc.push(curr);
                                      }
                                      return acc;
                                  }, [])
                            : formatedDataList
                                  .filter((k) => {
                                      return !k.isGroup && !k.isDetail;
                                  })
                                  .map((k) => k.mergedLines.map((e) => e.entry))
                                  .flat()
                                  .filter((entry) => moment(entry.startDate).format("YYYY-MM-DD") === e.date),
                    ),
            },
            // {
            //     title: `${t("total")} ${t("heures")} ${t("pauseMatin")}`,
            //     getValue: (e) =>
            //         formatedDataList
            //             .filter((k) => {
            //                 return !k.isGroup && !k.isDetail;
            //             })
            //             .map((k) => k.mergedLines.map((e) => e.entry))
            //             .flat()
            //             .filter(
            //                 (entry) =>
            //                     moment(entry.startDate).format("YYYY-MM-DD") ===
            //                     e.date,
            //             )
            //             .reduce((acc, curr) => {
            //                 return acc + (curr.breakMorning || 0);
            //             }, 0) || "",
            // },
            {
                title: `${t("total")} ${t("heures")} ${t("pauseMidi")}`,
                getValue: (e) =>
                    formatedDataList
                        .filter((k) => {
                            return !k.isGroup && !k.isDetail;
                        })
                        .map((k) => k.mergedLines.map((e) => e.entry))
                        .flat()
                        .filter((entry) => moment(entry.startDate).format("YYYY-MM-DD") === e.date)
                        .reduce((acc, curr) => {
                            return acc + (curr.breakLunch || 0);
                        }, 0) || "",
            },
        ],
        [formatedDataList],
    );

    useEffect(() => {
        const totalOpen = localStorage.getItem("entires-totalOpen");
        if (totalOpen === "true") {
            setTotalOpen(true);
        } else {
            setTotalOpen(false);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem("entires-totalOpen", totalOpen);
    }, [totalOpen]);

    return (
        <Fragment>
            <tr>
                <td colSpan="8" className="text-xs px-2">
                    <div className="w-max">
                        <Button
                            isReversed
                            withoutBorder
                            isBgTransparent
                            text={totalOpen ? t("masquerLesTotaux") : t("afficherLesTotaux")}
                            onClick={() => setTotalOpen((e) => !e)}
                        />
                    </div>
                </td>
            </tr>
            {!!totalOpen &&
                summData.map((data, i) => (
                    <tr key={i} className={"" + heightRow}>
                        <td className="border border-r-0 text-2xs "></td>
                        <td colSpan="3" className="border-t border-b px-3 font-bold text-2xs summ">
                            {data.title}
                        </td>
                        <td className="border text-2xs w-full max-w-[0px] ">
                            <div className="overflow-x-scroll no-scrollbar timeline-row">
                                <div className={"flex w-max" + heightRow}>
                                    {daysInRange.map((e, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className={
                                                    "text-center px-3 border-r capitalize flex items-center justify-center" +
                                                    (filterDateRange === "byDay" ? " w-[200px]" : "") +
                                                    (filterDateRange === "byWeek" ? " w-[74px]" : "") +
                                                    (filterDateRange === "byMonth" ? " w-[40px]" : "") +
                                                    (e.isToday
                                                        ? " bg-blue-100"
                                                        : e.isWeekend
                                                          ? " bg-blue-50"
                                                          : !!e.isHoliday?.length
                                                            ? " bg-green-50"
                                                            : !!e.isCompendated?.length
                                                              ? " bg-yellow-50"
                                                              : !!e.isVacation
                                                                ? " bg-red-50"
                                                                : "")
                                                }>
                                                {data.getValue(e)}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </td>
                        <td className="border px-3 text-2xs min-w-[60px]"></td>
                    </tr>
                ))}
        </Fragment>
    );
};

export default TableFooter;
