import axios from "axios";

import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { debounce } from "helpers/input";
import { getProjectparentsList } from "helpers/project";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import MobileMenuOverlay from "components/nav/MobileMenuOverlay";

import ProjectArboItem from "./ProjectArboItem";

import { Input, theme } from "antd";

const ProjectArbo = ({
    profile,
    currentProject,
    currentFile,
    projects,
    closeMenu,
    refreshAction,
    setCurrentItem,
    isMobileMenu = false,
    userRoles,
    selectedProject,
    setSelectedProject,
    alreadyImportedFiles = [],
    importFile,
    minFolderRole,
    isCurrentSelectedDefault,
    isWhiteText = false,
    isDuplication = false,
    // Automations
    currentAutomationId,
    automationType,
    selectedFolders,
    setSelectedFolders,
}) => {
    const { t } = useTranslation();

    const [arbo, setArbo] = useState(null);
    const [openArbo, setOpenArbo] = useState([]);
    const [openDriveFolder, setOpenDriveFolder] = useState(true);

    const [searchTerms, setSearchTerms] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const generateProjectArbo = () => {
        setArbo(
            projects
                .filter(
                    (project) =>
                        !project.restrictedUsers.some(
                            (restrictedUser) =>
                                restrictedUser.toString() ===
                                profile.user._id.toString(),
                        ),
                )
                .filter((project) =>
                    minFolderRole === "MODERATOR"
                        ? project.users.find(
                              (member) =>
                                  member.user === profile.user._id &&
                                  (member.role.code === "OWNER" ||
                                      member.role.code === "MODERATOR"),
                          )
                        : minFolderRole === "EDITOR"
                          ? project.users.find(
                                (member) =>
                                    member.user === profile.user._id &&
                                    (member.role.code === "EDITOR" ||
                                        member.role.code === "OWNER" ||
                                        member.role.code === "MODERATOR"),
                            )
                          : true,
                )
                .sort((a, b) =>
                    a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
                ),
        );

        const newArbo = [];

        if (currentProject) {
            const generateOpenArbo = async () => {
                const parents = await getProjectparentsList(currentProject._id);

                if (parents.length > 0) {
                    parents.forEach((parent) => {
                        if (!openArbo.includes(parent._id))
                            newArbo.push(parent._id);
                    });
                }

                if (!openArbo.includes(currentProject._id)) {
                    newArbo.push(currentProject._id);
                }

                setOpenArbo([...openArbo, ...newArbo]);
            };

            generateOpenArbo();
        } else if (currentFile && !openArbo.includes(currentFile.project._id)) {
            newArbo.push(currentFile.project._id);
            setOpenArbo([...openArbo, ...newArbo]);
        }
    };

    useEffect(() => {
        if (projects && profile) generateProjectArbo();
    }, [projects, currentFile]);

    const onSearch = async (e) => {
        if (e.target.value.length > 0 && e.target.value.length < 3) {
            setIsLoading(false);
            return;
        }

        if (e.target.value.trim() === "") {
            setSearchTerms("");
            setOpenArbo([]);
            setIsLoading(false);
            return;
        }
        const resSearch = await axios.post(apiURL.searchProject, {
            terms: e.target.value.toLowerCase(),
            isOnlyFolders: true,
        });

        if (resSearch?.data) {
            const newArbo = [];

            await Promise.all(
                resSearch.data.map(async (item) => {
                    const parents = await getProjectparentsList(item._id);

                    if (parents.length > 0) {
                        parents.forEach((parent) => {
                            if (!openArbo.includes(parent._id))
                                newArbo.push(parent._id);
                        });
                    }

                    if (!openArbo.includes(item._id)) {
                        newArbo.push(item._id);
                    }
                }),
            );

            setOpenArbo(newArbo);
            setIsLoading(false);

            if (newArbo.length > 0) {
                setSearchTerms(e.target.value);
                setIsLoading(false);
            }
        } else setOpenArbo([]);
    };

    const deboundedHandleInput = useCallback(
        debounce((e) => {
            onSearch(e);
        }, 800),
        [debounce],
    );

    const { token } = theme.useToken();

    return (
        arbo && (
            <Fragment>
                {isMobileMenu && (
                    <MobileMenuOverlay
                        height="h-[calc(100%-60px)]"
                        setVisible={closeMenu}
                    />
                )}

                {(importFile || setSelectedProject || setSelectedFolders) && (
                    <Input
                        addonAfter={
                            isLoading ? (
                                <MaterialIcons
                                    name="progress_activity"
                                    spin
                                    type="primary"
                                />
                            ) : (
                                <MaterialIcons name="search" />
                            )
                        }
                        placeholder={t("rechercherUnDossier")}
                        style={{ marginBottom: token.marginMD }}
                        onChange={(e) => {
                            setIsLoading(true);
                            deboundedHandleInput(e);
                        }}
                    />
                )}
                <div
                    className={
                        isMobileMenu
                            ? "absolute bottom-[60px] rounded-t-2xl bg-black w-full max-h-[80vh] overflow-auto z-[11]"
                            : ""
                    }
                >
                    {setSelectedProject && (
                        <div className="text-xs">
                            <div
                                className={
                                    "rounded-lg hover:cursor-pointer hover:bg-slate-300 relative" +
                                    (!selectedProject &&
                                    currentProject?.parentProject
                                        ? " bg-slate-300"
                                        : "")
                                }
                            >
                                <div className="flex flex-row">
                                    <button
                                        type="button"
                                        style={{
                                            padding: token.paddingXXS,
                                            paddingRight: 0,
                                        }}
                                        onClick={(e) =>
                                            setOpenDriveFolder(!openDriveFolder)
                                        }
                                    >
                                        {openDriveFolder ? (
                                            <MaterialIcons
                                                name="expand_more"
                                                size="sm"
                                            />
                                        ) : (
                                            <MaterialIcons
                                                name="chevron_right"
                                                size="sm"
                                            />
                                        )}
                                    </button>

                                    <div
                                        style={{
                                            width: "100%",
                                            paddingLeft: 0,
                                            paddingTop: token.paddingXS,
                                            paddingBottom: token.paddingXS,
                                        }}
                                        onClick={(e) =>
                                            setSelectedProject(null)
                                        }
                                    >
                                        <MaterialIcons
                                            name="folder"
                                            size="sm"
                                        />
                                        <span
                                            style={{
                                                verticalAlign: "middle",
                                                fontWeight: "bold",
                                                marginLeft: token.marginXXS,
                                            }}
                                        >
                                            {t("mesProjets")}
                                        </span>
                                    </div>
                                    {!selectedProject &&
                                        currentProject?.parentProject && (
                                            <div
                                                style={{
                                                    marginRight: token.marginXS,
                                                }}
                                                className="my-auto"
                                            >
                                                <MaterialIcons
                                                    name="check"
                                                    size="md"
                                                    type="primary"
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    )}

                    {arbo.map((project) => {
                        return (
                            openDriveFolder && (
                                <div
                                    key={project._id}
                                    className={
                                        (setSelectedProject ? "pl-4" : "") +
                                        (isWhiteText ? " text-white" : "")
                                    }
                                >
                                    <ProjectArboItem
                                        profile={profile}
                                        project={project}
                                        currentProject={currentProject}
                                        currentFile={currentFile}
                                        openArbo={openArbo}
                                        setOpenArbo={setOpenArbo}
                                        refreshAction={refreshAction}
                                        setCurrentItem={setCurrentItem}
                                        closeMenu={closeMenu}
                                        userRoles={userRoles}
                                        selectedProject={selectedProject}
                                        setSelectedProject={setSelectedProject}
                                        selectedFolders={selectedFolders}
                                        setSelectedFolders={setSelectedFolders}
                                        alreadyImportedFiles={
                                            alreadyImportedFiles
                                        }
                                        importFile={importFile}
                                        isCurrentSelectedDefault={
                                            isCurrentSelectedDefault
                                        }
                                        isDuplication={isDuplication}
                                        currentAutomationId={
                                            currentAutomationId
                                        }
                                        automationType={automationType}
                                        isWhiteText={isWhiteText}
                                        searchTerms={searchTerms}
                                    />
                                </div>
                            )
                        );
                    })}
                </div>
            </Fragment>
        )
    );
};

export default ProjectArbo;
