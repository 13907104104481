import i18n from "i18next";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr";

export function secondsToHms(d) {
    d = Number(d);

    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    if (h < 10) h = "0" + h;

    if (m < 10) m = "0" + m;

    if (s < 10) s = "0" + s;

    var hDisplay = h > 0 ? h + " : " : "";
    var mDisplay = m > 0 ? m : "00";
    var sDisplay = s > 0 ? s : "00";

    return hDisplay + mDisplay + " : " + sDisplay;
}

export function numberToSeconds(number) {
    let min = 0;
    let sec = 0;

    // Verif si il s'agit d'un integer ou d'un float

    if (number.toString().includes(".")) {
        let splitValues = number.toString().split(".");

        min = splitValues[0];
        sec = splitValues[1];
    } else sec = number * 60;

    if (min < 10) min = 0 + min;

    if (sec.length === 1) sec = sec + 0;

    return parseInt(min * 60) + parseInt(sec);
}

export function getSecondsBetweenTwoDates(date1, date2) {
    var dateBegin = moment(date1);
    var dateEnd = moment(date2);

    var duration = moment.duration(dateEnd.diff(dateBegin));
    var seconds = duration.asSeconds();

    return seconds;
}

export function getHoursBetweenTwoDates(date1, date2) {
    var dateBegin = moment(date1);
    var dateEnd = moment(date2);

    var duration = moment.duration(dateEnd.diff(dateBegin));
    var hours = duration.asHours();

    return hours;
}

// Retourne en chaine de caractères la durée entre deux dates

export function getTimeBetweenTwoDates(date1, date2) {
    var dateBegin = moment(date1);
    var dateEnd = moment(date2);

    var ms = moment(dateEnd, "DD/MM/YYYY HH:mm:ss").diff(
        moment(dateBegin, "DD/MM/YYYY HH:mm:ss"),
    );
    var d = moment.duration(ms);
    var s = Math.floor(d.asHours()) + moment.utc(ms).format(" : mm : ss");

    return s;
}

// Retourne le temps en Ms entre deux dates

export function getDurationBetweenTwoDates(date1, date2) {
    var dateBegin = moment(date1);
    var dateEnd = moment(date2);

    var ms = moment(dateEnd, "DD/MM/YYYY HH:mm:ss").diff(
        moment(dateBegin, "DD/MM/YYYY HH:mm:ss"),
    );

    return ms;
}

export function convertMsToHours(ms) {
    var d = moment.duration(ms);
    var s = Math.floor(d.asHours()) + moment.utc(ms).format(" : mm : ss");

    return s;
}

export function minutesToString(min) {
    if (min) {
        let stringMin = min.toString();
        let formatedMin = "";

        // Si nombre inférieur à 1, on affiche en secondes

        if (min < 1) {
            // On gère la partie décimale en ajoutant un 0 si nécessaire, et en coupant après deux chiffres au cas où

            let decimal = stringMin.split(".")[1];

            if (decimal) {
                if (decimal.length === 1) decimal += "0";

                if (decimal.length > 2) decimal = stringMin.substr(0, 2);

                formatedMin = decimal + " " + i18n.t("sec");
            }
        } else {
            let decimal = stringMin.split(".")[1];

            if (decimal) {
                if (decimal.length === 1) decimal += "0";

                if (decimal.length > 2) decimal = stringMin.substr(0, 2);

                formatedMin =
                    stringMin.split(".")[0] +
                    " " +
                    i18n.t("min") +
                    " " +
                    decimal;
            } else formatedMin = min + " " + i18n.t("min");
        }

        return formatedMin;
    } else return "";
}

export function fromMongoToStringHoursMinutes(date) {
    let formatedDate = moment(date).locale(i18n.locale).format("HH : mm");

    return formatedDate;
}

export function fromMsToHoursMinutes(ms) {
    const duration = moment.duration(ms);
    const isNeg = duration.asMilliseconds() < 0;
    const hours = isNeg
        ? Math.ceil(duration.asHours())
        : Math.floor(duration.asHours());
    const minutes = Math.abs(duration.minutes());

    return `${isNeg && hours === 0 ? "-" : ""}${hours}h${String(
        minutes,
    ).padStart(2, "0")}`.replace("h00", "h");
}

export function secondsToStringToRemain(seconds) {
    const fakeTime = 2;
    const estimatedSeconds = Math.round(seconds);

    if (estimatedSeconds > 60 * 60) {
        return `Environ ${Math.round(
            estimatedSeconds / 60 / 60,
        )} heures et ${Math.round(
            ((estimatedSeconds / 60 / 60) % 1) * 60,
        )} restantes`;
    }
    if (estimatedSeconds > 60 - fakeTime) {
        return `Moins de ${Math.round(
            estimatedSeconds / 60,
        )} minutes restantes`;
    }
    if (estimatedSeconds < 60 - fakeTime) {
        return `Moins de ${estimatedSeconds + fakeTime} secondes restantes`;
    }
    if (estimatedSeconds === 0) {
        return `Terminé`;
    }
}
