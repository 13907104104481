export function removeAccents(string) {
    if (typeof string === "string")
        return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    else return "";
}

export function fromIfcFormatToReadable(originalString) {
    if (typeof originalString !== "string") return originalString;

    let convertedString = "";

    // On split à cahque moment ou l'o ntrouve un caractère hexa
    let stringParts = originalString.split("\\X2\\");

    stringParts.forEach((part) => {
        if (part.includes("\\X0\\")) {
            let stringSubParts = part.split("\\X0\\");
            // On remplace le code hexa par le cractère qui correspond
            stringSubParts[0] = hexToAscii(stringSubParts[0]);
            part = stringSubParts[0].trim() + stringSubParts[1];
        }
        convertedString += part;
    });

    // On remplace les doubles apostrophes par une seule
    convertedString = convertedString.replace(/''/g, "'");

    return convertedString;
}

// Converti un code hexadécimal vers du ASCII
function hexToAscii(str1) {
    var hex = str1.toString();
    var str = "";
    for (var n = 0; n < hex.length; n += 2) {
        str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str.substring(1);
}

export function capitalize(str) {
    if (typeof str !== "string") return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
}

// Sorts an array of objects based on the Levenshtein distance of their titles to a target object's title.
export function sortWithLevenshteinDistance(source, target, extension) {
    if (!source) return;

    return source
        .filter(
            (e) =>
                e.title?.trim().toLowerCase().slice(0, 7) ===
                    target?.title?.trim().toLowerCase().slice(0, 7) &&
                e.file?.toLowerCase().includes(extension),
        )
        ?.sort((a, b) => {
            const distanceA = levenshteinDistance(
                target?.title?.toLowerCase(),
                a.title.toLowerCase(),
            );
            const distanceB = levenshteinDistance(
                target?.title?.toLowerCase(),
                b.title.toLowerCase(),
            );
            return distanceA - distanceB;
        });
}

// Sort based on the Levenshtein distance to the target title
export function levenshteinDistance(a, b) {
    const matrix = [];

    // Increment along the first column of each row
    for (let i = 0; i <= b.length; i++) {
        matrix[i] = [i];
    }

    // Increment each column in the first row
    for (let j = 0; j <= a.length; j++) {
        matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (let i = 1; i <= b.length; i++) {
        for (let j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) === a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // Substitution
                    matrix[i][j - 1] + 1, // Insertion
                    matrix[i - 1][j] + 1, // Deletion
                );
            }
        }
    }

    return matrix[b.length][a.length];
}
