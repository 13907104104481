import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";
import ProjectSelection from "components/project/ProjectSelection";

import { Select as AntdSelect, Card, Flex, theme } from "antd";

const TriggerLine = ({
    profile,
    formData,
    setFormData,
    itemTypes,
    itemActions,
    type,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    useEffect(() => {
        if (itemTypes.length > 0) {
            // On définit l'action par défaut sur la première de la liste
            updateTriggerFormValue("itemType", itemTypes[0].val);
        }
    }, [itemTypes]);

    useEffect(() => {
        if (formData.trigger.itemType) {
            if (itemActions.length > 0) {
                // On définit l'action par défaut sur la première de la liste
                updateTriggerFormValue("itemAction", itemActions[0].val);
            }
        }
    }, [itemActions]);

    const onChange = (value, name) => {
        const updatedTriggerDatas = formData.trigger;
        updatedTriggerDatas[name] = value;

        setFormData((formData) => ({
            ...formData,
            trigger: updatedTriggerDatas,
        }));
    };

    const updateTriggerFormValue = (name, value) => {
        const updatedTriggerDatas = formData.trigger;
        updatedTriggerDatas[name] = value;
        setFormData((formData) => ({
            ...formData,
            trigger: updatedTriggerDatas,
        }));
    };

    const setSelectedFolders = (selectedFolder) => {
        if (!formData.trigger.folders.includes(selectedFolder)) {
            if (type === "visa") {
                updateTriggerFormValue("folders", [selectedFolder]);
            } else {
                updateTriggerFormValue("folders", [
                    ...formData.trigger.folders,
                    selectedFolder,
                ]);
            }
        } else {
            updateTriggerFormValue(
                "folders",
                formData.trigger.folders.filter(
                    (folder) => folder !== selectedFolder,
                ),
            );
        }
    };

    return (
        Boolean(itemTypes.length) &&
        Boolean(itemActions.length) && (
            <Flex wrap gap={token.paddingLG}>
                <Text
                    bold
                    style={{
                        padding: token.paddingLG,
                        width: 150,
                    }}
                >
                    {t("quand")}
                </Text>
                <Card
                    style={{
                        flex: 1,
                        maxWidth: "100%",
                        backgroundColor: token.colorPrimaryBg + "44",
                    }}
                >
                    <Flex gap={token.paddingLG} wrap>
                        <AntdSelect
                            name="itemType"
                            options={itemTypes.map((e) => ({
                                value: e.val,
                                label: e.label,
                            }))}
                            value={formData.trigger.itemType}
                            onChange={(e) => onChange(e, "itemType")}
                        />
                        <AntdSelect
                            name="itemAction"
                            options={itemActions.map((e) => ({
                                value: e.val,
                                label: e.label,
                            }))}
                            value={formData.trigger.itemAction}
                            onChange={(e) => onChange(e, "itemAction")}
                        />
                        <ProjectSelection
                            profile={profile}
                            selectedFolders={formData.trigger.folders}
                            setSelectedFolders={setSelectedFolders}
                            minFolderRole="MODERATOR"
                            automationType={type}
                            currentAutomationId={formData.id}
                        />
                    </Flex>
                </Card>
            </Flex>
        )
    );
};

export default TriggerLine;
