import { createContext } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";

import { capitalize } from "helpers/string";

import { sendErrorEmail } from "components/error-boundary/ErrorBoundary";

import { message, notification } from "antd";

export const PopupContext = createContext(null);

const LayoutPopup = ({ children }) => {
    const { t } = useTranslation();

    const [notificationApi, notificationRender] =
        notification.useNotification();

    const [messageApi, messageRender] = message.useMessage();

    const startDriveSyncMessage = () => {
        messageApi.loading({
            key: "syncDrive",
            content: t("attenteDeSynchronisation"),
            duration: 10,
        });
    };

    const successDriveSyncMessage = () => {
        messageApi.success({
            key: "syncDrive",
            content: t("drive") + " " + t("misAJour"),
            duration: 2,
        });
    };

    const startElementSyncMessage = () => {
        messageApi.loading({
            key: "syncElement",
            content: t("attenteDeSynchronisation"),
            duration: 10,
        });
    };

    const successElementSyncMessage = () => {
        messageApi.success({
            key: "syncElement",
            content: capitalize(t("element")) + " " + t("misAJour"),
            duration: 2,
        });
    };

    const toastAlert = (type, message) => {
        messageApi.open({
            type,
            placement: "bottomLeft",
            content: message,
            duration: 5,
        });
    };

    const runNotification = ({ title, content, type, duration = 5 }) => {
        notificationApi.open({
            message: title,
            description: content,
            placement: "bottomLeft",
            type: type || "info",
            duration: duration || content.length > 50 ? 10 : 5,
        });
    };

    const runMessage = ({ content, type, reset, duration = 5 }) => {
        if (reset) messageApi.destroy();
        return messageApi.open({
            content: content,
            type: type || "info",
            duration,
        });
    };

    const wrapperSyncDrive = async (func) => {
        startDriveSyncMessage();
        try {
            await func();
            successDriveSyncMessage();
        } catch (error) {
            sendErrorEmail(func, error);
        }
    };

    const wrapperSyncElement = async (func) => {
        startElementSyncMessage();
        try {
            await func();
            successElementSyncMessage();
        } catch (e) {
            sendErrorEmail(func, error);
        }
    };

    const wrapperDownload = async (func) => {
        messageApi.loading({
            key: "syncDownload",
            content: t("telechargementEnCours"),
            duration: 10,
        });
        try {
            await func();
            messageApi.success({
                key: "syncDownload",
                content: t("telechargementTermine"),
                duration: 2,
            });
        } catch (error) {
            sendErrorEmail(error, func);
        }
    };

    return (
        <PopupContext.Provider
            value={{
                // Drive Common
                startDriveSyncMessage,
                successDriveSyncMessage,
                wrapperSyncDrive,

                startElementSyncMessage,
                successElementSyncMessage,
                wrapperSyncElement,
                wrapperDownload,
                // Common
                runNotification,
                runMessage,

                notificationApi,
                // Compatibility with old notification system
                toastAlert,
            }}
        >
            {notificationRender}
            {messageRender}
            {children}
            {/* // TODO REMOVE WHEN ALL NOTIFICATION ARE MIGRATED */}
            <ToastContainer draggable={false} style={{ zIndex: 99999 }} />
            {/* // TODO REMOVE WHEN ALL NOTIFICATION ARE MIGRATED */}
        </PopupContext.Provider>
    );
};
export default LayoutPopup;
