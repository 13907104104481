import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getRelatedCompiled } from "helpers/file";

import Text from "components/common/Text";

import CardEntityLight from "./CardEntityLight";

import { Alert, Col, Flex, Row } from "antd";

const FileRelatedCompiled = ({ file, isMoving = false }) => {
    const { t } = useTranslation();

    const [relatedCompiled, setRelatedCompiled] = useState([]);

    useEffect(() => {
        const getCompiled = async () => {
            const resCompiled = await getRelatedCompiled(file._id);
            setRelatedCompiled(resCompiled);
        };

        getCompiled();
    }, []);

    return (
        relatedCompiled.length > 0 && (
            <Flex vertical gap="large">
                <Alert
                    message={t("attention")}
                    description={
                        <Text paragraph>
                            {t(
                                !isMoving
                                    ? "leFichierEstUtiliseDansLesCompilationsSuivantesSaSuppressionEngendreraEgalementCelleDesFichiersCompilesAssocies"
                                    : "leFichierEstUtiliseDansLesCompilationsSuivantesSonDeplacementEngendreraEgalementLaSuppressionDesFichiersCompilesAssocies",
                                {
                                    fichier: file.title,
                                },
                            )}
                        </Text>
                    }
                    type="warning"
                    showIcon
                />
                <Row
                    wrap
                    gutter={[16, 16]}
                    style={{
                        overflow: "auto",
                        maxHeight: "50dvh",
                        paddingTop: 30,
                        paddingBottom: 30,
                    }}
                >
                    {relatedCompiled.map((item) => (
                        <Col
                            xxl={6}
                            xl={8}
                            lg={12}
                            md={12}
                            xs={24}
                            key={item._id}
                            style={{
                                pointerEvents: "none",
                            }}
                        >
                            <CardEntityLight key={item._id} item={item} />
                        </Col>
                    ))}
                </Row>
            </Flex>
        )
    );
};

export default FileRelatedCompiled;
