function HoneyPot({ value, onChange, name = "honeyPot" }) {
    return (
        <div className="hidden">
            <label htmlFor="honeyPot"></label>
            <input
                type="text"
                name={name}
                id={name}
                value={value}
                onChange={(e) => onChange(e)}
            ></input>
        </div>
    );
}

export default HoneyPot;
