import { useMemo } from "react";

import { Typography, theme } from "antd";

const Text = ({
    children,
    size = "",
    type = "",
    color = "",
    bold = false,
    ellipsis = false,
    title,
    style = {},
    paragraph = false,
    ...rest
}) => {
    const { token } = theme.useToken();

    const getSize = useMemo(() => {
        if (size === "xs") return token.fontSize - 4;
        if (size === "sm" || size === "small") return token.fontSize - 2;
        if (size === "base") return token.fontSize;
        if (size === "md" || size === "large") return token.fontSize + 4;
        if (size === "lg") return token.fontSizeHeading2;
        if (size === "xl") return token.fontSizeHeading1;
        if (!!size) return size;
        return token.fontSize;
    }, [size, token]);

    const getColor = useMemo(() => {
        if (color === "success") return token.colorSuccess;
        if (color === "secondary") return token.colorTextSecondary;
        if (color === "primary") return token.colorPrimary;
        if (color === "white") return token.colorBgBase;
        if (color === "error" || color === "danger") return token.colorError;
        if (color === "warning") return token.colorWarning;
        if (color === "info") return token.colorInfo;
        if (color === "disabled") return token.colorTextDisabled;
        if (!!color) return color;
        return "";
    }, [color, token]);

    const Component = paragraph ? Typography.Paragraph : Typography.Text;

    return (
        <Component
            style={{
                fontSize: getSize,
                color: getColor,
                fontWeight: bold ? "bold" : "normal",
                // lineHeight: 1.5,
                letterSpacing: -0.01, // -0.01px for better readability
                whiteSpace: paragraph ? "pre-wrap" : "",
                ...style,
            }}
            ellipsis={ellipsis}
            type={type} //  'secondary' | 'success' | 'warning' | 'danger'
            children={children}
            title={title}
            {...rest}
        />
    );
};

export default Text;
