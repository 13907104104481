import MaterialIcons from "components/common/MaterialIcons";

const FolderIcon = ({
    isResponseTender = false,
    isChildTender = false,
    isShared = false,
    isTender = false,
    fillColor,
    size = 110,
}) => {
    return (
        <div
            style={{
                position: "relative",
                userSelect: "none",
            }}
        >
            <MaterialIcons
                name="folder"
                size={size}
                color={fillColor || "#747fba"}
            />
            {isChildTender && (
                <MaterialIcons
                    name="note_stack"
                    size={50}
                    color={"white"}
                    isOutlined
                    style={{
                        position: "absolute",
                        bottom: 25,
                        left: 30,
                    }}
                />
            )}
            {isResponseTender && (
                <MaterialIcons
                    name="deployed_code_account"
                    size={50}
                    color={"white"}
                    isOutlined
                    style={{
                        position: "absolute",
                        bottom: 18,
                        left: 18,
                    }}
                />
            )}
            {isTender && (
                <MaterialIcons
                    name="package_2"
                    size={50}
                    color={"white"}
                    isOutlined
                    style={{
                        position: "absolute",
                        bottom: 25,
                        left: 30,
                    }}
                />
            )}
            {!isTender && !isResponseTender && !isChildTender && isShared && (
                <MaterialIcons
                    name="group_add"
                    size={50}
                    color={"white"}
                    isOutlined
                    style={{
                        position: "absolute",
                        bottom: 18,
                        left: 18,
                    }}
                />
            )}
        </div>
    );
};

export default FolderIcon;
