import { DndContext, useDroppable } from "@dnd-kit/core";
import {
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Flex } from "antd";

// Make sure to create this component

const DragOrder = ({ orderAction, children }) => {
    const { setNodeRef } = useDroppable({
        id: "automations",
    });

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active?.data?.current?.action && over?.data?.current?.action)
            orderAction(active.data.current.action, over.data.current.action);
    };

    return (
        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
            <SortableContext
                id="automations"
                strategy={verticalListSortingStrategy}
                items={children.map((item) => item.key)}
            >
                <Flex gap="large" vertical ref={setNodeRef}>
                    {children.map((item) => (
                        <DragItem
                            key={item.key}
                            id={item.key}
                            data={item.props}
                        >
                            {item}
                        </DragItem>
                    ))}
                </Flex>
            </SortableContext>
        </DndContext>
    );
};

const DragItem = ({ id, data, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
            data,
        });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div size="small" ref={setNodeRef} style={style} {...attributes}>
            {children}
        </div>
    );
};

export default DragOrder;
