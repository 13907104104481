import axios from "axios";
import i18n from "i18next";

import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import { DriveToolsContext } from "components/drive/Drive";
import SwitchButton from "components/input/SwitchButton";
import ConfirmationModal from "components/modal/ConfirmationModal";
import Spinner from "components/utils/Spinner";

import { Alert } from "antd";

const AutomationItem = ({
    automation,
    deleteAutomation,
    getAutomations,
    setEditedAutomation,
}) => {
    const { t } = useTranslation();
    const { setToolsData } = useContext(DriveToolsContext);

    const [confirmationModal, setConfirmationModal] = useState(null);
    const [loader, setLoader] = useState(false);
    const [isEnabled, setEnabled] = useState(automation.isActive);

    const onChangeEnabled = async () => {
        const resUpdated = await axios.post(apiURL.updateAutomation, {
            id: automation._id,
            isActive: !isEnabled,
        });

        if (resUpdated && resUpdated.data) setEnabled(resUpdated.data.isActive);
        else toastAlert("error", t("uneErreurEstSurvenue"));
    };

    const formatAutomationSentence = () => {
        let sentence = "";
        sentence += t("quand");
        sentence +=
            " " + automation.trigger.type["title" + i18n.locale].toLowerCase();
        sentence +=
            " " +
            automation.trigger.action["title" + i18n.locale].toLowerCase();

        // Gestion des dossiers dans le cas ou il n'y en a qu'un ou plusieurs pour le trigger
        if (automation.trigger.folders.length > 1) {
            sentence += " " + t("lesDossiers") + " ";

            automation.trigger.folders.forEach((folder, i) => {
                sentence +=
                    folder.title +
                    (automation.trigger.folders[i + 1] ? " / " : "");
            });

            sentence += ".";
        } else {
            sentence +=
                " " +
                t("leDossier") +
                " " +
                (automation.trigger.folders[0]?.title || "") +
                ".";
        }

        sentence += " " + t("si");

        automation.conditions.forEach((condition) => {
            sentence +=
                " " +
                (condition.operator === ""
                    ? ""
                    : condition.operator === "AND"
                      ? t("et").toUpperCase() + " "
                      : condition.operator === "OR" &&
                        t("ou").toUpperCase() + " ") +
                condition.type["title" + i18n.locale].toLowerCase() +
                " ";

            if (condition.userType) {
                if (
                    condition.userType.code === "FOLDER_MEMBER" &&
                    condition.user
                ) {
                    sentence +=
                        condition.user.firstname +
                        " " +
                        condition.user.lastname;
                } else {
                    sentence +=
                        condition.userType["title" + i18n.locale].toLowerCase();
                }
            }
        });

        sentence += ".";
        sentence += " " + t("alors");

        // Texte pour les automatisations de type visa
        automation.actions
            .filter((action) => action.visa)
            .forEach(
                (action, i) =>
                    (sentence +=
                        " " +
                        (action.operator === "" || i === 0
                            ? ""
                            : action.operator === "AND"
                              ? t("et").toUpperCase() + " "
                              : action.operator === "OR" &&
                                t("ou").toUpperCase() + " ") +
                        action.type["title" + i18n.locale].toLowerCase() +
                        " " +
                        (action.visa && action.visa.title) +
                        " " +
                        action.constraint["title" + i18n.locale].toLowerCase() +
                        " " +
                        action.deadlineDays +
                        " " +
                        (action.deadlineDays > 1 ? t("jours") : t("jour"))),
            );

        // Texte pour les automatisations de type nomenclature
        automation.actions
            .filter(
                (action) => !action.visa && action.nomenclature !== undefined,
            )
            .forEach(
                (action, i) =>
                    (sentence +=
                        " " +
                        (action.operator === "" || i === 0
                            ? ""
                            : action.operator === "AND"
                              ? t("et").toUpperCase() + " "
                              : action.operator === "OR" &&
                                t("ou").toUpperCase() + " ") +
                        action.type["title" + i18n.locale].toLowerCase() +
                        (action.constraint.code === "FILENAME_CUSTOM"
                            ? ` "${action.nomenclature}"`
                            : ` "${action.constraint[
                                  "title" + i18n.locale
                              ].toLowerCase()}"`)),
            );

        sentence += ".";

        return sentence;
    };

    const handleDelete = () => {
        setConfirmationModal({
            title: t("voulezVousVraimentSupprimerCetteAutomatisation?"),
            callBackFunc: deleteAutomation,
            callBackParams: automation._id,
            closeReset: setConfirmationModal,
        });
    };

    const handleDuplicate = async () => {
        setLoader(true);
        try {
            const resDuplicated = await axios.get(
                apiURL.duplicateAutomation + automation._id,
            );

            if (resDuplicated && resDuplicated.data) getAutomations();
            else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
        setLoader(false);
    };

    const handleEdit = () => {
        setEditedAutomation(automation);
        setToolsData((e) => ({
            ...e,
            filtersOpen: false,
        }));
    };

    return (
        <div className="my-4">
            <h5 className="font-bold ml-1">{automation.title}</h5>
            <li className="bordered-card p-4 sm:flex sm:flex-row mt-2">
                <div className="w-full sm:w-[calc(100%-150px)]">
                    {automation.trigger.folders.length === 0 && (
                        <Alert
                            type="warning"
                            description={
                                t(
                                    "cetteAutomatisationNeConcerneAucunDossierActuellement",
                                ) + "."
                            }
                            showIcon
                            className="mb-2"
                        />
                    )}
                    <div className="mb-6">{formatAutomationSentence()}</div>
                    <div className="inline-block">
                        <SwitchButton
                            text={isEnabled ? t("active") : t("desactive")}
                            onChange={onChangeEnabled}
                            value={isEnabled}
                            isDisabled={
                                !isEnabled &&
                                automation.trigger.folders.length === 0
                            }
                        />
                    </div>
                </div>
                <div className="mt-2 sm:mt-0 sm:w-[150px]">
                    <ul className="flex flex-row justify-end">
                        <li className="m-1">
                            <button
                                type="button"
                                onClick={handleEdit}
                                className="flex p-1"
                                title={t("modifier")}
                            >
                                <i
                                    className="material-icons notranslate"
                                    style={{ fontSize: "1.5rem" }}
                                >
                                    edit
                                </i>
                            </button>
                        </li>
                        <li className="m-1">
                            <button
                                type="button"
                                onClick={handleDuplicate}
                                className="flex p-1"
                                title={t("copier")}
                            >
                                <i
                                    className="material-icons notranslate"
                                    style={{ fontSize: "1.5rem" }}
                                >
                                    content_copy
                                </i>
                            </button>
                        </li>
                        <li className="m-1">
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="flex p-1"
                                title={t("supprimer")}
                            >
                                <i
                                    className="material-icons notranslate"
                                    style={{ fontSize: "1.5rem" }}
                                >
                                    delete
                                </i>
                            </button>
                        </li>
                    </ul>
                </div>
            </li>

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                />
            )}

            {loader && <Spinner />}
        </div>
    );
};

export default AutomationItem;
