import moment from "moment";

import { fromDateToDatePickerValue } from "helpers/date";

const DatePicker = ({
    name,
    text = "",
    value,
    onChange,
    errorMsg,
    minToday = true,
    maxDays,
    isOutlined,
    isDisabled,
}) => {
    return (
        <div className="">
            {text !== "" && (
                <label htmlFor={name} className="text-sm">
                    {text}
                </label>
            )}

            <div className="flex items-center">
                <input
                    type="date"
                    id={name}
                    name={name}
                    value={value}
                    min={minToday ? fromDateToDatePickerValue(Date.now()) : ""}
                    max={
                        maxDays &&
                        fromDateToDatePickerValue(
                            moment(moment.now()).add(maxDays, "day"),
                        )
                    }
                    onChange={(e) => onChange(e)}
                    disabled={isDisabled}
                    onClick={(e) => e.target.showPicker()}
                    className={
                        " py-2 px-3" +
                        (isOutlined
                            ? " border rounded-lg w-full text-gray-700 focus:outline-none focus:shadow-outline h-[38px]"
                            : "") +
                        (isDisabled ? " opacity-50" : " cursor-pointer")
                    }
                />
            </div>
            {errorMsg && (
                <div className="error-msg font-bold text-red-500 text-xs italic">
                    {errorMsg}
                </div>
            )}
        </div>
    );
};

export default DatePicker;
