const RenderUniSelectField = ({
    title,
    _id,
    options = [],
    value,
    onChange,
}) => {
    return (
        <div className="mt-4">
            <h3 className="font-bold text-sm">{title}</h3>

            <ul className="flex flex-col gap-6 my-4">
                {options.map((option) => (
                    <li
                        key={option}
                        className="flex items-center gap-2"
                        onClick={() => onChange(option)}
                    >
                        <input
                            type="radio"
                            id={option}
                            name={_id}
                            value={option}
                            className="w-4 h-4"
                            defaultChecked={value === option}
                        />
                        <label htmlFor={option}>{option}</label>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RenderUniSelectField;
