import { useEffect } from "react";
import { Provider } from "react-redux";

import setAuthToken from "helpers/setAuthToken";

import { loadUser } from "actions/auth";

import { store } from "../store";

export default function LayoutRedux({ children }) {
    useEffect(() => {
        store.dispatch(loadUser());
    }, []);

    // Si l'utilisateur est connecté
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    return (
        <Provider store={store}>
            {/* <PersistGate loading={null} persistor={persistor}> */}
            {children}
            {/* </PersistGate> */}
        </Provider>
    );
}
