import axios from "axios";
import i18n from "i18next";
import JSZip from "jszip";

import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import { sendErrorEmail } from "components/error-boundary/ErrorBoundary";
import ContactFormButton from "components/input/ContactFormButton";

import { toastAlert } from "./alert";
import { uploadToS3 } from "./awsS3";
import { fromBase64ToFile } from "./image";
import { isFileUploadAllowed } from "./size";
import { generateRandomToken } from "./token";

export async function fromIfcZipToIfc(file) {
    const fileName = file.name.split(".").slice(0, -1).join(".") + ".ifc";

    const zip = new JSZip();
    let ifcFile = null;
    await zip.loadAsync(file).then(async (res) => {
        await Object.values(res.files)[0]
            .async("blob")
            .then((fileData) => {
                if (
                    Object.values(res.files)[0]
                        .name.split(".")
                        .pop()
                        .toLowerCase() !== "ifc"
                )
                    return null;
                else {
                    // On consèrve uniquement le fichier IFC du ZIP
                    return (ifcFile = new File([fileData], fileName));
                }
            });
    });

    return ifcFile;
}

export async function fileUploadProcess(datas) {
    try {
        const {
            project,
            file,
            previewImage,
            existingFileId,
            createVersionData,
            readStream,
            moreData,
            action,
            startPdfConversion = false,
        } = datas;
        // On génère un nom unique avec la date suivi d'un token random
        const date = Date.now();
        const ext = getFileExtension(file.name);
        const prefixFileName = date + generateRandomToken(10);
        const fileName = prefixFileName + "." + ext;
        // Titre du fichier visible dans l'application par la suite (on reprend le nom original)
        const fileTitle = file.name
            .substr(0, file.name.lastIndexOf("."))
            .substring(0, Constants.maxLengthFileTitle);

        // requit pour savoir si un fichier pdf est un plan
        const metadatas = await getMetadatas(file);

        // Upload du fichier sur AWS S3
        const resUploadFile = await uploadToS3(
            Constants.awsS3BucketFiles[process.env.REACT_APP_NODE_ENV],
            fileName,
            file,
            readStream,
        );

        if (resUploadFile) {
            let imageName;
            let image = previewImage;

            if (!image) {
                image = await generatePreview({ file });
            }

            if (image) {
                imageName = prefixFileName + ".jpeg";

                // Conversion de Base64 vers un fichier
                const previewImageFile = fromBase64ToFile(image, imageName);

                // Upload de l'image de preview sur AWS S3
                await uploadToS3(
                    Constants.awsS3BucketFilesPreviews[
                        process.env.REACT_APP_NODE_ENV
                    ],
                    imageName,
                    previewImageFile,
                );
            }

            // Update du projet
            let projectFields = {
                id: project?._id,
                file: fileName,
                fileTitle,
                metadatas,
                filePublicToken: generateRandomToken(24), // Sera enregistré que si le user fait partie d'une configuration client particulière avec l'option "isDefaultPublicToken" activée
            };

            projectFields.previewImage = imageName;
            let resFile;

            if ((existingFileId && action === "version") || createVersionData) {
                resFile = await axios.put(apiURL.addProjectFile, {
                    ...projectFields,
                    versionForId: existingFileId,
                    createVersionData,
                });
            } else if (existingFileId) {
                resFile = await axios.post(apiURL.updateProjectFile, {
                    id: existingFileId,
                    file: fileName,
                    title: fileTitle,
                    metadatas,
                    previewImage: imageName,
                    ...moreData,
                });
            } else {
                resFile = await axios.put(apiURL.addProjectFile, {
                    ...projectFields,
                    ...moreData,
                });
            }
            if (readStream)
                readStream({
                    status: "success",
                    percentage: 100,
                });
            if (resFile && resFile.data) {
                if (!startPdfConversion) return true;
                // Lancer la conversion pdf vers svg si besoin
                const file = resFile.data?.files?.find(
                    (o) => o.file === resUploadFile.Key,
                );
                if (file) convertPdfToSvg(file);

                return true;
            } else {
                sendErrorEmail(
                    "Erreur lors de l'upload d'un fichier - 1",
                    JSON.stringify(resFile),
                );
                toastAlert("error", i18n.t("impossibleDimporterLeFichier"));
            }
        } else {
            sendErrorEmail(
                "Erreur lors de l'upload d'un fichier - 2",
                JSON.stringify(resUploadFile),
            );
            toastAlert("error", i18n.t("impossibleDimporterLeFichier"));
        }
    } catch (error) {
        sendErrorEmail(
            "Erreur lors de l'upload d'un fichier - 3",
            JSON.stringify(error),
        );
        if (error.response?.data?.msg)
            toastAlert("error", error.response.data.msg);
        else toastAlert("error", i18n.t("impossibleDimporterLeFichier"));
    }

    return false;
}

// Vérifie si une combinaison de fichiers compilés est déjà présente pour un dossier
export async function isCompiledFilesExists(files, folderId) {
    try {
        let isExists = false;

        const project = await axios.get(apiURL.getProjectById + folderId);

        if (project?.data) {
            project.data.files
                .filter((file) => file.compiledFiles.length > 0)
                .forEach((projectFile) => {
                    let isDifferent = false;

                    const sortedNewFiles = files.sort();
                    const sortedCompiledFiles =
                        projectFile.compiledFiles.sort();

                    if (sortedNewFiles.length === sortedCompiledFiles.length) {
                        for (let i = 0; i < sortedNewFiles.length; i++) {
                            if (
                                sortedNewFiles[i] !==
                                sortedCompiledFiles[i].toString()
                            ) {
                                isDifferent = true;
                            }
                        }
                        if (!isDifferent) return (isExists = true);
                    }
                });
        }

        return isExists;
    } catch (error) {
        return false;
    }
}

export function isFavoriteFile(file, profile) {
    if (
        profile.favoritesFiles
            .filter((favorite) => favorite.file)
            .some((favorite) => favorite.file._id === file._id)
    )
        return true;

    return false;
}

export function getFileType(file) {
    if (checkIfCompiledFile(file)) return i18n.t("fichiersCompiles");
    if (file.file) return getFileExtension(file.file).toUpperCase();

    return i18n.t("inconnu");
}

export function checkIfCompiledFile(file) {
    return file.compiledFiles && file.compiledFiles.length > 0;
}

export function getFileExtension(fileName) {
    if (!fileName) return "";
    return fileName.split(".").pop();
}

export async function getRelatedCompiled(fileId) {
    const resCompiled = await axios.get(apiURL.getFileRelatedCompiled + fileId);

    if (resCompiled && resCompiled.data) return resCompiled.data;

    return [];
}

export async function downloadFileById(fileId, title, useLightViewer = false) {
    try {
        const resPresigned = await axios.post(
            useLightViewer
                ? apiURL.getFilePresignedUrlLightViewer
                : apiURL.getFilePresignedUrl,
            {
                fileId,
            },
        );

        if (resPresigned?.data) {
            const url = resPresigned.data;
            const link = document.createElement("a");
            link.href = url;
            link.download = title;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export async function downloadFile(title, content, type) {
    const blob =
        content instanceof Blob ? content : new Blob([content], { type });
    startDownload(title, blob);
}

function startDownload(title, blob) {
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(blobUrl);
}

export function isIfcFile(file) {
    if (
        file.compiledFiles.length > 0 ||
        getFileExtension(file.file).toLowerCase() === "ifc" ||
        getFileExtension(file.file).toLowerCase() === "ifczip"
    )
        return true;

    return false;
}

export function isImageFile(file) {
    if (
        file.file &&
        Constants.imagesTypes.includes(
            getFileExtension(file.file).toLowerCase(),
        )
    )
        return true;

    return false;
}

export function isVideoFile(file) {
    if (
        file.file &&
        Constants.videosTypes.includes(
            getFileExtension(file.file).toLowerCase(),
        )
    )
        return true;

    return false;
}

export function isPcdFile(file) {
    if (
        getFileExtension(file.file).toLowerCase() === "pcd" ||
        getFileExtension(file.file).toLowerCase() === "xyz" ||
        getFileExtension(file.file).toLowerCase() === "las" ||
        getFileExtension(file.file).toLowerCase() === "laz" ||
        getFileExtension(file.file).toLowerCase() === "obj"
    )
        return true;

    return false;
}

export function iFrameGenerator(url) {
    return (
        `<iframe
    src="` +
        url +
        `"
    width="600"
    height="450"
    loading="lazy"
    title="` +
        i18n.t("applicationTitle") +
        `" 
></iframe>`
    );
}

export async function blobToArrayBuffer(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function () {
            resolve(reader.result);
        };

        reader.onerror = function (error) {
            reject(error);
        };

        reader.readAsArrayBuffer(blob);
    });
}

export function exportBase64ToPDF(base64String, fileName) {
    var binaryString = atob(base64String);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);

    for (var i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    var blob = new Blob([bytes], { type: "application/pdf" });

    var url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

// On cache les fichiers compilés
export function hideMergedFiles(files) {
    let result = [...files];
    files
        .filter((file) => file?.compiledFiles?.length > 0)
        .forEach((file) => {
            file?.compiledFiles.forEach((compiledFile) => {
                result = result.filter(
                    (item) =>
                        item._id !== compiledFile?._id &&
                        item._id !== compiledFile,
                );
            });
        });

    return result;
}

// Avant upload, on vérifie que l'ajout des fichiers ne fera pas dépasser la limite de stockage maximale
export const isLimitedDriveSize = async (files, project, addModalData, t) => {
    let totalSize = files.reduce((acc, file) => acc + file.size, 0);

    const isUploadAllowed = await isFileUploadAllowed(project, totalSize);
    if (!isUploadAllowed) {
        const key = generateRandomToken(10);
        addModalData({
            key,
            type: "illustrated",
            title: t("limiteDeStockage"),
            subtitle: t("votreDriveEstPresquePlein!"),
            content: (
                <div className="m-auto text-center">
                    <p className="text-lg font-bold text-black mb-8">
                        {t(
                            "votreEspaceDeStockageArriveASonMaximumPassezALaLicenceSuperieurePourEnObtenirDavantage",
                        )}
                        .
                    </p>
                    <div className="m-auto inline-block">
                        <ContactFormButton
                            text={t("augmenterMonEspace").toUpperCase()}
                        />
                    </div>
                </div>
            ),
            width: 1000,
        });
        return true;
    }
    return false;
};

export const generatePreview = async ({ file }) => {
    const extension = getFileExtension(file.name).toUpperCase();
    const baseResolution = [350, 180];

    if (extension === "PDF") {
        const scaler = 3;
        const base64PdfPreview = await getBase64PdfPreview(
            file,
            baseResolution[0] * scaler,
            baseResolution[0] * scaler,
        );
        return base64PdfPreview;
    } else if (["JPG", "JPEG", "PNG"].includes(extension)) {
        const scaler = 2;
        const base64Image = await getBase64LowResolutionImage(
            file,
            baseResolution[0] * scaler,
            baseResolution[0] * scaler,
        );
        return base64Image;
    }

    return false;
};

async function getBase64LowResolutionImage(file, maxWidth, maxHeight) {
    const reader = new FileReader();

    const dataUrl = await new Promise((resolve, reject) => {
        reader.onload = function (event) {
            const img = new Image();
            img.onload = function () {
                const canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height *= maxWidth / width;
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width *= maxHeight / height;
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);

                resolve(canvas.toDataURL("image/png"));
            };
            img.src = event.target.result;
        };

        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    return dataUrl;
}

async function getBase64PdfPreview(file, maxWidth, maxHeight) {
    const reader = new FileReader();

    const dataUrl = await new Promise((resolve, reject) => {
        reader.onload = async function (event) {
            const pdfData = new Uint8Array(event.target.result);
            const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;
            const page = await pdf.getPage(1);

            const viewport = page.getViewport({ scale: 1 });
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            let width = viewport.width;
            let height = viewport.height;

            // Calculate the new dimensions while maintaining the aspect ratio
            if (width > maxWidth || height > maxHeight) {
                if (width > height) {
                    height = Math.round((height * maxWidth) / width);
                    width = maxWidth;
                } else {
                    width = Math.round((width * maxHeight) / height);
                    height = maxHeight;
                }
            }

            canvas.width = width;
            canvas.height = height;

            const renderContext = {
                canvasContext: context,
                viewport: page.getViewport({ scale: width / viewport.width }),
            };

            await page.render(renderContext).promise;

            resolve(canvas.toDataURL("image/png"));
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    });

    return dataUrl;
}

export const getMetadatas = async (file) => {
    return new Promise((res, rej) => {
        const reader = new FileReader();
        reader.readAsText(file, "utf-8");
        reader.onloadend = () => {
            const ext = getFileExtension(file.name).toLowerCase();
            const metadatas = { pages: 1 };
            if (ext === "pdf") {
                // Compter le nombre de page
                const pages = reader.result.match(/\/Type[\s]*\/Page[^s]/g);
                metadatas.pages = pages?.length || 1;

                // Faire ressortir l'échelle d'un plan (approximatif)
                // const scale = reader.result.match(/(?:1)(?:(:|\/))[1-9](\d+)/g);
                const scale = reader.result.match(/1(:|\/)[1-9](\d+)/g);
                if (scale) {
                    const scaleSplitted = scale[0].split(/:|\//)[1];
                    metadatas.scale = {
                        units: "cm", // suggérer des unités en centimètres
                        value: scaleSplitted,
                    };
                }
            }

            res(metadatas);
        };
        reader.onerror = (e) => {
            rej({});
        };
    });
};

const convertPdfToSvg = (file) => {
    const isPdf = getFileExtension(file?.file) === "pdf";
    const isSinglePage = file?.metadatas?.pages === 1;
    const id = file?._id;

    if (!isPdf || !isSinglePage || !id) return;

    axios.get(apiURL.convertProjectSvgFileById + id);
};

// Créer un fichier .url permettant d'ouvrir une page depuis le Drive
export const createURLFile = (url, title) => {
    const fileContent = `[InternetShortcut]\nURL=${url.trim()}`;

    return new File([fileContent], title + ".url", { type: "text/plain" });
};

// Récupère seulement l'URL dans le contenu d'un fichier .url
export const extractUrlFromFile = (fileContent) => {
    const match = fileContent.match(/^URL=(.+)$/m);

    if (match && match[1]) {
        return match[1].trim();
    } else {
        return null;
    }
};
