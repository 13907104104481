import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import AutoCompleteTextField from "components/input/AutoCompleteTextField";

import { Select as AntdSelect, Button, Card, Flex, theme } from "antd";

const ConditionLine = ({
    index = 0,
    conditionDatas,
    updateValue,
    onChange,
    deleteRow,
    folderId,
    folderMemberType,
    conditionTypes,
    userTypes,
    members,
}) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const [usersList, setUsersList] = useState(null);

    const [username, setUsername] = useState("");

    useEffect(() => {
        if (conditionTypes.length > 0) {
            // On définit la condition par défaut sur la première de la liste
            updateConditionFormValue(
                "type",
                conditionDatas.type !== ""
                    ? conditionDatas.type
                    : conditionTypes[0].val,
            );
        }
    }, [conditionTypes]);

    useEffect(() => {
        if (userTypes.length > 0) {
            // On définit la condition par défaut sur la première de la liste
            updateConditionFormValue(
                "userType",
                conditionDatas.userType !== ""
                    ? conditionDatas.userType
                    : userTypes[0].val,
            );
        }
    }, [userTypes]);

    useEffect(() => {
        if (index > 0 && conditionDatas.operator === "")
            updateConditionFormValue(
                "operator",
                conditionDatas.operator !== "" ? conditionDatas.operator : "OR",
            );
    }, [index]);

    useEffect(() => {
        updateConditionFormValue(
            "user",
            conditionDatas.user && folderId ? conditionDatas.user : null,
        );

        if (!conditionDatas.user) setUsername("");
    }, [conditionDatas.user]);

    useEffect(() => {
        if (conditionDatas.user && members) {
            const userMember = members.find(
                (member) => member.user._id === conditionDatas.user,
            );

            if (userMember) {
                setUsername(
                    userMember.user.firstname +
                        " " +
                        userMember.user.lastname +
                        " (" +
                        userMember.user.email +
                        ")",
                );
            } else setUsername("");
        }
    }, [members, folderId]);

    const onChangeUser = async (e) => {
        if (conditionDatas.user) {
            updateConditionFormValue("user");
            return;
        }

        if (e.target.value.length < 1) {
            setUsersList(null);
            return;
        }

        const terms = e.target.value.toLowerCase();

        if (members) {
            const resMembersByTerm = members
                .filter((member) => member.user)
                .map((member) => member.user)
                .filter(
                    (user) =>
                        user.email.toLowerCase().includes(terms) ||
                        user.firstname.toLowerCase().includes(terms) ||
                        user.lastname.toLowerCase().includes(terms),
                );

            if (resMembersByTerm.length > 0) {
                let updatedList = [];

                resMembersByTerm.forEach((user) => {
                    updatedList.push({
                        id: user._id,
                        text:
                            user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.email +
                            ")",
                    });
                });

                setUsersList(updatedList);
            } else setUsersList([]);
        }
    };

    const onClickUser = (value) => {
        updateConditionFormValue("user", value);
    };

    const updateConditionFormValue = (name, value) => {
        updateValue(index, name, value);
    };

    const onDelete = () => {
        deleteRow(index);
    };

    return (
        <Flex gap={token.paddingLG}>
            <Text
                bold
                style={{
                    padding: token.paddingLG,
                    width: 150,
                }}
            >
                {index === 0 ? t("si") : capitalize(t("ou"))}
            </Text>
            <Card
                style={{
                    flex: 1,
                    backgroundColor: token.colorPrimaryBg + "44",
                }}
            >
                <Flex gap={token.paddingLG}>
                    <AntdSelect
                        name="conditionType"
                        options={conditionTypes.map((e) => ({
                            value: e.val,
                            label: e.label,
                        }))}
                        value={conditionDatas.type}
                        onChange={(e) => onChange(index, e, "conditionType")}
                        disabled={folderId ? false : true}
                    />

                    <AntdSelect
                        name="userType"
                        options={userTypes.map((e) => ({
                            value: e.val,
                            label: e.label,
                        }))}
                        value={conditionDatas.userType}
                        onChange={(e) => onChange(index, e, "userType")}
                        disabled={folderId ? false : true}
                    />

                    <div
                        className={
                            folderId &&
                            conditionDatas.userType &&
                            folderMemberType &&
                            conditionDatas.userType ===
                                folderMemberType._id.toString()
                                ? ""
                                : " hidden"
                        }
                    >
                        <AutoCompleteTextField
                            name="user"
                            defaultValue={username}
                            availableValues={usersList}
                            setAvailableValues={setUsersList}
                            onClickChoice={onClickUser}
                            onChange={onChangeUser}
                            maxLength={Constants.maxLengthEmail}
                        />
                    </div>
                    {index > 0 && (
                        <Button
                            shape="circle"
                            type="text"
                            onClick={(e) => onDelete(index)}
                            style={{ marginLeft: "auto" }}
                        >
                            <MaterialIcons name="delete" isOutlined />
                        </Button>
                    )}
                </Flex>
            </Card>
        </Flex>
    );
};

export default ConditionLine;
