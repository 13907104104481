import axios from "axios";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useEffect, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useTranslation } from "react-i18next";

import { convertMsToHours } from "helpers/time";
import apiURL from "utils/apiURL";

import Button from "components/input/Button";

const TimeWorked = ({ dateFilter, canValidate, sendEntries }) => {
    const { t } = useTranslation();

    const [hoursWeekWorked, setHoursWeekWorked] = useState(null);
    const { scheduleConfiguration } = useProfileStore();

    const [showConfettis, setShowConfettis] = useState(false);

    useEffect(() => {
        axios
            .post(apiURL.getTimeWorkedTimeEntries, {
                start: moment(dateFilter).startOf("week").format("YYYY-MM-DD"),
                end: moment(dateFilter).endOf("week").format("YYYY-MM-DD"),
                scheduleConfiguration: scheduleConfiguration
                    ? {
                          isMorningBreakHidden:
                              scheduleConfiguration.isMorningBreakHidden,
                          isLunchBreakHidden:
                              scheduleConfiguration.isLunchBreakHidden,
                      }
                    : null,
            })
            .then((e) => setHoursWeekWorked(e.data));
    }, [dateFilter]);

    const formattedTime = convertMsToHours(hoursWeekWorked * 1000)
        .slice(0, -5)
        .replaceAll(" ", "");

    const handleSubmit = () => {
        sendEntries();
        setShowConfettis(true);
    };

    return (
        <div className="flex items-center gap-2 justify-between my-4 text-sm">
            {` ${t("totalSemaine")} : ${
                hoursWeekWorked !== null ? formattedTime : "--:--"
            }`}
            {canValidate && (
                <div className="w-[150px]">
                    <Button
                        text={<span>{t("soumettre")}</span>}
                        onClick={handleSubmit}
                    />
                </div>
            )}

            {showConfettis && (
                <ConfettiExplosion
                    force={0.5}
                    duration={3000}
                    particleCount={40}
                    width={500}
                    zIndex={100}
                    onAnimationEnd={(e) => setShowConfettis(false)}
                />
            )}
        </div>
    );
};

export default TimeWorked;
