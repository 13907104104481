export const applySearch = (dataList, clientFilterSearch) =>
    dataList.filter((e) =>
        Object.keys(clientFilterSearch)
            .map((k) =>
                e[k]
                    .toLowerCase()
                    .includes(clientFilterSearch[k].toLowerCase()),
            )
            .includes(true),
    );

export const applyFilters = (dataList, filters) => {
    let result = [...dataList];
    if (filters.statuts?.length) {
        result = result.filter((e) =>
            filters.statuts.map((k) => k.val).includes(e.statut),
        );
    }
    if (filters.users?.length) {
        result = result.filter((e) =>
            filters.users.map((k) => k._id).includes(e.author._id),
        );
    }
    return result;
};
