import { Text as TextPrint } from "@react-pdf/renderer";
import moment from "moment";

import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { pdfStyles } from "../ExportFormPDF";

import { Col, Form, Input, Row, TimePicker, theme } from "antd";

export function TimeAnswer({ title, index, options, required, disabled }) {
    return (
        <Form.Item
            name={["fields", index, "value"]}
            label={<Text bold>{title} :</Text>}
            rules={[
                {
                    required,
                },
            ]}
            getValueProps={(value) => {
                return {
                    value: value ? moment(value) : null,
                };
            }}>
            <TimePicker
                disabled={disabled}
                style={{
                    width: "100%",
                }}
                format="HH:mm"
                needConfirm={false}
            />
        </Form.Item>
    );
}

export function TimeTemplate({ index }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    return (
        <Row gutter={[token.paddingLG, token.paddingLG]}>
            <Col span={24} lg={12}>
                <Form.Item label="Texte fixe:" name={[index, "title"]}>
                    <Input />
                </Form.Item>
            </Col>
            <Col span={24} lg={12}>
                <Form.Item
                    label="Valeur par défaut :"
                    name={[index, "defaultValue"]}
                    getValueProps={(value) => {
                        return {
                            value: value ? moment(value) : null,
                        };
                    }}>
                    <TimePicker
                        placeholder={"Valeur par défaut :"}
                        style={{
                            width: "100%",
                        }}
                        needConfirm={false}
                    />
                </Form.Item>
            </Col>
            {/* <Col span={4}>
                <Form.Item name={[index, "options"]} valuePropName="checked">
                    <Checkbox.Group>
                        <Flex>
                            <Checkbox
                                value={"HOURS"}
                                style={{
                                    transform: "scale(1.1)",
                                    marginLeft: "10px",
                                }}
                            >
                                Heure
                            </Checkbox>
                            <Checkbox
                                value={"MINUTES"}
                                style={{
                                    transform: "scale(1.1)",
                                    marginLeft: "10px",
                                }}
                            >
                                Minutes
                            </Checkbox>
                            <Checkbox
                                value={"SECONDS"}
                                style={{
                                    transform: "scale(1.1)",
                                    marginLeft: "10px",
                                }}
                            >
                                Secondes
                            </Checkbox>
                        </Flex>
                    </Checkbox.Group>
                </Form.Item>
            </Col> */}
        </Row>
    );
}

export function TimePrint({ field, index }) {
    return (
        <TextPrint
            style={{
                ...pdfStyles.text,
            }}>
            {field.value ? moment(field.value).format("HH:mm") : ""}
        </TextPrint>
    );
}
