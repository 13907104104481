import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getRelatedCompiled } from "helpers/file";
import { getUserProjects } from "helpers/user";

import Button from "components/input/Button";
import ConfirmationModal from "components/modal/ConfirmationModal";

import FileRelatedCompiled from "./FileRelatedCompiled";
import ProjectArbo from "./ProjectArbo";
import ProjectBreadcrumb from "./ProjectBreadcrumb";

const ProjectSelection = ({
    profile,
    currentProject,
    file,
    onConfirm,
    isMoving = false,
    isCurrentSelectedDefault = false,
    minFolderRole,
    isDuplication = false,
    // Automations
    currentAutomationId,
    automationType,
    selectedFolders,
    setSelectedFolders,
}) => {
    const { t } = useTranslation();

    const [confirmationModal, setConfirmationModal] = useState(null);

    const [selectedProject, setSelectedProject] = useState(
        isCurrentSelectedDefault && currentProject,
    );

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const getProjects = async () => {
            const resProjects = await getUserProjects(false, true);
            setProjects(resProjects);
        };

        getProjects();
    }, [profile]);

    const handleConfirm = async () => {
        if (isMoving && file) {
            // On check si le fichier n'est pas une dépendance d'un fichier compilé
            // Si c'est le cas, on affiche un message pour prévenir l'utilisateur
            const relatedCompiled = await getRelatedCompiled(file._id);

            if (relatedCompiled.length > 0) {
                setConfirmationModal({
                    title: t("confirmation"),
                    content: (
                        <FileRelatedCompiled file={file} isMoving={isMoving} />
                    ),
                    callBackFunc: onConfirm,
                    callBackParams: selectedProject._id,
                    closeReset: setConfirmationModal,
                    successText: t("confirmer"),
                    errorText: t("annuler"),
                    customWidth: "max-w-[1000px]",
                });
            } else onConfirm(selectedProject._id);
        } else onConfirm(selectedProject ? selectedProject._id : null);
    };

    return (
        <div
            style={{
                flex: 1,
                maxWidth: "100%",
            }}
        >
            {!setSelectedFolders && (
                <Fragment>
                    <ProjectBreadcrumb
                        project={selectedProject}
                        onClick={setSelectedProject}
                    />
                    <hr className="mt-4" />
                </Fragment>
            )}

            <div
                className={
                    "overflow-auto text-left" +
                    (!setSelectedFolders
                        ? " max-h-[70vh] mt-4"
                        : " max-h-[300px]")
                }
            >
                <ProjectArbo
                    profile={profile}
                    projects={projects}
                    currentProject={currentProject}
                    currentFile={file}
                    selectedProject={!selectedFolders && selectedProject}
                    setSelectedProject={
                        !setSelectedFolders && setSelectedProject
                    }
                    selectedFolders={selectedFolders}
                    setSelectedFolders={setSelectedFolders}
                    minFolderRole={minFolderRole}
                    isCurrentSelectedDefault={isCurrentSelectedDefault}
                    currentAutomationId={currentAutomationId}
                    automationType={automationType}
                    isDuplication={isDuplication}
                />
            </div>

            {(selectedProject ||
                (!file &&
                    !selectedProject &&
                    currentProject?.parentProject)) && (
                <div className="inline-block float-right mt-4">
                    <Button
                        text={t("confirmer")}
                        onClick={(e) => handleConfirm()}
                    />
                </div>
            )}

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                    successText={confirmationModal.successText}
                    errorText={confirmationModal.errorText}
                    customWidth={confirmationModal.customWidth}
                />
            )}
        </div>
    );
};

export default ProjectSelection;
