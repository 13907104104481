import { useContext, useMemo } from "react";

import { secondsToStringToRemain } from "helpers/time";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";

import { BackgroundActionsContext } from "layout/LayoutBackgroundActions";

import { Button, Flex, Progress, theme } from "antd";

const DriveBackgroundItem = ({
    file,
    streamId,
    percentage,
    status,
    secondsRemaining,
    abortStream,
}) => {
    const { token } = theme.useToken();

    const { updateFileProgressIndicator, removeStream, cancelAction } =
        useContext(BackgroundActionsContext);
    const { handleFiles } = useContext(DriveToolsContext);

    let iconStatus = "clock_loader_20";
    if (status === "calculating") iconStatus = "clock_loader_20";
    if (status === "processing") iconStatus = "progress_activity";
    if (status === "success") iconStatus = "check_circle";
    if (status === "cancelled") iconStatus = "sync";
    if (status === "error") iconStatus = "error";

    const cancelItem = async () => {
        cancelAction(streamId);
    };

    const restartItem = async () => {
        // Delete Old Stream
        updateFileProgressIndicator({
            streamId,
            status: "calculating",
        });
        setTimeout(() => {
            removeStream(streamId);
        }, 200);

        // Create New Stream with re-upload
        handleFiles([file]);
    };

    const closeItem = async () => {
        removeStream(streamId);
    };

    const getTimeIntervalString = useMemo(() => {
        if (status === "calculating") return "Calcul en cours...";
        if (status === "processing")
            return secondsToStringToRemain(secondsRemaining);
        if (status === "success") return "Terminé";
        if (status === "cancelled") return "Importation annulée";
        if (status === "error") return "Erreur d'importation";
        return "";
    }, [status, secondsRemaining]);

    return (
        <Flex
            vertical
            // INCASE : Import Progress / Pouvoir cliquer sur les éléments téléchargés pour les ouvrir, naviguer, etc...
            // className={status === "success" && "hoverable"}
            // onClick={() => {}}
            // onDoubleClick={() => {}}
            style={{
                padding: `${token.paddingLG / 2}px ${token.paddingLG}px`,
            }}
        >
            <Flex align="center" gap={12}>
                <MaterialIcons
                    style={{
                        fontSize: token.fontSizeHeading3,
                    }}
                    spin={["processing", "calculating"].includes(status)}
                    name={iconStatus}
                    isOutlined
                />

                <Flex vertical style={{ flex: 1 }}>
                    <Text ellipsis strong>
                        {file.name}
                    </Text>
                    <Text size="small" type="secondary">
                        {getTimeIntervalString}
                    </Text>
                </Flex>

                <Flex>
                    {status === "processing" && (
                        <Button type="text" onClick={cancelItem}>
                            Annuler
                        </Button>
                    )}
                    {status === "success" && (
                        <Button
                            type="text"
                            shape="circle"
                            onClick={closeItem}
                            icon={<MaterialIcons name="close" />}
                        />
                    )}
                    {status === "cancelled" && (
                        <Button type="text" onClick={restartItem}>
                            Relancer
                        </Button>
                    )}
                </Flex>
            </Flex>
            <Progress
                trailColor={token.colorTextSecondary}
                strokeColor={
                    ["error", "cancelled"].includes(status)
                        ? token.colorError
                        : token.colorSuccess
                }
                size={["default", 2]}
                percent={percentage}
                showInfo={false}
            />
        </Flex>
    );
};

export default DriveBackgroundItem;
