import axios from "axios";

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";

import ModalCreateProject from "components/project/ModalCreateProject";

import { ModalContext } from "layout/LayoutModal";

import { DriveToolsContext } from "./Drive";

import { Empty } from "antd";

const DriveEmptyLayout = () => {
    const { t } = useTranslation();

    const { userRoles, userProfile, currentProject, refreshAction } =
        useContext(DriveToolsContext);

    const navigate = useNavigate();
    const { addModalData } = useContext(ModalContext);

    const onClick = () =>
        currentProject
            ? document.getElementById("import-file-btn").click()
            : addModalData({
                  key: "createFolder",
                  title: t("nouveauDossier"),
                  okText: t("confirmer"),
                  method: "BLOCK",
                  content: (
                      <ModalCreateProject
                          profile={userProfile}
                          refreshAction={refreshAction}
                      />
                  ),
                  handleConfirm: async (formData) => {
                      try {
                          const projectFields = {
                              projectTitle: formData.title,
                              email: userProfile.user.email,
                              language: userProfile.user.language.code,
                              parentProject: null,
                              userId: userProfile.user._id,
                              sendNotification: true,
                          };

                          const resProject = await axios.post(
                              apiURL.createProject,
                              projectFields,
                          );

                          if (resProject && resProject.data) {
                              refreshAction();
                              setTimeout(() => {
                                  return navigate(
                                      "/drive/project/" + resProject.data._id,
                                  );
                              }, 200);
                          } else {
                              toastAlert(
                                  "error",
                                  t(
                                      "uneErreurEstSurvenueLorsDeLaCreationDuDossier",
                                  ),
                              );
                          }
                      } catch (error) {
                          toastAlert("error", t("uneErreurEstSurvenue"));
                      }
                  },
              });

    if (!userRoles.EDITOR && currentProject) return <Empty />;
    else
        return (
            <div
                className="flex w-full h-full relative group"
                onClick={onClick}
                style={{
                    cursor: currentProject ? "default" : "pointer",
                    pointerEvents: currentProject ? "none" : "auto",
                }}
            >
                <div className="relative w-[200px] md:w-[350px] xl:w-[450px] h-[200px] md:h-[350px] xl:h-[450px] bg-blue-100 m-auto flex rounded-full items-center justify-center duration-200 group-hover:scale-105">
                    {currentProject ? (
                        <Fragment>
                            <div className="m-auto text-center text-xs md:text-sm xl:text-lg">
                                <img
                                    loading="lazy"
                                    src="/img/file-icon.webp"
                                    alt=""
                                    className="m-auto w-[75px] md:w-[150px] xl:w-[200px]"
                                />
                                <div className="font-bold text-black mt-2">
                                    {t("deposezVosFichiersIci")}
                                </div>
                                <div className="max-w-[75%] md:max-w-full mx-auto text-black">
                                    {t("ouUtilisezLeBoutonNouveau")}
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        <button className="flex w-full h-full text-center">
                            <div className="m-auto text-xs md:text-sm xl:text-lg">
                                <img
                                    loading="lazy"
                                    src="/img/file-icon.webp"
                                    alt=""
                                    className="m-auto w-[75px] md:w-[150px] xl:w-[200px]"
                                />
                                <div className="font-bold text-black mt-2">
                                    {t("creezVotrePremierDossier")}
                                </div>
                                <div className="max-w-[75%] md:max-w-full mx-auto text-black">
                                    {t("ouUtilisezLeBoutonNouveau")}
                                </div>
                            </div>
                        </button>
                    )}
                </div>
            </div>
        );
};

export default DriveEmptyLayout;
