import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import Text from "components/common/Text";

import { Avatar, Flex, Form, Table, theme } from "antd";

const FormMembers = ({ projectMembers }) => {
    const { token } = theme.useToken();
    const { t } = useTranslation();

    const form = Form.useFormInstance();
    const selectedRowKeys = Form.useWatch("allowedTimesheetUsers", form);

    const setSelectedRowKeys = useCallback(
        (keys) => {
            form.setFieldValue("allowedTimesheetUsers", keys);
        },
        [form],
    );

    return (
        <Form.Item
            name="allowedTimesheetUsers"
            label={
                <Text size="large" bold>
                    {t("equipe")}
                </Text>
            }>
            <Table
                title={() => <Text>{t("selectionnezLesUtilisateursQuiPourrontSaisirDuTempsDeTravail")}</Text>}
                size="small"
                dataSource={projectMembers}
                pagination={false}
                rowKey={(record) => record?._id}
                rowSelection={{
                    selectedRowKeys,
                    onChange: setSelectedRowKeys,
                }}
                onRow={(record) => {
                    return {
                        style: {
                            cursor: "pointer",
                        },
                        onClick: () => {
                            setSelectedRowKeys(
                                selectedRowKeys.includes(record._id)
                                    ? selectedRowKeys.filter((key) => key !== record._id)
                                    : [...selectedRowKeys, record._id],
                            );
                        },
                    };
                }}
                columns={[
                    {
                        title: "Membres",
                        dataIndex: "user",
                        key: "user",
                        render: (_, user) => (
                            <Flex gap={token.padding} align="center">
                                <Avatar
                                    style={{
                                        backgroundColor: "black",
                                    }}>
                                    {user.firstname[0]}
                                    {user.lastname[0]}
                                </Avatar>
                                <Flex vertical>
                                    <Text>
                                        {user.firstname} {user.lastname}
                                    </Text>
                                    <Text size="small">{user.email}</Text>
                                </Flex>
                            </Flex>
                        ),
                    },
                ]}
            />
        </Form.Item>
    );
};

export default FormMembers;
