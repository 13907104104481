import axios from "axios";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { getVisaDeadlineDaysList } from "helpers/visa";
import apiURL from "utils/apiURL";

import Button from "components/input/Button";
import Select from "components/input/Select";
import Spinner from "components/utils/Spinner";

import VisaForm from "./VisaForm";

const AddVisa = ({
    project,
    files,
    onClose,
    refreshAction,
    userRoles,
    visas,
}) => {
    const { t } = useTranslation();

    const [loader, setLoader] = useState(false);
    const [visasList, setVisasList] = useState([]);
    const [selectedVisas, setSelectedVisas] = useState([]);

    const deadlineDays = getVisaDeadlineDaysList();
    const [visasDays, setVisasDays] = useState(5);

    useEffect(() => {
        setVisasList(visas);
    }, [visas]);

    const handleSelection = (selectedVisa) => {
        if (selectedVisas.includes(selectedVisa))
            setSelectedVisas(
                selectedVisas.filter((visa) => visa !== selectedVisa),
            );
        else {
            let updatedVisas = selectedVisas;
            selectedVisas.push(selectedVisa);
            setSelectedVisas([...updatedVisas]);
        }
    };

    const onConfirm = async () => {
        setLoader(true);

        try {
            const resVisas = await axios.post(apiURL.addVisasToFiles, {
                files: files.map((file) => file._id),
                visas: selectedVisas,
                deadlineDays: visasDays,
            });

            if (resVisas?.data) refreshAction();
            else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        onClose();
        setLoader(false);
    };

    const refreshVisas = async () => {
        const resVisas = await axios.get(apiURL.getFolderVisas + project._id);

        if (resVisas?.data) {
            setVisasList(
                resVisas.data.filter(
                    (visa) =>
                        !files.some((file) =>
                            file.visas.some(
                                (item) => item.visa._id === visa._id,
                            ),
                        ),
                ),
            );
        }
    };

    return (
        <Fragment>
            {visasList?.length > 0 ? (
                <Fragment>
                    <div className="font-bold text-black ">
                        {t("attention")} :
                    </div>
                    <div className="text-sm text-black mb-6">
                        {t(
                            "uneFoisAppliqueLeVisaNePourraPlusEtreModifieSiUnVisaEstDejaPresentSurLeDocumentSelectionneIlResteraInchange",
                        )}
                        .
                    </div>
                    <div className="font-bold text-black ">
                        {t("visasDisponibles")} :
                    </div>
                    <div className="mt-1 mb-6">
                        {visasList
                            ?.sort((a, b) =>
                                a.title.toLowerCase() > b.title.toLowerCase()
                                    ? 1
                                    : -1,
                            )
                            .map((el, i) => (
                                <div
                                    key={i}
                                    onClick={() => handleSelection(el._id)}
                                    className={
                                        "inline-block border-2 text-sm rounded-md font-bold p-1 mx-1 hover:cursor-pointer" +
                                        (selectedVisas.includes(el._id)
                                            ? " text-primary border-primary"
                                            : " border-slate-500 text-slate-500")
                                    }
                                >
                                    {el.title}
                                </div>
                            ))}
                    </div>

                    <div className="font-bold text-black ">
                        {t("aApprouverSous")} :
                    </div>
                    <div className="inline-block mt-1">
                        <Select
                            name="deadlineDays"
                            options={deadlineDays}
                            isRequired={true}
                            value={visasDays}
                            onChange={(e) => setVisasDays(e.target.value)}
                        />
                    </div>

                    <div className="flex flex-row justify-end gap-2">
                        <div className="inline-block">
                            <Button
                                isReversed={true}
                                text={t("annuler")}
                                onClick={onClose}
                            />
                        </div>
                        <div className="inline-block">
                            <Button
                                text={t("ajouter")}
                                onClick={onConfirm}
                                isDisabled={selectedVisas.length === 0}
                            />
                        </div>
                    </div>

                    {loader && <Spinner />}
                </Fragment>
            ) : (
                visasList && (
                    <div className="text-center">
                        {t("aucunVisaDisponible")}
                    </div>
                )
            )}
            {userRoles.MODERATOR && (
                <VisaForm projectId={project._id} onRefresh={refreshVisas} />
            )}
        </Fragment>
    );
};

export default AddVisa;
