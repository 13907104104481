import { useProfileStore } from "providers/ProviderProfile";

import React from "react";
import { useTranslation } from "react-i18next";

import { sendEmail } from "helpers/email";
import apiURL from "utils/apiURL";

import IntercomChat from "components/integrations/IntercomChat";
import NavBar from "components/nav/NavBar";

import { Button, Flex, theme } from "antd";

class ErrorBoundaryClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }
    componentDidCatch(error, errorInfo) {
        sendErrorEmail(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <Flex
                    style={{
                        backgroundColor: this.props.token.colorBgBase,
                        height: "100vh",
                        width: "100vw",
                        padding: this.props.token.paddingMD,
                    }}
                    vertical
                >
                    <NavBar />
                    <Flex
                        style={{ margin: "auto", textAlign: "center" }}
                        vertical
                    >
                        <h1
                            style={{
                                fontSize: "3.5rem",
                                fontWeight: "lighter",
                            }}
                        >
                            {this.props.t("oupsUneErreurEstSurvenue")}
                        </h1>
                        <p
                            style={{
                                marginTop: this.props.token.marginSM,
                                maxWidth: 600,
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        >
                            {this.props.t(
                                "nousAvonsRencontreUnProblemeInattenduEtEnAvonsEteInformeNotreEquipeTravailleALeCorrigerAuPlusVite",
                            )}
                            .
                        </p>

                        <div
                            style={{
                                display: "inline-block",
                                marginTop: this.props.token.marginXL,
                            }}
                        >
                            <Button
                                type="primary"
                                onClick={(e) => window.location.reload()}
                            >
                                {this.props.t("actualiserLaPage")}
                            </Button>
                        </div>
                    </Flex>
                    <IntercomChat />
                </Flex>
            );
        }

        return this.props.children;
    }
}

const ErrorBoundary = (props) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    return <ErrorBoundaryClass {...props} t={t} token={token} />;
};

export const sendErrorEmail = (error, errorInfo) => {
    const { user, customerConfig } = useProfileStore?.getState();

    const errorMessage =
        error instanceof Error ? error.toString() : JSON.stringify(error);

    if (/ChunkLoadError/.test(errorMessage)) return;

    const errorDetails = errorInfo
        ? errorInfo.componentStack
            ? errorInfo.componentStack
            : errorInfo
        : "No error info available";

    if (process.env.REACT_APP_NODE_ENV === "development") return;

    // Envoi d'un mail d'alerte aux devs
    const mailsToAlert = [
        "y.mermin@bimono.io",
        "bastien.chantrel.pro@gmail.com",
    ];

    mailsToAlert.forEach((email) => {
        sendEmail(apiURL.emailAdminAlert, email, {
            errorTitle: errorMessage,
            errorInfo:
                "\n" +
                "Utilisateur : " +
                user?.email +
                " / " +
                user?.firstname +
                " " +
                user?.lastname +
                " / " +
                user?._id +
                " / " +
                customerConfig?.name +
                "\n" +
                "Page : " +
                window.location.href +
                "\n" +
                errorDetails,
        });
    });
};

export default ErrorBoundary;
