import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { scrollToComment } from "helpers/comment";
import {
    getFileExtension,
    getFileType,
    isIfcFile,
    isPcdFile,
} from "helpers/file";
import { getFileExtensionIcon } from "helpers/image";
import { handleDownloadMultipleEntities } from "helpers/project";
import { getSettingByCode } from "helpers/setting";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import CommentsContent from "components/comment/CommentsContent";
import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import Button from "components/input/Button";
import ConfirmationModal from "components/modal/ConfirmationModal";
import InformationModal from "components/modal/InformationModal";
import FileInfos from "components/project/FileInfos";
import FileRelatedCompiled from "components/project/FileRelatedCompiled";
import FilesCompilation from "components/project/FilesCompilation";
import ProjectInfos from "components/project/ProjectInfos";
import ShareProjectFile from "components/share/ShareProjectFile";
import Login from "components/user/login/Login";
import Spinner from "components/utils/Spinner";
import AddVisa from "components/visa/AddVisa";
import RemoveVisa from "components/visa/RemoveVisa";
import VisaForm from "components/visa/VisaForm";
import VisasInlineList from "components/visa/VisasInlineList";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { DriveToolsContext } from "./Drive";
import ActivitiesList from "./activities/ActivitiesList";

import { Button as AntdButton, Card, Divider, theme } from "antd";

const DriveSidePanel = ({
    item,
    currentProject,
    selectedFiles = [],
    setSelectedFiles,
    closePanel,
    refreshAction,
    profile,
    userRoles,
    isFromDocument = false,
    commentId,
    toolView = "",
}) => {
    const { t } = useTranslation();

    const { permissionsTools } = useProfileStore();
    const { toolsData, setToolsData } = useContext(DriveToolsContext);
    const { startDriveSyncMessage } = useContext(PopupContext);
    const { addModalData, removeModal } = useContext(ModalContext);

    const [informationModal, setInformationModal] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(null);

    const [loader, setLoader] = useState(false);
    const [loaderSmall, setLoaderSmall] = useState(false);
    const [isLoadVisas, setLoadVisas] = useState(false);

    useEffect(() => {
        if (loader) {
            startDriveSyncMessage();
        }
        // else {
        //     refreshAction();
        // }
    }, [loader]);

    const [isVisible, setVisible] = useState(false);

    const [oldProjectId, setOldProjectId] = useState(null);

    const [comments, setComments] = useState([]);
    const [isCommentsLimitReached, setCommentsLimitReached] = useState(false);

    const [activities, setActivities] = useState([]);
    const [activitiesStep, setActivitiesStep] = useState(0);
    const [isActivitiesLimitReached, setActivitiesLimitReached] =
        useState(false);

    const [visasToAdd, setVisasToAdd] = useState(null);
    const [visasToRemove, setVisasToRemove] = useState(null);

    const [currentItemId, setCurrentItemId] = useState(null);

    const [maxSelection, setMaxSelection] = useState(0);

    // On consèrve l'ID du projet pour rouvir le panel en cas de changement de projet
    useEffect(() => {
        if (currentProject) {
            setOldProjectId(currentProject._id);

            const getSelectionLimit = async () => {
                const maxSelectionRes = await getSettingByCode(
                    "MAX_VIEWER_LOADED_FILES",
                );

                if (maxSelectionRes) setMaxSelection(maxSelectionRes);
            };

            if (!maxSelection) getSelectionLimit();
        } else setOldProjectId(null);
    }, [currentProject]);

    useEffect(() => {
        if (
            (oldProjectId &&
                currentProject &&
                currentProject._id !== oldProjectId) ||
            item ||
            selectedFiles.length > 0
        )
            setVisible(true);
        else if (selectedFiles.length === 0 && !item) setVisible(false);

        if (
            toolView === "" &&
            permissionsTools?.visas?.canView &&
            currentProject &&
            selectedFiles.length
        )
            getVisas();
    }, [currentProject, item, selectedFiles]);

    useEffect(() => {
        if (item?._id) {
            const getActivities = async () => {
                const activitiesRes = await axios.post(
                    item.files
                        ? apiURL.getProjectActivities + item._id
                        : apiURL.getFileActivities + item._id,
                    { start: activitiesStep },
                );

                if (activitiesRes && activitiesRes.data) {
                    setActivities(activitiesRes.data);
                } else setActivities([]);
            };

            if (profile) getActivities();
            else setActivities([]);

            if (currentItemId !== item._id) {
                setComments([]);
                setCommentsLimitReached(false);
                setCurrentItemId(item._id);
            }
        }
    }, [item]);

    useEffect(() => {
        isFromDocument && fetchComments();
    }, [isFromDocument]);

    const getVisas = async () => {
        setLoadVisas(true);

        try {
            await axios
                .get(apiURL.getFolderVisas + currentProject._id)
                .then((res) => {
                    const newVisasToAdd = res.data.filter(
                        (visa) =>
                            !selectedFiles.some((file) =>
                                file.visas.some(
                                    (item) => item.visa._id === visa._id,
                                ),
                            ),
                    );

                    const newVisasToRemove = res.data.filter((visa) =>
                        selectedFiles.some((file) =>
                            file.visas.some(
                                (item) =>
                                    item.visa._id === visa._id &&
                                    !(
                                        item.approvedDate ||
                                        item.refusedDate ||
                                        item.commentedDate ||
                                        item.notConcernedDate
                                    ),
                            ),
                        ),
                    );

                    setVisasToAdd([...newVisasToAdd]);
                    setVisasToRemove([...newVisasToRemove]);
                });
        } catch (err) {}
        setLoadVisas(false);
    };

    // Gestion de l'assemblage de plusieurs fichiers en un raccourci
    const onConfirmCompiles = async (res) => {
        if (res) refreshAction();

        setSelectedFiles([]);
        setInformationModal(null);
    };

    const handleScroll = async (e) => {
        if (!profile) return;

        if (
            e.target.scrollHeight - e.target.scrollTop ===
                e.target.clientHeight &&
            !isActivitiesLimitReached
        ) {
            const activitiesRes = await axios.post(
                item.files
                    ? apiURL.getProjectActivities + item._id
                    : apiURL.getFileActivities + item._id,
                { start: activitiesStep + 1 },
            );

            if (activitiesRes && activitiesRes.data) {
                if (activitiesRes.data.length === 0)
                    setActivitiesLimitReached(true);
                else {
                    setActivities((oldActivities) => [
                        ...oldActivities,
                        ...activitiesRes.data,
                    ]);

                    setActivitiesStep(activitiesStep + 1);
                }
            }
        }
    };

    const [downloadLoader, setDownloadLoader] = useState();
    const [moveLoader, setMoveLoader] = useState();
    const [copyLoader, setCopyLoader] = useState();

    const handleDownload = async () => {
        setDownloadLoader(true);
        try {
            const isFinished =
                await handleDownloadMultipleEntities(selectedFiles);
            if (!isFinished) {
                // Handle the case where no files or folders are selected
                toastAlert("error", t("uneErreurEstSurvenue"));
            }
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        } finally {
            setDownloadLoader(false);
        }
    };

    /*const handleMove = async () => {
        addModalData({
            key: "moveFile",
            title: t("deplacerVers"),
            content: (
                <ProjectSelection
                    profile={profile}
                    currentProject={currentProject}
                    file={selectedFiles[0]}
                    onConfirm={onConfirmMoving}
                    isMoving={true}
                />
            ),
            isForm: false,
        });
    };

    const onConfirmMoving = async (projectId) => {
        setMoveLoader(true);
        startDriveSyncMessage();

        try {
            const foldersItems = selectedFiles.filter((item) => item.files);
            const filesItems = selectedFiles.filter((item) => !item.files);

            let fileExists = false;

            await Promise.all(
                filesItems.map(async (file) => {
                    // Si c'est un fichier compilé, on check qu'il n'existe pas déjà dans le dossier de destination
                    const isExists = await isCompiledFilesExists(
                        file.compiledFiles.map(
                            (selectedFile) => selectedFile._id,
                        ),
                        projectId,
                    );

                    if (isExists) {
                        fileExists = true;
                        toastAlert(
                            "warning",
                            t(
                                "cetteCompilationDeFichiersExisteDejaDansCeDossier",
                            ),
                        );
                    }
                }),
            );

            if (fileExists) return;
            else {
                if (foldersItems.length > 0) {
                    const resMovingFolders = await axios.post(
                        apiURL.moveProject,
                        {
                            projectIds: foldersItems.map((item) => item._id),
                            parentId: projectId ? projectId : 0,
                        },
                    );

                    if (!resMovingFolders?.data)
                        toastAlert("error", t("uneErreurEstSurvenue"));
                }
                if (filesItems.length > 0) {
                    const resMovingFiles = await axios.get(
                        apiURL.moveProjectFile + file._id + "/" + projectId,
                    );

                    if (!resMovingFiles?.data)
                        toastAlert("error", t("uneErreurEstSurvenue"));
                }
            }
        } catch (e) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setMoveLoader(false);
    };

    const handleCopy = async () => {
        setCopyLoader(true);
        try {
            const isFinished =
                await handleDownloadMultipleEntities(selectedFiles);
            if (!isFinished) {
                // Handle the case where no files or folders are selected
                toastAlert("error", t("uneErreurEstSurvenue"));
            }
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        } finally {
            setCopyLoader(false);
        }
    };*/

    // Check si tous les fichiers sont des modèles ou non (pour afficher le bouton d'ouverture dans la visionneuse)
    // Si le param "isOnlyIfc" = true, on affiche le bouton de regroupement
    const isAllSelectedModelsFiles = (isOnlyIfc = false) => {
        return (
            selectedFiles.filter(
                (selectedFile) =>
                    selectedFile.compiledFiles &&
                    (isOnlyIfc
                        ? getFileType(selectedFile) === "IFC"
                        : getFileType(selectedFile) === "IFC" ||
                          isPcdFile(selectedFile)),
            ).length === selectedFiles.length
        );
    };

    // Suppression d'un ou plusieurs éléments
    const handleDeleteMultiple = async () => {
        setLoader(true);
        try {
            let items = [];

            selectedFiles.forEach((selectedFile) => {
                items.push({
                    type: selectedFile.files ? "FOLDER" : "FILE",
                    id: selectedFile._id,
                    title: selectedFile.title,
                    isCompiled: selectedFile.compiledFiles?.length > 0,
                    project: selectedFile.files
                        ? selectedFile.parentProject
                        : selectedFile.project._id,
                });
            });

            items = items.reduce((acc, selectedFile) => {
                const projectId = selectedFile.project;
                if (!acc[projectId]) {
                    acc[projectId] = [];
                }
                acc[projectId].push(selectedFile);
                return acc;
            }, {});

            let resDeleted = await Promise.all(
                Object.keys(items).map((projectId) =>
                    axios.post(apiURL.deleteMultipleItems + projectId, {
                        items: items[projectId],
                    }),
                ),
            );
            resDeleted = resDeleted.map((res) => res.data).flat();

            if (resDeleted.length > 0) {
                resDeleted.forEach((item) => {
                    if (item.status === false)
                        toastAlert(
                            "error",
                            t(
                                item.type === "FILE"
                                    ? "erreurDeSuppressionDuFichier"
                                    : "erreurDeSuppressionDuDossier",
                            ) +
                                " " +
                                item.title,
                        );
                });
            }
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        refreshAction(false);

        setLoader(false);
    };

    const handleMarkerMode = () => {
        setToolsData((e) => ({
            ...e,
            markers: {
                ...e.markers,
                isMarkerMode: !toolsData.markers.isMarkerMode,
            },
        }));
    };

    const toggleShowMarkers = (
        areMarkersVisible,
        lastComments = comments,
        deleteAdded = false,
    ) => {
        if (areMarkersVisible) {
            let markersToAdd = [];

            lastComments.forEach((comment, i) => {
                if (comment.marker) {
                    markersToAdd.push({
                        commentId: comment._id,
                        position: comment.marker,
                        commentNumber: lastComments.length - i,
                    });
                }
            });

            setToolsData((e) => ({
                ...e,
                markers: {
                    ...e.markers,
                    commentsMarkers: markersToAdd,
                    addedMarker: deleteAdded
                        ? null
                        : toolsData.markers.addedMarker,
                },
            }));
        } else {
            setToolsData((e) => ({
                ...e,
                markers: {
                    ...e.markers,
                    commentsMarkers: [],
                },
            }));
        }
    };

    // Le commentaire avec le marker actif a été enregistré, on retire le marker et on refresh la liste basée sur les nouveaux commentaires
    const handleMarkerSaved = (areMarkersVisible, updatedComments) => {
        setToolsData((e) => ({
            ...e,
            markers: {
                ...e.markers,
                addedMarker: null,
                isMarkerMode: false,
            },
        }));

        if (areMarkersVisible) {
            toggleShowMarkers(areMarkersVisible, updatedComments, true);
        }
    };

    const handleMarkerDeleted = (areMarkersVisible, updatedComments) => {
        if (areMarkersVisible) {
            toggleShowMarkers(areMarkersVisible, updatedComments);
        }
    };

    const fetchComments = async (currentStep = 0, commentsToAdd = []) => {
        setLoaderSmall(true);

        const newFilesComments = await axios.post(apiURL.getFilesComments, {
            start: currentStep,
            files: [item._id],
        });

        if (newFilesComments?.data?.length > 0) {
            const sortedComments = newFilesComments.data.sort((a, b) =>
                a.creationDate < b.creationDate ? 1 : -1,
            );

            if (currentStep === 0 && commentId) {
                const prioritizedComment = sortedComments.find(
                    (comment) => comment._id.toString() === commentId,
                );

                if (prioritizedComment) {
                    commentsToAdd = [
                        prioritizedComment,
                        ...sortedComments.filter(
                            (comment) => comment._id.toString() !== commentId,
                        ),
                        ...commentsToAdd,
                    ];
                } else {
                    commentsToAdd.push(...sortedComments);
                }
            } else {
                commentsToAdd.push(...sortedComments);
            }

            if (newFilesComments.data.length < Constants.nbCommentsPageLimit) {
                setComments(commentsToAdd);
                setCommentsLimitReached(true);
            } else {
                return await fetchComments(currentStep + 1, commentsToAdd);
            }
        } else {
            setCommentsLimitReached(true);
            setComments(commentsToAdd);
        }

        setLoaderSmall(false);

        if (commentId) {
            setTimeout(() => {
                scrollToComment(commentId);
            }, 500);
        }
    };

    const { token } = theme.useToken();

    return (
        isVisible && (
            <Card
                title={
                    selectedFiles?.length > 0 ? (
                        <Text>
                            {selectedFiles.length +
                                " " +
                                (selectedFiles.length === 1
                                    ? t("elementSelectionne")
                                    : t("elementsSelectionnes"))}
                        </Text>
                    ) : isFromDocument ? (
                        t("commentaires")
                    ) : (
                        t("informations")
                    )
                }
                extra={
                    <AntdButton
                        shape="circle"
                        type="text"
                        icon={<MaterialIcons name="close" />}
                        onClick={(e) => {
                            closePanel();
                            setVisible(false);
                            if (selectedFiles?.length > 0) setSelectedFiles([]);
                        }}
                    />
                }
                bordered
                className={"h-full w-full relative flex flex-col"}
                classNames={{
                    body: "overflow-y-scroll scrollbar-hover",
                }}
                styles={{
                    header: {
                        padding: 16,
                    },
                    body: {
                        padding: token.paddingLG / 2,
                    },
                }}
                onScroll={(e) => handleScroll(e)}
            >
                {selectedFiles.length === 0 && item ? (
                    <Fragment>
                        {!isFromDocument && (
                            <>
                                {item.files ? (
                                    <ProjectInfos
                                        project={item}
                                        user={profile?.user}
                                    />
                                ) : (
                                    <FileInfos
                                        file={item}
                                        isFromDocument={isFromDocument}
                                    />
                                )}
                            </>
                        )}

                        {permissionsTools?.visas?.canView && (
                            <Fragment>
                                {item.files &&
                                permissionsTools.automations?.canView &&
                                !item.parentProject &&
                                userRoles.MODERATOR ? (
                                    <VisaForm projectId={item._id} />
                                ) : (
                                    !item.files &&
                                    item.visas.filter((item) => item.visa)
                                        .length > 0 && (
                                        <>
                                            {!isFromDocument && <Divider />}
                                            <Text size="large" bold>
                                                {t("visasDuFichier")}
                                            </Text>
                                            <div className="max-w-full py-2">
                                                <VisasInlineList
                                                    file={item.project && item}
                                                    user={profile?.user}
                                                />
                                            </div>
                                        </>
                                    )
                                )}
                            </Fragment>
                        )}

                        {loaderSmall ? (
                            <div className="mt-20">
                                <Spinner isSmall={true} />
                            </div>
                        ) : (
                            <Fragment>
                                {item.project &&
                                    !isIfcFile(item) &&
                                    !isPcdFile(item) && (
                                        <div className="mt-5">
                                            {!isFromDocument && (
                                                <Fragment>
                                                    <Divider />
                                                    <h6 className="my-3 font-bold text-lg">
                                                        {t("commentaires")}
                                                    </h6>
                                                </Fragment>
                                            )}
                                            <div className="relative">
                                                {profile ||
                                                localStorage.getItem(
                                                    "guestName",
                                                ) ? (
                                                    <CommentsContent
                                                        files={[item]}
                                                        filesIds={[item._id]}
                                                        comments={
                                                            comments.length > 0
                                                                ? comments
                                                                : item.comments
                                                        }
                                                        setComments={
                                                            setComments
                                                        }
                                                        isFromViewer={false}
                                                        isFromDocument={
                                                            isFromDocument
                                                        }
                                                        userRoles={userRoles}
                                                        handleMarkerMode={
                                                            handleMarkerMode
                                                        }
                                                        isMarkerMode={
                                                            toolsData.markers
                                                                .isMarkerMode
                                                        }
                                                        addedMarker={
                                                            toolsData.markers
                                                                .addedMarker
                                                        }
                                                        toggleShowMarkers={
                                                            toggleShowMarkers
                                                        }
                                                        handleMarkerSaved={
                                                            handleMarkerSaved
                                                        }
                                                        handleMarkerDeleted={
                                                            handleMarkerDeleted
                                                        }
                                                        fetchComments={
                                                            fetchComments
                                                        }
                                                        isCommentsLimitReached={
                                                            isCommentsLimitReached
                                                        }
                                                    />
                                                ) : (
                                                    <Button
                                                        text={t(
                                                            "ajouterUnCommentaire",
                                                        )}
                                                        onClick={(e) =>
                                                            setInformationModal(
                                                                {
                                                                    title: t(
                                                                        "connexion",
                                                                    ),
                                                                    content: (
                                                                        <Login
                                                                            isFromFile={
                                                                                true
                                                                            }
                                                                            closeModal={
                                                                                setInformationModal
                                                                            }
                                                                        />
                                                                    ),
                                                                    closeReset:
                                                                        setInformationModal,
                                                                    isNoPadding: true,
                                                                    customWidth:
                                                                        "max-w-[750px] lg:max-w-[1050px]",
                                                                },
                                                            )
                                                        }
                                                        icon="add"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}

                                {activities.length > 0 && (
                                    <ActivitiesList
                                        item={item}
                                        activities={activities}
                                        userRoles={userRoles}
                                    />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    selectedFiles.length > 0 && (
                        <Fragment>
                            {/* <div className="text-black font-bold px-4 mt-3 w-[calc(100%-24px)]">
                                {selectedFiles.length +
                                    " " +
                                    (selectedFiles.length === 1
                                        ? t("elementSelectionne")
                                        : t("elementsSelectionnes"))}
                            </div> */}

                            {selectedFiles.length > 0 && (
                                <div className="mt-14 lg:mt-4 px-4">
                                    {toolView === "" &&
                                        !selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.files,
                                        ) && (
                                            <div className="mt-4">
                                                <AntdButton
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="primary"
                                                    size="large"
                                                    onClick={(e) =>
                                                        addModalData({
                                                            key: "shareFile",
                                                            title: t(
                                                                "partager",
                                                            ),
                                                            width: 1000,
                                                            isForm: false,
                                                            content: (
                                                                <ShareProjectFile
                                                                    files={
                                                                        selectedFiles
                                                                    }
                                                                    filesIds={selectedFiles.map(
                                                                        (
                                                                            selectedFile,
                                                                        ) =>
                                                                            selectedFile._id,
                                                                    )}
                                                                    refreshAction={
                                                                        refreshAction
                                                                    }
                                                                />
                                                            ),
                                                        })
                                                    }
                                                    children={t("partager")}
                                                />
                                            </div>
                                        )}

                                    {!selectedFiles.some(
                                        (selectedFile) =>
                                            selectedFile.compiledFiles?.length >
                                            0,
                                    ) && (
                                        <div className="mt-4">
                                            <AntdButton
                                                style={{
                                                    width: "100%",
                                                }}
                                                type="primary"
                                                size="large"
                                                onClick={handleDownload}
                                                children={t("telecharger")}
                                                loading={downloadLoader}
                                            />
                                        </div>
                                    )}

                                    {/*currentProject && (
                                        <>
                                            <div className="mt-4">
                                                <AntdButton
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="primary"
                                                    size="large"
                                                    onClick={handleMove}
                                                    children={t("deplacerVers")}
                                                    loading={moveLoader}
                                                />
                                            </div>

                                            {!selectedFiles.some(
                                                (selectedFile) =>
                                                    selectedFile.compiledFiles
                                                        ?.length > 0,
                                            ) && (
                                                <div className="mt-4">
                                                    <AntdButton
                                                        style={{
                                                            width: "100%",
                                                        }}
                                                        type="primary"
                                                        size="large"
                                                        onClick={handleCopy}
                                                        children={t(
                                                            "copierVers",
                                                        )}
                                                        loading={copyLoader}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )*/}

                                    {toolView === "" &&
                                        permissionsTools?.visas?.canView &&
                                        selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.visas,
                                        ) &&
                                        !selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.files ||
                                                selectedFile.isOldVersion,
                                        ) &&
                                        (!userRoles.MODERATOR
                                            ? visasToAdd?.length > 0 &&
                                              !selectedFiles.some(
                                                  (selectedFile) =>
                                                      selectedFile.author?._id.toString() !==
                                                      profile.user._id.toString(),
                                              )
                                            : true) && (
                                            <div className="mt-4">
                                                <AntdButton
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="primary"
                                                    size="large"
                                                    children={t(
                                                        "demanderUnVisa",
                                                    )}
                                                    loading={isLoadVisas}
                                                    onClick={(e) =>
                                                        addModalData({
                                                            key: "addFileVisa",
                                                            title: t(
                                                                "demanderUnVisa",
                                                            ),
                                                            width: 1000,
                                                            isForm: false,
                                                            content: (
                                                                <AddVisa
                                                                    project={
                                                                        currentProject
                                                                    }
                                                                    files={
                                                                        selectedFiles
                                                                    }
                                                                    onClose={() =>
                                                                        removeModal(
                                                                            "addVisaFile",
                                                                        )
                                                                    }
                                                                    refreshAction={
                                                                        refreshAction
                                                                    }
                                                                    userRoles={
                                                                        userRoles
                                                                    }
                                                                    visas={
                                                                        visasToAdd
                                                                    }
                                                                />
                                                            ),
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                    {toolView === "" &&
                                        permissionsTools?.visas?.canView &&
                                        selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.visas?.length > 0,
                                        ) &&
                                        !selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.visas?.filter(
                                                    (visa) =>
                                                        visa.approvedDate ||
                                                        visa.refusedDate ||
                                                        visa.commentedDate ||
                                                        visa.notConcernedDate,
                                                ).length ===
                                                selectedFile.visas.length,
                                        ) &&
                                        !selectedFiles.some(
                                            (selectedFile) =>
                                                selectedFile.files ||
                                                selectedFile.isOldVersion,
                                        ) &&
                                        (userRoles.MODERATOR ||
                                            !selectedFiles.some(
                                                (selectedFile) =>
                                                    selectedFile.author?._id.toString() !==
                                                    profile.user._id.toString(),
                                            )) && (
                                            <div className="mt-4">
                                                <AntdButton
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="primary"
                                                    size="large"
                                                    children={t(
                                                        "retirerLesVisas",
                                                    )}
                                                    loading={isLoadVisas}
                                                    onClick={(e) => {
                                                        addModalData({
                                                            key: "removeFileVisa",
                                                            title: t(
                                                                "retirerLesVisas",
                                                            ),
                                                            width: 1000,
                                                            isForm: false,
                                                            content: (
                                                                <RemoveVisa
                                                                    files={
                                                                        selectedFiles
                                                                    }
                                                                    onClose={() =>
                                                                        removeModal(
                                                                            "removeFileVisa",
                                                                        )
                                                                    }
                                                                    refreshAction={
                                                                        refreshAction
                                                                    }
                                                                    visas={
                                                                        visasToRemove
                                                                    }
                                                                />
                                                            ),
                                                        });
                                                    }}
                                                />
                                            </div>
                                        )}

                                    {isAllSelectedModelsFiles() && (
                                        <div className="mt-4">
                                            <AntdButton
                                                style={{
                                                    width: "100%",
                                                    fontSize: 14,
                                                }}
                                                type="primary"
                                                size="large"
                                                onClick={(e) =>
                                                    selectedFiles.length >
                                                        maxSelection &&
                                                    toastAlert(
                                                        "warning",
                                                        t(
                                                            "limiteDeSelectionsAtteinte",
                                                        ),
                                                    )
                                                }
                                                href={
                                                    selectedFiles.length <=
                                                    maxSelection
                                                        ? "/viewer/" +
                                                          selectedFiles
                                                              .map(
                                                                  (file) =>
                                                                      file._id,
                                                              )
                                                              .join("-")
                                                        : ""
                                                }
                                                target="_blank"
                                                children={t(
                                                    "ouvrirDansLaVisionneuse",
                                                )}
                                            />
                                        </div>
                                    )}

                                    {isAllSelectedModelsFiles(true) &&
                                        selectedFiles.length > 1 &&
                                        userRoles.MODERATOR && (
                                            <div className="relative mt-4">
                                                <AntdButton
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    type="primary"
                                                    size="large"
                                                    onClick={(e) =>
                                                        selectedFiles.length >
                                                        maxSelection
                                                            ? toastAlert(
                                                                  "warning",
                                                                  t(
                                                                      "limiteDeSelectionsAtteinte",
                                                                  ),
                                                              )
                                                            : setInformationModal(
                                                                  {
                                                                      title: t(
                                                                          "regrouperDesFichiers",
                                                                      ),
                                                                      content: (
                                                                          <FilesCompilation
                                                                              profile={
                                                                                  profile
                                                                              }
                                                                              files={
                                                                                  selectedFiles
                                                                              }
                                                                              defaultProject={
                                                                                  currentProject
                                                                              }
                                                                              onConfirm={
                                                                                  onConfirmCompiles
                                                                              }
                                                                          />
                                                                      ),
                                                                      closeReset:
                                                                          setInformationModal,
                                                                      customWidth:
                                                                          "max-w-[500px]",
                                                                  },
                                                              )
                                                    }
                                                    children={t("regrouper")}
                                                />
                                            </div>
                                        )}

                                    {userRoles.MODERATOR && (
                                        <div className="mt-4">
                                            <AntdButton
                                                style={{
                                                    width: "100%",
                                                }}
                                                type="primary"
                                                size="large"
                                                onClick={(e) =>
                                                    setConfirmationModal({
                                                        title: t(
                                                            "voulezVousVraimentSupprimerCesElements?",
                                                        ),
                                                        content: (
                                                            <Fragment>
                                                                <ul className="text-base">
                                                                    {selectedFiles
                                                                        .sort(
                                                                            (
                                                                                a,
                                                                                b,
                                                                            ) =>
                                                                                a.title >
                                                                                b.title
                                                                                    ? 1
                                                                                    : -1,
                                                                        )
                                                                        .map(
                                                                            (
                                                                                selectedFile,
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        selectedFile._id
                                                                                    }
                                                                                    className="flex flex-row my-2"
                                                                                >
                                                                                    {selectedFile.files ? (
                                                                                        <i
                                                                                            className="material-icons notranslate inline mr-2 text-black my-auto"
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    "1.2rem",
                                                                                                color:
                                                                                                    selectedFile.color &&
                                                                                                    selectedFile.color,
                                                                                            }}
                                                                                        >
                                                                                            folder
                                                                                        </i>
                                                                                    ) : (
                                                                                        <img
                                                                                            src={getFileExtensionIcon(
                                                                                                selectedFile,
                                                                                            )}
                                                                                            loading="lazy"
                                                                                            alt={
                                                                                                selectedFile.title
                                                                                            }
                                                                                            className="w-[20px] mr-1"
                                                                                        ></img>
                                                                                    )}
                                                                                    {selectedFile.title +
                                                                                        (selectedFile.files
                                                                                            ? ""
                                                                                            : selectedFile
                                                                                                    .compiledFiles
                                                                                                    ?.length >
                                                                                                0
                                                                                              ? ".ifc"
                                                                                              : "." +
                                                                                                getFileExtension(
                                                                                                    selectedFile.file,
                                                                                                ))}
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                </ul>

                                                                <ul className="mt-5">
                                                                    {selectedFiles
                                                                        .filter(
                                                                            (
                                                                                selectedFile,
                                                                            ) =>
                                                                                getFileType(
                                                                                    selectedFile,
                                                                                ) ===
                                                                                "IFC",
                                                                        )
                                                                        .map(
                                                                            (
                                                                                selectedFile,
                                                                            ) => (
                                                                                <li
                                                                                    key={
                                                                                        selectedFile._id
                                                                                    }
                                                                                >
                                                                                    <FileRelatedCompiled
                                                                                        file={
                                                                                            selectedFile
                                                                                        }
                                                                                    />
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                </ul>
                                                            </Fragment>
                                                        ),
                                                        callBackFunc:
                                                            handleDeleteMultiple,
                                                        closeReset:
                                                            setConfirmationModal,
                                                        successText:
                                                            t("confirmer"),
                                                        errorText: t("annuler"),
                                                    })
                                                }
                                                children={t("supprimer")}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </Fragment>
                    )
                )}

                {informationModal && (
                    <InformationModal
                        title={informationModal.title}
                        content={informationModal.content}
                        closeReset={informationModal.closeReset}
                        isNoPadding={informationModal.isNoPadding}
                        customWidth={informationModal.customWidth}
                    />
                )}

                {confirmationModal && (
                    <ConfirmationModal
                        title={confirmationModal.title}
                        content={confirmationModal.content}
                        callBackFunc={confirmationModal.callBackFunc}
                        closeReset={confirmationModal.closeReset}
                    />
                )}
            </Card>
        )
    );
};

export default DriveSidePanel;
