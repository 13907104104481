const Badge = ({
    text,
    bgColor,
    textColor,
    borderColor,
    deleteClick,
    isSquare = false,
}) => {
    return (
        <div className="inline-block">
            <div
                className={
                    "flex flex-row text-xs font-bold mr-2 px-2.5 py-0.5 whitespace-nowrap" +
                    (isSquare ? " rounded-md" : " rounded-full")
                }
                style={{
                    backgroundColor: bgColor,
                    color: textColor,
                    borderColor: borderColor || bgColor,
                    borderWidth: 2,
                }}
            >
                <div className="my-auto">{text}</div>
                {deleteClick && (
                    <button
                        type="button"
                        onClick={(e) => deleteClick()}
                        className="flex p-0.5 pr-0"
                    >
                        <i
                            className="material-icons notranslate"
                            style={{ fontSize: "1rem" }}
                        >
                            close
                        </i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default Badge;
