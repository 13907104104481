import axios from "axios";

import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import { convertTemplateToAnswerDefault, deleteTemplateForm, formModalKey } from "helpers/forms";
import apiURL from "utils/apiURL";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { FormAnswer } from "./FormAnswer";
import { useFormsStore } from "./FormView";

import { Button, Flex, Form, Input, Switch, Tag, Tooltip, theme } from "antd";

export default function FormTemplateHeader() {
    const { t } = useTranslation();
    const { formId } = useParams();
    const { templateRef, loading } = useFormsStore();

    if (!loading)
        return (
            <Form form={templateRef} layout="inline" style={{ width: "100%" }}>
                <Flex gap="small" style={{ width: "100%" }} align="center">
                    <Link to={"/forms/templates"}>
                        <Text size="large" bold>
                            {t("formulaires")}
                        </Text>
                    </Link>
                    {templateRef.submit && <TitleForm templateRef={templateRef} formId={formId} />}
                    {templateRef.submit && <ActionButtons templateRef={templateRef} formId={formId} />}
                </Flex>
            </Form>
        );
}

const TitleForm = ({ templateRef }) => {
    const [editTitle, setEditTitle] = useState(false);
    const inputRef = useRef();

    const titleValue = Form.useWatch("title", templateRef);
    return (
        <>
            <MaterialIcons name="chevron_forward" />
            <Form.Item name="title" noStyle>
                <Input
                    ref={inputRef}
                    className="forms-title-edit"
                    required
                    htmlSize={titleValue?.length}
                    variant={editTitle ? "outlined" : "borderless"}
                    size="large"
                    // value={titleValue}
                    // onChange={(e) => {
                    //     setTitleValue(e.target.value);
                    //     templateRef?.setFieldValue("title", e.target.value);
                    // }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") setEditTitle(false);
                    }}
                    onFocus={(e) => {
                        if (e.relatedTarget?.tagName === "BUTTON") {
                        } else setEditTitle(true);
                    }}
                    onBlurCapture={(e) => {
                        if (e.relatedTarget?.tagName === "BUTTON") {
                        } else setEditTitle(false);
                    }}
                    style={{
                        minWidth: 150,
                        color: "black",
                        cursor: "text",
                        width: "auto",
                        marginRight: "auto",
                    }}
                    suffix={
                        <Button
                            shape="circle"
                            type="text"
                            onClick={() => {
                                setEditTitle((e) => {
                                    if (e) inputRef.current.blur();
                                    return !e;
                                });
                            }}>
                            <MaterialIcons
                                type={editTitle ? "primary" : "default"}
                                name={editTitle ? "check" : "edit"}
                            />
                        </Button>
                    }
                />
            </Form.Item>
        </>
    );
};

const ActionButtons = ({ templateRef, formId }) => {
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const { t } = useTranslation();
    const { startDriveSyncMessage, successDriveSyncMessage } = useContext(PopupContext);
    const { addModalData } = useContext(ModalContext);

    const [canDelete, setCanDelete] = useState(false);
    useEffect(() => {
        axios.get(apiURL.checkHasAnswers + formId).then((res) => {
            setCanDelete(!res?.data);
        });
    }, []);

    const active = Form.useWatch("active", templateRef);
    const title = Form.useWatch("title", templateRef);

    return (
        <>
            <Tag
                color={active ? "success" : "warning"}
                style={{
                    fontSize: 16,
                    padding: `${token.paddingLG / 4}px ${token.paddingLG}px`,
                }}>
                <Flex align="center" gap={token.paddingLG}>
                    {active ? "Actif" : "Inactif"}
                    <Form.Item name="active" noStyle>
                        <Switch />
                    </Form.Item>
                </Flex>
            </Tag>
            <Button
                size="large"
                onClick={() => {
                    const templateDataNow = templateRef.getFieldsValue();
                    addModalData({
                        key: formModalKey,
                        width: 800,
                        title: (
                            <Flex vertical>
                                <Text type="secondary">Prévisualisation du formulaire</Text>
                                <Text size="large" bold>
                                    {title}
                                </Text>
                            </Flex>
                        ),
                        content: <FormAnswer templateData={templateDataNow} />,
                        footer: <></>,
                        initialValues: convertTemplateToAnswerDefault(templateDataNow),
                    });
                }}>
                {t("previsualisation")}
                <MaterialIcons name="visibility" />
            </Button>

            <Flex gap="small">
                <Tooltip
                    title={canDelete ? null : "Des formulaires ont été enregistrés avec ce template"}
                    placement="bottom">
                    <Button
                        variant="outlined"
                        color="danger"
                        size="large"
                        disabled={!canDelete}
                        onClick={() => {
                            if (canDelete)
                                addModalData({
                                    key: "delete-form",
                                    title: "Supprimer le formulaire",
                                    content: "Êtes-vous sûr de vouloir supprimer ce formulaire ?",
                                    confirmText: "Supprimer",
                                    handleConfirm: () => {
                                        startDriveSyncMessage();
                                        deleteTemplateForm({
                                            id: formId,
                                        }).then(() => {
                                            navigate("/forms/templates");
                                            successDriveSyncMessage();
                                        });
                                    },
                                });
                        }}>
                        Supprimer
                    </Button>
                </Tooltip>
                <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                        templateRef?.submit?.();
                    }}>
                    Enregistrer
                </Button>
            </Flex>
        </>
    );
};
