import { useFiltersStore } from "providers/ProviderFilters";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import MaterialIcons from "components/common/MaterialIcons";

import { Badge, Button, theme } from "antd";

export default function NewButtonFilter({ filtersTypes }) {
    const { t } = useTranslation();

    const { token } = theme.useToken();
    const { filters, filterOpen, toggleFiltersPanel } = useFiltersStore();
    const { pathname } = useLocation();
    const filtersPage = filters[pathname];

    const countFilters = useMemo(() => {
        let count = 0;
        if (!filtersPage) return 0;
        if (filtersPage.forms?._id) count++;

        return count;
    }, [filtersPage]);

    return (
        <Badge color={token.colorPrimary} size="small" count={countFilters} offset={[-5, 5]}>
            <Button
                type={filterOpen ? "default" : "text"}
                onClick={() => {
                    toggleFiltersPanel(filtersTypes);
                }}
                icon={<MaterialIcons size="small" name="filter_alt" />}
                style={{
                    fontWeight: "bold",
                }}>
                {t("filtres")}
            </Button>
        </Badge>
    );
}
