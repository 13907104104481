import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import Constants from "utils/constants";

import Button from "components/input/Button";
import Textarea from "components/input/Textarea";

const CommentTextarea = ({
    isEdit,
    onChange,
    value,
    fieldsError,
    onSave,
    loader,
    onChangeMentions,
    mentionsList,
    height = 100,
    rows = 3,
    onImageUpload,
}) => {
    const { t } = useTranslation();

    return (
        <div className="mt-2 w-full flex flex-row text-sm">
            <Textarea
                placeholder={t("ecrivezUnCommentaire")}
                maxLength={Constants.maxLengthCommentContent}
                name="content"
                onChange={onChange}
                value={value}
                errorMsg={fieldsError?.content}
                rows={rows}
                isResizable={false}
                mentionsList={mentionsList}
                onChangeMentions={onChangeMentions}
                height={height}
                isNoBorder={true}
                showCharactersCount={false}
                isComment={true}
            />

            {onImageUpload && (
                <Fragment>
                    <button
                        type="button"
                        onClick={(e) => {
                            document
                                .getElementById("import-file-comment")
                                .click();
                        }}
                        className="p-1 mr-1 hover:cursor-pointer"
                    >
                        <i
                            className="material-icons notranslate flex"
                            style={{
                                fontSize: "1.3rem",
                            }}
                        >
                            photo_camera
                        </i>
                    </button>
                    <div className="hidden">
                        <Button
                            id="import-file-comment"
                            type="file"
                            accept={Constants.imagesList}
                            onChange={onImageUpload}
                        />
                    </div>
                </Fragment>
            )}

            {onSave && (
                <button
                    type="button"
                    className="p-1 mr-1 hover:cursor-pointer"
                    onClick={(e) => onSave()}
                    disabled={loader}
                >
                    {isEdit ? (
                        <i
                            className="material-icons notranslate flex text-green-500"
                            style={{
                                fontSize: "1.3rem",
                            }}
                        >
                            check
                        </i>
                    ) : (
                        <i
                            className="material-icons notranslate flex"
                            style={{
                                fontSize: "1.3rem",
                            }}
                        >
                            send
                        </i>
                    )}
                </button>
            )}
        </div>
    );
};

export default CommentTextarea;
