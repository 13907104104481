import axios from "axios";
import i18n from "i18next";
import PropTypes from "prop-types";

import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";

import ConnectionAPIClient, {
    checkBIMCollabRole,
    isBIMCollabAvailable,
} from "helpers/bcf";
import { getCustomerConfig } from "helpers/user";
import { takeScreenshot } from "helpers/viewer";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import BCFForm from "components/bcf/BCFForm";
import InformationModal from "components/modal/InformationModal";
import Login from "components/user/login/Login";

import { getCurrentProfile } from "actions/profile";

import CommentForm from "./CommentForm";
import CommentsList from "./CommentsList";
import NoComments from "./NoComments";

import { Select } from "antd";

export const CommentsContext = createContext(null);

const CommentsContent = ({
    profile: { profile },
    getCurrentProfile,
    files,
    filesIds,
    world,
    models,
    comments,
    setComments,
    searchedTerm,
    setSearchedTerm,
    commentsFilters,
    setCommentsFilters,
    addMarkerToList,
    activeBCF,
    setActiveBCF,
    set2DPlanVisible,
    deleteMarkerFromList,
    isTilesView = false,
    toggleBcfTiles,
    isFromViewer = true,
    isFromDocument = false,
    userRoles,
    compiledFile,
    BIMCollabProjects,
    currentBIMCollabProject,
    setCurrentBIMCollabProject,
    currentBIMCollabUser,
    bimCollabCommentsList,
    disableScrollFetch,
    isLightViewer,
    setSideVisibility,
    setBCFMode,
    handleMarkerMode,
    isMarkerMode,
    addedMarker,
    toggleShowMarkers,
    handleMarkerSaved,
    handleMarkerDeleted,
    fetchComments,
    isCommentsLimitReached,
}) => {
    const { t } = useTranslation();

    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const [informationModal, setInformationModal] = useState(null);

    const [showCommentForm, setShowCommentForm] = useState(!isFromViewer);
    const [activeItem, setActiveItem] = useState(0);
    const [isSearchVisible, setSearchVisible] = useState(false);

    const [priorities, setPriorities] = useState([]);
    const [members, setMembers] = useState([]);
    const [commentToAnswer, setCommentToAnswer] = useState("");

    const [areMarkersVisible, setMarkersVisible] = useState(false);

    const [isListMode, setListMode] = useState(!mdMedia);

    const [customerConfig, setCustomerConfig] = useState(null);

    const [bimCollabMembers, setBimCollabMembers] = useState([]);

    const checkCustomerConfig = () => {
        if (profile) {
            getCustomerConfig({}).then((res) =>
                res ? setCustomerConfig(res) : setCustomerConfig(false),
            );
        } else if (localStorage.getItem("guestName")) setCustomerConfig(false);
    };

    useEffect(() => {
        checkCustomerConfig();
    }, [profile]);

    useEffect(() => {
        if (!isFromViewer) return;

        if (!profile) getCurrentProfile();
    }, []);

    useEffect(() => {
        // On masque la barre de recherche par défaut sur mobile
        setSearchVisible(mdMedia);
    }, [mdMedia]);

    useEffect(() => {
        const handleResize = () => {
            if (!mdMedia && isTilesView) toggleBcfTiles();
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mdMedia]);

    useEffect(() => {
        if (activeBCF) {
            setActiveItem(
                comments.findIndex((comment) => comment._id === activeBCF),
            );

            setActiveBCF(null);
        }
    }, [activeBCF]);

    useEffect(() => {
        if (customerConfig === null) return;

        if (priorities.length === 0) {
            const getPriorities = async () => {
                const resPriorities = await axios.get(apiURL.getAllPriorities);

                if (resPriorities && resPriorities.data) {
                    const prioritiesList = [];

                    resPriorities.data.map((priority) => {
                        // La priorité par défaut est "Normale"
                        return prioritiesList.push({
                            val: priority._id,
                            label: priority["title" + i18n.locale],
                            isDefault: priority.position === 2 ? true : false,
                        });
                    });
                    setPriorities(prioritiesList);
                }
            };

            getPriorities();
        }

        if (members.length === 0 && profile) {
            const getMembers = async () => {
                let resMembers;

                if (isFromViewer && isBIMCollabAvailable(customerConfig)) {
                    // Pour définir la liste des users, on récupère celle de BIM Collab, et on garde tous ceux qui ont un compte
                    const apiClient = new ConnectionAPIClient(
                        customerConfig ? customerConfig.BIMCollab.url : "",
                    );

                    const resMembersAPI = await apiClient.getProjectMembers(
                        currentBIMCollabProject,
                    );

                    // Servira par la suite pour afficher les assignation des users n'étant pas dans le dossier ou sur BIMONO
                    // En revanche, on ne pourra assigner depuis BIMONO un BCF qu'à un membre BIM Collab ayant un compte chez nous
                    setBimCollabMembers(resMembersAPI);

                    const resMembersDB = await axios.get(
                        apiURL.getProjectMembers + files[0].project._id,
                    );

                    if (resMembersDB?.data) {
                        resMembers = resMembersDB.data.filter((member) =>
                            resMembersAPI.some(
                                (userAPI) =>
                                    userAPI.email === member.user?.email,
                            ),
                        );
                    }
                } else {
                    resMembers = await axios.get(
                        apiURL.getProjectMembers + files[0].project._id,
                    );

                    resMembers = resMembers.data;
                }

                if (resMembers) setMembers(resMembers);
            };

            getMembers();
        }
    }, [customerConfig, currentBIMCollabProject]);

    useEffect(() => {
        if (!disableScrollFetch) return;

        if (!mdMedia && !showCommentForm && !isListMode)
            disableScrollFetch(true);
        else disableScrollFetch(false);
    }, [mdMedia, showCommentForm, isListMode]);

    const toggleSearchBar = () => {
        setSearchVisible(!isSearchVisible);
    };

    const handleChange = (val) => {
        setCurrentBIMCollabProject(val);
    };

    const onClickAdd = async () => {
        if (profile || localStorage.getItem("guestName")) {
            if (isTilesView) toggleBcfTiles();
            if (isFromViewer) {
                const image = await takeScreenshot();

                setInformationModal({
                    title: t("ajouterUnCommentaire"),
                    content: (
                        <BCFForm
                            profile={profile && profile}
                            files={files}
                            compiledId={compiledFile && compiledFile._id}
                            set2DPlanVisible={set2DPlanVisible}
                            image={image}
                            onClose={() => setInformationModal()}
                        />
                    ),
                    closeReset: setInformationModal,
                    customWidth: "max-w-[750px] lg:max-w-[1050px]",
                });
            } else setShowCommentForm(true);
        } else {
            setInformationModal({
                content: (
                    <Login
                        isFromFile={true}
                        closeModal={() => setInformationModal()}
                    />
                ),
                closeReset: setInformationModal,
            });
        }
    };

    const respondToComment = (comment) => {
        setCommentToAnswer(comment);
    };

    const BIMCollabLogout = () => {
        localStorage.removeItem("BIM_API_RefreshToken");
        localStorage.removeItem("BIM_API_Token");

        setBCFMode(false);
        setTimeout(() => {
            setSideVisibility(false);
        }, 5);
    };

    return (
        <CommentsContext.Provider
            value={{
                models,
                comments,
                setComments: setComments,
                priorities,
                members,
                world,
                addMarkerToList,
                deleteMarkerFromList,
                activeBCF,
                setActiveBCF,
                isFromViewer,
                isFromDocument,
                customerConfig,
                userRoles,
                currentBIMCollabProject,
                currentBIMCollabUser,
                bimCollabMembers,
                bimCollabCommentsList,
                activeItem,
                setActiveItem: setActiveItem,
                isListMode,
                setListMode: setListMode,
                isTilesView,
                set2DPlanVisible,
                isLightViewer,
                respondToComment,
                handleMarkerMode,
                isMarkerMode,
                addedMarker,
                toggleShowMarkers,
                handleMarkerSaved,
                areMarkersVisible,
                setMarkersVisible,
                handleMarkerDeleted,
                fetchComments,
                isCommentsLimitReached,
            }}
        >
            {!isTilesView && (
                <div>
                    {!mdMedia &&
                        !showCommentForm &&
                        !isListMode &&
                        comments.length > 0 && (
                            <div className="w-full my-auto ml-2 inline-flex">
                                <button
                                    type="button"
                                    className="my-auto flex p-1"
                                    onClick={(e) =>
                                        !mdMedia &&
                                        activeItem > 0 &&
                                        setActiveItem(activeItem - 1)
                                    }
                                >
                                    <i
                                        className="material-icons notranslate"
                                        style={{ fontSize: "1.8rem" }}
                                    >
                                        chevron_left
                                    </i>
                                </button>
                                <span className="my-auto font-bold">
                                    {activeItem + 1} / {comments.length}
                                </span>
                                <button
                                    type="button"
                                    className="my-auto flex p-1"
                                    onClick={(e) =>
                                        !mdMedia &&
                                        activeItem < comments.length - 1 &&
                                        setActiveItem(activeItem + 1)
                                    }
                                >
                                    <i
                                        className="material-icons notranslate"
                                        style={{ fontSize: "1.8rem" }}
                                    >
                                        chevron_right
                                    </i>
                                </button>
                            </div>
                        )}

                    {!mdMedia && comments.length > 0 && !showCommentForm && (
                        <button
                            type="button"
                            onClick={(e) => toggleSearchBar()}
                            className="p-1 mx-2 md:absolute md:top-[11px] md:right-[50px]"
                            title={t("rechercher")}
                        >
                            <i
                                className="material-icons notranslate flex"
                                style={{ fontSize: "2rem" }}
                            >
                                search
                            </i>
                        </button>
                    )}

                    {(!isFromViewer || isLightViewer) && (
                        <div
                            className={
                                "w-full" + (isLightViewer ? " px-4" : "")
                            }
                        >
                            <CommentForm
                                user={profile && profile.user}
                                files={files}
                                showCommentForm={true}
                                setShowCommentForm={setShowCommentForm}
                                compiledId={compiledFile && compiledFile._id}
                                commentToAnswer={commentToAnswer}
                                setCommentToAnswer={setCommentToAnswer}
                            />
                        </div>
                    )}
                </div>
            )}

            {isBIMCollabAvailable(customerConfig) &&
                isFromViewer &&
                BIMCollabProjects &&
                !showCommentForm && (
                    <div className="px-2 mt-4 md:mt-0">
                        <div className="text-right mb-1">
                            <button
                                type="button"
                                className="text-primary text-sm"
                                onClick={BIMCollabLogout}
                            >
                                {t("seDeconnecter")}
                            </button>
                        </div>

                        <Select
                            options={BIMCollabProjects}
                            value={currentBIMCollabProject || undefined}
                            onChange={handleChange}
                            placeholder={t("projet")}
                            style={{ display: "block" }}
                        />
                    </div>
                )}

            {(comments.length > 0 || isSearchVisible) &&
                (!showCommentForm || !isFromViewer) && (
                    <CommentsList
                        profile={profile}
                        files={compiledFile ? [compiledFile] : files}
                        filesIds={filesIds}
                        toggleBcfTiles={toggleBcfTiles}
                        isSearchVisible={isSearchVisible}
                        searchedTerm={searchedTerm}
                        setSearchedTerm={setSearchedTerm}
                        commentsFilters={commentsFilters}
                        setCommentsFilters={setCommentsFilters}
                        onClickAdd={onClickAdd}
                        setListMode={setListMode}
                        showCommentForm={showCommentForm}
                        areMarkersVisible={areMarkersVisible}
                    />
                )}

            {comments.length === 0 && isFromViewer && !isLightViewer && (
                <NoComments
                    files={files}
                    setShowCommentForm={setShowCommentForm}
                    profile={profile}
                    toggleBcfTiles={toggleBcfTiles}
                    compiledFile={compiledFile}
                    hasBIMCollabRole={checkBIMCollabRole(
                        profile,
                        isFromViewer,
                        customerConfig,
                        "Editor",
                        currentBIMCollabUser,
                    )}
                />
            )}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    customWidth={informationModal.customWidth}
                />
            )}
        </CommentsContext.Provider>
    );
};

CommentsContent.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(CommentsContent);
