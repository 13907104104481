import * as OBC from "@thatopen/components";
import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import { Vector3 } from "three";

import apiURL from "utils/apiURL";

import { isDatesEquals } from "./date";
import { formatBCFNumber, takeScreenshot } from "./viewer";

const RefreshTokenKey = "BIM_API_RefreshToken";
const ClientID = process.env.REACT_APP_BIMCOLLAB_CLIENT_ID;
const ClientSecret = process.env.REACT_APP_BIMCOLLAB_CLIENT_SECRET;
const CallbackURL = process.env.REACT_APP_BASE_URL + "bcf-callback";
const Scope = "openid offline_access bcf";
const maxTopicsLimit = 50;

export default class ConnectionAPIClient {
    constructor(BIMCollabURL) {
        this.BIMcollabSpace = BIMCollabURL;
        this.SelectedApiVersion = null;
        this.API_Token =
            JSON.parse(localStorage.getItem("BIM_API_Token")) || null;
    }

    async getVersions() {
        const response = await axios.get(`${this.BIMcollabSpace}/bcf/versions`);
        const versions = response.data;
        this.SelectedApiVersion = versions.versions[1].version_id;
    }

    async login() {
        try {
            const auth = await this.getAuth();

            const refreshToken = localStorage.getItem(RefreshTokenKey);
            if (refreshToken) {
                this.API_Token = await this.getRefreshToken(auth, refreshToken);
                if (this.API_Token.expires_in > 0) {
                    localStorage.setItem(
                        RefreshTokenKey,
                        this.API_Token.refresh_token,
                    );
                    localStorage.setItem(
                        "BIM_API_Token",
                        JSON.stringify(this.API_Token),
                    );
                    return true;
                }
            }
            // Call getCode and handle the token exchange
            const code = await this.getCode(auth);

            if (code) {
                this.API_Token = await this.getToken(code);

                // Si token vide, on retire tous les éléments de l'API du localStorage
                if (this.API_Token === "") {
                    localStorage.removeItem("BIM_API_RefreshToken");
                    localStorage.removeItem("BIM_API_Token");
                } else {
                    localStorage.setItem(
                        RefreshTokenKey,
                        this.API_Token.refresh_token,
                    );
                    localStorage.setItem(
                        "BIM_API_Token",
                        JSON.stringify(this.API_Token),
                    );
                }
            } else {
                console.error("Failed to retrieve authorization code");
            }
        } catch (err) {
            localStorage.removeItem("BIM_API_RefreshToken");
            localStorage.removeItem("BIM_API_Token");

            await this.login();
        }
    }

    // GET Endpoints

    async getCurrentUser(projectId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const user = await this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/current-user`,
        );

        // On va récupérer les membres du projet courant, car c'est ici que l'on peut connaître le rôle du user
        const members = await this.getProjectMembers(projectId);

        const userMember = members.find((member) => member.email === user.id);

        const resUser = {
            id: user.id,
            name: user.name,
            role: userMember?.role || null,
        };

        return resUser;
    }

    async getProjects() {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects`,
        );
    }

    async getTopics(projectId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const topics = await this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics?$orderby=index desc`,
        );

        return topics;
    }

    async getFormattedTopics(
        projectId,
        topics,
        step,
        terms = "",
        filters,
        bcfId,
    ) {
        // On filtre les résultats selon les critères
        if (filters) {
            if (filters.status) {
                let selectedStatuses = [];

                if (filters.status.active) {
                    selectedStatuses.push("Active");
                }
                if (filters.status.resolved) {
                    selectedStatuses.push("Resolved");
                }
                if (filters.status.closed) {
                    selectedStatuses.push("Closed");
                }

                if (selectedStatuses.length > 0) {
                    topics = topics.filter((topic) =>
                        selectedStatuses.includes(topic.topic_status),
                    );
                }
            }

            if (filters.priority !== "") {
                const priority = await axios.get(
                    apiURL.getPriorityById + filters.priority,
                );

                if (priority) {
                    const formatedPriority = getPriorityByDBCode(
                        priority.data.code,
                    );
                    topics = topics.filter(
                        (topic) => topic.priority === formatedPriority,
                    );
                }
            }

            if (filters.createdBy) {
                topics = topics.filter(
                    (topic) => topic.creation_author === filters.createdBy,
                );
            }

            if (filters.assignedTo) {
                topics = topics.filter(
                    (topic) => topic.assigned_to === filters.assignedTo,
                );
            }

            if (filters.dueDate !== "") {
                const filtersDate = moment(filters.dueDate).startOf("day");

                switch (filters.dueDateOperator) {
                    case "<":
                        topics = topics.filter((topic) =>
                            moment(topic.due_date)
                                .startOf("day")
                                .isBefore(filtersDate),
                        );
                        break;
                    case "=":
                        topics = topics.filter((topic) =>
                            moment(topic.due_date)
                                .startOf("day")
                                .isSame(filtersDate),
                        );
                        break;
                    case ">":
                        topics = topics.filter((topic) =>
                            moment(topic.due_date)
                                .startOf("day")
                                .isAfter(filtersDate),
                        );
                        break;
                    default:
                        break;
                }
            }
        }

        if (terms !== "") {
            // On va récupérer les 50 résultats qui correspondent au mieux à la recherche
            const members = await this.getProjectMembers(projectId);

            const filteredTopics = topics.filter((topic) => {
                const member = members.find(
                    (member) => member.email === topic.creation_author,
                );

                return (
                    formatBCFNumber(topic.index).includes(terms) ||
                    topic.index.toString().includes(terms) ||
                    topic.title.toLowerCase().includes(terms.toLowerCase()) ||
                    topic.description
                        .toLowerCase()
                        .includes(terms.toLowerCase()) ||
                    member?.name?.toLowerCase().includes(terms.toLowerCase()) ||
                    topic.creation_author
                        ?.toLowerCase()
                        .includes(terms.toLowerCase())
                );
            });

            filteredTopics.sort((a, b) => {
                const aAuthor = members.find(
                    (member) => member.email === a.creation_author,
                );

                const bAuthor = members.find(
                    (member) => member.email === b.creation_author,
                );

                const aRelevance =
                    (a.title.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (a.description.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (aAuthor?.name?.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (a.creation_author
                        ?.toLowerCase()
                        .includes(terms.toLowerCase())
                        ? 1
                        : 0);

                const bRelevance =
                    (b.title.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (b.description.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (bAuthor?.name?.toLowerCase().includes(terms.toLowerCase())
                        ? 1
                        : 0) +
                    (b.creation_author
                        ?.toLowerCase()
                        .includes(terms.toLowerCase())
                        ? 1
                        : 0);

                if (aRelevance > bRelevance) {
                    return -1;
                } else if (aRelevance < bRelevance) {
                    return 1;
                } else {
                    return a.title.localeCompare(b.title);
                }
            });

            const formatedTopics = await this.fromBIMCollabToMongoDBFormat(
                projectId,
                filteredTopics.slice(
                    step * maxTopicsLimit,
                    step * maxTopicsLimit + maxTopicsLimit,
                ),
            );

            return formatedTopics;
        }

        let activeTopic;

        if (bcfId) {
            if (
                !topics
                    .slice(
                        step * maxTopicsLimit,
                        step * maxTopicsLimit + maxTopicsLimit,
                    )
                    .some((topic) => topic.guid === bcfId)
            )
                activeTopic = topics.find((topic) => topic.guid === bcfId);
        }

        let topicsToFormat = topics.slice(
            step * maxTopicsLimit,
            step * maxTopicsLimit + maxTopicsLimit,
        );

        if (activeTopic) topicsToFormat.push(activeTopic);

        const formatedTopics = await this.fromBIMCollabToMongoDBFormat(
            projectId,
            topicsToFormat,
        );

        return formatedTopics;
    }

    async getTopicById(projectId, topicId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}`,
        );
    }

    async getTopicViewpoint(projectId, topicId, viewpointId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/viewpoints/${viewpointId}`,
        );
    }

    async getViewpointImage(projectId, topicId, viewpointId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/viewpoints/${viewpointId}/snapshot`,
            "blob",
        );
    }

    async getTopicComments(projectId, topicId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/comments`,
        );
    }

    async getTopicCommentById(projectId, topicId, commentId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        return this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/comments/${commentId}`,
        );
    }

    async getProjectMembers(projectId) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const extensions = await this.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/extensions`,
        );

        if (extensions) {
            return extensions.team_members.map((member) => {
                return {
                    email: member.email,
                    name: member.name,
                    role: member.role,
                };
            });
        }

        return [];
    }

    async get(path, responseType = "json") {
        try {
            const response = await axios.get(path, {
                headers: {
                    Authorization: `Bearer ${this.API_Token.access_token}`,
                },
                responseType,
            });
            return response.data;
        } catch (e) {}
    }

    // POST Endpoints

    async createTopic(world, projectId, data) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const priority = await axios.get(
            apiURL.getPriorityById + data.priority,
        );

        const formatedPriority = getPriorityByDBCode(priority.data.code);

        const topicDatas = {
            title: data.title,
            description: data.content,
            priority: formatedPriority,
            assigned_to: data.assignedTo,
        };

        const topic = await this.post(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics`,
            topicDatas,
        );

        const viewpoint = await this.createTopicViewpoint(
            world,
            projectId,
            topic.guid,
            data,
        );

        // Association du Viewpoint au BCF

        const updatedTopic = await this.updateTopicViewpoint(
            projectId,
            topic.guid,
            `"${viewpoint.guid}"`,
        );

        const formatedRes = await this.fromBIMCollabToMongoDBFormat(projectId, [
            updatedTopic,
        ]);

        return formatedRes;
    }

    async createTopicViewpoint(world, projectId, topicId, data) {
        if (!this.SelectedApiVersion) await this.getVersions();

        // Convert Three.js coordinates to BIM Collab engine
        const camera = data.BCFContent.camera;

        const fragments = world.components.get(OBC.FragmentsManager);

        const position = new Vector3(
            camera.position[0],
            camera.position[1],
            camera.position[2],
        );

        position.applyMatrix4(
            fragments.baseCoordinationMatrix.clone().invert(),
        );

        const direction = new Vector3(
            camera.direction[0] - camera.position[0],
            -(camera.direction[2] - camera.position[2]),
            camera.direction[1] - camera.position[1],
        );

        direction.normalize();

        // Gestion des coupes
        const clippingPlanes = [];

        data.settings.forEach((setting) => {
            if (setting.type === "PLANE") {
                let settingDatas = JSON.parse(setting.datas);

                const planePosition = new Vector3(
                    settingDatas.origin.x,
                    settingDatas.origin.y,
                    settingDatas.origin.z,
                );

                planePosition.applyMatrix4(
                    fragments.baseCoordinationMatrix.clone().invert(),
                );

                const planeDirection = new Vector3(
                    settingDatas.normal.x,
                    -settingDatas.normal.z,
                    settingDatas.normal.y,
                );

                planeDirection.normalize();

                clippingPlanes.push({
                    location: {
                        x: planePosition.x,
                        y: -planePosition.z,
                        z: planePosition.y,
                    },
                    direction: {
                        x: -planeDirection.x,
                        y: -planeDirection.y,
                        z: -planeDirection.z,
                    },
                });
            }
        });

        // Create the viewpoint
        const thumbnail = await takeScreenshot(true);

        const viewpointData = {
            index: 0,
            perspective_camera: {
                camera_view_point: {
                    x: position.x,
                    y: -position.z,
                    z: position.y,
                },
                camera_direction: {
                    x: direction.x,
                    y: direction.y,
                    z: direction.z,
                },
                camera_up_vector: {
                    x: 0,
                    y: 1,
                    z: 0,
                },
                field_of_view: camera.fov,
            },
            snapshot: {
                snapshot_type: "jpg",
                snapshot_data: thumbnail,
            },
            is_default: true,
            components: {
                selection: [],
                coloring: [],
                visibility: {
                    defaultVisibility: true,
                    exceptions: [],
                    viewSetupHints: {
                        spacesVisible: true,
                        spaceBoundariesVisible: true,
                        openingsVisible: true,
                    },
                },
            },
            clipping_planes: clippingPlanes,
        };

        const viewpoint = await this.post(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/viewpoints`,
            viewpointData,
        );

        return viewpoint;
    }

    async createTopicComment(projectId, topicId, data) {
        if (!this.SelectedApiVersion) await this.getVersions();

        // Si on a une image, on crée un viewpoint
        if (data.image) {
            const viewpoint = await this.createTopicViewpointImage(
                projectId,
                topicId,
                data.image,
            );

            data.viewpoint_guid = viewpoint.guid;
        }

        await this.post(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/comments`,
            data,
        );

        // Récupération du topic complet
        const topic = await this.getTopicById(projectId, topicId);

        const formatedRes = await this.fromBIMCollabToMongoDBFormat(projectId, [
            topic,
        ]);

        return formatedRes[0];
    }

    // Création d'un viewpoint en conservant les coordonnes du viewpoint par défaut actuel, juste pour ajouter une image différente
    async createTopicViewpointImage(
        projectId,
        topicId,
        image,
        isDefault = true,
    ) {
        if (!this.SelectedApiVersion) await this.getVersions();

        // Récup du viewpoint par défaut pour conserver les datas
        const topic = await this.getTopicById(projectId, topicId);

        if (topic) {
            const defaultViewpoint = await this.getTopicViewpoint(
                projectId,
                topicId,
                topic.default_viewpoint_guid,
            );

            if (defaultViewpoint) {
                const viewpointData = {
                    index: 0,
                    perspective_camera: {
                        camera_view_point: {
                            x: defaultViewpoint.perspective_camera
                                .camera_view_point.x,
                            y: defaultViewpoint.perspective_camera
                                .camera_view_point.y,
                            z: defaultViewpoint.perspective_camera
                                .camera_view_point.z,
                        },
                        camera_direction: {
                            x: defaultViewpoint.perspective_camera
                                .camera_direction.x,
                            y: defaultViewpoint.perspective_camera
                                .camera_direction.y,
                            z: defaultViewpoint.perspective_camera
                                .camera_direction.z,
                        },
                        camera_up_vector: {
                            x: defaultViewpoint.perspective_camera
                                .camera_up_vector.x,
                            y: defaultViewpoint.perspective_camera
                                .camera_up_vector.y,
                            z: defaultViewpoint.perspective_camera
                                .camera_up_vector.z,
                        },
                        field_of_view:
                            defaultViewpoint.perspective_camera.field_of_view,
                    },
                    snapshot: {
                        snapshot_type: "jpg",
                        snapshot_data: image.replace(
                            /^data:image\/\w+;base64,/,
                            "",
                        ),
                    },
                    is_default: isDefault,
                    components: {
                        selection: [],
                        coloring: [],
                        visibility: {
                            defaultVisibility: true,
                            exceptions: [],
                            viewSetupHints: {
                                spacesVisible: true,
                                spaceBoundariesVisible: true,
                                openingsVisible: true,
                            },
                        },
                    },
                    clipping_planes: defaultViewpoint.clipping_planes,
                    dimensions: defaultViewpoint.dimensions,
                };

                const viewpoint = await this.post(
                    `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/viewpoints`,
                    viewpointData,
                );

                return viewpoint;
            }
        }

        return null;
    }

    async post(path, data) {
        const response = await axios.post(path, data, {
            headers: { Authorization: `Bearer ${this.API_Token.access_token}` },
        });
        return response.data;
    }

    // PUT Endpoints

    async updateTopic(world, projectId, topicId, data) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const priority = await axios.get(
            apiURL.getPriorityById +
                (data.priority._id ? data.priority._id : data.priority),
        );

        const formatedPriority = getPriorityByDBCode(priority.data.code);

        // Update du viewpoint (on en crée un nouveau)

        const viewpoint = await this.createTopicViewpoint(
            world,
            projectId,
            topicId,
            data,
        );

        const topicDatas = {
            title: data.title,
            description: data.content,
            priority: formatedPriority,
            assigned_to: data.assignedTo,
            default_viewpoint_guid: viewpoint.guid,
            topic_status: data.status,
            due_date: data.dueDate,
        };

        const topic = await this.put(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}`,
            topicDatas,
        );

        const formatedRes = await this.fromBIMCollabToMongoDBFormat(projectId, [
            topic,
        ]);

        return formatedRes[0];
    }

    async updateTopicViewpoint(projectId, topicId, data) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const topic = await this.put(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/default-viewpoint`,
            data,
        );

        return topic;
    }

    // Update d'une image d'un BCF, en conservant les données du précédent (on est obligé de créer un nouveau viewpoint pour faire ça)
    async updateTopicViewpointImage(projectId, topicId, image) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const newTopicViewpoint = await this.createTopicViewpointImage(
            projectId,
            topicId,
            image,
        );

        if (newTopicViewpoint) {
            const updatedTopic = await this.updateTopicViewpoint(
                projectId,
                topicId,
                `"${newTopicViewpoint.guid}"`,
            );

            const formatedRes = await this.fromBIMCollabToMongoDBFormat(
                projectId,
                [updatedTopic],
            );

            return formatedRes;
        }

        return null;
    }

    // Update d'une image d'un commentaire, (même principe que updateTopicViewpointImage mais pour un commentaire en particulier)
    async updateTopicCommentViewpointImage(
        projectId,
        topicId,
        commentId,
        image,
    ) {
        if (!this.SelectedApiVersion) await this.getVersions();

        // On check si le commentaire actuel est l'image de viewpoint par défaut
        const comment = await this.getTopicCommentById(
            projectId,
            topicId,
            commentId,
        );

        const topic = await this.getTopicById(projectId, topicId);

        if (comment && topic) {
            const isDefault =
                comment.viewpoint_guid === topic.default_viewpoint_guid;

            const newTopicViewpoint = await this.createTopicViewpointImage(
                projectId,
                topicId,
                image,
                isDefault,
            );

            if (newTopicViewpoint) {
                const updatedComment = await this.updateTopicComment(
                    projectId,
                    topicId,
                    commentId,
                    {
                        comment: comment.comment,
                        viewpoint_guid: newTopicViewpoint.guid,
                    },
                );

                if (updatedComment) {
                    // On récupère le topic entier pour refresh sur l'interface
                    const updatedTopic = await this.getTopicById(
                        projectId,
                        topicId,
                    );

                    const formatedRes = await this.fromBIMCollabToMongoDBFormat(
                        projectId,
                        [updatedTopic],
                    );

                    // On retourne que le commentaire concerné
                    return formatedRes[0].comments.find(
                        (comment) => comment._id === commentId,
                    );
                }
            }
        }

        return null;
    }

    async updateTopicStatus(projectId, comment, status) {
        if (!this.SelectedApiVersion) await this.getVersions();

        const priorityRes = await axios.get(
            apiURL.getPriorityById +
                (comment.priority._id
                    ? comment.priority._id
                    : comment.priority),
        );

        const formatedPriority = getPriorityByDBCode(priorityRes.data.code);

        const topic = await this.put(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${comment._id}`,
            {
                title: comment.title,
                topic_status: status,
                priority: formatedPriority,
                assigned_to: comment.assignedTo,
            },
        );

        const formatedRes = await this.fromBIMCollabToMongoDBFormat(projectId, [
            topic,
        ]);

        return formatedRes[0];
    }

    async updateTopicComment(projectId, topicId, commentId, data) {
        const topic = await this.put(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/projects/${projectId}/topics/${topicId}/comments/${commentId}`,
            data,
        );

        return topic;
    }

    async put(path, data) {
        const response = await axios.put(path, data, {
            headers: {
                Authorization: `Bearer ${this.API_Token.access_token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        return response.data;
    }

    // Auth

    async getAuth() {
        await this.getVersions();
        const response = await axios.get(
            `${this.BIMcollabSpace}/bcf/${this.SelectedApiVersion}/auth`,
        );
        return response.data;
    }

    async getCode(auth) {
        const params = new URLSearchParams({
            response_type: "code",
            client_id: ClientID,
            redirect_uri: CallbackURL,
            scope: Scope,
        });

        const authUrl = `${auth.oauth2_auth_url}?${params.toString()}`;

        window.open(authUrl, "_blank");

        // Return a promise that resolves with the auth code
        return new Promise((resolve) => {
            window.addEventListener("message", (event) => {
                if (event.data.code) {
                    resolve(event.data.code);
                }
            });
        });
    }

    async getToken(code) {
        try {
            const params = new URLSearchParams({
                client_id: ClientID,
                client_secret: ClientSecret,
                code: code,
                grant_type: "authorization_code",
                redirect_uri: CallbackURL,
            });

            const response = await axios.post(
                this.BIMcollabSpace + "identity/connect/token",
                params.toString(),
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                },
            );

            this.API_Token = response.data;
            localStorage.setItem(
                "BIM_API_Token",
                JSON.stringify(this.API_Token),
            );
            return response.data;
        } catch (error) {
            console.error(
                "Token request failed:",
                error.response ? error.response.data : error.message,
            );
            return "";
        }
    }

    async getRefreshToken(auth, refreshToken) {
        const params = new URLSearchParams({
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            client_id: ClientID,
            client_secret: ClientSecret,
        });

        const response = await axios.post(
            auth.oauth2_token_url,
            params.toString(),
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            },
        );

        this.API_Token = response.data;
        localStorage.setItem("BIM_API_Token", JSON.stringify(this.API_Token));
        return response.data;
    }

    // Format
    async fromBIMCollabToMongoDBFormat(projectId, topics) {
        const formatedRes = [];

        // Récup des priorités depuis la BDD en fonction de celle définie dans BIMCollab
        const priorities = await axios.get(apiURL.getAllPriorities);

        const members = await this.getProjectMembers(projectId);

        await Promise.all(
            topics
                .filter((topic) => topic.default_viewpoint_guid)
                .map(async (topic) => {
                    const viewpointDatas = await this.getTopicViewpoint(
                        projectId,
                        topic.guid,
                        topic.default_viewpoint_guid,
                    );

                    let viewpointImage = await this.getViewpointImage(
                        projectId,
                        topic.guid,
                        topic.default_viewpoint_guid,
                    );

                    // On converti l'image en Base64
                    viewpointImage =
                        viewpointImage && URL.createObjectURL(viewpointImage);

                    const priority = getPriorityByBIMCollabCode(
                        priorities?.data,
                        topic.priority,
                    );

                    const comments = await this.getTopicComments(
                        projectId,
                        topic.guid,
                    );

                    const author = members.find(
                        (member) => member.email === topic.creation_author,
                    );

                    // Gestion du viewpoint de la caméra
                    const cameraViewpoint =
                        viewpointDatas.perspective_camera ||
                        viewpointDatas.orthogonal_camera;

                    formatedRes.push({
                        _id: topic.guid,
                        title: topic.title,
                        content: topic.description,
                        guestName: topic.creation_author, // Using creator as guestName
                        bimCollabName: author?.name,
                        assignedTo: topic.assigned_to,
                        priority,
                        status:
                            topic.topic_status === "Active"
                                ? "ACTIVE"
                                : topic.topic_status === "Closed"
                                  ? "CLOSED"
                                  : topic.topic_status === "Resolved" &&
                                    "RESOLVED",
                        dueDate: topic.due_date && new Date(topic.due_date),
                        BCFNumber: topic.index,
                        BCFContent: {
                            image: viewpointImage,
                            camera: cameraViewpoint && {
                                position: [
                                    cameraViewpoint.camera_view_point.x,
                                    cameraViewpoint.camera_view_point.y,
                                    cameraViewpoint.camera_view_point.z,
                                ],
                                direction: [
                                    cameraViewpoint.camera_direction.x,
                                    cameraViewpoint.camera_direction.y,
                                    cameraViewpoint.camera_direction.z,
                                ],
                                upVector: [
                                    cameraViewpoint.camera_up_vector.x,
                                    cameraViewpoint.camera_up_vector.y,
                                    cameraViewpoint.camera_up_vector.z,
                                ],
                            },
                            fov: cameraViewpoint?.field_of_view,
                        },
                        comments: await Promise.all(
                            comments.map(async (comment) => {
                                const commentUser = members.find(
                                    (member) => member.email === comment.author,
                                );

                                let commentViewpointImage =
                                    await this.getViewpointImage(
                                        projectId,
                                        topic.guid,
                                        comment.viewpoint_guid,
                                    );

                                commentViewpointImage =
                                    commentViewpointImage &&
                                    URL.createObjectURL(commentViewpointImage);

                                return {
                                    _id: comment.guid,
                                    content: comment.comment,
                                    image: commentViewpointImage,
                                    guestName: comment.author,
                                    bimCollabName: commentUser?.name,
                                    creationDate: new Date(comment.date),
                                    updatedDate: new Date(
                                        comment.modified_date,
                                    ),
                                };
                            }),
                        ),
                        viewpointDatasAPI: viewpointDatas,
                        settings: [],
                        creationDate: new Date(topic.creation_date),
                        updatedDate: new Date(topic.modified_date),
                    });
                }),
        );

        return formatedRes;
    }
}

// Check si l'on affiche les BCF via BIM Collab API ou le système classique
export function isBIMCollabAvailable(customerConfig) {
    if (!customerConfig) return false;

    if (
        customerConfig.BIMCollab.isActive &&
        customerConfig.BIMCollab.url.length > 0
    )
        return true;

    return false;
}

// Check le rôle de l'utilisateur dans BIM Collab pour donner accès ou non à cerrtaines actions
export function checkBIMCollabRole(
    profile,
    isFromViewer,
    customerConfig,
    role,
    user,
) {
    // Config pas encore traitée car user non connecté (Il n'est pas encore en mode guest)
    if (customerConfig === null) return profile ? false : true;

    if (!isFromViewer || !isBIMCollabAvailable(customerConfig)) return true;

    if (!user?.role) return false;

    const leaderRole = "Project leader";
    const editorRole = "Editor";
    const reviewerRole = "Reviewer";
    const viewerRole = "Viewer";

    if (role === leaderRole) {
        if (user.role === leaderRole) return true;
        else return false;
    } else if (role === editorRole) {
        if (user.role === leaderRole || user.role === editorRole) return true;
        else return false;
    } else if (role === reviewerRole) {
        if (
            user.role === leaderRole ||
            user.role === editorRole ||
            user.role === reviewerRole
        )
            return true;
        else return false;
    } else if (role === viewerRole) {
        if (
            user.role === leaderRole ||
            user.role === editorRole ||
            user.role === reviewerRole ||
            user.role === viewerRole
        )
            return true;
        else return false;
    }

    return false;
}

// Retourne la bonne priorité selon le code BIMCollab
const getPriorityByBIMCollabCode = (priorities, code) => {
    let priority;

    if (!priorities) return priority;

    switch (code) {
        case "On hold":
            priority = priorities.find((item) => item.code === "ON_HOLD");
            break;
        case "Minor":
            priority = priorities.find((item) => item.code === "MINOR");
            break;
        case "Normal":
            priority = priorities.find((item) => item.code === "NORMAL");
            break;
        case "Major":
            priority = priorities.find((item) => item.code === "MAJOR");
            break;
        case "Critical":
            priority = priorities.find((item) => item.code === "CRITICAL");
            break;
        default:
            break;
    }

    return priority;
};

// Retourne le code priorité BIMCollab selon le code BCDD
const getPriorityByDBCode = (priorityCode) => {
    let BIMCollabValue = "";

    if (!priorityCode) return BIMCollabValue;

    switch (priorityCode) {
        case "ON_HOLD":
            BIMCollabValue = "On hold";
            break;
        case "MINOR":
            BIMCollabValue = "Minor";
            break;
        case "NORMAL":
            BIMCollabValue = "Normal";
            break;
        case "MAJOR":
            BIMCollabValue = "Major";
            break;
        case "CRITICAL":
            BIMCollabValue = "Critical";
            break;
        default:
            break;
    }

    return BIMCollabValue;
};

// Retourne une description de l'activité pour un commentaire en particulier
export function getActivityDetails(bcf, comment) {
    // Créé par
    if (isDatesEquals(bcf.creationDate, comment.creationDate, "second"))
        return (
            i18n.t("creePar") +
            " " +
            (comment.bimCollabName
                ? comment.bimCollabName
                : comment.author
                  ? comment.author.firstname + " " + comment.author.lastname
                  : comment.guestName)
        );

    // Modifié par (par défaut)
    return (
        i18n.t("modifiePar") +
        " " +
        (comment.bimCollabName
            ? comment.bimCollabName
            : comment.author
              ? comment.author.firstname + " " + comment.author.lastname
              : comment.guestName)
    );
}
