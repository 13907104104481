import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import FilterByForm from "components/filters/FilterByForm";

import { Button, Drawer, Flex } from "antd";

export const defaultFields = [
    {
        _id: "reference",
        title: "Ref.",
    },
    {
        _id: "title",
        title: "Modèle de formulaire",
    },
    {
        _id: "author",
        title: "Auteur",
    },
    {
        _id: "creationDate",
        title: "Date de création",
    },
    {
        _id: "updatedDate",
        title: "Date de modification",
    },
    {
        _id: "status",
        title: "Statut",
    },
];

export const useFiltersStore = create(
    persist(
        (set, get) => ({
            // DATA
            filters: {
                "/forms": {
                    forms: null,
                    formColumns: defaultFields,
                    formFields: null,
                },
            },
            filtersTypes: [],
            filterOpen: false,

            // ACTIONS

            // Open filters
            toggleFiltersPanel: async (filtersTypes) => {
                if (filtersTypes)
                    set({ filterOpen: !get().filterOpen, filtersTypes });
                else set({ filterOpen: !get().filterOpen });
            },

            setFilters: (filters) => {
                let newFilters = { ...get().filters };
                newFilters[window.location.pathname] = {
                    ...newFilters[window.location.pathname],
                    ...filters,
                };

                set({
                    filters: newFilters,
                });
            },
        }),
        {
            name: "filters-storage",
            storage: createJSONStorage(() => localStorage),
            partialize: (state) => ({ ...state, filterOpen: undefined }),
        },
    ),
);

export default function ProviderFilters() {
    const { filters, filtersTypes, filterOpen, toggleFiltersPanel } =
        useFiltersStore();
    const { t } = useTranslation();

    useEffect(() => {
        const handleClickOutside = (event) => {
            const drawer = document.getElementById("filters-drawer");
            if (
                drawer &&
                !drawer.contains(event.target) &&
                !event.target.closest(".ant-select-dropdown")
            ) {
                toggleFiltersPanel();
            }
        };

        if (filterOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [filterOpen, toggleFiltersPanel]);

    return (
        <Drawer
            id="filters-drawer"
            open={filterOpen}
            onClose={() => {
                toggleFiltersPanel();
            }}
            mask={null}
            title={
                <Text bold size="md">
                    {t("filtres")}
                </Text>
            }
            width={450}
            styles={{
                body: {
                    padding: 10,
                },
            }}
            destroyOnClose
            closeIcon={null}
            extra={
                <Button
                    onClick={() => {
                        toggleFiltersPanel();
                    }}
                    icon={<MaterialIcons name="close" />}
                    shape="circle"
                    type="text"
                />
            }
        >
            <Flex vertical gap="small">
                {filtersTypes?.includes("forms") && <FilterByForm />}
            </Flex>
        </Drawer>
    );
}
