import { useNavigate } from "react-router-dom";

const PortalApplicationItem = ({ icon, text, link }) => {
    const navigate = useNavigate();

    return (
        <div className="text-black w-[100px] sm:w-[120px] inline-block mx-8 text-center mb-6">
            <button
                onClick={(e) =>
                    navigate(link, { state: { isPortalChoice: true } })
                }
            >
                <div className="bordered-card rounded-2xl shadow-sm w-[100px] sm:w-[120px] h-[100px] sm:h-[120px] flex">
                    <i
                        className="material-icons notranslate m-auto"
                        style={{ fontSize: "5rem" }}
                    >
                        {icon}
                    </i>
                </div>
            </button>
            <div className="mt-2 font-bold text-xs">{text}</div>
        </div>
    );
};

export default PortalApplicationItem;
