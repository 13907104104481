import { Link } from "react-router-dom";

import { Button } from "antd";

const ContactFormButton = ({ text, isTextOnly = false }) => {
    if (isTextOnly) {
        return (
            <Link to="https://app.bimono.io/contact" target="_blank">
                {text}
            </Link>
        );
    }

    return (
        <Button
            type="primary"
            href="https://app.bimono.io/contact"
            target="_blanck"
        >
            {text}
        </Button>
    );
};

export default ContactFormButton;
