import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import SwitchButton from "components/input/SwitchButton";
import InfoTooltip from "components/utils/InfoTooltip";

import InputCellSelect from "./InputCellSelect";
import InputCellText from "./InputCellText";
import { TableInlineContext } from "./Table";

const TableRenderCell = ({
    field,
    dataLine,
    errorMsg,
    setErrorMsg,
    isLastCell,
}) => {
    const { onEditInline } = useContext(TableInlineContext);
    const { t } = useTranslation();
    const [detailOpen, setDetailOpen] = useState(false);

    let value = null;
    if (!dataLine) {
        value = "";
    } else if (field.specialRender) {
        value = field.specialRender(dataLine);
    } else if (field.key.includes(".")) {
        const keys = field.key.split(".");
        value = dataLine[keys[0]]?.[keys[1]];
    } else {
        value = dataLine[field.key];
    }

    if (field.getOptions && field.getOptions(dataLine))
        return (
            <InputCellSelect
                isDisabled={field.isDisabled}
                dataLine={dataLine}
                field={field}
                value={value}
                onChange={async (newValue) => {
                    await onEditInline(dataLine, field.key, newValue);
                }}
            />
        );

    if (typeof value === "boolean" || field.isBoolean)
        return (
            <div className="w-min mx-auto">
                <SwitchButton
                    isDisabled={field.isDisabled}
                    value={value || false}
                    onChange={async () => {
                        await onEditInline(dataLine, field.key, !value);
                    }}
                />
            </div>
        );

    const isTextArea = value?.includes("\n") || value?.length > 50;

    return (
        <InputCellText
            isDisabled={field.isDisabled}
            name={field.key}
            value={isTextArea && !detailOpen ? "" : value || ""}
            placeholder={
                isTextArea && !detailOpen ? `+ ${t("plus").toLowerCase()}` : ""
            }
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            isLastCell={isLastCell}
            type={isTextArea ? "textarea" : field.type}
            rows={detailOpen ? value?.split("\n").length + 1 : 1}
            onClick={() => setDetailOpen(true)}
            onKeyDown={async (e) => {
                if (e.key === "Enter") {
                    const response = await onEditInline(
                        dataLine,
                        field.key,
                        e.target.value,
                    );
                    e.target.blur();
                    if (response.status === 200) {
                        setErrorMsg();
                    } else {
                        setErrorMsg(response.message);
                    }
                }
            }}
            onBlur={async (e) => {
                setDetailOpen(false);
                if (e.target.value !== value && e.target.value !== undefined) {
                    const response = await onEditInline(
                        dataLine,
                        field.key,
                        e.target.value,
                    );
                    if (response.status === 200) {
                        setErrorMsg();
                    } else {
                        setErrorMsg(response.message);
                    }
                }
            }}
        />
    );
};

export const CellWrapper = ({ field, dataLine, ButtonOptions, indexField }) => {
    const { t } = useTranslation();
    const { fields, setDataList, setLoader } = useContext(TableInlineContext);

    const [errorMsg, setErrorMsg] = useState();

    const isLastCell = indexField === fields.length - 1;
    return (
        <td
            key={`${field.key} + ${dataLine._id}`}
            className={
                "group relative  align-middle justify-center px-3 border-2 text-xs whitespace-nowrap" +
                (errorMsg && !field.isDisabled
                    ? " border border-red-500"
                    : field.isDisabled
                      ? ""
                      : " hover:border-blue-500")
            }
        >
            <div
                className="relative flex items-center"
                // Here to change the style/padding of the first cell of the line if this is children
                style={{
                    paddingLeft:
                        indexField === 0 && dataLine.childrenLevel
                            ? ` ${dataLine.childrenLevel * 20}px `
                            : "0px",
                }}
            >
                <TableRenderCell
                    errorMsg={errorMsg}
                    setErrorMsg={setErrorMsg}
                    field={field}
                    dataLine={dataLine}
                    isLastCell={isLastCell}
                />
                {ButtonOptions && isLastCell && !dataLine.locked && (
                    <ButtonOptions
                        dataLine={dataLine}
                        setDataList={setDataList}
                        setLoader={setLoader}
                    />
                )}
                {!!dataLine.locked && isLastCell && (
                    <div className="w-[10px] -left-[18px] relative">
                        <InfoTooltip
                            alignRight
                            text={
                                <span className="text-slate-500 mr-2 p-2">
                                    {t(
                                        "desSaisiesDeTempsOntEteEnvoyesSurUnProjetLieACeClientIlNePeutPlusEtreSupprimeMaisPeutEncoreEtreModifie",
                                    )}
                                </span>
                            }
                        />
                    </div>
                )}
            </div>
        </td>
    );
};

export default TableRenderCell;
