import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useLocation } from "react-router";

import useLongPress from "helpers/dnd";
import { getFileExtension } from "helpers/file";
import { convertTemplateToAnswerDefault, formModalKey } from "helpers/forms";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import DocumentReader from "components/documents/DocumentReader";
import { FooterAnswerForm, FormAnswer } from "components/forms/FormAnswer";
import { TitleFormAnswer } from "components/forms/FormAnswer";
import { useFormsStore } from "components/forms/FormView";
import { ExtensionImageContent } from "components/project/FileImage";

import { ModalContext } from "layout/LayoutModal";

import ModalSelectForm from "../forms/ModalSelectForm";

import { Dropdown, Empty, Flex, Select } from "antd";

export default function PlansView() {
    const { t } = useTranslation();
    const { listPlans, getAllowedPlans, setSelectedPlan, selectedPlan, positionClick, setPositionClick, getMarkers } =
        usePlansStore();
    const { permissionsTools, user } = useProfileStore();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");
    const { pathname } = useLocation();
    // DropDown
    const { addModalData, removeModal } = useContext(ModalContext);
    const [optionsOpen, setOptionsOpen] = useState(false);

    const openDropDown = (e) => {
        const isOnPdf = e.target.closest(".react-pdf__Page") || e.target.closest(".rpv-core__viewer");
        setOptionsOpen(!!isOnPdf);
        if (!isOnPdf) setPositionClick(null);
    };

    const refContainer = useRef(null);

    const backspaceLongPress = useLongPress((e) => {
        if (pathname == "/plans" && positionClick) {
            const element = refContainer.current.querySelector(".react-pdf__Page canvas");
            element.dispatchEvent(
                new MouseEvent("click", {
                    bubbles: true,
                    cancelable: true,
                    view: window,
                    clientX: e.nativeEvent.clientX,
                    clientY: e.nativeEvent.clientY,
                }),
            );
            openDropDown(e);
        }
    }, 1000);

    // Plans Managment
    useEffect(() => {
        getAllowedPlans();
    }, [getAllowedPlans]);
    useEffect(() => {
        if (!selectedPlan && listPlans.length > 0) {
            setSelectedPlan(listPlans[0]);
        } else if (listPlans.length === 0) {
            usePlansStore.setState({ selectedPlan: null });
        } else {
            getMarkers();
        }

        return () => {
            usePlansStore.setState({ positionsForms: [], positionClick: null });
        };
    }, [selectedPlan, listPlans]);

    // Forms Managment
    const { listTemplates, getTemplatesForms } = useFormsStore();
    useEffect(() => {
        getTemplatesForms();
    }, [getTemplatesForms]);

    if (!listPlans?.length)
        return (
            <Empty
                style={{
                    margin: "auto",
                }}
            />
        );

    if (selectedPlan)
        return (
            <Dropdown
                open={optionsOpen}
                onOpenChange={(open) => {
                    if (!open) {
                        setOptionsOpen(false);
                        setPositionClick(null);
                    }
                }}
                menu={{
                    items: [
                        (permissionsTools.formsAdmin.canView || permissionsTools.formsUser.canView) && {
                            icon: <MaterialIcons name="add" />,
                            label: t("creerUnFormulaire"),
                            onClick: () => {
                                addModalData({
                                    key: "selectTemplate",
                                    title: t("remplirUnFormulaire"),
                                    isForm: false,
                                    content: (
                                        <ModalSelectForm
                                            listTemplates={listTemplates
                                                .filter((e) => e.active)
                                                .sort((a, b) => a.title.localeCompare(b.title))}
                                            onSelect={(form) => {
                                                addModalData({
                                                    key: formModalKey,
                                                    okText: t("envoyer"),
                                                    title: form.title,
                                                    width: 800,
                                                    fullScreen: !mdMedia,
                                                    title: <TitleFormAnswer templateData={form} author={form.author} />,
                                                    content: <FormAnswer templateData={form} />,
                                                    footer: (
                                                        <FooterAnswerForm
                                                            _id={null}
                                                            templateData={form}
                                                            planOptions={{
                                                                selectedPlan,
                                                                positionClick,
                                                            }}
                                                        />
                                                    ),
                                                    initialValues: convertTemplateToAnswerDefault({
                                                        ...form,
                                                        author: user,
                                                    }),
                                                    handleConfirm: async (values) => {
                                                        removeModal("selectTemplate");
                                                        removeModal(formModalKey);
                                                        await getMarkers();
                                                    },
                                                });
                                            }}
                                        />
                                    ),
                                });
                            },
                        },
                    ],
                }}
                trigger={["contextMenu", "click"]}>
                <div
                    ref={refContainer}
                    onContextMenu={openDropDown}
                    {...backspaceLongPress}
                    style={{
                        height: "100%",
                        zIndex: 1,
                        userSelect: "none",
                    }}>
                    <DocumentReader fileId={selectedPlan._id} isFromCompare={true} isFromViewer />
                </div>
            </Dropdown>
        );
}

export const TitlePlanView = () => {
    const { t } = useTranslation();
    const { listPlans, selectedPlan, setSelectedPlan } = usePlansStore();
    const selectRef = useRef(null);
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");

    return (
        <>
            <Text bold size="large">
                {t("plans")}
            </Text>
            <MaterialIcons name="chevron_forward" />
            <Select
                ref={selectRef}
                size="large"
                showSearch
                placeholder={t("rechercher")}
                style={{
                    width: "100%",
                    maxWidth: 500,
                }}
                dropdownStyle={
                    mdMedia
                        ? {}
                        : {
                              position: "absolute",
                              maxWidth: "100vw",
                              left: 0,
                          }
                }
                value={selectedPlan?._id}
                options={listPlans.map((plan) => ({
                    ...plan,
                    label: plan.title + "." + getFileExtension(plan.file),
                    value: plan._id,
                }))}
                onChange={(value) => {
                    setSelectedPlan(listPlans.find((e) => e._id === value));
                }}
                onSelect={() => {
                    selectRef.current.blur();
                }}
                popupMatchSelectWidth={false}
                optionRender={(option) => (
                    <Flex vertical>
                        <Flex align="center" gap="small">
                            <ExtensionImageContent file={option.data} width={24} />
                            <Text ellipsis>{option.label}</Text>
                        </Flex>
                    </Flex>
                )}
            />
        </>
    );
};

export const usePlansStore = create(
    persist(
        (set, get) => ({
            // Data
            listPlans: [],
            selectedPlan: null,
            selectedForm: null,

            positionsForms: [],
            positionClick: null,

            // Actions
            getAllowedPlans: async () => {
                const { data: listPlans } = await axios.get(apiURL.getAllowedPlans);
                set({ listPlans });
            },
            setSelectedPlan: async (selectedPlan) => {
                if (!selectedPlan) {
                    set({ selectedPlan: null });
                }

                const { data: positionsForms } = await axios.get(apiURL.getPositionsForms + selectedPlan._id);
                set({ selectedPlan, positionsForms });
            },
            setSelectedForm: async (selectedForm) => {
                set({ selectedForm });
            },
            setPositionClick: async (positionClick) => {
                set({ positionClick });
            },
            getMarkers: async () => {
                const selectedPlan = get().selectedPlan;
                if (selectedPlan) {
                    const { data: positionsForms } = await axios.get(apiURL.getPositionsForms + selectedPlan._id);
                    set({ positionsForms });
                }
            },
        }),
        {
            name: "plans-storage", // name of the item in the storage (must be unique)
            storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
            partialize: (state) => ({
                listPlans: state.listPlans,
                selectedPlan: state.selectedPlan,
                selectedForm: state.selectedForm,
                positionClick: state.positionClick,
                // positionsForms is intentionally omitted to avoid persistence
            }),
        },
    ),
);
