import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "components/input/Button";
import TextField from "components/input/TextField";

const TextMatchConfirm = ({ text, termToMatch, onCancel, onConfirm }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState("");

    return (
        <Fragment>
            <div className="mt-4 mb-2 text-sm">{text}</div>
            <TextField
                name="delete-title"
                autoComplete="off"
                onChange={(e) => setValue(e.target.value)}
            />
            <div className="flex flex-row justify-end mt-6 gap-2">
                <div className="inline-block">
                    <Button
                        text={t("annuler")}
                        isReversed={true}
                        onClick={onCancel}
                    />
                </div>
                <div className="inline-block" id="delete-title-confirm-btn">
                    <Button
                        text={t("confirmer")}
                        onClick={onConfirm}
                        isDisabled={value !== termToMatch}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export default TextMatchConfirm;
