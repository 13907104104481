import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { copyToClipBoard } from "helpers/input";

import MaterialIcons from "components/common/MaterialIcons";

import { PopupContext } from "layout/LayoutPopup";

import { Button, Flex, Typography, theme } from "antd";

const CodeDisplay = ({ code }) => {
    const { t } = useTranslation();
    const { runMessage } = useContext(PopupContext);
    const { token } = theme.useToken();

    return (
        <>
            <Flex justify="space-between" align="center">
                <Typography.Title level={4}>Iframe HTML</Typography.Title>
                <Button
                    onClick={async () => {
                        await copyToClipBoard(code).then(() => {
                            runMessage({
                                content: t("copieDansLePressePapier"),
                                type: "success",
                            });
                        });
                    }}
                    type="primary"
                    icon={<MaterialIcons name="link" />}
                >
                    {t("copier")}
                </Button>
            </Flex>
            <div className="text-left code-block overflow-auto">
                <pre
                    style={{
                        width: "1000px",
                        color: token.colorPrimaryBgHover,
                    }}
                >
                    {code}
                </pre>
            </div>
        </>
    );
};

export default CodeDisplay;
