import { useState } from "react";
import { useTranslation } from "react-i18next";

import Constants from "utils/constants";

import AutoCompleteTextField from "components/input/AutoCompleteTextField";
import Button from "components/input/Button";
import Checkbox from "components/input/Checkbox";
import DatePicker from "components/input/DatePicker";
import Select from "components/input/Select";

const CommentsFilters = ({
    commentsFilters,
    setCommentsFilters,
    priorities,
    members,
    onClose,
}) => {
    const { t } = useTranslation();

    const [assignedToList, setAssignedToList] = useState(null);
    const [createdByList, setCreatedByList] = useState(null);

    const initialFormData = {
        priority: "",
        createdBy: "",
        assignedTo: null,
        dueDateOperator: "<",
        dueDate: "",
        status: {
            active: true,
            resolved: false,
            closed: false,
        },
    };

    const [formData, setFormData] = useState(
        commentsFilters || initialFormData,
    );

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onCheck = (e) => {
        const updatedStatus = formData.status;

        if (formData.status[e.target.name] === false) {
            updatedStatus[e.target.name] = true;
        } else {
            updatedStatus[e.target.name] = false;
        }

        setFormData({ ...formData, status: updatedStatus });
    };

    const onClickAssigned = (value) => {
        setFormData({ ...formData, assignedTo: value });
    };

    const onClickCreated = (value) => {
        setFormData({ ...formData, createdBy: value });
    };

    const onChangeAssigned = async (e) => {
        // On reset l'assignation car on vient de modifier la valeur du champ
        if (formData.assignedTo) onClickAssigned(null);

        processOnChange(e, setAssignedToList);
    };

    const onChangeCreated = async (e) => {
        if (formData.assignedTo) onClickCreated(null);

        processOnChange(e, setCreatedByList);
    };

    const processOnChange = (e, setResult) => {
        if (e.target.value.length < 2) {
            setResult(null);
            return;
        }

        const terms = e.target.value.toLowerCase();

        const resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (resMembersByTerm.length > 0) {
            let updatedList = [];

            resMembersByTerm.forEach((user) => {
                updatedList.push({
                    id: user.email,
                    text:
                        user.firstname +
                        " " +
                        user.lastname +
                        " (" +
                        user.email +
                        ")",
                });
            });

            setResult(updatedList);
        } else setResult([]);
    };

    const saveFilters = () => {
        setCommentsFilters(formData);
        onClose();
    };

    const resetFilters = () => {
        setFormData(initialFormData);
    };

    const getAssignedUserDisplayName = () => {
        return processGetDisplayName("assignedTo");
    };

    const getCreatedUserDisplayName = () => {
        return processGetDisplayName("createdBy");
    };

    const processGetDisplayName = (name) => {
        const assignedMember = members.find(
            (member) => member.user.email === formData[name],
        );
        if (assignedMember) {
            return `${assignedMember.user.firstname} ${assignedMember.user.lastname} (${formData[name]})`;
        }

        if (formData.assignedTo?.email) {
            return `${formData[name].firstname} ${formData[name].lastname} (${formData[name].email})`;
        }
        return "";
    };

    return (
        <form>
            <div className="flex flex-row">
                <div className="w-1/2 px-2">
                    <AutoCompleteTextField
                        text={t("creePar")}
                        name="createdBy"
                        availableValues={createdByList}
                        setAvailableValues={setCreatedByList}
                        onClickChoice={onClickCreated}
                        onChange={onChangeCreated}
                        maxLength={Constants.maxLengthEmail}
                        defaultValue={getCreatedUserDisplayName()}
                    />
                </div>
                <div className="w-1/2 px-2">
                    <AutoCompleteTextField
                        text={t("assigneA")}
                        name="assignedTo"
                        availableValues={assignedToList}
                        setAvailableValues={setAssignedToList}
                        onClickChoice={onClickAssigned}
                        onChange={onChangeAssigned}
                        maxLength={Constants.maxLengthEmail}
                        defaultValue={getAssignedUserDisplayName()}
                    />
                </div>
            </div>
            <div className="flex flex-row mt-4">
                <div className="w-1/2 px-2">
                    <Select
                        name="priority"
                        text={t("priorite")}
                        options={[
                            { val: "", label: t("toutes") },
                            ...priorities,
                        ]}
                        isRequired={true}
                        onChange={onChange}
                        value={formData.priority}
                    />
                </div>
            </div>
            <div className="flex flex-row mt-4">
                <div className="w-1/2 px-2 pt-2">
                    <label className="text-sm font-bold">
                        {t("dateDecheance")}
                    </label>
                    <div className="flex flex-row">
                        <div className="my-auto mr-1">
                            <Select
                                name="dueDateOperator"
                                options={[
                                    { val: "<", label: t("inferieureAu") },
                                    { val: "=", label: t("egaleAu") },
                                    { val: ">", label: t("superieureAu") },
                                ]}
                                isRequired={true}
                                onChange={onChange}
                                value={formData.dueDateOperator}
                            />
                        </div>

                        <div className="my-auto">
                            <DatePicker
                                name="dueDate"
                                onChange={onChange}
                                value={formData.dueDate}
                                minToday={false}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-1/2 px-2 pt-2">
                    <label className="text-sm font-bold">{t("statut")}</label>
                    <div className="flex flex-row gap-4 mt-3">
                        <Checkbox
                            name="active"
                            text={t("actif")}
                            onChange={onCheck}
                            value={formData.status.active}
                        />
                        <Checkbox
                            name="resolved"
                            text={t("resolu")}
                            onChange={onCheck}
                            value={formData.status.resolved}
                        />
                        <Checkbox
                            name="closed"
                            text={t("ferme")}
                            onChange={onCheck}
                            value={formData.status.closed}
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-end gap-4 mt-4">
                <div>
                    <Button
                        onClick={resetFilters}
                        text={t("reinitialiser")}
                        isReversed={true}
                    />
                </div>
                <div>
                    <Button onClick={saveFilters} text={t("enregistrer")} />
                </div>
                <div className="my-auto">
                    <button
                        type="button"
                        onClick={onClose}
                        className="text-black text-sm"
                    >
                        {t("annuler")}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CommentsFilters;
