import PropTypes from "prop-types";
import { useProfileStore } from "providers/ProviderProfile";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import InformationModal from "components/modal/InformationModal";
import Login from "components/user/login/Login";

import { logout } from "actions/auth";

const UserMenuItem = ({
    to,
    text,
    isLogin = false,
    isLogout = false,
    isMobileMenu = false,
    logout,
}) => {
    const { t } = useTranslation();
    const [informationModal, setInformationModal] = useState(null);
    const { logout: providerLogout } = useProfileStore();

    // Ouverture du modal de Login sans redirection
    const handleLogin = (e) => {
        e.preventDefault();
        setInformationModal({
            title: t("connexion"),
            content: <Login />,
            closeReset: setInformationModal,
            isNoPadding: true,
            customWidth: "max-w-[750px] lg:max-w-[1050px]",
        });
    };

    return (
        <li>
            {!isLogin ? (
                <Link
                    to={to ? to : "/home"}
                    className="block py-2 px-4 text-sm text-gray-200 hover:bg-gray-800 hover:text-white"
                    onClick={(e) => {
                        if (isLogout) {
                            providerLogout();
                            logout();
                        }
                    }}
                >
                    {text}
                </Link>
            ) : (
                <button
                    className={
                        "w-full text-left py-2 px-4 text-sm text-gray-200 hover:text-white " +
                        (isMobileMenu
                            ? " hover:bg-secondary"
                            : " hover:bg-gray-600")
                    }
                    onClick={(e) => handleLogin(e)}
                >
                    {text}
                </button>
            )}
            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    isNoPadding={informationModal.isNoPadding}
                    customWidth={informationModal.customWidth}
                />
            )}
        </li>
    );
};

UserMenuItem.propTypes = {
    logout: PropTypes.func.isRequired,
};

export default connect(null, { logout })(UserMenuItem);
