import axios from "axios";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fromDateToDatePickerValue, fromDateToStringDate } from "helpers/date";
import { fieldValidation } from "helpers/fieldValidation";
import { getSettingByCode } from "helpers/setting";
import { generateRandomToken } from "helpers/token";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import DatePicker from "components/input/DatePicker";
import SwitchButton from "components/input/SwitchButton";
import TextField from "components/input/TextField";
import ConfirmationModal from "components/modal/ConfirmationModal";
import Spinner from "components/utils/Spinner";

import { Button, Flex } from "antd";

const SharingOptions = ({
    user,
    file,
    refreshAction,
    userRoles,
    removeModal,
}) => {
    const [confirmationModal, setConfirmationModal] = useState(null);

    const [fileDatas, setFileDatas] = useState(file);
    const [formData, setFormData] = useState({
        password: "",
        expirationDate: "",
    });

    const [fieldsError, setFieldsError] = useState({
        password: "",
    });

    const [isDownloadActive, setDownloadActive] = useState(
        file && file.isPublicDownload ? true : false,
    );
    const [isPasswordActive, setPasswordActive] = useState(
        file && file.isPasswordAccess ? true : false,
    );
    const [isExpirationActive, setExpirationActive] = useState(
        file && file.expireTmpAccessToken ? true : false,
    );

    const [editPassword, setEditPassword] = useState(false);
    const [editExpiration, setEditExpiration] = useState(false);

    const [maxDays, setMaxDays] = useState(null);

    const [loader, setLoader] = useState(false);

    const { t } = useTranslation();

    useEffect(() => {
        if (
            editExpiration &&
            fileDatas &&
            fileDatas.expireTmpAccessToken &&
            formData.expirationDate === ""
        ) {
            setFormData({
                ...formData,
                expirationDate: fromDateToDatePickerValue(
                    file.expireTmpAccessToken,
                ),
            });
        }
    }, [editExpiration]);

    useEffect(() => {
        const getMaxDays = async () => {
            const res = await getSettingByCode(
                "MAX_TMP_FILE_ACCESS_DURATION_DAYS",
            );
            setMaxDays(res);
        };

        getMaxDays();
    }, []);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        setLoader(true);

        const resValidation = await fieldsValidation();

        if (
            resValidation === 0 &&
            (formData.password !== "" ||
                formData.expirationDate !== "" ||
                isDownloadActive !== file.isPublicDownload)
        ) {
            let datas = {
                id: fileDatas._id,
                isPublicDownload: isDownloadActive,
            };

            if (formData.password !== "") {
                datas.password = formData.password;
                datas.isPasswordAccess = true;
            }

            if (formData.expirationDate !== "") {
                datas.expireTmpAccessToken = new Date(formData.expirationDate);
                let token = generateRandomToken(24);
                datas.publicToken = token;
            }

            const updatedFile = await axios.post(
                apiURL.updateProjectFile,
                datas,
            );

            if (updatedFile && updatedFile.data) {
                if (refreshAction) refreshAction();

                setFileDatas(updatedFile.data);

                toastAlert("success", t("modificationsEnregistrees"));

                removeModal && removeModal("shareFile");
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setEditPassword(false);
        setEditExpiration(false);
        setFormData({
            password: "",
            expirationDate: "",
        });

        setLoader(false);
    };

    const fieldsValidation = async () => {
        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        if (formData.password !== "") {
            validation = fieldValidation(formData.password, "password");
            updatedFieldsError.password = validation;
            if (validation !== "") nbErrors++;
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const handleRemovePassword = async () => {
        setLoader(true);

        const updatedFile = await axios.post(apiURL.updateProjectFile, {
            id: fileDatas._id,
            isPasswordAccess: false,
            password: null,
        });

        if (updatedFile && updatedFile.data) {
            if (refreshAction) refreshAction();

            setFileDatas(updatedFile.data);
            setPasswordActive(false);

            toastAlert("success", t("motDePasseRetire"));
        } else toastAlert("error", t("uneErreurEstSurvenue"));

        setLoader(false);
    };

    const handleRemoveExpiration = async () => {
        setLoader(true);

        const updatedFile = await axios.post(apiURL.updateProjectFile, {
            id: fileDatas._id,
            expireTmpAccessToken: null,
        });

        if (updatedFile && updatedFile.data) {
            if (refreshAction) refreshAction();

            setFileDatas(updatedFile.data);
            setExpirationActive(false);

            toastAlert("success", t("dateDexpirationRetiree"));
        } else toastAlert("error", t("uneErreurEstSurvenue"));

        setLoader(false);
    };

    const hasPermission = () => {
        return user && userRoles.MODERATOR;
    };

    const handleSwitchPassword = () => {
        if (isPasswordActive && fileDatas.isPasswordAccess) {
            setConfirmationModal({
                title: t("confirmation"),
                content: t(
                    "confirmezVousLaDesactivationDuMotDePassePourAccederACeFichier?",
                ),
                callBackFunc: handleRemovePassword,
                closeReset: setConfirmationModal,
            });
        } else setPasswordActive(!isPasswordActive);
    };

    const handleSwitchExpiration = () => {
        if (isExpirationActive && fileDatas.expireTmpAccessToken) {
            setConfirmationModal({
                title: t("confirmation"),
                content: t(
                    "confirmezVousLaDesactivationDuLienDaccesTemporaire?",
                ),
                callBackFunc: handleRemoveExpiration,
                closeReset: setConfirmationModal,
            });
        } else {
            setExpirationActive(!isExpirationActive);
        }
    };

    return (
        <div className="text-left p-2 mt-2">
            <div className="font-bold text-black">{t("optionsDePartage")}</div>

            <div className="mt-6">
                <div className="inline-block">
                    <SwitchButton
                        text={t("autoriserLeTelechargementDuFichier")}
                        isDisabled={hasPermission() ? false : true}
                        isUserOnly={user ? false : true}
                        value={isDownloadActive}
                        onChange={(e) => setDownloadActive(!isDownloadActive)}
                    />
                </div>
                <div className="sm:ml-12 max-w-[400px]">
                    <div
                        className={
                            "text-xs my-2" +
                            (!hasPermission() ? " text-slate-300" : "")
                        }
                    >
                        {t(
                            "lesUtilisateursNayantPasDeCompteOuNeFaisantPasPartieDuDossierPourrontTelechargerLeFichier",
                        )}
                    </div>
                </div>
            </div>

            <div className="my-6">
                <div className="inline-block">
                    <SwitchButton
                        text={t("protegerAvecUnMotDePasse")}
                        isDisabled={hasPermission() ? false : true}
                        isUserOnly={user ? false : true}
                        value={isPasswordActive}
                        onChange={(e) => handleSwitchPassword()}
                    />
                </div>
                <div className="sm:ml-12 max-w-[400px]">
                    <div
                        className={
                            "text-xs my-2" +
                            (!hasPermission() ? " text-slate-300" : "")
                        }
                    >
                        {t(
                            "definissezUnMotDePassePourLimiterLaccesAuFichierViaUnLien",
                        )}
                    </div>
                    {fileDatas &&
                    fileDatas.isPasswordAccess &&
                    !editPassword &&
                    hasPermission() ? (
                        <Flex gap={8} style={{ marginTop: 10 }}>
                            <Button
                                color="primary"
                                variant="solid"
                                onClick={(e) => setEditPassword(true)}
                            >
                                {t("modifier")}
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleRemovePassword}
                            >
                                {t("retirer")}
                            </Button>
                        </Flex>
                    ) : (
                        isPasswordActive &&
                        hasPermission() && (
                            <Fragment>
                                <TextField
                                    placeholder={t("motDePasse")}
                                    name="password"
                                    maxLength={Constants.maxLengthPassword}
                                    type="password"
                                    onChange={onChange}
                                    value={formData.password}
                                    errorMsg={fieldsError.password}
                                    enablePasswordValidation={true}
                                />
                                {editPassword && (
                                    <div style={{ marginTop: 10 }}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={(e) =>
                                                setEditPassword(false)
                                            }
                                        >
                                            {t("annuler")}
                                        </Button>
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                </div>
            </div>

            <div className="my-6">
                <div className="inline-block">
                    <SwitchButton
                        text={t("ajouterUneDateDexpiration")}
                        isDisabled={hasPermission() ? false : true}
                        isUserOnly={user ? false : true}
                        value={isExpirationActive}
                        onChange={(e) => handleSwitchExpiration()}
                    />
                </div>
                <div className="sm:ml-12 max-w-[400px]">
                    <div
                        className={
                            "text-xs my-2" +
                            (!hasPermission() ? " text-slate-300" : "")
                        }
                    >
                        {t("desactivezCeLienAUneDateSpecifique")}
                    </div>
                    {fileDatas &&
                    fileDatas.expireTmpAccessToken &&
                    !editExpiration ? (
                        <div>
                            {t("expireLe") +
                                " " +
                                fromDateToStringDate(
                                    fileDatas.expireTmpAccessToken,
                                    true,
                                )}
                            {hasPermission() && (
                                <Flex gap={8} style={{ marginTop: 10 }}>
                                    <Button
                                        color="primary"
                                        variant="solid"
                                        onClick={(e) => setEditExpiration(true)}
                                    >
                                        {t("modifier")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={handleRemoveExpiration}
                                    >
                                        {t("retirer")}
                                    </Button>
                                </Flex>
                            )}
                        </div>
                    ) : (
                        isExpirationActive && (
                            <Fragment>
                                <DatePicker
                                    name="expirationDate"
                                    onChange={onChange}
                                    value={formData.expirationDate}
                                    maxDays={maxDays}
                                />
                                {editExpiration && (
                                    <div style={{ marginTop: 10 }}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            onClick={(e) =>
                                                setEditExpiration(false)
                                            }
                                        >
                                            {t("annuler")}
                                        </Button>
                                    </div>
                                )}
                            </Fragment>
                        )
                    )}
                </div>
            </div>

            {hasPermission() &&
                ((isPasswordActive && formData.password !== "") ||
                    (isExpirationActive && formData.expirationDate !== "") ||
                    isDownloadActive !== file.isPublicDownload) && (
                    <div className="w-full text-right">
                        <Button
                            color="primary"
                            variant="solid"
                            onClick={onSubmit}
                            disabled={loader}
                        >
                            {t("enregistrer")}
                        </Button>
                    </div>
                )}

            {loader && <Spinner />}

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    closeReset={confirmationModal.closeReset}
                />
            )}
        </div>
    );
};

export default SharingOptions;
