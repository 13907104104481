import axios from "axios";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { fromDateToStringDate } from "helpers/date";
import { formatSizeToDisplay, getProjectSize } from "helpers/size";
import apiURL from "utils/apiURL";

import FileImage from "./FileImage";

const ProjectInfos = ({ project, user }) => {
    const { t } = useTranslation();

    const [size, setSize] = useState(null);

    const [members, setMembers] = useState([]);
    const [membersNoCurrent, setMembersNoCurrent] = useState([]);

    useEffect(() => {
        const getFileSize = async () => {
            const resSize = await getProjectSize(project._id);

            setSize(resSize);
        };

        getFileSize();

        if (members.length === 0) {
            const getMembers = async () => {
                const resMembers = await axios.get(
                    apiURL.getProjectMembers + project._id,
                );

                if (resMembers && resMembers.data) {
                    setMembers(resMembers.data);
                    setMembersNoCurrent(
                        resMembers.data.filter(
                            (member) =>
                                member.user && member.user._id !== user._id,
                        ),
                    );
                }
            };

            getMembers();
        }
    }, [project]);

    return (
        project && (
            <div className="text-left">
                <div
                    className="text-xs font-bold mb-2 truncate"
                    title={project.title}
                >
                    {project.title}
                </div>
                <FileImage file={project} isFromInfos={true} user={user} />
                <div className="text-xs mt-3">
                    <div className="my-2">
                        <span className="font-bold">
                            {t("proprietaire")} -{" "}
                        </span>{" "}
                        {project.author
                            ? project.author.firstname +
                              " " +
                              project.author.lastname
                            : t("inconnu")}
                    </div>
                    {membersNoCurrent.length > 0 && (
                        <div className="my-2">
                            <span className="font-bold">
                                {t("partageAvec")} -{" "}
                            </span>
                            {membersNoCurrent.length === 1
                                ? " " +
                                  membersNoCurrent[0].user.firstname +
                                  " " +
                                  membersNoCurrent[0].user.lastname
                                : membersNoCurrent.length === 2
                                  ? " " +
                                    membersNoCurrent[0].user.firstname +
                                    " " +
                                    membersNoCurrent[0].user.lastname +
                                    " " +
                                    t("et") +
                                    " " +
                                    membersNoCurrent[1].user.firstname +
                                    " " +
                                    membersNoCurrent[1].user.lastname
                                  : " " +
                                    membersNoCurrent[0].user.firstname +
                                    " " +
                                    membersNoCurrent[0].user.lastname +
                                    ", " +
                                    membersNoCurrent[1].user.firstname +
                                    " " +
                                    membersNoCurrent[1].user.lastname +
                                    " " +
                                    t("et") +
                                    " " +
                                    (membersNoCurrent.length - 2) +
                                    " " +
                                    (membersNoCurrent.length - 2 === 1
                                        ? t("personne")
                                        : t("personnes"))}
                        </div>
                    )}
                    <div className="my-2">
                        <span className="font-bold">{t("type")} - </span>
                        {project.author && project.author._id !== user._id
                            ? t("dossier") + " (" + t("partageAvecVous") + ")"
                            : t("dossier")}
                    </div>
                    <div className="my-2">
                        <span className="font-bold">
                            {t("derniereModification")} -{" "}
                        </span>
                        {fromDateToStringDate(
                            project.updatedDate
                                ? project.updatedDate
                                : project.creationDate,
                        )}
                    </div>
                    <div className="my-2">
                        <span className="font-bold">
                            {t("dateDeCreation")} -{" "}
                        </span>
                        {fromDateToStringDate(project.creationDate)}
                    </div>
                    {size && (
                        <div className="my-2">
                            <span className="font-bold">{t("taille")} - </span>
                            {formatSizeToDisplay(size)}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

export default ProjectInfos;
