import React, { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";

import { Input } from "antd";

const Textarea = ({
    text,
    placeholder = "",
    name,
    value,
    maxLength,
    onChange,
    errorMsg,
    isRequired = false,
    rows = 4,
    isTransparent = false,
    isResizable = true,
    mentionsList,
    onChangeMentions,
    height = 138,
    isNoBorder = false,
    showCharactersCount = true,
}) => {
    const textareaRef = useRef(null);
    const [isExpanded, setIsExpanded] = useState(value?.trim().length > 0);

    useEffect(() => {
        setIsExpanded(value === "" ? false : true);
    }, [value]);

    const handleMentionChange = (ev, newValue, newPlainTextValue) => {
        if (newValue.length > maxLength) return;

        onChange(ev, name);

        const atIndex = newPlainTextValue.lastIndexOf("@");
        const newMentionPart = newPlainTextValue.substring(atIndex);

        onChangeMentions(newMentionPart);
    };

    const textareaStyles = {
        width: "100%",
        backgroundColor: isTransparent ? "transparent" : undefined,
        border: isNoBorder ? "none" : errorMsg ? "1px solid red" : undefined,
        resize: isResizable ? "both" : "none",
        padding: 5,
    };

    const mentionsInputStyle = {
        control: {
            width: "100%",
            minHeight: isExpanded ? height : 32,
            border: isNoBorder ? "none" : "1px solid #d9d9d9",
            borderRadius: 4,
            padding: 8,
            fontSize: 14,
            boxShadow: isNoBorder
                ? "none"
                : "0 1px 3px rgba(0,0,0,0.1), 0 1px 2px rgba(0,0,0,0.06)",
        },
        highlighter: {
            overflow: "hidden",
        },
        input: {
            margin: 0,
            padding: 5,
        },
        suggestions: {
            list: {
                width: "100%",
                boxSizing: "border-box",
                backgroundColor: "#fff",
                boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                fontSize: 11,
            },
            item: {
                width: "100%",
                display: "block",
                padding: "6px 8px",
            },
        },
    };

    return (
        <div style={{ width: "100%" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 4,
                }}
            >
                {text && (
                    <label
                        htmlFor={name}
                        style={{ fontWeight: "bold", fontSize: 14 }}
                    >
                        {isRequired && <span style={{ color: "red" }}>*</span>}{" "}
                        {text}
                    </label>
                )}
                {showCharactersCount && (
                    <small style={{ color: "#64748B", fontSize: 12 }}>
                        {value.length <= maxLength ? value.length : maxLength} /{" "}
                        {maxLength}
                    </small>
                )}
            </div>

            <div
                style={{
                    backgroundColor: onChangeMentions ? "white" : undefined,
                    borderRadius: 8,
                    boxShadow: onChangeMentions
                        ? "0 1px 3px rgba(0,0,0,0.1)"
                        : "none",
                }}
            >
                {!onChangeMentions ? (
                    <Input.TextArea
                        ref={textareaRef}
                        placeholder={placeholder}
                        id={name}
                        name={name}
                        value={value}
                        maxLength={maxLength}
                        onChange={onChange}
                        rows={isExpanded ? rows : 1}
                        style={textareaStyles}
                    />
                ) : (
                    <div style={{ width: "100%", position: "relative" }}>
                        <MentionsInput
                            inputRef={textareaRef}
                            placeholder={placeholder}
                            value={value}
                            id={name}
                            name={name}
                            onChange={handleMentionChange}
                            maxLength={maxLength}
                            style={mentionsInputStyle}
                            positionSuggestions={() => ({
                                position: "absolute",
                                left: 0,
                                top: "100%", // appear just below the input
                            })}
                        >
                            <Mention
                                data={mentionsList}
                                renderSuggestion={(entry) => (
                                    <div title={entry.display}>
                                        {entry.display}
                                    </div>
                                )}
                                appendSpaceOnAdd
                                displayTransform={(id) => `@${id}`}
                                markup="@[__id__]"
                            />
                        </MentionsInput>
                    </div>
                )}
            </div>

            {errorMsg && (
                <div
                    style={{
                        color: "red",
                        fontSize: 12,
                        fontStyle: "italic",
                        fontWeight: "bold",
                        marginTop: 8,
                    }}
                >
                    {errorMsg}
                </div>
            )}
        </div>
    );
};

export default Textarea;
