import axios from "axios";
import i18n from "i18next";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fromDateToStringDate } from "helpers/date";
import { isOwnerMember } from "helpers/user";
import apiURL from "utils/apiURL";

import Checkbox from "components/input/Checkbox";
import Select from "components/input/Select";
import ConfirmationModal from "components/modal/ConfirmationModal";
import ClipboardCopy from "components/utils/ClipboardCopy";

import UserCircle from "./UserCircle";

function MemberItem({
    project,
    member,
    membersList,
    setMembersList,
    roles,
    visas,
    refreshAction,
    isFromTimesheet,
    emailsToShare,
    setEmailsToShare,
    userRoles,
}) {
    const { t } = useTranslation();
    const { permissionsTools } = useProfileStore();

    const [isShowOptions, showOptions] = useState(false);

    const [isMemberOptions, showMemberOptions] = useState(false);
    const [isEditRole, setEditRole] = useState(false);
    const [isEditVisa, setEditVisa] = useState(false);

    const [confirmationModal, setConfirmationModal] = useState(null);

    const [role, setRole] = useState(member.role._id);
    const [visa, setVisa] = useState(
        member.visas && member.visas[0] ? member.visas[0]._id : "",
    );

    const [roleLabel, setRoleLabel] = useState(
        member.role["title" + i18n.locale],
    );
    const [visaLabel, setVisaLabel] = useState(
        member.visas && member.visas[0] ? member.visas[0].title : "",
    );

    const [isDeleted, setDeleted] = useState(false);

    useEffect(() => {
        showOptions(false);
    }, [isEditRole]);

    const onChangeRole = (e) => {
        setRole(e.target.value);
    };

    const onChangeVisa = (e) => {
        setVisa(e.target.value);
    };

    const updateRole = async () => {
        setEditRole(false);

        try {
            const resUpdated = await axios.post(apiURL.updateProjectUserRole, {
                projectId: project._id,
                memberId: member._id,
                roleId: role,
                isInvitation: member.user ? false : true,
            });

            if (resUpdated && resUpdated.data) {
                const newRole = roles.find(
                    (item) => item.val === role.toString(),
                );

                if (newRole) setRoleLabel(newRole.label);

                refreshAction();
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const updateVisa = async () => {
        setEditVisa(false);

        try {
            const resUpdated = await axios.post(apiURL.updateProjectUserVisa, {
                projectId: project._id,
                memberEmail: member.user ? member.user.email : member.email,
                visaId: visa,
                isInvitation: member.user ? false : true,
            });

            if (resUpdated && resUpdated.data) {
                const newVisa = visas.find(
                    (item) => item.val === visa.toString(),
                );

                setVisaLabel(newVisa ? newVisa.label : "");

                refreshAction();
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const removeMember = async () => {
        try {
            const resDeleted = await axios.delete(
                apiURL.removeProjectMember +
                    project._id +
                    "/" +
                    member.user._id,
            );

            if (resDeleted && resDeleted.data) {
                refreshAction();
                setDeleted(true);
                setMembersList(
                    membersList.filter((item) => item._id !== member._id),
                );
            } else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }
    };

    const handleEmailAdd = () => {
        if (!emailsToShare.some((email) => email === member.user.email))
            setEmailsToShare([...emailsToShare, member.user.email]);
        else
            setEmailsToShare(
                emailsToShare.filter((email) => email !== member.user.email),
            );
    };

    const handleDeleteInvitation = async () => {
        const resDeleted = await axios.delete(
            apiURL.projectDeleteInvitation + project._id + "/" + member._id,
        );

        if (resDeleted && resDeleted.data) setDeleted(true);
        else toastAlert("error", t("uneErreurEstSurvenue"));
    };

    return (
        !isDeleted && (
            <div
                className={
                    "relative" +
                    (!isFromTimesheet ? " border-b md:border-b-0" : "")
                }
                onClick={(e) => isFromTimesheet && handleEmailAdd()}
            >
                <div
                    className={
                        "hover:cursor-default md:flex md:flex-row overflow-auto" +
                        (isShowOptions ? " rounded-t-lg" : " rounded-lg")
                    }
                >
                    <div className="w-full flex flex-row md:pl-2 md:pr-4 my-4 md:my-0">
                        {!isFromTimesheet && (
                            <div className="md:hidden font-bold text-xs w-[100px]">
                                {t("membre")}
                            </div>
                        )}

                        {isFromTimesheet && (
                            <div className="my-auto mr-2">
                                <Checkbox
                                    onChange={(e) => handleEmailAdd()}
                                    value={emailsToShare.some(
                                        (email) => email === member.user.email,
                                    )}
                                    removeMarging={true}
                                />
                            </div>
                        )}
                        {member.user ? (
                            <div className="w-full flex-1">
                                <div
                                    className="text-xs w-full cursor-pointer"
                                    onClick={(e) =>
                                        !isOwnerMember(member) &&
                                        !isFromTimesheet &&
                                        showMemberOptions(!isMemberOptions)
                                    }
                                >
                                    <div className="flex flex-row w-full">
                                        <div className="w-full text-left my-auto flex flex-row">
                                            <UserCircle user={member.user} />
                                            <div className="text-xs ml-2 text-left">
                                                <div className="font-bold">
                                                    {member.user.firstname +
                                                        " " +
                                                        member.user.lastname}
                                                </div>
                                                <div>{member.user.email}</div>
                                            </div>
                                        </div>
                                        {userRoles.MODERATOR &&
                                            !isFromTimesheet && (
                                                <div className="my-auto">
                                                    <i
                                                        className="material-icons notranslate align-middle"
                                                        style={{
                                                            fontSize: "1.3rem",
                                                        }}
                                                    >
                                                        {isMemberOptions
                                                            ? "expand_more"
                                                            : "chevron_right"}
                                                    </i>
                                                </div>
                                            )}
                                    </div>
                                </div>
                                {isMemberOptions &&
                                    !isFromTimesheet &&
                                    userRoles.MODERATOR && (
                                        <ul className="w-full bg-slate-50 border-t-2 border-slate-300 rounded-b-lg p-1 text-sm mt-1">
                                            <li>
                                                <button
                                                    type="button"
                                                    className="w-full p-1 text-left"
                                                    onClick={(e) => {
                                                        setConfirmationModal({
                                                            title: t(
                                                                "voulezVousVraimentRetirerCeMembreDuDossier?",
                                                            ),
                                                            callBackFunc:
                                                                removeMember,
                                                            closeReset:
                                                                setConfirmationModal,
                                                        });
                                                    }}
                                                >
                                                    {t("retirerDuDossier")}
                                                </button>
                                            </li>
                                        </ul>
                                    )}
                            </div>
                        ) : (
                            <div className="w-full">
                                <div className="flex flex-row">
                                    <div className="w-full">
                                        <div className="text-xs font-bold">
                                            {member.email}
                                        </div>
                                        <small className="text-xs">
                                            {t("invitationEnvoyeeLe") +
                                                " " +
                                                fromDateToStringDate(
                                                    member.sendedDate,
                                                )}
                                        </small>
                                    </div>

                                    {userRoles.MODERATOR && (
                                        <div className="ml-3 my-auto flex flex-row">
                                            <ClipboardCopy
                                                text={
                                                    process.env
                                                        .REACT_APP_BASE_URL +
                                                    "join-project/" +
                                                    project._id +
                                                    "/" +
                                                    member.token
                                                }
                                                isVerySmall={true}
                                            />
                                            <button
                                                type="button"
                                                onClick={(e) =>
                                                    handleDeleteInvitation()
                                                }
                                                title={t("supprimer")}
                                            >
                                                <i
                                                    className="material-icons notranslate align-middle ml-2 text-red-500"
                                                    style={{
                                                        fontSize: "1.2rem",
                                                    }}
                                                >
                                                    delete
                                                </i>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {!isFromTimesheet && (
                        <Fragment>
                            <div className="md:w-[300px] my-2 md:my-auto md:pl-2 md:pr-8 flex flex-row">
                                <div className="md:hidden font-bold text-xs w-[100px] my-auto">
                                    {t("role")}
                                </div>

                                <div className="relative w-full">
                                    {!isEditRole && (
                                        <button
                                            type="button"
                                            className="text-xs w-full"
                                            onClick={(e) =>
                                                !isOwnerMember(member) &&
                                                userRoles.MODERATOR &&
                                                setEditRole(!isEditRole)
                                            }
                                        >
                                            <div className="flex flex-row w-full">
                                                <div className="w-full text-left my-auto">
                                                    {roleLabel}
                                                </div>
                                                {userRoles.MODERATOR && (
                                                    <div>
                                                        <i
                                                            className="material-icons notranslate align-middle"
                                                            style={{
                                                                fontSize:
                                                                    "1.3rem",
                                                            }}
                                                        >
                                                            chevron_right
                                                        </i>
                                                    </div>
                                                )}
                                            </div>
                                        </button>
                                    )}

                                    {isEditRole && (
                                        <div className="text-sm">
                                            <Select
                                                name="role"
                                                options={roles}
                                                value={role}
                                                onChange={onChangeRole}
                                                isRequired={true}
                                            />
                                            <div className="flex inline-flex text-primary">
                                                <div className="w-1/2 my-auto mx-1">
                                                    <button
                                                        type="button"
                                                        onClick={(e) =>
                                                            updateRole()
                                                        }
                                                        title={t("enregistrer")}
                                                        className="p-1"
                                                    >
                                                        <i
                                                            className="material-icons notranslate align-middle"
                                                            style={{
                                                                fontSize:
                                                                    "1.5rem",
                                                            }}
                                                        >
                                                            check
                                                        </i>
                                                    </button>
                                                </div>
                                                <div className="w-1/2 my-auto mx-1">
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            setEditRole(false);
                                                            setRole(
                                                                member.role._id,
                                                            );
                                                        }}
                                                        title={t("annuler")}
                                                        className="p-1"
                                                    >
                                                        <i
                                                            className="material-icons notranslate align-middle"
                                                            style={{
                                                                fontSize:
                                                                    "1.5rem",
                                                            }}
                                                        >
                                                            close
                                                        </i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {permissionsTools.visas.canView && (
                                <div className="md:w-[300px] md:pr-2 my-2 md:my-auto flex flex-row">
                                    <div className="md:hidden font-bold text-xs w-[100px] my-auto">
                                        {t("visa")}
                                    </div>
                                    <div className="relative w-full">
                                        {!isEditVisa && (
                                            <button
                                                type="button"
                                                className="text-xs w-full"
                                                onClick={(e) =>
                                                    userRoles.MODERATOR &&
                                                    setEditVisa(!isEditVisa)
                                                }
                                            >
                                                <div className="flex flex-row w-full">
                                                    <div className="w-full text-left my-auto">
                                                        {visaLabel !== ""
                                                            ? visaLabel
                                                            : t("aucun")}
                                                    </div>
                                                    {userRoles.MODERATOR && (
                                                        <div>
                                                            <i
                                                                className="material-icons notranslate align-middle"
                                                                style={{
                                                                    fontSize:
                                                                        "1.3rem",
                                                                }}
                                                            >
                                                                chevron_right
                                                            </i>
                                                        </div>
                                                    )}
                                                </div>
                                            </button>
                                        )}

                                        {isEditVisa && (
                                            <div className="text-sm">
                                                <Select
                                                    name="visa"
                                                    options={visas}
                                                    value={visa}
                                                    onChange={onChangeVisa}
                                                />
                                                <div className="flex inline-flex text-primary">
                                                    <div className="w-1/2 my-auto mx-1">
                                                        <button
                                                            type="button"
                                                            onClick={(e) =>
                                                                updateVisa()
                                                            }
                                                            title={t(
                                                                "enregistrer",
                                                            )}
                                                            className="p-1"
                                                        >
                                                            <i
                                                                className="material-icons notranslate align-middle"
                                                                style={{
                                                                    fontSize:
                                                                        "1.5rem",
                                                                }}
                                                            >
                                                                check
                                                            </i>
                                                        </button>
                                                    </div>
                                                    <div className="w-1/2 my-auto mx-1">
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                setEditVisa(
                                                                    false,
                                                                );
                                                                setVisa(
                                                                    member
                                                                        .visas[0]
                                                                        ? member
                                                                              .visas[0]
                                                                              ._id
                                                                        : "",
                                                                );
                                                            }}
                                                            title={t("annuler")}
                                                            className="p-1"
                                                        >
                                                            <i
                                                                className="material-icons notranslate align-middle"
                                                                style={{
                                                                    fontSize:
                                                                        "1.5rem",
                                                                }}
                                                            >
                                                                close
                                                            </i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="md:w-[300px] md:px-2 my-2 md:my-auto flex flex-row">
                                <div className="md:hidden font-bold text-xs w-[100px] my-auto">
                                    {t("derniereConnexion")}
                                </div>
                                <div className="relative w-full">
                                    <div className="w-full text-left my-auto text-xs">
                                        {member.user
                                            ? fromDateToStringDate(
                                                  member.user.lastLoginDate,
                                              )
                                            : t("aucune")}
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </div>

                {confirmationModal && (
                    <ConfirmationModal
                        title={confirmationModal.title}
                        callBackFunc={confirmationModal.callBackFunc}
                        closeReset={confirmationModal.closeReset}
                    />
                )}
            </div>
        )
    );
}

export default MemberItem;
