import PropTypes from "prop-types";

import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { connect } from "react-redux";

import { getCurrentProfile } from "actions/profile";
import Constants from "utils/constants";

import Button from "components/input/Button";
import InformationModal from "components/modal/InformationModal";
import UserCircle from "components/user/UserCircle";
import Login from "components/user/login/Login";

import UserMenuContent from "./UserMenuContent";

const UserMenu = ({ profile: { profile, loading }, getCurrentProfile }) => {
    const { t } = useTranslation();

    const [informationModal, setInformationModal] = useState(null);
    const [isUserMenuVisible, setUserMenuVisible] = useState(false);

    const smMedia = useMediaPredicate("(min-width: " + Constants.smSize + ")");

    useEffect(() => {
        if (!profile) getCurrentProfile();
    }, []);

    useEffect(() => {
        if (!smMedia && isUserMenuVisible) setUserMenuVisible(false);
    }, [smMedia]);

    useEffect(() => {
        handleUserMenu();
    }, [profile]);

    // Gestion de l'ouverture / fermeture du menu user
    const handleUserMenu = () => {
        const concernedElementUser = document.querySelector("#user-menu");
        const concernedElementBtnUser =
            document.querySelector("#user-menu-btn");

        document.addEventListener("click", (event) => {
            setUserMenuVisible(true);
            if (
                concernedElementBtnUser &&
                !concernedElementBtnUser.contains(event.target)
            ) {
                if (!concernedElementUser.contains(event.target))
                    setUserMenuVisible(false);
            }
        });
    };

    return profile && !loading ? (
        <Fragment>
            <div className="text-right items-center order-2 ml-4 my-auto flex">
                <button
                    type="button"
                    className="rounded-full hover:ring-2 hover:ring-gray-500"
                    id="user-menu-btn"
                    onClick={(e) => setUserMenuVisible(!isUserMenuVisible)}
                >
                    <UserCircle user={profile.user} />
                </button>
            </div>
            <UserMenuContent
                isVisible={isUserMenuVisible}
                setVisible={setUserMenuVisible}
                user={profile.user}
                isMobileMenu={false}
            />
        </Fragment>
    ) : (
        !loading && (
            <Fragment>
                <Button
                    text={t("connexion")}
                    onClick={(e) =>
                        setInformationModal({
                            title: t("connexion"),
                            content: <Login />,
                            closeReset: setInformationModal,
                            isNoPadding: true,
                            customWidth: "max-w-[750px] lg:max-w-[1050px]",
                        })
                    }
                />

                {informationModal && (
                    <InformationModal
                        title={informationModal.title}
                        content={informationModal.content}
                        closeReset={informationModal.closeReset}
                        isNoPadding={informationModal.isNoPadding}
                        customWidth={informationModal.customWidth}
                    />
                )}
            </Fragment>
        )
    );
};

UserMenu.propTypes = {
    getCurrentProfile: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    profile: state.profile,
});

export default connect(mapStateToProps, { getCurrentProfile })(UserMenu);
