import axios from "axios";

import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import { getFileExtension } from "./file";

export async function getImageByCode(code) {
    try {
        const image = await axios.get(apiURL.getImageByCode + code);

        // Retourne le fichier associé à l'image si on l'a trouvée

        if (image.data) return getAppImage(image.data.imageFile);
        else return null;
    } catch (err) {
        return null;
    }
}

// Conversion d'une image uploadée en base64

export async function getBase64(file) {
    return new Promise((resolve) => {
        let baseURL = "";

        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
}

// Vérifie si une image accessible via un URL existe ou non

export async function isImageExists(imageUrl) {
    let img = new Image();
    img.src = imageUrl;
    return img.height !== 0;
}

// Récupère l'image depuis S3

export function getAppImage(image) {
    if (image)
        return Constants.imageS3Path[process.env.REACT_APP_NODE_ENV] + image;
    else return null;
}

export const dataUrlToFile = async (blob, fileName, mimeType) => {
    return new File([blob], fileName, { type: mimeType });
};

export function downloadBlob(blob, filename) {
    const objectUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => URL.revokeObjectURL(objectUrl), 5000);
}

export function downloadQRCode(id, name, extension) {
    let format;
    switch (extension) {
        case "JPEG":
            format = "jpeg";
            break;
        case "PNG":
            format = "png";
            break;
        case "SVG":
            format = "svg";
            break;
        default:
            break;
    }
    const canvas = document.querySelector(`#${id} canvas`);

    const link = document.createElement("a");
    if (format === "svg") {
        const svgHeader = `
        <svg xmlns="http://www.w3.org/2000/svg" width="${canvas.width}" height="${canvas.height}">
          <rect width="100%" height="100%" fill="white"></rect>`;
        const svgFooter = `</svg>`;
        const dataURL = canvas.toDataURL();
        const svgImage = `<image href="${dataURL}" width="${canvas.width}" height="${canvas.height}"></image>`;
        const svgContent = svgHeader + svgImage + svgFooter;
        const blob = new Blob([svgContent], {
            type: "image/svg+xml;charset=utf-8",
        });
        link.href = URL.createObjectURL(blob);
        link.download = name + ".svg";
    } else {
        link.href = canvas.toDataURL(`image/${format}`);
        link.download = name + `.${format}`;
    }
    link.click();
}

export function downloadBase64(base64Image, filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = base64Image;
    downloadLink.download = filename;
    downloadLink.click();
}

// Convertit une image Base64 en un fichier
export function fromBase64ToFile(base64, fileName) {
    var arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = window.atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mime });
}

export function getFileExtensionIcon(file, format = "svg") {
    // Si dossier
    if (file.compiledFiles && file.compiledFiles.length > 0)
        return "/img/extension-merge." + format;

    let extension = getFileExtension(file.file || file.name).toLowerCase();

    switch (extension) {
        case "ifc":
            return "/img/extension-ifc." + format;
        case "ifczip":
            return "/img/extension-ifczip." + format;
        case "pdf":
            return "/img/extension-pdf." + format;
        case "doc":
            return "/img/extension-doc." + format;
        case "docx":
            return "/img/extension-docx." + format;
        case "csv":
            return "/img/extension-csv." + format;
        case "xls":
            return "/img/extension-xls." + format;
        case "xlsx":
            return "/img/extension-xlsx." + format;
        case "mpp":
            return "/img/extension-mpp." + format;
        case "ppt":
            return "/img/extension-ppt." + format;
        case "pptx":
            return "/img/extension-pptx." + format;
        case "dwg":
            return "/img/extension-dwg." + format;
        case "rvt":
            return "/img/extension-rvt." + format;
        case "rfa":
            return "/img/extension-rfa." + format;
        case "nwc":
            return "/img/extension-nwc." + format;
        case "nwd":
            return "/img/extension-nwd." + format;
        case "jpg":
            return "/img/extension-jpg." + format;
        case "jpeg":
            return "/img/extension-jpeg." + format;
        case "png":
            return "/img/extension-png." + format;
        case "webp":
            return "/img/extension-webp." + format;
        case "svg":
            return "/img/extension-svg." + format;
        case "gif":
            return "/img/extension-gif." + format;
        case "vcf":
            return "/img/extension-vcf." + format;
        case "vsd":
            return "/img/extension-vsd." + format;
        case "vsdx":
            return "/img/extension-vsdx." + format;
        case "crbx":
            return "/img/extension-crbx." + format;
        case "pcd":
            return "/img/extension-pcd." + format;
        case "xyz":
            return "/img/extension-xyz." + format;
        case "las":
            return "/img/extension-las." + format;
        case "laz":
            return "/img/extension-laz." + format;
        case "obj":
            return "/img/extension-obj." + format;
        case "mp4":
            return "/img/extension-mp4." + format;
        case "mov":
            return "/img/extension-mov." + format;
        case "zip":
            return "/img/extension-zip." + format;
        case "01s":
            return "/img/extension-01S." + format;
        case "eps":
            return "/img/extension-eps." + format;
        case "rcp":
            return "/img/extension-rcp." + format;
        case "pln":
            return "/img/extension-pln." + format;
        case "psd":
            return "/img/extension-psd." + format;
        case "pla":
            return "/img/extension-pla." + format;
        case "ai":
            return "/img/extension-ai." + format;
        case "tif":
            return "/img/extension-tif." + format;
        case "e57":
            return "/img/extension-e57." + format;
        case "indd":
            return "/img/extension-indd." + format;
        case "tiff":
            return "/img/extension-tiff." + format;
        case "lgs":
            return "/img/extension-lgs." + format;
        case "msg":
            return "/img/extension-msg." + format;
        case "url":
            return "/img/extension-url." + format;
        case "3dm":
            return "/img/extension-3dm." + format;
        case "3drhino":
            return "/img/extension-3drhino." + format;
        default:
            return "/img/extension-unknown." + format;
    }
}

export async function getPreviewPresignedFile(fileId) {
    try {
        const resPresigned = await axios.post(apiURL.getFilePresignedUrl, {
            fileId,
            isPreviewImage: true,
        });

        if (resPresigned && resPresigned.data) return resPresigned.data;
    } catch (e) {}

    return "";
}

export const convertPresignedUrlToBase64 = async (presignedUrl) => {
    try {
        const response = await fetch(presignedUrl);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result); // Base64 string
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error("Error converting file to Base64:", error);
        return null;
    }
};
