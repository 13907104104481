import moment from "moment";
import { useAppStore } from "providers/ProviderApp";

import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";

import { deleteTemplateForm, duplicateTemplateForm, formModalKey, newTemplateForm } from "helpers/forms";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { FormAnswer } from "./FormAnswer";
import { useFormsStore } from "./FormView";
import ModalNewForm from "./ModalNewForm";

import { Button, Card, Col, Dropdown, Flex, Row, Table, Tag, theme } from "antd";

export default function FormTemplateList({ isAdmin }) {
    const { token } = theme.useToken();
    const { listTemplates, getTemplatesForms } = useFormsStore();
    const { viewMode } = useAppStore();

    useEffect(() => {
        getTemplatesForms();
    }, []);

    if (!listTemplates) return <></>;
    // INCASE Formulaires Grid Mode
    // if (viewMode === "list")
    return (
        <>
            {isAdmin && <AddForm />}
            <TableForms getTemplatesForms={getTemplatesForms} listTemplates={listTemplates} isAdmin={isAdmin} />
        </>
    );
    if (viewMode === "grid")
        return (
            <>
                {isAdmin && <AddForm />}
                <Flex vertical gap={token.paddingLG}>
                    <Row gutter={[token.paddingLG, token.paddingLG]}>
                        {listTemplates.map((form) => (
                            <Col xxl={6} xl={8} lg={12} md={12} xs={24} key={form._id}>
                                <FormCard form={form} />
                            </Col>
                        ))}
                    </Row>
                </Flex>
            </>
        );
}

const AddForm = () => {
    const { token } = theme.useToken();
    const { addModalData } = useContext(ModalContext);
    const navigate = useNavigate();

    return (
        <Button
            style={{
                marginBottom: token.paddingSM,
                maxWidth: "100%",
                whiteSpace: "pre-wrap",
                minHeight: "max-content",
            }}
            type="primary"
            icon={<MaterialIcons name="add" />}
            onClick={() => {
                addModalData({
                    key: "newForm",
                    title: "Nouveau formulaire",
                    content: <ModalNewForm />,
                    handleConfirm: async (values) => {
                        const { _id } = await newTemplateForm(values);
                        navigate("/forms/templates/" + _id);
                    },
                });
            }}>
            Ajouter un modèle de formulaire
        </Button>
    );
};

const TableForms = ({ getTemplatesForms, listTemplates, isAdmin }) => {
    const { token } = theme.useToken();
    const navigate = useNavigate();

    // const [selectedRow, setSelectedRow] = useState(null);
    // const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
    // const [visible, setVisible] = useState(false);

    // const handleContextMenu = (event, record) => {
    //     event.preventDefault();
    //     setSelectedRow(record);
    //     setDropdownPosition({ x: event.clientX, y: event.clientY });
    //     setVisible(true);
    // };

    return (
        <>
            <Table
                size="small"
                scroll={{
                    x: 1500,
                    y: "60dvh",
                }}
                pagination={false}
                dataSource={listTemplates}
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    style: {
                        cursor: "pointer",
                    },
                    onClick: (e) => {
                        navigate("/forms/templates/" + record._id);
                    },
                    // onContextMenu: (event) => handleContextMenu(event, record),
                })}
                columns={[
                    {
                        title: "Titre",
                        dataIndex: "title",
                        render: (text) => (
                            <Flex align="center" gap={token.paddingSM}>
                                <MaterialIcons name="docs" />
                                <Text>{text}</Text>
                            </Flex>
                        ),
                        width: 400,
                    },
                    {
                        title: "Description",
                        dataIndex: "description",
                        width: 500,
                        render: (text) => (
                            <Text
                                paragraph
                                size="sm"
                                ellipsis={{
                                    rows: 2,
                                    expandable: true,
                                    symbol: "Voir plus",
                                }}
                                style={{
                                    whiteSpace: "pre-wrap",
                                    margin: 0,
                                }}>
                                {text}
                            </Text>
                        ),
                    },
                    {
                        title: "Auteur",
                        dataIndex: "author",
                        width: 200,
                        render: (author) => author?.firstname + " " + author?.lastname,
                        ellipsis: true,
                    },
                    {
                        title: "Dernière modification",
                        dataIndex: "updatedDate",
                        width: 200,
                        render: (updatedDate) => moment(updatedDate).format("DD/MM/YYYY - HH:mm"),
                    },
                    // {
                    //     title: "Date de creation",
                    //     dataIndex: "createdDate",
                    //     render: (createdDate) =>
                    //         moment(createdDate).format("DD/MM/YYYY - HH:mm"),
                    // },
                    {
                        title: "Statut",
                        dataIndex: "active",
                        width: 100,
                        render: (active, record) => (
                            <Flex align="center" gap={token.paddingSM}>
                                <Tag color={active ? "success" : "warning"}>{active ? "Actif" : "Inactif"}</Tag>
                            </Flex>
                        ),
                    },
                    {
                        title: "",
                        dataIndex: "",
                        render: (_, record) => (
                            <Flex justify="end">
                                <TemplateOptions getTemplatesForms={getTemplatesForms} record={record}>
                                    <Button
                                        shape="circle"
                                        type="text"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}>
                                        <MaterialIcons name="more_vert" />
                                    </Button>
                                </TemplateOptions>
                            </Flex>
                        ),
                    },
                ].filter(Boolean)}
            />
        </>
    );
};

const FormCard = ({ form, navigateToForm }) => {
    const { token } = theme.useToken();

    return (
        <Card
            onClick={(e) => {
                navigateToForm(e, form._id);
            }}
            size="small"
            hoverable
            bordered
            style={{
                position: "relative",
                cursor: "pointer",
            }}
            cover={
                <Flex
                    style={{
                        height: 200,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    <MaterialIcons name="docs" size={124} />
                </Flex>
            }>
            <Flex align="start" gap={token.paddingSM}>
                <Flex vertical style={{ flex: 1, display: "grid" }}>
                    <Text bold ellipsis>
                        {form.title}
                    </Text>
                    <Text
                        paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: "Voir plus",
                        }}
                        style={{
                            whiteSpace: "pre-wrap",
                            margin: 0,
                            fontSize: 12,
                        }}
                        type="secondary">
                        {form.description}
                    </Text>
                    {/* <Text
                        size="small"
                        type="secondary"
                     
                    >
                        {moment(form.updatedDate).format("DD/MM/YYYY - HH:mm")}
                    </Text> */}
                </Flex>

                <Button shape="circle" type="text" style={{ marginLeft: "auto" }}>
                    <MaterialIcons name="more_vert" />
                </Button>
            </Flex>
        </Card>
    );
};

const TemplateOptions = ({ children, record, getTemplatesForms, templateProps }) => {
    const { addModalData } = useContext(ModalContext);
    const { wrapperSyncElement } = useContext(PopupContext);
    const navigate = useNavigate();

    return (
        <Dropdown
            {...templateProps}
            menu={{
                items: [
                    {
                        label: "Editer",
                        icon: <MaterialIcons name="edit" />,
                        onClick: () => {
                            navigate("/forms/templates/" + record._id);
                        },
                    },
                    {
                        label: "Dupliquer",
                        icon: <MaterialIcons name="file_copy" />,
                        onClick: async (e) => {
                            e.domEvent.stopPropagation();
                            e.domEvent.preventDefault();
                            await wrapperSyncElement(async () => {
                                await duplicateTemplateForm({
                                    id: record._id,
                                });
                                await getTemplatesForms();
                            });
                        },
                    },
                    {
                        label: "Prévisualisation",
                        icon: <MaterialIcons name="visibility" />,
                        onClick: (e) => {
                            e.domEvent.stopPropagation();
                            e.domEvent.preventDefault();
                            addModalData({
                                key: formModalKey,
                                width: 800,
                                title: (
                                    <Flex vertical>
                                        <Text type="secondary">Prévisualisation du formulaire</Text>
                                        <Text size="large" bold>
                                            {record.title}
                                        </Text>
                                    </Flex>
                                ),
                                content: <FormAnswer templateData={record} />,
                                footer: <></>,
                            });
                        },
                    },
                    {
                        label: "Supprimer",
                        icon: <MaterialIcons name="delete" />,
                        danger: true,
                        onClick: (e) => {
                            e.domEvent.stopPropagation();
                            e.domEvent.preventDefault();
                            addModalData({
                                key: "delete-form",
                                title: "Supprimer le formulaire",
                                content: "Êtes-vous sûr de vouloir supprimer ce formulaire ?",
                                confirmText: "Supprimer",
                                handleConfirm: async () => {
                                    await wrapperSyncElement(async () => {
                                        await deleteTemplateForm({
                                            id: record._id,
                                        });
                                        await getTemplatesForms();
                                    });
                                },
                            });
                        },
                    },
                ],
            }}>
            {children}
        </Dropdown>
    );
};
