import i18n from "i18next";

import { useTranslation } from "react-i18next";

import { isConstraintWithDescription } from "helpers/automation";
import { getFileExtensionIcon } from "helpers/image";
import Constants from "utils/constants";

import Popover from "components/utils/Popover";
import Spinner from "components/utils/Spinner";

import CompleteNomenclature from "./CompleteNomenclature";

const DriveNomenclatureMobile = ({
    nomenclatureData,
    answers,
    setAnswers,
    projectToUpload,
    oldFile,
    filesToImport,
    importStates,
    processedFiles,
    currentProcessedFile,
    errorMessage,
    showErrorMessage,
    fieldsError,
    removeLine,
}) => {
    const { t } = useTranslation();

    return (
        <ul className="text-black text-sm">
            {filesToImport.map((file, fileIndex) => (
                <li key={fileIndex} className="border-b-2 pb-4 mb-4">
                    <div className="flex flex-row">
                        <div className="w-1/2">
                            <div className="font-bold mb-2">
                                {t("nomDuFichier")}
                            </div>
                            <div className="relative">
                                <button
                                    className="absolute flex top-[-10px] left-[-8px] p-1"
                                    type="button"
                                    onClick={(e) => removeLine(fileIndex)}
                                >
                                    <i
                                        className="material-icons notranslate m-auto bg-slate-200 rounded-full p-0.5"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        close
                                    </i>
                                </button>
                                <div className="flex flex-row">
                                    <div className="w-[40px]">
                                        <img
                                            src={getFileExtensionIcon(file)}
                                            loading="lazy"
                                            alt={file.name}
                                            className="w-full"
                                        ></img>
                                    </div>
                                    <div className="my-auto ml-1 truncate w-[calc(100%-40px)]">
                                        {file.name
                                            .substr(
                                                0,
                                                file.name.lastIndexOf("."),
                                            )
                                            .substring(
                                                0,
                                                Constants.maxLengthFileTitle,
                                            )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/2">
                            <div className="font-bold mb-3">{t("import")}</div>
                            <div className="inline-block">
                                {currentProcessedFile === fileIndex ? (
                                    <Spinner
                                        isSmall={true}
                                        widthClass=" w-8"
                                        heightClass=" w-8"
                                    />
                                ) : (
                                    <div
                                        className="relative"
                                        onMouseEnter={(e) => {
                                            showErrorMessage(fileIndex);
                                        }}
                                        onMouseLeave={(e) => {
                                            showErrorMessage(null);
                                        }}
                                        onClick={(e) => {
                                            showErrorMessage((currentIndex) =>
                                                currentIndex === fileIndex
                                                    ? null
                                                    : fileIndex,
                                            );
                                        }}
                                    >
                                        <div
                                            className={
                                                "border-2 text-sm rounded-md p-1 hover:cursor-pointer" +
                                                (importStates[fileIndex] === ""
                                                    ? " text-green-500 border-green-500"
                                                    : " border-red-500 text-red-500")
                                            }
                                        >
                                            {t(
                                                processedFiles[fileIndex]
                                                    ? "importe"
                                                    : processedFiles[
                                                            fileIndex
                                                        ] === false
                                                      ? "erreur"
                                                      : importStates[
                                                              fileIndex
                                                          ] === ""
                                                        ? "valide"
                                                        : "erreur",
                                            )}
                                        </div>

                                        {importStates[fileIndex] !== "" &&
                                            errorMessage === fileIndex && (
                                                <Popover
                                                    text={
                                                        importStates[fileIndex]
                                                    }
                                                    width={250}
                                                />
                                            )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {nomenclatureData.actions.map((action, i) => (
                        <div key={i} className="inline-block w-1/2 p-2 mt-4">
                            <div className="mb-2 font-bold">
                                {action.description &&
                                isConstraintWithDescription(
                                    action.constraint.code,
                                )
                                    ? action.description
                                    : action.constraint["title" + i18n.locale]}
                            </div>
                            <CompleteNomenclature
                                action={action}
                                indexField={i + "_" + fileIndex}
                                projectToUpload={projectToUpload}
                                answers={answers}
                                setAnswers={setAnswers}
                                oldFile={oldFile}
                                fileName={file.name
                                    .substr(0, file.name.lastIndexOf("."))
                                    .substring(0, Constants.maxLengthFileTitle)}
                            />
                        </div>
                    ))}
                </li>
            ))}
        </ul>
    );
};

export default DriveNomenclatureMobile;
