import axios from "axios";

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import apiURL from "utils/apiURL";

import Button from "components/input/Button";
import Spinner from "components/utils/Spinner";

import { Checkbox, theme } from "antd";

const RemoveVisa = ({ files, onClose, refreshAction, visas }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const [loader, setLoader] = useState(false);

    const [selectedVisas, setSelectedVisas] = useState([]);
    const [isAllSelected, setAllSelected] = useState(false);

    const handleSelection = (selectedVisa) => {
        if (selectedVisas.includes(selectedVisa))
            setSelectedVisas(
                selectedVisas.filter((visa) => visa !== selectedVisa),
            );
        else {
            let updatedVisas = selectedVisas;
            selectedVisas.push(selectedVisa);
            setSelectedVisas([...updatedVisas]);
        }
    };

    const onConfirm = async () => {
        setLoader(true);

        try {
            const resVisas = await axios.post(apiURL.cancelVisasForFiles, {
                files,
                visas: selectedVisas,
            });

            if (resVisas?.data) refreshAction();
            else toastAlert("error", t("uneErreurEstSurvenue"));
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        onClose();
        setLoader(false);
    };

    const selectAll = (e) => {
        let checked = e.target.checked;
        setAllSelected(checked);

        if (checked) setSelectedVisas(visas.map((visa) => visa._id));
        else setSelectedVisas([]);
    };

    return (
        <Fragment>
            {visas?.length > 0 ? (
                <Fragment>
                    <div className="font-bold text-black ">
                        {t("visasARetirer")} :
                    </div>
                    <Checkbox
                        onChange={selectAll}
                        value={isAllSelected}
                        style={{
                            marginTop: token.marginSM,
                            marginBottom: token.marginSM,
                        }}
                    >
                        {t("toutSelectionner")}
                    </Checkbox>

                    <div className="mt-1 mb-6">
                        {visas
                            ?.sort((a, b) =>
                                a.title.toLowerCase() > b.title.toLowerCase()
                                    ? 1
                                    : -1,
                            )
                            .map((el, i) => (
                                <div
                                    key={i}
                                    onClick={() => handleSelection(el._id)}
                                    className={
                                        "inline-block border-2 text-sm rounded-md font-bold p-1 mx-1 hover:cursor-pointer" +
                                        (selectedVisas.includes(el._id)
                                            ? " text-primary border-primary"
                                            : " border-slate-500 text-slate-500")
                                    }
                                >
                                    {el.title}
                                </div>
                            ))}
                    </div>

                    <div className="flex flex-row justify-end gap-2">
                        <div className="inline-block">
                            <Button
                                isReversed={true}
                                text={t("annuler")}
                                onClick={onClose}
                            />
                        </div>
                        <div className="inline-block">
                            <Button
                                text={t("confirmer")}
                                onClick={onConfirm}
                                isDisabled={selectedVisas.length === 0}
                            />
                        </div>
                    </div>

                    {loader && <Spinner />}
                </Fragment>
            ) : (
                visas && (
                    <div className="text-center">
                        {t("aucunVisaDisponible")}
                    </div>
                )
            )}
        </Fragment>
    );
};

export default RemoveVisa;
