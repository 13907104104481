import axios from "axios";

import { useContext, useEffect, useState } from "react";

import { getFileExtension } from "helpers/file";
import apiURL from "utils/apiURL";
import constants from "utils/constants";

import { ModalContext } from "layout/LayoutModal";
import { PopupContext } from "layout/LayoutPopup";

import { Form, Input } from "antd";

const ModalRename = ({ file, project, refreshAction }) => {
    const [title, setTitle] = useState(file?.title || project?.title);
    const { setHandleConfirm } = useContext(ModalContext);
    const { startDriveSyncMessage } = useContext(PopupContext);

    useEffect(() => {
        if (file) {
            setHandleConfirm("rename", async () => {
                try {
                    startDriveSyncMessage();
                    await axios.post(apiURL.updateProjectFile, {
                        id: file._id,
                        title: title.trim(),
                    });

                    refreshAction();
                    return true;
                } catch (err) {
                    return false;
                }
            });
        } else if (project) {
            setHandleConfirm("rename", async () => {
                try {
                    startDriveSyncMessage();
                    await axios.post(apiURL.updateProject, {
                        id: project._id,
                        title: title.trim(),
                    });
                    refreshAction();
                    return true;
                } catch (err) {
                    return false;
                }
            });
        }
    }, [title, project, file]);

    return (
        <Form.Item
            hasFeedback
            name={" "}
            validateTrigger="onChange"
            rules={[
                {
                    max: file
                        ? constants.maxLengthFileTitle
                        : constants.maxLengthProjectTitle,
                    required: true,
                    transform: (value) => value.trim(),
                },
            ]}
            initialValue={file?.title || project?.title}
        >
            <Input
                placeholder={file?.title || project?.title}
                addonAfter={file && "." + getFileExtension(file.file)}
                size="large"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
            />
        </Form.Item>
    );
};

export default ModalRename;
