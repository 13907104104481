import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getTemplateForm, saveTemplateForm } from "helpers/forms";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { PopupContext } from "layout/LayoutPopup";

import { useFormsStore } from "./FormView";
import ColumnFields from "./editor/ColumnFields";
import ColumnForm from "./editor/ColumnForm";
import GrabContext from "./editor/GrabContext";

import { Col, Divider, Flex, Form, Input, Row, Spin, theme } from "antd";

export default function FormTemplate({ formId }) {
    const { token } = theme.useToken();

    const { startDriveSyncMessage, successDriveSyncMessage } = useContext(PopupContext);
    const [sortableItems, setSortableItems] = useState();

    const { setFormTemplateRef, setLoading } = useFormsStore();

    const [templateData, setTemplateData] = useState();
    useEffect(() => {
        setLoading(true);
        getTemplateForm({ id: formId })
            .then((res) => {
                setTemplateData(res);
                setSortableItems(res.fields.map((e) => e._id));
            })
            .catch((err) => {});
    }, [formId]);

    const [previewsField, setPreviewsField] = useState(null);

    const [form] = Form.useForm();

    useEffect(() => {
        if (templateData && form) {
            setFormTemplateRef(form);
            form.setFieldsValue(templateData);
            setLoading(false);
        }
    }, [templateData, form]);

    if (!templateData) return <Spin />;
    return (
        <GrabContext
            setPreviewsField={setPreviewsField}
            sortableItems={sortableItems}
            setSortableItems={setSortableItems}
            form={form}>
            <Form
                form={form}
                className="from-nomargin"
                onFinish={(values) => {
                    startDriveSyncMessage();
                    saveTemplateForm({ values, id: templateData._id }).then((res) => {
                        successDriveSyncMessage();
                    });
                }}
                style={{ height: "100%" }}
                initialValues={templateData}
                layout="vertical">
                <Form.Item name="_id" hidden></Form.Item>
                <Form.Item name="status" hidden></Form.Item>
                <Form.Item name="title" hidden></Form.Item>
                <Form.Item name="active" hidden></Form.Item>

                <Row style={{ height: "100%" }} gutter={[token.paddingLG, token.paddingLG]}>
                    <Col
                        span={16}
                        style={{
                            height: "100%",
                            overflow: "auto",
                        }}>
                        <Flex style={{ height: "100%" }} vertical gap={token.padding}>
                            <FormDescription />
                            <FormIndication />

                            <ColumnForm form={form} previewsField={previewsField} sortableItems={sortableItems} />
                        </Flex>
                    </Col>
                    <Col
                        span={8}
                        style={{
                            height: "100%",
                            overflow: "auto",
                        }}>
                        <ColumnFields />
                    </Col>
                </Row>
            </Form>
        </GrabContext>
    );
}

const FormDescription = () => {
    const { t } = useTranslation();
    return (
        <Form.Item label={<Text bold>Description du formulaire :</Text>} name="description" style={{ marginBottom: 0 }}>
            <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 12 }}
                size="large"
                rows={3}
                style={{
                    fontSize: 14,
                }}
                placeholder={t("description")}
            />
        </Form.Item>
    );
};

const FormIndication = () => {
    return (
        <Divider plain>
            <Flex align="center" gap={6}>
                <MaterialIcons type="disabled" name="arrow_drop_down" />
                <Text type="secondary">Glisser déposer un élément dans cette zone</Text>
                <MaterialIcons type="disabled" name="arrow_drop_down" />
            </Flex>
        </Divider>
    );
};
