import moment from "moment";

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getDaysBetweenTwoDates } from "helpers/date";
import { absencesStatutOptions, absencesTypesOptions } from "helpers/timesheet";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";

import { Col, Flex, Form, Input, Row, Select, Tag, theme } from "antd";

const ModalAbsence = () => {
    const { t } = useTranslation();
    const { token } = theme.useToken();
    const form = Form.useFormInstance();
    const startDate = Form.useWatch("startDate", form);
    const endDate = Form.useWatch("endDate", form);
    const validationDetails = form.getFieldValue("validationDetails");
    const statut = absencesStatutOptions.find((e) => e.value === form.getFieldValue("statut"));

    // Prevent endDate to be before startDate
    const renderTextDaysDuration = useMemo(() => {
        const duration = getDaysBetweenTwoDates(endDate, moment(startDate).subtract(1, "day"));
        if (duration < 0) form.setFieldValue("endDate", moment(startDate).add(1, "days").format("YYYY-MM-DD"));
        return `${duration} ${duration > 1 ? t("jours") : t("jour")}`;
    }, [startDate, endDate, form]);

    return (
        <Row gutter={[16, 16]}>
            {validationDetails && (
                <Col span={24}>
                    <Tag
                        color={statut.color}
                        style={{
                            padding: token.paddingXS,
                        }}>
                        <Flex align="center" gap="small">
                            <MaterialIcons name={statut.value === "ACCEPTED" ? "check" : "close"} />
                            <Text color={statut.color} strong>
                                {statut.value === "ACCEPTED" ? t("accepte") : t("refuse")} {t("par")}{" "}
                                {validationDetails.validatedBy.firstname + " " + validationDetails.validatedBy.lastname}{" "}
                                - {moment(validationDetails.validatedAt).format("DD/MM/YYYY HH:mm")}
                            </Text>
                        </Flex>
                    </Tag>
                </Col>
            )}

            <Col lg={12} span={24}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name="startDate"
                    label={<Text bold>{t("debut")}</Text>}>
                    <Input
                        type="date"
                        onClick={(e) => {
                            e.target.showPicker();
                        }}
                    />
                </Form.Item>
            </Col>

            <Col lg={12} span={24}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name="endDate"
                    label={<Text bold>{t("fin")}</Text>}>
                    <Input
                        min={moment(startDate).add(1, "days").format("YYYY-MM-DD")}
                        type="date"
                        onClick={(e) => {
                            e.target.showPicker();
                        }}
                    />
                </Form.Item>
            </Col>

            <Col lg={12} span={24}>
                <Form.Item label={<Text bold>{t("duree")}</Text>}>
                    <Text>{renderTextDaysDuration}</Text>
                </Form.Item>
            </Col>

            <Col lg={12} span={24}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    name="type"
                    label={<Text bold>{t("type")}</Text>}>
                    <Select
                        options={absencesTypesOptions.map((e) => ({
                            value: e.value,
                            label: t(e.label),
                        }))}
                    />
                </Form.Item>
            </Col>

            <Col span={24}>
                <Form.Item name="comment" label={<Text bold>{t("commentaire")}</Text>}>
                    <Input.TextArea
                        autoSize={{
                            minRows: 6,
                            maxRows: 10,
                        }}
                        placeholder={t("laisserUnCommentaire")}
                    />
                </Form.Item>
            </Col>
        </Row>
    );
};

export default ModalAbsence;
