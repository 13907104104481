import axios from "axios";

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import ConnectionAPIClient, { isBIMCollabAvailable } from "helpers/bcf";
import { fromDateToDatePickerValue } from "helpers/date";
import { fieldValidation } from "helpers/fieldValidation";
import {
    addBCFMarker,
    generateBCFContent,
    saveSceneSettings,
} from "helpers/viewer";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import { CommentsContext } from "components/comment/CommentsContent";
import SubCommentList from "components/comment/SubCommentList";
import AutoCompleteTextField from "components/input/AutoCompleteTextField";
import Button from "components/input/Button";
import Checkbox from "components/input/Checkbox";
import DatePicker from "components/input/DatePicker";
import IconButton from "components/input/IconButton";
import Select from "components/input/Select";
import TextField from "components/input/TextField";
import Textarea from "components/input/Textarea";
import ImageEditor from "components/utils/ImageEditor";
import Spinner from "components/utils/Spinner";

const BCFForm = ({
    profile,
    files,
    comment,
    compiledId,
    set2DPlanVisible,
    image,
    onClose,
}) => {
    const { t } = useTranslation();

    const {
        comments,
        setComments,
        addMarkerToList,
        setActiveBCF,
        priorities,
        members,
        world,
        customerConfig,
        currentBIMCollabProject,
        deleteMarkerFromList,
    } = useContext(CommentsContext);

    const apiClient = new ConnectionAPIClient(
        customerConfig ? customerConfig.BIMCollab.url : "",
    );

    const [loader, setLoader] = useState(false);

    const initialFormData = {
        id: comment && comment._id,
        title: comment?.title || "",
        content: comment?.content || "",
        author: profile && profile.user,
        guestName: comment?.guestName || null,
        priority: comment?.priority?._id || "",
        assignedTo: comment?.assignedTo || null,
        BCFContent: comment?.BCFContent || null,
        status: comment?.status || "ACTIVE",
        dueDate: comment?.dueDate
            ? fromDateToDatePickerValue(comment.dueDate)
            : null,
        comment: "",
        projectFiles: files.map((file) => file._id),
        compiledId: compiledId,
        userEditor: profile && profile.user._id,
    };
    const [formData, setFormData] = useState(initialFormData);

    const initialFieldsError = {
        title: "",
        priority: "",
        content: "",
        comment: "",
    };
    const [fieldsError, setFieldsError] = useState(initialFieldsError);

    const [assignedToList, setAssignedToList] = useState(null);
    const [mentionsList, setMentionsList] = useState([]);

    const [previewImage, setPreviewImage] = useState(image);
    const [isEditImage, setEditImage] = useState(false);

    const onChange = (e, name) => {
        setFormData({
            ...formData,
            [name ? name : e.target.name]: e.target.value,
        });
    };

    const onCheck = (e) => {
        if (e.target.name === "active")
            setFormData({ ...formData, status: "ACTIVE" });
        else if (e.target.name === "resolved")
            setFormData({ ...formData, status: "RESOLVED" });
        else if (e.target.name === "closed")
            setFormData({ ...formData, status: "CLOSED" });
    };

    useEffect(() => {
        if (priorities) {
            const defaultPriority = priorities.find(
                (priority) => priority.isDefault,
            );

            if (defaultPriority && formData.priority === "")
                setFormData({ ...formData, priority: defaultPriority.val });
        }
    }, [priorities]);

    const onChangeAssigned = async (e) => {
        // On reset l'assignation car on vient de modifier la valeur du champ
        if (formData.assignedTo) onClickAssigned(null);

        if (e.target.value.length < 2) {
            setAssignedToList(null);
            return;
        }

        const terms = e.target.value.toLowerCase();

        const resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (resMembersByTerm.length > 0) {
            let updatedList = [];

            resMembersByTerm.forEach((user) => {
                updatedList.push({
                    id: user._id ? user._id : user.email,
                    text:
                        user.firstname +
                        " " +
                        user.lastname +
                        " (" +
                        user.email +
                        ")",
                });
            });

            setAssignedToList(updatedList);
        } else setAssignedToList([]);
    };

    const onClickAssigned = (value) => {
        setFormData({ ...formData, assignedTo: value });
    };

    const onChangeMentions = async (e) => {
        if (e.slice(1).length < 2) {
            setMentionsList([]);
            return;
        }

        const terms = e.slice(1).toLowerCase();

        let resMembersByTerm = [];

        resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (
            resMembersByTerm.filter((item) => item.email !== profile.user.email)
                .length > 0
        ) {
            let updatedList = [];

            resMembersByTerm
                .filter((item) => item.email !== profile.user.email)
                .forEach((user) => {
                    updatedList.push({
                        id: user.email,
                        display:
                            user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.email +
                            ")",
                    });
                });

            setMentionsList(updatedList);
        } else setMentionsList([]);
    };

    // Partie BCF
    const onSave = async () => {
        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            try {
                let commentDatas = formData;
                commentDatas.guestName = localStorage.getItem("guestName");

                commentDatas.BCFContent = await generateBCFContent(world);
                commentDatas.BCFContent.image = previewImage;

                // Génération des settings actifs dans la scène
                const resSettings = saveSceneSettings(world);

                commentDatas.settings = resSettings;

                if (commentDatas.assignedTo === "")
                    commentDatas.assignedTo = null;
                if (commentDatas.priority === "") commentDatas.priority = null;

                // EDIT
                if (comment) {
                    // Commentaire avec connexion
                    if (isBIMCollabAvailable(customerConfig)) {
                        // Gestion pour API BIMCollab
                        let apiCommentDatas = { ...formData };

                        const assignedToUser = members.find(
                            (member) => member.user._id === formData.assignedTo,
                        );

                        apiCommentDatas.assignedTo = assignedToUser
                            ? assignedToUser.user.email
                            : null;

                        if (formData.status === "ACTIVE")
                            apiCommentDatas.status = "Active";
                        else if (formData.status === "CLOSED")
                            apiCommentDatas.status = "Closed";
                        else if (formData.status === "RESOLVED")
                            apiCommentDatas.status = "Resolved";

                        const commentRes = await apiClient.updateTopic(
                            world,
                            currentBIMCollabProject,
                            formData.id,
                            apiCommentDatas,
                        );

                        if (commentRes) {
                            // On précise qu'on utilise l'API pour récupérer correctement le commentaire par la suite
                            formData.isBimCollab = true;
                            formData.bimCollabId = formData.id;
                            axios.post(apiURL.updateComment, formData);
                            commentDatas = commentRes;
                        }
                    } else {
                        const commentRes = await axios.post(
                            apiURL.updateComment,
                            formData,
                        );

                        if (commentRes && commentRes.data)
                            commentDatas = commentRes.data;
                    }

                    if (commentDatas) {
                        // Ajout d'un commentaire avec contenu du commentaire et image viewpoint
                        if (formData.comment !== "" || previewImage)
                            commentDatas = await onSaveComment(commentDatas);

                        toastAlert("success", t("commentaireModifie"));

                        const commentIndex = comments.findIndex(
                            (item) => item._id === comment._id,
                        );

                        if (commentIndex > -1) {
                            // On remplace l'élement dans le tableau des commentaires
                            const updatedComments = [...comments];
                            updatedComments[commentIndex] = commentDatas;
                            setComments([...updatedComments]);

                            if (!isBIMCollabAvailable(customerConfig)) {
                                // On supprime l'ancien marqueur car les donnés ne sont plus les mêmes
                                deleteMarkerFromList(formData.id);

                                addBCFMarker(
                                    world,
                                    commentDatas,
                                    addMarkerToList,
                                    setActiveBCF,
                                    set2DPlanVisible,
                                );
                            }
                        }
                    } else {
                        toastAlert(
                            "error",
                            t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                        );
                    }
                } else {
                    // CREATE
                    let commentRes;

                    if (isBIMCollabAvailable(customerConfig)) {
                        // Création du BCF dans BIMCollab
                        let apiCommentDatas = { ...commentDatas };

                        const assignedToUser = members.find(
                            (member) =>
                                member.user._id === commentDatas.assignedTo,
                        );

                        apiCommentDatas.assignedTo = assignedToUser
                            ? assignedToUser.user.email
                            : "";

                        commentRes = await apiClient.createTopic(
                            world,
                            currentBIMCollabProject,
                            apiCommentDatas,
                        );

                        if (commentRes) {
                            // On crée aussi le BCF en BDD afin de créer les notifs / activités et d'avoir une copie chez BIMONO
                            commentDatas.bimCollabId = commentRes[0]._id;
                            await axios.post(
                                apiURL.createComment,
                                commentDatas,
                            );

                            commentRes = commentRes[0];
                        }
                    } else {
                        // Fonctionnement classique en BDD
                        commentRes = await axios.post(
                            apiURL.createComment,
                            commentDatas,
                        );

                        commentRes = commentRes?.data;
                    }

                    if (commentRes) {
                        commentRes = await onSaveComment(commentRes);

                        toastAlert("success", t("commentaireAjoute"));
                        // On ajoute l'élement au début du tableau des commentaires
                        const updatedComments = [...comments];
                        updatedComments.unshift(commentRes);
                        setComments([...updatedComments]);

                        if (!isBIMCollabAvailable(customerConfig)) {
                            addBCFMarker(
                                world,
                                commentRes,
                                addMarkerToList,
                                setActiveBCF,
                                set2DPlanVisible,
                            );
                        }
                    } else {
                        toastAlert(
                            "error",
                            t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                        );
                    }
                }
            } catch (err) {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                );
            }

            clearForm();
            onClose();
        }

        setLoader(false);
    };

    // Partie commentaire ajouté au BCF
    const onSaveComment = async (parentBCF) => {
        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            try {
                let commentRes;

                if (isBIMCollabAvailable(customerConfig)) {
                    // Création du commentaire dans BIMCollab
                    commentRes = await apiClient.createTopicComment(
                        currentBIMCollabProject,
                        parentBCF._id,
                        {
                            comment: formData.comment,
                            image: previewImage,
                        },
                    );
                } else {
                    commentRes = await axios.put(apiURL.addSubComment, {
                        id: parentBCF._id,
                        content: formData.comment,
                        image: previewImage,
                        author: profile && profile.user,
                        guestEmail:
                            localStorage.getItem("guestEmail") &&
                            localStorage.getItem("guestEmail"),
                        guestName:
                            localStorage.getItem("guestName") &&
                            localStorage.getItem("guestName"),
                        subCommentId: null,
                        fileId: files[0]._id,
                    });

                    commentRes = commentRes.data;
                }

                if (commentRes) {
                    return commentRes;
                } else toastAlert("error", t("uneErreurEstSurvenue"));

                return null;
            } catch (err) {
                toastAlert("error", t("uneErreurEstSurvenue"));
            }
        }
    };

    const clearForm = () => {
        setFormData(initialFormData);
        setFieldsError(initialFieldsError);
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        validation = fieldValidation(formData.title, "required");
        updatedFieldsError.title = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.priority, "required");
        updatedFieldsError.priority = validation;
        if (validation !== "") nbErrors++;

        validation = fieldValidation(formData.content, "required");
        updatedFieldsError.content = validation;
        if (validation !== "") nbErrors++;

        if (!previewImage) {
            validation = fieldValidation(formData.comment, "required");
            updatedFieldsError.comment = validation;
            if (validation !== "") nbErrors++;
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const onUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                const base64String = reader.result;

                setPreviewImage(base64String);
            };

            reader.readAsDataURL(file);
        }
    };

    const getAssignedUserDisplayName = () => {
        if (isBIMCollabAvailable(customerConfig)) {
            const assignedMember = members.find(
                (member) => member.user.email === formData.assignedTo,
            );
            if (assignedMember) {
                return `${assignedMember.user.firstname} ${assignedMember.user.lastname} (${formData.assignedTo})`;
            }
        }
        if (formData.assignedTo?.email) {
            return `${formData.assignedTo.firstname} ${formData.assignedTo.lastname} (${formData.assignedTo.email})`;
        }
        return "";
    };

    return (
        <Fragment>
            <form className="mt-4 w-full p-3">
                <div className="md:flex md:flex-row">
                    <div className="md:w-1/3 md:pr-4">
                        <TextField
                            text={t("titre")}
                            maxLength={Constants.maxLengthCommentTitle}
                            name="title"
                            isRequired={true}
                            onChange={onChange}
                            value={formData.title}
                            errorMsg={fieldsError.title}
                        />
                    </div>
                    <div className="md:w-1/3 mt-4 md:mt-0">
                        {profile && (
                            <AutoCompleteTextField
                                text={t("assigneA")}
                                name="assignedTo"
                                defaultValue={getAssignedUserDisplayName()}
                                availableValues={assignedToList}
                                setAvailableValues={setAssignedToList}
                                onClickChoice={onClickAssigned}
                                onChange={onChangeAssigned}
                                maxLength={Constants.maxLengthEmail}
                            />
                        )}
                    </div>
                    <div className="md:w-1/3 mt-4 md:mt-0 md:pl-4">
                        <Select
                            text={t("priorite")}
                            name="priority"
                            options={priorities}
                            isRequired={true}
                            onChange={onChange}
                            value={formData.priority}
                            errorMsg={fieldsError.priority}
                        />
                    </div>
                </div>

                <div className="md:flex flex-row mt-8">
                    <div className="md:w-1/2 lg:w-[40%] md:pr-3 mt-4 md:mt-0">
                        {comment &&
                            profile &&
                            ((comment.author &&
                                comment.author._id === profile.user._id) ||
                                profile.projects.some((project) =>
                                    files.some(
                                        (file) =>
                                            project._id === file.project._id,
                                    ),
                                )) && (
                                <Fragment>
                                    <label className="block text-gray-700 text-sm font-bold mb-2">
                                        {t("statut")}
                                    </label>
                                    <div className="flex flex-row gap-2">
                                        <Checkbox
                                            text={t("ouvert")}
                                            name="active"
                                            type="radio"
                                            onChange={onCheck}
                                            value={
                                                !formData.status ||
                                                formData.status === "ACTIVE"
                                            }
                                        />
                                        <Checkbox
                                            text={t("resolu")}
                                            name="resolved"
                                            type="radio"
                                            onChange={onCheck}
                                            value={
                                                formData.status === "RESOLVED"
                                            }
                                        />
                                        <Checkbox
                                            text={t("ferme")}
                                            name="closed"
                                            type="radio"
                                            onChange={onCheck}
                                            value={formData.status === "CLOSED"}
                                        />
                                    </div>
                                </Fragment>
                            )}

                        <label
                            className="block text-gray-700 text-sm font-bold mb-2 whitespace-nowrap mt-2"
                            htmlFor="dueDate"
                        >
                            {t("dateDecheance")}
                        </label>
                        <DatePicker
                            name="dueDate"
                            onChange={onChange}
                            value={formData.dueDate || ""}
                        />
                    </div>

                    <div className="md:w-1/2 lg:w-[60%] md:pl-3 mt-4 md:mt-0">
                        <Textarea
                            text={t("description")}
                            maxLength={Constants.maxLengthCommentContent}
                            name="content"
                            onChange={onChange}
                            value={formData.content}
                            errorMsg={fieldsError?.content}
                            isResizable={false}
                            isRequired={true}
                        />
                    </div>
                </div>

                <hr className="my-6" />

                <div className="md:flex flex-row md:h-[280px] md:pb-12">
                    <div className="md:w-1/2 lg:w-[60%] md:pr-3">
                        <Textarea
                            text={t("commentaire")}
                            placeholder={t("ecrivezUnCommentaire")}
                            maxLength={Constants.maxLengthCommentContent}
                            name="comment"
                            onChange={onChange}
                            value={formData.comment}
                            errorMsg={fieldsError.comment}
                            isResizable={false}
                            mentionsList={profile && mentionsList}
                            onChangeMentions={profile && onChangeMentions}
                        />
                    </div>
                    <div className="md:w-1/2 lg:w-[40%] md:pl-3 mt-8 md:mt-0">
                        <div
                            className="mx-auto flex background-img rounded-xl w-full md:w-auto max-w-[350px] md:max-w-full h-[230px] bg-gray-100"
                            style={{
                                backgroundImage:
                                    previewImage && "url(" + previewImage + ")",
                            }}
                        >
                            {!previewImage && (
                                <i
                                    className="material-icons notranslate m-auto"
                                    style={{ fontSize: "5rem" }}
                                >
                                    no_photography
                                </i>
                            )}
                        </div>

                        <div className="w-full md:w-auto max-w-[350px] md:max-w-full mx-auto flex flex-row gap-2 justify-end mt-2">
                            {previewImage && (
                                <div>
                                    <IconButton
                                        icon="edit"
                                        text={t("modifier")}
                                        onClick={(e) => setEditImage(true)}
                                        isSmall={true}
                                        isNeutral={true}
                                    />
                                </div>
                            )}
                            <div>
                                <Fragment>
                                    <IconButton
                                        icon="add_photo_alternate"
                                        text={t("importer")}
                                        onClick={(e) =>
                                            document
                                                .getElementById(
                                                    "import-file-comment",
                                                )
                                                .click()
                                        }
                                        isSmall={true}
                                        isNeutral={true}
                                    />

                                    <div className="hidden">
                                        <Button
                                            id="import-file-comment"
                                            type="file"
                                            accept={Constants.imagesList}
                                            onChange={onUpload}
                                        />
                                    </div>
                                </Fragment>
                            </div>

                            {previewImage && (
                                <div>
                                    <IconButton
                                        icon="delete"
                                        text={t("supprimer")}
                                        onClick={(e) => setPreviewImage(null)}
                                        isSmall={true}
                                        isNeutral={true}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex flex-row mt-5 justify-end">
                    <div className="inline-block mr-1">
                        <Button
                            text={t(comment ? "enregistrer" : "ajouter")}
                            onClick={(e) => onSave()}
                        />
                    </div>
                    <div className="inline-block ml-1">
                        <Button
                            text={t("annuler")}
                            onClick={onClose}
                            isReversed={true}
                        />
                    </div>
                </div>

                {comment?.comments?.length > 0 && (
                    <div className="md:w-2/3 lg:w-[60%] mt-6">
                        <h4 className="font-bold text-black mx-2">
                            {t("activites")}
                        </h4>

                        <SubCommentList
                            profile={profile}
                            files={files}
                            parentComment={comment}
                            comments={comment.comments}
                            isFromForm={true}
                        />
                    </div>
                )}
            </form>

            {isEditImage && (
                <ImageEditor
                    image={previewImage}
                    onSave={(e) => setPreviewImage(e)}
                    onClose={setEditImage}
                />
            )}

            {loader && <Spinner />}
        </Fragment>
    );
};

export default BCFForm;
