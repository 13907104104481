import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { getProjectparentsList } from "helpers/project";
import { generateRandomToken } from "helpers/token";
import { checkUserPermission } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Button from "components/input/Button";
import ContactFormButton from "components/input/ContactFormButton";
import Select from "components/input/Select";
import SeparatedTextField from "components/input/SeparatedTextField";
import MemberItem from "components/user/MemberItem";
import Badge from "components/utils/Badge";
import ClipboardCopy from "components/utils/ClipboardCopy";
import InfoMessage from "components/utils/InfoMessage";
import Spinner from "components/utils/Spinner";

const ProjectMembers = ({
    project,
    members,
    user,
    refreshAction,
    isFromTimesheet = false,
    isFromFilter = false,
    defaultCheckedMembers = [],
    userRoles = {},
}) => {
    const { t } = useTranslation();

    const { permissionsTools } = useProfileStore();
    const [loader, setLoader] = useState(false);

    const [membersList, setMembersList] = useState([]);
    const [addedList, setAddedList] = useState([]);
    const [emailsToShare, setEmailsToShare] = useState(defaultCheckedMembers);

    const [sortedItems, setSortedItems] = useState([]);
    const [sortConfig, setSortConfig] = useState(null);
    const [colsFilters, setColsFilters] = useState(null);

    const [role, setRole] = useState("");
    const [roles, setRoles] = useState([]);

    const [visa, setVisa] = useState("");
    const [visas, setVisas] = useState([]);

    const [sendedUsers, setSendedUsers] = useState([]);

    const [searchTerms, setSearchTerms] = useState("");

    useEffect(() => {
        const getMembers = async () => {
            let updatedMembers = members;

            // Check si le user peut accéder à ce dossier (s'il n'est pas restreint sur celui-ci ou un parent)

            updatedMembers = updatedMembers.filter(
                (member) =>
                    !project.restrictedUsers?.some(
                        (restrictedUser) =>
                            restrictedUser.toString() ===
                            member.user._id.toString(),
                    ),
            );

            if (project.parentProject) {
                const parents = await getProjectparentsList(project._id);
                parents.forEach((parent) => {
                    updatedMembers = updatedMembers.filter(
                        (member) =>
                            !parent.restrictedUsers?.some(
                                (restrictedUser) =>
                                    restrictedUser.toString() ===
                                    member.user._id.toString(),
                            ),
                    );
                });
            }

            setMembersList(updatedMembers);
        };

        getMembers();
    }, [members]);

    useEffect(() => {
        if (isFromTimesheet) refreshAction(emailsToShare);
    }, [isFromTimesheet, emailsToShare]);

    useEffect(() => {
        const getRoles = async () => {
            const resRoles = await axios.get(apiURL.getAllRoles);

            if (resRoles && resRoles.data) {
                const rolesList = [];

                resRoles.data
                    .filter((role) => role.code !== "OWNER")
                    .sort((a, b) => (a.position > b.position ? -1 : 1))
                    .map((role) => {
                        return rolesList.push({
                            val: role._id,
                            label: role["title" + i18n.locale],
                        });
                    });

                setRoles(rolesList);
                // On défini le role courant avec le moins haut en terme de permissions
                if (rolesList.length > 0) setRole(rolesList[0].val);
            }
        };

        if (!isFromTimesheet && roles.length === 0) getRoles();

        const getVisas = async () => {
            const resVisas = await axios.get(
                apiURL.getFolderVisas + project._id,
            );

            if (resVisas && resVisas.data) {
                const visasList = [];

                resVisas.data
                    .sort((a, b) =>
                        a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
                    )
                    .map((visa) => {
                        return visasList.push({
                            val: visa._id,
                            label: visa.title,
                        });
                    });

                setVisas(visasList);
            }
        };

        if (
            !isFromTimesheet &&
            roles.length === 0 &&
            permissionsTools?.visas?.canView
        )
            getVisas();
    }, []);

    const onChangeRole = (e) => {
        setRole(e.target.value);
    };

    const onChangeVisa = (e) => {
        setVisa(e.target.value);
    };

    // Possibilité d'inviter des membres avec vérification de la limite du nombre de membres selon la formule d'abonnement
    const canMemberInvite = async () => {
        if (checkUserPermission(project.author, "LIMIT_NB_COLLABORATORS")) {
            const permission = user.subscription.permissions.find(
                (item) => item.code === "LIMIT_NB_COLLABORATORS",
            );

            if (
                permission &&
                membersList.filter((member) => member.user?._id !== user._id)
                    .length >= permission["value" + i18n.locale]
            ) {
                // Impossible d'ajouter un collaborateur de plus
                return false;
            }
        }

        return true;
    };

    const onConfirm = async () => {
        if (addedList.length === 0 || !canMemberInvite()) return;

        setLoader(true);

        try {
            let datas = {
                projectId: project._id,
                roleId: role,
                visaId: visa,
            };
            let userDatas = [];

            addedList.forEach((invited) => {
                // Génération d'un token pour chaque user invité
                let token = generateRandomToken(20);

                userDatas.push({
                    email: invited.toLowerCase(),
                    token: token,
                });
            });

            datas.datas = userDatas;

            const resInvites = await axios.post(
                apiURL.projectInviteUsers,
                datas,
            );

            // On va mettre à disposition les liens pour rejoindre le projet (envoyés également dans l'email)
            if (resInvites && resInvites.data) setSendedUsers([...userDatas]);

            toastAlert("success", t("invitationsEnvoyees"));

            // Reset du form
            setAddedList([]);
            setRole(roles[0].val);
            setVisa("");
        } catch (err) {
            toastAlert("error", t("uneErreurEstSurvenue"));
        }

        setLoader(false);
    };

    const onClickEmail = (value) => {
        if (!value) return;

        if (!emailsToShare.some((email) => email === value))
            setEmailsToShare([...emailsToShare, value]);
        else setEmailsToShare(emailsToShare.filter((email) => email !== value));
    };

    const requestSort = (key, direction) => {
        if (!direction) {
            // Champs de filtre classiques
            if (!sortConfig || (sortConfig && sortConfig.key !== key))
                setSortConfig({ key, direction: "descending" });
            else if (sortConfig && sortConfig.direction === "descending")
                setSortConfig({ key, direction: "ascending" });
            else if (sortConfig && sortConfig.direction === "ascending") {
                setSortConfig(null);
            }
        } else {
            // Filtres avec liste des possibilités
            setSortConfig({ key, direction, id: colsFilters });
        }
    };

    const compareValues = (a, b, direction) => {
        if (sortConfig.key) {
            return direction === "ascending"
                ? a.localeCompare(b)
                : b.localeCompare(a);
        } else {
            // TODO
        }

        return 0;
    };

    useMemo(() => {
        const sortItems = () => {
            let sortableItems = JSON.parse(JSON.stringify(membersList));

            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (sortConfig.key === "user") {
                        let aItem = a.user
                            ? a.user.firstname + " " + a.user.lastname
                            : "";
                        let bItem = b.user
                            ? b.user.firstname + " " + b.user.lastname
                            : "";

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "role") {
                        let aItem = a.role["title" + i18n.locale];
                        let bItem = b.role["title" + i18n.locale];

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "visa") {
                        let aItem =
                            a.visas.length > 0 ? a.visas[0].title : t("aucun");
                        let bItem =
                            b.visas.length > 0 ? b.visas[0].title : t("aucun");

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else if (sortConfig.key === "login") {
                        const parseDate = (date) =>
                            date ? moment(date) : null;

                        let aItem =
                            a.user && a.user.lastLoginDate
                                ? parseDate(a.user.lastLoginDate)
                                : null;
                        let bItem =
                            b.user && b.user.lastLoginDate
                                ? parseDate(b.user.lastLoginDate)
                                : null;

                        if (!aItem) return 1;
                        if (!bItem) return -1;

                        return sortConfig.direction === "ascending"
                            ? aItem.diff(bItem)
                            : bItem.diff(aItem);
                    } else {
                        return compareValues(
                            a[sortConfig.key].toLowerCase(),
                            b[sortConfig.key].toLowerCase(),
                            sortConfig.direction,
                        );
                    }
                });
            }
            setSortedItems(sortableItems);
        };

        sortItems();

        setColsFilters(null);
    }, [membersList, sortConfig]);

    return (
        <div className="mt-2 pb-4 text-left">
            {!isFromTimesheet && userRoles.MODERATOR && (
                <Fragment>
                    <div className="flex flex-row mb-8">
                        <div className="w-full max-w-[400px]">
                            <SeparatedTextField
                                text={t("email")}
                                maxLength={Constants.maxLengthEmail}
                                addedList={addedList}
                                setAddedList={setAddedList}
                                isEmail={true}
                                alreadyExistsList={membersList.map(
                                    (member) =>
                                        member.user && member.user.email,
                                )}
                                isDisabled={!canMemberInvite()}
                            />
                        </div>

                        <div className="w-[500px] mx-2">
                            <Select
                                text={t("role")}
                                name="role"
                                options={!canMemberInvite() ? [] : roles}
                                value={role}
                                onChange={onChangeRole}
                                isRequired={true}
                                isDisabled={!canMemberInvite()}
                            />
                        </div>

                        {permissionsTools?.visas?.canView && (
                            <div className="w-[500px] mx-2">
                                <Select
                                    text={t("visa")}
                                    name="visa"
                                    options={!canMemberInvite() ? [] : visas}
                                    value={visa}
                                    onChange={onChangeVisa}
                                    isDisabled={!canMemberInvite()}
                                />
                            </div>
                        )}

                        <div className="mt-7 w-[250px] ml-2">
                            <Button
                                text={t("inviter")}
                                onClick={(e) => onConfirm()}
                                isDisabled={
                                    addedList.length === 0 ? true : false
                                }
                            />
                        </div>
                    </div>

                    {!canMemberInvite() && (
                        <div>
                            <InfoMessage
                                type="warning"
                                title={t("limiteDeCollaborateurs")}
                                content={
                                    <div>
                                        <div>
                                            {t(
                                                "leNombreDeCollaborateurMaximumPossiblePourCeProjetAEteAtteintPassezALaLicenceSuperieurePourInviterPlusDeMembres",
                                            )}
                                            .
                                        </div>
                                        <div className="inline-block mt-2 text-primary font-bold hover:cursor-pointer">
                                            <ContactFormButton
                                                text={t("retirerLaLimite")}
                                                isTextOnly={true}
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                    )}
                </Fragment>
            )}

            <div className="text-black">
                {sendedUsers.length > 0 && (
                    <Fragment>
                        <hr className="my-4" />
                        <div>
                            {t(
                                "vosInvitationsOntEteEnvoyeesParMailVousPouvezEgalementLesRetrouverDansLaListeSuivante",
                            )}
                            .
                        </div>
                        <ul className="card mt-2 p-4">
                            {sendedUsers.map((sendedUser, i) => (
                                <li key={i} className="my-4">
                                    <div className="font-bold text-sm mb-2">
                                        {sendedUser.email}
                                    </div>
                                    <ClipboardCopy
                                        text={
                                            process.env.REACT_APP_BASE_URL +
                                            "join-project/" +
                                            project._id +
                                            "/" +
                                            sendedUser.token
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </Fragment>
                )}

                {emailsToShare.filter(
                    (email) =>
                        !membersList
                            .filter((member) => member.user)
                            .some((member) => member.user.email === email),
                ).length > 0 && (
                    <div className="mt-4">
                        <div>{t("autresAdresses")}</div>
                        <ul>
                            {emailsToShare
                                .filter(
                                    (email) =>
                                        !membersList
                                            .filter((member) => member.user)
                                            .some(
                                                (member) =>
                                                    member.user.email === email,
                                            ),
                                )
                                .map((email) => (
                                    <li
                                        key={email}
                                        className="inline-block mt-2"
                                    >
                                        <Badge
                                            text={email}
                                            deleteClick={(e) =>
                                                onClickEmail(email)
                                            }
                                        />
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}

                {!isFromFilter && (
                    <div className="sm:flex sm:flex-row mt-4">
                        <div
                            className={
                                "w-full font-bold" +
                                (isFromTimesheet
                                    ? " text-xl"
                                    : " text-black sm:text-xl")
                            }
                        >
                            {isFromTimesheet
                                ? t("equipe")
                                : t("membresDuDossier")}
                        </div>
                    </div>
                )}
                {isFromTimesheet && !isFromFilter && (
                    <div className="text-xs text-black break-words w-2/3 whitespace-break-spaces mt-4">
                        {t(
                            "selectionnezLesUtilisateursQuiPourrontSaisirDuTempsDeTravail",
                        )}
                        .
                    </div>
                )}

                {!isFromTimesheet && (
                    <div className="mt-4">
                        <input
                            type="text"
                            placeholder={t("rechercherParNomPrenomEmail")}
                            onChange={(e) => setSearchTerms(e.target.value)}
                            className="rounded-md shadow-sm bg-transparent border px-3 py-2 text-xs w-full"
                        />
                    </div>
                )}

                {sortedItems && (
                    <div className="mt-8">
                        {!isFromTimesheet && (
                            <div className="hidden md:flex flex-row font-bold text-xs">
                                <div className="w-full">
                                    <button
                                        type="button"
                                        onClick={(e) => requestSort("user")}
                                    >
                                        {t("membre")}
                                        <MaterialIcons
                                            name={
                                                !sortConfig ||
                                                (sortConfig &&
                                                    sortConfig.key !== "user")
                                                    ? "unfold_more"
                                                    : sortConfig.direction ===
                                                        "ascending"
                                                      ? "expand_less"
                                                      : sortConfig.direction ===
                                                            "descending" &&
                                                        "expand_more"
                                            }
                                            size="sm"
                                        />
                                    </button>
                                </div>
                                <div className="w-[300px] px-2">
                                    <button
                                        type="button"
                                        onClick={(e) => requestSort("role")}
                                    >
                                        {t("role")}
                                        <MaterialIcons
                                            name={
                                                !sortConfig ||
                                                (sortConfig &&
                                                    sortConfig.key !== "role")
                                                    ? "unfold_more"
                                                    : sortConfig.direction ===
                                                        "ascending"
                                                      ? "expand_less"
                                                      : sortConfig.direction ===
                                                            "descending" &&
                                                        "expand_more"
                                            }
                                            size="sm"
                                        />
                                    </button>
                                </div>
                                {permissionsTools?.visas?.canView && (
                                    <div className="w-[300px] px-2">
                                        <button
                                            type="button"
                                            onClick={(e) => requestSort("visa")}
                                        >
                                            {t("visa")}
                                            <MaterialIcons
                                                name={
                                                    !sortConfig ||
                                                    (sortConfig &&
                                                        sortConfig.key !==
                                                            "visa")
                                                        ? "unfold_more"
                                                        : sortConfig.direction ===
                                                            "ascending"
                                                          ? "expand_less"
                                                          : sortConfig.direction ===
                                                                "descending" &&
                                                            "expand_more"
                                                }
                                                size="sm"
                                            />
                                        </button>
                                    </div>
                                )}
                                <div className="w-[300px] px-2 whitespace-nowrap">
                                    <button
                                        type="button"
                                        onClick={(e) => requestSort("login")}
                                    >
                                        {t("derniereConnexion")}
                                        <MaterialIcons
                                            name={
                                                !sortConfig ||
                                                (sortConfig &&
                                                    sortConfig.key !== "login")
                                                    ? "unfold_more"
                                                    : sortConfig.direction ===
                                                        "ascending"
                                                      ? "expand_less"
                                                      : sortConfig.direction ===
                                                            "descending" &&
                                                        "expand_more"
                                            }
                                            size="sm"
                                        />
                                    </button>
                                </div>
                            </div>
                        )}

                        <ul>
                            {sortedItems
                                .filter(
                                    (member) =>
                                        !member.user && member.sendedDate,
                                )
                                .filter((member) =>
                                    searchTerms !== ""
                                        ? member.email
                                              .toLowerCase()
                                              .includes(
                                                  searchTerms.toLowerCase(),
                                              )
                                        : true,
                                )
                                .map((member) => (
                                    <li key={member._id} className="my-3">
                                        <MemberItem
                                            project={project}
                                            member={member}
                                            roles={roles}
                                            visas={visas}
                                            refreshAction={refreshAction}
                                            emailsToShare={emailsToShare}
                                            setEmailsToShare={setEmailsToShare}
                                            userRoles={userRoles}
                                        />
                                    </li>
                                ))}
                            {sortedItems
                                .filter(
                                    (member) =>
                                        member.user &&
                                        !project.restrictedUsers?.some(
                                            (restrictedUser) =>
                                                restrictedUser.toString() ===
                                                member.user._id.toString(),
                                        ),
                                )
                                .filter((member) =>
                                    searchTerms !== ""
                                        ? member.user.email
                                              .toLowerCase()
                                              .includes(
                                                  searchTerms.toLowerCase(),
                                              ) ||
                                          member.user.firstname
                                              .toLowerCase()
                                              .includes(
                                                  searchTerms.toLowerCase(),
                                              ) ||
                                          member.user.lastname
                                              .toLowerCase()
                                              .includes(
                                                  searchTerms.toLowerCase(),
                                              )
                                        : true,
                                )
                                .map((member) => (
                                    <li key={member._id} className="my-3">
                                        <MemberItem
                                            project={project}
                                            member={member}
                                            membersList={membersList}
                                            setMembersList={setMembersList}
                                            roles={roles}
                                            visas={visas}
                                            refreshAction={refreshAction}
                                            isFromTimesheet={isFromTimesheet}
                                            emailsToShare={emailsToShare}
                                            setEmailsToShare={setEmailsToShare}
                                            userRoles={userRoles}
                                        />
                                    </li>
                                ))}
                        </ul>
                    </div>
                )}
            </div>

            {loader && <Spinner />}
        </div>
    );
};

export default ProjectMembers;
