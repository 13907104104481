import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

import { capitalize } from "helpers/string";
import { getAdminFields, getAdminTeam } from "helpers/timesheet";
import Constants from "utils/constants";

import { DriveToolsContext } from "components/drive/Drive";

import { useTimesheetStore } from "./TimesheetStore";
import TabAbsencesValidation from "./viewAbsences/TabAbsencesValidation";
import TabConfiguration from "./viewAbsences/TabConfiguration";
import TabCustomers from "./viewAdmin/TabCustomers";
import TabEntries from "./viewAdmin/TabEntries";
import TabFields from "./viewAdmin/TabFields";
import TabProjects from "./viewAdmin/TabProjects";
import TabSummaryCouter from "./viewAdmin/TabSummaryCouter";
import TabTasks from "./viewAdmin/TabTasks";
import TabAbsences from "./viewUser/TabAbsences";
import TabHourCouter from "./viewUser/TabHourCouter";
import TabSummary from "./viewUser/TabSummary";

import { Spin, Tabs } from "antd";

export const TimesheetContext = createContext(null);

export const pagesTimesheetAdminItems = [
    {
        title: "journal",
        component: <TabEntries />,
        route: "/timesheet/entries",
    },
    {
        title: "taches",
        component: <TabTasks />,
        route: "/timesheet/tasks",
    },
    {
        title: "dossiers",
        component: <TabProjects />,
        route: "/timesheet/projects",
    },
    {
        title: "clients",
        component: <TabCustomers />,
        route: "/timesheet/customers",
    },
    {
        title: "champsPersonnalises",
        component: <TabFields />,
        route: "/timesheet/fields",
    },
    // {
    //     title: "compteurDheures",
    //     component: <TabSummaryCouter />,
    //     route: "/timesheet/summary-counter",
    // },
    // {
    //     title: "absences",
    //     component: <TabAbsencesValidation />,
    //     route: "/timesheet/absences-validation",
    // },
    // {
    //     title: "configurationHoraires",
    //     component: <TabConfiguration />,
    //     route: "/timesheet/configuration",
    // },
];

export const pagesAbsencesAdminItems = [
    {
        title: "absences",
        component: <TabAbsencesValidation />,
        route: "/timesheet/absences-validation",
    },
    {
        title: "configurationHoraires",
        component: <TabConfiguration />,
        route: "/timesheet/configuration",
    },
];

export const pagesTimesheetUserItems = [
    {
        title: "saisieDeTemps",
        component: <TabSummary />,
        route: "/timesheet/summary",
    },
    {
        title: "mesAbsences",
        component: <TabAbsences />,
        route: "/timesheet/absences",
    },
    {
        title: "compteurDheures",
        component: <TabHourCouter />,
        route: "/timesheet/hour-counter",
    },
    {
        title: "compteurDheures",
        component: <TabSummaryCouter />,
        route: "/timesheet/summary-counter",
    },
];

const TimesheetViewContext = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setLoader, userProfile } = useContext(DriveToolsContext);
    const { getUserProjects, getAdminProjects, getAdminTasks, getAdminMembers } = useTimesheetStore();
    // Manage page to render
    const absenceAdminRoute = pagesAbsencesAdminItems.find((e) => e.route === location.pathname);
    const timesheetAdminRoute = pagesTimesheetAdminItems.find((e) => e.route === location.pathname);
    const timesheetUserRoute = pagesTimesheetUserItems.find((e) => e.route === location.pathname);
    const tabToRender = absenceAdminRoute || timesheetAdminRoute || timesheetUserRoute;

    const tabsToRender = absenceAdminRoute
        ? pagesAbsencesAdminItems.filter(
              (e) =>
                  e.route !== "/timesheet/configuration" ||
                  (e.route === "/timesheet/configuration" &&
                      [...Constants.demoBimonoMails].includes(userProfile.user.email)),
          )
        : timesheetAdminRoute
          ? pagesTimesheetAdminItems
          : timesheetUserRoute
            ? pagesTimesheetUserItems
            : [];

    // Prevent unknown page
    useEffect(() => {
        if (!tabToRender) navigate("/drive");
    }, [tabToRender]);

    // Manage title page
    const titlePage = capitalize(t(tabToRender?.title));
    useEffect(() => {
        document.title = titlePage + " - " + t("applicationTitle");
    }, [titlePage]);

    // Manage timesheet common data
    const [timesheetData, setTimesheetData] = useState();
    useEffect(() => {
        const getTimesheetData = async () => {
            setLoader(true);

            const userProjects = await getAdminProjects();
            const userFields = await getAdminFields();
            const userTeam = await getAdminTeam();

            await getUserProjects();
            await getAdminProjects();
            await getAdminTasks();
            await getAdminMembers();

            setTimesheetData({
                userProjects,
                userFields,
                userTeam,
            });
            setLoader(false);
        };
        getTimesheetData();
    }, []);

    useEffect(() => {}, []);

    return timesheetData ? (
        <TimesheetContext.Provider value={{ ...timesheetData, setTimesheetData }}>
            {timesheetAdminRoute || absenceAdminRoute ? (
                <Tabs
                    id="tabs-timesheet"
                    animated
                    activeKey={location.pathname}
                    destroyInactiveTabPane
                    onTabClick={(key) => navigate(key)}
                    items={tabsToRender.map((e) => ({
                        label: capitalize(t(e.title)),
                        key: e.route,
                        children: e.component,
                    }))}
                />
            ) : (
                tabToRender?.component
            )}
        </TimesheetContext.Provider>
    ) : (
        <Spin />
    );
};

export default TimesheetViewContext;
