import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContactFormButton from "components/input/ContactFormButton";
import InformationModal from "components/modal/InformationModal";
import Notifications from "components/notification/Notifications";
import Login from "components/user/login/Login";

import MenuItem from "./MenuItem";
import UserMenu from "./UserMenu";

const NavBarMenu = ({
    profile,
    isFromMobile = false,
    isBlackText = true,
    toggleSideMenu,
    isAuthenticated,
}) => {
    const { t } = useTranslation();

    const [informationModal, setInformationModal] = useState(null);

    useEffect(() => {
        if (isAuthenticated) setInformationModal(null);
    }, [isAuthenticated]);

    return (
        <div
            className={
                "relative" +
                (!isFromMobile
                    ? " hidden justify-between items-center w-full md:flex md:order-1 h-full"
                    : "") +
                (isAuthenticated
                    ? " md:w-[calc(100%-200px)]"
                    : " md:w-[calc(100%-150px)]")
            }
        >
            {!isAuthenticated ? (
                <Fragment>
                    <ul
                        className={
                            (!isFromMobile
                                ? "flex flex-col mt-4 md:flex-row md:space-x-4 md:mt-0 md:text-sm md:font-medium h-full w-full text-left"
                                : "") +
                            (isBlackText ? " text-black" : "text-white")
                        }
                    >
                        <MenuItem
                            to="/home"
                            text={t("decouvrirBimono")}
                            isFromMobile={isFromMobile}
                            isBlackText={isBlackText}
                        />
                        <MenuItem
                            text={
                                <ContactFormButton
                                    text={t("contact")}
                                    isTextOnly
                                />
                            }
                            isFromMobile={isFromMobile}
                            isBlackText={isBlackText}
                        />
                    </ul>
                    <div
                        className={
                            !isFromMobile
                                ? "ml-4 flex flex-row"
                                : "p-2 bg-gray-700"
                        }
                    >
                        <div className=" inline-block p-2 md:p-0">
                            <button
                                className={
                                    "block text-center w-full border-2 font-bold py-2 md:py-1 px-3 rounded-lg text-xs whitespace-nowrap" +
                                    (isBlackText && !isFromMobile
                                        ? " text-white bg-primary border-primary"
                                        : " text-black bg-white border-white")
                                }
                                onClick={(e) =>
                                    setInformationModal({
                                        title: t("connexion"),
                                        content: <Login />,
                                        closeReset: setInformationModal,
                                        isNoPadding: true,
                                        customWidth:
                                            "max-w-[750px] lg:max-w-[1050px]",
                                    })
                                }
                            >
                                {t("connexion")}
                            </button>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className="absolute flex right-0">
                    <Notifications profile={profile} />
                    <UserMenu />
                </div>
            )}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                    isNoPadding={informationModal.isNoPadding}
                    customWidth={informationModal.customWidth}
                />
            )}
        </div>
    );
};

export default NavBarMenu;
