import { Fragment } from "react";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({
    title,
    content,
    callBackFunc,
    callBackParams,
    closeReset = (e) => e,
    customWidth,
    successText = null,
    errorText = null,
}) => {
    const { t } = useTranslation();

    const handleClose = () => {
        closeReset(null);
    };

    return (
        <Fragment>
            <div
                style={{
                    pointerEvents: "auto",
                }}
                className="modal h-[100dvh] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none"
            >
                <div
                    className={
                        "fixed sm:relative bottom-0 sm:bottom-auto w-full max-h-full mx-auto " +
                        (customWidth ? customWidth : "max-w-2xl")
                    }
                >
                    <div className="border-0 rounded-t-xl sm:rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                            <h3
                                className={
                                    "text-left sm:text-lg text-black font-semibold w-[95%] truncate my-auto" +
                                    (title && title.length > 30
                                        ? " text-xs"
                                        : " text-sm")
                                }
                            >
                                {title}
                            </h3>
                            <button
                                type="button"
                                onClick={(e) => handleClose()}
                                className="w-[5%] flex"
                            >
                                <i
                                    className="material-icons notranslate my-auto"
                                    style={{ fontSize: "1.8rem" }}
                                >
                                    close
                                </i>
                            </button>
                        </div>
                        {content && (
                            <div className="relative p-6 flex-auto">
                                <div className="text-left my-4 text-slate-500 leading-relaxed text-sm">
                                    {content}
                                </div>
                            </div>
                        )}
                        <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                            <button
                                className="text-primary background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => handleClose()}
                            >
                                {errorText ? errorText : t("non")}
                            </button>
                            <button
                                className="bg-primary text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="button"
                                onClick={(e) => {
                                    callBackFunc(
                                        callBackParams && callBackParams,
                                    );
                                    handleClose();
                                }}
                            >
                                {successText ? successText : t("oui")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </Fragment>
    );
};

export default ConfirmationModal;
