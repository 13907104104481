const Popover = ({
    text,
    width,
    arrowDirection = "top",
    isCentered = true,
}) => {
    const arrowStyles = {
        top: {
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderBottom: "10px solid black",
            top: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
        },
        bottom: {
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid black",
            bottom: "-10px",
            left: "50%",
            transform: "translateX(-50%)",
        },
        left: {
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: "10px solid black",
            left: "-10px",
            top: "50%",
            transform: "translateY(-50%)",
        },
        right: {
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderLeft: "10px solid black",
            right: "-10px",
            top: "50%",
            transform: "translateY(-50%)",
        },
    };

    return (
        <div
            className={
                "absolute z-[1] card bg-black text-white text-xs p-2" +
                (isCentered ? " text-center" : " text-left")
            }
            style={{
                width: width + "px",
                left: isCentered ? `calc(50% - ${width / 2}px)` : 0,
            }}
        >
            {text}
            <div className="absolute" style={arrowStyles[arrowDirection]}></div>
        </div>
    );
};

export default Popover;
