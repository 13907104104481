import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { Document, Page } from "react-pdf";
import { useLocation } from "react-router";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import useLongPress from "helpers/dnd";
import { debounce } from "helpers/input";
import Constants from "utils/constants";

import Text from "components/common/Text";
import { DriveToolsContext } from "components/drive/Drive";
import { usePlansStore } from "components/plans/PlansView";

import { innerScale } from "../DocumentReader";
import MarkerForms from "../markers/MarkerForms";
import MarkerNewItem from "../markers/MarkerNewItem";
import MarkersComments from "../markers/MarkersComments";

import { Flex, Spin } from "antd";

export default function PdfEnginePlan({
    scale,
    setScale,
    file,
    pdfWidth,
    pdfHeight,
    pdfDimensions,
}) {
    const transformRef = useRef(null);
    const [numPages, setNumPages] = useState();
    const [documentReady, setDocumentReady] = useState();

    function isWidth(
        containerWidth,
        containerHeight,
        contentWidth,
        contentHeight,
    ) {
        const scale = Math.min(
            containerWidth / contentWidth,
            containerHeight / contentHeight,
        );

        const newWidth = contentWidth * scale;
        return newWidth === containerWidth;
    }

    const dimensions = useMemo(() => {
        return isWidth(
            pdfWidth,
            pdfHeight,
            pdfDimensions?.width,
            pdfDimensions?.height,
        ) || pdfWidth < pdfHeight
            ? {
                  //   height: pdfHeight,
                  width: pdfWidth,
              }
            : {
                  //   width: pdfWidth,
                  height: pdfHeight,
              };
    }, [pdfHeight, pdfWidth, file]);

    return (
        <>
            <LoadingPDF documentReady={documentReady} />
            <ZoomWrapper
                scale={scale}
                setScale={setScale}
                pdfHeight={pdfHeight}
                pdfWidth={pdfWidth}
                transformRef={transformRef}
            >
                <Document
                    file={file.fileURL}
                    onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                    }}
                >
                    {[...Array(numPages)].map((_, index) => (
                        <CustomPageLayer
                            key={index}
                            index={index}
                            dimensions={dimensions}
                            setDocumentReady={setDocumentReady}
                            file={file}
                            scale={scale}
                            transformRef={transformRef}
                        />
                    ))}
                </Document>
            </ZoomWrapper>
        </>
    );
}

const CustomPageLayer = ({
    index,
    file,
    setDocumentReady,
    scale,
    dimensions,
    transformRef,
}) => {
    const { pathname } = useLocation();
    const { toolsData, setToolsData } = useContext(DriveToolsContext);
    const { setPositionClick, positionClick } = usePlansStore();

    const cancelAction = () => {
        setToolsData((e) => ({
            ...e,
            markers: {
                ...e.markers,
                isMarkerMode: false,
                addedMarker: null,
            },
        }));
    };
    // Handle PDF click to add marker
    const handlePdfClick = (event) => {
        if (toolsData.markers?.isMarkerMode) {
            const boundingRect = event.target.getBoundingClientRect();
            const x =
                ((event.clientX - boundingRect.left) / boundingRect.width) *
                100;
            const y =
                ((event.clientY - boundingRect.top) / boundingRect.height) *
                100;

            setToolsData((e) => ({
                ...e,
                markers: {
                    ...e.markers,
                    addedMarker: {
                        x,
                        y,
                        page: index + 1,
                    },
                },
            }));
        }
    };

    // Handle PDF click to add marker
    const handlePdfContextMenu = (event) => {
        if (toolsData.markers?.isMarkerMode) {
            cancelAction();
        }
        if (pathname == "/plans") {
            setPositionClick(null);
            setTimeout(() => {
                const boundingRect = event.target.getBoundingClientRect();
                const x =
                    ((event.clientX - boundingRect.left) / boundingRect.width) *
                    100;
                const y =
                    ((event.clientY - boundingRect.top) / boundingRect.height) *
                    100;
                setPositionClick({ x, y, page: index + 1 });
            }, 100);
        }
    };

    const backspaceLongPress = useLongPress((e) => {
        if (pathname == "/plans") {
            setPositionClick(null);
            setTimeout(() => {
                const boundingRect = e.target.getBoundingClientRect();
                const x =
                    ((e.clientX - boundingRect.left) / boundingRect.width) *
                    100;
                const y =
                    ((e.clientY - boundingRect.top) / boundingRect.height) *
                    100;
                setPositionClick({ x, y, page: index + 1 });
            }, 100);
        }
    }, 900);

    return (
        <div key={`page_wrapper_${index + 1}`} className="relative">
            <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                scale={innerScale}
                className={
                    toolsData.markers?.isMarkerMode
                        ? "pdf-cursor-crosshair"
                        : ""
                }
                {...dimensions}
                {...backspaceLongPress}
                onClick={(event) => handlePdfClick(event, index + 1)}
                onContextMenu={handlePdfContextMenu}
                onLoadSuccess={(data) => {
                    if (index === 0) {
                        setTimeout(() => {
                            if (transformRef.current)
                                transformRef.current?.centerView();
                        }, 200);
                    }
                }}
                onRenderSuccess={() => {
                    setDocumentReady(true);
                }}
            />
            <MarkersComments file={file} scale={scale} index={index} />
            <MarkerForms scale={scale} />
            {positionClick && <MarkerNewItem scale={scale} />}
        </div>
    );
};

const LoadingPDF = ({ documentReady }) => {
    const { t } = useTranslation();

    return (
        !documentReady && (
            <div
                style={{
                    textShadow: "none",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    backgroundColor: "white",
                }}
            >
                <Flex
                    vertical
                    justify="center"
                    align="center"
                    gap="large"
                    style={{
                        position: "absolute",
                        top: "30%",
                    }}
                >
                    <Spin />
                    <Text size="md" bold color="primary">
                        {t("lectureDuPdf")}
                    </Text>
                </Flex>
            </div>
        )
    );
};

const ZoomWrapper = ({
    children,
    scale,
    setScale,
    pdfHeight,
    pdfWidth,
    transformRef,
}) => {
    const mdMedia = useMediaPredicate("(min-width: " + Constants.mdSize + ")");

    const configWrapper = {
        smooth: true,
        centerZoomedOut: false,
        centerOnInit: true,
        limitToBounds: true,
        initialScale: scale,
        maxScale: 4,
        minScale: 1 / innerScale,
        panning: {
            //   allowLeftClickPan: false,
            allowRightClickPan: false,
        },
        wheel: {
            smoothStep: 0.001,
            step: 0.001,
            smooth: true,
            excluded: [],
        },
        doubleClick: {
            disabled: mdMedia,
        },
    };

    useEffect(() => {
        const api = transformRef.current;
        if (!api) return;

        const wrapper = api.instance.wrapperComponent;
        const prevScale = api.instance.transformState.scale;

        const rect = wrapper.getBoundingClientRect();
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;

        const offsetX =
            (centerX - api.instance.transformState.positionX) / prevScale;
        const offsetY =
            (centerY - api.instance.transformState.positionY) / prevScale;

        const newPosX = centerX - offsetX * scale;
        const newPosY = centerY - offsetY * scale;

        api.setTransform(newPosX, newPosY, scale);
    }, [scale]);

    return (
        <TransformWrapper
            ref={transformRef}
            {...configWrapper}
            onZoom={debounce(({ state }) => {
                setScale(state.scale);
            }, 200)}
        >
            <TransformComponent
                wrapperStyle={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    backgroundColor: "white",
                }}
                wrapperProps={{
                    onContextMenu: (e) => e.preventDefault(),
                }}
                contentStyle={{
                    padding: `${pdfHeight - 20}px ${pdfWidth - 20}px`,
                }}
            >
                {children}
            </TransformComponent>
        </TransformWrapper>
    );
};
