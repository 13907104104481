import { useEffect, useState } from "react";

const ColorPicker = ({
    name,
    defaultColor,
    saveColor,
    autoSave = false,
    pickerRef,
}) => {
    const [newColor, setNewColor] = useState(defaultColor || "");

    useEffect(() => {
        if (!autoSave) setNewColor(defaultColor || "");
    }, [defaultColor]);

    useEffect(() => {
        if (newColor && autoSave) saveColor(newColor);
    }, [newColor]);

    return (
        <div className="flex flex-row">
            <div className="overflow-hidden">
                <input
                    type="color"
                    ref={pickerRef}
                    name={name}
                    onChange={(e) => setNewColor(e.target.value)}
                    value={newColor || "#000000"}
                    className="w-0 h-0"
                    list="presets"
                />
                <datalist id="presets">
                    <option>#000000</option>
                    <option>#FF0000</option>
                    <option>#00FF00</option>
                    <option>#0000FF</option>
                    <option>#FFFF00</option>
                    <option>#FF00FF</option>
                    <option>#00FFFF</option>
                    <option>#808080</option>
                    <option>#800000</option>
                    <option>#008000</option>
                </datalist>
            </div>
        </div>
    );
};

export default ColorPicker;
