import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";

import Button from "components/input/Button";
import TextField from "components/input/TextField";

const CustomOptionsList = ({ dataLine, closeOptions, editData }) => {
    const { t } = useTranslation();

    const [listCreatedOptions, setListCreatedOptions] = useState(
        dataLine["options"]?.length ? dataLine["options"] : [""],
    );

    const removeCreatedOption = (index) => {
        const newList = listCreatedOptions.filter((_, i) => i !== index);
        setListCreatedOptions(newList);
    };

    const addCreatedOption = () => {
        const newList = [...listCreatedOptions, ""];
        setListCreatedOptions(newList);
    };

    const editCreatedOption = (index, value) => {
        const newList = [...listCreatedOptions];
        newList[index] = value;
        setListCreatedOptions(newList);
    };

    const orderCreatedOption = (value, source) => {
        setListCreatedOptions((arr) => {
            const newArr = [...arr];
            const targetIndex = newArr.findIndex((item) => item === value);
            const sourceIndex = newArr.findIndex((item) => item === source);
            newArr.splice(sourceIndex, 1);
            newArr.splice(targetIndex, 0, source);
            return newArr;
        });
    };

    return (
        <div className="cursor-default min-w-[250px]">
            <b className="font-bold"> {t("options")} </b>

            <ul className="py-6 flex flex-col ">
                {listCreatedOptions?.map((createdOption, index) => (
                    <DraggableOptionElement
                        key={index}
                        index={index}
                        createdOption={createdOption}
                        removeCreatedOption={removeCreatedOption}
                        editCreatedOption={editCreatedOption}
                        orderCreatedOption={orderCreatedOption}
                    />
                ))}

                <li
                    className="flex items-center gap-2 w-max text-blue-400 hover:text-blue-700 cursor-pointer"
                    onClick={addCreatedOption}
                >
                    <i
                        className="hover:cursor-pointer material-icons notranslate text-sm "
                        style={{ fontSize: "1.2rem" }}
                    >
                        add
                    </i>
                    <span>{t("ajouterUneOption")}</span>
                </li>
            </ul>
            <div className="flex gap-4 justify-end">
                <Button
                    text={t("annuler")}
                    isReversed={true}
                    onClick={closeOptions}
                />
                <Button
                    text={t("enregistrer")}
                    onClick={async () => {
                        await editData(listCreatedOptions);
                        closeOptions();
                    }}
                />
            </div>
        </div>
    );
};

const DraggableOptionElement = ({
    index,
    createdOption,
    removeCreatedOption,
    editCreatedOption,
    orderCreatedOption,
}) => {
    const [{ isDragging }, drag] = useDrag({
        type: "option",
        item: () => ({ source: createdOption }),
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [{ isOver }, drop] = useDrop({
        accept: "option",
        drop: ({ source }) => {
            orderCreatedOption(createdOption, source);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    });

    const isFirstItem = index === 0;

    return (
        <li
            ref={drop}
            key={index}
            className={
                `flex items-center gap-1 py-2 w-full duration-200 rounded-lg ` +
                (isOver
                    ? "bg-blue-200 via-20%  via-transparent to-transparent"
                    : "") +
                (isFirstItem ? " pr-6" : "")
            }
        >
            <div ref={drag}>
                <i
                    className=" hover:text-black text-slate-300 material-icons notranslate text-sm "
                    style={{ fontSize: "1.2rem" }}
                >
                    drag_indicator
                </i>
            </div>
            <TextField
                name={"option" + index}
                value={createdOption}
                onChange={(e) => editCreatedOption(index, e.target.value)}
            />
            {!isFirstItem && (
                <i
                    onClick={() => removeCreatedOption(index)}
                    className="deleteCreatedOption hover:cursor-pointer  text-slate-300 hover:text-red-500 material-icons notranslate text-sm "
                    style={{ fontSize: "1.2rem" }}
                >
                    delete
                </i>
            )}
        </li>
    );
};

export default CustomOptionsList;
