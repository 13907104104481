import axios from "axios";
import { useProfileStore } from "providers/ProviderProfile";

import { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

import { toastAlert } from "helpers/alert";
import { ItemTypes } from "helpers/dnd";
import {
    getFileType,
    getRelatedCompiled,
    hideMergedFiles,
    isCompiledFilesExists,
    isPcdFile,
} from "helpers/file";
import { getProjectparentsList } from "helpers/project";
import { getMemberRoles } from "helpers/user";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import ConfirmationModal from "components/modal/ConfirmationModal";
import Spinner from "components/utils/Spinner";

import FileRelatedCompiled from "./FileRelatedCompiled";
import ProjectArboItemFile from "./ProjectArboItemFile";

import { Checkbox, Typography, theme } from "antd";

const ProjectArboItem = ({
    profile,
    project,
    currentProject,
    currentFile,
    openArbo,
    setOpenArbo,
    refreshAction,
    setCurrentItem,
    closeMenu,
    userRoles,
    selectedProject,
    setSelectedProject,
    alreadyImportedFiles = [],
    importFile,
    isCurrentSelectedDefault,
    isWhiteText,
    isDuplication,
    searchTerms = "",
    // Automations
    currentAutomationId,
    automationType,
    selectedFolders,
    setSelectedFolders,
}) => {
    const lgMedia = useMediaPredicate("(min-width: " + Constants.lgSize + ")");

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { customerConfig } = useProfileStore();
    const [confirmationModal, setConfirmationModal] = useState(null);
    const [loader, setLoader] = useState(false);

    const [projectDatas, setProjectDatas] = useState(null);

    const [{ isDragging }, drag] = useDrag(() => ({
        type: ItemTypes.PROJECT,
        item: { type: ItemTypes.PROJECT, id: project._id, project: project },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }));

    const [{ isOver, canDrop }, drop] = useDrop(
        () => ({
            accept: [ItemTypes.FILE, ItemTypes.PROJECT],
            drop: (item) => handleMove(item.type, item.id, item.project),
            canDrop: (item) => handleCanDrop(item.type, item.id, item.project),
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
        }),
        [project],
    );
    const [{ isOverFiles, canDropFiles }, dropFiles] = useDrop(
        () => ({
            accept: [ItemTypes.FILE, ItemTypes.PROJECT],
            drop: (item) => handleMove(item.type, item.id, item.project),
            canDrop: (item) => handleCanDrop(item.type, item.id, item.project),
            collect: (monitor) => ({
                isOverFiles: monitor.isOver(),
                canDropFiles: monitor.canDrop(),
            }),
        }),
        [project],
    );

    useEffect(() => {
        try {
            if (openArbo.includes(project._id)) {
                const getProjectDatas = async () => {
                    const projectRes = await axios.get(
                        apiURL.getProjectById + project._id,
                    );
                    if (projectRes && projectRes.data)
                        setProjectDatas({
                            ...projectRes.data,
                            files: customerConfig?.isLightViewer
                                ? hideMergedFiles(
                                      projectRes.data.files.filter(
                                          (file) => !file.isOldVersion,
                                      ),
                                  ).sort((a, b) =>
                                      a.title
                                          .toLowerCase()
                                          .localeCompare(b.title.toLowerCase()),
                                  )
                                : projectRes.data.files
                                      .filter((file) => !file.isOldVersion)
                                      .sort((a, b) =>
                                          a.title
                                              .toLowerCase()
                                              .localeCompare(
                                                  b.title.toLowerCase(),
                                              ),
                                      ),
                        });
                };
                if (customerConfig) getProjectDatas();
            }
        } catch (e) {}
    }, [openArbo, customerConfig]);

    const handleMove = async (type, itemId, originalProject) => {
        // On ne déplace pas si on est dans le même projet
        if (originalProject._id === project._id) return;

        setLoader(true);

        if (type === ItemTypes.FILE) {
            const projectFile = await axios.get(
                apiURL.getProjectFileById + itemId,
            );

            if (projectFile && projectFile.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectFile.data.project,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !userRoles.MODERATOR) return;

                if (projectFile.data.compiledFiles.length > 0) {
                    const isExists = await isCompiledFilesExists(
                        projectFile.data.compiledFiles.map(
                            (selectedFile) => selectedFile._id,
                        ),
                        project._id,
                    );

                    if (isExists) {
                        toastAlert(
                            "warning",
                            t(
                                "cetteCompilationDeFichiersExisteDejaDansCeDossier",
                            ),
                        );

                        setLoader(false);
                        return;
                    }
                }

                const relatedCompiled = await getRelatedCompiled(
                    projectFile.data._id,
                );

                if (relatedCompiled.length > 0) {
                    setLoader(false);

                    setConfirmationModal({
                        title: t("confirmation"),
                        content: (
                            <FileRelatedCompiled
                                file={projectFile.data}
                                isMoving={true}
                            />
                        ),
                        callBackFunc: moveFile,
                        callBackParams: itemId,
                        closeReset: setConfirmationModal,
                        successText: t("confirmer"),
                        errorText: t("annuler"),
                        customWidth: "max-w-[1000px]",
                    });
                } else await moveFile(itemId);
            }
        } else if (type === ItemTypes.PROJECT) {
            if (
                itemId !== project._id.toString() &&
                project.projects.some(
                    (subProject) => subProject._id === originalProject._id,
                )
            )
                return;

            // On check si le projet dans lequel on déplace l'élément courant n'est pas un enfant
            const parents = await getProjectparentsList(project._id);

            if (parents.length > 0) {
                if (
                    parents.some((parent) => parent._id === originalProject._id)
                )
                    return;
            }

            const projectToMove = await axios.get(
                apiURL.getProjectById + itemId,
            );

            if (projectToMove && projectToMove.data) {
                // On ne déplace pas si on a pas les droits
                const resRoles = await getMemberRoles(
                    projectToMove.data,
                    profile.user,
                );

                if (!resRoles.MODERATOR || !userRoles.MODERATOR) return;

                await moveProject(itemId);
            }
        }

        setLoader(false);
    };

    // Déplacement du fichier dans le projet ciblé
    const moveFile = async (fileId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProjectFile + fileId + "/" + project._id,
            );

            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    // Déplacement du projet dans le projet ciblé
    const moveProject = async (movedProjectId) => {
        setLoader(true);

        try {
            const resMoving = await axios.get(
                apiURL.moveProject + movedProjectId + "/" + project._id,
            );
            if (resMoving && resMoving.data) refreshAction();
        } catch (e) {}

        setLoader(false);
    };

    const handleCanDrop = (type, itemId, originalProject) => {
        if (
            originalProject._id === project._id ||
            (type === ItemTypes.PROJECT &&
                itemId !== project._id.toString() &&
                project.projects &&
                project.projects.some(
                    (subProject) =>
                        subProject === originalProject._id.toString(),
                ))
        )
            return false;

        return true;
    };

    // Ouvre / Ferme une entrée de l'arborescence du projet
    const toggleOpenArbo = (id) => {
        const newArbo = openArbo;

        if (newArbo.includes(id)) newArbo.splice(newArbo.indexOf(id), 1);
        else newArbo.push(id);

        setOpenArbo([...newArbo]);
    };

    const handleClick = () => {
        if (setSelectedProject) {
            if (
                (isCurrentSelectedDefault ||
                    currentProject._id !== project._id) &&
                (currentFile ||
                    isDuplication ||
                    currentProject.parentProject !== project._id.toString())
            )
                setSelectedProject(project);
        } else if (!importFile) {
            navigate("/drive/project/" + project._id);
            closeMenu && closeMenu(false);
            if (setCurrentItem) setCurrentItem(lgMedia && project);
        }
    };

    const onCheck = () => {
        setSelectedFolders(project._id);
    };

    const highlightText = (text) => {
        if (!searchTerms.trim()) return text; // If search term is empty, return text as is.

        const lowerText = text.toLowerCase();
        const lowerSearchTerms = searchTerms.toLowerCase();

        if (!lowerText.includes(lowerSearchTerms)) return text; // No match, return original text

        const regex = new RegExp(`(${searchTerms})`, "gi");

        return text.split(regex).map((part, index) =>
            part.toLowerCase() === lowerSearchTerms ? (
                <Typography.Text key={index} mark>
                    {part}
                </Typography.Text>
            ) : (
                part
            ),
        );
    };

    if (setSelectedProject && currentProject && !currentFile) {
        if (currentProject._id && currentProject._id === project._id)
            return null;
    }
    const alreadyHasNomenclature =
        selectedFolders &&
        automationType === "nomenclature" &&
        project.automations
            .filter((automation) =>
                automation._id ? automation._id !== currentAutomationId : true,
            )
            .some(
                (automation) =>
                    automation.actions &&
                    automation.actions[0].type.code === "ADD_NOMENCLATURE",
            );

    const activeBgColor = isWhiteText ? " bg-slate-500" : " bg-slate-300";

    const { token } = theme.useToken();

    return (
        <div
            ref={!setSelectedProject && !setSelectedFolders ? drag : null}
            key={project._id}
            className={
                "text-xs" +
                (alreadyHasNomenclature
                    ? " opacity-25 hover:cursor-default"
                    : "")
            }
        >
            <div
                ref={!setSelectedProject && !setSelectedFolders ? drop : null}
                className={
                    "rounded-lg relative hover:cursor-pointer" +
                    (!isOver &&
                    ((currentProject &&
                        project._id === currentProject._id &&
                        !selectedProject) ||
                        (selectedProject &&
                            selectedProject._id === project._id) ||
                        (selectedFolders &&
                            selectedFolders.some(
                                (folder) => folder === project._id,
                            )))
                        ? activeBgColor
                        : isOver && canDrop
                          ? " bg-blue-200"
                          : "") +
                    (isWhiteText
                        ? " hover:bg-slate-500"
                        : " hover:bg-slate-300")
                }
            >
                <div className="flex flex-row">
                    {project.files.length === 0 &&
                    project.projects.length === 0 ? (
                        <div
                            style={{
                                padding: token.paddingXXS,
                                paddingRight: 0,
                                marginRight: token.marginXXS,
                                marginLeft: token.marginMD,
                            }}
                        ></div>
                    ) : (
                        <button
                            type="button"
                            style={{
                                padding: token.paddingXXS,
                                paddingRight: 0,
                                marginRight: token.marginXXS,
                            }}
                            onClick={(e) =>
                                !alreadyHasNomenclature &&
                                toggleOpenArbo(project._id)
                            }
                        >
                            {openArbo.includes(project._id) ? (
                                <MaterialIcons name="expand_more" size="sm" />
                            ) : (
                                <MaterialIcons name="chevron_right" size="sm" />
                            )}
                        </button>
                    )}

                    <div
                        className="w-full truncate"
                        style={{
                            padding: token.paddingXS,
                            paddingLeft: 0,
                        }}
                        onClick={(e) => !setSelectedFolders && handleClick()}
                    >
                        {setSelectedFolders && (
                            <div className="mr-2 inline align-middle">
                                <Checkbox
                                    onChange={onCheck}
                                    checked={selectedFolders.includes(
                                        project._id,
                                    )}
                                    disabled={alreadyHasNomenclature}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                display: "inline",
                                marginRight: token.marginMD,
                                verticalAlign: "middle",
                            }}
                        >
                            <MaterialIcons
                                name="folder"
                                type={isWhiteText ? "white" : ""}
                                color={project.color && project.color}
                                size="sm"
                            />
                        </div>

                        <span className="align-middle" title={project.title}>
                            {highlightText(project.title)}
                        </span>
                    </div>
                    {((selectedProject &&
                        selectedProject._id === project._id) ||
                        (selectedFolders &&
                            selectedFolders.some(
                                (folder) => folder === project._id,
                            ))) && (
                        <div
                            style={{
                                display: "inline",
                                marginRight: token.marginXS,
                            }}
                            className="my-auto"
                        >
                            <MaterialIcons
                                name="check"
                                size="md"
                                type="primary"
                            />
                        </div>
                    )}
                </div>
            </div>
            {openArbo.includes(project._id) && projectDatas && (
                <div>
                    <div
                        ref={!setSelectedProject ? dropFiles : null}
                        className={
                            isOverFiles && canDropFiles ? " bg-blue-200" : ""
                        }
                    >
                        {projectDatas.files.length > 0 &&
                            !setSelectedProject &&
                            !setSelectedFolders && (
                                <div>
                                    {projectDatas.files
                                        .filter((file) =>
                                            alreadyImportedFiles.some(
                                                (importedFile) =>
                                                    importedFile._id ===
                                                    file._id,
                                            )
                                                ? false
                                                : true,
                                        )
                                        .filter((file) =>
                                            alreadyImportedFiles.length > 0 &&
                                            getFileType(file) !== "IFC" &&
                                            !isPcdFile(file)
                                                ? false
                                                : true,
                                        )
                                        .map((projectFile) => {
                                            return (
                                                <ProjectArboItemFile
                                                    key={projectFile._id}
                                                    project={projectDatas}
                                                    file={projectFile}
                                                    currentFile={currentFile}
                                                    profile={profile}
                                                    refreshAction={
                                                        refreshAction
                                                    }
                                                    closeMenu={closeMenu}
                                                    importFile={importFile}
                                                    isWhiteText={isWhiteText}
                                                />
                                            );
                                        })}
                                </div>
                            )}
                    </div>

                    <div>
                        {projectDatas.projects.length > 0 && (
                            <div>
                                {projectDatas.projects
                                    .filter(
                                        (subProject) =>
                                            !subProject.restrictedUsers?.some(
                                                (restrictedUser) =>
                                                    restrictedUser.toString() ===
                                                    profile?.user?._id.toString(),
                                            ),
                                    )
                                    .sort((a, b) =>
                                        a.title
                                            .toLowerCase()
                                            .localeCompare(
                                                b.title.toLowerCase(),
                                            ),
                                    )
                                    .map((subProject) => {
                                        return (
                                            <div
                                                key={subProject._id}
                                                className="pl-4"
                                            >
                                                <ProjectArboItem
                                                    profile={profile}
                                                    project={subProject}
                                                    currentProject={
                                                        currentProject
                                                    }
                                                    currentFile={currentFile}
                                                    openArbo={openArbo}
                                                    setOpenArbo={setOpenArbo}
                                                    refreshAction={
                                                        refreshAction
                                                    }
                                                    setCurrentItem={
                                                        setCurrentItem
                                                    }
                                                    closeMenu={closeMenu}
                                                    userRoles={userRoles}
                                                    selectedProject={
                                                        selectedProject
                                                    }
                                                    setSelectedProject={
                                                        setSelectedProject
                                                    }
                                                    alreadyImportedFiles={
                                                        alreadyImportedFiles
                                                    }
                                                    importFile={importFile}
                                                    selectedFolders={
                                                        selectedFolders
                                                    }
                                                    setSelectedFolders={
                                                        setSelectedFolders
                                                    }
                                                    isCurrentSelectedDefault={
                                                        isCurrentSelectedDefault
                                                    }
                                                    currentAutomationId={
                                                        currentAutomationId
                                                    }
                                                    automationType={
                                                        automationType
                                                    }
                                                    isWhiteText={isWhiteText}
                                                    isDuplication={
                                                        isDuplication
                                                    }
                                                    searchTerms={searchTerms}
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                        )}
                    </div>
                </div>
            )}

            {confirmationModal && (
                <ConfirmationModal
                    title={confirmationModal.title}
                    content={confirmationModal.content}
                    callBackFunc={confirmationModal.callBackFunc}
                    callBackParams={confirmationModal.callBackParams}
                    closeReset={confirmationModal.closeReset}
                    successText={confirmationModal.successText}
                    errorText={confirmationModal.errorText}
                    customWidth={confirmationModal.customWidth}
                />
            )}

            {loader && <Spinner />}
        </div>
    );
};

export default ProjectArboItem;
