import { Fragment } from "react";

import { usePreventModalCloseOnBackButton } from "helpers/window";

const InformationModal = ({
    title,
    titleSize,
    content,
    closeReset,
    customWidth,
    specificOverflow = "",
    maxHeight = "",
    isNoPadding = false,
    useBack = true,
}) => {
    const handleClose = () => {
        closeReset(null);
    };

    usePreventModalCloseOnBackButton(useBack, closeReset);

    return (
        <Fragment>
            <div
                style={{
                    pointerEvents: "auto",
                }}
                className="modal justify-center items-center flex overflow-x-hidden fixed inset-0 outline-none focus:outline-none z-[100]"
            >
                <div
                    className={
                        "fixed sm:relative bottom-0 sm:bottom-auto z-50 sm:my-6 mx-auto w-full " +
                        (customWidth ? customWidth : "max-w-2xl")
                    }
                >
                    <div
                        className={
                            "border-0 rounded-t-xl sm:rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[100dvh] " +
                            (specificOverflow === ""
                                ? "overflow-y-auto"
                                : specificOverflow)
                        }
                    >
                        {!isNoPadding && (
                            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                <h3
                                    className={
                                        "sm:text-lg text-black font-semibold w-[95%] text-left truncate my-auto" +
                                        (titleSize
                                            ? ` ${titleSize}`
                                            : title && title.length > 30
                                              ? " text-xs"
                                              : " text-sm")
                                    }
                                >
                                    {title}
                                </h3>
                                <button
                                    type="button"
                                    onClick={(e) => handleClose()}
                                    className="w-[5%] flex"
                                >
                                    <i
                                        className="material-icons notranslate my-auto"
                                        style={{ fontSize: "1.8rem" }}
                                    >
                                        close
                                    </i>
                                </button>
                            </div>
                        )}

                        <div
                            className={
                                "relative flex-auto overflow-y-auto" +
                                (!isNoPadding ? " p-6" : "")
                            }
                        >
                            {isNoPadding && (
                                <button
                                    type="button"
                                    onClick={(e) => handleClose()}
                                    className="absolute z-[1] right-2 top-2 w-[25px] h-[25px] text-white md:text-black border-2 border-white md:border-black rounded-full"
                                >
                                    <i
                                        className="material-icons notranslate"
                                        style={{ fontSize: "1.3rem" }}
                                    >
                                        close
                                    </i>
                                </button>
                            )}
                            <div
                                className={
                                    "text-slate-500 leading-relaxed " +
                                    maxHeight
                                }
                            >
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="opacity-50 fixed inset-0 z-40 bg-black"
                    onClick={(e) => handleClose()}
                ></div>
            </div>
        </Fragment>
    );
};

export default InformationModal;
