import moment from "moment";

import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";

import { Button, Checkbox, Col, DatePicker, Form, Input, Row, TimePicker, theme } from "antd";

const ModalCallTender = ({ edit = false }) => {
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const [passwordEnabled, setPasswordEnabled] = useState(!!edit?.password);
    const [termDateEnabled, setTermDateEnabled] = useState(!!edit?.termDate);

    return (
        <Fragment>
            {!edit && (
                <Form.Item
                    hasFeedback
                    name="title"
                    label={t("titreDeVotreDossier")}
                    validateTrigger="onBlur"
                    rules={[
                        {
                            max: constants.maxLengthProjectTitle,
                            required: true,
                        },
                    ]}>
                    <Input size="large" required />
                </Form.Item>
            )}
            {/* <Form.Item hasFeedback name="keyWords" label={t("domaine")}>
                <Select
                    mode="tags"
                    size="large"
                    required
                    options={[
                        {
                            label: "Domaines",
                            options: [
                                { label: <span>Gros oeuvre</span>, value: "____" },
                            ],
                        },
                        {
                            label: "Mots-clés",
                            options: [
                                { label: <span>Construction</span>, value: "____" },
                                { label: <span>BIM</span>, value: "____" },
                            ],
                        },
                    ]}
                />
            </Form.Item> */}

            <Form.Item
                hasFeedback
                name="description"
                label={t("description")}
                validateTrigger="onBlur"
                rules={[
                    {
                        required: true,
                    },
                ]}>
                <Input.TextArea rows={6} size="large" required />
            </Form.Item>

            <Form.Item name="termDateEnabled" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox
                    style={{
                        marginBottom: token.marginLG,
                    }}
                    value={termDateEnabled}
                    onChange={() => setTermDateEnabled(!termDateEnabled)}>
                    {t("fixerUneEcheance")}
                </Checkbox>
            </Form.Item>

            <Form.Item
                hidden={!termDateEnabled}
                style={{
                    marginBottom: 0,
                }}>
                <Row gutter={24}>
                    <Col lg={12} span={24}>
                        <Form.Item name="termDate" label={t("dateLimite")} rules={[{ required: termDateEnabled }]}>
                            <DatePicker
                                size="large"
                                style={{
                                    width: "100%",
                                }}
                                minDate={moment()}
                                disabledDate={(current) => current && (current.day() === 0 || current.day() === 6)}
                                cellRender={(value) => {
                                    if (
                                        value.day() === 0 ||
                                        value.day() === 6 ||
                                        value.isBefore(moment().startOf("day"))
                                    ) {
                                        return <div style={{ textDecoration: "line-through" }}>{value.date()}</div>;
                                    }
                                    if (value.isSame(moment(), "day")) {
                                        return <div style={{ backgroundColor: token.hover }}>{value.date()}</div>;
                                    }
                                    return value.date();
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={12} span={24}>
                        <Form.Item name="termTime" label={t("heureLimite")} rules={[{ required: termDateEnabled }]}>
                            <TimePicker
                                size="large"
                                style={{
                                    width: "100%",
                                }}
                                format="HH:mm"
                                needConfirm={false}
                                showNow={false}
                                hideDisabledOptions
                                disabledTime={(current) => {
                                    if (current) {
                                        return {
                                            disabledHours: () => {
                                                const hours = [];
                                                for (let i = 0; i < moment().add(1, "hours").hour(); i++) {
                                                    hours.push(i);
                                                }
                                                return hours;
                                            },
                                            disabledMinutes: () => {
                                                const minutes = [];
                                                for (let i = 0; i < 60; i++) {
                                                    if (i % 5 !== 0) minutes.push(i);
                                                }
                                                return minutes;
                                            },
                                        };
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="allowLateSubmission" validateTrigger="onChange" valuePropName="checked">
                    <Checkbox>{t("authoriserLeTransfertEnRetard")}</Checkbox>
                </Form.Item>
            </Form.Item>

            <Form.Item name="passwordEnabled" valuePropName="checked" style={{ marginBottom: 0 }}>
                <Checkbox
                    disabled={!!edit}
                    style={{
                        marginBottom: token.marginLG,
                    }}
                    value={passwordEnabled}
                    onChange={() => setPasswordEnabled(!passwordEnabled)}>
                    {t("motDePasse")}
                </Checkbox>
            </Form.Item>

            {!edit && (
                <Form.Item
                    hidden={!passwordEnabled}
                    name="password"
                    rules={[
                        {
                            required: passwordEnabled,
                            max: constants.maxLengthPassword,
                        },
                    ]}>
                    <Input.Password size="large" />
                </Form.Item>
            )}

            <Form.Item label={t("fichiersRequis")}>
                <Form.List name={"requiredFiles"}>
                    {(fields, { add, remove }) => (
                        <Fragment>
                            {fields.map(({ key, name }) => (
                                <Form.Item
                                    name={[name, "name"]}
                                    key={key}
                                    style={{
                                        marginBottom: token.padding,
                                    }}
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>
                                    <Input
                                        placeholder={t("intituleDuFichier")}
                                        suffix={
                                            <Button
                                                type="text"
                                                onClick={() => remove(name)}
                                                size="small"
                                                icon={<MaterialIcons name="do_not_disturb_on" size="xs" />}
                                            />
                                        }
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button type="text" onClick={() => add()} icon={<MaterialIcons name="add" />}>
                                    {t("ajouterUnePiece")}
                                </Button>
                            </Form.Item>
                        </Fragment>
                    )}
                </Form.List>
            </Form.Item>
        </Fragment>
    );
};

export default ModalCallTender;
