import { useTranslation } from "react-i18next";

import { Form, Input } from "antd";

export default function ModalNewForm() {
    const { t } = useTranslation();

    return (
        <>
            <Form.Item
                hasFeedback
                name="title"
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input size="large" placeholder={t("titre")} />
            </Form.Item>
        </>
    );
}
