import { useTranslation } from "react-i18next";

import UserMenu from "components/nav/UserMenu";
import Notifications from "components/notification/Notifications";

const PortalNav = ({ profile }) => {
    const { t } = useTranslation();

    return (
        <nav className="flex flex-row py-1 px-2 text-left">
            <div className="w-full">
                <img
                    src="/img/logo-big.png"
                    className="w-[160px] h-[70px] object-contain"
                    alt={t("applicationTitle")}
                />
            </div>
            <div className="relative flex h-full my-auto p-3 mr-2">
                <Notifications profile={profile} />
                <UserMenu />
            </div>
        </nav>
    );
};

export default PortalNav;
