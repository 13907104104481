import axios from "axios";
import i18n from "i18next";
import moment from "moment";
import { useProfileStore } from "providers/ProviderProfile";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getDaysBetweenTwoDates } from "helpers/date";
import { capitalize } from "helpers/string";
import apiURL from "utils/apiURL";

import { getTotalWorkedHours } from "../viewAdmin/tableTimeline/InputCellTime";

import { Card, Col, Flex, Row, Skeleton, Typography } from "antd";

const TabHourCouter = () => {
    // Contexts
    const { t } = useTranslation();
    const { scheduleConfiguration } = useProfileStore();

    const [summaryData, setSummaryData] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const thisMonth = await axios.post(apiURL.getFilteredTimeEntries, {
            filters: {
                dateRange: "month",
            },
            pagination: moment(),
        });
        const lastMonth = await axios.post(apiURL.getFilteredTimeEntries, {
            filters: {
                dateRange: "month",
            },
            pagination: moment().subtract(1, "months"),
        });
        const vacations = await axios.get(apiURL.getAbsences);

        //////////////
        const taken = vacations.data.reduce((acc, curr) => {
            if (curr.statut === "ACCEPTED" && curr.type === "VACATION" && curr.endDate <= moment().format("YYYY-MM-DD")) {
                acc += getDaysBetweenTwoDates(moment(curr.endDate), moment(curr.startDate));
            }
            return acc;
        }, 0);
        const plannedAtEndOfYear = vacations.data.reduce((acc, curr) => {
            if (curr.statut === "ACCEPTED" && curr.type === "VACATION") {
                acc += getDaysBetweenTwoDates(moment(curr.endDate), moment(curr.startDate));
            }
            return acc;
        }, 0);

        const hoursWorkedLastMonth = getTotalWorkedHours(scheduleConfiguration, lastMonth.data) || "0h";
        const hoursWorkedThisMonth = getTotalWorkedHours(scheduleConfiguration, thisMonth.data) || "0h";
        const rights = 25; // TOWAIT : Put Rights in Schedule configuration in API
        const remaining = rights - taken;
        const remainingAtEndOfYear = rights - taken - plannedAtEndOfYear;

        setSummaryData({
            hoursWorkedLastMonth,
            hoursWorkedThisMonth,
            rights,
            taken,
            remaining,
            plannedAtEndOfYear,
            remainingAtEndOfYear,
        });
    };

    return (
        <>
            <Card title="Heures travaillées" size="small" style={{ maxWidth: 400 }}>
                {summaryData ? (
                    <Row>
                        <Col span={12}>
                            <Typography.Text type="secondary">
                                {capitalize(moment().subtract(1, "months").locale(i18n.locale).format("MMMM YYYY"))}
                            </Typography.Text>
                            <br />
                            <Typography.Text strong style={{ fontSize: 22 }}>
                                {summaryData.hoursWorkedLastMonth}
                            </Typography.Text>
                        </Col>
                        <Col span={12}>
                            <Typography.Text type="secondary">{capitalize(moment().locale(i18n.locale).format("MMMM YYYY"))}</Typography.Text>
                            <br />
                            <Typography.Text strong type={summaryData.hoursWorkedThisMonth && "success"} style={{ fontSize: 22 }}>
                                {summaryData.hoursWorkedThisMonth && "+"}
                                {summaryData.hoursWorkedThisMonth}
                            </Typography.Text>
                        </Col>
                    </Row>
                ) : (
                    <Flex vertical gap={12}>
                        <Skeleton.Input active block />
                        <Skeleton.Input active block />
                    </Flex>
                )}
            </Card>

            <Card title="Vacances" size="small" style={{ marginTop: 24, maxWidth: 400 }}>
                {summaryData ? (
                    <>
                        <Flex justify="space-between">
                            <Typography.Text>{t("droit")}</Typography.Text>
                            <Typography.Text strong>
                                {summaryData.rights} {t("jours")}
                            </Typography.Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Typography.Text>{t("consomme")}</Typography.Text>
                            <Typography.Text strong>
                                {summaryData.taken} {t("jours")}
                            </Typography.Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Typography.Text>{t("avoirRestant")}</Typography.Text>
                            <Typography.Text strong type={summaryData.remaining > 0 && "success"}>
                                {summaryData.remaining} {t("jours")}
                            </Typography.Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Typography.Text>{`${t("plannifie")} ${t("au")} 31.12`}</Typography.Text>
                            <Typography.Text strong>
                                {summaryData.plannedAtEndOfYear} {t("jours")}
                            </Typography.Text>
                        </Flex>
                        <Flex justify="space-between">
                            <Typography.Text>{`${t("avoirRestant")} ${t("au")} 31.12`}</Typography.Text>
                            <Typography.Text strong type={summaryData.remainingAtEndOfYear > 0 && "success"}>
                                {summaryData.remainingAtEndOfYear} {t("jours")}
                            </Typography.Text>
                        </Flex>
                    </>
                ) : (
                    <Skeleton
                        paragraph={{
                            rows: 5,
                            width: "100%",
                        }}
                        title={false}
                        active
                    />
                )}
            </Card>
        </>
    );
};

export default TabHourCouter;
