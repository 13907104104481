import axios from "axios";

import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { toastAlert } from "helpers/alert";
import { fieldValidation } from "helpers/fieldValidation";
import { getFileType } from "helpers/file";
import { generateBCFContent, saveSceneSettings } from "helpers/viewer";
import apiURL from "utils/apiURL";
import Constants from "utils/constants";

import Button from "components/input/Button";
import IconButton from "components/input/IconButton";
import InformationModal from "components/modal/InformationModal";
import Login from "components/user/login/Login";
import Spinner from "components/utils/Spinner";

import CommentTextarea from "./CommentTextarea";
import { CommentsContext } from "./CommentsContent";

const CommentForm = ({
    user,
    files,
    showCommentForm,
    setShowCommentForm,
    compiledId,
    commentToAnswer,
    setCommentToAnswer,
}) => {
    const { t } = useTranslation();

    const formRef = useRef(null);

    const {
        comments,
        setComments,
        members,
        isLightViewer,
        handleMarkerMode,
        isMarkerMode,
        addedMarker,
        toggleShowMarkers,
        handleMarkerSaved,
        areMarkersVisible,
        setMarkersVisible,
        isFromDocument,
        isFromViewer,
        world,
    } = useContext(CommentsContext);

    useEffect(() => {
        if (!toggleShowMarkers) return;

        toggleShowMarkers(true);
        setMarkersVisible(true);
    }, []);
    const [informationModal, setInformationModal] = useState(null);

    const [loader, setLoader] = useState(false);

    const initialFormData = {
        title: "",
        content: "",
        image: null,
        author: user && user,
        answeredCommentId: null,
        guestEmail: null,
        guestName: null,
        projectFiles: files.map((file) => file._id),
        compiledId,
        isLightViewer,
        BCFContent: null,
    };
    const [formData, setFormData] = useState(initialFormData);

    const initialFieldsError = {
        content: "",
    };
    const [fieldsError, setFieldsError] = useState(initialFieldsError);

    const [mentionsList, setMentionsList] = useState([]);

    useEffect(() => {
        if (!commentToAnswer) return;

        const emailToAnswer =
            commentToAnswer.author?.email || commentToAnswer.guestEmail;

        if (emailToAnswer.length > 0) {
            const updatedFormData = formData;
            updatedFormData.content = "@[" + emailToAnswer + "] ";
            updatedFormData.answeredCommentId = commentToAnswer._id;
            setFormData(updatedFormData);

            formRef.current.querySelector("textarea")?.focus();
            setCommentToAnswer(null);
        }
    }, [commentToAnswer]);

    const onChange = (e, name) => {
        setFormData({
            ...formData,
            [name ? name : e.target.name]: e.target.value,
        });
    };

    const onChangeMentions = async (e) => {
        if (e.slice(1).length < 2) {
            setMentionsList([]);
            return;
        }

        const terms = e.slice(1).toLowerCase();

        let resMembersByTerm = [];

        resMembersByTerm = members
            .filter((member) => member.user)
            .map((member) => member.user)
            .filter(
                (user) =>
                    user.email.toLowerCase().includes(terms) ||
                    user.firstname.toLowerCase().includes(terms) ||
                    user.lastname.toLowerCase().includes(terms),
            );

        if (
            resMembersByTerm.filter((item) => item.email !== user.email)
                .length > 0
        ) {
            let updatedList = [];

            resMembersByTerm
                .filter((item) => item.email !== user.email)
                .forEach((user) => {
                    updatedList.push({
                        id: user.email,
                        display:
                            user.firstname +
                            " " +
                            user.lastname +
                            " (" +
                            user.email +
                            ")",
                    });
                });

            setMentionsList(updatedList);
        } else setMentionsList([]);
    };

    const onSave = async () => {
        // On check si le user est en guest pour qu'il renseigne en amont son nom et son email
        if (
            !user &&
            (!localStorage.getItem("guestEmail") ||
                !localStorage.getItem("guestName"))
        ) {
            setInformationModal({
                content: (
                    <Login isFromFile={true} closeModal={closeModalAndSave} />
                ),
                closeReset: setInformationModal,
            });

            return;
        }

        setLoader(true);

        const resValidation = await fieldsValidation();

        if (resValidation === 0) {
            try {
                let commentDatas = formData;
                commentDatas.guestEmail = localStorage.getItem("guestEmail");
                commentDatas.guestName = localStorage.getItem("guestName");

                if (isFromViewer && isLightViewer) {
                    commentDatas.BCFContent = await generateBCFContent(world);
                    const resSettings = saveSceneSettings(world);
                    commentDatas.settings = resSettings;
                }

                // Si l'utilisateur à placé un marker sur le document
                if (addedMarker) commentDatas.marker = addedMarker;

                let commentRes;

                // Fonctionnement classique en BDD
                commentRes = await axios.post(
                    apiURL.createComment,
                    commentDatas,
                );

                commentRes = commentRes?.data;

                if (commentRes) {
                    toastAlert("success", t("commentaireAjoute"));
                    // On ajoute l'élement au début du tableau des commentaires
                    const updatedComments = [...comments];
                    updatedComments.unshift(commentRes);
                    setComments([...updatedComments]);
                    // Si on a ajouté un marker, on va supprime celui-ci du marker actif et si nécessaire refresh la liste des markers des commedntaires pour ce fichier
                    if (addedMarker && handleMarkerSaved) {
                        handleMarkerSaved(areMarkersVisible, updatedComments);
                    }
                } else {
                    toastAlert(
                        "error",
                        t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                    );
                }
            } catch (err) {
                toastAlert(
                    "error",
                    t("uneErreurEstSurvenueLorsDeLEnregistrement"),
                );
            }

            setShowCommentForm(false);
            clearForm();
        }

        setLoader(false);
    };

    const closeModalAndSave = () => {
        setInformationModal(null);
        onSave();
    };

    const clearForm = () => {
        setFormData(initialFormData);
        setFieldsError(initialFieldsError);
    };

    const fieldsValidation = async () => {
        // Validation des champs

        let nbErrors = 0;

        var updatedFieldsError = fieldsError;
        var validation = "";

        if (!formData.image) {
            validation = fieldValidation(formData.content, "required");
            updatedFieldsError.content = validation;
            if (validation !== "") nbErrors++;
        }

        setFieldsError({ ...fieldsError, updatedFieldsError });

        return nbErrors;
    };

    const onUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                // Get the Base64 string from the result
                const base64String = reader.result;

                setFormData({ ...formData, image: base64String });
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <Fragment>
            {!showCommentForm ? (
                <div className="text-right px-2 md:absolute md:top-2 md:right-0 flex">
                    <div className="my-auto">
                        <IconButton
                            text={t("ajouterUnCommentaire")}
                            onClick={(e) =>
                                user || localStorage.getItem("guestName")
                                    ? setShowCommentForm(true)
                                    : setInformationModal({
                                          content: (
                                              <Login
                                                  isFromFile={true}
                                                  closeModal={
                                                      setInformationModal
                                                  }
                                              />
                                          ),
                                          closeReset: setInformationModal,
                                      })
                            }
                            icon="add"
                        />
                    </div>
                </div>
            ) : (
                <form ref={formRef} className="w-full">
                    {toggleShowMarkers &&
                        getFileType(files[0]) === "PDF" &&
                        isFromDocument && (
                            <div className="w-full text-right p-2">
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        toggleShowMarkers(!areMarkersVisible);
                                        setMarkersVisible(!areMarkersVisible);
                                    }}
                                >
                                    <div className="flex flex-row">
                                        <i
                                            className="material-icons notranslate"
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            location_on
                                        </i>
                                        <div className="ml-1 my-auto text-sm">
                                            {t(
                                                areMarkersVisible
                                                    ? "masquerLesPins"
                                                    : "afficherLesPins",
                                            )}
                                        </div>
                                    </div>
                                </button>
                            </div>
                        )}

                    <CommentTextarea
                        value={formData.content}
                        fieldsError={fieldsError}
                        loader={loader}
                        mentionsList={mentionsList}
                        onChange={onChange}
                        onChangeMentions={onChangeMentions}
                    />
                    <div className="flex flex-row mt-4">
                        {handleMarkerMode &&
                            isFromDocument &&
                            getFileType(files[0]) === "PDF" && (
                                <div>
                                    <button
                                        type="button"
                                        className="p-1"
                                        title={t("ajouterUnMarqueur")}
                                        onClick={handleMarkerMode}
                                    >
                                        <i
                                            className={
                                                "material-icons notranslate" +
                                                (isMarkerMode
                                                    ? " text-primary"
                                                    : "")
                                            }
                                            style={{ fontSize: "1.5rem" }}
                                        >
                                            add_location_alt
                                        </i>
                                    </button>
                                </div>
                            )}

                        {formData.content.trim() !== "" && (
                            <div>
                                <button
                                    type="button"
                                    className="p-1"
                                    title={t("importerUnFichier")}
                                    onClick={(e) => {
                                        document
                                            .getElementById(
                                                "import-file-comment",
                                            )
                                            .click();
                                    }}
                                >
                                    <i
                                        className="material-icons notranslate"
                                        style={{ fontSize: "1.5rem" }}
                                    >
                                        add_photo_alternate
                                    </i>
                                </button>
                                <div className="hidden">
                                    <Button
                                        id="import-file-comment"
                                        type="file"
                                        accept={Constants.imagesList}
                                        onChange={onUpload}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    {formData.image && (
                        <div
                            style={{
                                backgroundImage: "url(" + formData.image + ")",
                            }}
                            className="relative flex w-full h-[250px] sm:h-[300px] md:h-[180px] rounded-xl background-img"
                        >
                            <button
                                className="absolute flex right-[-12px] top-[-14px] p-1"
                                type="button"
                                onClick={(e) =>
                                    setFormData({ ...formData, image: null })
                                }
                            >
                                <i
                                    className="material-icons notranslate m-auto bg-slate-200 rounded-full p-0.5"
                                    style={{ fontSize: "1rem" }}
                                >
                                    close
                                </i>
                            </button>
                        </div>
                    )}

                    {formData.content.trim() !== "" && (
                        <div className="inline-block mt-3">
                            <Button
                                text={t("ajouter")}
                                onClick={(e) => onSave()}
                                isDisabled={
                                    formData.content.length === 0 &&
                                    !formData.image
                                }
                            />
                        </div>
                    )}
                </form>
            )}

            {loader && <Spinner />}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                />
            )}
        </Fragment>
    );
};

export default CommentForm;
