import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationModal from "components/modal/ConfirmationModal";
import OptionMenu from "components/utils/OptionMenu";

const ButtonDelete = ({ deleteFunction }) => {
    const { t } = useTranslation();
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    return (
        <Fragment>
            {confirmationOpen && (
                <ConfirmationModal
                    title={t("confimerLaSuppressionDefinitive?")}
                    closeReset={setConfirmationOpen}
                    callBackFunc={async () => {
                        await deleteFunction();
                    }}
                />
            )}

            <div className="relative -top-[10px] left-[10px] w-[30px]">
                <OptionMenu
                    options={[
                        {
                            content: (
                                <button
                                    onClick={() => setConfirmationOpen(true)}
                                    className="p-2 border  inline-flex w-full text-red-500"
                                >
                                    <i
                                        className="material-icons notranslate my-auto mr-1"
                                        style={{
                                            fontSize: "1.1rem",
                                        }}
                                    >
                                        delete
                                    </i>
                                    {t("supprimerDefinitivement")}
                                </button>
                            ),
                        },
                    ]}
                />
            </div>
        </Fragment>
    );
};

export default ButtonDelete;
