import axios from "axios";
import DOMPurify from "dompurify";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { fromDateToStringDateHours } from "helpers/date";
import { isIfcFile, isPcdFile } from "helpers/file";
import { formatMentions } from "helpers/text";
import apiURL from "utils/apiURL";

import Button from "components/input/Button";
import InformationModal from "components/modal/InformationModal";
import FileImage from "components/project/FileImage";

const NotificationItem = ({ user, notification, setNotifications }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [informationModal, setInformationModal] = useState(null);

    const markAsRead = async () => {
        const readNotification = await axios.get(
            apiURL.markNotificationAsRead + notification._id,
        );

        if (readNotification && readNotification.data)
            setNotifications(readNotification.data);
    };

    const onClick = () => {
        if (isIfcFile(notification.file) || isPcdFile(notification.file)) {
            window.open(
                "/viewer/" +
                    notification.file._id +
                    (notification.comment
                        ? "/" +
                          (notification.comment.bimCollabId ||
                              notification.comment._id)
                        : ""),
            );
        } else {
            window.open(
                "/document/" +
                    notification.file._id +
                    (notification.comment
                        ? "/" + notification.comment._id
                        : ""),
            );
        }

        markAsRead();
    };

    return (
        <div className="relative card p-2 mb-4">
            <div className="font-bold mt-2">
                {notification.author
                    ? notification.author.firstname +
                      " " +
                      notification.author.lastname
                    : notification.guestName}
            </div>
            {notification.isImportant && (
                <i
                    className="material-icons notranslate text-red inline-block align-middle"
                    style={{ fontSize: "1.4rem" }}
                >
                    priority_high
                </i>
            )}
            <div className="inline-block align-middle text-slate-400">
                {fromDateToStringDateHours(notification.creationDate)}
            </div>
            <div
                className="mt-2 overflow-x-auto"
                dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(notification.title),
                }}
            ></div>
            {notification.content && !notification.isOpenContent && (
                <div
                    className="card p-2 bg-slate-200 my-3"
                    dangerouslySetInnerHTML={{
                        __html: formatMentions(notification.content),
                    }}
                ></div>
            )}
            {notification.file ? (
                <div className="mt-4">
                    <FileImage
                        file={notification.file}
                        isFromInfos={true}
                        user={user}
                        comment={notification.comment && notification.comment}
                    />
                    <div className="mt-2 max-w-[300px] m-auto">
                        <Button
                            text={
                                notification.comment
                                    ? t("voirLeCommentaire")
                                    : t("voirLeFichier")
                            }
                            onClick={(e) => onClick()}
                        />
                    </div>
                </div>
            ) : (
                notification.project && (
                    <div className="mt-4">
                        <FileImage
                            file={notification.project}
                            isFromInfos={true}
                            user={user}
                        />
                        <div className="mt-2 max-w-[300px] m-auto">
                            <Button
                                text={t("voirLeDossier")}
                                onClick={(e) => {
                                    window.open(
                                        "/drive/project/" +
                                            notification.project._id,
                                    );

                                    markAsRead();
                                }}
                            />
                        </div>
                    </div>
                )
            )}
            <div className="text-right">
                <button
                    type="button"
                    className="text-primary text-sm mt-3 p-2"
                    onClick={(e) => markAsRead()}
                >
                    {t("marquerCommeLu")}
                </button>
            </div>

            {notification.isOpenContent && (
                <div className="mt-2 max-w-[300px] m-auto">
                    <Button
                        text={t("ouvrir")}
                        onClick={(e) =>
                            setInformationModal({
                                title: (
                                    <div>
                                        {notification.isImportant ? (
                                            <i
                                                className="material-icons notranslate text-red inline-block align-middle"
                                                style={{ fontSize: "1.4rem" }}
                                            >
                                                priority_high
                                            </i>
                                        ) : (
                                            ""
                                        )}
                                        <div className="inline-block align-middle">
                                            {notification.title}
                                        </div>
                                    </div>
                                ),
                                content: (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: DOMPurify.sanitize(
                                                notification.content,
                                            ),
                                        }}
                                    />
                                ),
                                closeReset: setInformationModal,
                            })
                        }
                    />
                </div>
            )}

            {informationModal && (
                <InformationModal
                    title={informationModal.title}
                    content={informationModal.content}
                    closeReset={informationModal.closeReset}
                />
            )}
        </div>
    );
};

export default NotificationItem;
