import { useState } from "react";
import { useTranslation } from "react-i18next";

import SwitchButton from "components/input/SwitchButton";
import InfoMessage from "components/utils/InfoMessage";
import QRCodeGenerator from "components/utils/QRCodeGenerator";

import { Flex } from "antd";

const ShareQRCode = ({
    fileTitle,
    publicToken,
    fileUrl,
    handleUrl,
    updatePublicToken,
    userRoles,
    switchDisabled = false,
}) => {
    const { t } = useTranslation();

    const [isPublic, setIsPublic] = useState(
        fileUrl.includes(publicToken) ? true : false,
    );

    const onChange = async () => {
        // Si pas de lien public, on le génère ici
        if (!publicToken) await updatePublicToken(false);

        handleUrl(fileUrl.includes(publicToken) ? null : publicToken);
        setIsPublic(!isPublic);
    };

    return fileUrl !== "" ? (
        <Flex vertical>
            {(publicToken || userRoles.MODERATOR) && !switchDisabled ? (
                <div className="m-auto">
                    <SwitchButton
                        text={t("utiliserLeLienPublic")}
                        onChange={onChange}
                        value={isPublic}
                    />
                </div>
            ) : (
                !publicToken &&
                userRoles.READER &&
                !userRoles.MODERATOR &&
                !switchDisabled && (
                    <InfoMessage
                        type="warning"
                        title={t("attention")}
                        content={
                            t(
                                "ceFichierNePossedePasDeLienDePartagePublicAfinDeLeConsulterIlFautEtreMembreDuDossier",
                            ) + "."
                        }
                    />
                )
            )}

            <div className="flex mt-6">
                <div className="m-auto">
                    <QRCodeGenerator
                        url={fileUrl}
                        id={"projectQRCode"}
                        name={fileTitle}
                    />
                </div>
            </div>
        </Flex>
    ) : null;
};

export default ShareQRCode;
