import moment from "moment";
import { defaultFields, useFiltersStore } from "providers/ProviderFilters";
import { useProfileStore } from "providers/ProviderProfile";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaPredicate } from "react-media-hook";

import { getFileExtension } from "helpers/file";
import { convertDataToForm, convertTemplateToAnswerDefault, formModalKey, formStatus } from "helpers/forms";
import constants from "utils/constants";

import MaterialIcons from "components/common/MaterialIcons";
import Text from "components/common/Text";
import { RenderFiedsCheckboxes, SearchForms, applyFilterByFieldForm } from "components/filters/FilterByForm";
import NewButtonFilter from "components/filters/NewButtonFilter";
import ModalSelectForm from "components/forms/ModalSelectForm";
import { ExtensionImageContent } from "components/project/FileImage";

import { ModalContext } from "layout/LayoutModal";

import { FooterAnswerForm, FormAnswer, TitleFormAnswer } from "./FormAnswer";
import { useFormsStore } from "./FormView";

import { Button, Flex, Popover, Spin, Table, Tag, theme } from "antd";

export default function FormAnswerList({ isAdmin }) {
    const { token } = theme.useToken();
    const { t } = useTranslation();
    const { addModalData, updateModalData, removeModal } = useContext(ModalContext);
    const { listAnswers, getAnwsersForm } = useFormsStore();
    const { permissionsTools } = useProfileStore();
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");
    const { filters, setFilters } = useFiltersStore();
    const filterPage = filters[window.location.pathname];
    const filterForms = filterPage?.formColumns || [];
    const isInPlan = permissionsTools.plansAdmin.canView || permissionsTools.plansUser.canView;

    useEffect(() => {
        if (filterForms?.length === 0) {
            setFilters({
                formColumns: defaultFields,
            });
        }
    }, [filterForms]);

    useEffect(() => {
        getAnwsersForm();
    }, []);

    const openForm = async (record) => {
        addModalData({
            isForm: false,
            key: formModalKey,
            content: <Spin />,
        });
        const initialValues = await convertDataToForm(record);
        updateModalData({
            isForm: true,
            key: formModalKey,
            okText: t("envoyer"),
            fullScreen: !mdMedia,
            width: 800,
            initialValues,
            formDisabled: record.status === "APPROVED",
            title: <TitleFormAnswer author={record.author} templateData={record} />,
            content: <FormAnswer templateData={record} />,
            footer: <FooterAnswerForm templateData={record} refreshList={getAnwsersForm} _id={record._id} />,
            handleConfirm: async () => {
                await getAnwsersForm();
                removeModal(formModalKey);
            },
        });
    };

    if (!listAnswers) return <></>;
    return (
        <Flex vertical gap="small">
            <Flex gap={mdMedia ? "large" : "small"} align="center" wrap>
                {!isAdmin && <NewForm refreshList={getAnwsersForm} />}
                <NewButtonFilter filtersTypes={["forms"]} />
                <SearchForms />
            </Flex>
            <Table
                size="small"
                scroll={{
                    x: 1500,
                    y: "60dvh",
                }}
                tableLayout="auto"
                pagination={false}
                dataSource={applyFilterByFieldForm(listAnswers, filterPage)}
                rowKey={(record) => record._id}
                onRow={(record) => ({
                    style: {
                        cursor: "pointer",
                    },
                    onClick: () => {
                        openForm(record);
                    },
                })}
                columns={[
                    ...filterForms
                        ?.filter((e) =>
                            defaultFields.find((k) => k._id === e._id)
                                ? true
                                : filterPage.forms?.fields?.find((k) => k._id === e._id),
                        )
                        ?.map((field) => {
                            let column = {
                                dataIndex: field._id,
                                ellipsis: true,
                                minWidth: 200,
                                title: <TitleColumn field={field} />,
                                render: () => <></>,
                            };

                            if (field._id === "reference") {
                                column.minWidth = 70;
                                column.render = (_, record) => <Text size="sm">{record.reference}</Text>;
                            } else if (field._id === "title") {
                                column.render = (_, record) => (
                                    <Flex align="center" gap={token.paddingSM}>
                                        <MaterialIcons name="docs" />
                                        <Text size="sm">{record.title || record.template.title}</Text>
                                    </Flex>
                                );
                            } else if (field._id === "author") {
                                column.render = (_, record) => (
                                    <Text size="sm">{record.author?.firstname + " " + record.author?.lastname}</Text>
                                );
                            } else if (field._id === "creationDate") {
                                column.render = (_, record) => (
                                    <Text size="sm">{moment(record.creationDate).format("DD/MM/YYYY - HH:mm")}</Text>
                                );
                            } else if (field._id === "updatedDate") {
                                column.render = (_, record) => (
                                    <Text size="sm">{moment(record.updatedDate).format("DD/MM/YYYY - HH:mm")}</Text>
                                );
                            } else if (field._id === "status") {
                                column.render = (_, record) =>
                                    formStatus[record.status] && (
                                        <Tag color={formStatus[record.status].variant}>
                                            <Flex align="center" gap={6}>
                                                <MaterialIcons name={formStatus[record.status].icon} size="xs" />
                                                {formStatus[record.status].label}
                                            </Flex>
                                        </Tag>
                                    );
                            } else if (field._id === "position") {
                                column.render = (_, record) => {
                                    if (!record.position?.file) return "-";
                                    return (
                                        <Flex align="center" gap="small">
                                            <ExtensionImageContent file={record.position.file} width={26} />
                                            <Text ellipsis>
                                                {record.position.file.title +
                                                    "." +
                                                    getFileExtension(record.position.file.file)}
                                            </Text>
                                        </Flex>
                                    );
                                };
                            } else {
                                if (field.type === "STATE") {
                                    column.filters = field.options?.map((option, i) => ({
                                        text: (
                                            <Tag
                                                color={
                                                    i === 0
                                                        ? "error"
                                                        : i === 1
                                                          ? "warning"
                                                          : i === 2
                                                            ? "success"
                                                            : "blue"
                                                }>
                                                <Flex align="center" gap={6}>
                                                    {option}
                                                </Flex>
                                            </Tag>
                                        ),
                                        value: option,
                                    }));
                                    column.onFilter = (value, record) => {
                                        const result = record.fields.find((e) => e.templateField === field?._id);
                                        return result?.value === field.options.findIndex((e) => e === value);
                                    };
                                }
                                if (field.type === "BOOLEAN") {
                                    column.filters = [
                                        {
                                            text: "Oui",
                                            value: true,
                                        },
                                        {
                                            text: "Non",
                                            value: false,
                                        },
                                    ];
                                    column.onFilter = (value, record) => {
                                        const result = record.fields.find((e) => e.templateField === field?._id);
                                        return value === true ? result.value === true : result.value !== true;
                                    };
                                }
                                if (field.type === "TEXT_SHORT") {
                                    column.filters = listAnswers
                                        .filter((e) => e.fields.find((k) => k.templateField === field._id))
                                        .map((e) => {
                                            const result = e.fields.find((k) => k.templateField === field._id);
                                            return {
                                                text: result.value,
                                                value: result.value,
                                            };
                                        });
                                    column.onFilter = (value, record) => {
                                        const result = record.fields.find((e) => e.templateField === field?._id);
                                        return result.value === value;
                                    };
                                }
                                if (
                                    field.type === "SELECT_LIST" ||
                                    field.type === "SELECT_RADIO" ||
                                    field.type === "WEATHER" ||
                                    field.type === "SELECT_CHECKBOX"
                                ) {
                                    column.filters = field.options?.map((option, i) => ({
                                        text: option,
                                        value: option,
                                    }));
                                    column.onFilter = (value, record) => {
                                        const result = record.fields.find((e) => e.templateField === field?._id);
                                        return Array.isArray(result.value)
                                            ? result.value.includes(value)
                                            : result.value === value;
                                    };
                                }

                                column.render = (_, record) => {
                                    const result = record.fields.find((e) => e.templateField === field?._id);
                                    return <RenderTextFieldAnswer openForm={openForm} field={result} />;
                                };
                            }

                            return column;
                        }),
                    {
                        fixed: "right",
                        title: (
                            <Popover
                                style={{
                                    marginLeft: "auto",
                                }}
                                trigger={["click"]}
                                placement="leftTop"
                                content={<RenderFiedsCheckboxes />}>
                                <Button
                                    style={{
                                        marginLeft: "auto",
                                    }}
                                    type="text"
                                    ghost
                                    color="secondary"
                                    icon={<MaterialIcons name="playlist_add" />}
                                />
                            </Popover>
                        ),
                        width: 40,
                        minWidth: 40,
                        render: (value) => ({
                            props: {
                                style: {
                                    background: "transparent",
                                },
                            },
                        }),
                    },
                ].filter(Boolean)}
            />
        </Flex>
    );
}

const TitleColumn = ({ field }) => {
    const { filters, setFilters } = useFiltersStore();
    const filterPage = filters[window.location.pathname];
    const filterForms = filterPage?.formColumns || [];

    return (
        <Flex justify="space-between" align="center">
            <Text ellipsis bold>
                {field?.title}
            </Text>
            <Button
                shape="circle"
                type="text"
                size="small"
                icon={<MaterialIcons size="xs" name="close" />}
                onClick={() => {
                    setFilters({
                        formColumns: filterForms.filter((e) => e._id !== field._id),
                    });
                }}
            />
        </Flex>
    );
};

const NewForm = ({ refreshList }) => {
    const { t } = useTranslation();
    const { addModalData, removeModal } = useContext(ModalContext);
    const mdMedia = useMediaPredicate("(min-width: " + constants.mdSize + ")");
    const { user } = useProfileStore();
    // Forms Managment
    const { listTemplates, getTemplatesForms } = useFormsStore();
    useEffect(() => {
        getTemplatesForms();
    }, [getTemplatesForms]);

    return (
        <Button
            children={t("remplirUnFormulaire")}
            icon={<MaterialIcons name="edit_note" />}
            variant="solid"
            color="primary"
            style={{ width: "max-content" }}
            onClick={() => {
                addModalData({
                    key: "selectTemplate",
                    title: t("remplirUnFormulaire"),
                    isForm: false,
                    content: (
                        <ModalSelectForm
                            listTemplates={listTemplates
                                .filter((e) => e.active)
                                .sort((a, b) => a.title.localeCompare(b.title))}
                            onSelect={(form) => {
                                addModalData({
                                    key: formModalKey,
                                    okText: t("envoyer"),
                                    title: form.title,
                                    width: 800,
                                    fullScreen: !mdMedia,
                                    title: <TitleFormAnswer templateData={form} author={user} />,
                                    content: <FormAnswer templateData={form} />,
                                    footer: <FooterAnswerForm _id={null} templateData={form} />,
                                    initialValues: convertTemplateToAnswerDefault({
                                        ...form,
                                        author: user,
                                    }),
                                    handleConfirm: async () => {
                                        await refreshList();
                                        removeModal(formModalKey);
                                        removeModal("selectTemplate");
                                    },
                                });
                                // INCASE : Formulaires fullscreen modal + maxWidth 700px
                                // style={
                                //     {
                                // width: 700,
                                // maxWidth: "100%",
                                // margin: "0 auto",
                                // }
                            }}
                        />
                    ),
                });
            }}
        />
    );
};

export const RenderTextFieldAnswer = ({ openForm, field }) => {
    const { listAnswers } = useFormsStore();

    const value = field?.value;

    if (value === undefined) return <Text size="sm">-</Text>;
    if (field.type === "BOOLEAN") {
        return <Text size="sm">{value === true ? "Oui" : "Non"}</Text>;
    } else if (field.type === "DATE") return <Text size="sm">{moment(value).format("DD/MM/YYYY")}</Text>;
    else if (field.type === "TIME") return <Text size="sm">{moment(value).format("HH:mm")}</Text>;
    else if (field.type === "STATE") {
        return (
            <Tag color={+value === 0 ? "error" : +value === 1 ? "warning" : +value === 2 ? "green" : "blue"}>
                <Flex align="center" gap={6}>
                    {field.options[+value]}
                </Flex>
            </Tag>
        );
    } else if (field.type === "TIME") return <Text size="sm">{moment(value).format("HH:mm")}</Text>;
    else if (
        field.type === "SELECT_LIST" ||
        field.type === "SELECT_RADIO" ||
        field.type === "SELECT_CHECKBOX" ||
        field.type === "WEATHER"
    )
        return <Text size="sm">{Array.isArray(value) ? value?.join(" / ") : value}</Text>;
    else if (field.type === "REFERENCE") {
        const form = listAnswers.find((e) => e._id === value);
        if (form)
            return (
                <Button
                    style={{
                        width: "100%",
                    }}
                    onClick={(e) => {
                        if (openForm) {
                            e.stopPropagation();
                            e.preventDefault();
                            openForm(form);
                        }
                    }}>
                    <Flex
                        align="start"
                        gap="small"
                        style={{
                            width: "100%",
                        }}>
                        <MaterialIcons name="docs" size="xs" />
                        <Text bold size="sm" ellipsis>
                            {form?.title}
                        </Text>
                    </Flex>
                </Button>
            );
        else return <Text size="sm">-</Text>;
    }

    return <Text size="sm">{value}</Text>;
};
