export function scrollToElementByClass(className, isInstant = false) {
    let elements = document.getElementsByClassName(className);

    if (elements && elements[0]) {
        if (isInstant)
            elements[0].scrollIntoView({ block: "center", inline: "nearest" });
        else
            elements[0].scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
    }
}

export function scrollToElementById(id, isInstant = false) {
    let element = document.getElementById(id);

    if (element) {
        if (isInstant)
            element.scrollIntoView({ block: "center", inline: "nearest" });
        else
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
    }
}

export function scrollTop(isInstant = false) {
    if (isInstant === true) window.scrollTo({ top: 0 });
    else window.scrollTo({ top: 0, behavior: "smooth" });
}

export function scrollToPosition(top = 0, isInstant = false) {
    if (isInstant === true) window.scrollTo({ top: top });
    else window.scrollTo({ top: top, behavior: "smooth" });
}
