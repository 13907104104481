const InfoMessage = ({ type = "info", title, content }) => {
    return (
        <div
            className={
                "border-t-4 rounded-b px-4 py-3 shadow-md text-left" +
                (type === "info"
                    ? " bg-teal-100 border-teal-500 text-teal-900"
                    : type === "warning" &&
                      " bg-orange-100 border-orange-500 text-orange-900")
            }
            role="alert"
        >
            <div className="md:flex">
                <div className="mt-2 md:mt-0">
                    <div className="font-bold text-sm flex flex-row">
                        {type === "info" ? (
                            <i
                                className="material-icons notranslate mr-2"
                                style={{ fontSize: "1.2rem" }}
                            >
                                info
                            </i>
                        ) : (
                            <i
                                className="material-icons notranslate mr-2"
                                style={{ fontSize: "1.2rem" }}
                            >
                                warning
                            </i>
                        )}

                        <div className="my-auto">{title}</div>
                    </div>
                    {content && <p className="text-sm mt-2">{content}</p>}
                </div>
            </div>
        </div>
    );
};

export default InfoMessage;
