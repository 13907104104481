import "@react-pdf-viewer/core/lib/styles/index.css";
import ProviderApp from "providers/ProviderApp";
import ProviderFilters from "providers/ProviderFilters";
import ProviderProfile from "providers/ProviderProfile";

import { Suspense } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import Spinner from "components/utils/Spinner";

import AntdProvider from "layout/AntdProvider";
import LayoutBackgroundActions from "layout/LayoutBackgroundActions";
import LayoutDrawer from "layout/LayoutDrawer";
import LayoutIntegrations from "layout/LayoutIntegrations";
import LayoutModal from "layout/LayoutModal";
import LayoutNotifications from "layout/LayoutPopup";
import LayoutRedux from "layout/LayoutRedux";
import Router from "layout/Router";

import "./styles/custom.scss";
import "./utils/i18n";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdfWorker.mjs`;

function App() {
    return (
        <Suspense fallback={<Spinner />}>
            <AntdProvider>
                <LayoutNotifications>
                    <LayoutRedux>
                        <ErrorBoundary>
                            <ProviderProfile />
                            <ProviderApp />
                            <ProviderFilters />
                            <DndProvider backend={HTML5Backend}>
                                <LayoutModal>
                                    <LayoutIntegrations>
                                        <LayoutDrawer>
                                            <LayoutBackgroundActions>
                                                <Router />
                                            </LayoutBackgroundActions>
                                        </LayoutDrawer>
                                    </LayoutIntegrations>
                                </LayoutModal>
                            </DndProvider>
                        </ErrorBoundary>
                    </LayoutRedux>
                </LayoutNotifications>
            </AntdProvider>
        </Suspense>
    );
}

export default App;
