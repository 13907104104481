import { useContext, useMemo } from "react";
import { useLocation } from "react-router";

import { DriveToolsContext } from "components/drive/Drive";

import { Badge, theme } from "antd";

const CountFilter = ({ children, isRelative }) => {
    const { pathname } = useLocation();
    const { toolsData } = useContext(DriveToolsContext);
    const { token } = theme.useToken();

    const filterCount = useMemo(() => {
        let count = 0;
        if (toolsData.filters?.projects?.length) count++;
        if (toolsData.filters?.users?.length) count++;
        if (toolsData.filters?.statuts?.length) count++;
        if (toolsData.filters?.dateRange !== "all") {
            if (
                pathname === "/timesheet/entries" &&
                toolsData.filters?.dateRange !== "byMonth"
            )
                count++;
        }
        if (toolsData.filters?.folders?.length) count++;
        return count;
    }, [toolsData.filters, toolsData.pagination]);

    return (
        <Badge
            color={token.colorPrimary}
            size="small"
            count={filterCount}
            style={
                isRelative
                    ? {
                          position: "relative",
                      }
                    : {}
            }
        >
            {children}
        </Badge>
    );
};

export default CountFilter;
