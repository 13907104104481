import axios from "axios";

import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { addDaysToDate } from "helpers/date";
import apiURL from "utils/apiURL";

import DocumentReader from "components/documents/DocumentReader";
import { DriveToolsContext } from "components/drive/Drive";
import Spinner from "components/utils/Spinner";

import VisaTableColFilters from "./VisaTableColFilters";
import VisasTableLine from "./VisasTableLine";

const VisasTable = ({ onItemClick, refreshAction }) => {
    const { t } = useTranslation();

    const tableRef = useRef(null);
    const { toolsData, userProfile } = useContext(DriveToolsContext);
    const [sortedItems, setSortedItems] = useState([]);
    const [sortConfig, setSortConfig] = useState(null);

    const [visasCols, setVisasCols] = useState([]);
    const [colsFilters, setColsFilters] = useState(null);

    const [visas, setVisas] = useState([]);

    const [visasStep, setVisasStep] = useState(0);
    const [isVisasLimitReached, setVisasLimitReached] = useState(false);

    const [renderedDocument, setRenderedDocument] = useState(null);

    const [loader, setLoader] = useState(false);
    const [smallLoaderMore, setSmallLoaderMore] = useState(false);

    const limitNbVisas = 50;

    useEffect(() => {
        document.title = t("visas") + " - " + t("applicationTitle");

        const getVisas = async () => {
            setLoader(true);

            try {
                const visasRes = await axios.get(
                    apiURL.getUserVisas + visasStep,
                );

                if (visasRes && visasRes.data) {
                    setVisas(visasRes.data);
                } else {
                    setVisas([]);
                }
            } catch (e) {
                setVisas([]);
            }

            setLoader(false);
        };

        if (visas.length === 0) getVisas();
    }, [userProfile]);

    const compareValues = (a, b, direction) => {
        if (sortConfig.key === "title") {
            if (a < b) {
                return direction === "ascending" ? 1 : -1;
            }
            if (a > b) {
                return direction === "ascending" ? -1 : 1;
            }
        } else {
            if (a === "") return 1;
            if (b === "") return -1;

            if (a.refusedDate) return 1;
            if (b.refusedDate) return -1;

            if (a.approvedDate) return 1;
            if (b.approvedDate) return -1;

            let aItem = addDaysToDate(a.deadlineDays, a.startedDate);
            let bItem = addDaysToDate(b.deadlineDays, b.startedDate);

            if (aItem < bItem) {
                return direction === "ascending" ? 1 : -1;
            }
            if (aItem > bItem) {
                return direction === "ascending" ? -1 : 1;
            }
        }

        return 0;
    };

    useMemo(() => {
        const sortItems = () => {
            let sortableItems = JSON.parse(JSON.stringify(visas));

            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (sortConfig.key === "dueDate") {
                        a = a.visas.find(
                            (visa) =>
                                visa.visa && visa.visa._id === colsFilters,
                        );
                        b = b.visas.find(
                            (visa) =>
                                visa.visa && visa.visa._id === colsFilters,
                        );

                        let aItem = a ? a : "";
                        let bItem = b ? b : "";

                        return compareValues(
                            aItem,
                            bItem,
                            sortConfig.direction,
                        );
                    } else {
                        return compareValues(
                            a[sortConfig.key].toLowerCase(),
                            b[sortConfig.key].toLowerCase(),
                            sortConfig.direction,
                        );
                    }
                });
            }
            setSortedItems(sortableItems);
        };

        sortItems();

        setColsFilters(null);
    }, [visas, sortConfig]);

    useEffect(() => {
        let visasColNames = [];

        visas.forEach((item) => {
            item.visas
                .filter((visa) => visa.visa)
                .forEach((visa) => {
                    if (
                        !visasColNames.some(
                            (item) => item.title === visa.visa.title,
                        )
                    ) {
                        visasColNames.push({
                            id: visa.visa._id,
                            title: visa.visa.title,
                        });
                    }
                });
        });

        setVisasCols(
            visasColNames.sort((a, b) =>
                a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
            ),
        );
    }, [visas]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (visas.length > 0 && toolsData.visas.updatedVisa) {
            const lineIndex = visas.findIndex((line) =>
                line.visas.some(
                    (lineVisa) =>
                        lineVisa._id === toolsData.visas.updatedVisa._id,
                ),
            );

            if (lineIndex > -1) {
                let updatedVisas = visas;
                const visaIndex = updatedVisas[lineIndex].visas.findIndex(
                    (lineVisa) =>
                        lineVisa._id === toolsData.visas.updatedVisa._id,
                );

                if (visaIndex > -1) {
                    updatedVisas[lineIndex].visas[visaIndex] =
                        toolsData.visas.updatedVisa;

                    setVisas([...updatedVisas]);
                }
            }
        }
    }, [toolsData.visas.updatedVisa]);

    const handleClickOutside = (e) => {
        const checkParent = (element) => {
            if (!element || element.tagName === "HTML") return false;
            if (element.classList.contains("filters-list")) return true;

            return checkParent(element.parentElement);
        };

        if (!checkParent(e.target)) setColsFilters(null);
    };

    const requestSort = (key, direction) => {
        if (!direction) {
            // Champs de filtre classiques
            if (!sortConfig || (sortConfig && sortConfig.key !== key))
                setSortConfig({ key, direction: "descending" });
            else if (sortConfig && sortConfig.direction === "descending")
                setSortConfig({ key, direction: "ascending" });
            else if (sortConfig && sortConfig.direction === "ascending") {
                setSortConfig(null);
            }
        } else {
            // Filtres avec liste des possibilités
            setSortConfig({ key, direction, id: colsFilters });
        }
    };

    const handleColsFilters = (colId) => {
        if (!colsFilters || colsFilters !== colId) setColsFilters(colId);
        else setColsFilters(null);
    };

    const handleScroll = async (e) => {
        if (visas.length < limitNbVisas) return;

        if (
            e.target.scrollHeight - e.target.scrollTop ===
                e.target.clientHeight &&
            !isVisasLimitReached
        ) {
            setSmallLoaderMore(true);

            const visasRes = await axios.get(
                apiURL.getUserVisas + (visasStep + 1),
            );

            if (visasRes && visasRes.data) {
                if (visasRes.data.length === 0) setVisasLimitReached(true);
                else {
                    setVisas((oldVisas) => [...oldVisas, ...visasRes.data]);

                    setVisasStep(visasStep + 1);
                }
            }

            setSmallLoaderMore(false);
        }
    };

    const closeReader = () => {
        setRenderedDocument(null);
    };

    return visas && !loader ? (
        <div
            ref={tableRef}
            className="max-h-[calc(100vh-170px)] overflow-auto"
            onScroll={(e) => handleScroll(e)}
        >
            {visas.length > 0 ? (
                <table className="table-fixed min-w-max w-full text-left mt-8 text-sm">
                    <thead>
                        <tr>
                            <th className="sticky left-0 z-[1] p-4 sm:p-2 whitespace-nowrap w-[150px] sm:w-[300px] lg:-[400px] xl:w-[500px]">
                                <button
                                    type="button"
                                    className="w-full text-left"
                                    onClick={(e) => requestSort("title")}
                                >
                                    {t("nom")}
                                    <i
                                        className="material-icons notranslate inline ml-1 align-middle"
                                        style={{ fontSize: "1rem" }}
                                    >
                                        {!sortConfig ||
                                        (sortConfig &&
                                            sortConfig.key !== "title")
                                            ? "unfold_more"
                                            : sortConfig.direction ===
                                                "ascending"
                                              ? "expand_less"
                                              : sortConfig.direction ===
                                                    "descending" &&
                                                "expand_more"}
                                    </i>
                                </button>
                            </th>
                            {visasCols.map((col) => (
                                <th
                                    key={col.id}
                                    className="relative p-4 sm:p-2 whitespace-nowrap"
                                >
                                    <VisaTableColFilters
                                        requestSort={requestSort}
                                        tableRef={tableRef}
                                        colsFilters={colsFilters}
                                        col={col}
                                        handleColsFilters={handleColsFilters}
                                        sortConfig={sortConfig}
                                        setSortConfig={setSortConfig}
                                    />
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {sortedItems.map((item) => (
                            <VisasTableLine
                                key={item._id}
                                profile={userProfile}
                                item={item}
                                visasCols={visasCols}
                                onItemClick={onItemClick}
                                setRenderedDocument={setRenderedDocument}
                            />
                        ))}
                    </tbody>
                </table>
            ) : (
                <div className="text-center mt-10 mx-4 text-lg font-bold">
                    {t("aucunVisaDisponible")}
                </div>
            )}

            {smallLoaderMore && (
                <div className="absolute flex w-full bottom-2">
                    <Spinner isSmall={true} />
                </div>
            )}

            {renderedDocument && (
                <DocumentReader
                    fileId={renderedDocument}
                    closeReader={closeReader}
                    profile={userProfile}
                    refreshAction={refreshAction}
                />
            )}
        </div>
    ) : (
        <div className="mt-10">
            <Spinner isSmall={true} />
        </div>
    );
};

export default VisasTable;
