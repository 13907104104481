import axios from "axios";

import { useCallback } from "react";
import { Ch } from "react-flags-select";
import { useTranslation } from "react-i18next";

import apiURL from "utils/apiURL";

import ButtonDelete from "components/tableInline/ButtonDelete";
import TableInline from "components/tableInline/Table";

const TabCustomers = () => {
    const { t } = useTranslation();

    const getDataList = useCallback(async () => {
        const { data } = await axios.get(apiURL.getCustomers);
        return data;
    }, []);

    const editInline = useCallback(async (itemId, newItem) => {
        const { data } = await axios.patch(
            apiURL.updateCustomer + itemId,
            newItem,
        );
        return data;
    }, []);

    const addInline = useCallback(async (newItem) => {
        const { data } = await axios.post(apiURL.createCustomer, {
            name: "Nouveau client",
            ...newItem,
        });
        return data;
    }, []);

    const getFlags = useCallback(async () => {
        return [
            {
                val: "CH",
                label: (
                    <span className="flex items-center gap-2">
                        <Ch />
                        Switzerland
                    </span>
                ),
            },
        ];
    }, []);

    return (
        <TableInline
            getFunction={getDataList}
            addFunction={addInline}
            editFunction={editInline}
            ButtonOptions={ButtonOptions}
            fields={[
                {
                    key: "name",
                    label: t("nomDuClient"),
                },
                {
                    key: "address.street",
                    label: t("adresseDuClient"),
                },
                {
                    key: "address.city",
                    label: t("ville"),
                    width: "250px",
                },
                {
                    key: "address.code",
                    label: t("codePostal"),
                    width: "150px",
                },
                {
                    key: "address.country",
                    label: t("pays"),
                    width: "250px",
                    getOptions: getFlags,
                },
            ]}
        />
    );
};

const ButtonOptions = ({ dataLine, setDataList, setLoader }) => {
    const { t } = useTranslation();

    const deleteInline = useCallback(async () => {
        setLoader(true);
        await axios.delete(apiURL.removeCustomer + dataLine._id);
        setDataList((e) => e.filter((e) => e._id !== dataLine._id));
        setLoader(false);
    }, [dataLine]);

    return <ButtonDelete deleteFunction={deleteInline} />;
};

export default TabCustomers;
