import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import MobileMenuOverlay from "./MobileMenuOverlay";
import UserMenuItem from "./UserMenuItem";

const UserMenuContent = ({
    isVisible,
    setVisible,
    user,
    isMobileMenu = false,
}) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            {isMobileMenu && isVisible && (
                <MobileMenuOverlay
                    height="h-[calc(100%-60px)]"
                    setVisible={setVisible}
                />
            )}

            <div
                className={
                    "list-none divide-y absolute text-base rounded z-[21] shadow bg-black divide-gray-600 text-left" +
                    (!isMobileMenu
                        ? " right-0 top-full"
                        : " left-0 bottom-[60px] w-full rounded-t-2xl rounded-b-none") +
                    (isVisible ? "" : " hidden")
                }
                id={!isMobileMenu ? "user-menu" : undefined}
            >
                {user && (
                    <Fragment>
                        <div className="py-3 px-4 text-xs">
                            <span className="block text-white">
                                {user.firstname + " " + user.lastname}
                            </span>
                            <span className="block font-medium truncate text-gray-400">
                                {user.email}
                            </span>
                        </div>
                        <ul className="py-1">
                            <UserMenuItem
                                to="/profile"
                                text={t("profil")}
                                isMobileMenu={isMobileMenu}
                            />
                            {!localStorage.getItem("isWorker") && (
                                <UserMenuItem
                                    to="/my-account"
                                    text={t("monCompte")}
                                    isMobileMenu={isMobileMenu}
                                />
                            )}
                            <UserMenuItem
                                isLogout={true}
                                text={t("deconnexion")}
                                isMobileMenu={isMobileMenu}
                            />
                        </ul>
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
};

export default UserMenuContent;
