import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { capitalize } from "helpers/string";

import Select from "components/input/Select";

const ActionLine = ({
    index = 0,
    actionDatas,
    updateValue,
    onChange,
    deleteRow,
    folderId,
    actionTypes,
    actionConstraints,
    visas,
    deadlineDays,
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (actionTypes.length > 0) {
            // On définit l'action par défaut sur la première de la liste
            updateActionFormValue(
                "type",
                actionDatas.type !== "" ? actionDatas.type : actionTypes[0].val,
            );
        }
    }, [actionTypes]);

    useEffect(() => {
        if (actionConstraints.length > 0)
            updateActionFormValue(
                "constraint",
                actionDatas.constraint !== ""
                    ? actionDatas.constraint
                    : actionConstraints[0].val,
            );
    }, [actionConstraints]);

    useEffect(() => {
        if (!visas) return;

        if (visas.length > 0)
            updateActionFormValue(
                "visa",
                actionDatas.visa && actionDatas.visa !== ""
                    ? actionDatas.visa
                    : visas[0].val,
            );
        else updateActionFormValue("visa", "");
    }, [visas, folderId]);

    useEffect(() => {
        if (deadlineDays.length > 0)
            updateActionFormValue(
                "deadlineDays",
                actionDatas.deadlineDays > 0
                    ? actionDatas.deadlineDays
                    : deadlineDays[0].val,
            );
    }, []);

    useEffect(() => {
        if (index > 0 && actionDatas.operator === "")
            updateActionFormValue(
                "operator",
                actionDatas.operator !== "" ? actionDatas.operator : "AND",
            );
    }, [index]);

    const updateActionFormValue = (name, value) => {
        updateValue(index, name, value);
    };

    const onDelete = () => {
        deleteRow(index);
    };

    const handleChange = (e) => {
        onChange(index, e);
    };

    return (
        <div className="flex flex-row">
            <div className="my-auto w-[100px]">
                {index === 0 ? (
                    <div className="font-bold">{t("alors")}</div>
                ) : (
                    capitalize(t("et"))
                )}
            </div>
            <div className="card w-[calc(100%-100px)] rounded-xl bg-slate-50 border-slate-200 border-2 p-4 my-2">
                <div className="flex flex-row w-full">
                    <div className="w-full xl:flex xl:flex-row">
                        <div className="lg:flex lg:flex-row">
                            <div>
                                <Select
                                    name="actionType"
                                    options={folderId ? actionTypes : []}
                                    isRequired={true}
                                    value={actionDatas.type}
                                    onChange={handleChange}
                                    isDisabled={folderId ? false : true}
                                />
                            </div>
                            <div className="lg:ml-4 mt-6 lg:mt-0">
                                <Select
                                    name="visa"
                                    options={
                                        folderId
                                            ? visas?.sort((a, b) =>
                                                  a.label > b.label ? 1 : -1,
                                              ) || []
                                            : []
                                    }
                                    isRequired={true}
                                    value={actionDatas.visa}
                                    onChange={handleChange}
                                    isDisabled={folderId ? false : true}
                                />
                            </div>
                        </div>
                        <div className="lg:flex lg:flex-row mt-6 xl:mt-0">
                            <div className="xl:ml-4">
                                <Select
                                    name="actionConstraint"
                                    options={
                                        folderId && actionDatas.visa
                                            ? actionConstraints
                                            : []
                                    }
                                    isRequired={true}
                                    value={actionDatas.constraint}
                                    onChange={handleChange}
                                    isDisabled={folderId ? false : true}
                                />
                            </div>
                            <div className="lg:ml-4 mt-6 lg:mt-0">
                                <Select
                                    name="deadlineDays"
                                    options={
                                        folderId && actionDatas.visa
                                            ? deadlineDays
                                            : []
                                    }
                                    isRequired={true}
                                    value={actionDatas.deadlineDays}
                                    onChange={handleChange}
                                    isDisabled={folderId ? false : true}
                                />
                            </div>
                        </div>
                    </div>

                    {index > 0 && (
                        <button
                            type="button"
                            className="flex my-auto p-1 ml-2 lg:ml-0"
                            onClick={(e) => onDelete(index)}
                        >
                            <i
                                className="material-icons notranslate text-slate-400 my-auto"
                                style={{ fontSize: "2rem" }}
                            >
                                delete
                            </i>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ActionLine;
